import { boolean, number, object, string } from "yup"
import { PropertyDataRequiredInfo } from "../sections/contracts/newContract/PropertyData"

export const AddTenantPropertyInfoSchema = (propertyRequiredInfo: PropertyDataRequiredInfo | undefined) =>
    object().shape({
        county: string().required("county_required"),
        city: string().required("city_required"),
        street: string().test("requiredTest", "street_required", (val) => {
            return !propertyRequiredInfo?.isStreetRequired || (val != null && val.trim() !== "")
        }),
        streetNumber: string().test("requiredTest", "number_required", (val) => {
            return !propertyRequiredInfo?.isStreetNumberRequired || (val != null && val.trim() !== "")
        }),
        buildingNumber: string().test("requiredTest", "number_required", (val) => {
            return !propertyRequiredInfo?.isBuildingNumberRequired || (val != null && val.trim() !== "")
        }),
        stair: string().test("requiredTest", "stair_required", (val) => {
            return !propertyRequiredInfo?.isStairRequired || (val != null && val.trim() !== "")
        }),
        floor: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("requiredTest", "floor_required", (val) => {
                return !propertyRequiredInfo?.isFloorRequired || val != null
            }),
        apartment: string().test("requiredTest", "apartment_required", (val) => {
            return !propertyRequiredInfo?.isApartmentRequired || (val != null && val.trim() !== "")
        }),
        savePropertyInfo: boolean().required(),
    })
