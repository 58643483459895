import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import DeleteContent from "../../../../../components/DeleteContent"
import { useUserLabelsQuery } from "../../../../../queries/userData"
import { deleteLabelMutation } from "../../../../../mutations/labels"

export default function DeleteLabelGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteLabel, isPending: isDeleteLabelPending } = deleteLabelMutation()
    const { data: labels } = useUserLabelsQuery()

    const currentLabel = useMemo(() => labels?.find((l) => l.id === editingItem?.id), [labels, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.LabelDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentLabel == null) return
        await deleteLabel(currentLabel?.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentLabel, deleteLabel, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete_label")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteLabel}
                onClose={onClose}
                isFullScreen={false}
                variantCloseButton={"text"}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_label_content", { name: currentLabel?.value })}
                    isLoading={isDeleteLabelPending}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteLabel}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_label")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_label_content", { name: currentLabel?.value })}
                    isLoading={isDeleteLabelPending}
                />
            </DetailsSheet>
        </>
    )
}
