import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material"
import { LoadingIcon } from "rentzz"
import { useNotificationsTemplatesQuery } from "../../../../queries/userData"
import React, { useCallback } from "react"
import CustomAddCard from "../../../../components/CustomAddCard"
import NotificationItem from "./NotificationItem"

export default function DesktopNotificationsList() {
    const navigate = useNavigate()
    const { data: notificationsTemplates } = useNotificationsTemplatesQuery()

    const triggerAddNewNotification = useCallback(() => {
        navigate("/document-templates/notification-templates/new")
    }, [navigate])

    if (notificationsTemplates == null) return <LoadingIcon />

    return (
        <Grid container paddingY={2} rowGap={2}>
            {notificationsTemplates.items?.map((notification) => <NotificationItem key={notification.id} notification={notification} />)}

            <Grid item xs={12} md={4} lg={3}>
                <CustomAddCard
                    onCardClick={triggerAddNewNotification}
                    isDesktop={true}
                    text={"new_notification"}
                    icon={"mdi:file-document-add-outline"}
                    marginRight={2}
                />
            </Grid>
        </Grid>
    )
}
