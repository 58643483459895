import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { removeOrDeleteManagerMutation } from "../../../../mutations"

export default function DeleteOrRemoveManagerGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { modalOpen, editingItem, currentPropertyId } = useSelector((state) => state.appState)
    const { mutateAsync: removeInvitationOrDelete, isPending: isRemoveInvitationOrDeleteOwnerLoading } = removeOrDeleteManagerMutation()

    const isAccepted = useMemo(() => propertyDetails?.users.some((user) => user.mail === editingItem?.mail), [propertyDetails, editingItem])

    const handleInvitation = useCallback(async () => {
        if (currentPropertyId == null || editingItem == null || isAccepted == null) return
        await removeInvitationOrDelete({
            propertyId: currentPropertyId,
            email: editingItem?.mail,
            isInvitationAccepted: isAccepted,
        })

        dispatch(setModalOpen(undefined))
        dispatch(editingItem(undefined))
    }, [currentPropertyId, editingItem])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate(isAccepted ? "delete_user" : "delete_invitation")}
                onClose={() => dispatch(setModalOpen(undefined))}
                open={!isMobile && modalOpen === AppModals.RemoveManagerInvitation}
                maxWidth='xs'
                onAccept={handleInvitation}
                acceptText={translate("delete")}
                closeText={translate("cancel")}
                closeButtonColor={"primary"}
                variantCloseButton={"text"}
                acceptButtonColor={"error"}
                isDisabled={isRemoveInvitationOrDeleteOwnerLoading}
                isLoading={isRemoveInvitationOrDeleteOwnerLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2} flexDirection={"column"}>
                    {isAccepted ? (
                        <Alert
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                color: theme.palette.grey["700"],
                            }}
                            severity='warning'
                        >
                            {translate("delete_owner_info", { email: editingItem?.mail })}
                        </Alert>
                    ) : (
                        <Typography>
                            {translate(isAccepted ? "delete_owner_content" : "delete_invitation_content", { email: editingItem?.mail })}
                        </Typography>
                    )}
                </Box>
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(isAccepted ? "delete_user" : "delete_invitation")}
                isOpen={isMobile && modalOpen === AppModals.RemoveManagerInvitation}
                onClose={() => dispatch(setModalOpen(AppModals.ManagerDetails))}
                headerColor={theme.palette.error.main}
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
                    <Box display={"flex"} justifyContent={"center"} paddingY={2} flexDirection={"column"}>
                        {isAccepted ? (
                            <Alert
                                sx={{
                                    alignItems: "center",
                                    display: "flex",
                                    color: theme.palette.grey["700"],
                                }}
                                severity='warning'
                            >
                                {translate("delete_owner_info", { email: editingItem?.mail })}
                            </Alert>
                        ) : (
                            <Typography textAlign={"center"}>
                                {translate(isAccepted ? "delete_owner_content" : "delete_invitation_content", { email: editingItem?.mail })}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={handleInvitation}
                            variant='contained'
                            color={"error"}
                            fullWidth
                            loading={isRemoveInvitationOrDeleteOwnerLoading}
                            disabled={isRemoveInvitationOrDeleteOwnerLoading}
                        >
                            {translate("delete")}
                        </LoadingButton>
                        <Button
                            disabled={isRemoveInvitationOrDeleteOwnerLoading}
                            onClick={() => dispatch(setModalOpen(AppModals.ManagerDetails))}
                            color={"primary"}
                            fullWidth
                        >
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>
        </>
    )
}
