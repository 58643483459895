import { IconButton } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import React from "react"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { LightTooltip, useLocales } from "rentzz"

interface Props {
    tooltipText: string
}
function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, "id" | "value"> & Props) {
    const { value: isExpanded, tooltipText } = props
    const { translate } = useLocales()

    return (
        <LightTooltip title={translate(tooltipText)} arrow>
            <IconButton size='small' tabIndex={-1} aria-label={isExpanded ? "Close" : "Open"}>
                {isExpanded ? (
                    <VisibilityOff color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} fontSize='inherit' />
                ) : (
                    <Visibility color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} fontSize='inherit' />
                )}
            </IconButton>
        </LightTooltip>
    )
}

export default CustomDetailPanelToggle
