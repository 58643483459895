import { Box, Divider } from "@mui/material"
import React, { useMemo } from "react"
import VariableContent from "./VariableContent"
import { Label, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import NoDataPage from "../../../components/NoDataPage"
import { orderBy } from "lodash"
import { useSelector } from "../../../redux/store"

interface Props {
    handleNotificationPreview: () => void
}
export default function NotificationVariables({ handleNotificationPreview }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    const variablesAreNotConfigured = useMemo(
        () => rentingPeriodDetails?.paymentNotificationVariables.some((v) => v.value == null || v.value === ""),
        [rentingPeriodDetails],
    )
    return (
        <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            height={isMobile ? undefined : window.innerHeight - 320}
            sx={{ overflowY: "auto" }}
        >
            {rentingPeriodDetails?.paymentNotificationVariables.length === 0 ? (
                <NoDataPage icon={"mdi:success-circle-outline"} noDataText={"no_variables"} />
            ) : (
                <>
                    <Box>
                        <Box display={"flex"} gap={2}>
                            <Label sx={{ textTransform: "none" }} color={variablesAreNotConfigured ? "error" : "success"}>
                                {translate(variablesAreNotConfigured ? "variables_are_not_configured" : "all_variables_are_configured")}
                            </Label>
                        </Box>

                        <Divider sx={{ marginTop: 1, backgroundColor: theme.palette.grey["500"] }} />
                    </Box>

                    {orderBy(rentingPeriodDetails?.paymentNotificationVariables, "pattern").map((variable) => (
                        <VariableContent key={variable.pattern} variable={variable} handleNotificationPreview={handleNotificationPreview} />
                    ))}
                </>
            )}
        </Box>
    )
}
