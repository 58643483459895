import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import SelectProvider from "./SelectProvider"
import ProviderCredentials from "./ProviderCredentials"
import Review from "./Review"
import { AddProviderRequest } from "./types"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import { useAvailableExpenseProviders, useAvailableMeterProviders } from "../../../../../queries"
import { addConfiguredExpenseProvider } from "../../../../../mutations/expenses"
import { ProviderType } from "../../../../../redux/slices/AddProperty"
import { addConfiguredMeterProvider } from "../../../../../mutations/property/meters/meterProviders"

enum AddProviderSteps {
    SelectProvider,
    ProviderConfiguration,
    Review,
}

interface Props {
    onClose: () => void
}
export default function AddProviderForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const [addProviderRequest, setAddProviderRequest] = useState<Partial<AddProviderRequest>>()
    const [activeStep, setActiveStep] = useState<AddProviderSteps>(AddProviderSteps.SelectProvider)
    const { data: expenseAvailableProviders } = useAvailableExpenseProviders()
    const { data: metersAvailableProviders } = useAvailableMeterProviders()
    const { mutateAsync: addExpenseProvider, isPending: isAddExpenseProviderLoading } = addConfiguredExpenseProvider()
    const { mutateAsync: addMeterProvider, isPending: isAddMeterProviderLoading } = addConfiguredMeterProvider()

    const availableProviders = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? expenseAvailableProviders : metersAvailableProviders),
        [editingItem, expenseAvailableProviders, metersAvailableProviders],
    )

    const getSteps = useCallback(() => {
        return [
            {
                title: translate("choose_provider"),
            },
            {
                title: translate("provider_configuration"),
            },
            {
                title: translate("review"),
            },
        ]
    }, [translate])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddProviderSteps.SelectProvider:
                return (
                    <SelectProvider
                        onClose={onClose}
                        onSubmit={(providerId, propertyId, meterId) => {
                            setAddProviderRequest((prevRequest) => ({ ...prevRequest, providerId, propertyId, meterId }))
                            setActiveStep(AddProviderSteps.ProviderConfiguration)
                        }}
                    />
                )
            case AddProviderSteps.ProviderConfiguration:
                return (
                    <ProviderCredentials
                        fields={availableProviders?.find((p) => p.id === addProviderRequest?.providerId)?.requiredFields ?? []}
                        onSubmit={(data) => {
                            setAddProviderRequest((prevRequest) => ({
                                ...prevRequest,
                                fields: { ...data },
                                assignee: data.assignee as ExpenseAssignee,
                            }))
                            setActiveStep(AddProviderSteps.Review)
                        }}
                        onBack={() => {
                            setActiveStep(AddProviderSteps.SelectProvider)
                            setAddProviderRequest((prevRequest) => ({ ...prevRequest, fields: undefined, assignee: undefined }))
                        }}
                    />
                )
            case AddProviderSteps.Review:
                return (
                    <Review
                        isLoading={isAddMeterProviderLoading || isAddExpenseProviderLoading}
                        request={addProviderRequest!}
                        onBack={() => {
                            setActiveStep(AddProviderSteps.ProviderConfiguration)
                        }}
                        onNext={async () => {
                            if (editingItem?.providerType === ProviderType.Expense) {
                                await addExpenseProvider(
                                    { data: addProviderRequest! as AddProviderRequest },
                                    {
                                        onSuccess: () => {
                                            dispatch(setModalOpen(undefined))
                                            dispatch(setEditingItem(undefined))
                                            setAddProviderRequest(undefined)
                                        },
                                    },
                                )
                            }
                            if (editingItem?.providerType === ProviderType.Meter) {
                                await addMeterProvider(
                                    { data: addProviderRequest! as AddProviderRequest },
                                    {
                                        onSuccess: () => {
                                            if (isMobile) {
                                                dispatch(setModalOpen(AppModals.MeterDetails))
                                            } else {
                                                dispatch(setModalOpen(undefined))
                                                dispatch(setEditingItem(undefined))
                                            }
                                            setAddProviderRequest(undefined)
                                        },
                                    },
                                )
                            }
                        }}
                    />
                )
        }
    }, [activeStep, availableProviders, addProviderRequest, isAddMeterProviderLoading, isAddExpenseProviderLoading, addMeterProvider, editingItem])

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                {getSteps().map((step, index) => {
                    return (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    )
                })}
            </Stepper>
            {!isMobile && renderActiveStep()}
        </Box>
    )
}
