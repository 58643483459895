import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deleteInvoicingConfiguration } from "../../../../../mutations/invoicingConfiguration"
import { useAllInvoicingConfigurationsQuery } from "../../../../../queries/invoicing-configuration"

export default function DeleteInvoicingConfigurationGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteConfiguration, isPending: isDeletingInvoicingConfiguration } = deleteInvoicingConfiguration()
    const { data: allInvoicingConfigurations } = useAllInvoicingConfigurationsQuery()

    const currentInvoicingConfiguration = useMemo(
        () => allInvoicingConfigurations?.find((section) => section.id === editingItem?.id),
        [allInvoicingConfigurations, editingItem],
    )

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.InvoicingConfigurationDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentInvoicingConfiguration == null) return
        await deleteConfiguration(currentInvoicingConfiguration.id)

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentInvoicingConfiguration, deleteConfiguration, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete_invoicing_configuration")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteInvoicingConfiguration}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_invoicing_configuration_details")}
                    isLoading={isDeletingInvoicingConfiguration}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteInvoicingConfiguration}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_invoicing_configuration")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_invoicing_configuration_details")}
                    isLoading={isDeletingInvoicingConfiguration}
                />
            </DetailsSheet>
        </>
    )
}
