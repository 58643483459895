import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useCallback } from "react"
import AddDocumentForm, { AddDocumentsRequest } from "../../../sections/files/AddDocumentForm"
import { addDocumentsMutation } from "../../../mutations/property/documents"

export default function DocumentsGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: addDocument, isPending: isAddDocumentLoading } = addDocumentsMutation()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [modalOpen, dispatch])

    const onAddDocumentsSave = useCallback(
        async (data: AddDocumentsRequest) => {
            await addDocument({ data })
            closeModal()
        },
        [addDocument, closeModal],
    )

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.AddDocument}
                onClose={closeModal}
                isLoading={isAddDocumentLoading}
                title={translate("add_document")}
            >
                <AddDocumentForm onClose={closeModal} onSubmit={onAddDocumentsSave} />
            </DetailsSheet>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.AddDocument}
                isFullScreen={false}
                title={translate("add_document")}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
            >
                <AddDocumentForm onClose={closeModal} onSubmit={onAddDocumentsSave} />
            </CustomDialog>
        </>
    )
}
