import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useStyles } from "./useStyles"
import { TenantRequiredInfo } from "../newContract/TenantRequiredData"
import { useLocales } from "rentzz"

interface TenantContactProps {
    tenantInfo: TenantRequiredInfo | undefined
    isFocused: boolean
}

const TenantContactRequiredInfo = ({ tenantInfo, isFocused }: TenantContactProps) => {
    const { translate } = useLocales()
    const styles = useStyles()
    const renderEmptyIfNoValue = useCallback(
        (value: boolean | undefined, label?: string) => {
            if (value) {
                return <Text style={{ display: "flex", flexDirection: "row" }}>{translate(label)} ___________</Text>
            }
            return ""
        },
        [styles],
    )

    return (
        <View style={isFocused ? styles.focused : undefined}>
            <Text wrap style={styles.mainText}>
                {renderEmptyIfNoValue(tenantInfo?.isFullNameRequired)} {translate("contracts.address")}
                {renderEmptyIfNoValue(tenantInfo?.isCountyRequired)}, {renderEmptyIfNoValue(tenantInfo?.isCityRequired)}
                {renderEmptyIfNoValue(tenantInfo?.isStreetRequired, "contracts.street")}
                {renderEmptyIfNoValue(tenantInfo?.isStreetNumberRequired, "contracts.number")}
                {renderEmptyIfNoValue(tenantInfo?.isBuildingNumberRequired, "contracts.buildingNumber")}
                {renderEmptyIfNoValue(tenantInfo?.isStairRequired, "contracts.stair")}
                {renderEmptyIfNoValue(tenantInfo?.isFloorRequired, "contracts.floor")}
                {renderEmptyIfNoValue(tenantInfo?.isApartmentRequired, "contracts.apartment")}
                {renderEmptyIfNoValue(tenantInfo?.isIdentitySeriesRequired, "contracts.identitySeries")}
                {renderEmptyIfNoValue(tenantInfo?.isIdentityNumberRequired, "contracts.number")}
                {renderEmptyIfNoValue(tenantInfo?.isPhoneRequired, "contracts.phone")}
                {renderEmptyIfNoValue(tenantInfo?.isEmailRequired, "contracts.email")} {translate("contracts.as")}{" "}
                <Text style={{ ...styles.boldFont, textTransform: "uppercase" }}>{translate("tenant")}</Text>
            </Text>
        </View>
    )
}

export default TenantContactRequiredInfo
