import { Box, Button, Card, CardHeader, CircularProgress, Divider, Typography } from "@mui/material"
import { Iconify, Label, LightTooltip, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { alpha, useTheme } from "@mui/material/styles"
import { useRentingPeriodDetails, useSingleRentingPeriodQuery } from "../../../../../../queries/tenants"
import { UserFlags, useUserPropertiesQuery } from "../../../../../../queries/userData"
import { DateTime } from "luxon"
import { arrayOfNumbersToPeriod } from "../../../../../../utils/dateMagic"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../../../queries/currency"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../../../redux/slices/App"
import { RentingPeriodStatusCode } from "../../../../../../redux/slices/AddProperty"
import { useNavigate } from "react-router-dom"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function RentingPeriodDetailsCard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { data: currencyData } = useCurrencyQueryFn()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails, isLoading } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(currentRentingPeriodId)
    const canEditFinishedRentingPeriods = useFeatureIsOn(UserFlags.CanEditFinishedRentingPeriods.toString())

    const getAmount = useCallback(
        (value?: number, currencyId?: number) => {
            if (currencyId == null || value == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [intl, currencyData],
    )

    const rentingPeriodDetail = useCallback(
        (name: string, info?: string, onClick?: () => void) => (
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems={"center"}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate(name)}
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography
                        variant={"body2"}
                        color={theme.palette.grey.A700}
                        fontWeight={"bold"}
                        sx={{
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            textAlign: "right",
                            cursor: onClick ? "pointer" : "arrow",
                            color: onClick ? theme.palette.primary.main : undefined,
                            textDecoration: onClick ? "underline" : undefined,
                        }}
                        onClick={onClick}
                    >
                        {name === "payment.title" ? <Label color={"primary"}>{info}</Label> : info}
                    </Typography>
                </Box>
            </Box>
        ),
        [translate, theme],
    )

    const renderRentingPeriodDetails = useCallback(
        () => (
            <>
                {rentingPeriodDetail("property", `${properties?.find((p) => p.id === rentingPeriodDetails?.propertyId)?.label}`, () =>
                    navigate(`/properties/${rentingPeriodDetails?.propertyId}/general`),
                )}
                {rentingPeriodDetail(
                    "tenant-data.rentingPeriod",
                    `${rentingPeriodDetails?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${rentingPeriodDetails?.moveOutDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )}`,
                )}
                {rentingPeriodDetail("Rent period", arrayOfNumbersToPeriod(rentingPeriodDetails?.paymentPeriod ?? []).join(", "))}
                {rentingPeriodDetail(
                    "tenant_remove_access_at",
                    rentingPeriodDetails?.removeTenantAccessAt?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
                )}
                {rentingPeriodDetail("tenant-data.price", getAmount(rentingPeriodDetails?.value, rentingPeriodDetails?.currencyId))}
                {rentingPeriodDetail(
                    "tenant-data.securityDeposit",
                    getAmount(rentingPeriodDetails?.securityDeposit, rentingPeriodDetails?.securityDepositCurrencyId),
                )}
                {rentingPeriodDetails?.bankName && rentingPeriodDetail("bank_name", rentingPeriodDetails.bankName)}
                {rentingPeriodDetails?.bankAccount && rentingPeriodDetail("bank_account", rentingPeriodDetails.bankAccount)}
            </>
        ),
        [properties, rentingPeriodDetail, translate, getAmount, rentingPeriodDetails],
    )

    return (
        <Card elevation={6}>
            <CardHeader
                title={translate("details")}
                sx={{ color: theme.palette.grey.A700, p: 2 }}
                action={
                    rentingPeriodDetails?.canWrite && (
                        <LightTooltip
                            title={
                                currentRentingPeriod?.rentingPeriodStatus === RentingPeriodStatusCode.Finished && !canEditFinishedRentingPeriods
                                    ? translate("renting_period_ended_no_edit")
                                    : ""
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        borderRadius: 1,
                                        ":hover": { borderRadius: 1, backgroundColor: alpha(theme.palette.primary.main, 0.1) },
                                    }}
                                    onClick={() => dispatch(setModalOpen(AppModals.EditRentingPeriodDetails))}
                                    variant={"text"}
                                    color={"primary"}
                                    disabled={
                                        currentRentingPeriod?.rentingPeriodStatus === RentingPeriodStatusCode.Finished &&
                                        !canEditFinishedRentingPeriods
                                    }
                                >
                                    <Iconify icon={"mdi:pencil"} />
                                    <Typography>{translate("grid_actions.edit")}</Typography>
                                </Button>
                            </span>
                        </LightTooltip>
                    )
                }
            />
            <Divider />
            <Box display='flex' gap={1.3} height={isMobile ? 250 : 230} flexDirection='column' padding={3} paddingTop={2} overflow={"auto"}>
                {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                        <CircularProgress size={"1.5rem"} />
                    </Box>
                ) : (
                    renderRentingPeriodDetails()
                )}
            </Box>
        </Card>
    )
}
