import { Box, CircularProgress, InputAdornment, MenuItem } from "@mui/material"
import { RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import React from "react"
import { useCompanySeries, useCompanyVATs } from "../../../../../queries/invoicing-configuration"
import { useSelector } from "../../../../../redux/store"

export default function InvoicingForExpenses() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { data: allUserSeries } = useCompanySeries(rentingPeriodDetails?.invoicingCompanies.at(0)?.id)
    const { data: allUserVATs } = useCompanyVATs(rentingPeriodDetails?.invoicingCompanies.at(0)?.id)

    return (
        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
            <RHFSelect
                name={"reinvoicingSeries"}
                size='small'
                variant={"outlined"}
                label={translate("invoicing_series")}
                disabled={!canWrite || allUserSeries == null || allUserSeries.length === 0}
                fullWidth
            >
                {allUserSeries ? (
                    allUserSeries.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                            {c.name}
                        </MenuItem>
                    ))
                ) : (
                    <CircularProgress />
                )}
            </RHFSelect>
            <RHFSelect
                name={"reinvoicingVAT"}
                label={translate("TVA")}
                size='small'
                disabled={!canWrite || allUserVATs == null || allUserVATs.length === 0}
                fullWidth
            >
                {allUserVATs ? (
                    allUserVATs.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                            {c.name}
                        </MenuItem>
                    ))
                ) : (
                    <CircularProgress />
                )}
            </RHFSelect>

            <RHFTextField
                name={"reinvoicingPercentage"}
                label={translate("reinvoiceValuePercentage")}
                size='small'
                disabled={!canWrite}
                fullWidth
                type={"number"}
                InputProps={{
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
            />
            <RHFTextField name={"defaultReinvoicingText"} label={translate("invoicing_template")} size='small' disabled={!canWrite} fullWidth />
        </Box>
    )
}
