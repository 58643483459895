import { CustomDialog, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import EditExternalSystemCodeNameForm from "../../../sections/propertyDetails/Tenants/rentingPeriodDetails/EditExternalSystemCodeNameForm"

export default function EditExternalSystemCodeName() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen } = useSelector((state) => state.appState)

    return (
        <CustomDialog
            open={modalOpen === AppModals.EditExternalSystemCodeName}
            title={translate("edit_external_system_code_name")}
            maxWidth={"sm"}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isFullScreen={false}
            titleAlign={"center"}
        >
            <Box p={2}>
                <EditExternalSystemCodeNameForm
                    onClose={() => {
                        dispatch(setModalOpen(undefined))
                        dispatch(setEditingItem(undefined))
                    }}
                />
            </Box>
        </CustomDialog>
    )
}
