import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import AddNoteForm, { AddNoteRequest } from "../../../sections/propertyDetails/General/details/notes/AddNoteForm"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import { addNoteMutation, updateNoteMutation } from "../../../mutations/notes"
import { getNoteByIdQueryFn } from "../../../queries/notes"
import * as jsonpatch from "fast-json-patch"

export default function AddNoteGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addNote, isPending: isAddNoteLoading } = addNoteMutation()
    const { mutateAsync: updateNote, isPending: isUpdatingLoading } = updateNoteMutation()
    const currentNote = getNoteByIdQueryFn(editingItem?.id, [{ field: "addedAt", sort: "desc" }])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onSave = useCallback(
        async (data: AddNoteRequest) => {
            if (currentNote != null && modalOpen === AppModals.EditNote) {
                const operations = jsonpatch.compare({ ...currentNote, files: [] }, { ...data, files: [] })
                if (!data.noteId) return
                await updateNote({
                    propertyId: data.propertyId,
                    noteId: data.noteId,
                    operations,
                })
                if (isMobile) {
                    dispatch(setModalOpen(AppModals.NoteDetails))
                } else {
                    closeModal()
                }
            } else {
                await addNote({ data })
                closeModal()
            }
        },
        [addNote, closeModal, currentNote, modalOpen, updateNote, dispatch],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.EditNote ? "notes.editNote" : "notes.addNote")}
                open={!isMobile && (modalOpen === AppModals.AddNote || modalOpen === AppModals.EditNote)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddNoteForm onClose={closeModal} onSave={onSave} isLoading={isAddNoteLoading || isUpdatingLoading} defaultValues={currentNote} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditNote ? "notes.editNote" : "notes.addNote")}
                isOpen={isMobile && (modalOpen === AppModals.AddNote || modalOpen === AppModals.EditNote)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddNoteForm
                        onClose={() => {
                            if (modalOpen === AppModals.EditNote) {
                                dispatch(setModalOpen(AppModals.NoteDetails))
                            } else {
                                closeModal()
                            }
                        }}
                        onSave={onSave}
                        isLoading={isAddNoteLoading || isUpdatingLoading}
                        defaultValues={currentNote}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
