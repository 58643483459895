import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { deleteEntityFileMutation } from "../../../mutations/entityFile"

export default function DeleteFilesGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteFile, isPending: isDeleteFileLoading } = deleteEntityFileMutation()

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_file", { fileName: editingItem?.name })}
                isOpen={isMobile && modalOpen === AppModals.DeleteFile}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_file_details", {
                        fileName: editingItem?.name,
                    })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteFileLoading}
                    onAccept={async () => {
                        await deleteFile({ fileId: editingItem?.id })
                        onClose()
                    }}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteFile}
                isDeleteLoading={isDeleteFileLoading}
                title={translate("delete_file", { fileName: editingItem?.name })}
                content={translate("delete_file_details", {
                    fileName: editingItem?.name,
                })}
                onClose={onClose}
                onAccept={async () => {
                    await deleteFile({ fileId: editingItem?.id })
                    onClose()
                }}
            />
        </>
    )
}
