import { Box, Typography, useTheme } from "@mui/material"
import { Iconify, LightTooltip, useLocales } from "rentzz"
import React from "react"

interface HeaderProps {
    title?: string
    adCity?: string
    adCounty?: string
    rentPrice?: string
}

export default function HeaderLivePreview({ rentPrice, title, adCity, adCounty }: HeaderProps) {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <Box display={"flex"} justifyContent={"space-between"} marginBottom={2}>
            <Box width={"70%"}>
                <Typography fontWeight={"bold"} fontSize={17}>
                    {title || ""}
                </Typography>

                <Box display={"flex"} alignItems={"center"} paddingTop={0.5} gap={0.3}>
                    {adCounty && <Iconify icon={"mdi:map-marker"} color={theme.palette.grey.A400} />}
                    <Typography color={theme.palette.grey.A400} fontSize={14}>
                        {adCounty || ""}
                        {adCity ? ", " : ""}
                        {adCity || ""}
                    </Typography>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                {rentPrice ? (
                    <Typography fontWeight={"bold"} fontSize={21} color={theme.palette.primary.main}>
                        {rentPrice}
                    </Typography>
                ) : (
                    <LightTooltip title={translate("marketing.noRentPrice")}>
                        <span>
                            <Iconify icon={"mdi:currency-usd-off"} width={22} height={22} color={theme.palette.primary.main} />
                        </span>
                    </LightTooltip>
                )}
            </Box>
        </Box>
    )
}
