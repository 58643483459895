import { combineReducers } from "redux"
// slices
import addPropertyReducer from "./slices/AddProperty"
import appStateReducer from "./slices/App"

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
    addProperty: addPropertyReducer,
    appState: appStateReducer,
})

export { rootReducer }
