import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import MobileTenantRevisionsList from "./MobileTenantRevisionsList"
import DesktopTenantRevisionsList from "./DesktopTenantRevisionsList"

export default function TenantRevision() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Page title={translate("revisions")} px={isMobile ? 1 : 2} paddingBottom={2} paddingTop={1}>
            {isMobile ? <MobileTenantRevisionsList /> : <DesktopTenantRevisionsList />}
        </Page>
    )
}
