import { useTheme } from "@mui/material/styles"
import { AppContext, Iconify, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { Box, Button, Typography } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import ExportButton from "../../expenses/export/ExportButton"
import { ExportType } from "../../../utils/actions"

interface HeaderProps {
    currentMeterId: number
    canWrite: boolean | undefined
    lastValue: number | null
    numberOfItems: number | undefined
}
const Header = ({ lastValue, canWrite, currentMeterId, numberOfItems }: HeaderProps) => {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { context } = useSelector((state) => state.appState)

    const handleOpen = useCallback(() => {
        dispatch(setEditingItem({ id: currentMeterId }))
        dispatch(setModalOpen(AppModals.AddNewIndex))
    }, [dispatch, currentMeterId])

    return (
        <Box display={"flex"} justifyContent={"space-between"} alignItems='center' gap={2} flex={1}>
            <Box>
                <Typography variant='h5' color={theme.palette.grey.A700}>
                    {translate("readings")}
                </Typography>
            </Box>

            <Box display={"flex"} gap={1}>
                {context === AppContext.Owner && (
                    <ExportButton isDisabled={numberOfItems == null || numberOfItems === 0} type={ExportType.Reading} itemId={currentMeterId} />
                )}
                {canWrite && (
                    <Button variant='text' color={"primary"} onClick={handleOpen} disabled={lastValue == null} size='small'>
                        <Iconify icon={"mdi:add"} sx={{ height: 18, width: 18 }} />
                        <Typography>{translate("add_reading")}</Typography>
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default Header
