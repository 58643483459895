import { LoadingIcon, Page, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd"
import { AnimatePresence, m } from "framer-motion"
import { Box, Grid, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import SummaryCardsRow from "../dashboard/SummaryCardsRow"
import {
    deleteGroupChartMutation,
    deleteGroupSummaryCardMutation,
    updateGroupChartMutation,
    updateGroupSummaryCardMutation,
} from "../../mutations/groups"
import { useDispatch, useSelector } from "../../redux/store"
import CustomAddCard from "../../components/CustomAddCard"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import SummaryCardsRowMobile from "../dashboard/SummaryCardsRowMobile"
import ChartsRow from "../dashboard/ChartsRow"
import { DashboardItemType } from "../user/dashboard-preview/DashboardItemsPreview"
import ChartCardsRowMobile from "../dashboard/ChartCardsRowMobile"
import { useUserGroupsQueryFn } from "../../queries/groups"

export default function DashboardGroup() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentGroupId } = useSelector((state) => state.appState)
    const { mutate: updateSummaryCard } = updateGroupSummaryCardMutation()
    const { mutate: updateChart } = updateGroupChartMutation()
    const { mutate: deleteSummaryCards } = deleteGroupSummaryCardMutation()
    const { mutate: deleteChart } = deleteGroupChartMutation()
    const { data: groups } = useUserGroupsQueryFn()
    const [showDelete, setShowDelete] = useState(false)
    const theme = useTheme()

    const currentGroup = useMemo(() => groups?.find((group) => group.id === currentGroupId), [groups, currentGroupId])

    const handleDragStartDelete = useCallback(() => {
        setShowDelete(true)
    }, [])

    const onDragEnd = useCallback(
        async (result: DropResult) => {
            setShowDelete(false)
            if (currentGroup?.summaryCards == null || currentGroupId == null) return
            if (result.destination?.droppableId === "delete") {
                if (result.source.droppableId === "summary-card-row") {
                    const ourItemIndex = currentGroup.summaryCards.findIndex((c) => c.id === Number(result.draggableId))
                    let newItems = [...currentGroup.summaryCards]
                    newItems.splice(ourItemIndex, 1)
                    newItems = newItems.map((i, idx) => ({ ...i, order: idx }))
                    deleteSummaryCards({
                        summaryCardId: Number(result.draggableId),
                        summaryCards: newItems,
                    })
                }
                if (result.source.droppableId === "chart-row") {
                    const ourItemIndex = currentGroup.dashboardCharts.findIndex((c) => c.id === Number(result.draggableId))
                    let newItems = [...currentGroup.dashboardCharts]
                    newItems.splice(ourItemIndex, 1)
                    newItems = newItems.map((i, idx) => ({ ...i, order: idx }))
                    deleteChart({
                        chartId: Number(result.draggableId),
                        charts: newItems,
                    })
                }
            }

            if (result.source.droppableId === "summary-card-row" && result.destination?.droppableId === "summary-card-row") {
                const ourItemIndex = currentGroup.summaryCards.findIndex((c) => c.id === Number(result.draggableId))
                let newItems = [...currentGroup.summaryCards]
                newItems.swap(result.destination.index, ourItemIndex)
                newItems = newItems.map((i, idx) => ({ ...i, order: idx }))
                newItems.forEach((i, idx) => {
                    updateSummaryCard({
                        summaryCardId: i.id,
                        newPosition: idx,
                        summaryCards: newItems,
                    })
                })
            }

            if (result.source.droppableId === "chart-row" && result.destination?.droppableId === "chart-row") {
                const ourItemIndex = currentGroup.dashboardCharts.findIndex((c) => c.id === Number(result.draggableId))
                let newItems = [...currentGroup.dashboardCharts]
                newItems.swap(result.destination.index, ourItemIndex)
                newItems = newItems.map((i, idx) => ({ ...i, order: idx }))
                newItems.forEach((i, idx) => {
                    updateChart({
                        chartId: i.id,
                        newPosition: idx,
                        charts: newItems,
                    })
                })
            }
        },
        [updateSummaryCard, currentGroup, deleteSummaryCards, deleteChart, updateChart],
    )

    if (currentGroup == null) return <LoadingIcon />

    return (
        <Page title={translate("home")} sx={{ color: theme.palette.grey.A700, display: "flex", flexDirection: "column", gap: 2 }} paddingTop={2}>
            <DragDropContext onDragStart={handleDragStartDelete} onDragEnd={onDragEnd}>
                {!isMobile && (
                    <Droppable droppableId={"delete"} direction='vertical'>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    ...provided.droppableProps,
                                    zIndex: 10000,
                                    position: "absolute",
                                    top: 0,
                                    left: "25%",
                                    width: "50vw",
                                    height: 70,
                                    backgroundColor: "transparent",
                                    color: "transparent",
                                }}
                            >
                                <AnimatePresence>
                                    {showDelete && (
                                        <m.div
                                            layout
                                            initial={{ opacity: 0, scale: 0 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0 }}
                                            style={{
                                                display: "flex",
                                                backgroundColor: theme.palette.error.main,
                                                color: theme.palette.getContrastText(theme.palette.error.main),
                                                width: "100%",
                                                height: "100%",
                                                borderBottomLeftRadius: 1000,
                                                borderBottomRightRadius: 1000,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                originX: "50%",
                                            }}
                                        >
                                            <Box display='flex' flexDirection='row' alignItems='center' gap={2}>
                                                <DeleteIcon />
                                                <Typography variant='body2'>{translate("drag_to_delete")}</Typography>
                                            </Box>
                                            {provided.placeholder}
                                        </m.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        )}
                    </Droppable>
                )}

                {currentGroup && (currentGroup.summaryCards ?? []).length === 0 && (
                    <CustomAddCard
                        onCardClick={() => {
                            dispatch(setModalOpen(AppModals.DashboardItemsPreview))
                            dispatch(setEditingItem({ itemType: DashboardItemType.SummaryCard }))
                        }}
                        isDesktop={true}
                        text={isMobile ? "add_summary_card_mobile" : "new_summary_card"}
                        icon={"mdi:add-circle-outline"}
                        isAddDisabled={isMobile}
                        marginRight={2}
                    />
                )}

                {!isMobile && currentGroup && (currentGroup.summaryCards ?? []).length > 0 && (
                    <SummaryCardsRow summaryCards={currentGroup.summaryCards} />
                )}
                {currentGroup && (currentGroup.dashboardCharts ?? []).length === 0 && (
                    <CustomAddCard
                        onCardClick={() => {
                            dispatch(setModalOpen(AppModals.DashboardItemsPreview))
                            dispatch(setEditingItem({ itemType: DashboardItemType.Chart }))
                        }}
                        isDesktop={true}
                        text={isMobile ? "add_chart_mobile" : "new_chart"}
                        icon={"mdi:add-circle-outline"}
                        isAddDisabled={isMobile}
                    />
                )}
                {!isMobile && (currentGroup.dashboardCharts ?? []).length > 0 && <ChartsRow charts={currentGroup.dashboardCharts} />}

                <Grid container justifyContent={"space-evenly"} spacing={2}>
                    <>
                        {isMobile && currentGroup && (currentGroup.summaryCards ?? []).length > 0 && (
                            <SummaryCardsRowMobile summaryCards={currentGroup.summaryCards} />
                        )}
                        {isMobile && currentGroup && (currentGroup.dashboardCharts ?? []).length > 0 && (
                            <ChartCardsRowMobile charts={currentGroup.dashboardCharts} />
                        )}
                    </>
                </Grid>
            </DragDropContext>
        </Page>
    )
}
