import { Box, Button, Typography } from "@mui/material"
import { DateTime } from "luxon"
import React from "react"
import { useSelector } from "../../../../redux/store"
import { useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useSingleRentingPeriodQuery } from "../../../../queries/tenants"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { LoadingButton } from "@mui/lab"

export interface DeleteRentingPeriodInitialStepProps {
    onClose: () => void
    onNext: () => void
    noSteps: boolean
    isLoading: boolean
}

const DeleteRentingPeriodInitialStep = ({ onClose, onNext, noSteps, isLoading }: DeleteRentingPeriodInitialStepProps) => {
    const { editingItem } = useSelector((state) => state.appState)
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(editingItem?.rentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()
    const isMobile = useIsMobile()

    return (
        <Box display='flex' flexDirection='column' gap={2} paddingTop={2}>
            <Typography fontSize={15} color={theme.palette.grey.A700} textAlign={"center"} paddingY={2}>
                {translate("delete_renting_period_initial", {
                    date: `${currentRentingPeriod?.moveInDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )} - ${currentRentingPeriod?.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}`,
                    propertyName: properties?.find((property) => property.id === currentRentingPeriod?.propertyId)?.label,
                })}
            </Typography>
            <Box display='flex' justifyContent={isMobile ? "center" : "flex-end"}>
                <Box
                    display='flex'
                    width={isMobile ? "100%" : "max-content"}
                    flexDirection={isMobile ? "column-reverse" : "row"}
                    alignItems='center'
                    gap={2}
                >
                    <Button disabled={isLoading} onClick={onClose} color={"primary"} fullWidth>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton loading={isLoading} disabled={isLoading} onClick={onNext} variant='contained' color={"error"} fullWidth>
                        {translate(noSteps ? "delete" : "next")}
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

export default DeleteRentingPeriodInitialStep
