import { ReactNode } from "react"
import { useManagerRequests, useUserDataQueryFn } from "../queries/userData"
import { useSelector } from "../redux/store"
import { AppContext, LoadingScreen } from "rentzz"
import ManagerNotification from "../sections/manager/ManagerNotification"

type Props = {
    children: ReactNode
}

export default function ManagerNotificationGuard({ children }: Props) {
    const { data: user } = useUserDataQueryFn()
    const { data: managerRequests, isFetching: areManagerRequestsLoading } = useManagerRequests()
    const { context } = useSelector((state) => state.appState)

    if (context === AppContext.Owner && areManagerRequestsLoading) return <LoadingScreen />

    if (user && context === AppContext.Owner && managerRequests && managerRequests.length !== 0) {
        return managerRequests.map((request) => <ManagerNotification key={request.id} request={request} />)
    }

    return <>{children}</>
}
