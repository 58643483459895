import { Box, Button, Typography, useTheme } from "@mui/material"
import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import SignaturePad from "signature_pad"
import { useIsMobile, useLocales } from "rentzz"
import { LoadingButton } from "@mui/lab"

interface Props {
    onSave: (data: string) => void
    onBack: () => void
    defaultValue?: string
    isLoading: boolean
    onNext?: (signature: string) => void
    onClose?: () => void
}
export default function SignatureContent({ onSave, onBack, defaultValue, isLoading, onNext, onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const signaturePad = useRef<SignaturePad | undefined>(undefined)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        if (defaultValue) signaturePad.current?.clear()
    }, [defaultValue])

    useEffect(() => {
        signaturePad.current = new SignaturePad(document.getElementById("signature") as HTMLCanvasElement)
        signaturePad.current?.on()
        return () => signaturePad.current?.off()
    }, [])

    const handleSignatureSave = useCallback(() => {
        if (signaturePad.current == null) return
        if (signaturePad.current?.isEmpty()) return setIsError(true)
        setIsError(false)
        if (onNext) {
            onNext(signaturePad.current.toDataURL())
            onClose?.()
        } else {
            onSave(signaturePad.current.toDataURL())
        }
    }, [onSave, setIsError, onNext])

    const handleSignatureReset = useCallback(() => {
        signaturePad.current?.clear()
    }, [])

    return (
        <Box>
            <Box display='flex' width='100%' height={250} marginTop={4} justifyContent='space-around' flexDirection={"column"} alignItems={"center"}>
                <Box width={300} height={150} sx={{ border: 1, borderColor: theme.palette.primary.main }}>
                    <canvas id={"signature"} width={300} height={150} style={{ width: 300, height: 150 }} />
                </Box>

                {isError && (
                    <Box display={"flex"} justifyContent={"center"} width={"100%"} marginTop={-2}>
                        <Typography color={"error"} fontSize={12}>
                            {translate("request_signature")}
                        </Typography>
                    </Box>
                )}

                <Button sx={{ textTransform: "none" }} onClick={handleSignatureReset} color={"primary"}>
                    {translate("try_again")}
                </Button>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button color={"primary"} onClick={onBack} disabled={isLoading}>
                    {translate("back")}
                </Button>
                <LoadingButton disabled={isLoading} loading={isLoading} color={"primary"} onClick={handleSignatureSave} variant='contained'>
                    {translate(isMobile ? "save" : "send_invitation")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
