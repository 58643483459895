import { LightTooltip, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import ErrorIcon from "@mui/icons-material/Error"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import React from "react"
import { useSingleRentingPeriodQuery } from "../../queries/tenants"
import { useTheme } from "@mui/material/styles"

interface Props {
    rentingPeriodId?: number
}
export default function IncomeInfoButton({ rentingPeriodId }: Props) {
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(rentingPeriodId)

    return (
        <LightTooltip
            sx={{ whiteSpace: "pre-wrap" }}
            arrow
            title={
                <Box display='flex' flexDirection='column'>
                    <Typography variant='body2'>{translate("tenant-data.rentingPeriod")}</Typography>
                    <Typography variant='caption'>
                        {currentRentingPeriod?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} -{" "}
                        {currentRentingPeriod?.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            }
        >
            <ErrorIcon sx={{ color: theme.palette.primary.main, fontSize: DESKTOP_ICON_SIZE }} />
        </LightTooltip>
    )
}
