import { useDispatch, useSelector } from "../../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import React, { SyntheticEvent, useCallback, useMemo } from "react"
import SyncIcon from "@mui/icons-material/Sync"
import { InvitedManagerStatus, Manager, ManagerAcceptanceStatus } from "../../../../../redux/slices/AddProperty"
import { MOBILE_ICON_SIZE } from "../../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { NewInvitedManager, usePropertyDetailsQueryFn } from "../../../../../queries"
import { getInvitedManagerColor, getInvitedManagerIcon, getManagerAcceptanceAccountColor, getManagerAcceptanceIcon } from "../../utils"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useUserDataQueryFn } from "../../../../../queries/userData"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

export default function ManagerDetailsSheet() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: propertyDetails, isLoading: arePropertyDetailsLoading } = usePropertyDetailsQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canAddManager } = usePermissions(PermissionType.Properties, currentPropertyId)

    const currentManager = useMemo(
        () => propertyDetails?.finalManagers.find((manager) => manager.mail === editingItem?.mail),
        [propertyDetails, editingItem],
    )

    const getTooltipDeleteTitle = useCallback(
        (manager: Manager | NewInvitedManager) => {
            if (userData?.email === manager.mail) return "can_not_delete_you"
            if (!manager.canDelete) return "can_not_delete_property_creator"
            return "delete"
        },
        [userData],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentManager == null) return []
        return [
            {
                icon: (
                    <SyncIcon
                        color={currentManager.status === ManagerAcceptanceStatus.ACCEPTED ? "disabled" : "primary"}
                        sx={{ fontSize: MOBILE_ICON_SIZE }}
                    />
                ),
                title: "resend",
                isDisabled: currentManager.status === ManagerAcceptanceStatus.ACCEPTED,
                onClick: () => dispatch(setModalOpen(AppModals.ResendManagerInvitation)),
                hidden: !canAddManager,
            },
            {
                icon: <DeleteIcon color={currentManager.canDelete ? "error" : "inherit"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: getTooltipDeleteTitle(currentManager),
                onClick: () => dispatch(setModalOpen(AppModals.RemoveManagerInvitation)),
                hidden: !canAddManager,
                isDisabled: !currentManager.canDelete,
            },
        ]
    }, [currentManager, dispatch, canAddManager])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ManagerDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={arePropertyDetailsLoading}
            title={currentManager?.name === "-" ? currentManager?.mail : currentManager?.name}
            subtitle={currentManager?.name === "-" ? "" : currentManager?.mail}
            options={options}
            headerColor={
                currentManager?.name === "-"
                    ? getInvitedManagerColor(currentManager?.status as InvitedManagerStatus, theme)
                    : getManagerAcceptanceAccountColor(currentManager?.status as ManagerAcceptanceStatus, theme)
            }
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    `${
                        currentManager?.name === "-"
                            ? getInvitedManagerIcon(currentManager?.status as InvitedManagerStatus)
                            : getManagerAcceptanceIcon(currentManager?.status as ManagerAcceptanceStatus)
                    }`,
                    `${translate(
                        currentManager?.name === "-"
                            ? `mail_notification_status_tooltip.${currentManager?.status}`
                            : `user_acceptance_status_tooltip.${currentManager?.status}`,
                    )}`,
                    currentManager?.name === "-"
                        ? getInvitedManagerColor(currentManager?.status as InvitedManagerStatus, theme)
                        : getManagerAcceptanceAccountColor(currentManager?.status as ManagerAcceptanceStatus, theme),
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("last_invitation")}: ${currentManager?.date.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {currentManager?.status === ManagerAcceptanceStatus.ACCEPTED &&
                    renderActionSheetInfo("mdi:sync", translate("accepted_invitation"), theme.palette.primary.main, theme.palette.grey["600"])}
                {currentManager?.status === ManagerAcceptanceStatus.ACCEPTED &&
                    renderActionSheetInfo(
                        "mdi:trash-can",
                        translate("can_not_delete_invitation_owner.accepted"),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
