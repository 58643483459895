import AddInsurance, { AddInsuranceRequest, UpdateInsuranceRequest } from "../../../sections/insurance/AddInsurance"
import { AppModals } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useSelector } from "../../../redux/store"
import * as jsonpatch from "fast-json-patch"
import { addInsuranceMutation, updateInsuranceMutation } from "../../../mutations/property/insurance"
import { getInsuranceByIdQueryFn } from "../../../queries"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import AddFormInsurance from "../../../sections/insurance/AddFormInsurance"
import { InsuranceType } from "../../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"

interface AddInsuranceGuardProps {
    onClose: () => void
}
export default function AddOrEditInsuranceGuard({ onClose }: AddInsuranceGuardProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addInsurance, isPending: isAddInsuranceLoading } = addInsuranceMutation()
    const { mutateAsync: editInsurance, isPending: isEditInsuranceLoading } = updateInsuranceMutation()
    const { data: currentInsurance } = getInsuranceByIdQueryFn(editingItem?.id)

    const onAddInsuranceSave = useCallback(
        async (data: AddInsuranceRequest | UpdateInsuranceRequest) => {
            if (currentInsurance == null) {
                await addInsurance({ data: data as AddInsuranceRequest })
            } else {
                if (!data.id) return
                const operations = jsonpatch.compare(
                    { ...currentInsurance, startDate: currentInsurance?.startDate.toISO(), endDate: currentInsurance?.endDate.toISO(), files: [] },
                    { ...(data as UpdateInsuranceRequest), files: [] },
                )
                await editInsurance({ insuranceId: data.id, propertyId: data.propertyId, operations })
            }
        },
        [currentInsurance, addInsurance, editInsurance],
    )

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddInsurance || modalOpen === AppModals.EditInsurance)}
                onClose={onClose}
                isLoading={isAddInsuranceLoading || isEditInsuranceLoading}
                headerColor={
                    currentInsurance
                        ? currentInsurance?.insuranceType === InsuranceType.Mandatory
                            ? theme.palette.warning.main
                            : theme.palette.info.main
                        : theme.palette.primary.main
                }
                title={translate(modalOpen === AppModals.EditInsurance ? "insurance.editInsurance" : "insurance.addInsurance")}
            >
                <AddFormInsurance
                    onClose={onClose}
                    onSave={onAddInsuranceSave}
                    isLoading={isAddInsuranceLoading || isEditInsuranceLoading}
                    defaultValues={currentInsurance}
                />
            </DetailsSheet>

            <AddInsurance
                open={!isMobile && (modalOpen === AppModals.AddInsurance || modalOpen === AppModals.EditInsurance)}
                onClose={onClose}
                onSave={onAddInsuranceSave}
                isLoading={isAddInsuranceLoading || isEditInsuranceLoading}
                defaultValues={currentInsurance}
            />
        </>
    )
}
