import { CustomDialog, useLocales } from "rentzz"
import React from "react"
import { AddMessageRequest } from "./types"
import SendMessageForm from "./SendMessageForm"
import AgencyContactPage from "./AgencyContactPage"
import { UserFlags } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface AddExpenseProps {
    isVisible: boolean
    onClose: () => void
    onSave: (data: AddMessageRequest) => Promise<void>
    isSendMessageLoading: boolean
}

const SendMessageDialog = ({ isVisible, onClose, onSave, isSendMessageLoading }: AddExpenseProps) => {
    const { translate } = useLocales()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    return (
        <CustomDialog
            title={translate("support.contact")}
            open={isVisible}
            onClose={onClose}
            maxWidth={"sm"}
            titleAlign={"center"}
            isFullScreen={false}
        >
            {isWhitelabel ? (
                <AgencyContactPage />
            ) : (
                <SendMessageForm onSave={onSave} isSendingMessageLoading={isSendMessageLoading} onClose={onClose} />
            )}
        </CustomDialog>
    )
}

export default SendMessageDialog
