import CustomCard from "./CustomCard"
import { Avatar, Box, Typography } from "@mui/material"
import { Iconify, Label, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { ConfiguredExpenseProvider, ProviderStatus, ProviderType } from "../redux/slices/AddProperty"
import React, { Fragment, useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { DateTime } from "luxon"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"
import { getAssigneeLabelColor } from "../sections/expenses/utils"
import { MOBILE_ICON_SIZE } from "../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../redux/slices/App"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useDispatch, useSelector } from "../redux/store"
import ProviderPaymentSyncForm from "../sections/propertyDetails/configurations/providers/ProviderPaymentSyncForm"
import ProviderSendAutomaticallyForm from "../sections/propertyDetails/configurations/providers/ProviderSendAutomaticallyForm"
import { stringAvatarWithTheme } from "../sections/kanban/utils"
import InfoIcon from "@mui/icons-material/Info"

interface Props {
    onCardClick?: () => void
    provider: ConfiguredExpenseProvider
    isEditable?: boolean
}
export default function CustomProviderCard({ onCardClick, provider, isEditable = true }: Props) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)
    const { currentPropertyId } = useSelector((state) => state.appState)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { canDelete } = usePermissions(PermissionType.Properties, currentPropertyId)

    const getLabelColor = useCallback(
        (status: ProviderStatus) => {
            if (status === ProviderStatus.Pending) return "warning"
            if (status === ProviderStatus.Rejected) return "error"
            return "success"
        },
        [translate],
    )

    return (
        <CustomCard onClick={onCardClick} isCardClickable={!!onCardClick} px={1} py={isMobile ? 1 : 2} noHover={true}>
            {!isMobile && (
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {`${translate("lastExpenseDate")}: ${
                            provider.lastExpenseCreationDate.year === 1
                                ? translate("no_expense_added")
                                : provider.lastExpenseCreationDate.toLocaleString(DateTime.DATE_SHORT)
                        }`}
                    </Typography>
                    <Box display={"flex"} gap={1}>
                        {!isMobile && (
                            <LightTooltip
                                title={
                                    <Box display='flex' flexDirection='column'>
                                        {provider.fields.map((f) => {
                                            if (f.name === "assignee") return
                                            return (
                                                <Fragment key={f.name}>
                                                    <Typography variant={"body2"} fontWeight='bold'>
                                                        {translate(f.label)}
                                                    </Typography>
                                                    <Typography variant={"body2"} sx={{ maxWidth: 230 }}>
                                                        {f.value}
                                                    </Typography>
                                                </Fragment>
                                            )
                                        })}
                                    </Box>
                                }
                            >
                                <InfoIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"info"} />
                            </LightTooltip>
                        )}
                        {canDelete && (
                            <Box display={"flex"} gap={1}>
                                <Iconify
                                    icon={"mdi:delete"}
                                    sx={{ fontSize: MOBILE_ICON_SIZE, color: theme.palette.error.main, cursor: "pointer" }}
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.DeleteProvider))
                                        dispatch(setEditingItem({ id: provider.id, name: provider.providerName, providerType: ProviderType.Expense }))
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2} alignItems={"center"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} width={"100%"}>
                    {isMobile && <Avatar src={""} {...stringAvatarWithTheme(provider.providerName, theme)} alt={""} />}

                    <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
                        <Box display={"flex"} flexDirection={"row"} gap={1}>
                            <Typography
                                fontWeight={"bold"}
                                fontSize={14}
                                color={theme.palette.grey.A700}
                                maxWidth={160}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                            >
                                {provider.providerName}
                            </Typography>
                            {!isMobile && (
                                <>
                                    <Label color={getAssigneeLabelColor(provider.assignee)} style={{ textTransform: "none" }}>
                                        {translate(`expenses.assignee-${provider.assignee}${customPaidBy ? "-" + customPaidBy : ""}`)}
                                    </Label>
                                    <Label color={getLabelColor(provider.status)} style={{ textTransform: "none" }}>
                                        {translate(translate(`rejected_provider_label-${provider.status}`))}
                                    </Label>
                                </>
                            )}
                        </Box>
                        {!isMobile && isEditable && (
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                {isWhitelabel && <ProviderPaymentSyncForm providerId={provider.id} />}
                                <ProviderSendAutomaticallyForm providerId={provider.id} />
                            </Box>
                        )}
                    </Box>
                </Box>
                {isMobile && (
                    <Label color={getLabelColor(provider.status)} style={{ textTransform: "none" }}>
                        {translate(translate(`rejected_provider_label-${provider.status}`))}
                    </Label>
                )}
            </Box>
        </CustomCard>
    )
}
