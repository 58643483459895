import { useIsMobile } from "rentzz"
import DesktopGroupPropertiesPage from "./DesktopGroupPropertiesPage"
import MobileGroupPropertiesPage from "./MobileGroupPropertiesPage"

export default function GroupPropertiesPage() {
    const isMobile = useIsMobile()

    if (isMobile) return <MobileGroupPropertiesPage />
    return <DesktopGroupPropertiesPage />
}
