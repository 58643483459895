import { Box, IconButton, Typography } from "@mui/material"
import { RentingPeriod } from "../../../../redux/slices/AddProperty"
import { Iconify, Label, useLocales } from "rentzz"
import CustomCard from "../../../../components/CustomCard"
import React from "react"
import { useTheme } from "@mui/material/styles"
import { UserFlags, useUserPropertiesQuery } from "../../../../queries/userData"
import { getAccountBackgroundColor, getAccountColor, getLabelColor, getUserIcon } from "../utils"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import DeleteIcon from "@mui/icons-material/Delete"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface TenantCustomCardProps {
    rentingPeriod: RentingPeriod & { canWrite: boolean }
    currentPropertyId?: number
    getAmount: (value: number, currencyId?: number) => string
}

export default function RentingPeriodCard({ rentingPeriod, getAmount }: TenantCustomCardProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const canDeleteRentingPeriod = useFeatureIsOn(UserFlags.CanDeleteRentingPeriod.toString())

    return (
        <CustomCard
            key={rentingPeriod.id}
            onClick={(e) => {
                e.stopPropagation()
                if (currentPropertyId) {
                    navigate(`/properties/${currentPropertyId}/tenants/${rentingPeriod.id}/general`)
                } else {
                    navigate(`/tenants/${rentingPeriod.id}/general`)
                }
            }}
            isCardClickable={false}
            px={1}
            py={1}
        >
            <Box display={"flex"} justifyContent={"space-between"}>
                <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }} alignSelf={"center"}>
                    {`${rentingPeriod.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${rentingPeriod.moveOutDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )}`}
                </Typography>
                {rentingPeriod.canWrite && canDeleteRentingPeriod && (
                    <IconButton
                        color={"error"}
                        onClick={async (event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation()
                            event.preventDefault()
                            dispatch(setModalOpen(AppModals.DeleteTenant))
                            dispatch(setEditingItem({ rentingPeriodId: rentingPeriod.id, propertyId: rentingPeriod.propertyId }))
                        }}
                    >
                        <DeleteIcon sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    </IconButton>
                )}
            </Box>

            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Box
                        width={35}
                        height={35}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{
                            borderWidth: 1,
                            borderColor: getAccountBackgroundColor(rentingPeriod.rentingPeriodStatus, theme),
                            borderStyle: "solid",
                            borderRadius: 50,
                            backgroundColor: getAccountBackgroundColor(rentingPeriod.rentingPeriodStatus, theme),
                        }}
                    >
                        <Iconify
                            icon={getUserIcon(rentingPeriod.rentingPeriodStatus)}
                            style={{
                                color: getAccountColor(rentingPeriod.rentingPeriodStatus, theme),
                            }}
                        />
                    </Box>

                    <Box display={"flex"} flexDirection={"column"} maxWidth={170}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {userProperties?.find((p) => p.id === rentingPeriod.propertyId)?.label}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                        {getAmount(rentingPeriod.value, rentingPeriod.currencyId)}
                    </Typography>
                    <Label color={getLabelColor(rentingPeriod.rentingPeriodStatus)}>
                        {translate(`rentingPeriodStatus.${rentingPeriod.rentingPeriodStatus}`)}
                    </Label>
                </Box>
            </Box>
        </CustomCard>
    )
}
