import { useIntl } from "react-intl"
import { alpha, useTheme } from "@mui/material/styles"
import { Iconify } from "rentzz"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import React, { useCallback } from "react"
import CustomCard from "../../../components/CustomCard"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { PropertyExpense } from "../../../queries/expenses"

interface Props {
    expense: PropertyExpense
    onClick: () => void
}
export default function AssignedExpenseCard({ expense, onClick }: Props) {
    const intl = useIntl()
    const theme = useTheme()
    const { data: currencyData } = useCurrencyQueryFn()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    return (
        <CustomCard key={expense.id} onClick={onClick} isCardClickable={false} px={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:tag-multiple"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(theme.palette.warning.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: theme.palette.warning.main,
                            backgroundColor: alpha(theme.palette.warning.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {expense.name}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"}>
                    <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                        {getAmount(expense.value, expense.currencyId)}
                    </Typography>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {expense.date.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
