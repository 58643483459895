import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { deletePropertyFromBankAccountMutation } from "../../../../../mutations/user"
import { useUserPropertiesQuery } from "../../../../../queries/userData"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"

export default function DeletePropertyFromBankAccount() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteProperty, isPending: isDeleteProperty } = deletePropertyFromBankAccountMutation()
    const { data: properties } = useUserPropertiesQuery()

    const currentProperty = useMemo(() => properties?.find((property) => property.id === editingItem?.propertyId), [properties, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.BankAccountProperties))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentProperty == null) return

        await deleteProperty({
            bankAccountId: editingItem.bankAccountId,
            propertyId: currentProperty.id,
        })
        onClose()
    }, [currentProperty, deleteProperty, dispatch, onClose])

    return (
        <>
            <CustomDialog
                title={translate("delete")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeletePropertyFromBankAccount}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_property_text", { propertyName: currentProperty?.label })}
                    isLoading={isDeleteProperty}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeletePropertyFromBankAccount}
                onClose={onClose}
                isLoading={false}
                title={translate("delete")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_property_text", { propertyName: currentProperty?.label })}
                    isLoading={isDeleteProperty}
                />
            </DetailsSheet>
        </>
    )
}
