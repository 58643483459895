import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import * as React from "react"
import { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import AddTaskForm from "../../../sections/kanban/AddTaskForm"

export default function AddTaskGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.AddTask}
                onClose={onClose}
                isLoading={false}
                title={translate("add_task")}
                headerColor={theme.palette.primary.main}
            >
                <AddTaskForm onClose={onClose} />
            </DetailsSheet>
            <CustomDialog
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.AddTask}
                title={translate("add_task")}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddTaskForm onClose={onClose} />
            </CustomDialog>
        </>
    )
}
