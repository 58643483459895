import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Box, Theme } from "@mui/material"
import AddMeterForm from "../../../sections/propertyDetails/Meters/AddMeterForm"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useMetersQueryFn } from "../../../queries/meters"
import { OwnerPropertyMeter } from "../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { useTheme } from "@mui/material/styles"

export const headerMeterSheetColor = (lastModified: DateTime | undefined, theme: Theme, areDefaultValues: boolean) => {
    if (!areDefaultValues) return theme.palette.primary.main
    if (lastModified?.month === DateTime.now().month) return theme.palette.success.main
    return theme.palette.warning.main
}
export default function AddOrEditMeterGuard() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()

    const currentMeter = useMemo(() => (meters as OwnerPropertyMeter[])?.find((meter) => meter?.id === editingItem?.id), [meters, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            if (currentMeter) {
                dispatch(setModalOpen(AppModals.MeterDetails))
            } else {
                dispatch(setModalOpen(undefined))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile, currentMeter])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddMeter || modalOpen === AppModals.EditMeter)}
                onClose={onClose}
                isLoading={currentMeter == null && modalOpen === AppModals.EditMeter}
                title={translate(modalOpen === AppModals.AddMeter ? "add_meter" : "edit_meter")}
                headerColor={headerMeterSheetColor(currentMeter?.lastModified, theme, !!currentMeter)}
            >
                <Box sx={{ overflowY: "auto", maxHeight: 680 }}>
                    <Box p={2}>
                        <AddMeterForm onClose={onClose} defaultValues={currentMeter} />
                    </Box>
                </Box>
            </DetailsSheet>
            <CustomDialog
                onClose={onClose}
                open={!isMobile && (modalOpen === AppModals.AddMeter || modalOpen === AppModals.EditMeter)}
                title={translate(modalOpen === AppModals.AddMeter ? "add_meter" : "edit_meter")}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <Box paddingTop={2} sx={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }} marginTop={0}>
                    <AddMeterForm onClose={onClose} defaultValues={currentMeter} />
                </Box>
            </CustomDialog>
        </>
    )
}
