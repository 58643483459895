import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { Box } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { IncomeStatus, PropertyFileType, PropertyIncomePaymentMethod } from "../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { getIncomeTitleStatus } from "../../../sections/income/utils"
import {
    getIncomeLabelColorByPaymentMethod,
    getIncomeLabelColorByStatus,
    getIncomeOrPaymentStatusColor,
    getIncomeOrPaymentStatusIcon,
    MOBILE_ICON_SIZE,
} from "../../../utils/helpers"
import { renderActionSheetInfo } from "../utils"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { useSingleIncomeQuery } from "../../../queries/income"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteIcon from "@mui/icons-material/Delete"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { useSingleRentingPeriodQuery } from "../../../queries/tenants"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

interface IncomeActionSheetProps {
    onClose: () => void
}
export default function IncomeDetailsSheet({ onClose }: IncomeActionSheetProps) {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const { data: currentIncome } = useSingleIncomeQuery(editingItem?.isFromAnExpense ? editingItem?.incomeId : editingItem?.id)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(currentIncome?.rentingPeriodId)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentIncome == null) return []

        return [
            {
                icon: <ErrorOutlineIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "verify",
                hidden: !currentIncome.canWrite || currentIncome.status !== IncomeStatus.Waiting,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.HandleIncomeStatus))
                },
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_expenses",
                hidden: editingItem?.isFromAnExpense,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AssignedExpenses))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !currentIncome.canWrite || editingItem?.isFromAnExpense || currentIncome.paymentMethod === PropertyIncomePaymentMethod.Online,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditIncome))
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: editingItem?.isFromAnExpense ? editingItem.id : currentIncome.id,
                            type: PropertyFileType.Income,
                            name: currentIncome.type,
                            status: currentIncome.status,
                            canWrite: currentIncome.canWrite,
                            isFromAnExpense: editingItem?.isFromAnExpense,
                            incomeId: editingItem?.incomeId,
                            backgroundColor: editingItem?.backgroundColor,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentIncome.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteIncome))
                },
            },
        ]
    }, [currentIncome, dispatch, theme, editingItem])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.IncomeDetails}
            onClose={onClose}
            isLoading={currentIncome == null}
            title={translate(`income-table.incomeType-${currentIncome?.type}`)}
            options={options}
            headerColor={currentIncome ? getIncomeOrPaymentStatusColor(currentIncome.status, theme) : theme.palette.primary.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:cash",
                    `${getAmount(currentIncome?.value ?? 0, currentIncome?.currencyId)}   `,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    translate(`income-table.paymentMethod-${currentIncome?.paymentMethod}`),
                    currentIncome ? getIncomeLabelColorByPaymentMethod(currentIncome.paymentMethod) : "default",
                )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    properties?.find((p) => p.id === currentIncome?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-clock",
                    `${translate("tenant-data.rentingPeriod")}: ${currentRentingPeriod?.moveInDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )} - ${currentRentingPeriod?.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    currentIncome?.date?.toLocaleString(DateTime.DATE_SHORT),
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {currentIncome == null
                    ? ""
                    : renderActionSheetInfo(
                          getIncomeOrPaymentStatusIcon(currentIncome?.status),
                          translate(getIncomeTitleStatus(currentIncome?.status)),
                          theme.palette.primary.main,
                          theme.palette.grey["600"],
                          true,
                          "",
                          getIncomeLabelColorByStatus(currentIncome.status),
                      )}
            </Box>
        </DetailsSheet>
    )
}
