import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { DateTime } from "luxon"
import {
    closeSnackbar,
    enqueueSnackbar,
    ExpenseAssignee,
    FormProvider,
    Label,
    LightTooltip,
    RHFDateRangePicker,
    RHFRadioGroup,
    RHFSelect,
    useIsMobile,
    useLocales,
} from "rentzz"
import React, { useCallback, useEffect, useState } from "react"
import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import { UserFlags, useUserLabelsQuery, useUserPropertiesQuery } from "../../../queries/userData"
import { getReverseTextColor } from "../../../utils/helpers"
import { useDispatch, useSelector } from "../../../redux/store"
import { exportData, ExportType } from "../../../utils/actions"
import { LoadingButton } from "@mui/lab"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { ExportBalanceSchema, ExportExpenseSchema, ExportIncomeSchema, ExportReadingSchema } from "../../../validations/export-expense-schema"
import { ObjectSchema } from "yup"
import { PaymentType } from "../../../redux/slices/AddProperty"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { nanoid } from "@reduxjs/toolkit"
import PropertySelector from "./PropertySelector"
import { useFeatureValue } from "@growthbook/growthbook-react"

export interface ExportRequest {
    from: string
    to: string
}

export interface ExportExpensesRequest extends ExportRequest {
    rentingPeriodId?: number
    labelId: number
    propertyId: number
    expenseFilterOption: ExpenseFilterOption
    assignee: ExpenseAssignee
}

enum ExpenseFilterOption {
    All,
    Unpaid,
    PartiallyPaid,
    OverPaid,
    Paid,
}

export interface ExportIncomeRequest extends ExportRequest {
    rentingPeriodId?: number
    incomeType?: number
    propertyId: number
}

export interface ExportReadingRequest extends ExportRequest {
    meterId: number
    rentingPeriodId?: number
    propertyId: number
}

export interface ExportBalanceRequest extends ExportRequest {
    rentingPeriodId: number
    format: number
}

export interface ExportFormProps {
    onSubmit?: (result: unknown) => void
    type: ExportType
}

const ExportForm = ({ onSubmit, type }: ExportFormProps) => {
    const isMobile = useIsMobile()
    const { data: userLabels } = useUserLabelsQuery()
    const { translate } = useLocales()
    const defaultAssignee = useFeatureValue<ExpenseAssignee>(UserFlags.DefaultAssignee, ExpenseAssignee.ME)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)
    const { currentPropertyId, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: userProperties } = useUserPropertiesQuery()
    const dispatch = useDispatch()
    const [selectedPropertyId, setSelectedPropertyId] = useState<number | undefined>(
        currentPropertyId ?? rentingPeriodDetails?.propertyId ?? editingItem?.propertyId ?? -1,
    )

    const ExportSchemaResolver = useCallback(
        (type: ExportType): ObjectSchema<ExportExpensesRequest | ExportIncomeRequest | ExportReadingRequest | ExportBalanceRequest> => {
            switch (type) {
                case ExportType.Expense:
                    return ExportExpenseSchema()
                case ExportType.Income:
                    return ExportIncomeSchema()
                case ExportType.Reading:
                    return ExportReadingSchema()
                case ExportType.Balance:
                    return ExportBalanceSchema()
            }
        },
        [],
    )

    const methods = useForm<ExportExpensesRequest | ExportIncomeRequest | ExportReadingRequest | ExportBalanceRequest>({
        resolver: yupResolver(ExportSchemaResolver(type)),
        mode: "onBlur",
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
        reset,
    } = methods

    useEffect(() => {
        if (type === ExportType.Expense) {
            reset({
                from: DateTime.now().minus({ year: 1 }).toISODate(),
                to: DateTime.now().toISODate(),
                labelId: -1,
                propertyId: currentPropertyId ?? rentingPeriodDetails?.propertyId ?? -1,
                rentingPeriodId: currentRentingPeriodId ?? -1,
                expenseFilterOption: ExpenseFilterOption.All,
                assignee: currentRentingPeriodId != null ? ExpenseAssignee.TENANT : defaultAssignee,
            })
        }
        if (type === ExportType.Income) {
            reset({
                from: DateTime.now().minus({ year: 1 }).toISODate(),
                to: DateTime.now().toISODate(),
                propertyId: currentPropertyId ?? rentingPeriodDetails?.propertyId ?? -1,
                rentingPeriodId: currentRentingPeriodId ?? -1,
                incomeType: -1,
            })
        }

        if (type === ExportType.Reading) {
            reset({
                from: DateTime.now().minus({ year: 1 }).toISODate(),
                to: DateTime.now().toISODate(),
                propertyId: currentPropertyId,
                rentingPeriodId: -1,
                meterId: editingItem?.id,
            })
        }

        if (type === ExportType.Balance) {
            const now = DateTime.now()
            const endDate = rentingPeriodDetails?.moveOutDate ?? DateTime.now()
            reset({
                from: rentingPeriodDetails?.moveInDate.toISODate() ?? DateTime.now().minus({ year: 1 }).toISODate(),
                to: (endDate > now ? now : endDate).toISODate()!,
                rentingPeriodId: currentRentingPeriodId ?? editingItem?.currentRentingPeriodId ?? -1,
            })
        }
    }, [type, reset, currentRentingPeriodId, currentPropertyId, rentingPeriodDetails, editingItem, defaultAssignee])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "propertyId" && "propertyId" in v) {
                setSelectedPropertyId(Number(v.propertyId))
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, setSelectedPropertyId])

    const handleExport = useCallback(
        async (data: ExportExpensesRequest | ExportIncomeRequest | ExportReadingRequest | ExportBalanceRequest) => {
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            let result: unknown
            switch (type) {
                case ExportType.Expense:
                    result = await exportData(data as ExportExpensesRequest, ExportType.Expense)
                    closeSnackbar(key)
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            file: new File([new Blob([result as BlobPart])], `${nanoid()}.xlsx`),
                        }),
                    )
                    dispatch(setModalOpen(AppModals.ExcelViewer))
                    break
                case ExportType.Income:
                    result = await exportData(
                        {
                            ...(data as ExportIncomeRequest),
                            incomeType: (data as ExportIncomeRequest).incomeType === -1 ? undefined : (data as ExportIncomeRequest).incomeType,
                        },
                        ExportType.Income,
                    )
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            file: new File([new Blob([result as BlobPart])], `${nanoid()}.xlsx`),
                        }),
                    )
                    dispatch(setModalOpen(AppModals.ExcelViewer))
                    closeSnackbar(key)
                    break
                case ExportType.Reading:
                    result = await exportData(data as ExportReadingRequest, ExportType.Reading)
                    closeSnackbar(key)
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            file: new File([new Blob([result as BlobPart])], `${nanoid()}.xlsx`),
                        }),
                    )
                    dispatch(setModalOpen(AppModals.ExcelViewer))
                    break
                case ExportType.Balance: {
                    result = await exportData(data as ExportBalanceRequest, ExportType.Balance)
                    const format = (data as ExportBalanceRequest).format
                    closeSnackbar(key)

                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            url: window.URL.createObjectURL(new Blob([result as BlobPart]))!,
                            file: format === 0 ? new File([new Blob([result as BlobPart])], `${nanoid()}.xlsx`) : undefined,
                        }),
                    )
                    if (format === 1) dispatch(setModalOpen(AppModals.PdfViewer))
                    if (format === 0) dispatch(setModalOpen(AppModals.ExcelViewer))
                    break
                }
            }

            onSubmit?.(result)
        },
        [onSubmit, type],
    )

    if (userLabels == null) return <></>
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleExport)}>
            <Box
                width={isMobile || editingItem?.isFromTenantsTable ? undefined : 400}
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection='column'
                gap={2}
            >
                <RHFDateRangePicker fromName={"from"} toName={"to"} />
                {type === ExportType.Expense && (
                    <RHFSelect name={"labelId"} size='small' label={translate("labelDescription")}>
                        <MenuItem
                            key={-1}
                            value={-1}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate("all_labels")}
                        </MenuItem>
                        {userLabels ? (
                            userLabels.map((label) => (
                                <MenuItem
                                    key={label.id}
                                    value={label.id}
                                    sx={{
                                        textTransform: "none",
                                        justifyContent: "center",
                                        ":hover": { backgroundColor: "transparent" },
                                    }}
                                >
                                    <Label
                                        style={{ width: "90%" }}
                                        sx={{
                                            textTransform: "none",
                                            backgroundColor: `#${label.color}`,
                                            color: getReverseTextColor(label.color),
                                            cursor: "pointer",
                                            transition: "all 0.3s ease 0s",
                                            ":hover": {
                                                transform: "scale(1.03)",
                                            },
                                        }}
                                    >
                                        {label.value}
                                    </Label>
                                </MenuItem>
                            ))
                        ) : (
                            <CircularProgress />
                        )}
                    </RHFSelect>
                )}
                {type === ExportType.Expense && (
                    <RHFSelect name={"assignee"} size='small' label={translate("expenses.assignee")}>
                        <MenuItem
                            key={ExpenseAssignee.ME}
                            value={ExpenseAssignee.ME}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expenses.assignee-${ExpenseAssignee.ME}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </MenuItem>
                        <MenuItem
                            key={ExpenseAssignee.TENANT}
                            value={ExpenseAssignee.TENANT}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expenses.assignee-${ExpenseAssignee.TENANT}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </MenuItem>
                    </RHFSelect>
                )}
                {type === ExportType.Expense && (
                    <RHFSelect name={"expenseFilterOption"} size='small' label={translate("expense_filter_option")}>
                        <MenuItem
                            key={ExpenseFilterOption.All}
                            value={ExpenseFilterOption.All}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expense_filter_option-${ExpenseFilterOption.All}`)}
                        </MenuItem>
                        <MenuItem
                            key={ExpenseFilterOption.Unpaid}
                            value={ExpenseFilterOption.Unpaid}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expense_filter_option-${ExpenseFilterOption.Unpaid}`)}
                        </MenuItem>
                        <MenuItem
                            key={ExpenseFilterOption.PartiallyPaid}
                            value={ExpenseFilterOption.PartiallyPaid}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expense_filter_option-${ExpenseFilterOption.PartiallyPaid}`)}
                        </MenuItem>
                        <MenuItem
                            key={ExpenseFilterOption.OverPaid}
                            value={ExpenseFilterOption.OverPaid}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expense_filter_option-${ExpenseFilterOption.OverPaid}`)}
                        </MenuItem>
                        <MenuItem
                            key={ExpenseFilterOption.Paid}
                            value={ExpenseFilterOption.Paid}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`expense_filter_option-${ExpenseFilterOption.Paid}`)}
                        </MenuItem>
                    </RHFSelect>
                )}

                {type === ExportType.Income && (
                    <RHFSelect name={"incomeType"} size='small' label={translate("income_type")}>
                        <MenuItem
                            key={-1}
                            value={-1}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate("all_incomes")}
                        </MenuItem>

                        <MenuItem
                            key={PaymentType.Rent}
                            value={PaymentType.Rent}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`income-table.incomeType-${PaymentType.Rent}`)}
                        </MenuItem>
                        <MenuItem
                            key={PaymentType.SecurityDeposit}
                            value={PaymentType.SecurityDeposit}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`income-table.incomeType-${PaymentType.SecurityDeposit}`)}
                        </MenuItem>
                        <MenuItem
                            key={PaymentType.Fees}
                            value={PaymentType.Fees}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`income-table.incomeType-${PaymentType.Fees}`)}
                        </MenuItem>
                        <MenuItem
                            key={PaymentType.Damages}
                            value={PaymentType.Damages}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`income-table.incomeType-${PaymentType.Damages}`)}
                        </MenuItem>
                        <MenuItem
                            key={PaymentType.Expense}
                            value={PaymentType.Expense}
                            sx={{
                                textTransform: "none",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            {translate(`income-table.incomeType-${PaymentType.Expense}`)}
                        </MenuItem>
                    </RHFSelect>
                )}

                {type !== ExportType.Balance && (
                    <LightTooltip title={currentPropertyId != null || currentRentingPeriodId != null ? translate("can_not_edit_field") : ""} arrow>
                        <span style={{ width: "100%" }}>
                            <RHFSelect
                                name={"propertyId"}
                                size='small'
                                disabled={currentPropertyId != null || currentRentingPeriodId != null}
                                label={translate("property")}
                                required={currentPropertyId == null}
                            >
                                <MenuItem
                                    key={"-1"}
                                    value={"-1"}
                                    sx={{
                                        textTransform: "none",
                                        ":hover": { backgroundColor: "transparent" },
                                    }}
                                >
                                    {translate("all_properties")}
                                </MenuItem>
                                {userProperties ? (
                                    userProperties.map((property) => (
                                        <MenuItem key={property.id} value={property.id} style={{ textTransform: "none" }}>
                                            {property.label}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <CircularProgress />
                                )}
                            </RHFSelect>
                        </span>
                    </LightTooltip>
                )}
                {selectedPropertyId !== -1 && <PropertySelector propertyId={selectedPropertyId} />}
                {type === ExportType.Balance && (
                    <RHFRadioGroup
                        name='format'
                        options={[
                            {
                                label: "Excel",
                                value: 0,
                                moreInfo: "",
                            },
                            { label: "PDF", value: 1, moreInfo: "" },
                        ]}
                    />
                )}
                <Box
                    display={"flex"}
                    gap={1}
                    width={"100%"}
                    justifyContent={editingItem?.isFromTenantsTable ? "flex-end" : undefined}
                    flexDirection={isMobile ? "column-reverse" : "row"}
                >
                    {!isMobile && (
                        <Button color={"primary"} onClick={onSubmit} fullWidth={!editingItem?.isFromTenantsTable}>
                            {translate("cancel")}
                        </Button>
                    )}

                    <LoadingButton
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type='submit'
                        fullWidth={!editingItem?.isFromTenantsTable}
                        variant='contained'
                        color='primary'
                    >
                        {translate("export_data")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default ExportForm
