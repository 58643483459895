import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { useSelector } from "../redux/store"
import { AppContext, useIsMobile } from "rentzz"
import { InvitedManagerStatus, ManagerAcceptanceStatus, PropertyInsurance, PropertyInsuranceResponse } from "../redux/slices/AddProperty"
import { sum } from "lodash"
import { GridSortModel } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { UserFlags, useUserPropertiesQuery } from "./userData"
import { AppModals } from "../redux/slices/App"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export interface PresentationFile {
    id: string
    position: number
}
export interface NewInvitedManager {
    name: string
    mail: string
    ownershipType: ManagerType
    date: DateTime
    canDelete: boolean
    status: InvitedManagerStatus | ManagerAcceptanceStatus
}
export const usePropertyDetailsQueryFn = (selectedPropertyId?: number) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.Property, { id: currentPropertyId || selectedPropertyId }],
        queryFn: Api.fetchPropertyDetails,
        staleTime: Infinity,
        enabled: (!!currentPropertyId || !!selectedPropertyId) && context === AppContext.Owner,
        select: (data) => {
            const newUsers: NewInvitedManager[] = []
            const activeManagersEmails = data?.users.map((user) => user.mail)
            const newInvitedManagers = data?.invitedUsers.filter((user) => activeManagersEmails?.every((mail) => mail !== user.mail))

            if (newInvitedManagers && newInvitedManagers.length > 0) {
                newInvitedManagers.forEach((manager) => {
                    newUsers.push({
                        name: "-",
                        mail: manager.mail,
                        date: manager.lastTry,
                        status: manager.status,
                        ownershipType: manager.ownershipType,
                        canDelete: true,
                    })
                })
            }

            return { ...data, finalManagers: [...data.users, ...newUsers] }
        },
    })
}

export const useCountiesQueryFn = () => {
    return useQuery({
        queryKey: [SimpleQueryIDs.Counties],
        queryFn: Api.fetchCounties,
        staleTime: Infinity,
    })
}

export const useCitiesQueryFn = (countyId?: number) => {
    return useQuery({
        staleTime: Infinity,
        enabled: !!countyId,
        queryKey: [ComplexQueryIDs.CountyCities, { id: countyId }],
        queryFn: Api.fetchCities,
    })
}

export const useAreasQueryFn = (countyId?: number, cityId?: number) => {
    return useQuery({
        staleTime: Infinity,
        enabled: !!countyId && !!cityId,
        queryKey: [ComplexQueryIDs.CityAreas, { id: cityId, countyId }],
        queryFn: Api.fetchAreas,
    })
}

export const useUserInsuranceQueryFn = (page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Insurances)

    return useQuery({
        queryKey: [ComplexQueryIDs.Insurances, { id: currentPropertyId, page, pageSize, sortingColumns }],
        queryFn: Api.fetchUserInsurance,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && pageSize > 0,
        placeholderData: () => ({
            items: [],
            count: 20,
        }),
        select: (data) => {
            if (userProperties == null) return { count: 0, items: [] }
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteUserInsuranceQueryFn = () => {
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Insurances)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.Insurances, { id: currentPropertyId }],
        queryFn: Api.fetchInfiniteUserInsurance,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
    })
}
export const getInsuranceByIdQueryFn = (insuranceId?: number) => {
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { currentPropertyId, modalOpen } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Insurances)

    return useQuery({
        queryKey: [ComplexQueryIDs.Insurance, { insuranceId }],
        queryFn: async () => {
            let toReturn: (PropertyInsurance & { canWrite: boolean; canDelete: boolean }) | undefined | null
            if (isMobile) {
                const insurances = queryClient.getQueryData([ComplexQueryIDs.Insurance, { id: currentPropertyId }]) as
                    | {
                          pages: PropertyInsuranceResponse[]
                      }
                    | undefined
                const arrayOfInsurance = insurances?.pages.flatMap((r) => r.items)
                const insurance = arrayOfInsurance?.find((insurance) => insurance.id == insuranceId)

                if (insurance) {
                    toReturn = {
                        ...insurance,
                        canWrite: editablePropertiesIds?.includes(insurance.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(insurance.propertyId) ?? false,
                    }
                } else toReturn = insurance ?? null
            } else {
                const insurancesPages = queryClient.getQueriesData({
                    queryKey: [ComplexQueryIDs.Insurance, { id: currentPropertyId }],
                })

                const arrayOfInsurances = (
                    insurancesPages?.flatMap((insuranceWithKey) => insuranceWithKey[1]) as PropertyInsuranceResponse[]
                )?.flatMap((r) => r?.items)
                const insurance = arrayOfInsurances.find((insurance) => insurance?.id == insuranceId)
                if (insurance) {
                    toReturn = {
                        ...insurance,
                        canWrite: editablePropertiesIds?.includes(insurance.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(insurance.propertyId) ?? false,
                    }
                } else toReturn = insurance ?? null
            }

            const insurance = toReturn ?? (await Api.getInsurance(insuranceId!)) ?? null

            if (insurance) {
                toReturn = {
                    ...insurance,
                    canWrite: editablePropertiesIds?.includes(insurance.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(insurance.propertyId) ?? false,
                }
            } else toReturn = null

            return toReturn ?? null
        },
        staleTime: Infinity,
        enabled:
            !!insuranceId &&
            modalOpen != null &&
            [
                AppModals.InsuranceDetails,
                AppModals.AddInsurance,
                AppModals.EditInsurance,
                AppModals.EditInsuranceNotification,
                AppModals.DeleteInsurance,
            ].includes(modalOpen),
    })
}

export const useConfiguredExpenseProviders = () => {
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())
    const { currentPropertyId, context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.ConfiguredExpenseProviders, { propertyId: currentPropertyId }],
        queryFn: Api.fetchPropertyExpenseProviders,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!currentPropertyId && areExpenseProvidersEnabled,
    })
}

export const useAvailableExpenseProviders = () => {
    const { currentPropertyId, context, modalOpen } = useSelector((state) => state.appState)

    return useQuery({
        staleTime: Infinity,
        queryKey: [SimpleQueryIDs.ExpenseProviders],
        queryFn: Api.fetchAvailableExpenseProviders,
        enabled: context === AppContext.Owner && !!currentPropertyId && modalOpen === AppModals.AddExpenseProvider,
    })
}

export const useAvailableMeterProviders = () => {
    const { currentPropertyId, context, modalOpen } = useSelector((state) => state.appState)
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())

    return useQuery({
        staleTime: Infinity,
        queryKey: [SimpleQueryIDs.MetersProviders],
        queryFn: Api.fetchAvailableMeterProviders,
        enabled: context === AppContext.Owner && !!currentPropertyId && modalOpen === AppModals.AddMeterProvider && areMetersProvidersEnabled,
    })
}

export const usePropertyPhotosQueryFn = () => {
    const { currentPropertyId } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [ComplexQueryIDs.PropertyPhotos, { propertyId: currentPropertyId }],
        queryFn: Api.fetchPropertyPhotos,
        staleTime: Infinity,
    })
}
