import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import * as jsonpatch from "fast-json-patch"
import { addJournalMutation, updateJournalMutation } from "../../../../mutations/tenants"
import { getJournalByIdQueryFn } from "../../../../queries/journal"
import AddOrEditJournalForm, {
    AddJournalRequest,
} from "../../../../sections/propertyDetails/Tenants/rentingPeriodDetails/journal/AddOrEditJournalForm"
import { useAtomValue } from "jotai/index"
import { journalsFilterState } from "../../../../utils/atom"

export default function AddOrEditJournalGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addJournal, isPending: isAddJournalLoading } = addJournalMutation()
    const { mutateAsync: updateJournal, isPending: isUpdatingLoading } = updateJournalMutation()
    const filterModel = useAtomValue(journalsFilterState)
    const currentJournal = getJournalByIdQueryFn(editingItem?.id, [{ field: "createdAt", sort: "desc" }], filterModel)

    const closeModal = useCallback(() => {
        if (isMobile && modalOpen === AppModals.EditJournal) return dispatch(setModalOpen(AppModals.JournalDetails))

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile, modalOpen])

    const onSave = useCallback(
        async (data: AddJournalRequest) => {
            if (currentJournal != null && modalOpen === AppModals.EditJournal) {
                const operations = jsonpatch.compare({ ...currentJournal, files: [] }, { ...data, files: [] })
                if (!data.id) return
                await updateJournal({
                    journalId: data.id,
                    operations,
                })
                if (isMobile) {
                    dispatch(setModalOpen(AppModals.JournalDetails))
                } else {
                    closeModal()
                }
            } else {
                await addJournal({ data })
                closeModal()
            }
        },
        [addJournal, closeModal, currentJournal, modalOpen, updateJournal, dispatch],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.EditJournal ? "edit_journal" : "add_journal")}
                open={!isMobile && (modalOpen === AppModals.AddJournal || modalOpen === AppModals.EditJournal)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddOrEditJournalForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddJournalLoading || isUpdatingLoading}
                    defaultValues={currentJournal}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditJournal ? "edit_journal" : "add_journal")}
                isOpen={isMobile && (modalOpen === AppModals.AddJournal || modalOpen === AppModals.EditJournal)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditJournalForm
                    onClose={() => {
                        if (modalOpen === AppModals.EditJournal) {
                            dispatch(setModalOpen(AppModals.JournalDetails))
                        } else {
                            closeModal()
                        }
                    }}
                    onSave={onSave}
                    isLoading={isAddJournalLoading || isUpdatingLoading}
                    defaultValues={currentJournal}
                />
            </DetailsSheet>
        </>
    )
}
