import { Box, Button } from "@mui/material"
import ChooseCardPage from "./ChooseCardPage"
import React, { useCallback } from "react"
import { useIsMobile, useLocales } from "rentzz"
import { useFormContext } from "react-hook-form"
import { AddIncomeRequest } from "./types"
import { PaymentType } from "../../../redux/slices/AddProperty"

interface Props {
    onClose: () => void
}
export default function ChooseIncomeOrPaymentTypePage({ onClose }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { setValue } = useFormContext<AddIncomeRequest>()

    const handleExpenseTypeCard = useCallback(() => {
        setValue("type", PaymentType.Expense)
    }, [setValue])

    const handleIncomeTypeCard = useCallback(() => {
        setValue("type", PaymentType.Rent)
    }, [setValue])

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={2}>
            <>
                <ChooseCardPage title={"expenses.expense"} icon={"mdi:tag-multiple"} onClick={handleExpenseTypeCard} />
                <ChooseCardPage
                    title={translate("rent_or_security_deposit_or_damage")}
                    icon={"mdi:key-chain-variant"}
                    onClick={handleIncomeTypeCard}
                />
            </>

            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={onClose} fullWidth={isMobile} variant={isMobile ? "contained" : "text"}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
