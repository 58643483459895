import { Box, Divider, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../redux/store"
import { usePropertyDetailsQueryFn } from "../../queries"
import { AppModals, setModalOpen } from "../../redux/slices/App"
import * as React from "react"
import NoDataPage from "../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"

export default function OwnerDetails() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: currentProperty } = usePropertyDetailsQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Properties, currentPropertyId)

    return (
        <Paper elevation={3} square={false} sx={{ color: "text.secondary" }}>
            <Box display={"flex"} justifyContent={"space-between"} paddingY={1} paddingX={2} alignItems={"center"}>
                <Typography fontWeight={"bold"}>{translate("owner_data")}</Typography>

                {canWrite && (
                    <Tooltip title={translate("edit")} arrow>
                        <IconButton onClick={() => dispatch(setModalOpen(AppModals.EditPropertyOwnerDetails))}>
                            <Iconify icon={"mdi:edit"} sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Divider sx={{ marginX: 1 }} />
            <Box display={"flex"} flexDirection={"column"} gap={1} padding={2}>
                {(currentProperty?.marketingSettings?.phoneNumber || currentProperty?.marketingSettings?.email) &&
                currentProperty?.marketingSettings?.lastName &&
                currentProperty?.marketingSettings?.firstName ? (
                    <Box display='flex' flexDirection='row' height={160} sx={{ overflowY: "auto" }}>
                        <Box display={"flex"} flexDirection={"column"} gap={1} alignItems={"start"} flex={1}>
                            <Typography variant='body2' fontWeight={"bold"}>
                                {translate("first_name")}
                            </Typography>
                            <Typography variant='body2' fontWeight={"bold"}>
                                {translate("last_name")}
                            </Typography>
                            {currentProperty?.marketingSettings?.phoneNumber && (
                                <Typography variant='body2' fontWeight={"bold"}>
                                    {translate("phone")}
                                </Typography>
                            )}
                            {currentProperty?.marketingSettings?.email && (
                                <Typography variant='body2' fontWeight={"bold"}>
                                    {translate("email")}
                                </Typography>
                            )}
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} gap={1} alignItems={"start"} flex={1}>
                            <Typography variant='body2'>{currentProperty?.marketingSettings?.firstName}</Typography>
                            <Typography variant='body2'>{currentProperty?.marketingSettings?.lastName}</Typography>
                            {currentProperty?.marketingSettings?.phoneNumber && (
                                <Typography variant='body2'>{currentProperty?.marketingSettings?.phoneNumber}</Typography>
                            )}
                            {currentProperty?.marketingSettings?.email && (
                                <Typography variant='body2'>{currentProperty?.marketingSettings?.email ?? "-"}</Typography>
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box display={"flex"} padding={2} justifyContent={"center"} height={160}>
                        <NoDataPage noDataText={"no_owner_details"} icon={"mdi:user-off"} />
                    </Box>
                )}
            </Box>
        </Paper>
    )
}
