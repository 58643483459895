import { Page, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useAtom } from "jotai"
import { settingsPageAtom, SettingsPages } from "../../utils/atom"
import { a11yProps, TabPanel } from "../../pages/PropertyDetails"
import { Tab, Tabs } from "@mui/material"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import NotificationsIcon from "@mui/icons-material/Notifications"
import VpnKeyIcon from "@mui/icons-material/VpnKey"
import { useTheme } from "@mui/material/styles"
import GeneralPage from "./general/GeneralPage"
import Security from "./Security"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"
import NotificationCard from "./notifications/NotificationCard"

export default function OwnerSettingsPage() {
    const theme = useTheme()
    const { translate } = useLocales()
    const [settingsPage, setSettingsPage] = useAtom(settingsPageAtom)
    const deleteUser = useFeatureIsOn(UserFlags.DeleteUser.toString())

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: SettingsPages) => {
        switch (newValue) {
            case SettingsPages.General:
                setSettingsPage(SettingsPages.General)
                break
            case SettingsPages.Notifications:
                setSettingsPage(SettingsPages.Notifications)
                break
            case SettingsPages.Security:
                setSettingsPage(SettingsPages.Security)
                break
        }
    }, [])

    return (
        <Page title={translate("settings")} paddingBottom={2} paddingTop={1} px={2} color={theme.palette.grey.A700}>
            <Tabs value={settingsPage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                <Tab label={translate("general")} icon={<AccountBoxIcon />} {...a11yProps(0)} />
                <Tab label={translate("grid_actions.notifyPeriodStatus")} icon={<NotificationsIcon />} {...a11yProps(2)} />
                {deleteUser && <Tab label={translate("security")} icon={<VpnKeyIcon />} {...a11yProps(3)} />}
            </Tabs>
            <TabPanel value={settingsPage} index={SettingsPages.General}>
                <GeneralPage />
            </TabPanel>
            <TabPanel value={settingsPage} index={SettingsPages.Notifications}>
                <NotificationCard />
            </TabPanel>
            {deleteUser && (
                <TabPanel value={settingsPage} index={SettingsPages.Security}>
                    <Security />
                </TabPanel>
            )}
        </Page>
    )
}
