import { Avatar, Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import { AppContext, CustomFile, FormProvider, Iconify, RHFUpload, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { OwnerTaskCommentSchema, TenantTaskCommentSchema } from "../../../validations/kanban"
import RHFEditableInput from "../../../components/RHFEditableInput"
import { LoadingButton } from "@mui/lab"
import { addTaskCommentMutation, tenantAddTaskCommentMutation } from "../../../mutations/kanban"
import { stringAvatar } from "../utils"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn } from "../../../queries/userData"
import { DESKTOP_ICON_SIZE, getReverseTextColor, MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { useSelector } from "../../../redux/store"

export interface AddTaskCommentRequestBase {
    message: string
    taskId: string
    files: CustomFile[]
}

export interface TenantAddTaskCommentRequest extends AddTaskCommentRequestBase {
    rentingPeriodId: number
}
export interface OwnerAddTaskCommentRequest extends AddTaskCommentRequestBase {
    sectionId: string
}

interface Props {
    sectionId?: string
    taskId?: string
}

export default function TaskDetailsCommentInput({ sectionId, taskId }: Props) {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { mutateAsync: addMessage, isPending } = addTaskCommentMutation()
    const { mutateAsync: tenantAddMessage, isPending: isTenantAddMessageLoading } = tenantAddTaskCommentMutation()
    const { data: user } = useUserDataQueryFn()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const [filesToUpload, setFilesToUpload] = useState<CustomFile[]>([])

    const methods = useForm<OwnerAddTaskCommentRequest | TenantAddTaskCommentRequest>({
        resolver: yupResolver<OwnerAddTaskCommentRequest | TenantAddTaskCommentRequest>(
            context === AppContext.Owner ? OwnerTaskCommentSchema() : TenantTaskCommentSchema(),
        ),
    })

    const { handleSubmit, getValues, reset, setValue, watch } = methods

    useEffect(() => {
        if (context === AppContext.Owner) {
            reset({
                message: "",
                sectionId: sectionId,
                taskId: taskId,
                files: [],
            })
        } else {
            reset({
                message: "",
                taskId: taskId,
                files: [],
                rentingPeriodId: tenantRentingPeriods?.find((r) => r.propertyId === currentPropertyId)?.rentingPeriodId,
            })
        }
    }, [context, reset, tenantRentingPeriods, taskId, sectionId, currentPropertyId])

    useEffect(() => {
        const subscription = watch((v) => {
            if (v.files != null) setFilesToUpload(v.files.filter((f) => f != null))
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleDeleteFile = useCallback((f: CustomFile) => {
        setFilesToUpload((prevFiles) => {
            setValue("files", [...prevFiles.filter((f2) => f2.path !== f.path)])
            return [...prevFiles.filter((f2) => f2.path !== f.path)]
        })
    }, [])

    const onSubmit = useCallback(
        async (data: OwnerAddTaskCommentRequest | TenantAddTaskCommentRequest) => {
            if (context === AppContext.Owner) {
                await addMessage({
                    sectionId: (data as OwnerAddTaskCommentRequest).sectionId,
                    taskId: data.taskId,
                    files: data.files,
                    message: data.message,
                })
            } else {
                await tenantAddMessage({
                    rentingPeriodId: (data as TenantAddTaskCommentRequest).rentingPeriodId,
                    taskId: data.taskId,
                    files: data.files,
                    message: data.message,
                })
            }

            setValue("message", "")
            setValue("files", [])
        },
        [addMessage, getValues, setValue, tenantAddMessage],
    )

    return (
        <Stack direction='row' spacing={2} sx={{ py: 3, px: 2 }}>
            <Avatar
                {...stringAvatar(`${user?.firstName} ${user?.lastName}`)}
                sx={{ backgroundColor: theme.palette.primary.dark, color: getReverseTextColor(theme.palette.primary.dark) }}
            />
            <Paper variant='outlined' sx={{ p: 1, flexGrow: 1 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <RHFEditableInput name={"message"} fullWidth multiline rows={2} placeholder={translate("type_message")} sx={{ px: 1 }} />
                    <Box display={"flex"} justifyContent={"space-between"} alignItems='center'>
                        <RHFUpload
                            name={"files"}
                            multiple
                            icon={"mdi:attachment"}
                            uploadText={""}
                            hasPreview={false}
                            padding={1}
                            iconSize={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                        />

                        <LoadingButton
                            variant='contained'
                            type={"submit"}
                            color={"primary"}
                            size={"small"}
                            disabled={isPending || isTenantAddMessageLoading}
                            loading={isPending || isTenantAddMessageLoading}
                        >
                            {translate("comment")}
                        </LoadingButton>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems='center' flexDirection='column' pt={1}>
                        {(filesToUpload ?? []).map((f, index) => (
                            <Box key={index.toString()} display={"flex"} flexDirection='row' gap={1} alignItems='center' width='100%'>
                                <Iconify
                                    icon='mdi:attachment'
                                    width={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                                    height={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                                />
                                <Box flex={1}>
                                    <Typography variant='caption'>{f.name}</Typography>
                                </Box>
                                <IconButton onClick={() => handleDeleteFile(f)} color='error'>
                                    <Iconify
                                        icon='mdi:delete'
                                        width={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                                        height={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
                                    />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                </FormProvider>
            </Paper>
        </Stack>
    )
}
