import MeterDetailsSheet from "./metersDetails/MeterDetailsSheet"
import MeterValuesHistoryGuard from "./readings/MeterValuesHistoryGuard"
import DeleteMeterGuard from "./DeleteMeterGuard"
import AddOrEditMeterGuard from "./AddOrEditMeterGuard"
import AddMeterProviderGuard from "./AddMeterProviderGuard"
import MeterStatusDetailsSheet from "../../../sections/dashboard/Tables/MeterStatusDetailsSheet"
import DeleteMeterValueGuard from "./DeleteMeterValueGuard"
import StopSendingAutomaticReading from "./readings/StopSendingAutomaticReading"
import PendingReadingDetailsSheet from "../../../sections/propertyDetails/General/management/pending-readings/PendingReadingDetailsSheet"
import IntegerMeterReading from "./metersDetails/IntegerMeterReading"

export default function MetersGuard() {
    return (
        <>
            <MeterDetailsSheet />
            <AddOrEditMeterGuard />
            <DeleteMeterGuard />
            <DeleteMeterValueGuard />
            <MeterValuesHistoryGuard />
            <AddMeterProviderGuard />
            <MeterStatusDetailsSheet />
            <StopSendingAutomaticReading />
            <PendingReadingDetailsSheet />
            <IntegerMeterReading />
        </>
    )
}
