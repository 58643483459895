import React from "react"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import AcceptPage from "./AcceptPage"
import { acceptTOSMutation } from "../../mutations/user"
import { useFeatureValue } from "@growthbook/growthbook-react"

export default function TOSAccept() {
    const { data: userData } = useUserDataQueryFn()
    const { mutateAsync, isPending: isLoading } = acceptTOSMutation()
    const tosURL = useFeatureValue(UserFlags.TosUrl.toString(), "")
    if (userData == null) return null

    return (
        <AcceptPage
            title={"tos"}
            consultText={"tos_consult_text"}
            consultURL={tosURL}
            acceptanceText={"tos_acceptance_text"}
            buttonText={"accept"}
            onAccept={mutateAsync}
            isLoading={isLoading}
            agreeWithTermsCheckTitle={"tos_accept"}
        />
    )
}
