import { Box, Button, Card, CardContent, IconButton } from "@mui/material"
import { Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { useTheme } from "@mui/material/styles"

interface CustomMobileAddCardProps {
    onCardClick: () => void
    icon: string
    text: string
    isAddDisabled?: boolean
    tooltipTitle?: string
    isDesktop?: boolean
    marginRight?: number
    height?: number
}

export default function CustomAddCard({
    onCardClick,
    icon,
    text,
    isAddDisabled,
    tooltipTitle,
    isDesktop,
    marginRight,
    height,
}: CustomMobileAddCardProps) {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()

    return (
        <LightTooltip title={isAddDisabled ? translate(tooltipTitle) : ""} arrow>
            <Card
                onClick={isAddDisabled ? console.log : onCardClick}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    px: isMobile ? 1 : 0,
                    height: height ? height : "100%",
                    borderWidth: 2,
                    borderColor: isAddDisabled ? theme.palette.grey.A400 : theme.palette.primary.main,
                    borderStyle: "dashed",
                    ":hover": {
                        backgroundColor: isAddDisabled && isDesktop ? "" : theme.palette.action.hover,
                    },
                    marginRight,
                }}
            >
                <CardContent sx={{ justifyContent: "center", display: "flex" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                alignSelf: "center",
                            }}
                        >
                            <IconButton
                                onClick={onCardClick}
                                sx={{
                                    ":hover": { backgroundColor: "transparent" },
                                }}
                                disabled={isAddDisabled}
                            >
                                <Iconify
                                    icon={icon}
                                    color={isAddDisabled ? theme.palette.grey.A400 : theme.palette.primary.main}
                                    width={40}
                                    height={40}
                                />
                            </IconButton>
                        </Box>
                        <Button
                            sx={{ textTransform: "none", ":hover": { backgroundColor: "transparent", borderColor: "transparent" } }}
                            onClick={onCardClick}
                            disabled={isAddDisabled}
                            color={"primary"}
                            variant={"text"}
                        >
                            {translate(text)}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </LightTooltip>
    )
}
