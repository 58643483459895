import CustomToolbar from "../../../components/CustomToolbar"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { CustomDataGrid, LoadingIcon, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { getGroupAccessList, GroupAccess, useUserGroupsQueryFn } from "../../../queries/groups"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"

const DesktopGroupAccessList = () => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: groups } = useUserGroupsQueryFn()
    const { data: groupAccess } = getGroupAccessList()
    const { currentGroupId } = useSelector((state) => state.appState)
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [currentGroupId, groups])

    const groupColumns = useMemo(() => {
        const columns: GridColDef<GroupAccess>[] = [
            {
                field: "fullName",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "date",
                type: "date",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("date"),
                valueFormatter: (value) => {
                    return (value as DateTime).toLocaleString(DateTime.DATE_SHORT)
                },
            },
            {
                field: "ownershipType",
                type: "string",
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("manager_type"),
                valueFormatter: (value) => {
                    return translate(`managerType-${value}`)
                },
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 200,
            headerName: translate("actions"),
            getActions: (params: GridRowParams<GroupAccess>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteGroupAccess))
                                dispatch(setEditingItem({ id: currentGroupId, userId: params.row.userId }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                }),
            ],
        }),
        [translate, currentGroupId],
    )

    if (currentGroup == null || groupAccess == null) return <LoadingIcon />

    return (
        <>
            <CustomToolbar
                onAddClick={() => {
                    dispatch(setModalOpen(AppModals.AddGroupAccess))
                    dispatch(setEditingItem({ id: currentGroupId }))
                }}
                numberOfItems={groupAccess?.length}
                buttonText={"handle_access"}
            />
            <CustomDataGrid
                sortingMode='client'
                columns={groupColumns}
                idKey={"id"}
                getRowId={(row) => row.userId}
                actionsColumn={[actionsColumn]}
                rows={groupAccess ?? []}
            />
        </>
    )
}

export default DesktopGroupAccessList
