import { Alert, Box, Button, CircularProgress } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useDispatch, useSelector } from "../../../redux/store"
import Api from "../../../api/Api"
import Map, { MapLayerMouseEvent } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { CustomFile, MapMarker, useIsMobile, useLocales } from "rentzz"
import { AddPropertyPage, LatLng, PropertyDetails, setActiveStep, setLocation, setPhotos } from "../../../redux/slices/AddProperty"
import { createPropertyMutation, updatePropertyDetailsMutation } from "../../../mutations"
import * as jsonpatch from "fast-json-patch"
import { useUserDataQueryFn } from "../../../queries/userData"
import { LoadingButton } from "@mui/lab"
import { setModalOpen } from "../../../redux/slices/App"

interface MapPickerProps {
    afterSubmit?: () => void
    isEditDialogOpen?: boolean
    closeDialog?: () => void
    defaultLng?: any
    defaultLat?: any
    defaultMarkerLng?: any
    defaultMarkerLat?: any
}

export interface CreatePropertyRequest {
    details: PropertyDetails | undefined
    photos: CustomFile[]
    location: LatLng | undefined
    propertyName: string | undefined
    labelId: number | undefined
}

const MapPicker = ({ afterSubmit, isEditDialogOpen, closeDialog, defaultLng, defaultLat, defaultMarkerLng, defaultMarkerLat }: MapPickerProps) => {
    const { details, location, photos, propertyName, labelId } = useSelector((state) => state.addProperty)
    const { errorMessage } = useSelector((state) => state.appState)
    const { data, isFetching } = useQuery({
        queryKey: ["coordinates", { county: details?.county, city: details?.city }],
        queryFn: Api.getCoordinates,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 1000,
        gcTime: 1000,
    })
    const { refetch: refetchUserData } = useUserDataQueryFn()
    const [marker, setMarker] = useState<{ lat: number; lng: number } | undefined>(undefined)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: editProperty, isPending: isLoading } = updatePropertyDetailsMutation()
    const { mutateAsync: createProperty, isPending: isCreatePropertyLoading } = createPropertyMutation()

    const handleOnClick = (e: MapLayerMouseEvent) => {
        dispatch(setLocation(e.lngLat))
        setMarker(e.lngLat)
    }
    useEffect(() => {
        if (data) {
            dispatch(setLocation(data))
        }
    }, [data])

    const handleCreate = async () => {
        if (isEditDialogOpen) {
            const operations = jsonpatch.compare(
                { lat: defaultMarkerLat, lng: defaultMarkerLng },
                {
                    ...marker,
                },
            )

            await editProperty({
                operations: operations.map((o) => ({ ...o, path: `/location${o.path}` })),
            })
        } else {
            await createProperty({ data: { details, location, photos, propertyName, labelId } })
            await refetchUserData()
            dispatch(setActiveStep(AddPropertyPage.Name))
            dispatch(setPhotos([]))
            dispatch(setModalOpen(undefined))
        }
        afterSubmit?.()
    }

    useEffect(() => {
        if (defaultMarkerLat && defaultMarkerLng) {
            setMarker({ lat: defaultMarkerLat, lng: defaultMarkerLng })
        }
    }, [defaultMarkerLat, defaultMarkerLng])

    const goBack = () => {
        if (isEditDialogOpen) closeDialog?.()

        dispatch(setActiveStep(1))
    }

    if (isFetching)
        return (
            <Box height={420} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
            <Map
                style={{ height: 350, width: "100%" }}
                onClick={handleOnClick}
                mapStyle='mapbox://styles/mapbox/streets-v9'
                mapboxAccessToken='pk.eyJ1IjoiZGFubW90IiwiYSI6ImNsN3dpZ2lvZjBkN2kzdnViajNwNjc1bjgifQ.wyNVjOvfMBMp3WP1ZJ9phw'
                initialViewState={{
                    latitude: defaultLat || data?.lat,
                    longitude: defaultLng || data?.lng,
                    zoom: 13,
                }}
            >
                {marker && <MapMarker longitude={marker?.lng} latitude={marker?.lat} />}
            </Map>
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}

            <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' gap={2}>
                <Button color='primary' onClick={goBack} disabled={isCreatePropertyLoading}>
                    {translate(isEditDialogOpen ? "cancel" : "previous")}
                </Button>

                <LoadingButton
                    loading={isCreatePropertyLoading || isLoading}
                    variant='contained'
                    onClick={handleCreate}
                    disabled={isCreatePropertyLoading || isLoading}
                    color={"primary"}
                >
                    {translate("save")}
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default MapPicker
