import { Box, Typography } from "@mui/material"
import { Iconify, LightTooltip, RHFAutocomplete, useLocales } from "rentzz"
import OptionWithLabel from "./providers/OptionWithLabel"
import React from "react"
import { useUserLabelsQuery } from "../queries/userData"
import { setEditingItem, setModalOpen } from "../redux/slices/App"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "../redux/store"
import { useTheme } from "@mui/material/styles"

interface RHFLabelInputProps {
    name: string
}
export default function RHFLabelInput({ name }: RHFLabelInputProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: labels } = useUserLabelsQuery()

    return (
        <>
            <RHFAutocomplete
                name={name}
                textFieldProps={{ size: "small" }}
                options={labels?.map((l) => ({ id: l.id, label: l.value, color: l.color })) ?? []}
                label={translate("labelDescription")}
                freeSolo={false}
                matchId
                autocompleteProps={{
                    size: "small",
                    renderOption: (props, option) => OptionWithLabel(option, props),
                    noOptionsText: (
                        <LightTooltip title={translate("add_label")}>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    dispatch(setModalOpen(undefined))
                                    dispatch(setEditingItem(undefined))
                                    navigate("/nomenclature/labels")
                                }}
                            >
                                <Typography>{translate("noLabel")}</Typography>
                                <Iconify sx={{ color: theme.palette.primary.main }} icon={"mdi:add-circle-outline"} />
                            </Box>
                        </LightTooltip>
                    ),
                }}
                coloredComponent={(value) => {
                    const castedValue = value as string
                    if (value == null) return <></>
                    const label = labels?.find((l) => l.id === Number(castedValue))

                    return (
                        <Box
                            sx={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: `#${label?.color}`,
                                backgroundColor: `#${label?.color}`,
                                width: 30,
                                height: 20,
                            }}
                        />
                    )
                }}
            />
        </>
    )
}
