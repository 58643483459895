import React, { useCallback, useMemo } from "react"
import { useMatches, useParams } from "react-router"
import { Breadcrumbs as MUIBreadcrumbs, Button, CircularProgress } from "@mui/material"
import { AppContext, useLocales } from "rentzz"
import { useUserContractTemplatesQuery, useUserPropertiesQuery } from "../queries/userData"
import { DateTime } from "luxon"
import { useSingleRentingPeriodQuery } from "../queries/tenants"
import { useSelector } from "../redux/store"
import { useNavigate } from "react-router-dom"
import { PropertySectionsType } from "../sections/files/PropertySections"
import { useUserGroupsQueryFn } from "../queries/groups"

interface IMatch {
    id: string
    pathname: string
    params: unknown
    data: unknown
    handle: {
        crumbs: string[]
    }
}

const Breadcrumbs = () => {
    const matches = useMatches() as IMatch[]
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()
    const { data: contracts } = useUserContractTemplatesQuery(false)
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(currentRentingPeriodId)
    const params = useParams()
    const crumbs = useMemo(() => matches?.filter((match) => Boolean(match.handle?.crumbs)).map((m) => m.handle.crumbs), [matches])

    const handleNavigate = useCallback(
        (i: number) => {
            const navigationItem = crumbs?.at(0)?.at(i)
            switch (navigationItem?.toLowerCase() ?? "") {
                case "properties":
                    navigate("/properties")
                    break
                case ":currentpropertyid":
                    navigate(`/properties/${params["currentPropertyId"]}`)
                    break
                case "groups":
                    navigate("/groups")
                    break
                case "documents":
                    navigate("/documents")
                    break
                case "propertydocuments":
                    navigate(`/properties/${params["currentPropertyId"]}/documents`)
                    break
                case "rentingperioddocuments":
                    navigate(`/tenants/${params["currentRentingPeriodId"]}/documents`)
                    break
                case ":currentfilespropertyid":
                    navigate(`/documents/${params["currentFilesPropertyId"]}`)
                    break
                case "tenants":
                    navigate("/tenants")
                    break
                case ":currentrentingperiodid":
                    navigate(`/tenants/${params["currentRentingPeriodId"]}`)
                    break
                case "template":
                    navigate("/document-templates")
                    break
                case "notifications_templates":
                    navigate("/document-templates/notification-templates")
                    break
                case "general_documents":
                    navigate("/document-templates/general-documents")
                    break
            }
        },
        [crumbs, params],
    )

    const currentCrumbNonParams = useMemo(() => {
        return crumbs?.at(0)?.filter((c) => !c.startsWith(":"))
    }, [crumbs])

    const handleParamCrumbs = useCallback(
        (param: string) => {
            const paramName = param.replaceAll(":", "")
            const paramValue = params[paramName]
            switch (param.toLowerCase()) {
                case ":currentpropertyid": {
                    return userProperties?.find((p) => p.id === Number(paramValue))?.label
                }
                case ":currentgroupid": {
                    return groups?.find((p) => Number(p.id) === Number(paramValue))?.name
                }
                case ":currentfilespropertyid": {
                    return userProperties?.find((p) => p.id === Number(paramValue))?.label
                }
                case ":contractid": {
                    return contracts?.find((p) => p.id === paramValue)?.label
                }
                case ":notificationid": {
                    return translate("edit_notification")
                }
                case ":generaldocumentid": {
                    return translate("edit_document")
                }
                case ":currentrentingperiodid": {
                    return currentRentingPeriod != null
                        ? `Contract ${currentRentingPeriod?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${currentRentingPeriod?.moveOutDate.toLocaleString(
                              DateTime.DATE_SHORT,
                          )}`
                        : null
                }
                case ":section": {
                    switch (paramValue) {
                        case PropertySectionsType.Expenses.toString():
                            return translate("Expenses")
                        case PropertySectionsType.Incomes.toString():
                            return translate("income")
                        case PropertySectionsType.Insurances.toString():
                            return translate("insurance.name")
                        case PropertySectionsType.Documents.toString():
                            return translate("documents")
                        case PropertySectionsType.Contracts.toString():
                            return translate("contracts.title")
                        case PropertySectionsType.Meters.toString():
                            return translate("meters_readings")
                        case PropertySectionsType.RevisionObservations.toString():
                            return translate("revisions")
                    }
                }
            }
            return null
        },
        [params, currentRentingPeriod, userProperties, contracts],
    )

    if (context === AppContext.Tenant || crumbs?.at(0)?.length === 1) return null
    if (userProperties == null || (currentRentingPeriodId && !currentRentingPeriod)) return <CircularProgress size='1rem' />

    return (
        <MUIBreadcrumbs separator='›' sx={{ pl: 2 }}>
            {crumbs?.at(0)?.map((m, i) => (
                <Button onClick={() => handleNavigate(i)} color='primary' key={m} disabled={i === crumbs?.at(0)!.length - 1}>
                    {currentCrumbNonParams?.includes(m) ? translate(m) : handleParamCrumbs(m)}
                </Button>
            ))}
        </MUIBreadcrumbs>
    )
}

export default Breadcrumbs
