import { Alert, Box, MenuItem } from "@mui/material"
import { PaymentType, PropertyIncome, PropertyIncomePaymentMethod, RentingPeriodStatusCode } from "../../../redux/slices/AddProperty"
import { LightTooltip, RHFAutocomplete, RHFDatePicker, RHFSelect, RHFUnitInput, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { getOwnerMaxSize } from "../../../utils/actions"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "../../../redux/store"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUserDataQueryFn } from "../../../queries/userData"
import { usePropertyRentingPeriods, useRentingPeriodDetails, useSingleRentingPeriodQuery } from "../../../queries/tenants"
import { AppForm, useFormResetter } from "../../../hooks/useFormResetter"
import { AddIncomeRequest } from "./types"
import { useFormContext } from "react-hook-form"
import { DateTime } from "luxon"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"

interface Props {
    defaultValues: PropertyIncome | undefined
}
export default function AddIncomeTypeRentForm({ defaultValues }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId, errorMessage, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [propertyId, setPropertyId] = useState<number | undefined>(undefined)
    const { data: currencies } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { resetFields } = useFormResetter<AddIncomeRequest>()
    const { setValue, watch, getValues, reset } = useFormContext<AddIncomeRequest>()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: rentingPeriod } = useSingleRentingPeriodQuery(currentRentingPeriodId)
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(getValues("propertyId"))
    const { editableProperties } = usePermissions(PermissionType.Incomes)

    const currentRentingPeriod = useMemo(() => {
        if (currentRentingPeriodId) {
            return rentingPeriod
        }
        const accepted = rentingPeriodsFromAProperty?.find((t) => t.rentingPeriodStatus === RentingPeriodStatusCode.Accepted)
        const finished = rentingPeriodsFromAProperty?.find((t) => t.rentingPeriodStatus === RentingPeriodStatusCode.Finished)

        if (accepted) return accepted
        return finished
    }, [rentingPeriodsFromAProperty, propertyId, currentPropertyId, rentingPeriod])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "rentingPeriodId") {
                const selectedRentingPeriod = rentingPeriodsFromAProperty?.find((period) => period.id === v.rentingPeriodId)
                if (selectedRentingPeriod) {
                    setValue("value", selectedRentingPeriod.value, { shouldValidate: true })
                    setValue("currencyId", selectedRentingPeriod.currencyId, { shouldValidate: true })
                } else {
                    setValue("currencyId", userData?.currency.id ?? 1, { shouldValidate: true })
                    resetFields(AppForm.AddIncome, "rentingPeriodId")
                }
            }
            if (v.propertyId != null) {
                setPropertyId(v.propertyId)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, rentingPeriodsFromAProperty, resetFields, setValue, userData])

    const acceptedProperties = useCallback(() => {
        const propertiesOfTenantsWhoAcceptedIds = rentingPeriodsFromAProperty
            ?.filter((rentingPeriod) => rentingPeriod.rentingPeriodStatus === RentingPeriodStatusCode.Accepted)
            .map((t) => t.propertyId)
        return editableProperties?.filter((p) => propertiesOfTenantsWhoAcceptedIds?.includes(p.id))
    }, [editableProperties, rentingPeriodsFromAProperty, currentPropertyId])

    useEffect(() => {
        let newFormValues: unknown | undefined

        if (defaultValues) {
            newFormValues = {
                ...defaultValues,
                date: defaultValues.date.toISO() ?? undefined,
                type: defaultValues.type,
                files: [],
            }
        } else {
            newFormValues = {
                date: DateTime.now().toISO() ?? undefined,
                paymentMethod: PropertyIncomePaymentMethod.Bank,
                files: [],
                propertyId: currentPropertyId ?? propertyId ?? rentingPeriodDetails?.propertyId ?? (acceptedProperties() ?? [])[0]?.id,
                type: getValues("type"),
                currencyId: currentRentingPeriod?.currencyId ?? userData?.currency.id,
                value: currentRentingPeriod?.value,
                rentingPeriodId: currentRentingPeriod?.id,
            }
        }
        reset(newFormValues as AddIncomeRequest)
    }, [defaultValues, userData, editableProperties, rentingPeriodDetails, currentRentingPeriod, propertyId, acceptedProperties])

    return (
        <>
            <Box display='flex' flexDirection='column' gap={2} marginY={2} justifyContent={"center"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <LightTooltip
                        title={
                            currentPropertyId != null || defaultValues != null || rentingPeriodDetails != null ? translate("can_not_edit_field") : ""
                        }
                        arrow
                    >
                        <span style={{ width: "100%" }}>
                            <RHFAutocomplete
                                freeSolo={false}
                                autocompleteProps={{
                                    sx: {
                                        width: "100%",
                                    },
                                    noOptionsText: translate("no_value_found"),
                                }}
                                matchId
                                name={"propertyId"}
                                options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                label={translate("property")}
                                required={currentPropertyId == null}
                                disabled={currentPropertyId != null || defaultValues != null || rentingPeriodDetails != null}
                            />
                        </span>
                    </LightTooltip>
                    <RHFSelect
                        disabled={defaultValues != null || rentingPeriodDetails != null}
                        name={"rentingPeriodId"}
                        size='small'
                        label={translate("tenant-data.rentingPeriod")}
                        required
                    >
                        {rentingPeriodsFromAProperty && rentingPeriodsFromAProperty.length === 0 ? (
                            <MenuItem style={{ textTransform: "none" }} disabled>
                                {translate("no_renting_period_for_this_property")}
                            </MenuItem>
                        ) : (
                            rentingPeriodsFromAProperty
                                ?.sort((a, b) => b.moveOutDate.toMillis() - a.moveOutDate.toMillis())
                                ?.map((period) => (
                                    <MenuItem key={period.id} value={period.id} style={{ textTransform: "none" }}>
                                        {`${period.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${period.moveOutDate.toLocaleString(
                                            DateTime.DATE_SHORT,
                                        )}`}
                                    </MenuItem>
                                ))
                        )}
                    </RHFSelect>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName='currencyId'
                        unitType='currency'
                        name='value'
                        size='small'
                        label={translate("amount")}
                        required
                    />
                    <RHFSelect name='paymentMethod' label={translate("income-table.paymentMethod")} size='small'>
                        <MenuItem value={PropertyIncomePaymentMethod.Cash}>{translate("income-table.paymentMethod-0")}</MenuItem>
                        <MenuItem value={PropertyIncomePaymentMethod.Bank}>{translate("income-table.paymentMethod-1")}</MenuItem>
                    </RHFSelect>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFDatePicker
                        name='date'
                        inputProps={{ fullWidth: true, size: "small" }}
                        label={translate("date")}
                        disableFuture
                        minDate={rentingPeriodDetails ? rentingPeriodDetails.moveInDate : undefined}
                        maxDate={rentingPeriodDetails ? rentingPeriodDetails.moveOutDate : undefined}
                    />
                    <RHFSelect name='type' label={translate("income-table.chooseIncomeType")} size='small'>
                        <MenuItem value={PaymentType.Rent}>{translate("income-table.incomeType-1")}</MenuItem>
                        <MenuItem value={PaymentType.SecurityDeposit}>{translate("income-table.incomeType-2")}</MenuItem>
                        <MenuItem value={PaymentType.Fees}>{translate("income-table.incomeType-3")}</MenuItem>
                        <MenuItem value={PaymentType.Damages}>{translate("income-table.incomeType-4")}</MenuItem>
                    </RHFSelect>
                </Box>
            </Box>
            {defaultValues == null && (
                <RHFUpload
                    name={"files"}
                    multiple
                    maxSize={getOwnerMaxSize(editableProperties, userData?.features?.maxPropertySize, currentPropertyId ?? getValues("propertyId"))}
                    icon={"mdi:file-document-plus-outline"}
                />
            )}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
        </>
    )
}
