import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import AddPendingExpenseForm, { AddPendingExpenseRequest } from "../../../sections/expenses/pending/AddPendingExpenseForm"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import * as jsonpatch from "fast-json-patch"
import { DateTime } from "luxon"
import { updateExpenseMutation } from "../../../mutations/expenses"
import { ExpenseStatus, useSinglePendingExpenseQueryFn } from "../../../queries/expenses"
import { uploadEntityFileMutation } from "../../../mutations/entityFile"
import { PropertyFileType } from "../../../redux/slices/AddProperty"

export default function AddPendingExpenseGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateExpense, isPending: isExpenseUpdating } = updateExpenseMutation()
    const { data: currentPendingExpense } = useSinglePendingExpenseQueryFn(editingItem?.id)
    const { mutateAsync: uploadFile, isPending: isUploadingFile } = uploadEntityFileMutation()

    const onAddingExpense = useCallback(
        async (data: AddPendingExpenseRequest) => {
            if (!data.id) return
            for (const newFile of data.files) {
                if ("path" in newFile) {
                    await uploadFile({
                        data: {
                            uploadType: PropertyFileType.Expense,
                            referenceId: data.id!.toString(),
                            files: [newFile],
                        },
                    })
                }
            }

            const operations = jsonpatch.compare(
                {
                    ...currentPendingExpense,
                    date: currentPendingExpense?.date.toISODate(),
                    dueDate: currentPendingExpense?.dueDate.toISODate(),
                    files: [],
                },
                {
                    ...data,
                    assignee: Number(data.assignee),
                    dueDate: DateTime.fromISO(data.dueDate).toISODate(),
                    date: DateTime.fromISO(data.date).toISODate(),
                    status: ExpenseStatus.Accepted,
                    files: [],
                },
            )

            await updateExpense({ propertyId: data.propertyId, expenseId: data.id, operations })
        },
        [currentPendingExpense, updateExpense, uploadFile],
    )

    const onClose = useCallback(() => {
        if (isMobile && modalOpen === AppModals.PendingExpenseStatus) {
            dispatch(setModalOpen(AppModals.PendingExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, modalOpen, isMobile])

    return (
        <>
            <CustomDialog
                title={translate("expenses.addExpense")}
                open={!isMobile && modalOpen === AppModals.PendingExpenseStatus}
                onClose={onClose}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddPendingExpenseForm onClose={onClose} onSave={onAddingExpense} isLoading={isUploadingFile || isExpenseUpdating} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("expenses.addExpense")}
                isOpen={isMobile && modalOpen === AppModals.PendingExpenseStatus}
                onClose={onClose}
                headerColor={theme.palette.warning.main}
            >
                <AddPendingExpenseForm onClose={onClose} onSave={onAddingExpense} isLoading={isUploadingFile || isExpenseUpdating} />
            </DetailsSheet>
        </>
    )
}
