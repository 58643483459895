import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import React, { SyntheticEvent, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useUserBankAccountsQuery } from "../../../queries/userData"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

export default function BankAccountDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: bankAccounts } = useUserBankAccountsQuery()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const currentBankAccount = useMemo(
        () => bankAccounts?.items.find((bankAccount) => bankAccount.id === editingItem?.id),
        [bankAccounts, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentBankAccount == null) return []

        return [
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_properties",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.BankAccountProperties))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditBankAccount))
                    dispatch(setEditingItem({ id: currentBankAccount.id }))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteBankAccount))
                },
            },
        ]
    }, [currentBankAccount, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.BankAccountDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentBankAccount?.holder}
            subtitle={currentBankAccount?.iban}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo(
                "mdi:cardholder",
                `${translate("holder")}: ${currentBankAccount?.holder}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:bank",
                `${translate("bank")}: ${translate(`bank-name-${currentBankAccount?.bank}`)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:piggy-bank-outline",
                `${translate("iban")}: ${currentBankAccount?.iban}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentBankAccount?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-clock",
                `${translate("lastModifiedAt")}: ${currentBankAccount?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
