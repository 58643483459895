import { Box, InputAdornment, TextField } from "@mui/material"
import { useUserPropertiesQuery } from "../../queries/userData"
import * as React from "react"
import PercentIcon from "@mui/icons-material/Percent"
import { useLocales } from "rentzz"
import { GroupProperty } from "../../queries/groups"

interface Props {
    property: GroupProperty
}
export default function SharingGroupExpense({ property }: Props) {
    const { translate } = useLocales()
    const { data: properties } = useUserPropertiesQuery()

    return (
        <Box display={"flex"} width={"100%"} gap={1}>
            <TextField
                name={"propertyId"}
                disabled
                value={properties?.find((p) => p.id === property.propertyId)?.label}
                label={translate("property")}
                sx={{ width: "50%" }}
                size={"small"}
            />
            <TextField
                name={"sharedExpense"}
                label={translate("percentage")}
                value={property.expenseShare * 100}
                disabled
                sx={{ width: "50%" }}
                size={"small"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={"end"}>
                            <PercentIcon fontSize={"small"} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}
