import { TenantRequiredInfo } from "../sections/contracts/newContract/TenantRequiredData"
import { number, object, string } from "yup"
import { ContactInfoPersonType } from "../queries/userData"

export const TenantInfoSchema = (tenantRequiredInfo: TenantRequiredInfo) => {
    return object().shape({
        fullName: string()
            .test("requiredTest", "name_required", (val) => {
                return !tenantRequiredInfo.isFullNameRequired || (val != null && val.trim() !== "")
            })
            .required(),
        county: string()
            .test("requiredTest", "county_required", (val) => {
                return !tenantRequiredInfo.isCountyRequired || (val != null && val.trim() !== "")
            })
            .required(),
        city: string()
            .test("requiredTest", "city_required", (val) => {
                return !tenantRequiredInfo.isCityRequired || (val != null && val.trim() !== "")
            })
            .required(),
        street: string().test("requiredTest", "street_required", (val) => {
            return !tenantRequiredInfo.isStreetRequired || (val != null && val.trim() !== "")
        }),
        streetNumber: string().test("requiredTest", "number_required", (val) => {
            return !tenantRequiredInfo.isStreetNumberRequired || (val != null && val.trim() !== "")
        }),
        buildingNumber: string().test("requiredTest", "number_required", (val) => {
            return !tenantRequiredInfo.isBuildingNumberRequired || (val != null && val.trim() !== "")
        }),
        stair: string().test("requiredTest", "stair_required", (val) => {
            return !tenantRequiredInfo.isStairRequired || (val != null && val.trim() !== "")
        }),
        floor: string().test("requiredTest", "floor_required", (val) => {
            return !tenantRequiredInfo.isFloorRequired || val != null
        }),
        apartment: string().test("requiredTest", "apartment_required", (val) => {
            return !tenantRequiredInfo.isApartmentRequired || (val != null && val.trim() !== "")
        }),
        contactInfoPersonType: number().required(),
        identitySeries: string()
            .matches(/^[A-Z]+$/, "must_be_uppercase")
            .min(2, "two_characters")
            .max(2, "two_characters")
            .test("test", "identity_series_required", (val, context) => {
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Individual)
                    return !tenantRequiredInfo.isIdentitySeriesRequired || (val != null && val.trim() !== "")
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Company) return val == null || val.trim() === ""
            }),

        identityNumber: string()
            .optional()
            .min(6, "six_characters")
            .max(6, "six_characters")
            .test("test", "identity_number_required", (val, context) => {
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Individual)
                    return !tenantRequiredInfo.isIdentityNumberRequired || (val != null && val.trim() !== "")
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Company) return val == null || val.trim() === ""
            }),
        phone: string().test("requiredTest", "phone_required", (val) => {
            return !tenantRequiredInfo.isPhoneRequired || (val != null && val.trim() !== "")
        }),

        companyName: string().test("test", "required_companyName", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyRegistrationNumber: string().test("test", "required_companyRegistrationNumber", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyPosition: string().test("test", "required_as", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
    })
}
