import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import React, { useCallback } from "react"
import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor"
import { IToolbarItemModel } from "@syncfusion/ej2-react-richtexteditor"
import { downloadFile } from "../../../utils/actions"

export default function ImageViewer() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)

    let imgObj: ImageEditorComponent | null

    const onSaveImage = useCallback(async () => {
        await downloadFile({
            downloadId: editingItem?.downloadId,
            downloadName: editingItem?.downloadName,
            extension: editingItem?.extension,
        })
    }, [downloadFile, editingItem])

    const saveImage: IToolbarItemModel = {
        id: "saveItem",
        tooltipText: "Save image",
        text: `\u21E9 ${translate("save")}`,
        align: "Left",
        click: onSaveImage,
    }

    const closeModal: IToolbarItemModel = {
        id: "saveItem",
        tooltipText: "Save image",
        text: "&#x2717",
        align: "Right",
        click: () => dispatch(setModalOpen(editingItem?.previousModal)),
    }

    const toolbar = ["ZoomIn", "ZoomOut", saveImage, closeModal]

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.ImageViewer}
                isFullScreen={isMobile}
                maxWidth={"md"}
                title={undefined}
                showCloseIcon={false}
                onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
                paddingContent={0}
                paddingDialogBottom={0}
            >
                <Box height={650} padding={0}>
                    <ImageEditorComponent
                        ref={(img) => {
                            imgObj = img
                        }}
                        created={() => imgObj && imgObj.open(editingItem?.url)}
                        toolbar={toolbar}
                    />
                </Box>
            </CustomDialog>
            <DetailsSheet
                isLoading={false}
                title={translate("preview")}
                isOpen={isMobile && modalOpen === AppModals.ImageViewer}
                onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
            >
                <Box height={"75vh"}>
                    <ImageEditorComponent
                        ref={(img) => {
                            imgObj = img
                        }}
                        created={() => imgObj && imgObj.open(editingItem?.url)}
                        toolbar={toolbar}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
