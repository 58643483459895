import { QueryFunctionContext, useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { AppContext, useIsMobile } from "rentzz"
import { AppModals } from "../redux/slices/App"
import { sum } from "lodash"
import { GridSortModel } from "@mui/x-data-grid-pro"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { OwnerPropertyMeter, PropertyMeterValue, PropertyMeterValuesResponse, TenantPropertyMeter } from "../redux/slices/AddProperty"
import { useMemo } from "react"

export const useMetersQueryFn = (selectedPropertyId?: number) => {
    const { currentPropertyId, context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Meters)

    return useQuery({
        queryFn: async ({ queryKey }: QueryFunctionContext) => {
            const [_, data] = queryKey
            const { id } = data as { id: number }
            return context === AppContext.Owner ? Api.fetchMeters(id) : Api.fetchMetersAsTenant(id, currentRentingPeriodId!)
        },
        queryKey: [ComplexQueryIDs.Meters, { id: currentPropertyId || selectedPropertyId, rentingPeriodId: currentRentingPeriodId }],
        staleTime: Infinity,
        enabled:
            (context === AppContext.Owner && currentPropertyId != null) ||
            selectedPropertyId != null ||
            (context == AppContext.Tenant && currentPropertyId != null && currentRentingPeriodId != null),
        select: (data: TenantPropertyMeter[] | OwnerPropertyMeter[]) => {
            return data.map((meter) => ({
                ...meter,
                canWrite: context === AppContext.Owner ? editablePropertiesIds?.includes(meter.propertyId) ?? false : false,
                canDelete: context === AppContext.Owner ? deletablePropertiesIds?.includes(meter.propertyId) ?? false : false,
            }))
        },
    })
}

export const useMeterValuesHistoryQueryFn = (currentMeterId: number | undefined, page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { editingItem, modalOpen, currentRentingPeriodId, context } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [
            ComplexQueryIDs.MetersValuesHistory,
            { meterId: currentMeterId ?? editingItem?.id, page, pageSize, sortingColumns, rentingPeriodId: currentRentingPeriodId },
        ],
        queryFn: Api.fetchMeterValuesHistory,
        staleTime: Infinity,
        enabled:
            currentMeterId != null ||
            (!!editingItem?.id &&
                modalOpen === AppModals.MeterValuesHistory &&
                pageSize > 0 &&
                ((context === AppContext.Tenant && currentRentingPeriodId != null) || context == AppContext.Owner)),
        placeholderData: () => ({
            items: [],
            count: 20,
        }),
    })
}

export const usePendingMeterValuesQueryFn = (page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { modalOpen } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [ComplexQueryIDs.PendingMeterValues, { page, pageSize, sortingColumns }],
        queryFn: Api.fetchPendingMeterValues,
        staleTime: Infinity,
        enabled: modalOpen === AppModals.PendingReadings,
        placeholderData: () => ({
            items: [],
            count: 20,
        }),
    })
}

export const useInfinitePendingMeterValuesQueryFn = () => {
    const { modalOpen } = useSelector((state) => state.appState)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.PendingMeterValues],
        queryFn: Api.fetchInfinitePendingMeterValues,
        staleTime: Infinity,
        enabled: modalOpen != null && modalOpen === AppModals.PendingReadings,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const useInfiniteMeterValuesHistoryQueryFn = () => {
    const { editingItem, modalOpen, context, currentRentingPeriodId } = useSelector((state) => state.appState)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.MetersValuesHistory, { meterId: editingItem?.id, rentingPeriodId: currentRentingPeriodId }],
        queryFn: Api.fetchInfiniteMeterValuesHistory,
        staleTime: Infinity,
        enabled:
            !!editingItem?.id &&
            modalOpen != null &&
            [AppModals.MeterValuesHistory, AppModals.EditReading, AppModals.AddNewIndex].includes(modalOpen) &&
            ((context === AppContext.Tenant && currentRentingPeriodId != null) || context == AppContext.Owner),
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getReadingByIdQueryFn = (readingId?: number): PropertyMeterValue | undefined => {
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()

    return useMemo(() => {
        if (isMobile) {
            const readings = queryClient.getQueryData([ComplexQueryIDs.PendingMeterValues]) as
                | {
                      pages: PropertyMeterValuesResponse[]
                  }
                | undefined
            const arrayOdReadings = readings?.pages.flatMap((r) => r.items) as PropertyMeterValue[]

            const reading = arrayOdReadings?.find((reading) => reading?.id == readingId)
            return reading ?? undefined
        }
        const readingsPages = queryClient.getQueriesData({
            queryKey: [ComplexQueryIDs.PendingMeterValues],
        })

        const arrayOfReadings = (readingsPages?.flatMap((readingWithKey) => readingWithKey[1]) as PropertyMeterValuesResponse[])?.flatMap(
            (r) => r?.items,
        ) as PropertyMeterValue[]
        const reading = arrayOfReadings?.find((reading) => reading?.id == readingId)
        if (reading) return reading ?? undefined
    }, [queryClient, isMobile, readingId])
}
