import { StackedChart } from "rentzz"
import { getGroupRentingPeriods } from "../../../queries/groups"
import React from "react"
import { Box } from "@mui/material"

const PropertyHistory = () => {
    return (
        <Box>
            <StackedChart useGroupsRentingPeriodsQuery={getGroupRentingPeriods} />
        </Box>
    )
}

export default PropertyHistory
