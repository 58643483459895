import {
    CustomFile,
    ExpenseAssignee,
    FormProvider,
    LightTooltip,
    RHFCheckbox,
    RHFDatePicker,
    RHFSelect,
    RHFUnitInput,
    useIsMobile,
    useLocales,
} from "rentzz"
import { Box, Button, MenuItem } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useDispatch, useSelector } from "../../../redux/store"
import { RecurringType } from "../../../queries/expenses"
import { AddRecurringExpenseDetailsStepSchema } from "../../../validations/recurringexpense"
import { AddRecurringExpenseRequest } from "../../../mutations/expenses"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import RHFProviderInput from "../../../components/RHFProviderInput"
import RHFLabelInput from "../../../components/RHFLabelInput"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { setEditingItem } from "../../../redux/slices/App"

export interface AddRecurringExpenseDetailsStepData {
    assignee: ExpenseAssignee
    value: number
    currencyId: number
    recurringType: RecurringType
    shouldNotifyOtherParty: boolean
    files: CustomFile[]
    propertyId: number
    labelId?: number
    associatedProviderId?: number
    shouldGenerateInvoice: boolean
    expirationDate?: string
}

interface AddRecurringExpenseFormProps {
    onSave: (data: AddRecurringExpenseDetailsStepData) => void
    presetValues: Partial<AddRecurringExpenseRequest> | undefined | null
    onClose: () => void
    isAdding: boolean
}

export default function AddRecurringExpenseDetailsStep({ onSave, presetValues, onClose, isAdding }: AddRecurringExpenseFormProps) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { editablePropertiesIds, editableProperties } = usePermissions(PermissionType.RecurringExpense)
    const defaultAssignee = useFeatureValue<ExpenseAssignee>(UserFlags.DefaultAssignee, ExpenseAssignee.ME)
    const [assignee, setAssignee] = useState<ExpenseAssignee>(presetValues?.assignee ?? defaultAssignee)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const methods = useForm<AddRecurringExpenseDetailsStepData>({
        resolver: yupResolver<AddRecurringExpenseDetailsStepData>(AddRecurringExpenseDetailsStepSchema),
        mode: "onChange",
    })
    const { reset, handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "assignee" && v.assignee != null) {
                setAssignee(v.assignee)
            }
            if (name === "shouldGenerateInvoice") {
                dispatch(setEditingItem({ shouldGenerateInvoice: v.shouldGenerateInvoice }))
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, dispatch])

    useEffect(() => {
        reset(
            presetValues ?? {
                assignee: defaultAssignee,
                recurringType: RecurringType.Monthly,
                propertyId: currentPropertyId ?? editablePropertiesIds?.at(0),
                currencyId: user?.currency.id,
                shouldNotifyOtherParty: false,
                shouldGenerateInvoice: false,
                expirationDate: undefined,
                files: [],
            },
        )
    }, [presetValues, currentPropertyId, user, editablePropertiesIds])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <LightTooltip title={currentPropertyId != null || !isAdding ? translate("can_not_edit_field") : ""} arrow>
                            <span style={{ width: "100%" }}>
                                <RHFSelect
                                    name={"propertyId"}
                                    size='small'
                                    disabled={currentPropertyId != null || !isAdding}
                                    label={translate("property")}
                                    required={currentPropertyId == null}
                                >
                                    {editableProperties?.map((property) => (
                                        <MenuItem key={property.id} value={property.id} style={{ textTransform: "none" }}>
                                            {property.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </span>
                        </LightTooltip>
                        <RHFSelect name={"assignee"} size='small' variant={"outlined"} label={translate("assignee")} required>
                            <MenuItem value={0} style={{ textTransform: "none" }}>
                                {translate(`expenses.assignee-0${customPaidBy ? "-" + customPaidBy : ""}`)}
                            </MenuItem>
                            <MenuItem value={1} style={{ textTransform: "none" }}>
                                {translate("expenses.assignee-1")}
                            </MenuItem>
                        </RHFSelect>
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFSelect
                            name={"recurringType"}
                            size='small'
                            variant={"outlined"}
                            label={translate("recurring_expense.recurringType")}
                            required
                        >
                            <MenuItem value={RecurringType.Monthly} style={{ textTransform: "none" }}>
                                {translate(`recurring-type-${RecurringType.Monthly}`)}
                            </MenuItem>
                        </RHFSelect>
                        <RHFUnitInput
                            data={currencies ?? []}
                            unitName={"currencyId"}
                            unitType={"currency"}
                            name='value'
                            type='number'
                            size='small'
                            label={translate("amount")}
                            required
                        />
                    </Box>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFProviderInput name={"associatedProviderId"} />
                        <RHFLabelInput name={"labelId"} />
                    </Box>
                    <RHFDatePicker name='expirationDate' label={translate("expiration_recurring_expense_date")} required />
                    <Box>
                        {assignee === ExpenseAssignee.TENANT && <RHFCheckbox name={"shouldNotifyOtherParty"} label={translate("notify_tenant")} />}
                        {areInvoicingConfigurationOn && <RHFCheckbox name={"shouldGenerateInvoice"} label={translate("shouldGenerateInvoice")} />}
                    </Box>
                </Box>

                {/* {isAdding && <RHFUpload name={"files"} multiple maxSize={getMaxSize()} icon={"mdi:file-document-plus-outline"} />} */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={onClose}>
                        {translate("back")}
                    </Button>
                    <Button type='submit' variant='contained' color={"primary"}>
                        {translate("next")}
                    </Button>
                </Box>
            </FormProvider>
        </>
    )
}
