import { CustomFile, FormProvider, LightTooltip, RHFAutocomplete, RHFCheckbox, RHFDatePicker, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { PropertyRevision } from "../../../redux/slices/AddProperty"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect, useState } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddRevisionSchema } from "../../../validations/revisions"
import { DateTime } from "luxon"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"

export interface AddRevisionRequest {
    id?: string
    propertyId: number
    name: string
    description: string
    date: string
    files: CustomFile[]
    visibleToTenant: boolean
    allowTenantChoseDate: boolean
}

interface Props {
    onClose: () => void
    onSave: (data: AddRevisionRequest) => void
    isLoading: boolean
    defaultValues?: PropertyRevision
}

export default function AddOrEditRevisionForm({ onClose, onSave, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.Revisions)
    const [isVisible, setIsVisible] = useState<boolean | undefined>(defaultValues?.visibleToTenant ?? false)

    const methods = useForm<AddRevisionRequest>({
        resolver: yupResolver<AddRevisionRequest>(AddRevisionSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "visibleToTenant") {
                setIsVisible(v.visibleToTenant)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, setIsVisible])

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                name: defaultValues.name,
                description: defaultValues.description,
                propertyId: defaultValues.propertyId,
                date: defaultValues.date.toISO() ?? undefined,
                visibleToTenant: defaultValues.visibleToTenant ?? false,
                allowTenantChoseDate: defaultValues.allowTenantChoseDate ?? false,
                files: [],
            })
        }

        if (defaultValues == null) {
            reset({
                date: DateTime.now().toISO() ?? undefined,
                propertyId: currentPropertyId ?? (editableProperties ?? [])[0]?.id,
                visibleToTenant: false,
                allowTenantChoseDate: false,
                files: [],
            })
        }
    }, [defaultValues, reset])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <Box display={"flex"} gap={2}>
                        <RHFTextField name={"name"} label={translate("title")} size={"small"} required />
                        <RHFDatePicker name='date' label={translate("revisionDate")} required />
                    </Box>

                    <RHFTextField name={"description"} label={translate("description")} multiline required minRows={3} maxRows={3} />
                    <LightTooltip title={currentPropertyId != null || defaultValues != null ? translate("can_not_edit_field") : ""} arrow>
                        <span style={{ width: "100%" }}>
                            <RHFAutocomplete
                                autocompleteProps={{
                                    sx: {
                                        width: "100%",
                                    },
                                    noOptionsText: translate("no_value_found"),
                                }}
                                matchId
                                name={"propertyId"}
                                options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                label={translate("property")}
                                required={currentPropertyId == null}
                                disabled={currentPropertyId != null || defaultValues != null}
                                freeSolo={false}
                            />
                        </span>
                    </LightTooltip>
                    <Box>
                        <RHFCheckbox name={"visibleToTenant"} label={translate("revisionVisibleToTenant")} />
                        {isVisible && <RHFCheckbox name={"allowTenantChoseDate"} label={translate("allowTenantChoseDate")} />}
                    </Box>

                    {/* {defaultValues == null && ( */}
                    {/*     <RHFUpload */}
                    {/*         name={"files"} */}
                    {/*         multiple */}
                    {/*         maxSize={getOwnerMaxSize(properties, userData?.features?.maxPropertySize, currentPropertyId)} */}
                    {/*         icon={"mdi:file-document-plus-outline"} */}
                    {/*     /> */}
                    {/* )} */}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
