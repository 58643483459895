import React, { useCallback } from "react"
import { DetailsSheet, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { InsuranceType, PropertyFileType } from "../../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../../redux/store"
import { getInsuranceByIdQueryFn } from "../../../queries"
import { getNotificationIcon, getNotificationIconInsideActionSheet, getNotificationTile } from "../../../sections/insurance/utils"
import { DateTime } from "luxon"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import { RoleName, useUserDataQueryFn, useUserPropertiesQuery } from "../../../queries/userData"
import { renderActionSheetInfo } from "../utils"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import DeleteIcon from "@mui/icons-material/Delete"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

interface InsuranceDetailsSheetProps {
    onClose: () => void
}
export default function InsuranceDetailsSheet({ onClose }: InsuranceDetailsSheetProps) {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: currentInsurance, isFetching: isCurrentInsuranceFetching } = getInsuranceByIdQueryFn(editingItem?.id)
    const { data: properties } = useUserPropertiesQuery()

    const options = useCallback((): CustomSheetOption[] => {
        if (currentInsurance == null) return []

        return [
            {
                icon: getNotificationIcon(currentInsurance, MOBILE_ICON_SIZE, currentInsurance.shouldNotifyForRenewal ? "primary" : "disabled"),
                title: currentInsurance.shouldNotifyForRenewal ? "insurance.notificationOnMobile" : "insurance.notificationOffMobile",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditInsuranceNotification))
                },
                isDisabled: currentInsurance.endDate < DateTime.now() || user?.roles.roleName === RoleName.FREE,
                hidden: !currentInsurance.canWrite,
            },
            {
                icon: <ModeEditIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"primary"} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditInsurance))
                },
                hidden: !currentInsurance.canWrite || user?.roles.roleName === RoleName.FREE,
            },
            {
                icon: <CloudDownloadIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"info"} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: currentInsurance.id,
                            type: PropertyFileType.Insurance,
                            name: currentInsurance.insurer,
                            insuranceType: currentInsurance.insuranceType,
                            canWrite: currentInsurance.canWrite,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"error"} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteInsurance))
                },
                hidden: !currentInsurance.canDelete,
            },
        ]
    }, [currentInsurance, dispatch, theme])

    const getAmount = useCallback(
        (value: number, insuranceId?: number) => {
            if (currencyData == null && insuranceId == null) return ""
            const currency = currentInsurance?.currencyId
            if (!currency) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currency).code,
            })
        },
        [currentInsurance, currencyData, intl],
    )

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InsuranceDetails}
            onClose={onClose}
            isLoading={currentInsurance == null || isCurrentInsuranceFetching}
            options={options()}
            title={currentInsurance?.insurer}
            subtitle={translate(`insurance.type-${currentInsurance?.insuranceType}`)}
            headerColor={currentInsurance?.insuranceType === InsuranceType.Mandatory ? theme.palette.warning.main : theme.palette.info.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:cash",
                    getAmount(currentInsurance?.value ?? 0, currentInsurance?.id),
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("insurance_period")}: ${currentInsurance?.startDate?.toLocaleString(
                        DateTime.DATE_SHORT,
                    )} - ${currentInsurance?.endDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {currentInsurance &&
                    renderActionSheetInfo(
                        getNotificationIconInsideActionSheet(currentInsurance),
                        translate(getNotificationTile(currentInsurance)),
                        currentInsurance.shouldNotifyForRenewal ? theme.palette.primary.main : theme.palette.grey["400"],
                        theme.palette.grey["600"],
                    )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    properties?.find((p) => p.id === currentInsurance?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
