import PaymentDetailsSheet from "./PaymentDetailsSheet"
import { useCallback } from "react"
import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import AddOrEditPaymentGuard from "./AddOrEditPaymentGuard"
import DeletePaymentGuard from "./DeletePaymentGuard"
import { useIsMobile } from "rentzz"

export default function PaymentsGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        dispatch(setErrorMessage(undefined))
        if (editingItem?.choosePayment) {
            return dispatch(setModalOpen(AppModals.TenantPayment))
        }
        if (isMobile && modalOpen !== AppModals.PaymentDetails && modalOpen !== AppModals.AddPayment) {
            dispatch(setModalOpen(AppModals.PaymentDetails))
        } else if (isMobile && modalOpen === AppModals.PaymentDetails && editingItem?.isFromAnExpense) {
            dispatch(setModalOpen(AppModals.AssignedIncomes))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile, modalOpen, editingItem])

    return (
        <>
            <PaymentDetailsSheet onClose={closeModal} />
            <AddOrEditPaymentGuard onClose={closeModal} />
            <DeletePaymentGuard onClose={closeModal} />
        </>
    )
}
