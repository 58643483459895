import { useIsMobile, useLocales } from "rentzz"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import Invitation from "./Invitation"
import Meters from "./Meters"
import Payment from "./Payment"
import Contract from "./Contract"
import PropertyInfo from "./PropertyInfo"
import Signature from "./Signature"
import CheckContract from "./CheckContract"
import { RoleName, useUserDataQueryFn } from "../../../../queries/userData"
import OwnerContact from "./OwnerContact"
import { useDispatch } from "../../../../redux/store"
import { setEditingItem } from "../../../../redux/slices/App"
import { AddTenantRequest } from "../../../types/AddTenant"

interface AddTenantProps {
    onClose: () => void
    onSave: (data: AddTenantRequest) => void
    isLoading: boolean
}

enum AddTenantFormSteps {
    Invitation,
    RentDetails,
    Meters,
    Contract,
    OwnerContactInfo,
    PropertyInfo,
    CheckContract,
    Signature,
}

export default function AddTenant({ onClose, onSave, isLoading }: AddTenantProps) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState<AddTenantFormSteps>(AddTenantFormSteps.Invitation)
    const [newTenantRequest, setNewTenantRequest] = useState<AddTenantRequest>()
    const { data: userData } = useUserDataQueryFn()

    useEffect(() => {
        if (newTenantRequest?.contractData?.contractId == null) {
            dispatch(setEditingItem({ width: "sm" }))
        } else {
            dispatch(setEditingItem({ width: "md" }))
        }
    }, [newTenantRequest?.contractData?.contractId])

    const getSteps = useCallback(() => {
        const steps = [
            {
                title: translate("tenant_details"),
            },
            {
                title: translate("rent_details"),
            },
            {
                title: translate("meters_details"),
            },
            {
                title: translate("contract"),
            },
        ]

        if (newTenantRequest?.contractData?.contractId != null) {
            steps.push(
                {
                    title: translate("owner_contact"),
                },
                {
                    title: translate("property_data"),
                },

                {
                    title: translate("check_contract"),
                },
                {
                    title: translate("signature"),
                },
            )
        }

        return steps
    }, [translate, newTenantRequest])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case 0:
                return (
                    <Invitation
                        defaultValues={newTenantRequest?.details}
                        onClose={onClose}
                        onNext={(details) => {
                            setNewTenantRequest((prevState) => ({ ...prevState, details }))
                            setActiveStep(AddTenantFormSteps.RentDetails)
                        }}
                    />
                )
            case 1:
                return (
                    <Payment
                        defaultValues={newTenantRequest?.rentDetails}
                        hasEmails={(newTenantRequest?.details?.mails.length ?? 0) > 0}
                        onBack={() => {
                            setNewTenantRequest((prevState) => ({
                                ...prevState,
                                details: {
                                    ...prevState!.details!,
                                    tenantLastName: undefined,
                                    tenantFirstName: undefined,
                                    createTenantAccount: false,
                                    mails: [],
                                },
                            }))
                            setActiveStep(AddTenantFormSteps.Invitation)
                        }}
                        onNext={(rentDetails) => {
                            setNewTenantRequest((prevState) => ({ ...prevState, rentDetails }))
                            setActiveStep(AddTenantFormSteps.Meters)
                        }}
                    />
                )
            case 2:
                return (
                    <Meters
                        onBack={() => setActiveStep(AddTenantFormSteps.RentDetails)}
                        onNext={(meters) => {
                            setNewTenantRequest((prevState) => ({ ...prevState, meters }))
                            setActiveStep(AddTenantFormSteps.Contract)
                        }}
                        propertyId={newTenantRequest?.details?.propertyId ?? 0}
                        isLoading={isLoading}
                    />
                )
            case 3: {
                return (
                    <Contract
                        canChangeContract={!newTenantRequest?.details?.createTenantAccount}
                        onNext={(contract) => {
                            if (contract.contractId == null && newTenantRequest != null) {
                                onSave({ ...newTenantRequest, contractData: { ...contract, files: contract.files } })
                            } else {
                                if (userData?.roles.roleName === RoleName.BRONZE || userData?.roles.roleName === RoleName.FREE) return
                                setNewTenantRequest((prevState) => ({ ...prevState, contractData: { ...prevState?.contractData, ...contract } }))
                                setActiveStep(AddTenantFormSteps.OwnerContactInfo)
                            }
                        }}
                        onContractIdChange={(contractId) => {
                            setNewTenantRequest((prevState) => ({
                                ...prevState,
                                contractData: { ...prevState?.contractData, contractId, files: prevState?.contractData?.files ?? [] },
                            }))
                        }}
                        onBack={() => setActiveStep(AddTenantFormSteps.Meters)}
                        isLoading={isLoading}
                        contractId={newTenantRequest?.contractData?.contractId}
                        defaultValues={newTenantRequest?.contractData}
                    />
                )
            }
            case 4: {
                return (
                    <OwnerContact
                        onNext={(ownerContactInfo) => {
                            setNewTenantRequest((prevState) => ({
                                ...prevState,
                                ownerContactInfo: {
                                    ...ownerContactInfo,
                                    bankAccount: ownerContactInfo.bankAccount === "-1" ? "" : ownerContactInfo.bankAccount,
                                },
                            }))
                            setActiveStep(AddTenantFormSteps.PropertyInfo)
                        }}
                        onChange={(ownerContactInfo) => {
                            setNewTenantRequest((prevState) => ({ ...prevState, ownerContactInfo }))
                        }}
                        onBack={() => setActiveStep(AddTenantFormSteps.Contract)}
                    />
                )
            }
            case 5: {
                return (
                    <PropertyInfo
                        defaultValues={newTenantRequest?.propertyInfo}
                        contractId={newTenantRequest?.contractData?.contractId ?? ""}
                        propertyId={newTenantRequest?.details?.propertyId ?? 0}
                        onNext={(propertyInfo) => {
                            setNewTenantRequest((prevState) => ({ ...prevState, propertyInfo }))
                            setActiveStep(AddTenantFormSteps.CheckContract)
                        }}
                        onBack={() => {
                            setActiveStep(AddTenantFormSteps.OwnerContactInfo)
                        }}
                    />
                )
            }
            case 6: {
                return (
                    <CheckContract
                        data={newTenantRequest!}
                        onNext={() => setActiveStep(AddTenantFormSteps.Signature)}
                        onBack={() => setActiveStep(AddTenantFormSteps.PropertyInfo)}
                    />
                )
            }
            case 7: {
                return (
                    <Signature
                        isLoading={isLoading}
                        defaultValue={newTenantRequest?.contractData?.signature}
                        onNext={(signature) => {
                            setNewTenantRequest((prevState) => ({
                                ...prevState,
                                contractData: {
                                    ...newTenantRequest?.contractData,
                                    signature,
                                },
                            }))
                        }}
                        onSave={(signature) => {
                            onSave({
                                ...newTenantRequest,
                                contractData: {
                                    ...newTenantRequest?.contractData,
                                    signature,
                                    contractId: newTenantRequest?.contractData?.contractId,
                                    files: newTenantRequest?.contractData?.files ?? [],
                                },
                            })
                        }}
                        onBack={() => setActiveStep(AddTenantFormSteps.CheckContract)}
                    />
                )
            }
        }
    }, [newTenantRequest, activeStep, isLoading, userData])

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                {getSteps().map((step, index) => {
                    return (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    )
                })}
            </Stepper>
            {!isMobile && renderActiveStep()}
        </Box>
    )
}
