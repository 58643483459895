import { getReverseTextColor } from "../../utils/helpers"
import { Label } from "rentzz"
import { MenuItem } from "@mui/material"

export default function OptionWithLabel(option: { id: string; label: string; color: string }, otherProps: any) {
    return (
        <MenuItem
            value={option.id}
            style={{
                textTransform: "none",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            {...otherProps}
        >
            <Label
                sx={{
                    width: "95%",
                    textTransform: "none",
                    backgroundColor: `#${option.color}`,
                    color: option.color ? getReverseTextColor(option.color) : "black",
                    cursor: "pointer",
                    transition: "all 0.3s ease 0s",
                    ":hover": {
                        transform: "scale(1.03)",
                    },
                }}
            >
                {option.label}
            </Label>
        </MenuItem>
    )
}
