import { FormProvider, RHFSelect, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddBankStatementRequest } from "../../../../../mutations/bankAccounts"
import { BankStatementSchema } from "../../../../../validations/bankStatementSchema"
import { useUserBankAccountsQuery } from "../../../../../queries/userData"
import { useCurrencyQueryFn } from "../../../../../queries/currency"

interface Props {
    onSave: (data: AddBankStatementRequest) => void
    onClose: () => void
    isLoading: boolean
}
export default function UploadBankStatementForm({ onSave, onClose, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userBankAccounts } = useUserBankAccountsQuery()
    const { data: currencies } = useCurrencyQueryFn()

    const methods = useForm<AddBankStatementRequest>({
        resolver: yupResolver(BankStatementSchema),
        mode: "onBlur",
    })

    const { handleSubmit } = methods

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFSelect required name='bankAccountId' label={translate("bank_account")} size='small'>
                        {userBankAccounts?.items.map((r) => (
                            <MenuItem value={r?.id} key={r?.id} style={{ textTransform: "none" }}>
                                {r.iban} - {currencies?.find((c) => c.id == r.currencyId)?.code}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <RHFUpload name={"files"} icon={"mdi:file-document-plus-outline"} multiple={true} maxFiles={1} />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
