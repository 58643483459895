import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { Box } from "@mui/material"
import AddNewManagerInvitationForm from "../../../../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"

export default function AddNewInvitationSheet() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("add_manager")}
            isOpen={modalOpen === AppModals.InviteManager}
            onClose={() => dispatch(setModalOpen(undefined))}
            headerColor={theme.palette.primary.main}
        >
            <Box p={2}>
                <AddNewManagerInvitationForm onClose={() => dispatch(setModalOpen(undefined))} />
            </Box>
        </DetailsSheet>
    )
}
