import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useStyles } from "./useStyles"
import { PropertyDataRequiredInfo } from "../newContract/PropertyData"
import { useLocales } from "rentzz"

interface PropertyDataRequiredInfoProps {
    propertyData: PropertyDataRequiredInfo | undefined
    isFocused: boolean
}

const PropertyDataPreviewWhenCreatingContract = ({ propertyData, isFocused }: PropertyDataRequiredInfoProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    const renderEmptyIfNoValue = useCallback(
        (value: boolean | undefined, label?: string) => {
            if (value) {
                return <Text>{label} _________________</Text>
            }
            return ""
        },
        [styles],
    )

    return (
        <View style={isFocused ? styles.focused : undefined}>
            <Text wrap style={styles.mainText}>
                <Text style={styles.boldFont}>Art. 1. </Text>
                {translate("contracts.owner_assures_tenant")}
                {renderEmptyIfNoValue(propertyData?.isCountyRequired)},{renderEmptyIfNoValue(propertyData?.isCityRequired)}
                {renderEmptyIfNoValue(propertyData?.isStreetRequired, ", str.")}
                {renderEmptyIfNoValue(propertyData?.isStreetNumberRequired, ", nr. ")}
                {renderEmptyIfNoValue(propertyData?.isBuildingNumberRequired, ", bl. ")}
                {renderEmptyIfNoValue(propertyData?.isStairRequired, ", sc. ")}
                {renderEmptyIfNoValue(propertyData?.isFloorRequired, ", et. ")}
                {renderEmptyIfNoValue(propertyData?.isApartmentRequired, ", ap. ")}
            </Text>
        </View>
    )
}

export default PropertyDataPreviewWhenCreatingContract
