import { useTheme } from "@mui/material/styles"
import { Analytic, AnalyticSkeleton, Scrollbar, useLocales } from "rentzz"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { Card, Divider, Stack } from "@mui/material"
import { IncomesFilteredOptions } from "../../utils/atom"
import React, { useMemo } from "react"
import { getIncomesReportSummaryQueryFn } from "../../queries/income"
import { getIncomeLabelColorByFilter } from "./HeaderIncomes"
import { useFeatureValue } from "@growthbook/growthbook-react"

const headerFilterOptions = [
    IncomesFilteredOptions.Expenses,
    IncomesFilteredOptions.Rent,
    IncomesFilteredOptions.SecurityDeposit,
    IncomesFilteredOptions.Fees,
    IncomesFilteredOptions.Pending,
    IncomesFilteredOptions.Damages,
]

export const getIconByFilter = (filter: IncomesFilteredOptions) => {
    switch (filter) {
        case IncomesFilteredOptions.Rent:
            return "mdi:account-cash-outline"
        case IncomesFilteredOptions.SecurityDeposit:
            return "mdi:cash-lock"
        case IncomesFilteredOptions.Expenses:
            return "mdi:invoice-list-outline"
        case IncomesFilteredOptions.Pending:
            return "mdi:invoice-text-clock-outline"
        case IncomesFilteredOptions.NoAssignedExpenses:
            return "mdi:invoice-text-remove-outline"
        case IncomesFilteredOptions.Fees:
            return "lucide:receipt"
        case IncomesFilteredOptions.Damages:
            return "mdi:account-service"
    }
}

export default function IncomesSummaryReport() {
    const theme = useTheme()
    const { data: incomesSummaryReport } = getIncomesReportSummaryQueryFn()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const incomeOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)

    const allAmounts = useMemo(
        () =>
            incomesSummaryReport
                ? incomesSummaryReport[IncomesFilteredOptions.Expenses].sum +
                  incomesSummaryReport[IncomesFilteredOptions.Rent].sum +
                  incomesSummaryReport[IncomesFilteredOptions.SecurityDeposit].sum +
                  incomesSummaryReport[IncomesFilteredOptions.Damages].sum +
                  incomesSummaryReport[IncomesFilteredOptions.Fees].sum
                : 0,
        [incomesSummaryReport],
    )

    if (userData == null) return null

    return (
        <Card
            sx={{
                mb: { xs: 3, md: 3 },
            }}
        >
            <Scrollbar>
                <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: "dashed" }} />} sx={{ py: 2 }}>
                    {incomesSummaryReport == null && (
                        <>
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                        </>
                    )}
                    {incomesSummaryReport != null &&
                        headerFilterOptions
                            .filter((i) => incomesSummaryReport[i] != null && i !== IncomesFilteredOptions.Pending)
                            .map((f) => (
                                <Analytic
                                    key={f}
                                    icon={getIconByFilter(f) ?? ""}
                                    title={translate(`income_filter_options${incomeOverrideHeader ? "-" + incomeOverrideHeader : ""}-${f}`)}
                                    total={incomesSummaryReport[f].count}
                                    percent={(incomesSummaryReport[f].sum / allAmounts) * 100}
                                    price={incomesSummaryReport[f].sum}
                                    currency={userData?.currency.code}
                                    color={getIncomeLabelColorByFilter(f, theme)}
                                    itemName={translate("income").toLowerCase()}
                                />
                            ))}
                </Stack>
            </Scrollbar>
        </Card>
    )
}
