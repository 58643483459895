import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import React, { useCallback, useMemo } from "react"
import AddOrEditNewProvider from "../../../../../sections/nomenclature/providers/AddOrEditNewProvider"
import { useUserProvidersQuery } from "../../../../../queries/userData"
import { useTheme } from "@mui/material/styles"

export default function AddOrEditNomenclatureProviderGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: providers } = useUserProvidersQuery()
    const currentProvider = useMemo(() => providers?.find((provider) => provider.id === editingItem?.id), [providers, editingItem])

    const isDialogVisible = useMemo(() => {
        if (!isMobile && (modalOpen === AppModals.AddCustomProvider || modalOpen === AppModals.EditCustomProvider)) return true
        return false
    }, [isMobile, modalOpen])

    const handleClose = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.EditCustomProvider) {
                dispatch(setModalOpen(AppModals.CustomProviderDetails))
            }
            if (modalOpen === AppModals.AddCustomProvider) {
                dispatch(setModalOpen(undefined))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={isDialogVisible}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(currentProvider ? "edit_custom_provider" : "add_new_provider")}
            >
                <AddOrEditNewProvider onClose={handleClose} defaultValues={currentProvider} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddCustomProvider || modalOpen === AppModals.EditCustomProvider)}
                onClose={handleClose}
                isLoading={false}
                title={translate(currentProvider ? "edit_custom_provider" : "add_new_provider")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditNewProvider onClose={handleClose} defaultValues={currentProvider} />
            </DetailsSheet>
        </>
    )
}
