import { Box, Divider, Paper, Stack, Typography } from "@mui/material"
import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect } from "react"
import { updateUserProfile } from "../../../mutations/user"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useUserDataQueryFn } from "../../../queries/userData"
import { styled, useTheme } from "@mui/material/styles"
import { object, string } from "yup"

export const CustomTypography = styled("text")(({ theme }) => ({
    color: theme.palette.primary.dark,
    letterSpacing: 0.5,
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: 13,
}))

export const ProfileChangeSchema = object().shape({
    firstName: string().required().min(3, "minimum_characters"),
    lastName: string().required().min(3, "minimum_characters"),
    email: string().required().email("email_valid"),
    phoneNumber: string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, "phone_number"),
})

export interface ProfileChangeRequest {
    firstName: string
    lastName: string
    email: string
    phoneNumber?: string
}

export const GeneralProfile = () => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { mutateAsync: updateProfile, isPending: isUpdateProfileLoading } = updateUserProfile()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    const methods = useForm<ProfileChangeRequest>({
        resolver: yupResolver(ProfileChangeSchema),
        mode: "onBlur",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        reset({
            firstName: user?.firstName ?? undefined,
            lastName: user?.lastName ?? undefined,
            email: user?.email ?? undefined,
            // phoneNumber: user?.phoneNumber ?? undefined,
        })
    }, [reset, user])

    const onSubmit = useCallback(
        async (data: ProfileChangeRequest) => {
            await updateProfile(data)
        },
        [updateProfile],
    )

    return (
        <Paper elevation={6} sx={{ padding: 2, color: theme.palette.grey.A700 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={1.5} sx={{ px: 1, pt: 0 }}>
                    <Typography variant={"subtitle1"} fontWeight={"bold"}>
                        {translate("user_data")}
                    </Typography>
                    <Divider sx={{ borderStyle: "dashed" }} />
                    <Box display='flex' gap={3} flexDirection={"column"} paddingTop={1}>
                        <Box display={"flex"} gap={2} flexDirection={isMobile ? "column" : "row"}>
                            <RHFTextField name={"firstName"} label={translate("first_name")} />
                            <RHFTextField name={"lastName"} label={translate("last_name")} />
                        </Box>
                        <Box display={"flex"} gap={2} flexDirection={isMobile ? "column" : "row"}>
                            <RHFTextField name={"email"} label={translate("email")} />
                            {/* <RHFPhoneNumber name={"phone"} label={translate("phone")} sx={{ width: "100%" }} /> */}
                        </Box>

                        <Box display={"flex"} justifyContent={isMobile ? undefined : "flex-end"}>
                            <LoadingButton
                                loading={isUpdateProfileLoading}
                                disabled={isUpdateProfileLoading}
                                color={"primary"}
                                type='submit'
                                variant={"contained"}
                                style={{ textTransform: "none" }}
                                fullWidth={isMobile}
                            >
                                {translate("save")}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Stack>
            </FormProvider>
        </Paper>
    )
}
