import { boolean, number, object, string } from "yup"

export const AddNewInvoicingConfigurationSchema = object().shape({
    invoiceProvider: number().required("required"),
    user: string().required("required").min(3, "minimum_characters"),
    password: string().required("required").min(3, "minimum_characters"),
})

export const InvoicingSchema = object().shape({
    invoicingLanguage: string().required("required"),
    invoicingCompany: string().required("company_required"),
    createInvoiceDay: number().optional(),
    invoicingClientId: number().required("required"),
    invoicingSeries: string().required("required"),
    defaultInvoicingTemplate: string().required("required"),
    invoicingCurrencyId: number().required("required"),
    defaultInvoicingText: string().required("required"),
    invoiceMaxDays: number().required("required"),
    sendToSPV: boolean().required("required"),
    createInvoice: boolean().required("required"),
    invoicingPeriodStart: number()
        .required()
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
    invoicingPeriodEnd: number()
        .required()
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
    reinvoicingSeries: string().optional(),
    reinvoicingVAT: number().test("test", "requiredReinvoiceVAT", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            return !!value
        }
        return true
    }),
    reinvoicingPercentage: number().test("test", "requiredReinvoiceValuePercentage", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            if (value == null) return false

            return value > 0
        }
        return true
    }),
    defaultReinvoicingText: string().test("test", "requiredReinvoiceTemplate", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            return !!value
        }
        return true
    }),
})
