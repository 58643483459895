import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { InvoiceProvider } from "../queries/invoicing-configuration"

export interface ToggleInvoicingCompanyRequest {
    invoicingConfigurationId: number
    invoicingCompanyId: number
    active: boolean
}

export interface UpdateInvoicingConfigurationCompaniesRequest {
    invoicingConfigurationId: number
}

export interface AddNewInvoicingConfigurationRequest {
    invoiceProvider: InvoiceProvider
    user: string
    password: string
}

export const toggleInvoicingConfigurationCompany = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, invoicingCompanyId, active }: ToggleInvoicingCompanyRequest) =>
            active
                ? Api.activateInvoicingCompany(invoicingConfigurationId, invoicingCompanyId)
                : Api.deactivateInvoicingCompany(invoicingConfigurationId, invoicingCompanyId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}

export const updateInvoicingConfigurationCompanies = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId }: UpdateInvoicingConfigurationCompaniesRequest) =>
            Api.updateInvoicingConfigurationCompanies(invoicingConfigurationId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}

export const deleteInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (id: number) => Api.deleteInvoicingConfiguration(id),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserInvoicingConfigurations])
            }
        },
    })
}

export const addNewInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: AddNewInvoicingConfigurationRequest) => Api.addNewInvoicingConfigurationRequest(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserInvoicingConfigurations])
            }
        },
    })
}

export const updateInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, operations }: { invoicingConfigurationId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInvoicingConfiguration({
                invoicingConfigurationId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserInvoicingConfigurations, ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}
