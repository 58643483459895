import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import React, { SyntheticEvent, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../redux/store"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { deletePropertyMutation } from "../../mutations"
import { deleteContractTemplateMutation } from "../../mutations/property/contracts"
import { useTheme } from "@mui/material/styles"
import DeleteSheetContent from "../../sections/DeleteSheetContent"
import DeleteDialog from "../../dialogs/DeleteDialog"

export default function DeleteGuard() {
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { mutateAsync: deleteProperty, isPending: isDeletePropertyLoading } = deletePropertyMutation()
    const { mutateAsync: deleteContractTemplate, isPending: isDeleteContractTemplateLoading } = deleteContractTemplateMutation()

    const getTitle = useCallback(() => {
        switch (modalOpen) {
            case AppModals.DeleteProperty:
                return translate("delete_property")
            case AppModals.DeleteContract:
                return translate("delete_contract", { contractName: editingItem.name })
        }
    }, [modalOpen, translate, editingItem])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (!editingItem) return

            switch (modalOpen) {
                case AppModals.DeleteProperty:
                    await deleteProperty(editingItem?.propertyId)
                    break
                case AppModals.DeleteContract:
                    await deleteContractTemplate({ currentContractTemplateId: editingItem.contractId, isArchived: editingItem.isArchived })
                    break
            }
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [modalOpen, editingItem, dispatch, deleteContractTemplate],
    )

    const getDeleteContent = useCallback(() => {
        switch (modalOpen) {
            case AppModals.DeleteProperty:
                return translate("delete_property_text", { propertyName: editingItem.propertyName })
            case AppModals.DeleteContract:
                return translate("delete_contract_details", { contractName: editingItem.name })
        }
    }, [modalOpen, translate, editingItem])

    const isDeleteLoading = useMemo(
        () => isDeletePropertyLoading || isDeleteContractTemplateLoading,

        [isDeleteContractTemplateLoading, isDeletePropertyLoading],
    )

    const isDeleteVisible = useMemo(
        () => modalOpen === AppModals.DeleteProperty || modalOpen === AppModals.DeleteContract,

        [modalOpen],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={getTitle()}
                isOpen={isMobile && isDeleteVisible}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent content={getDeleteContent()} onClose={onClose} isDeleteLoading={isDeleteLoading} onAccept={onAccept} />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && isDeleteVisible}
                isDeleteLoading={isDeleteLoading}
                title={getTitle()}
                content={getDeleteContent()}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}
