import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import React from "react"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { Box } from "@mui/material"
import { getJournalByIdQueryFn } from "../../../../queries/journal"
import { useAtomValue } from "jotai"
import { journalsFilterState } from "../../../../utils/atom"
import GeneralCellRender from "../../../../sections/propertyDetails/Tenants/rentingPeriodDetails/journal/cell-renders"
import * as humps from "humps"

export default function JournalItemDetails() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const filterModel = useAtomValue(journalsFilterState)
    const currentJournal = getJournalByIdQueryFn(editingItem?.id, [{ field: "createdAt", sort: "desc" }], filterModel)

    if (currentJournal == null || currentJournal.parameters == null) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.JournalItemDetails}
            onClose={() => {
                dispatch(setModalOpen(AppModals.JournalDetails))
            }}
            isLoading={false}
            title={currentJournal?.isSystemDiaryEntry ? translate(currentJournal?.title) : currentJournal?.title}
            options={[]}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                <GeneralCellRender data={humps.camelizeKeys(JSON.parse(currentJournal?.parameters))} type={currentJournal?.type} />
            </Box>
        </DetailsSheet>
    )
}
