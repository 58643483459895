import { Text, View } from "@react-pdf/renderer"
import Obligations from "./Obligations"
import GDPR from "./GDPR"
import React from "react"
import { Focus } from "./OwnerLivePreview"
import useStyles from "./useStyles"
import { useLocales } from "rentzz"

interface ContractConditionsProps {
    ownerObligations: string[] | undefined
    tenantObligations: string[] | undefined
    partiesRequirements: string[] | undefined
    litigation: string[] | undefined
    hasGDPR?: boolean
    focus: Focus
    ownerFullName?: string
}

export default function ContractConditions({
    ownerObligations,
    focus,
    tenantObligations,
    litigation,
    hasGDPR,
    partiesRequirements,
}: ContractConditionsProps) {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <>
            {ownerObligations && (
                <>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("owner_obligations")}</Text>
                    </View>
                    <Obligations
                        key={ownerObligations.length}
                        obligations={ownerObligations}
                        isFocused={focus === Focus.OWNER_OBLIGATIONS}
                        countStart={6}
                    />
                </>
            )}
            {tenantObligations && (
                <>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("tenant_obligations")}</Text>
                    </View>
                    <Obligations
                        key={tenantObligations.length}
                        obligations={tenantObligations}
                        isFocused={focus === Focus.TENANT_OBLIGATIONS}
                        countStart={6 + (ownerObligations?.length ?? 0)}
                    />
                </>
            )}
            {partiesRequirements && (
                <>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("parties_agreement")}</Text>
                    </View>
                    <Obligations
                        key={partiesRequirements.length}
                        obligations={partiesRequirements}
                        isFocused={focus === Focus.PARTIES_REQUIREMENTS}
                        countStart={6 + (ownerObligations?.length ?? 0) + (tenantObligations?.length ?? 0)}
                    />
                </>
            )}
            {litigation && (
                <>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("disputes")}</Text>
                    </View>
                    <Obligations
                        key={litigation.length}
                        obligations={litigation}
                        isFocused={focus === Focus.LITIGATION}
                        countStart={6 + (ownerObligations?.length ?? 0) + (tenantObligations?.length ?? 0) + (partiesRequirements?.length ?? 0)}
                    />
                </>
            )}
            {hasGDPR && (
                <View style={focus === Focus.REVIEW ? styles.focused : undefined}>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("gdpr_title")}</Text>
                    </View>
                    <GDPR
                        firstArticleNum={
                            6 +
                            (ownerObligations?.length ?? 0) +
                            (tenantObligations?.length ?? 0) +
                            (partiesRequirements?.length ?? 0) +
                            (litigation?.length ?? 0)
                        }
                    />
                </View>
            )}
            {litigation && (
                <>
                    <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                        <Text>{translate("other_disputes")}</Text>
                    </View>
                    <View style={styles.mainText}>
                        <Text>{translate("this_rental_agreement_comply_with_terms")}</Text>
                    </View>
                </>
            )}
        </>
    )
}
