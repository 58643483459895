import { CustomFile, FormProvider, RHFDatePicker, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { RevisionObservation } from "../../../redux/slices/AddProperty"
import { AddObservationSchema } from "../../../validations/revisions"
import { useSelector } from "../../../redux/store"
import { DateTime } from "luxon"

interface Props {
    onClose: () => void
    onSave: (data: AddObservationRequest) => Promise<void>
    isLoading: boolean
    defaultValues?: RevisionObservation
}

export interface AddObservationRequest {
    id?: number
    maintenanceScheduleId: number
    title: string
    description: string
    observationDate: string
    files: CustomFile[]
}

export default function AddObservationForm({ onSave, onClose, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)

    const methods = useForm<AddObservationRequest>({
        resolver: yupResolver<AddObservationRequest>(AddObservationSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                observationDate: defaultValues.observationDate.toISODate() ?? undefined,
                files: [],
            })
        }

        if (defaultValues == null) {
            reset({
                maintenanceScheduleId: editingItem?.revisionId,
                observationDate: DateTime.now().toISODate(),
                files: [],
            })
        }
    }, [defaultValues, reset, editingItem])

    const handleSave = useCallback(
        async (data: AddObservationRequest) => {
            await onSave(data)
            onClose()
            reset()
        },
        [onSave, onClose, reset],
    )

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFTextField name={"title"} label={translate("title")} size={"small"} required />
                    <RHFTextField name={"description"} label={translate("text")} multiline required minRows={3} maxRows={3} />
                    <RHFDatePicker name='observationDate' label={translate("date")} required disableFuture />
                    {defaultValues == null && <RHFUpload name={"files"} multiple icon={"mdi:file-document-plus-outline"} />}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
