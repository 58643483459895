import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import InvoicingForm from "./InvoicingForm"
import { Alert } from "@mui/material"

const InvoicingGuard = () => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("create_invoice")}
                isOpen={isMobile && modalOpen === AppModals.CreateInvoice}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                {editingItem?.isCreateInvoiceButtonDisabled ? (
                    <Alert severity={"info"}>{translate("please_fill_invoice_data")}</Alert>
                ) : (
                    <InvoicingForm />
                )}
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("create_invoice")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.CreateInvoice}
                maxWidth='sm'
                closeButtonColor={"primary"}
                titleAlign={"center"}
            >
                <InvoicingForm />
            </CustomDialog>
        </>
    )
}

export default InvoicingGuard
