import {
    FormProvider,
    RHFAutocomplete,
    RHFCheckbox,
    RHFDateRangePicker,
    RHFSelect,
    RHFTextField,
    RHFUnitInput,
    RHFUpload,
    useIsMobile,
    useLocales,
} from "rentzz"
import { Alert, Box, Button, MenuItem } from "@mui/material"
import { InsuranceType, PropertyInsurance } from "../../redux/slices/AddProperty"
import { getOwnerMaxSize } from "../../utils/actions"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddInsuranceSchema, InsuranceBaseSchema } from "../../validations/addInsurance"
import { AddInsuranceRequest, UpdateInsuranceRequest } from "./AddInsurance"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { useCurrencyQueryFn } from "../../queries/currency"
import { useSelector } from "../../redux/store"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { DateTime } from "luxon"

interface AddFormInsuranceProps {
    onClose: () => void
    onSave: (data: UpdateInsuranceRequest | AddInsuranceRequest) => Promise<void>
    isLoading: boolean
    defaultValues: (PropertyInsurance & { canWrite: boolean }) | null | undefined
}
export default function AddFormInsurance({ defaultValues, onSave, isLoading, onClose }: AddFormInsuranceProps) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: user } = useUserDataQueryFn()
    const { currentPropertyId, errorMessage } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.Insurances)

    const methods = useForm({
        resolver: yupResolver(defaultValues ? InsuranceBaseSchema() : AddInsuranceSchema()),
        mode: "onChange",
        defaultValues: {
            insurer: defaultValues?.insurer ?? "",
        },
    })

    const { reset, handleSubmit, getValues } = methods

    useEffect(() => {
        reset(
            defaultValues
                ? {
                      ...defaultValues,
                      startDate: defaultValues.startDate.toISO() ?? undefined,
                      endDate: defaultValues.endDate.toISO() ?? undefined,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      files: [],
                  }
                : {
                      propertyId: editableProperties?.find((p) => p.id === currentPropertyId)?.id ?? (editableProperties ?? [])[0]?.id,
                      currencyId: user?.currency.id,
                      startDate: DateTime.now().toISO() ?? undefined,
                      endDate: DateTime.now().plus({ year: 1 }).toISO() ?? undefined,
                      shouldNotifyForRenewal: false,
                      shouldCreateExpense: false,
                      files: [],
                      insuranceType: InsuranceType.Optional,
                  },
        )
    }, [defaultValues, user, editableProperties, currentPropertyId])

    const onSubmit = useCallback(
        async (data: AddInsuranceRequest | UpdateInsuranceRequest) => {
            await onSave(data)
            onClose()
        },
        [onSave, onClose],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginTop={2}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFAutocomplete
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                            },
                            noOptionsText: translate("no_value_found"),
                        }}
                        matchId
                        freeSolo={false}
                        name={"propertyId"}
                        options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                        label={translate("property")}
                        required
                        disabled={defaultValues != null || currentPropertyId != null}
                    />
                    <RHFTextField name='insurer' label={translate("insurance.insurer")} size='small' required />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFSelect name={"insuranceType"} size='small' label={translate("insurance.type")} required>
                        <MenuItem value={InsuranceType.Mandatory} style={{ textTransform: "none" }}>
                            {translate("insurance.type-0")}
                        </MenuItem>
                        <MenuItem value={InsuranceType.Optional} style={{ textTransform: "none" }}>
                            {translate("insurance.type-1")}
                        </MenuItem>
                    </RHFSelect>
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName={"currencyId"}
                        unitType={"currency"}
                        name='value'
                        type='number'
                        size='small'
                        label={translate("amount")}
                        required
                    />
                </Box>

                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFDateRangePicker fromName={"startDate"} toName={"endDate"} />
                </Box>
            </Box>
            {defaultValues == null && <RHFCheckbox name='shouldNotifyForRenewal' label={translate("insurance.yesNotification")} />}
            {defaultValues == null && (
                <RHFCheckbox name='shouldCreateExpense' label={translate("insurance.shouldCreateExpense")} style={{ marginBottom: 8 }} />
            )}
            {defaultValues == null && (
                <RHFUpload
                    name={"files"}
                    multiple
                    maxSize={getOwnerMaxSize(userProperties, user?.features?.maxPropertySize, getValues("propertyId"))}
                    icon={"mdi:file-document-plus-outline"}
                />
            )}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                    {translate("cancel")}
                </Button>
                <LoadingButton color={"primary"} type='submit' variant='contained' loading={isLoading} disabled={isLoading}>
                    {translate("submit")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
