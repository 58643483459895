import React, { useCallback } from "react"
import * as jsonpatch from "fast-json-patch"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import EditRentingPeriodDetailsForm, {
    RentingPeriodRequest,
} from "../../../sections/propertyDetails/Tenants/rentingPeriodDetails/general/details/EditRentingPeriodDetailsForm"
import { useDispatch, useSelector } from "../../../redux/store"
import { updateRentingPeriodMutation } from "../../../mutations/tenants"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"

export default function EditRentingPeriodDetailsGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    const onSubmit = useCallback(
        async (data: RentingPeriodRequest) => {
            if (rentingPeriodDetails == null) return
            const operations = jsonpatch.compare(
                {
                    ...rentingPeriodDetails,
                    moveInDate: rentingPeriodDetails.moveInDate.toISO(),
                    moveOutDate: rentingPeriodDetails.moveOutDate.toISO(),
                },
                { ...data, isC168Sent: !data.shouldSendC168, shouldSendC168: undefined },
            )

            await updateRentingPeriod({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations: operations.map((o) => ({ ...o, path: o.path.replace("/moveOutDate", "/to") })),
            })
            dispatch(setModalOpen(undefined))
        },
        [updateRentingPeriod, rentingPeriodDetails],
    )
    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("grid_actions.edit")}
                open={!isMobile && modalOpen === AppModals.EditRentingPeriodDetails}
                onClose={() => dispatch(setModalOpen(undefined))}
                maxWidth='sm'
                titleAlign={"center"}
            >
                <EditRentingPeriodDetailsForm onSubmit={onSubmit} isLoading={isRentingPeriodUpdating} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.EditRentingPeriodDetails}
                onClose={() => dispatch(setModalOpen(undefined))}
                isLoading={rentingPeriodDetails == null}
                title={translate("grid_actions.edit")}
                headerColor={theme.palette.primary.main}
            >
                <Box p={1}>
                    <EditRentingPeriodDetailsForm onSubmit={onSubmit} isLoading={isRentingPeriodUpdating} />
                </Box>
            </DetailsSheet>
        </>
    )
}
