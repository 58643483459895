import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import CustomCard from "../../../../components/CustomCard"
import { DateTime } from "luxon"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { Iconify, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { useDispatch } from "../../../../redux/store"
import { NotificationTemplate } from "../../../../queries/userData"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"

interface NotificationItemProps {
    notification: NotificationTemplate
}

const NotificationItem = ({ notification }: NotificationItemProps) => {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isUsed = useMemo(() => notification.numberOfRentingPeriods != null && notification.numberOfRentingPeriods !== 0, [notification])

    return (
        <Grid key={notification.id} item xs={12} md={4} lg={3}>
            <CustomCard
                onClick={(e) => {
                    e.stopPropagation()
                    navigate(`${notification.id}`)
                }}
                isCardClickable
                px={1}
                py={1}
                marginRight={2}
            >
                <Box display='flex' justifyContent='space-between' width='100%' alignItems='center' paddingBottom={2}>
                    <Box display={"flex"} gap={1}>
                        <Tooltip title={`${translate("createdAt")} ${notification.createdAt.toLocaleString(DateTime.DATE_SHORT)}`} arrow>
                            <Iconify icon={"mdi:calendar-clock"} sx={{ color: theme.palette.grey.A700 }} width={18} height={18} />
                        </Tooltip>
                        <Typography variant={"caption"}>{notification.createdAt.toLocaleString(DateTime.DATE_SHORT)}</Typography>
                    </Box>

                    <IconButton
                        size='small'
                        color='error'
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(setModalOpen(AppModals.DeleteNotificationTemplate))
                            dispatch(setEditingItem({ id: notification.id }))
                        }}
                    >
                        <Iconify icon={"mdi:trash-can"} sx={{ height: 18, width: 18 }} />
                    </IconButton>
                </Box>

                <Box display='flex' justifyContent='space-between' flexDirection='column' width='100%' gap={2} height='100%'>
                    <Box display='flex' flexDirection='row' justifyContent='center' textAlign={"center"}>
                        <Typography variant={"h5"}>{notification.name}</Typography>
                    </Box>
                    <Box display='flex' flexDirection='column' textAlign={"start"} width={"fit-content"}>
                        <Tooltip
                            title={
                                isUsed
                                    ? `${translate("used_in")}
                                    ${notification.numberOfRentingPeriods} ${translate("contracts_lowercase")}`
                                    : translate("no_usage")
                            }
                            arrow
                        >
                            <Box display={"flex"} gap={0.5} alignItems={"center"}>
                                <Typography variant={"caption"}>
                                    {notification.numberOfRentingPeriods ? notification.numberOfRentingPeriods : ""}
                                </Typography>
                                <Iconify
                                    icon={isUsed ? "mdi:emoticon-happy-outline" : "mdi:emoticon-sad-outline"}
                                    sx={{ color: isUsed ? theme.palette.success.main : theme.palette.warning.main }}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </CustomCard>
        </Grid>
    )
}

export default NotificationItem
