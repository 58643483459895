import { ExpenseAssignee, LightTooltip } from "rentzz"
import React from "react"
import { Box, MenuItem, MenuItemProps, Theme, Typography } from "@mui/material"
import { IncomeStatus, RentingPeriod } from "../../redux/slices/AddProperty"
import { PropertyExpense } from "../../queries/expenses"

export const isReferenceDisabled = (reference: PropertyExpense | RentingPeriod, isAddingExpense: boolean) => {
    if (isAddingExpense) {
        const castedReference = reference as PropertyExpense
        return castedReference.assignee === ExpenseAssignee.ME
    }
    return false
}

export const getReferenceLabelTitle = (isAddingExpensePayment: boolean) => {
    if (isAddingExpensePayment) {
        return "expense_name"
    }

    return "tenant_name"
}

export const renderMenuItem = (
    tooltipTitle: string,
    menuItemText: string,
    other: MenuItemProps,
    translate: any,
    subtitle?: string,
    theme?: Theme,
    subtitle2?: string,
) => {
    if (tooltipTitle.length > 0) {
        return (
            <LightTooltip key={other.value?.toString()} arrow title={translate(tooltipTitle)}>
                <span>
                    <MenuItem style={{ textTransform: "none" }} disabled>
                        <Typography variant={"body2"}>{translate(menuItemText)}</Typography>
                    </MenuItem>
                </span>
            </LightTooltip>
        )
    }

    return (
        <MenuItem {...other} key={other.value?.toString()} style={{ textTransform: "none" }}>
            <Box width={"100%"}>
                <Typography variant={"body2"}>{translate(menuItemText)}</Typography>
                <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Typography variant={"caption"} color={theme?.palette.grey["600"]}>
                        {subtitle}
                    </Typography>
                    <Typography variant={"caption"} color={theme?.palette.grey["600"]}>
                        {subtitle2}
                    </Typography>
                </Box>
            </Box>
        </MenuItem>
    )
}

export const getIncomeTitleStatus = (status: IncomeStatus | undefined) => {
    switch (status) {
        case IncomeStatus.Waiting:
        case IncomeStatus.CardPaymentWaiting:
            return "payment_is_waiting_owner"
        case IncomeStatus.Accepted:
        case IncomeStatus.CardPaymentSucceeded:
            return "payment_is_accepted_owner"
        case IncomeStatus.Denied:
        case IncomeStatus.CardPaymentFailed:
            return "payment_is_denied_owner"
        case IncomeStatus.AcceptedFromExternalSystem:
            return "payment_is_accepted_external_system"
    }
}
