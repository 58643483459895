import { array, boolean, number, object, string } from "yup"

export const AddMeterSchema = object().shape({
    id: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    name: string().required("name_required").min(3, "min_characters_name"),
    unitId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    currentValue: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("current_value_required")
        .min(1)
        .positive("invalid_value"),
    reportingPeriod: array().required().min(1, "reporting_period_required"),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    files: array().required(),
    requirePhotoForReading: boolean().required(),
    requireInteger: boolean().required(),
})
