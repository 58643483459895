import { deleteIncomeMutation } from "../../../mutations/incomes"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { SyntheticEvent, useCallback } from "react"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useSingleIncomeQuery } from "../../../queries/income"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useTheme } from "@mui/material"
import { useIntl } from "react-intl"

const DeleteIncomeGuard = () => {
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentPropertyId } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const { data: currentIncome } = useSingleIncomeQuery(editingItem?.isFromAnExpense ? editingItem?.incomeId : editingItem?.id)
    const { mutateAsync: deleteIncome, isPending: isDeleteLoading } = deleteIncomeMutation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()

    const onClose = useCallback(() => {
        if (editingItem?.isFromAnExpense) {
            dispatch(setModalOpen(AppModals.AssignedIncomes))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, editingItem])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (!currentIncome) return
            await deleteIncome({ incomeId: currentIncome.id, propertyId: currentPropertyId })
            if (editingItem?.isFromAnExpense) {
                dispatch(setModalOpen(AppModals.AssignedIncomes))
            } else {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }
        },
        [modalOpen, dispatch, currentPropertyId, deleteIncome, currentIncome],
    )
    if (currentIncome == null) return

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_income")}
                isOpen={isMobile && modalOpen === AppModals.DeleteIncome}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_income_details", {
                        incomeDate: currentIncome?.date.toLocaleString(),
                        incomeValue: intl.formatNumber(currentIncome?.value as number, {
                            style: "currency",
                            currency: getCurrencyFromId(currencyData, currentIncome?.currencyId).code,
                        }),
                    })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteIncome}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_income")}
                content={translate("delete_income_details", {
                    incomeDate: currentIncome?.date.toLocaleString(),
                    incomeValue: intl.formatNumber(currentIncome?.value as number, {
                        style: "currency",
                        currency: getCurrencyFromId(currencyData, currentIncome?.currencyId).code,
                    }),
                })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}

export default DeleteIncomeGuard
