import { FormProvider, RHFCheckbox, useLocales } from "rentzz"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import * as React from "react"
import { useCallback } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { LoadingButton } from "@mui/lab"
import { ContactInfoType, SignatureType, useUserTenantRequests } from "../../../../queries/userData"
import { ContactInfoData } from "../../../contracts/livePreview/tenant/TenantContactInfo"
import { DateTime } from "luxon"
import { Document, usePDF } from "@react-pdf/renderer"
import TenantLivePreview from "../../../contracts/livePreview/tenant/TenantLivePreview"
import { SignatureAcceptedSchema } from "../../../../validations/contractAcceptedSchema"

interface CheckContractForm {
    accepted: boolean
}

interface CheckContractProps {
    goForward: () => void
    goBack: () => void
    tenantInfoData: Omit<ContactInfoData, "type"> | undefined
    tenantSignature: string | undefined
    rentPrice?: string
    paymentPeriod?: string
    securityDeposit?: string
    bankAccount?: string
    bankName?: string
}

export default function CheckContractByTenant({
    goForward,
    goBack,
    tenantInfoData,
    tenantSignature,
    rentPrice,
    paymentPeriod,
    securityDeposit,
    bankName,
    bankAccount,
}: CheckContractProps) {
    const { translate } = useLocales()
    const { data: tenantRequests } = useUserTenantRequests()

    const moveOutDate = useCallback(() => {
        if (tenantRequests == null) return ""

        return tenantRequests[0].moveOutDate.toLocaleString(DateTime.DATE_SHORT)
    }, [tenantRequests])

    const moveInDate = useCallback(() => {
        if (tenantRequests == null) return ""

        return tenantRequests[0].moveInDate.toLocaleString(DateTime.DATE_SHORT)
    }, [tenantRequests])

    const methods = useForm<CheckContractForm>({
        resolver: yupResolver<CheckContractForm>(SignatureAcceptedSchema),
        mode: "onChange",
        defaultValues: {
            accepted: false,
        },
    })

    const {
        formState: { isSubmitting, isValid },
    } = methods

    const getDocument = useCallback(() => {
        if (tenantRequests == null) return <Document />
        return (
            <Document>
                <TenantLivePreview
                    rentPrice={rentPrice}
                    securityDeposit={securityDeposit}
                    paymentPeriod={paymentPeriod}
                    moveInDate={moveInDate()}
                    moveOutDate={moveOutDate()}
                    ownerInfo={tenantRequests[0].contractToBeSigned?.contactInfos.find((info) => info.type === ContactInfoType.Owner)}
                    tenantInfo={tenantInfoData}
                    litigation={tenantRequests[0].contractToBeSigned?.litigation}
                    partiesRequirements={tenantRequests[0].contractToBeSigned?.partiesRequirements}
                    tenantObligations={tenantRequests[0].contractToBeSigned?.tenantObligations}
                    ownerObligations={tenantRequests[0].contractToBeSigned?.ownerObligations}
                    propertyData={tenantRequests[0].contractToBeSigned?.propertyData}
                    ownerSignature={
                        tenantRequests[0].contractToBeSigned?.signatures.find((s) => s.signatureType === SignatureType.Owner)?.signatureImage
                    }
                    tenantSignatures={[
                        ...(tenantRequests[0].contractToBeSigned?.signatures.filter((s) => s.signatureType === SignatureType.Tenant) ?? []),
                        { signatureType: SignatureType.Tenant, signatureImage: tenantSignature ?? "", name: tenantInfoData?.fullName ?? "" },
                    ]}
                    contactInfos={tenantRequests[0].contractToBeSigned?.contactInfos}
                    hasGDPR={tenantRequests[0].contractToBeSigned?.hasGDPR}
                    bankAccount={bankAccount}
                    bankName={bankName}
                />
            </Document>
        )
    }, [rentPrice, securityDeposit, paymentPeriod, tenantRequests, tenantSignature, tenantInfoData, moveInDate, moveOutDate])
    const [instance, update] = usePDF({ document: getDocument() })

    const handleViewContractClick = useCallback(() => {
        update(getDocument())
        if (instance.url == null) return

        window.open(instance.url, "_blank")
    }, [tenantRequests, tenantInfoData, instance, getDocument])

    if (tenantRequests == null)
        return (
            <Box display={"flex"} justifyContent={"center"} height={100}>
                <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
            </Box>
        )

    return (
        <FormProvider methods={methods}>
            <Box display='flex' flexDirection='column' alignItems='center' gap={3} py={3}>
                <Typography variant='subtitle1' align='center'>
                    {translate("check_contract_details", { context: translate("renter") })}
                </Typography>
                <Button color={"primary"} onClick={handleViewContractClick} variant='contained' sx={{ width: 300 }}>
                    {translate("view_contract")}
                </Button>
            </Box>
            <RHFCheckbox name={"accepted"} label={translate("contract_is_ok")} />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button color={"primary"} onClick={goBack} disabled={isSubmitting}>
                    {translate("back")}
                </Button>
                <LoadingButton disabled={isSubmitting || !isValid} onClick={goForward} variant='contained' color={"primary"}>
                    {translate("next")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
