import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useStyles } from "./useStyles"
import { useLocales } from "rentzz"
import { ContactInfoData } from "./tenant/TenantContactInfo"

interface OwnerContactProps {
    ownerInfo: Omit<ContactInfoData, "type" | "contactInfoPersonType"> | undefined
    isFocused: boolean
}

const IndividualOwnerContact = ({ ownerInfo, isFocused }: OwnerContactProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    const renderEmptyIfNoValue = useCallback(
        (value: number | string | undefined) => {
            if ((typeof value === "number" && value === 0) || value == null || (typeof value === "string" && value.length === 0)) {
                return ""
            }
            return (
                <Text style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ ...styles.boldFont }}>{value}</Text>,{" "}
                </Text>
            )
        },
        [styles],
    )

    return (
        <View style={isFocused ? styles.focused : undefined}>
            <Text wrap break style={styles.mainText}>
                {renderEmptyIfNoValue(ownerInfo?.fullName)}
                {translate("contracts.address")}
                {ownerInfo?.county === "Bucuresti" ? "" : translate("contracts.county")}
                {renderEmptyIfNoValue(ownerInfo?.county)}
                {ownerInfo?.county === "Bucuresti" ? "" : translate("contracts.city")}
                {renderEmptyIfNoValue(ownerInfo?.city)}
                {ownerInfo?.street ? translate("contracts.street") : ""}
                {renderEmptyIfNoValue(ownerInfo?.street)}
                {ownerInfo?.streetNumber ? translate("contracts.number") : ""}
                {renderEmptyIfNoValue(ownerInfo?.streetNumber)}
                {ownerInfo?.buildingNumber ? translate("contracts.buildingNumber") : ""}
                {renderEmptyIfNoValue(ownerInfo?.buildingNumber)}
                {ownerInfo?.stair ? translate("contracts.stair") : ""}
                {renderEmptyIfNoValue(ownerInfo?.stair)}
                {ownerInfo?.floor ? translate("contracts.floor") : ""}
                {ownerInfo?.floor ? (
                    <Text>
                        <Text style={{ ...styles.boldFont }}>
                            {translate(ownerInfo?.floor === "0" ? "ground" : "floor_with_number", { floor: Number(ownerInfo.floor) })}
                        </Text>
                        ,{" "}
                    </Text>
                ) : (
                    ""
                )}
                {ownerInfo?.apartment ? translate("contracts.apartment") : ""}
                {renderEmptyIfNoValue(ownerInfo?.apartment)}
                {translate("contracts.identitySeries")} {renderEmptyIfNoValue(ownerInfo?.identitySeries)}
                {translate("contracts.number")}
                {renderEmptyIfNoValue(ownerInfo?.identityNumber)}
                {ownerInfo?.phone ? translate("contracts.phone") : ""}
                {renderEmptyIfNoValue(ownerInfo?.phone)}
                {ownerInfo?.email ? translate("contracts.email") : ""}
                {renderEmptyIfNoValue(ownerInfo?.email)}
                {translate("contracts.as")} <Text style={{ ...styles.boldFont, textTransform: "uppercase" }}>{translate("owner")}</Text>
            </Text>
        </View>
    )
}

export default IndividualOwnerContact
