import { FormProvider, RHFRadioGroup, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { Alert, Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ManagerType } from "../../propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { addGroupAccessMutation } from "../../../mutations/groups"
import { NewGroupAccessSchema } from "../../../validations/group"

interface AddGroupAccessFormProps {
    onClose: () => void
}

export interface NewGroupAccessRequest {
    email: string
    managerType: ManagerType
}

const AddGroupAccessForm = ({ onClose }: AddGroupAccessFormProps) => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { errorMessage, currentGroupId } = useSelector((state) => state.appState)
    const { mutateAsync: addNewOwner, isPending: isAddNewOwnerLoading } = addGroupAccessMutation()

    const methods = useForm<NewGroupAccessRequest>({
        resolver: yupResolver(NewGroupAccessSchema()),
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            managerType: ManagerType.READ_WRITE,
        },
    })

    const { handleSubmit, setError } = methods

    const onSubmit = useCallback(
        async (data: NewGroupAccessRequest) => {
            if (data == null || currentGroupId == null) return

            await addNewOwner({ groupId: currentGroupId, email: data.email, accessType: data.managerType })
            onClose()
        },
        [addNewOwner, onClose, setError, currentGroupId],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={isMobile ? 2 : 1} flexDirection={"column"}>
                <RHFRadioGroup
                    name={"managerType"}
                    options={[
                        {
                            label: translate(`managerType-${ManagerType.READ_WRITE}`),
                            value: ManagerType.READ_WRITE,
                            moreInfo: "read_write_access_info",
                        },
                        {
                            label: translate(`managerType-${ManagerType.READ}`),
                            value: ManagerType.READ,
                            moreInfo: "read_access_info",
                        },
                    ]}
                    sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
                />

                <RHFTextField name='email' label={translate("email")} required />
                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isAddNewOwnerLoading}
                        disabled={isAddNewOwnerLoading}
                        color={"primary"}
                        type={"submit"}
                        fullWidth
                        variant={isMobile ? "contained" : "text"}
                    >
                        {translate("save")}
                    </LoadingButton>
                    {isMobile && (
                        <Button color={"primary"} onClick={onClose} disabled={isAddNewOwnerLoading}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}

export default AddGroupAccessForm
