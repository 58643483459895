import { useDispatch } from "../redux/store"
import { useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { setShowSubscriptionPage } from "../redux/slices/App"
import React from "react"
import { useUserDataQueryFn } from "../queries/userData"

export default function ChangePlanTooltipComponent() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography variant={"caption"}>{translate("permission_denied_action")}</Typography>
            <LoadingButton
                size={"small"}
                color={"primary"}
                onClick={() => dispatch(setShowSubscriptionPage(true))}
                variant={"contained"}
                style={{ textTransform: "none" }}
            >
                {translate(userData?.canTrial ? "start_free_trial" : "change_plan")}
            </LoadingButton>
        </Box>
    )
}
