import { useMutation } from "@tanstack/react-query"
import { useSnackbar } from "notistack"
import { Currency, CustomFile, useLocales } from "rentzz"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { AddDocumentsRequest } from "../../sections/files/AddDocumentForm"
import { DateTime } from "luxon"

export const addDocumentsMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddDocumentsRequest }) => Api.addPropertyDocument(data),
        onSuccess: async () => {
            enqueueSnackbar(translate("updated"), { preventDuplicate: false })
            await invalidateQueries([
                ComplexQueryIDs.OwnerPropertyFiles,
                ComplexQueryIDs.TenantPropertyFiles,
                ComplexQueryIDs.RentingPeriodDetails,
                SimpleQueryIDs.UserData,
                SimpleQueryIDs.UserProperties,
            ])
        },
    })
}

export const sendExpenseToAIExtract = () => {
    return useMutation({
        mutationKey: ["upload-ai-file"],
        mutationFn: ({ data }: { data: UploadExpenseExtractorAI }) => Api.uploadExpenseAIExtract(data),
    })
}

export interface UploadExpenseExtractorAI {
    files: CustomFile[]
}

export interface UploadExpenseExtractorAIResponseBase {
    amount: number
    currencyId: Currency
    name: string
}

export interface UploadExpenseExtractorAIResponseDto extends UploadExpenseExtractorAIResponseBase {
    date?: string
    dueDate?: string
}

export interface UploadExpenseExtractorAIResponse extends UploadExpenseExtractorAIResponseBase {
    date?: DateTime
    dueDate?: DateTime
}
