import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import AddTenant from "../../../sections/propertyDetails/Tenants/addTenant/AddTenant"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { addTenantMutation } from "../../../mutations/tenants"
import { useLocation } from "react-router-dom"
import { AddTenantRequest } from "../../../sections/types/AddTenant"

export default function CreateRentingPeriodGuard() {
    const { modalOpen, currentPropertyId, editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { pathname } = useLocation()
    const isDashboard = useMemo(() => pathname.includes("/dashboard"), [pathname])
    const { mutateAsync: inviteTenant, isPending: isInvitingTenant } = addTenantMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onAddTenant = useCallback(
        async (data: AddTenantRequest) => {
            await inviteTenant({
                data,
                propertyId: currentPropertyId,
            })
            onClose()
        },
        [isDashboard, inviteTenant, currentPropertyId, onClose],
    )

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.CreateRentingPeriod}
                title={translate("add_new_renting_period")}
                titleAlign={"center"}
                onClose={onClose}
                showCloseIcon={true}
                maxWidth={editingItem?.width ?? "sm"}
                isFullScreen={isMobile}
            >
                <AddTenant onClose={onClose} onSave={onAddTenant} isLoading={isInvitingTenant} />
            </CustomDialog>
            <DetailsSheet
                title={translate("add_new_renting_period")}
                isOpen={isMobile && modalOpen === AppModals.CreateRentingPeriod}
                onClose={onClose}
                isLoading={false}
            >
                <AddTenant onClose={onClose} onSave={onAddTenant} isLoading={isInvitingTenant} />
            </DetailsSheet>
        </>
    )
}
