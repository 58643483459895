import { Box, Typography, useTheme } from "@mui/material"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { ContactInfoPersonType, ContactInfoType, TenantRequest } from "../../../queries/userData"
import { DateTime } from "luxon"
import { arrayOfNumbersToPeriod } from "../../../utils/dateMagic"
import { useLocales } from "rentzz"
import { useMemo } from "react"

interface Props {
    request: TenantRequest
}

const NotificationHeader = ({ request }: Props) => {
    const theme = useTheme()
    const { translate } = useLocales()
    const intl = useIntl()
    const { data: currencies } = useCurrencyQueryFn()

    const isIndividual = useMemo(() => {
        if (request.contractToBeSigned == null) return true
        return (
            request.contractToBeSigned?.contactInfos.find((c) => c.type === ContactInfoType.Owner)?.contactInfoPersonType ===
            ContactInfoPersonType.Individual
        )
    }, [request])

    if (currencies == null) return null

    return (
        <Box
            display='flex'
            flexDirection='row'
            width='100%'
            flexWrap='wrap'
            rowGap={3}
            justifyContent={"space-between"}
            paddingY={isIndividual ? 0 : 3}
        >
            {!isIndividual && (
                <>
                    <Box display='flex' flexDirection='column' flexBasis={"25%"}>
                        <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                            {translate("company")}
                        </Typography>
                        <Typography align='center' variant={"body2"}>
                            {request.contractToBeSigned?.contactInfos.find((contact) => contact.type === ContactInfoType.Owner)?.companyName}
                        </Typography>
                    </Box>
                    <Box display='flex' flexDirection='column' flexBasis={"25%"}>
                        <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                            {translate("company_registration_number")}
                        </Typography>
                        <Typography align='center' variant={"body2"}>
                            {
                                request.contractToBeSigned?.contactInfos.find((contact) => contact.type === ContactInfoType.Owner)
                                    ?.companyRegistrationNumber
                            }
                        </Typography>
                    </Box>
                </>
            )}

            <Box display='flex' flexDirection='column' flexBasis={isIndividual ? "50%" : "25%"}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate(isIndividual ? "owner" : "full_name_company")}
                </Typography>
                <Box display='flex' flexDirection='column' flex={1}>
                    {request.contractToBeSigned?.contactInfos.find((contact) => contact.type === ContactInfoType.Owner)?.fullName ?? request.owner}
                </Box>
            </Box>
            {isIndividual && (
                <Box display='flex' flexDirection='column' flexBasis={"50%"}>
                    <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                        {translate("invite_date")}
                    </Typography>
                    <Typography align='center' variant={"body2"}>
                        {request.invitedDate.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            )}
            <Box display='flex' flexDirection='column' flexBasis='30%'>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("renting_period_notifications.amount")}
                </Typography>
                <Box display='flex' flexDirection='column' flex={1}>
                    {intl.formatNumber(Number(request.value), {
                        style: "currency",
                        currency: getCurrencyFromId(currencies, request.currencyId).code,
                    })}
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' flexBasis='30%'>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("tenant-data.securityDeposit")}
                </Typography>
                <Box display='flex' flexDirection='column' flex={1}>
                    {request.securityDeposit
                        ? intl.formatNumber(Number(request.securityDeposit), {
                              style: "currency",
                              currency: getCurrencyFromId(currencies, request.securityDepositCurrencyId).code,
                          })
                        : "-"}
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' flexBasis='30%'>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("renting_period_notifications.paymentPeriod")}
                </Typography>
                <Typography align='center' variant={"body2"}>
                    {arrayOfNumbersToPeriod(request.paymentPeriod).join(", ")}
                </Typography>
            </Box>
            {!isIndividual && (
                <Box display='flex' flexDirection='column' flexBasis={"25%"}>
                    <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                        {translate("invite_date")}
                    </Typography>
                    <Typography align='center' variant={"body2"}>
                        {request.invitedDate.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            )}
            <Box display='flex' flexDirection='column' flexBasis={isIndividual ? "50%" : "25%"}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("renting_period_notifications.paymentPeriodStart")}
                </Typography>
                <Typography align='center' variant={"body2"}>
                    {request.moveInDate.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
            </Box>
            <Box display='flex' flexDirection='column' flexBasis={isIndividual ? "50%" : "25%"}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("renting_period_notifications.paymentPeriodEnds")}
                </Typography>
                <Typography align='center' variant={"body2"}>
                    {request.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
            </Box>
        </Box>
    )
}

export default NotificationHeader
