import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import { DetailsSheet, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import React, { SyntheticEvent, useMemo } from "react"
import { getMailNotificationColor, getMailNotificationIcon, getUserAcceptanceAccountColor, getUserAcceptanceIcon } from "../../../utils"
import { RentingPeriodMailNotificationStatus, RentingPeriodUserAcceptanceStatus } from "../../../../../../redux/slices/AddProperty"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import DeleteIcon from "@mui/icons-material/Delete"
import SyncIcon from "@mui/icons-material/Sync"
import { MOBILE_ICON_SIZE } from "../../../../../../utils/helpers"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { VerifiedUser } from "@mui/icons-material"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../../queries/userData"

export default function TenantsDetailsSheet() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const isAcceptOnTenantBehalfEnabled = useFeatureIsOn(UserFlags.AcceptOnTenantBehalf.toString())

    const currentTenant = useMemo(
        () => rentingPeriodDetails?.rentingPeriodTenants.find((tenant) => tenant.mail === editingItem?.mail),
        [rentingPeriodDetails, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentTenant == null) return []
        return [
            {
                icon: (
                    <VerifiedUser
                        sx={{
                            fontSize: MOBILE_ICON_SIZE,
                            color:
                                currentTenant.name === "-"
                                    ? theme.palette.info.main
                                    : currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                        currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                      ? theme.palette.grey["500"]
                                      : theme.palette.info.main,
                        }}
                    />
                ),
                title:
                    currentTenant.name === "-"
                        ? translate("accept-on-tenant-behalf")
                        : translate(
                              currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                  currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                  ? "accepted_invitation_behalf"
                                  : "accept-on-tenant-behalf",
                          ),
                hidden:
                    !rentingPeriodDetails?.canWrite ||
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved ||
                    !isAcceptOnTenantBehalfEnabled,
                onClick: () => dispatch(setModalOpen(AppModals.AcceptOnTenantBehalf)),
            },
            {
                icon: (
                    <SyncIcon
                        color={
                            currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                            currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                ? "disabled"
                                : "primary"
                        }
                        sx={{ fontSize: MOBILE_ICON_SIZE }}
                    />
                ),
                title: "resend",
                isDisabled:
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved,
                hidden: !rentingPeriodDetails?.canWrite,
                onClick: () => dispatch(setModalOpen(AppModals.ResendInvitation)),
            },
            {
                icon: (
                    <DeleteIcon
                        color={
                            currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                            currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                ? "disabled"
                                : "error"
                        }
                        sx={{ fontSize: MOBILE_ICON_SIZE }}
                    />
                ),
                title: "grid_actions.delete",
                isDisabled:
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                    currentTenant.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved,
                onClick: () => dispatch(setModalOpen(AppModals.RemoveInvitation)),
                hidden: !rentingPeriodDetails?.canDelete,
            },
        ]
    }, [currentTenant, dispatch, isAcceptOnTenantBehalfEnabled])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.TenantDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={rentingPeriodDetails == null}
            title={currentTenant?.name === "-" ? currentTenant?.mail : currentTenant?.name}
            subtitle={currentTenant?.name === "-" ? "" : currentTenant?.mail}
            options={options}
            headerColor={
                currentTenant?.name === "-"
                    ? getMailNotificationColor(currentTenant?.status as RentingPeriodMailNotificationStatus, theme)
                    : getUserAcceptanceAccountColor(currentTenant?.status as RentingPeriodUserAcceptanceStatus, theme)
            }
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    `${
                        currentTenant?.name === "-"
                            ? getMailNotificationIcon(currentTenant?.status as RentingPeriodMailNotificationStatus)
                            : getUserAcceptanceIcon(currentTenant?.status as RentingPeriodUserAcceptanceStatus)
                    }`,
                    `${translate(
                        currentTenant?.name === "-"
                            ? `mail_notification_status_tooltip.${currentTenant?.status}`
                            : `user_acceptance_status_tooltip.${currentTenant?.status}`,
                    )}`,
                    currentTenant?.name === "-"
                        ? getMailNotificationColor(currentTenant?.status as RentingPeriodMailNotificationStatus, theme)
                        : getUserAcceptanceAccountColor(currentTenant?.status as RentingPeriodUserAcceptanceStatus, theme),
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("last_invitation")}: ${currentTenant?.date.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {(currentTenant?.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                    currentTenant?.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved) &&
                    renderActionSheetInfo("mdi:sync", translate("accepted_invitation"), theme.palette.primary.main, theme.palette.grey["600"])}
                {(currentTenant?.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                    currentTenant?.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved) &&
                    renderActionSheetInfo(
                        "mdi:trash-can",
                        translate("can_not_delete_invitation.accepted"),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
