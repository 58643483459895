import { CustomDialog, DetailsSheet, FormProvider, Image, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { Box, Button, MenuItem, Typography } from "@mui/material"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { resendTenantInvitationMutation } from "../../../mutations/tenants"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"
import { getMailNotificationColor, getUserAcceptanceAccountColor } from "./utils"
import { RentingPeriodMailNotificationStatus, RentingPeriodUserAcceptanceStatus } from "../../../redux/slices/AddProperty"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { useForm } from "react-hook-form"
import { useUserDataQueryFn } from "../../../queries/userData"
import { allLangs } from "../../../config"

interface ResendInvitationForm {
    invitationLanguage: string
}

export default function ResendInvitation() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { mutateAsync: resendInvitation, isPending: isResendInvitationLoading } = resendTenantInvitationMutation()
    const { data: userData } = useUserDataQueryFn()
    const methods = useForm<ResendInvitationForm>({
        mode: "onChange",
        defaultValues: {
            invitationLanguage: userData?.languageCode,
        },
    })
    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (modalOpen === AppModals.ResendInvitation) {
            reset({ invitationLanguage: userData?.languageCode })
        }
    }, [modalOpen, userData])

    const currentTenant = useMemo(
        () => rentingPeriodDetails?.rentingPeriodTenants.find((tenant) => tenant.mail === editingItem?.mail),
        [rentingPeriodDetails, editingItem],
    )

    const onResendInvitation = useCallback(
        async (data: ResendInvitationForm) => {
            if (currentRentingPeriodId == null || editingItem == null) return
            await resendInvitation({ rentingPeriodId: currentRentingPeriodId, email: editingItem?.mail, invitationLanguage: data.invitationLanguage })
            dispatch(setModalOpen(undefined))
            if (isMobile) {
                dispatch(setModalOpen(AppModals.TenantDetails))
            }
        },
        [currentRentingPeriodId, editingItem, resendInvitation],
    )

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("resend")}
                onClose={() => dispatch(setModalOpen(undefined))}
                open={!isMobile && modalOpen === AppModals.ResendInvitation}
                maxWidth='xs'
                closeButtonColor={"primary"}
                variantCloseButton={"text"}
                acceptButtonColor={"primary"}
                isDisabled={isResendInvitationLoading}
                isLoading={isResendInvitationLoading}
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onResendInvitation)}>
                    <Box display={"flex"} flexDirection='column' alignItems={"space-between"} gap={2}>
                        <Typography>{translate("resend_invitation_content", { email: editingItem?.mail })}</Typography>
                        <RHFSelect name={"invitationLanguage"} size='small' label={translate("invitation_language")} required>
                            {allLangs.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Box display='flex' flexDirection='row'>
                                        <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
                                        {option.label}
                                    </Box>
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <Box
                            sx={{
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                padding: 2,
                                gap: 2,
                            }}
                        >
                            <Button
                                disabled={isResendInvitationLoading}
                                onClick={() => dispatch(setModalOpen(AppModals.TenantDetails))}
                                color={"primary"}
                            >
                                {translate("cancel")}
                            </Button>
                            <LoadingButton
                                type='submit'
                                variant='contained'
                                color={"primary"}
                                loading={isResendInvitationLoading}
                                disabled={isResendInvitationLoading}
                            >
                                {translate("send")}
                            </LoadingButton>
                        </Box>
                    </Box>
                </FormProvider>
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("resend")}
                isOpen={isMobile && modalOpen === AppModals.ResendInvitation}
                onClose={() => dispatch(setModalOpen(AppModals.TenantDetails))}
                headerColor={
                    currentTenant?.name === "-"
                        ? getMailNotificationColor(currentTenant?.status as RentingPeriodMailNotificationStatus, theme)
                        : getUserAcceptanceAccountColor(currentTenant?.status as RentingPeriodUserAcceptanceStatus, theme)
                }
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onResendInvitation)}>
                        <Typography padding={1} textAlign={"center"}>
                            {translate("resend_invitation_content", { email: currentTenant?.mail })}
                        </Typography>
                        <RHFSelect name={"invitationLanguage"} size='small' label={translate("invitation_language")} required>
                            {allLangs.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <Box display='flex' flexDirection='row'>
                                        <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
                                        {option.label}
                                    </Box>
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <Box
                            sx={{
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                padding: 2,
                                gap: 2,
                            }}
                        >
                            <LoadingButton
                                type='submit'
                                variant='contained'
                                color={"primary"}
                                fullWidth
                                loading={isResendInvitationLoading}
                                disabled={isResendInvitationLoading}
                            >
                                {translate("send")}
                            </LoadingButton>
                            <Button
                                disabled={isResendInvitationLoading}
                                onClick={() => dispatch(setModalOpen(AppModals.TenantDetails))}
                                color={"primary"}
                                fullWidth
                            >
                                {translate("cancel")}
                            </Button>
                        </Box>
                    </FormProvider>
                </Box>
            </DetailsSheet>
        </>
    )
}
