import { Box, Typography } from "@mui/material"
import CallIcon from "@mui/icons-material/Call"
import { useTheme } from "@mui/material/styles"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import EmailIcon from "@mui/icons-material/Email"
import { Schedule } from "@mui/icons-material"
import React from "react"
import { UserFlags } from "../../../queries/userData"
import { useFeatureValue } from "@growthbook/growthbook-react"

export default function AgencyContactPage() {
    const theme = useTheme()
    const email = useFeatureValue(UserFlags.Email.toString(), "")
    const name = useFeatureValue(UserFlags.Name.toString(), "")
    const location = useFeatureValue(UserFlags.Location.toString(), "")
    const schedule = useFeatureValue(UserFlags.Schedule.toString(), "")
    const phoneNumber = useFeatureValue(UserFlags.PhoneNumber.toString(), "")

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={4}>
            <Box display={"flex"} gap={1}>
                <Typography color={theme.palette.grey.A700} fontWeight={"bold"} variant={"h6"}>
                    {name}
                </Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
                <CallIcon color={"primary"} fontSize={"small"} />
                <Typography color={theme.palette.grey.A700}>{phoneNumber}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
                <EmailIcon color={"primary"} fontSize={"small"} />
                <Typography color={theme.palette.grey.A700}>{email}</Typography>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
                <Schedule color={"primary"} fontSize={"small"} />
                <Typography color={theme.palette.grey.A700}>{schedule}</Typography>
            </Box>

            <Box display={"flex"} gap={1} alignItems={"center"}>
                <LocationOnIcon color={"primary"} fontSize={"small"} />
                <Typography color={theme.palette.grey.A700}>{location}</Typography>
            </Box>
        </Box>
    )
}
