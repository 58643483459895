import NotificationContainer from "./NotificationContainer"
import RentingPeriodNotification from "./RentingPeriodNotification"
import { Typography, useTheme } from "@mui/material"
import React, { useCallback } from "react"
import { useLocales } from "rentzz"
import { useUserTenantRequests } from "../../../queries/userData"
import { updateRentingPeriod } from "../../../mutations/user"

const NoContractRentingPeriodNotification = () => {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: tenantRequests } = useUserTenantRequests()
    const { mutateAsync: updateTenant, isPending: isLoading } = updateRentingPeriod()

    const handleUpdate = useCallback(
        async (tenantId: number, accept: boolean) => {
            await updateTenant({ accept, tenantId })
        },
        [updateTenant],
    )

    const handleSubmit = useCallback(async () => {
        if (tenantRequests == null || tenantRequests[0] == null) return
        if (tenantRequests[0].contractToBeSigned == null) await handleUpdate(tenantRequests[0].id, true)
    }, [tenantRequests])

    return (
        <NotificationContainer title={"add_renting_period"}>
            <Typography color={theme.palette.primary.dark} variant='h5'>
                {translate("renting_period_notifications.title")}
            </Typography>
            <RentingPeriodNotification nextText={"Accept"} onNextClick={handleSubmit} isNextLoading={isLoading} />
        </NotificationContainer>
    )
}

export default NoContractRentingPeriodNotification
