import { PropertyFileType } from "../../redux/slices/AddProperty"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { useDispatch, useSelector } from "../../redux/store"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef, GridRowParams, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro"
import { AppContext, CustomDataGrid, ExpenseAssignee, Iconify, Label, LightTooltip, LoadingIcon, useLocales } from "rentzz"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../utils/ActionWithTooltip"
import {
    AccountType,
    UserFlags,
    useTenantRentingPeriodsQuery,
    useUserDataQueryFn,
    useUserLabelsQuery,
    useUserPropertiesQuery,
} from "../../queries/userData"
import { DESKTOP_ICON_SIZE, getReverseTextColor } from "../../utils/helpers"
import { getAssigneeLabelColor } from "./utils"
import { BalanceBehavior, PropertyExpense, useExpensesQueryFn } from "../../queries/expenses"
import DeleteIcon from "@mui/icons-material/Delete"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"
import CustomExpenseToolbar from "./Toolbar"
import ExpenseDetailsPanel from "./expense-details/ExpenseDetailsPanel"
import CustomDetailPanelToggle from "./CustomDetailPanelToggle"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useAtomValue } from "jotai"
import { expensesFilterState } from "../../utils/atom"
import RentingPeriodDetailsHeader from "../propertyDetails/Tenants/rentingPeriodDetails/RentingPeriodDetailsHeader"
import { HighlightedRow } from "rentzz/lib/components/data-grid/CustomDataGrid"
import AddCardIcon from "@mui/icons-material/AddCard"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import ExpensePaymentStatusComponent from "./ExpensePaymentStatusComponent"
import NoDataPage from "../../components/NoDataPage"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"

interface DesktopExpensesListProps {
    getAmount: (value: number, currencyId?: number) => string
}

const DesktopExpensesList = ({ getAmount }: DesktopExpensesListProps) => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }])
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate, currentLang } = useLocales()
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const daysUntilWarning = useFeatureValue(UserFlags.ExpenseDaysWarning, 3)
    const filterModel = useAtomValue(expensesFilterState)
    const { data: expenses, isFetching } = useExpensesQueryFn(paginationModel.page, paginationModel.pageSize, false, sortModel, filterModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()
    const { data: labels } = useUserLabelsQuery()
    const api = useGridApiRef()
    const sendLabelToTenant = useFeatureIsOn(UserFlags.SendLabelToTenant.toString())
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const currentTenantRentingPeriod = useMemo(
        () => tenantRentingPeriods?.find((t) => t.rentingPeriodId === currentRentingPeriodId),
        [tenantRentingPeriods, currentRentingPeriodId],
    )
    const isPaymentOnlineEnabled = useFeatureIsOn(UserFlags.ExpenseOnlinePayment.toString())

    useEffect(() => {
        if (expenses?.items?.length === 0 && expenses.count === 0 && api.current?.setRows) api.current?.setRows([])
    }, [expenses])

    const getExpensesPanel = useCallback((row: GridRowParams<PropertyExpense>) => {
        return <ExpenseDetailsPanel currentExpense={row} />
    }, [])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 150,
            flex: 0.4,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<PropertyExpense & { canWrite: boolean; canDelete: boolean }>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<AddCardIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                if (isPaymentOnlineEnabled) {
                                    dispatch(setModalOpen(AppModals.TenantPayment))
                                } else {
                                    dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
                                }
                            }}
                            label={translate("payment")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden:
                        context === AppContext.Owner ||
                        (!currentTenantRentingPeriod?.acceptPartialPayments && params.row.propertyIncomesCount >= 1) ||
                        params.row.value <= 0,
                    tooltipText: translate("payment.addPayment"),
                    key: "payment",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={params.row.expenseBalanceBehavior === BalanceBehavior.RealValue ? "primary" : "inherit"}
                            icon={
                                params.row.expenseBalanceBehavior === BalanceBehavior.RealValue ? (
                                    <AttachMoneyIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <MoneyOffIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )
                            }
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.UpdateExpenseBalanceStatus))
                            }}
                            label={translate("update")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: context === AppContext.Tenant || !params.row.canWrite || params.row.value >= 0,
                    tooltipText: translate(`expense_reports_behavior-${params.row.expenseBalanceBehavior}`),
                    key: "update",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.AddExpense))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: context === AppContext.Tenant || !params.row.canWrite,
                    tooltipText: "",
                    key: "edit",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Expense,
                                        name: params.row.name,
                                        canWrite: params.row.canWrite,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteExpense))
                                dispatch(setEditingItem({ id: params.row.id }))
                            }}
                            label={`${translate("grid_actions.delete")}${params.row.propertyIncomes?.length > 0 ? translate("income_assigned") : ""}`}
                            sx={{ p: 1 }}
                            disabled={params.row.propertyIncomes?.length > 0}
                        />
                    ),
                    tooltipText: params.row.propertyIncomes?.length > 0 ? translate("can_not_delete_expense") : "",
                    key: "delete",
                    hidden: context === AppContext.Tenant || !params.row.canDelete,
                }),
            ],
        }),
        [translate, context, currentTenantRentingPeriod],
    )

    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [labels],
    )
    const unpaidExpenses: HighlightedRow[] | undefined = useMemo(
        () =>
            expenses?.items
                .filter((e) =>
                    context === AppContext.Owner
                        ? e.propertyIncomes.length === 0 && e.assignee === ExpenseAssignee.TENANT && e.dueDate < DateTime.now() && e.value > 0
                        : e.propertyIncomes.length === 0 && e.dueDate < DateTime.now() && e.value > 0,
                )
                .map((expense) => {
                    return { id: expense.id, type: "error" }
                }),
        [expenses, context],
    )

    const pendingExpenses: HighlightedRow[] | undefined = useMemo(
        () =>
            expenses?.items
                .filter((e) =>
                    context === AppContext.Owner
                        ? e.propertyIncomes.length === 0 &&
                          e.assignee === ExpenseAssignee.TENANT &&
                          e.dueDate > DateTime.now() &&
                          e.dueDate.diffNow("days").days <= daysUntilWarning &&
                          e.value > 0
                        : e.propertyIncomes.length === 0 &&
                          e.dueDate > DateTime.now() &&
                          e.dueDate.diffNow("days").days <= daysUntilWarning &&
                          e.value > 0,
                )
                .map((expense) => {
                    return { id: expense.id, type: "warning" }
                }),
        [expenses, context, daysUntilWarning],
    )

    const mergedExpenses = useMemo(() => [...(pendingExpenses ?? []), ...(unpaidExpenses ?? [])], [pendingExpenses, unpaidExpenses])

    const expenseColumns = useMemo(() => {
        let columns: GridColDef<PropertyExpense>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return (
                        <CustomDetailPanelToggle
                            id={params.id}
                            value={params.value}
                            title={context === AppContext.Owner ? "see_incomes" : "see_payments"}
                        />
                    )
                },
            },
            {
                field: "propertyIncomesCount",
                type: "number",
                width: context === AppContext.Owner ? 80 : 60,
                headerAlign: "center",
                align: "center",
                headerName: "",
                sortable: true,
                sortingOrder: ["asc", null],
                filterable: false,
                renderCell: (v) => {
                    return (
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <ExpensePaymentStatusComponent
                                currentExpense={v.api.getRow(v.id)}
                                getAmount={getAmount}
                                mergedExpenses={mergedExpenses}
                            />
                            {v.row.isAutomaticExpense && context === AppContext.Owner && (
                                <LightTooltip title={translate("is_automatic_expense")} arrow>
                                    <Iconify
                                        icon={"mdi:settings"}
                                        color={theme.palette.primary.main}
                                        width={DESKTOP_ICON_SIZE}
                                        height={DESKTOP_ICON_SIZE}
                                    />
                                </LightTooltip>
                            )}
                        </Box>
                    )
                },
            },
            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("expenses.name"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.4,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "assignee",
                type: "number",
                minWidth: 145,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.assignee"),
                renderCell: (v) => {
                    return (
                        <Label color={getAssigneeLabelColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`expenses.assignee-${v.value as number}${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </Label>
                    )
                },
            },
            {
                field: "providerName",
                flex: 0.4,
                minWidth: 110,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("provider"),
            },
            {
                field: "labelId",
                type: "actions",
                flex: 0.4,
                minWidth: 160,
                headerClassName: "hideRightSeparator",
                headerName: translate("labelDescription"),
                renderCell: (v) => {
                    if (context === AppContext.Tenant && sendLabelToTenant && v.row.labelColor != null && v.row.labelColor.length > 0) {
                        return (
                            <Label
                                style={{
                                    textTransform: "none",
                                    backgroundColor: `#${v.row.labelColor}`,
                                    color: getReverseTextColor(`#${v.row.labelColor}`),
                                }}
                            >
                                {v.row.labelText}
                            </Label>
                        )
                    }
                    if (v.value == null) return ""
                    const label = labels?.find((label) => label.id === v.value)?.value
                    if (label == null) return ""

                    return (
                        <Label
                            style={{
                                textTransform: "none",
                                backgroundColor: `#${getLabelColor(v.value)}`,
                                color: getReverseTextColor(`#${getLabelColor(v.value)}`),
                            }}
                        >
                            {label}
                        </Label>
                    )
                },
            },
            {
                field: "date",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "dueDate",
                type: "number",
                minWidth: 150,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("due_date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]
        if (filterModel.items.find((i) => i.field === "propertyIncomesCount")?.value === 1) {
            columns = columns.filter((c) => c.field !== "propertyIncomesCount")
        }

        if ((currentPropertyId || currentRentingPeriodId) && context === AppContext.Owner) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        if (context === AppContext.Tenant) {
            return columns
                .filter(
                    (c) =>
                        c.field !== "actions" && c.field !== "propertyId" && (sendLabelToTenant || c.field !== "labelId") && c.field !== "assignee",
                )
                .splice(0)
        }
        return columns
    }, [
        expenses,
        currentPropertyId,
        currentLang,
        userProperties,
        userData,
        getLabelColor,
        context,
        currentRentingPeriodId,
        translate,
        labels,
        getAmount,
        mergedExpenses,
        filterModel,
        theme,
    ])

    if (expenses == null) return <LoadingIcon />

    if (userProperties?.length === 0 && userData?.accountType === AccountType.WHITELABEL_USER && context === AppContext.Owner) {
        return <NoPropertyPage />
    }

    return (
        <>
            {currentRentingPeriodId ? (
                <RentingPeriodDetailsHeader page={"expenses"} />
            ) : (
                <CustomExpenseToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddExpense))}
                    numberOfItems={expenses?.count}
                    canExport={true}
                />
            )}
            <CustomDataGrid
                apiRef={api}
                paginationMode={expenses.count === 20 && expenses.items.length === 0 ? "client" : "server"}
                hidePagination={expenses.count === 20 && expenses.items.length === 0}
                isLoading={isFetching && !(expenses.count === 20 && expenses.items.length === 0)}
                rowsLoadingMode={expenses.count === 20 && expenses.items.length === 0 ? "server" : "client"}
                columns={expenseColumns}
                rows={expenses.items ?? []}
                getDetailPanelContent={getExpensesPanel}
                components={{
                    noRowsOverlay: () => (
                        <NoDataPage icon={"mdi:tag-off"} noDataText={context === AppContext.Owner ? "no_expenses" : "tenant_view_no_expense"} />
                    ),
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={expenses.count}
                filterModel={filterModel}
                actionsColumn={[actionsColumn]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                highlightedRows={mergedExpenses}
                rowHeight={50}
            />
        </>
    )
}

export default DesktopExpensesList
