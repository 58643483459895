import { CustomDialog, useLocales } from "rentzz"
import { Box, CircularProgress, Typography } from "@mui/material"
import React from "react"
import { PropertyInsurance } from "../../redux/slices/AddProperty"

interface EditInsuranceNotificationProps {
    isVisible: boolean
    onClose: () => void
    onSubmit: () => void
    isLoading: boolean
    currentInsurance: (PropertyInsurance & { canWrite: boolean }) | null | undefined
}

export default function EditInsuranceNotification({ isVisible, onClose, onSubmit, isLoading, currentInsurance }: EditInsuranceNotificationProps) {
    const { translate } = useLocales()

    return (
        <CustomDialog
            isFullScreen={false}
            title={translate("insurance.editNotificationTitle")}
            onClose={onClose}
            open={isVisible}
            maxWidth='xs'
            onAccept={onSubmit}
            acceptText={isLoading ? undefined : currentInsurance?.shouldNotifyForRenewal ? translate("stop") : translate("yes")}
            closeText={isLoading ? undefined : translate("cancel")}
            variantCloseButton={isLoading ? undefined : currentInsurance?.shouldNotifyForRenewal ? "contained" : "text"}
            acceptButtonColor={currentInsurance?.shouldNotifyForRenewal ? "error" : "primary"}
            closeButtonColor={"primary"}
        >
            {isLoading ? (
                <Box display={"flex"} justifyContent={"center"} height={100}>
                    <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                </Box>
            ) : (
                <Typography paddingY={2}>
                    {currentInsurance?.shouldNotifyForRenewal
                        ? translate("insurance.editNotificationContentOff")
                        : translate("insurance.editNotificationContentOn")}
                </Typography>
            )}
        </CustomDialog>
    )
}
