import { Box, Button } from "@mui/material"
import { LightTooltip, useLocales } from "rentzz"
import ExportButton from "../sections/expenses/export/ExportButton"
import { ExportType } from "../utils/actions"
import HelpComponent from "./HelpComponent"
import { ReactNode } from "react"

interface CustomToolbarProps {
    onAddClick?: () => void
    numberOfItems?: number
    buttonText: string
    canExport?: boolean
    exportType?: ExportType
    isDisabled?: boolean
    tooltipTitle?: string
    children?: ReactNode
}
const CustomToolbar = ({ onAddClick, numberOfItems, canExport, buttonText, isDisabled, tooltipTitle, exportType, children }: CustomToolbarProps) => {
    const { translate } = useLocales()

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                }}
            >
                <Box display={"flex"} gap={1}>
                    {canExport && exportType && <ExportButton isDisabled={numberOfItems == null || numberOfItems === 0} type={exportType} />}
                    {onAddClick && (
                        <HelpComponent helpPage={"https://rentzz.app/support/"}>
                            <LightTooltip title={tooltipTitle ? translate(tooltipTitle) : ""} arrow>
                                <span>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={onAddClick}
                                        sx={{ textTransform: "none", alignSelf: "flex-end" }}
                                        disabled={isDisabled}
                                    >
                                        {translate(buttonText)}
                                    </Button>
                                </span>
                            </LightTooltip>
                        </HelpComponent>
                    )}
                </Box>
            </Box>
            {children}
        </Box>
    )
}

export default CustomToolbar
