import { useTheme } from "@mui/material/styles"
import { LoadingIcon, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import React, {useMemo} from "react"
import { Box, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import CustomAddCard from "../../../components/CustomAddCard"
import { useGeneralDocumentsQuery } from "../../../queries/generalDocuments"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { useNavigate } from "react-router-dom"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { CloseIcon } from "yet-another-react-lightbox"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import AddIcon from "@mui/icons-material/Add"
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import FileUploadIcon from "@mui/icons-material/FileUpload"

export default function MobileGeneralDocumentsList() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { modalOpen } = useSelector((state) => state.appState)
    const { data: generalDocuments, isLoading: areGeneralDocumentsLoading } = useGeneralDocumentsQuery()
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)

    const isVisible = useMemo(() => (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER) || !isWhitelabel, [])

    if (areGeneralDocumentsLoading) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {generalDocuments?.count !== 0 && modalOpen == null && isVisible && (
                <SpeedDial
                    sx={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                    }}
                    color='primary'
                    ariaLabel='mobileGeneralDocumentsActions'
                    icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<MoreVertIcon />} />}
                >
                    <SpeedDialAction
                        tooltipTitle={translate("add")}
                        tooltipOpen
                        key={"add"}
                        icon={<AddIcon />}
                        onClick={() => navigate("/document-templates/general-documents/new")}
                    />
                    <SpeedDialAction
                        key={"upload"}
                        icon={<FileUploadIcon />}
                        tooltipTitle={translate("upload_file")}
                        tooltipOpen
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.UploadGeneralDocument))
                        }}
                    />
                    <SpeedDialAction
                        key={"learnMore"}
                        icon={<LocalLibraryIcon />}
                        tooltipTitle={translate("learn_more")}
                        tooltipOpen
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.ShowLearnMore))
                        }}
                    />
                </SpeedDial>
            )}

            <Box display={"flex"} flexDirection={"column"} gap={1}>
                {generalDocuments?.items.map((document) => (
                    <CustomMobileCard
                        key={document.id}
                        item={document}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.GeneralDocumentDetails))
                            dispatch(setEditingItem({ id: document.id }))
                        }}
                        icon={"mdi:file-document-box-multiple"}
                        iconColor={theme.palette.primary.main}
                        title={document.name}
                    />
                ))}
            </Box>

            {generalDocuments?.count === 0 && isVisible && (
                <CustomAddCard
                    onCardClick={() => navigate("/document-templates/general-documents/new")}
                    icon={"mdi:file-document-box-plus"}
                    text={"add_general_document"}
                />
            )}
        </Box>
    )
}
