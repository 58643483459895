import { CustomDialog, LoadingIcon, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback, useMemo } from "react"
import DesktopMetersValuesHistory from "./DesktopMetersValuesHistory"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useIntl } from "react-intl"
import { useSelector } from "../../../../redux/store"
import { useMetersQueryFn } from "../../../../queries/meters"
import { AppModals } from "../../../../redux/slices/App"

interface Props {
    onClose: () => void
}

const DesktopMeterValuesHistoryList = ({ onClose }: Props) => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: unitData, isLoading: areUnitsLoading } = useUnitsQueryFn()
    const intl = useIntl()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn(editingItem?.tableId ? editingItem?.propertyId : undefined)

    const currentMeter = useMemo(() => meters?.find((meter) => meter.id === editingItem?.id), [meters, editingItem])

    const getValue = useCallback(
        (value: number) => {
            if (value === -1) return "-"
            if (!currentMeter?.unitId) return ""
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, currentMeter?.unitId).code}`
        },
        [currentMeter, unitData, intl],
    )

    if (areUnitsLoading) return <LoadingIcon />

    return (
        <CustomDialog
            title={`${translate("readings")} ${currentMeter?.name}`}
            maxWidth={"md"}
            showCloseIcon
            closeText={translate("cancel")}
            closeButtonColor={"primary"}
            open={!isMobile && modalOpen === AppModals.MeterValuesHistory}
            onClose={onClose}
            titleAlign={"center"}
            paddingContent={2}
            isFullScreen={isMobile}
            variantCloseButton={"text"}
        >
            <DesktopMetersValuesHistory getValue={getValue} currentMeter={currentMeter} />
        </CustomDialog>
    )
}

export default DesktopMeterValuesHistoryList
