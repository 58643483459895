import { CustomDialog, CustomFile, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { InsuranceType, PropertyInsurance } from "../../redux/slices/AddProperty"
import AddFormInsurance from "./AddFormInsurance"

interface AddInsuranceProps {
    open: boolean
    onClose: () => void
    onSave: (data: AddInsuranceRequest | UpdateInsuranceRequest) => Promise<void>
    isLoading: boolean
    defaultValues: (PropertyInsurance & { canWrite: boolean }) | null | undefined
}

export interface UpdateInsuranceRequest {
    id?: number
    insuranceType: InsuranceType
    insurer: string
    startDate: string
    endDate: string
    value: number
    currencyId: number
    propertyId: number
}
export interface AddInsuranceRequest extends UpdateInsuranceRequest {
    shouldNotifyForRenewal: boolean
    shouldCreateExpense: boolean
    files: CustomFile[]
}

export default function AddInsurance({ open, onClose, onSave, isLoading, defaultValues }: AddInsuranceProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <CustomDialog
            open={open}
            title={translate(defaultValues ? "insurance.editInsurance" : "insurance.addInsurance")}
            titleAlign={"center"}
            onClose={onClose}
            showCloseIcon={true}
            maxWidth={"sm"}
            isFullScreen={isMobile}
        >
            <AddFormInsurance onClose={onClose} onSave={onSave} isLoading={isLoading} defaultValues={defaultValues} />
        </CustomDialog>
    )
}
