import { Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { useIsMobile, useLocales } from "rentzz"
import SignaturePad from "signature_pad"

interface Props {
    onSave: (signature: string) => void
    onBack: () => void
    defaultValue?: string
    isLoading: boolean
    onNext?: (signature: string) => void
    onClose?: () => void
}
export default function SignatureTenantContent({ onSave, onBack, defaultValue, isLoading, onNext, onClose }: Props) {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const signaturePad = useRef<SignaturePad | undefined>(undefined)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        if (defaultValue) signaturePad.current?.fromDataURL(defaultValue)
    }, [])

    useEffect(() => {
        signaturePad.current = new SignaturePad(document.getElementById("tenantSignature") as HTMLCanvasElement)
        signaturePad.current?.on()

        return () => signaturePad.current?.off()
    }, [])

    const handleSignatureReset = useCallback(() => {
        signaturePad.current?.clear()
    }, [signaturePad])

    const handleSignatureSave = useCallback(() => {
        if (signaturePad.current == null) return
        if (signaturePad.current?.isEmpty()) return setIsError(true)
        setIsError(false)
        if (onNext) {
            onNext(signaturePad.current.toDataURL())
            onClose?.()
        } else {
            onSave(signaturePad.current.toDataURL())
        }
    }, [onSave, setIsError, onNext])

    return (
        <>
            <Box display='flex' width='100%' height={250} marginTop={4} justifyContent='space-around' flexDirection={"column"} alignItems={"center"}>
                <Typography color={theme.palette.primary.dark} fontSize={16} fontWeight={"bold"}>
                    {translate("if_you_agree")}
                </Typography>
                <Box width={300} height={150} sx={{ border: 1, borderColor: theme.palette.primary.main, backgroundColor: "white" }}>
                    <canvas id={"tenantSignature"} width={300} height={150} style={{ width: 300, height: 150 }} />
                </Box>
                {isError && (
                    <Box display={"flex"} justifyContent={"center"} width={"100%"} marginTop={1}>
                        <Typography color={"error"} fontSize={12}>
                            {translate("request_signature")}
                        </Typography>
                    </Box>
                )}

                <Button color={"primary"} sx={{ textTransform: "none" }} onClick={handleSignatureReset}>
                    {translate("try_again")}
                </Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton color={"primary"} onClick={onBack} loading={isLoading} disabled={isLoading}>
                    {translate("back")}
                </LoadingButton>
                <LoadingButton color={"primary"} variant='contained' onClick={handleSignatureSave} loading={isLoading} disabled={isLoading}>
                    {translate(isMobile ? "save" : "accept_invitation")}
                </LoadingButton>
            </Box>
        </>
    )
}
