import { Box, Divider, IconButton, Paper, Tooltip, Typography, useTheme } from "@mui/material"
import { Iconify, Label, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useDispatch } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import React from "react"

const Features = () => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: property } = usePropertyDetailsQueryFn()
    const theme = useTheme()
    const { isPropertyEditable } = usePermissions(PermissionType.Properties)

    return (
        <Paper elevation={6} sx={{ padding: 1, color: "text.secondary" }}>
            <Box display={"flex"} justifyContent={"space-between"} flex={"1"}>
                <Box display='flex' flexDirection='row' flex={1} alignItems={"center"}>
                    <Box width={"50%"}>
                        <Typography textAlign={"center"} variant='subtitle1'>
                            {translate("utilities")}
                        </Typography>
                    </Box>
                    <Box width={"50%"} textAlign={"center"} paddingLeft={2}>
                        <Typography variant='subtitle1'>{translate("features")}</Typography>
                    </Box>
                </Box>
                {isPropertyEditable && (
                    <>
                        {property?.marketingSettings?.features.length === 0 && property?.marketingSettings.utilities?.length == 0 ? (
                            <Tooltip title={translate("add")} arrow>
                                <IconButton
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.UpdatePropertyFeaturesAndUtilities))
                                    }}
                                >
                                    <Iconify icon={"mdi:add"} sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={translate("edit")} arrow>
                                <IconButton
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.UpdatePropertyFeaturesAndUtilities))
                                    }}
                                >
                                    <Iconify icon={"mdi:edit"} sx={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}
            </Box>

            <Divider sx={{ marginY: 1 }} />
            <Box display='flex' flexDirection='row' height={290} sx={{ overflowY: "auto", width: "100%" }} gap={1}>
                <Box flexWrap={"wrap"} width={"50%"}>
                    {property && property?.marketingSettings && property?.marketingSettings.utilities?.length > 0 ? (
                        property?.marketingSettings.utilities?.map((u) => (
                            <Label key={u} sx={{ marginRight: 1, marginBottom: 1 }}>
                                {translate(`utility-${u}`)}
                            </Label>
                        ))
                    ) : (
                        <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center' gap={1}>
                            <Iconify icon={"mdi:home-remove-outline"} sx={{ color: theme.palette.primary.main, width: 36, height: 36 }} />
                            <Typography variant='caption'>{translate("no_utilities")}</Typography>
                        </Box>
                    )}
                </Box>
                <Divider orientation={"vertical"} />
                <Box flexWrap={"wrap"} gap={1} width={"50%"}>
                    {property && property?.marketingSettings && property?.marketingSettings.features?.length > 0 ? (
                        property?.marketingSettings.features?.map((f) => (
                            <Label key={f} sx={{ marginRight: 1, marginBottom: 1 }}>
                                {translate(`feature-${f}`)}
                            </Label>
                        ))
                    ) : (
                        <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center' gap={1}>
                            <Iconify icon={"mdi:home-off-outline"} sx={{ color: theme.palette.primary.main, width: 36, height: 36 }} />
                            <Typography variant='caption'>{translate("no_feature")}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Paper>
    )
}

export default Features
