import { JournalItemChanged, PropertyIncomeJournalDetailsDto } from "../../../../../../queries/expenses"
import { Box, Divider } from "@mui/material"
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowRight } from "@mui/icons-material"
import IncomeCellRender from "./Income"
import { useIsMobile } from "rentzz"

interface ExpenseCellRenderProps {
    data: JournalItemChanged<PropertyIncomeJournalDetailsDto>
}

const IncomeCellComparerRender = ({ data }: ExpenseCellRenderProps) => {
    const isMobile = useIsMobile()
    return (
        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1} p={1}>
            <Box display='flex' flexDirection='column' gap={1} p={1} width='100%'>
                <IncomeCellRender data={data.old} />
            </Box>
            <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem>
                {isMobile ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
            </Divider>

            <Box display='flex' flexDirection='column' gap={1} p={1}>
                <IncomeCellRender data={data.new} />
            </Box>
        </Box>
    )
}

export default IncomeCellComparerRender
