import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { SyntheticEvent, useCallback } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useSingleRecurringExpenseQuery } from "../../../queries/expenses"
import { deleteRecurringExpenseMutation } from "../../../mutations/expenses"

const DeleteRecurringExpenseGuard = () => {
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteRecurringExpense, isPending: isDeleteLoading } = deleteRecurringExpenseMutation()
    const { data: currentExpense } = useSingleRecurringExpenseQuery(editingItem?.id, [{ field: "createdAt", sort: "desc" }])
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.RecurringExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [isMobile, dispatch, editingItem])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (currentExpense == null) return
            await deleteRecurringExpense({ expenseId: currentExpense.id })

            dispatch(setModalOpen(undefined))
        },
        [modalOpen, dispatch, deleteRecurringExpense, currentExpense],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_recurring_expense")}
                isOpen={isMobile && modalOpen === AppModals.DeleteRecurringExpense}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_recurring_expense_details", { expenseName: currentExpense?.name })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteRecurringExpense}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_recurring_expense")}
                content={translate("delete_recurring_expense_details", { expenseName: currentExpense?.name })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}

export default DeleteRecurringExpenseGuard
