import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { CustomFile } from "rentzz"
import * as jsonpatch from "fast-json-patch"

export interface AddBankStatementRequest {
    bankAccountId: number
    files?: CustomFile[]
}

export interface AssociateExpenseToStatementTransactionRequest {
    currencyId: number
    propertyExpenseId: number
    value: number
    propertyIncomeId?: number
}

export const useAddBankStatementMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddBankStatementRequest }) => Api.addBankStatement(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankStatements])
            }
        },
    })
}

export const useUpdateBankStatementTransactionMutation = (transactionId: number) => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ statementId, operations }: { statementId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateBankStatementTransaction({
                transactionId,
                statementId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.BankStatements])
            }
        },
        mutationKey: ["update-transaction", { transactionId }],
    })
}

export const useLinkStatementTransactionToExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            data,
            statementId,
            transactionId,
        }: {
            data: AssociateExpenseToStatementTransactionRequest
            statementId: number
            transactionId: number
        }) => Api.associateStatementTransactionToExpense(statementId, transactionId, data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.BankStatements,
                    ComplexQueryIDs.BankStatementTransactionDetails,
                    ComplexQueryIDs.BankStatementTransactionPossibleExpenses,
                ])
            }
        },
    })
}

export const useDeleteStatementTransactionExpenseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ expenseId, statementId, transactionId }: { expenseId: number; statementId: number; transactionId: number }) =>
            Api.deleteAssociatedExpenseToTransaction({ statementId, transactionId, expenseId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.BankStatements,
                    ComplexQueryIDs.BankStatementTransactionDetails,
                    ComplexQueryIDs.BankStatementTransactionPossibleExpenses,
                ])
            }
        },
    })
}

export const useProcessBankStatement = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ statementId }: { statementId: number }) => Api.processBankStatement({ statementId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.BankStatements,
                    ComplexQueryIDs.BankStatementTransactionDetails,
                    ComplexQueryIDs.BankStatementTransactionPossibleExpenses,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    ComplexQueryIDs.ExpenseIncomes,
                    ComplexQueryIDs.IncomeExpenses,
                    ComplexQueryIDs.Income,
                    ComplexQueryIDs.Incomes,
                    ComplexQueryIDs.IncomeSummary,
                    ComplexQueryIDs.IncomeSummaryReport,
                ])
            }
        },
    })
}

export const deleteBankStatementMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteBankStatement,
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.BankStatements])
        },
    })
}
