import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import C168AddressConfiguration from "../../../../../sections/nomenclature/c168/C168AddressConfiguration"

export default function AddC168Address() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    const isDialogVisible = useMemo(() => {
        if (!isMobile && modalOpen === AppModals.AddC186Address) return true
        return false
    }, [isMobile, modalOpen])

    const handleClose = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.AddC186Address) {
                dispatch(setModalOpen(undefined))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch, isMobile])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={isDialogVisible}
                onClose={handleClose}
                maxWidth={"md"}
                title={translate("add_c168_address")}
                titleAlign={"center"}
            >
                <C168AddressConfiguration onClose={handleClose} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.AddC186Address}
                onClose={handleClose}
                isLoading={false}
                title={translate("add_c168_address")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <C168AddressConfiguration onClose={handleClose} />
            </DetailsSheet>
        </>
    )
}
