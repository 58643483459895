import { Box, Typography, useTheme } from "@mui/material"
import { Iconify, Label, useLocales } from "rentzz"
import React from "react"
import CustomCard from "../../../../components/CustomCard"
import { RecurringExpense } from "../../../../queries/expenses"
import { getRecurringTypeLabelColor, getRecurringTypeLabelText } from "../../utils"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { alpha } from "@mui/material/styles"

interface RecurringExpenseCustomCardProps {
    getAmount: (value: number, expenseId?: number) => string
    expense: RecurringExpense
    onClick: () => void
}

const RecurringExpenseCustomCard = ({ expense, getAmount, onClick }: RecurringExpenseCustomCardProps) => {
    const theme = useTheme()
    const { data: userProperties } = useUserPropertiesQuery()
    const { currentLang, translate } = useLocales()

    return (
        <CustomCard key={expense.id} onClick={onClick} isCardClickable px={1} py={1}>
            <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                {expense.createdAt.toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={expense.isActive ? "mdi:radiobox-marked" : "mdi:close-circle-outline"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: expense.isActive ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.warning.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: expense.isActive ? theme.palette.success.main : theme.palette.warning.main,
                            backgroundColor: expense.isActive ? alpha(theme.palette.success.main, 0.1) : alpha(theme.palette.warning.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {expense.name}
                        </Typography>

                        <Typography fontSize={12}>{userProperties?.find((p) => p.id === expense.propertyId)?.label}</Typography>
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"}>
                    <Typography alignSelf={"center"} fontSize={14} fontWeight='bold'>
                        {getAmount(expense.value, expense.currencyId)}
                    </Typography>
                    <Label
                        color={getRecurringTypeLabelColor(expense.recurringType)}
                        style={{
                            textTransform: "none",
                        }}
                    >
                        {translate(getRecurringTypeLabelText(expense.recurringType, currentLang.value, true))}
                    </Label>
                </Box>
            </Box>
        </CustomCard>
    )
}

export default React.memo(RecurringExpenseCustomCard)
