import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { SyntheticEvent, useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteSheetContent from "../../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../../dialogs/DeleteDialog"
import { getJournalByIdQueryFn } from "../../../../queries/journal"
import { deleteJournalMutation } from "../../../../mutations/tenants"
import { useAtomValue } from "jotai/index"
import { journalsFilterState } from "../../../../utils/atom"

export default function DeleteJournalGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const filterModel = useAtomValue(journalsFilterState)
    const currentJournal = getJournalByIdQueryFn(editingItem?.id, [{ field: "createdAt", sort: "desc" }], filterModel)
    const { mutateAsync: deleteJournal, isPending: isDeleteLoading } = deleteJournalMutation()

    const onClose = useCallback(() => {
        if (isMobile) return dispatch(setModalOpen(AppModals.JournalDetails))

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (!currentJournal) return
            await deleteJournal({ journalId: currentJournal.id })

            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [currentJournal, deleteJournal, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_journal")}
                isOpen={isMobile && modalOpen === AppModals.DeleteJournal}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_journal_content", { name: currentJournal?.title })}
                    onClose={() => {
                        dispatch(setModalOpen(AppModals.JournalDetails))
                    }}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteJournal}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_journal")}
                content={translate("delete_journal_content", { name: currentJournal?.title })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}
