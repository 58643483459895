import AlertsGuard from "../guards/alertsGuard/AlertsGuard"
import ContextGuard from "../guards/context/ContextGuard"
import ManagerNotificationGuard from "../guards/ManagerNotificationGuard"
import SubscriptionGuard from "../guards/SubscriptionGuard"
import OnboardedGuard from "../guards/OnboardedGuard"
import DashboardLayout from "./DashboardLayout"
import React, { Suspense } from "react"
import { LoadingScreen, ScrollToTop } from "rentzz"
import { Outlet } from "react-router-dom"
import MaintenanceGuard from "../guards/MaintenanceGuard"

const Root = () => (
    <MaintenanceGuard>
        <AlertsGuard>
            <ContextGuard>
                <ManagerNotificationGuard>
                    <SubscriptionGuard>
                        <OnboardedGuard>
                            <DashboardLayout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <ScrollToTop />
                                    <Outlet />
                                </Suspense>
                            </DashboardLayout>
                        </OnboardedGuard>
                    </SubscriptionGuard>
                </ManagerNotificationGuard>
            </ContextGuard>
        </AlertsGuard>
    </MaintenanceGuard>
)

export default Root
