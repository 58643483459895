import React from "react"
import AddRecurringExpense from "../../../sections/expenses/recurring/AddRecurringExpense"
import DeleteRecurringExpenseGuard from "./DeleteRecurringExpenseGuard"
import RecurringExpenseDetailsSheet from "../../../sections/expenses/recurring/mobile/RecurringExpenseDetailsSheet"

const RecurringExpensesGuard = () => {
    return (
        <>
            <AddRecurringExpense />
            <DeleteRecurringExpenseGuard />
            <RecurringExpenseDetailsSheet />
        </>
    )
}

export default RecurringExpensesGuard
