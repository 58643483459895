import { CustomFile, FormProvider, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../../../../queries/userData"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { Box, Button } from "@mui/material"
import { getOwnerMaxSize } from "../../../../../utils/actions"
import { LoadingButton } from "@mui/lab"
import { Journal } from "../../../../../queries/journal"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { AddJournalSchema } from "../../../../../validations/rentingPeriod"
import { useSelector } from "../../../../../redux/store"

interface Props {
    onClose: () => void
    onSave: (data: AddJournalRequest) => void
    isLoading: boolean
    defaultValues?: Journal
}

export interface AddJournalRequest {
    id?: number
    propertyId: number
    rentingPeriodId: number
    title: string
    description: string
    files: CustomFile[]
}

export default function AddOrEditJournalForm({ onSave, onClose, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()

    const methods = useForm<AddJournalRequest>({
        resolver: yupResolver<AddJournalRequest>(AddJournalSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                title: defaultValues.title,
                description: defaultValues.description,
                propertyId: defaultValues.propertyId,
                rentingPeriodId: defaultValues.rentingPeriodId,
                files: [],
            })
        }

        if (defaultValues == null) {
            if (rentingPeriodDetails == null) return
            reset({
                propertyId: rentingPeriodDetails.propertyId,
                rentingPeriodId: currentRentingPeriodId,
                files: [],
            })
        }
    }, [defaultValues, reset, rentingPeriodDetails])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFTextField name={"title"} label={translate("event_title")} size={"small"} required />
                    <RHFTextField name={"description"} label={translate("description")} multiline required minRows={3} maxRows={3} />
                    {defaultValues == null && (
                        <RHFUpload
                            name={"files"}
                            multiple
                            maxSize={getOwnerMaxSize(properties, userData?.features?.maxPropertySize, rentingPeriodDetails?.propertyId)}
                            icon={"mdi:file-document-plus-outline"}
                        />
                    )}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
