import { Box, Button } from "@mui/material"
import { CustomFile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback } from "react"
import { OwnerPropertyMeter, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { useDispatch } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"

interface CustomToolbarProps {
    lastValue?: number | null
    currentPropertyId: number | undefined
    meter: OwnerPropertyMeter | TenantPropertyMeter | undefined
}

export interface AddMeterValueRequest {
    previousValue: number
    value: number
    difference: number
    meterId: number
    files: CustomFile[]
    rentingPeriodId?: number
}

const CustomToolbarInner = ({ lastValue }: CustomToolbarProps) => {
    const dispatch = useDispatch()

    const { translate } = useLocales()

    const handleOpen = useCallback(() => {
        dispatch(setModalOpen(AppModals.AddNewIndex))
    }, [dispatch])

    return (
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, alignItems: "center" }} marginY={2}>
            <Button color='primary' variant='contained' onClick={handleOpen} disabled={lastValue == null} style={{ textTransform: "none" }}>
                {translate("add_reading")}
            </Button>
        </Box>
    )
}

export default CustomToolbarInner
