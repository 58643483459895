import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { updateExpenseMutation } from "../../../mutations/expenses"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import ExpenseBalanceForm from "../../../sections/expenses/ExpenseBalanceForm"

export default function UpdateExpenseStatus() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateExpense, isPending: isUpdateExpenseLoading } = updateExpenseMutation()
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)

    const closeModal = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const handleExpenseStatus = useCallback(async () => {
        if (currentExpense == null) return

        await updateExpense({
            propertyId: currentExpense.propertyId,
            expenseId: currentExpense.id,
            operations: [{ op: "replace", path: "/expenseBalanceBehavior", value: !currentExpense.expenseBalanceBehavior }],
        })
        closeModal()
    }, [currentExpense, closeModal, updateExpense])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("expense_reports_change_behavior_title")}
                isOpen={isMobile && modalOpen === AppModals.UpdateExpenseBalanceStatus}
                onClose={closeModal}
                headerColor={editingItem?.backgroundColor}
            >
                <ExpenseBalanceForm onClose={closeModal} handleExpenseBalanceStatus={handleExpenseStatus} isLoading={isUpdateExpenseLoading} />
            </DetailsSheet>
            <CustomDialog
                title={translate("expense_reports_change_behavior_title")}
                isFullScreen={false}
                maxWidth={"sm"}
                open={!isMobile && modalOpen === AppModals.UpdateExpenseBalanceStatus}
                onClose={closeModal}
            >
                <ExpenseBalanceForm onClose={closeModal} handleExpenseBalanceStatus={handleExpenseStatus} isLoading={isUpdateExpenseLoading} />
            </CustomDialog>
        </>
    )
}
