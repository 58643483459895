import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { DateTime } from "luxon"
import { AppContext } from "rentzz"
import { useSelector } from "../redux/store"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "./userData"

export enum InvoiceProvider {
    Oblio,
}

export interface InvoicingConfigurationBase {
    id: number
    invoiceProvider: InvoiceProvider
    user: string
    password: string
}

export interface InvoicingConfigurationDto extends InvoicingConfigurationBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfiguration extends InvoicingConfigurationBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationCompanyBase {
    id: number
    cif: string
    name: string
    isActive: boolean
}

export interface InvoicingConfigurationCompanyDto extends InvoicingConfigurationCompanyBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationCompany extends InvoicingConfigurationCompanyBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationSeriesBase {
    id: number
    name: string
}

export interface InvoicingConfigurationSeriesDto extends InvoicingConfigurationSeriesBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationSeries extends InvoicingConfigurationSeriesBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationVAT {
    id: number
    name: string
}

export interface InvoicingConfigurationClientBase {
    id: number
    name: string
}

export interface InvoicingConfigurationClientDto extends InvoicingConfigurationClientBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationClient extends InvoicingConfigurationClientBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}
export enum GeneratedStatus {
    OK,
    NotConfigured,
    SkippedDueToDeactivation,
}

export interface InvoicingDryRunBase {
    propertyName: string
    clientName: string
    value?: number
    currencyId?: number
    productName: string
    productDescription: string
    status: GeneratedStatus
}

export interface InvoicingDryRunDto extends InvoicingDryRunBase {
    date?: string
    dueDate?: string
}
export interface InvoicingDryRun extends InvoicingDryRunBase {
    date?: DateTime
    dueDate?: DateTime
}

export interface InvoicingItem {
    propertyName: string
    invoice: string
    invoiceDate: string
}

export const useAllInvoicingConfigurationsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchUserInvoicingConfigurations,
        queryKey: [SimpleQueryIDs.UserInvoicingConfigurations],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useInvoicingConfigurationsCompaniesQuery = (id: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchUserInvoicingConfigurationCompanies,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationCompanies, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useAllUserInvoicingCompanies = () => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchAllUserInvoicingCompanies,
        queryKey: [SimpleQueryIDs.UserInvoicingCompanies],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useCompanySeries = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchCompanySeries,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationSeries, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useCompanyVATs = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.ShowReinvoicingFields.toString())

    return useQuery({
        queryFn: Api.fetchCompanyVATs,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationVATs, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useCompanyClients = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchCompanyClients,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationClients, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useInvoicingDryRunInvoices = (companyId?: number) => {
    const isDryRunActive = useFeatureIsOn(UserFlags.DryRunInvoicing.toString())

    return useQuery({
        queryFn: Api.fetchDryRunInvoices,
        queryKey: [ComplexQueryIDs.DryRunInvoices, { id: companyId }],
        staleTime: Infinity,
        enabled: isDryRunActive && !!companyId,
    })
}
