import { array, number, object, string } from "yup"
import { DateTime } from "luxon"

export const PaymentSchema = object().shape({
    id: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    value: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .min(1, "required_value")
        .required("value_required")
        .positive("invalid_value"),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required()
        .min(0),
    date: string()
        .required()
        .test((date: string | undefined) => {
            if (date == null) return false

            const newDate = DateTime.fromISO(date)
            return newDate.isValid && DateTime.now().startOf("day") >= newDate.startOf("day")
        }),
    type: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required_type"),
    paymentMethod: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required_value"),
    referenceId: number().optional(),
    files: array().required(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
})
