import { AppContext, DetailsSheet, Iconify, MotionContainer, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useMemo, useState } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { PropertyRevision, RevisionStatus } from "../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { getRevisionByIdQueryFn, useTenantRevisionsQuery } from "../../../queries/revision"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

export default function RevisionDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const currentOwnerRevision = getRevisionByIdQueryFn(editingItem?.revisionId, [{ field: "date", sort: "desc" }])
    const { data: revisions } = useTenantRevisionsQuery()
    const currentTenantRevision = useMemo(() => revisions?.find((r) => r.id === editingItem?.revisionId), [editingItem, revisions])
    const [expanded, setExpanded] = useState<boolean>(false)

    const currentRevision = useMemo(
        () => (context === AppContext.Owner ? currentOwnerRevision : currentTenantRevision),
        [context, currentTenantRevision, currentOwnerRevision],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentRevision == null || context === AppContext.Tenant)
            return [
                {
                    icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "see_observations",
                    onClick: () => {
                        dispatch(setModalOpen(AppModals.ObservationsList))
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                canWrite: false,
                                canDelete: false,
                            }),
                        )
                    },
                },
            ]
        return [
            {
                icon:
                    currentRevision.status === RevisionStatus.Done ? (
                        <CheckCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    ) : (
                        <PublishedWithChangesIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    ),
                title: "change_status",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.UpdateRevisionStatus))
                },
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_observations",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.ObservationsList))
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            canWrite: (currentRevision as PropertyRevision & { canWrite: boolean; canDelete: boolean }).canWrite,
                            canDelete: (currentRevision as PropertyRevision & { canWrite: boolean; canDelete: boolean }).canDelete,
                        }),
                    )
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !(currentRevision as PropertyRevision & { canWrite: boolean }).canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditRevision))
                },
            },

            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !(currentRevision as PropertyRevision & { canDelete: boolean }).canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteRevision))
                },
            },
        ]
    }, [currentRevision, dispatch, context])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.RevisionDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={false}
            title={currentRevision?.name}
            options={options}
        >
            <Box p={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                    <Iconify icon={"mdi:information-variant"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontSize={13}
                            color={theme.palette.grey["600"]}
                            sx={{
                                overflow: "hidden",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {currentRevision?.description.substring(0, 120)}
                            {currentRevision && currentRevision?.description.length > 120 && !expanded && (
                                <>
                                    ...
                                    <Button
                                        size='small'
                                        color='primary'
                                        sx={{ marginBottom: 0.4 }}
                                        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                                    >
                                        {translate("view_all")}
                                    </Button>
                                </>
                            )}
                        </Typography>

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                <Typography
                                    fontSize={13}
                                    color={theme.palette.grey["600"]}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {currentRevision?.description.substring(121, currentRevision?.description.length - 1)}

                                    <Button size='small' color='primary' onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                                        {translate("close")}
                                    </Button>
                                </Typography>
                            </MotionContainer>
                        )}
                    </Box>
                </Box>
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("revisionDate")} ${currentRevision?.date?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {context === AppContext.Owner &&
                    (currentRevision as PropertyRevision)?.tenantChosenDate &&
                    (currentRevision as PropertyRevision)?.tenantChosenDate?.toLocaleString(DateTime.DATE_SHORT) !== "Invalid DateTime" &&
                    renderActionSheetInfo(
                        "mdi:calendar",
                        `${translate("tenantResponseDate")} ${(currentRevision as PropertyRevision)?.tenantChosenDate?.toLocaleString(
                            DateTime.DATE_SHORT,
                        )}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("notes.addedAt")} ${(currentRevision as PropertyRevision)?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("added_by")} ${(currentRevision as PropertyRevision)?.createdBy}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("lastModifiedAt")} ${(currentRevision as PropertyRevision)?.lastModifiedAt?.toLocaleString(
                            DateTime.DATE_SHORT,
                        )}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        (currentRevision as PropertyRevision)?.visibleToTenant ? "mdi:eye" : "mdi:eye-off",
                        translate((currentRevision as PropertyRevision)?.visibleToTenant ? "revisionVisibleToTenant" : "notVisibleToTenant"),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    (currentRevision as PropertyRevision)?.visibleToTenant &&
                    renderActionSheetInfo(
                        (currentRevision as PropertyRevision)?.allowTenantChoseDate ? "mdi:user-check" : "mdi:user-remove",
                        translate(
                            (currentRevision as PropertyRevision)?.allowTenantChoseDate ? "allowTenantChoseDateInfo" : "notAllowTenantChoseDateInfo",
                        ),
                        (currentRevision as PropertyRevision)?.allowTenantChoseDate ? theme.palette.success.main : theme.palette.error.main,
                        theme.palette.grey["600"],
                    )}
                {renderActionSheetInfo(
                    currentRevision?.status === RevisionStatus.Done ? "mdi:check-circle-outline" : "ic:outline-pending",
                    currentRevision?.status === RevisionStatus.Done ? translate("completed") : translate("pending"),
                    currentRevision?.status === RevisionStatus.Done ? theme.palette.primary.main : theme.palette.warning.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
