import { AppContext, Page, useIsMobile, useLocales } from "rentzz"
import { TabPanel } from "../../pages/PropertyDetails"
import React, { useCallback } from "react"
import Expenses from "./Expenses"
import RecurringExpenses from "./recurring/RecurringExpenses"
import { useSelector } from "../../redux/store"
import { expensesPageAtom, ExpensesPages } from "../../utils/atom"
import { Box, Button, ButtonGroup } from "@mui/material"
import { useAtom } from "jotai"
import PendingExpenses from "./pending/PendingExpenses"
import { UserFlags, useUserPropertiesQuery } from "../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"

export default function ExpensesPage() {
    const { translate } = useLocales()
    const { currentPropertyId, context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const [activePage, setActivePage] = useAtom(expensesPageAtom)
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())
    const { data: userProperties } = useUserPropertiesQuery()

    const handleChange = useCallback(
        (newValue: ExpensesPages) => {
            switch (newValue) {
                case ExpensesPages.ADDED:
                    setActivePage(ExpensesPages.ADDED)
                    break
                case ExpensesPages.PENDING_EXPENSES:
                    setActivePage(ExpensesPages.PENDING_EXPENSES)
                    break
                case ExpensesPages.RECURRING_EXPENSES:
                    setActivePage(ExpensesPages.RECURRING_EXPENSES)
                    break
            }
        },
        [setActivePage],
    )

    if (userProperties?.length === 0) return <NoPropertyPage />

    return (
        <Page
            title={translate("expenses.expenses")}
            propertyName={userProperties?.find((p) => p.id === currentPropertyId)?.label ?? ""}
            paddingBottom={2}
            paddingTop={1}
            px={currentPropertyId ? 0 : 2}
        >
            {isMobile && context === AppContext.Owner && !currentRentingPeriodId && (
                <Box justifyContent={"center"} display={"flex"}>
                    <ButtonGroup aria-label='outlined primary button group'>
                        <Button
                            variant={activePage === ExpensesPages.ADDED ? "contained" : "outlined"}
                            onClick={() => handleChange(ExpensesPages.ADDED)}
                        >
                            {translate("expenses.expenses")}
                        </Button>
                        {areExpenseProvidersEnabled && (
                            <Button
                                variant={activePage === ExpensesPages.PENDING_EXPENSES ? "contained" : "outlined"}
                                onClick={() => handleChange(ExpensesPages.PENDING_EXPENSES)}
                            >
                                {translate("expenses.pending-expenses")}
                            </Button>
                        )}
                        <Button
                            variant={activePage === ExpensesPages.RECURRING_EXPENSES ? "contained" : "outlined"}
                            onClick={() => handleChange(ExpensesPages.RECURRING_EXPENSES)}
                        >
                            {translate("expenses.recurring_expenses")}
                        </Button>
                    </ButtonGroup>
                </Box>
            )}
            <TabPanel value={activePage} index={ExpensesPages.ADDED}>
                <Expenses />
            </TabPanel>
            {areExpenseProvidersEnabled && (
                <TabPanel value={activePage} index={ExpensesPages.PENDING_EXPENSES}>
                    <PendingExpenses />
                </TabPanel>
            )}
            <TabPanel value={activePage} index={ExpensesPages.RECURRING_EXPENSES}>
                <RecurringExpenses />
            </TabPanel>
        </Page>
    )
}
