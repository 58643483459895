import { array, boolean, number, object, string } from "yup"
import { DateTime } from "luxon"

export const InsuranceBaseSchema = () =>
    object().shape({
        id: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        currencyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
        insurer: string().required("insurance.insurerRequired"),
        insuranceType: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("insurance.typeRequired")
            .min(0)
            .max(1),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("insurance.propertyRequired"),
        endDate: string()
            .required()
            .test("t", "date_before", (val: string | undefined, context) => {
                if (val == null || context.parent == null) return false
                const startDate = DateTime.fromISO(context.parent.startDate)
                const endDate = DateTime.fromISO(val)

                return startDate < endDate
            }),

        startDate: string()
            .required()
            .test("t", "date_before", (val: string | undefined, context) => {
                if (val == null || context.parent == null) return false
                const startDate = DateTime.fromISO(val)
                const endDate = DateTime.fromISO(context.parent.endDate)

                return startDate < endDate
            }),
        value: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("amount_required")
            .min(1, "amount_required")
            .positive("invalid_value"),
    })

export const AddInsuranceSchema = () =>
    InsuranceBaseSchema().shape({
        shouldNotifyForRenewal: boolean().required("insurance.notificationRequired"),
        shouldCreateExpense: boolean().required(),
        files: array().required(),
    })
