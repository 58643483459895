import { Page, useLocales } from "rentzz"
import React from "react"
import AddOrEditNotificationTemplate from "./AddOrEditNotificationTemplate"
import { useParams } from "react-router"
import { useNotificationTemplateDetailsQuery } from "../../../queries/userData"

export default function AddOrEditNotificationPage() {
    const { translate } = useLocales()
    const { notificationId } = useParams()
    const { data: notificationDetails } = useNotificationTemplateDetailsQuery(Number(notificationId))

    return (
        <Page title={translate("documents-templates")} propertyName={translate("notifications-templates")} paddingBottom={2}>
            <AddOrEditNotificationTemplate defaultValues={notificationDetails} />
        </Page>
    )
}
