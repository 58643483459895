import { DiaryType } from "../../../../../../queries/journal"
import ExpenseCellRender from "./Expense"
import { JournalItemChanged, PropertyExpenseJournalDetailsDto, PropertyIncomeJournalDetailsDto } from "../../../../../../queries/expenses"
import ExpenseComparer from "./ExpenseComparer"
import IncomeCellRender from "./Income"
import IncomeComparer from "./IncomeComparer"

interface CellRenderProps {
    data: unknown
    type: DiaryType | undefined
}
const GeneralCellRender = ({ data, type }: CellRenderProps) => {
    switch (type) {
        case DiaryType.AddedIncome:
        case DiaryType.DeletedIncome:
            return <IncomeCellRender data={data as PropertyIncomeJournalDetailsDto} />
        case DiaryType.ChangedIncome:
            return <IncomeComparer data={data as JournalItemChanged<PropertyIncomeJournalDetailsDto>} />
        case DiaryType.AddedExpense:
        case DiaryType.DeletedExpense:
            return <ExpenseCellRender data={data as PropertyExpenseJournalDetailsDto} />
        case DiaryType.ChangedExpense:
            return <ExpenseComparer data={data as JournalItemChanged<PropertyExpenseJournalDetailsDto>} />
        default:
            return ""
    }
}

export default GeneralCellRender
