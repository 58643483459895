import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { Currencies } from "rentzz"
import { DateTime } from "luxon"

export interface Currency {
    id: number
    code: Currencies
}

export const useCurrencyQueryFn = () => {
    return useQuery({ staleTime: Infinity, queryKey: [SimpleQueryIDs.Currencies], queryFn: Api.fetchCurrencies })
}

export const useExchangeRateQuery = (fromCurrencyId?: number, toCurrencyId?: number, date?: DateTime) => {
    return useQuery({
        staleTime: Infinity,
        queryKey: [ComplexQueryIDs.CurrencyExchange, { fromCurrencyId, toCurrencyId, date: date?.toISODate() }],
        queryFn: Api.fetchCurrencyExchangeRate,
        enabled: !!fromCurrencyId && !!toCurrencyId && !!date,
    })
}

export const getCurrencyFromId = (data: Currency[] | undefined, currencyId: number | undefined): Currency => {
    if (currencyId == null || data == null) {
        console.error("Invalid currency or data requested too fast")

        return {
            id: -1,
            code: Currencies.EUR,
        }
    }

    const currency = data.find((c) => c.id === currencyId)
    if (currency == null) throw new Error("Invalid currency provided")

    return currency
}
