import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { useCallback } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { deleteInsuranceMutation } from "../../../mutations/property/insurance"
import { useTheme } from "@mui/material/styles"
import { getInsuranceByIdQueryFn } from "../../../queries"

interface DeleteInsuranceGuardProps {
    onClose: () => void
}
export default function DeleteInsuranceGuard({ onClose }: DeleteInsuranceGuardProps) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { editingItem, modalOpen, currentPropertyId } = useSelector((state) => state.appState)
    const { mutateAsync: deleteInsurance, isPending: isDeleteInsuranceLoading } = deleteInsuranceMutation()
    const { data: currentInsurance } = getInsuranceByIdQueryFn(editingItem?.id)

    const onAccept = useCallback(async () => {
        if (currentInsurance == null) return
        await deleteInsurance({ insuranceId: currentInsurance.id, propertyId: currentPropertyId })
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [deleteInsurance, currentInsurance, currentPropertyId, dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_insurance")}
                isOpen={isMobile && modalOpen === AppModals.DeleteInsurance}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_insurance_details", {
                        insuranceName: currentInsurance?.insurer,
                    })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteInsuranceLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteInsurance}
                isDeleteLoading={isDeleteInsuranceLoading}
                title={translate("delete_insurance")}
                content={translate("delete_insurance_details", {
                    insuranceName: currentInsurance?.insurer,
                })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}
