import { useDispatch, useSelector } from "../../../../redux/store"
import { useCallback } from "react"
import { AppContext } from "rentzz"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useIntl } from "react-intl"
import TenantMeterDetails from "./TenantMeterDetails"
import OwnerMeterDetails from "./OwnerMeterDetails"
import { setEditingItem, setModalOpen } from "../../../../redux/slices/App"

export default function MeterDetailsSheet() {
    const { data: unitData } = useUnitsQueryFn()
    const intl = useIntl()
    const dispatch = useDispatch()
    const { context } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const getValue = useCallback(
        (value: number, unitId: number) => {
            if (value === -1) return "-"
            if (unitId == null) return ""
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, unitId).code}`
        },
        [unitData, intl],
    )

    if (context === AppContext.Tenant) return <TenantMeterDetails onClose={onClose} getValue={getValue} />

    return <OwnerMeterDetails onClose={onClose} getValue={getValue} />
}
