import InsuranceDetailsSheet from "./InsuranceDetailsSheet"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useCallback } from "react"
import { useIsMobile } from "rentzz"
import InsuranceNotificationGuard from "./InsuranceNotificationGuard"
import AddOrEditInsuranceGuard from "./AddOrEditInsuranceGuard"
import DeleteInsuranceGuard from "./DeleteInsuranceGuard"

export default function InsuranceGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        if (isMobile && modalOpen !== AppModals.InsuranceDetails && modalOpen !== AppModals.AddInsurance) {
            dispatch(setModalOpen(AppModals.InsuranceDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [modalOpen, dispatch, isMobile])

    return (
        <>
            <InsuranceDetailsSheet onClose={closeModal} />
            <AddOrEditInsuranceGuard onClose={closeModal} />
            <InsuranceNotificationGuard onClose={closeModal} />
            <DeleteInsuranceGuard onClose={closeModal} />
        </>
    )
}
