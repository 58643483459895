import {
    CustomFile,
    FormProvider,
    Iconify,
    LightTooltip,
    RHFCheckbox,
    RHFDayPicker,
    RHFTextField,
    RHFUnitInput,
    RHFUpload,
    useIsMobile,
    useLocales,
} from "rentzz"
import { Alert, Box, Button, Typography } from "@mui/material"
import { getOwnerMaxSize } from "../../../utils/actions"
import { LoadingButton } from "@mui/lab"
import * as React from "react"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddMeterSchema } from "../../../validations/addMeter"
import { max, min, sortBy } from "lodash"
import * as jsonpatch from "fast-json-patch"
import { useTheme } from "@mui/material/styles"
import { addMeterMutation, updateMeterMutation } from "../../../mutations/property/meters/meters"
import { useMetersQueryFn } from "../../../queries/meters"
import { useUnitsQueryFn } from "../../../queries/units"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"
import { OwnerPropertyMeter } from "../../../redux/slices/AddProperty"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"

export interface AddMeterRequest {
    id?: number
    name: string
    currentValue: number
    reportingPeriod: number[]
    unitId: number
    propertyId: number
    files: CustomFile[]
    requirePhotoForReading: boolean
    requireInteger: boolean
}
interface AddMeterFormProps {
    onClose: () => void
    defaultValues: OwnerPropertyMeter | undefined
}
export default function AddMeterForm({ defaultValues, onClose }: AddMeterFormProps) {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { mutateAsync: addMeter, isPending: isAddingMeter } = addMeterMutation()
    const { mutateAsync: edit, isPending: isEditingMeter } = updateMeterMutation()
    const { data: meters } = useMetersQueryFn()
    const { data: units } = useUnitsQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()
    const { currentPropertyId, errorMessage } = useSelector((state) => state.appState)
    const currentMeter = useMemo(() => (meters as OwnerPropertyMeter[])?.find((m) => m.id === defaultValues?.id), [defaultValues, meters])
    const { editableProperties } = usePermissions(PermissionType.Meters)

    useEffect(() => {
        if (units) {
            reset(
                defaultValues
                    ? { ...defaultValues, unitId: defaultValues?.unitId, files: [] }
                    : {
                          unitId: units[0].id,
                          reportingPeriod: [],
                          propertyId: currentPropertyId ?? (editableProperties ?? [])[0].id,
                          files: [],
                          requirePhotoForReading: false,
                          requireInteger: false,
                      },
            )
        }
    }, [units, defaultValues])

    const methods = useForm<AddMeterRequest>({
        resolver: yupResolver<AddMeterRequest>(AddMeterSchema),
        mode: "onChange",
        defaultValues: {
            unitId: units ? units[0].id : undefined,
            propertyId: currentPropertyId ?? (editableProperties ?? [])[0].id,
        },
    })

    const { handleSubmit, reset, watch, trigger } = methods

    useEffect(() => {
        const subscription = watch((_, { name }) => {
            if (name === "reportingPeriod")
                // TODO: hack
                trigger("reportingPeriod")
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const handleOnClose = () => {
        reset()
        onClose()
    }

    const onSubmit = async (data: AddMeterRequest) => {
        if (defaultValues == null) {
            await addMeter({ ...data, reportingPeriod: sortBy(data.reportingPeriod) })
        } else {
            if (currentMeter) {
                await edit({
                    operations: jsonpatch.compare(currentMeter, {
                        ...data,
                        reportingPeriodStartDay: min(data.reportingPeriod),
                        reportingPeriodEndDay: max(data.reportingPeriod),
                        reportingPeriod: sortBy(data.reportingPeriod),
                        files: undefined,
                    }),
                    meterId: currentMeter.id,
                    propertyId: currentPropertyId,
                })
            }
        }
        onClose()
        reset()
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection={"column"}>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2} marginBottom={2}>
                    <RHFTextField name='name' label={translate("meter_name")} placeholder={translate("hot_water")} size='small' required />
                    <RHFUnitInput
                        data={units ?? []}
                        unitName='unitId'
                        name='currentValue'
                        unitType='unit'
                        type='number'
                        size='small'
                        label={translate("current_value")}
                        disabled={defaultValues?.currentValue != null}
                        required
                    />
                </Box>

                <RHFCheckbox name='requirePhotoForReading' label={translate("requirePhotoForReading")} />
                <RHFCheckbox name='requireInteger' label={translate("value_must_be_an_integer")} />
                <Box display='flex' flexDirection='row' gap={16} justifyContent='center' alignItems={"center"} marginTop={4}>
                    <Typography fontWeight={"bold"} fontSize={14}>
                        {translate("reading_period")}*
                    </Typography>

                    <LightTooltip title={translate("select_reading_period")} arrow>
                        <span style={{ display: "flex" }}>
                            <Iconify icon={"mdi:question-mark-circle"} sx={{ width: 20, height: 20 }} color={theme.palette.primary.main} />
                        </span>
                    </LightTooltip>
                </Box>

                <Box display={"flex"} justifyContent={"center"}>
                    <RHFDayPicker name={"reportingPeriod"} defaultValue={defaultValues?.reportingPeriod ?? []} />
                </Box>

                {defaultValues == null && (
                    <Box marginTop={-3} display={"flex"} flexDirection={"column"} gap={2}>
                        <RHFUpload
                            name={"files"}
                            icon={"mdi:image-plus-outline"}
                            multiple
                            text={"addPhotoReading"}
                            maxSize={getOwnerMaxSize(userProperties, userData?.features?.maxPropertySize, currentPropertyId)}
                        />
                    </Box>
                )}

                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}

                <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"} marginTop={3}>
                    <Button color={"primary"} onClick={handleOnClose} disabled={isAddingMeter || isEditingMeter}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        color={"primary"}
                        loading={isAddingMeter || isEditingMeter}
                        type='submit'
                        variant='contained'
                        disabled={isAddingMeter || isEditingMeter}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
