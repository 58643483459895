import { Box, Link, Stack, Typography, useTheme } from "@mui/material"
import { Image, MotionContainer, useIsMobile, useLocales, varFade } from "rentzz"
import { m } from "framer-motion"
import { DocumentItemProps } from "../../tenantContext/rentingPeriodNotification/CustomCarousel"
import React from "react"

type CarouselItemProps = {
    item: DocumentItemProps
    isActive?: boolean
    itemNumber?: number
}

export function CarouselDocumentItem({ item, isActive, itemNumber }: CarouselItemProps) {
    const { title, subtitle, image } = item
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <MotionContainer action animate={isActive} sx={{ position: "relative" }}>
            <Stack
                spacing={2}
                sx={{
                    paddingX: 3,
                }}
            >
                {!isMobile && (
                    <m.div variants={varFade().inRight}>
                        <Link color='inherit' underline='none'>
                            <Typography
                                variant='body1'
                                letterSpacing={0.5}
                                textTransform='uppercase'
                                fontWeight={800}
                                color={theme.palette.primary.dark}
                                textAlign={"center"}
                            >
                                {itemNumber}. {translate(title)}
                            </Typography>
                        </Link>
                    </m.div>
                )}

                <m.div variants={varFade().inRight}>
                    <Link color='inherit' underline='none'>
                        <Typography variant='body1' fontSize={isMobile ? 14 : 16} textAlign={"center"}>
                            {translate(subtitle)}
                        </Typography>
                    </Link>
                </m.div>

                <m.div variants={varFade().inRight}>
                    <Box display='flex' width='100%'>
                        <Image disabledEffect alt={""} src={image} />
                    </Box>
                </m.div>
            </Stack>
        </MotionContainer>
    )
}
