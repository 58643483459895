import * as React from "react"
import { useCallback } from "react"
import DesktopMeterValuesHistoryList from "../../../../sections/propertyDetails/Meters/MetersHistory/DesktopMeterValuesHistoryList"
import MobileMeterValuesList from "../../../../sections/propertyDetails/Meters/mobile/MobileMeterValuesList"
import AddNewIndexGuard from "./AddNewIndexGuard"
import SendReadingPhotosGuard from "../metersDetails/SendReadingPhotosGuard"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useIsMobile } from "rentzz"

export default function MeterValuesHistoryGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        if (editingItem?.tableId && isMobile) return dispatch(setModalOpen(AppModals.StatusMeterDetails))
        if (isMobile) {
            dispatch(setModalOpen(AppModals.MeterDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch, isMobile, editingItem])

    return (
        <>
            <MobileMeterValuesList onClose={closeModal} />
            <DesktopMeterValuesHistoryList onClose={closeModal} />

            <AddNewIndexGuard />
            <SendReadingPhotosGuard />
        </>
    )
}
