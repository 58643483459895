import { FormProvider, RHFRadioGroup, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { NewInvitationSchema } from "../../../../../validations/propertyDetails"
import { NewInvitationOwnerRequest } from "./ManagersTable"
import { useSelector } from "../../../../../redux/store"
import { usePropertyDetailsQueryFn } from "../../../../../queries"
import { addNewInvitationMutation } from "../../../../../mutations"

interface Props {
    onClose: () => void
}

export enum ManagerType {
    FULL,
    READ,
    READ_WRITE,
}
export default function AddNewManagerInvitationForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId, errorMessage } = useSelector((state) => state.appState)
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { mutateAsync: addNewOwner, isPending: isAddNewOwnerLoading } = addNewInvitationMutation()

    const methods = useForm<NewInvitationOwnerRequest>({
        resolver: yupResolver(NewInvitationSchema()),
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            propertyId: currentPropertyId,
            email: "",
            managerType: ManagerType.FULL,
        },
    })

    const { handleSubmit, setError } = methods

    const onSubmit = useCallback(
        async (data: NewInvitationOwnerRequest) => {
            if (data == null) return
            const notifiedEmails = propertyDetails?.invitedUsers.map((user) => user.mail)
            if (notifiedEmails?.some((email) => email === data.email)) {
                setError("email", { message: "owner_exists" })
                return
            }
            await addNewOwner({ propertyId: data.propertyId, email: data.email, type: data.managerType })
            onClose()
        },
        [addNewOwner, onClose, setError],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={isMobile ? 2 : 1} flexDirection={"column"}>
                <RHFRadioGroup
                    name={"managerType"}
                    options={[
                        {
                            label: translate(`managerType-${ManagerType.FULL}`),
                            value: ManagerType.FULL,
                            moreInfo: "full_access_info",
                        },
                        {
                            label: translate(`managerType-${ManagerType.READ_WRITE}`),
                            value: ManagerType.READ_WRITE,
                            moreInfo: "read_write_access_info",
                        },
                        {
                            label: translate(`managerType-${ManagerType.READ}`),
                            value: ManagerType.READ,
                            moreInfo: "read_access_info",
                        },
                    ]}
                    sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
                />

                <RHFTextField name='email' label={translate("email")} required />
                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isAddNewOwnerLoading}
                        disabled={isAddNewOwnerLoading}
                        color={"primary"}
                        type={"submit"}
                        fullWidth
                        variant={isMobile ? "contained" : "text"}
                    >
                        {translate("send")}
                    </LoadingButton>
                    {isMobile && (
                        <Button color={"primary"} onClick={onClose} disabled={isAddNewOwnerLoading}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}
