import NotificationContainer from "../tenantContext/rentingPeriodNotification/NotificationContainer"
import { Alert, Box, Button, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { useLocales } from "rentzz"
import { ManagerRequest, useManagerRequests } from "../../queries/userData"
import { LoadingButton } from "@mui/lab"
import { setContext } from "../../redux/slices/App"
import { useDispatch, useSelector } from "../../redux/store"
import { ManagerType } from "../propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { updateManagerMutation } from "../../mutations/user"

interface Props {
    request: ManagerRequest
}
export default function ManagerNotification({ request }: Props) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { errorMessage } = useSelector((state) => state.appState)
    const { data: managerRequests } = useManagerRequests()
    const { mutateAsync: updateManager, isPending: isUpdateManager } = updateManagerMutation()
    const inviterName = useMemo(() => managerRequests?.find((manager) => manager.inviter !== null)?.inviter, [managerRequests])

    const getAlertContent = useCallback(() => {
        if (request.ownershipType === ManagerType.FULL) return translate("manager_notification_alert.full")
        if (request.ownershipType === ManagerType.READ) return translate("manager_notification_alert.read")
        if (request.ownershipType === ManagerType.READ_WRITE) return translate("manager_notification_alert.readWrite")
    }, [request, translate])

    const onDecline = useCallback(async () => {
        await updateManager({ propertyId: request.id, accept: false })
        dispatch(setContext(undefined))
    }, [updateManager, request, dispatch])

    const onAccept = useCallback(async () => {
        await updateManager({ propertyId: request.id, accept: true })
    }, [updateManager, request])

    const info = useCallback(() => {
        const infoText = (
            <>
                {translate("manager_notification.content", { inviter: inviterName, propertyName: request.name })}
                {request.county ? translate("located_in") : "."}
                {request.county ? translate("contracts.county") : ""}
                {request.county ? `${request.county}, ` : ""}
                {request.city ? translate("contracts.city") : ""}
                {request.city ? `${request.city}, ` : ""}
                {request.street ? translate("contracts.street") : ""}
                {request.street ? `${request.street}, ` : ""}
                {request.streetNumber ? translate("streetNumber") : ""}
                {request.streetNumber ? `${request.streetNumber}, ` : ""}
                {request.buildingNumber ? translate("contracts.buildingNumber") : ""}
                {request.buildingNumber ? `${request.buildingNumber}, ` : ""}
                {request.stair ? translate("contracts.stair") : ""}
                {request.stair ? `${request.stair}, ` : ""}
                {request.apartment ? translate("contracts.apartment") : ""}
                {request.apartment ? `${request.apartment}, ` : ""}
                {request.floor ? translate("contracts.floor") : ""}
                {request.floor ? `${request.floor}, ` : ""}
            </>
        )
        const infoTextWithoutEmptyStrings = infoText.props.children.filter((element: string) => element !== "")
        const lastElement = infoTextWithoutEmptyStrings[infoTextWithoutEmptyStrings.length - 1].replace(",", "")
        infoTextWithoutEmptyStrings.splice(-1)
        infoTextWithoutEmptyStrings.push(lastElement)
        return infoTextWithoutEmptyStrings
    }, [request, translate])

    return (
        <NotificationContainer title={"manager_notification.title"}>
            <Typography color={theme.palette.primary.dark} variant='h5'>
                {translate("manager_notification.title")}
            </Typography>
            <Box paddingY={2} display={"flex"} flexDirection={"column"} gap={1}>
                <Typography color={theme.palette.grey.A700} variant='body2' flexWrap={"wrap"} display={"flex"}>
                    {info()}
                </Typography>
                <Alert
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        textAlign: "center",
                        color: theme.palette.grey.A700,
                    }}
                    severity='info'
                >
                    {getAlertContent()}
                </Alert>
            </Box>
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
            <Box display='flex' flexDirection='row-reverse' width='100%' gap={2}>
                <LoadingButton color={"primary"} loading={isUpdateManager} disabled={isUpdateManager} variant='contained' onClick={onAccept}>
                    {translate("accept")}
                </LoadingButton>
                <LoadingButton loading={isUpdateManager} disabled={isUpdateManager} variant='contained' onClick={onDecline} color='error'>
                    {translate("decline")}
                </LoadingButton>
                <Button size='medium' color='primary' disabled={isUpdateManager} onClick={() => dispatch(setContext(undefined))}>
                    {translate("back")}
                </Button>
            </Box>
        </NotificationContainer>
    )
}
