import { Box, Button, Typography } from "@mui/material"
import React, { ReactNode, SyntheticEvent } from "react"
import { LoadingButton } from "@mui/lab"
import { useLocales } from "rentzz"

interface DeleteSheetContent {
    content?: string | ReactNode
    onClose: (e: SyntheticEvent) => void
    onAccept: (e: SyntheticEvent) => Promise<void>
    isDeleteLoading: boolean
    isDeleteDisabled?: boolean
    submitText?: string
    colorSubmitButton?: "inherit" | "error" | "primary" | "secondary" | "success" | "info" | "warning"
}
export default function DeleteSheetContent({
    content,
    onClose,
    onAccept,
    isDeleteLoading,
    submitText,
    isDeleteDisabled,
    colorSubmitButton,
}: DeleteSheetContent) {
    const { translate } = useLocales()

    return (
        <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between' gap={2}>
            <Typography padding={1} textAlign={"center"}>
                {content}
            </Typography>
            <Box
                sx={{
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <LoadingButton
                    onClick={onAccept}
                    variant='contained'
                    color={colorSubmitButton ?? "error"}
                    fullWidth
                    loading={isDeleteLoading}
                    disabled={isDeleteDisabled}
                >
                    {translate(submitText ?? "delete")}
                </LoadingButton>
                <Button onClick={onClose} color={"primary"} fullWidth disabled={isDeleteLoading}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
