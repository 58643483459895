import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../queries"
import { updatePropertyDetailsMutation } from "../../mutations"
import { useForm } from "react-hook-form"
import * as React from "react"
import { useCallback, useRef, useState } from "react"
import { Box, Divider, Paper, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import Api from "../../api/Api"
import { useSelector } from "../../redux/store"
import { useSnackbar } from "notistack"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"

export default function PropertyDescription() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const [isFetchingAIDescription, setIsFetchingAIDescription] = useState(false)
    const { mutateAsync: updateProperty, isPending: isUpdatePropertyPending } = updatePropertyDetailsMutation()
    const aiDescription = useRef<string>("")
    const { enqueueSnackbar } = useSnackbar()
    const { canWrite } = usePermissions(PermissionType.Properties, currentPropertyId)

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            description: propertyDetails?.marketingSettings?.description,
        },
    })

    const onAiFetchingClick = useCallback(() => {
        setIsFetchingAIDescription(true)
        setValue("description", "")
        fetchEventSource(Api.API + `/property/${currentPropertyId}/generate-marketing-description`, {
            credentials: "include",
            onopen: async (_: Response) => {
                aiDescription.current = ""
            },
            onmessage: (ev) => {
                if (ev.data.startsWith("error_")) {
                    enqueueSnackbar(ev.data.replace("error_", ""), { variant: "error" })
                } else {
                    aiDescription.current += ev.data.replace("data: ", "").replace("\n\n", "").replaceAll("\\n", "\n")
                    setValue("description", aiDescription.current)
                }
            },
            onclose: async () => {
                setIsFetchingAIDescription(false)
            },
            onerror: (_) => {
                setIsFetchingAIDescription(false)
            },
        })
    }, [enqueueSnackbar])

    const { handleSubmit, getValues, setValue } = methods

    const onSubmit = useCallback(async () => {
        await updateProperty({
            operations: [
                {
                    op: "replace",
                    path: "/marketingSettings/description",
                    value: getValues("description") ?? propertyDetails?.marketingSettings?.description ?? "",
                },
            ],
        })
    }, [updateProperty])

    return (
        <Paper elevation={3} square={false}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box display={"flex"} justifyContent={"space-between"} paddingY={1} paddingX={2} alignItems={"center"} color={"text.secondary"}>
                    <Typography fontWeight={"bold"}>{translate("property_description")}</Typography>
                    {canWrite && (
                        <Box display={"flex"} gap={2}>
                            <LoadingButton
                                loading={isFetchingAIDescription}
                                disabled={isFetchingAIDescription}
                                color={"info"}
                                onClick={onAiFetchingClick}
                                variant='outlined'
                            >
                                {translate("generate_description_with_ai")}
                            </LoadingButton>
                            <LoadingButton loading={isUpdatePropertyPending} disabled={isUpdatePropertyPending} color={"primary"} type={"submit"}>
                                {translate("save")}
                            </LoadingButton>
                        </Box>
                    )}
                </Box>
                <Divider sx={{ marginX: 1 }} />
                <Box display={"flex"} flexDirection={"column"} gap={2} padding={2}>
                    <RHFTextField
                        disabled={!canWrite}
                        name={"description"}
                        id={"description"}
                        style={{ borderRadius: 10 }}
                        multiline
                        minRows={isMobile ? 10 : 18}
                    />
                </Box>
            </FormProvider>
        </Paper>
    )
}
