import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import AddProviderForm from "../../../sections/propertyDetails/configurations/providers/addProvider/AddProviderForm"

export default function AddMeterProviderGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.MeterDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, isMobile])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("add_meter_provider")}
                isOpen={isMobile && modalOpen === AppModals.AddMeterProvider}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                <AddProviderForm onClose={onClose} />
            </DetailsSheet>
            <CustomDialog
                title={translate("add_meter_provider")}
                open={!isMobile && modalOpen === AppModals.AddMeterProvider}
                onClose={onClose}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddProviderForm onClose={onClose} />
            </CustomDialog>
        </>
    )
}
