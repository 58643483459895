import { Image, Text, View } from "@react-pdf/renderer"
import React from "react"
import { useLocales } from "rentzz"
import useStyles from "./useStyles"
import { ContractSignature } from "../../../queries/userData"

interface FinalStepProps {
    ownerFullName?: string
    ownerSignature?: string
    tenantSignatures?: ContractSignature[]
}
export default function Signatures({ ownerFullName, ownerSignature, tenantSignatures }: FinalStepProps) {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 13,
                marginTop: 20,
            }}
        >
            <View
                style={{
                    display: "flex",
                    fontSize: 13,
                }}
            >
                {ownerFullName && <Text style={{ ...styles.boldFont }}>{translate("owner")}</Text>}
                {ownerFullName && <Text style={styles.boldFont}>{ownerFullName}</Text>}
                {ownerSignature && <Image style={{ height: 75, width: 150 }} src={ownerSignature} />}
            </View>
            <View style={{ display: "flex", flexDirection: "column", rowGap: 8 }}>
                {tenantSignatures?.map((signature) => (
                    <View
                        key={signature.signatureImage}
                        style={{
                            display: "flex",
                            fontSize: 13,
                            paddingRight: 40,
                        }}
                    >
                        <Text style={{ ...styles.boldFont }}>{translate("tenant")}</Text>
                        <Text style={styles.boldFont}>{signature.name ?? "_______________"}</Text>
                        {signature.signatureImage && <Image style={{ height: 75, width: 150 }} src={signature.signatureImage} />}
                    </View>
                ))}
            </View>
        </View>
    )
}
