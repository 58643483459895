import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { AppContext, useIsMobile } from "rentzz"
import { DateTime } from "luxon"
import { sum } from "lodash"
import { useRentingPeriodDetails } from "./tenants"
import { useMemo } from "react"
import { useSelector } from "../redux/store"

export interface JournalBase {
    rentingPeriodId: number
    propertyId: number
    id: number
    title: string
    description: string
    isSystemDiaryEntry: boolean
    parameters: string
    type: DiaryType
}

export enum DiaryType {
    UserAdded = 0,
    AddedExpense = 1,
    ChangedExpense = 2,
    DeletedExpense = 3,
    AddedIncome = 4,
    ChangedIncome = 5,
    DeletedIncome = 6,
    DeliveredMail = 7,
}

export interface JournalDto extends JournalBase {
    createdAt: string
    lastModifiedAt: string
}

export interface Journal extends JournalBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface JournalResponseBase {
    count: number
}

export interface JournalResponseDto extends JournalResponseBase {
    items: JournalDto[]
}
export interface JournalResponse extends JournalResponseBase {
    items: Journal[]
}

export const useJournalsQuery = (page: number, pageSize: number, sortingColumns: GridSortModel, filterModel?: GridFilterModel) => {
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    return useQuery({
        queryKey: [ComplexQueryIDs.Journal, { rentingPeriodId: currentRentingPeriodId, page, pageSize, sortingColumns, filterModel }],
        queryFn: Api.fetchRentingPeriodJournals,
        enabled: context === AppContext.Owner && !!currentRentingPeriodId,
        select: (data) => {
            return {
                ...data,
                items: data.items.map((journal) => {
                    return {
                        ...journal,
                        canWrite: (editablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                        canDelete: (deletablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                    }
                }),
            }
        },
        placeholderData: () => ({
            items: [],
            count: 20,
        }),
    })
}

export const useInfiniteJournalsQueryFn = (sortingColumns?: GridSortModel, filterModel?: GridFilterModel) => {
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.Journal, { rentingPeriodId: currentRentingPeriodId, sortingColumns, filterModel }],
        queryFn: Api.fetchInfiniteJournals,
        enabled: context === AppContext.Owner && !!currentRentingPeriodId,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((journal) => ({
                            ...journal,
                            canWrite: (editablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                            canDelete: (deletablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getJournalByIdQueryFn = (
    journalId?: number,
    sortingColumns?: GridSortModel,
    filterModel?: GridFilterModel,
): (Journal & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    return useMemo(() => {
        if (isMobile) {
            const journals = queryClient.getQueryData([
                ComplexQueryIDs.Journal,
                { rentingPeriodId: currentRentingPeriodId, sortingColumns, filterModel },
            ]) as
                | {
                      pages: JournalResponse[]
                  }
                | undefined
            const arrayOfJournals = journals?.pages.flatMap((r) => r.items) as (Journal & { canWrite: boolean; canDelete: boolean })[]

            const journal = arrayOfJournals?.find((journal) => journal?.id == journalId)

            if (journal) {
                return {
                    ...journal,
                    canWrite: (editablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                    canDelete: (deletablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                }
            } else return journal ?? undefined
        }
        const journalsPages = queryClient.getQueriesData({
            queryKey: [ComplexQueryIDs.Journal, { rentingPeriodId: currentRentingPeriodId, sortingColumns, filterModel }],
        })

        const arrayOfJournals = (journalsPages?.flatMap((journalWithKey) => journalWithKey[1]) as JournalResponse[])?.flatMap(
            (r) => r?.items,
        ) as (Journal & {
            canWrite: boolean
            canDelete: boolean
        })[]
        const journal = arrayOfJournals?.find((journal) => journal?.id == journalId)
        if (journal) {
            return {
                ...journal,
                canWrite: (editablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
                canDelete: (deletablePropertiesIds?.includes(journal.propertyId) ?? false) && !journal.isSystemDiaryEntry,
            }
        } else return journal ?? undefined
    }, [queryClient, isMobile, journalId])
}
