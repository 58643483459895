import AddNoteGuard from "./AddNoteGuard"
import NoteDetailsActionSheet from "../../../sections/propertyDetails/General/details/notes/mobile/NoteDetailsActionSheet"
import DeleteNoteGuard from "./DeleteNoteGuard"
import ChangeTenantVisibility from "./ChangeTenantVisibility"

export default function NoteGuard() {
    return (
        <>
            <AddNoteGuard />
            <NoteDetailsActionSheet />
            <DeleteNoteGuard />
            <ChangeTenantVisibility />
        </>
    )
}
