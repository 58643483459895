import { Box, IconButton, Paper, Typography, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import HeaderLivePreview from "./HeaderLivePreview"
import GalleryLivePreview from "./GalleryLivePreview"
import DetailsLivePreview from "./DetailsLivePreview"
import { Iconify, useLocales } from "rentzz"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { useIntl } from "react-intl"
import { UseFormWatch } from "react-hook-form"
import { AdDataRequest } from "../addNewAd/NewAdWizard"

interface LivePreviewProps {
    watch: UseFormWatch<AdDataRequest>
}

export default function LivePreview({ watch }: LivePreviewProps) {
    const theme = useTheme()
    const intl = useIntl()
    const { translate } = useLocales()
    const { data: currencyData } = useCurrencyQueryFn()
    const [isPhoneVisible, setIsPhoneVisible] = useState<boolean>(false)
    const [adData, setAdData] = useState<AdDataRequest | undefined>(undefined)

    const getRentPrice = useCallback(() => {
        if (adData?.rentPrice == null || adData.rentPrice === 0) return ""
        return intl.formatNumber(Number(adData.rentPrice), {
            style: "currency",
            currency: getCurrencyFromId(currencyData, adData?.currencyId).code,
        })
    }, [adData, currencyData])

    useEffect(() => {
        const subscription = watch((v) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setAdData(v)
        })

        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <Paper sx={{ width: "45%", paddingBottom: 2 }} elevation={10}>
            <Box padding={2}>
                <HeaderLivePreview title={adData?.title} adCity={adData?.city} adCounty={adData?.county} rentPrice={getRentPrice()} />
                <GalleryLivePreview />
            </Box>
            {/*  TODO */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*  @ts-ignore */}
            <DetailsLivePreview adData={adData} />
            {adData?.description && (
                <Box padding={2}>
                    <Typography fontSize={14} fontWeight={"bold"} paddingBottom={1}>
                        {translate("property_description")}
                    </Typography>
                    <Typography color={theme.palette.grey["500"]} textAlign={"justify"}>
                        {adData?.description}
                    </Typography>
                </Box>
            )}
            {adData?.phone && (
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <IconButton
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                            borderRadius: 1,
                            paddingX: 2,
                            fontSize: 18,
                            ":hover": {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                        onClick={() => setIsPhoneVisible(true)}
                    >
                        <Iconify icon={"mdi:phone"} marginRight={1} />
                        {isPhoneVisible ? adData?.phone : translate("see_phone")}
                    </IconButton>
                </Box>
            )}
        </Paper>
    )
}
