import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { Box, Link, Stack, Typography, useTheme } from "@mui/material"
import { MotionContainer, useLocales, varFade } from "rentzz"
import { useIntl } from "react-intl"
import { m } from "framer-motion"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { MeterItemProps } from "../../../tenantContext/rentingPeriodNotification/CustomCarousel"

type CarouselItemProps = {
    item: MeterItemProps
    isActive?: boolean
}

export function CarouselMeterItem({ item, isActive }: CarouselItemProps) {
    const { data: units } = useUnitsQueryFn()
    const { name, currentValue, unitId, reportingPeriod } = item
    const theme = useTheme()
    const { translate } = useLocales()

    const intl = useIntl()
    if (!units) return null

    const unit = getUnitFromId(units, unitId).code

    return (
        <MotionContainer action animate={isActive} sx={{ position: "relative" }}>
            <Stack
                spacing={1}
                sx={{
                    p: 3,
                }}
            >
                <m.div variants={varFade().inRight}>
                    <Link color='inherit' underline='none'>
                        <Typography
                            variant='body1'
                            letterSpacing={0.5}
                            textTransform='uppercase'
                            fontWeight={800}
                            fontSize={16}
                            color={theme.palette.primary.dark}
                        >
                            {name}
                        </Typography>
                    </Link>
                </m.div>

                <m.div variants={varFade().inRight}>
                    <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' flexBasis='50%'>
                            <Typography
                                letterSpacing={0.5}
                                textTransform='uppercase'
                                fontWeight={800}
                                fontSize={13}
                                color={theme.palette.primary.dark}
                            >
                                {translate("last_index")}
                            </Typography>
                            <Typography variant='body2' noWrap>
                                {intl.formatNumber(currentValue, {
                                    style: "decimal",
                                })}{" "}
                                {unit}
                            </Typography>
                        </Box>
                        <Box display='flex' flexDirection='column' flexBasis='50%'>
                            <Typography
                                letterSpacing={0.5}
                                textTransform='uppercase'
                                fontWeight={800}
                                fontSize={13}
                                color={theme.palette.primary.dark}
                            >
                                {translate("reading_period")}
                            </Typography>
                            <Typography align='center' variant={"body2"}>
                                {arrayOfNumbersToPeriod(reportingPeriod).join(", ")}
                            </Typography>
                        </Box>
                    </Box>
                </m.div>
            </Stack>
        </MotionContainer>
    )
}
