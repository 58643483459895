import { List, Typography } from "@mui/material"
import NotificationCard from "./NotificationCard"
import React, { useCallback, useMemo } from "react"
import {
    expensesPageAtom,
    ExpensesPages,
    IncomesFilteredOptions,
    incomesFilterState,
    rentingPeriodPageAtom,
    RentingPeriodsPages,
} from "../../utils/atom"
import { AppModals, setModalOpen } from "../../redux/slices/App"
import { useNavigate } from "react-router-dom"
import { useSetAtom } from "jotai"
import { useDispatch } from "../../redux/store"
import { ICONS } from "../../routes/DashboardLayout"
import { UserFlags, useUserNotificationsQuery } from "../../queries/userData"
import { useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { getIncomesReportSummaryQueryFn } from "../../queries/income"
import { useSoonToBeExpiredRentingPeriodsQueryFn } from "../../queries/tenants"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function NotificationsDrawerContent() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const setExpensesPage = useSetAtom(expensesPageAtom)
    const setFilterModel = useSetAtom(incomesFilterState)
    const { data: userNotifications } = useUserNotificationsQuery()
    const { data: incomesSummaryStatus } = getIncomesReportSummaryQueryFn(true)
    const { data: soonToBeExpiredRentingPeriods } = useSoonToBeExpiredRentingPeriodsQueryFn()
    const setRentingPeriodPage = useSetAtom(rentingPeriodPageAtom)
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())

    const handleGoToPendingExpenses = useCallback(() => {
        setExpensesPage(ExpensesPages.PENDING_EXPENSES)
        navigate("/expenses")
        if (isMobile) {
            dispatch(setModalOpen(undefined))
        }
    }, [navigate, setExpensesPage, isMobile, dispatch])

    const handleGoToPendingReadings = useCallback(() => {
        dispatch(setModalOpen(AppModals.PendingReadings))
    }, [dispatch, setModalOpen])

    const handleGoToPendingIncomes = useCallback(() => {
        navigate("/income")
        setFilterModel({ items: [{ field: "status", operator: "equals", value: "'Waiting'" }] })
    }, [navigate, setFilterModel])

    const handleGoToSoonToBeExpiredContracts = useCallback(() => {
        navigate("/tenants")
        setRentingPeriodPage(RentingPeriodsPages.EndingSoon)
    }, [navigate, setRentingPeriodPage])

    const numberOfNotifications = useMemo(
        () =>
            (areExpenseProvidersEnabled ? (userNotifications?.pendingExpensesToAccept ?? 0) : 0) +
            (userNotifications?.pendingReadingsToSend ?? 0) +
            ((incomesSummaryStatus ?? [])[IncomesFilteredOptions.Pending]?.count ?? 0) +
            (soonToBeExpiredRentingPeriods?.length ?? 0),
        [userNotifications, incomesSummaryStatus, soonToBeExpiredRentingPeriods],
    )

    if (userNotifications == null || incomesSummaryStatus == null) return null

    return (
        <List disablePadding>
            {numberOfNotifications === 0 ? (
                <Typography color={theme.palette.grey.A700} variant={"subtitle2"} fontWeight={"bold"} textAlign={"center"} paddingTop={3}>
                    {translate("no_notifications")}
                </Typography>
            ) : (
                <>
                    {userNotifications.pendingExpensesToAccept > 0 && areExpenseProvidersEnabled && (
                        <NotificationCard
                            notification={{
                                icon: ICONS.expense,
                                title: "expenses.pending-expenses",
                                subtitle: "pending_expenses_message",
                                action: handleGoToPendingExpenses,
                                actionText: "see_expenses",
                                number: userNotifications.pendingExpensesToAccept,
                            }}
                        />
                    )}
                    {userNotifications?.pendingReadingsToSend > 0 && (
                        <NotificationCard
                            notification={{
                                icon: ICONS.meters,
                                title: "pending-readings",
                                subtitle: "pending_readings_message",
                                action: handleGoToPendingReadings,
                                actionText: "see_readings",
                                number: userNotifications.pendingReadingsToSend,
                            }}
                        />
                    )}
                    {incomesSummaryStatus[IncomesFilteredOptions.Pending].count > 0 && (
                        <NotificationCard
                            notification={{
                                icon: ICONS.income,
                                title: "pending_incomes",
                                subtitle: "pending_incomes_message",
                                action: handleGoToPendingIncomes,
                                actionText: "see_income",
                                number: incomesSummaryStatus[IncomesFilteredOptions.Pending].count,
                            }}
                        />
                    )}
                    {soonToBeExpiredRentingPeriods && soonToBeExpiredRentingPeriods.length > 0 && (
                        <NotificationCard
                            notification={{
                                icon: ICONS.contracts,
                                title: "pending_renting_periods",
                                subtitle: "pending_renting_periods_message",
                                action: handleGoToSoonToBeExpiredContracts,
                                actionText: "see_renting_periods",
                                number: soonToBeExpiredRentingPeriods.length,
                            }}
                        />
                    )}
                </>
            )}
        </List>
    )
}
