import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { useCallback } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useSingleIncomeQuery } from "../../../queries/income"
import { useIntl } from "react-intl"
import { deleteIncomeMutation } from "../../../mutations/incomes"

interface DeletePaymentGuardProps {
    onClose: () => void
}
export default function DeletePaymentGuard({ onClose }: DeletePaymentGuardProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const intl = useIntl()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currencyData } = useCurrencyQueryFn()
    const { mutateAsync: deletePayment, isPending: isDeletePaymentLoading } = deleteIncomeMutation()

    const { data: currentPayment } = useSingleIncomeQuery(editingItem?.id)
    const onDeletePayment = useCallback(async () => {
        if (currentPayment == null) return
        await deletePayment({ incomeId: currentPayment.id, propertyId: currentPayment.propertyId })
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentPayment, deletePayment, dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_payment")}
                isOpen={isMobile && modalOpen === AppModals.DeletePayment}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_payment_details", {
                        paymentDate: currentPayment?.date.toLocaleString(),
                        paymentValue:
                            currentPayment == null
                                ? ""
                                : intl.formatNumber(currentPayment.value as number, {
                                      style: "currency",
                                      currency: getCurrencyFromId(currencyData, currentPayment.currencyId).code,
                                  }),
                    })}
                    onClose={onClose}
                    isDeleteLoading={isDeletePaymentLoading}
                    onAccept={onDeletePayment}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeletePayment}
                isDeleteLoading={isDeletePaymentLoading}
                title={translate("delete_payment")}
                content={translate("delete_payment_details", {
                    paymentDate: currentPayment?.date.toLocaleString(),
                    paymentValue:
                        currentPayment == null
                            ? ""
                            : intl.formatNumber(currentPayment.value as number, {
                                  style: "currency",
                                  currency: getCurrencyFromId(currencyData, currentPayment.currencyId).code,
                              }),
                })}
                onClose={onClose}
                onAccept={onDeletePayment}
            />
        </>
    )
}
