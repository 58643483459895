import React, { ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { UserFlags } from "../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface PropertiesGuardProps {
    children: ReactNode
}
export default function WhitelabelGuard({ children }: PropertiesGuardProps) {
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    if (isWhitelabel) return <Navigate to='/' replace />
    return <>{children}</>
}
