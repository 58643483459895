import { useLocales } from "rentzz"
import { Box, Button } from "@mui/material"
import { GridToolbarExport } from "@mui/x-data-grid-pro"
import React, { useMemo } from "react"
import { AccountType, RoleName, UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import RoleLockedButton from "../../../components/RoleLockedButton"

interface Props {
    onAddClick: () => void
    onLearnMore: () => void
    onUploadFile: () => void
    numberOfItems?: number
    canExport: boolean
}
export default function GeneralDocumentsToolbar({ onAddClick, numberOfItems, canExport, onLearnMore, onUploadFile }: Props) {
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)

    const isVisible = useMemo(() => (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER) || !isWhitelabel, [])

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                marginBottom: 2,
                alignItems: "center",
            }}
        >
            {canExport && <GridToolbarExport disabled={numberOfItems == null || numberOfItems === 0} />}

            {isVisible && (
                <RoleLockedButton
                    onClick={onAddClick}
                    buttonText={"add_general_document"}
                    blockedRoles={[RoleName.FREE, RoleName.BRONZE, RoleName.SILVER]}
                />
            )}
            {isVisible && (
                <Button color='primary' variant='contained' onClick={onUploadFile} sx={{ textTransform: "none", alignSelf: "flex-end" }}>
                    {translate("upload_file")}
                </Button>
            )}
            {isVisible && (
                <Button color='primary' variant='contained' onClick={onLearnMore} sx={{ textTransform: "none", alignSelf: "flex-end" }}>
                    {translate("learn_more")}
                </Button>
            )}
        </Box>
    )
}
