import React, { ReactNode } from "react"
import { useSelector } from "../redux/store"
import { AppContext } from "rentzz"
import { Navigate } from "react-router-dom"

interface PropertiesGuardProps {
    children: ReactNode
}
export default function OwnerGuard({ children }: PropertiesGuardProps) {
    const { context } = useSelector((state) => state.appState)

    if (context === AppContext.Tenant) return <Navigate to='/' replace />
    return <>{children}</>
}
