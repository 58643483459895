import { AppContext, Iconify, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import React from "react"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "../../../redux/store"

const NoIncomes = () => {
    const theme = useTheme()
    const { translate } = useLocales()
    const { context } = useSelector((state) => state.appState)

    return (
        <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={1} justifyContent={"center"} alignItems={"center"} paddingBottom={3}>
            <Iconify icon={"mdi:cash-remove"} width={30} height={30} color={theme.palette.primary.main} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ color: "text.secondary" }} fontWeight={"bold"} fontSize={17}>
                    {translate(context === AppContext.Owner ? "no_income" : "no_payments")}
                </Typography>
            </Box>
        </Box>
    )
}

export default NoIncomes
