import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { useCallback, useMemo } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useMetersQueryFn } from "../../../queries/meters"
import { OwnerPropertyMeter } from "../../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../../redux/store"
import { deleteMeterMutation } from "../../../mutations/property/meters/meters"

export default function DeleteMeterGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { editingItem, currentPropertyId, modalOpen } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()
    const { mutateAsync: deleteMeter, isPending: isDeleteMeterLoading } = deleteMeterMutation()
    const currentMeter = useMemo(() => (meters as OwnerPropertyMeter[])?.find((meter) => meter?.id === editingItem?.id), [meters, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.MeterDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentMeter == null || currentPropertyId == null) return
        await deleteMeter({ meterId: currentMeter.id, propertyId: currentPropertyId })
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentMeter, currentPropertyId, deleteMeter, onClose, dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_meter")}
                isOpen={isMobile && modalOpen === AppModals.DeleteMeter}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_meter_details", { meterName: currentMeter?.name })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteMeterLoading}
                    onAccept={onDelete}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteMeter}
                isDeleteLoading={isDeleteMeterLoading}
                title={translate("delete_meter")}
                content={translate("delete_meter_details", { meterName: currentMeter?.name })}
                onClose={onClose}
                onAccept={onDelete}
            />
        </>
    )
}
