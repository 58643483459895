import React, { ReactNode } from "react"
import { Box, useTheme } from "@mui/material"
import { Iconify, LightTooltip, useLocales } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"

interface HelpTypographyProps {
    children: ReactNode
    helpPage: string
    icon?: string
}

export default function HelpComponent({ children, helpPage, icon = "mdi:export-variant" }: HelpTypographyProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    const onNavigate = () => {
        window.open(helpPage)
    }

    if (isWhitelabel) return children

    return (
        <Box display={"flex"} alignItems={"center"} gap={1}>
            {children}
            <LightTooltip title={translate("new_tab_will_open")} arrow>
                <span style={{ display: "flex" }}>
                    <Iconify
                        icon={icon}
                        onClick={onNavigate}
                        width={28}
                        height={28}
                        color={theme.palette.primary.main}
                        sx={{
                            cursor: "pointer",
                            alignContent: "center",
                        }}
                    />
                </span>
            </LightTooltip>
        </Box>
    )
}
