import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { useTheme } from "@mui/material/styles"
import { UserFlags, useUserPropertiesQuery } from "../../../queries/userData"
import { useDashboardTableQuery } from "../../../queries"
import React, { useCallback, useMemo } from "react"
import { arrayOfNumbersToPeriod } from "../../../utils/dateMagic"
import { AppContext, DetailsSheet, useLocales } from "rentzz"
import { getUnitFromId, useUnitsQueryFn } from "../../../queries/units"
import { useIntl } from "react-intl"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function MeterStatusDetailsSheet() {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: units, isLoading: areUnitsLoading } = useUnitsQueryFn()
    const { data: properties } = useUserPropertiesQuery()
    const { modalOpen, currentPropertyId, editingItem, context } = useSelector((status) => status.appState)
    const { data: tableData } = useDashboardTableQuery(editingItem?.tableId, currentPropertyId)
    const isNewReportingPeriod = useFeatureIsOn(UserFlags.IsNewReportingPeriod.toString())

    const currentMeter = useMemo(() => tableData?.values.find((meter) => meter.id === editingItem?.id), [tableData, editingItem])

    const getValue = useCallback(
        (value: number, unitId: number) => {
            if (value === -1) return "-"
            if (unitId == null) return
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(units, unitId).code}`
        },
        [units, intl],
    )

    const meterStatusOptions = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_history",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.MeterValuesHistory))
                },
            },
        ]
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isOpen={modalOpen === AppModals.StatusMeterDetails}
                onClose={() => {
                    dispatch(setEditingItem(undefined))
                    dispatch(setModalOpen(undefined))
                }}
                isLoading={currentMeter == null || areUnitsLoading}
                title={currentMeter?.name}
                options={meterStatusOptions}
                headerColor={currentMeter?.readThisMonth ? theme.palette.success.main : theme.palette.warning.main}
            >
                <Box p={1}>
                    {" "}
                    {renderActionSheetInfo(
                        "mdi:meter-electric",
                        `${translate("previous_index")}: ${currentMeter && getValue(currentMeter?.previousValue, currentMeter.unitId)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        "mdi:meter-electric",
                        `${translate("last_index_sent")}: ${currentMeter && getValue(currentMeter?.currentValue, currentMeter.unitId)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        "mdi:meter-electric",
                        `${translate("average_index")}: ${
                            currentMeter?.averageReadingDifference === -9999
                                ? translate("not_enough_data")
                                : currentMeter && getValue(currentMeter?.averageReadingDifference, currentMeter.unitId)
                        }`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        `${currentMeter?.readThisMonth ? "mdi:check" : "mdi:timer-sand"}`,
                        `${currentMeter?.readThisMonth ? translate("reading_sent") : translate("reading_not_sent")}`,
                        currentMeter?.readThisMonth ? theme.palette.success.main : theme.palette.warning.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("reading_period")}: ${currentMeter && (isNewReportingPeriod ? `${currentMeter.reportingPeriodStartDay}-${currentMeter.reportingPeriodEndDay}` : arrayOfNumbersToPeriod(currentMeter?.readingPeriod)?.join(", "))}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                        true,
                        "",
                        "info",
                    )}
                    {context === AppContext.Owner &&
                        renderActionSheetInfo(
                            "mdi:map-marker",
                            properties?.find((p) => p.id === currentMeter?.propertyId)?.label,
                            theme.palette.primary.main,
                            theme.palette.grey["600"],
                        )}
                </Box>
            </DetailsSheet>
        </>
    )
}
