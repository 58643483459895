import { Iconify, LightTooltip, LoadingIcon, MapMarker, useIsMobile, useLocales } from "rentzz"
import React, { useEffect, useState } from "react"
import Map from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { Box, IconButton, Paper } from "@mui/material"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { useTheme } from "@mui/material/styles"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useDispatch } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"

const MapView = () => {
    const { data } = usePropertyDetailsQueryFn()
    const [marker, setMarker] = useState<{ lat: number; lng: number } | undefined>(undefined)
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { isPropertyEditable } = usePermissions(PermissionType.Properties)

    useEffect(() => {
        if (data?.lat && data?.lng) {
            setMarker({ lat: data.lat, lng: data.lng })
        }
    }, [data])

    if (!data) return <LoadingIcon />
    return (
        <Paper elevation={6} sx={{ marginBottom: isMobile ? 2 : undefined }}>
            <Box position={"relative"} display={"table-column-flex"}>
                <Box position={"absolute"} top={5} left={isMobile ? "90%" : "94%"} zIndex={1}>
                    {isPropertyEditable && (
                        <LightTooltip title={translate("edit")}>
                            <IconButton
                                sx={{
                                    borderWidth: 1,
                                    borderColor: "white",
                                    borderStyle: "solid",
                                    backgroundColor: "white",
                                    ":hover": { backgroundColor: "white" },
                                    padding: 0.2,
                                }}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.EditPropertyLocation))
                                    dispatch(setEditingItem({ lat: marker?.lat, lng: marker?.lng }))
                                }}
                            >
                                <Iconify
                                    icon={"mdi:pencil"}
                                    sx={{ height: DESKTOP_ICON_SIZE, width: DESKTOP_ICON_SIZE }}
                                    color={theme.palette.primary.main}
                                />
                            </IconButton>
                        </LightTooltip>
                    )}
                </Box>
                <Map
                    interactive={false}
                    style={{ height: 350, width: "100%", zIndex: 0 }}
                    mapStyle='mapbox://styles/mapbox/streets-v9'
                    mapboxAccessToken='pk.eyJ1IjoiZGFubW90IiwiYSI6ImNsN3dpZ2lvZjBkN2kzdnViajNwNjc1bjgifQ.wyNVjOvfMBMp3WP1ZJ9phw'
                    viewState={{
                        latitude: data.lat,
                        longitude: data.lng,
                        zoom: 16,
                        bearing: 1,
                        pitch: 1,
                        height: 350,
                        width: 100,
                        padding: { top: 0, left: 0, bottom: 0, right: 0 },
                    }}
                >
                    {marker && <MapMarker longitude={marker.lng} latitude={marker.lat} />}
                </Map>
            </Box>
        </Paper>
    )
}

export default MapView
