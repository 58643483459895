import { setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch } from "../../../redux/store"
import SendMessageGuard from "./SendMessageGuard"

export default function ContactGuard() {
    const dispatch = useDispatch()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return <SendMessageGuard onClose={closeModal} />
}
