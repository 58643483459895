import { Box, CircularProgress, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import React, { useCallback, useRef, useState } from "react"
import { useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import DeleteRentingPeriodReviewStep from "./delete-renting-period/DeleteRentingPeriodReviewStep"
import { useRentingPeriodSummaryData, useSingleRentingPeriodQuery } from "../../../queries/tenants"
import DeleteRentingPeriodInitialStep from "./delete-renting-period/DeleteRentingPeriodInitialStep"
import DeleteRentingPeriodConfirmationStep from "./delete-renting-period/DeleteRentingPeriodConfirmationStep"

enum DeleteRentingPeriodSteps {
    Initial,
    Review,
    Confirmation,
}

interface Props {
    onAccept: (sendCopyToOwner: boolean) => void
    onClose: () => void
    isLoading: boolean
}
export default function DeleteRentingPeriodContent({ onClose, onAccept, isLoading }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { editingItem } = useSelector((state) => state.appState)
    const sendDataToOwner = useRef(false)
    const [activeStep, setActiveStep] = useState<DeleteRentingPeriodSteps>(DeleteRentingPeriodSteps.Initial)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(editingItem?.rentingPeriodId)
    const { data: currentRentingPeriodSummaryData, isLoading: isRentingPeriodSummaryLoading } = useRentingPeriodSummaryData(editingItem?.id)

    const getSteps = useCallback(() => {
        return [
            {
                title: translate("initial"),
            },
            {
                title: translate("review"),
            },
            {
                title: translate("delete"),
            },
        ]
    }, [translate])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case DeleteRentingPeriodSteps.Initial:
                return (
                    <DeleteRentingPeriodInitialStep
                        onClose={onClose}
                        onNext={() => setActiveStep(DeleteRentingPeriodSteps.Review)}
                        noSteps={false}
                        isLoading={isLoading}
                    />
                )
            case DeleteRentingPeriodSteps.Review:
                return (
                    <DeleteRentingPeriodReviewStep
                        onBack={() => setActiveStep(DeleteRentingPeriodSteps.Initial)}
                        onNext={(sendCopy) => {
                            sendDataToOwner.current = sendCopy
                            setActiveStep(DeleteRentingPeriodSteps.Confirmation)
                        }}
                    />
                )
            case DeleteRentingPeriodSteps.Confirmation:
                return (
                    <DeleteRentingPeriodConfirmationStep
                        isLoading={isLoading}
                        onBack={() => setActiveStep(DeleteRentingPeriodSteps.Review)}
                        onAccept={() => onAccept(sendDataToOwner.current)}
                    />
                )
        }
    }, [activeStep, currentRentingPeriod, onAccept, isLoading])

    return (
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            {(isRentingPeriodSummaryLoading || isLoading) && (
                <Box height={150} width={"100%"} display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                </Box>
            )}
            {!isRentingPeriodSummaryLoading && !isLoading && (
                <>
                    {currentRentingPeriodSummaryData?.numberOfExpenses === 0 &&
                    currentRentingPeriodSummaryData.numberOfIncome === 0 &&
                    currentRentingPeriodSummaryData ? (
                        <DeleteRentingPeriodInitialStep onClose={onClose} onNext={() => onAccept(false)} noSteps={true} isLoading={isLoading} />
                    ) : (
                        <>
                            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                                {getSteps().map((step, index) => {
                                    return (
                                        <Step key={step.title} completed={index < activeStep}>
                                            <StepLabel>{step.title}</StepLabel>
                                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                                        </Step>
                                    )
                                })}
                            </Stepper>
                            {!isMobile && renderActiveStep()}
                        </>
                    )}
                </>
            )}
        </Box>
    )
}
