import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { CustomDataGrid, Label, useLocales } from "rentzz"
import { usePendingMeterValuesQueryFn } from "../../../../../queries/meters"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { PropertyMeterValue } from "../../../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../../../utils/helpers"
import { getUnitFromId, useUnitsQueryFn } from "../../../../../queries/units"
import { useIntl } from "react-intl"
import SendIcon from "@mui/icons-material/Send"
import { UserFlags, useUserPropertiesQuery } from "../../../../../queries/userData"
import { sendMeterValueMutation } from "../../../../../mutations/property/meters/meter-values"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import { getPendingReadingLabelColor, getPendingReadingLabelText } from "../../utils"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const DesktopPendingReadingsList = () => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "createdAt", sort: "desc" }])
    const { translate } = useLocales()
    const { data: readingsList, isFetching } = usePendingMeterValuesQueryFn(paginationModel.page, paginationModel.pageSize, sortModel)
    const { mutate: sendMeterValue, isPending } = sendMeterValueMutation()
    const { editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const { data: unitData } = useUnitsQueryFn()
    const intl = useIntl()
    const { data: userProperties } = useUserPropertiesQuery()
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())

    const getValue = useCallback(
        (value: number, unit: number) => {
            if (value === -1) return "-"
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, unit).code}`
        },
        [unitData, intl],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<PropertyMeterValue>[] = [
            {
                field: "propertyId",
                type: "number",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === Number(value))?.label
                },
            },
            {
                field: "meterName",
                type: "number",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("meter_name"),
            },
            {
                field: "createdAt",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "string",
                valueFormatter: (value) => (value != null ? (value as DateTime).toLocaleString(DateTime.DATE_SHORT) : ""),
                headerName: translate("index_sent_date"),
            },
            {
                field: "createdBy",
                width: 140,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "string",
                sortable: false,
                headerName: translate("index_sent_by"),
            },
            {
                field: "previousValue",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "number",
                headerName: translate("previous_index"),
                valueFormatter: (value, row) => {
                    return getValue(value, row.unitId)
                },
            },
            {
                field: "currentValue",
                width: 90,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "number",
                headerName: translate("new_index"),
                valueFormatter: (value, row) => {
                    return getValue(value, row.unitId)
                },
            },
            {
                field: "status",
                type: "number",
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    return (
                        <Label color={getPendingReadingLabelColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(getPendingReadingLabelText(v.value))}
                        </Label>
                    )
                },
            },
            {
                field: "sendAutomaticallyAt",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: 0.5,
                type: "string",
                valueFormatter: (value) => {
                    if (value != null) return (value as DateTime).toLocaleString(DateTime.DATE_SHORT)
                    else return ""
                },
                headerName: translate("automaticallySendDate_reading"),
            },
        ]

        if (!areMetersProvidersEnabled) {
            return columns.filter((c) => c.field !== "sendAutomaticallyAt")
        }
        return columns
    }, [translate, getValue, userProperties])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 150,
            type: "actions",
            headerName: translate("actions"),
            getActions: (params: GridRowParams<PropertyMeterValue>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            icon={<StopCircleIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />}
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.StopAutomaticReadingSending))
                                dispatch(setEditingItem({ readingId: params.row.id, meterId: params.row.meterId }))
                            }}
                            label={translate("automaticallySendDate")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: params.row.sendAutomaticallyAt == null || !areMetersProvidersEnabled,
                    tooltipText: translate("grid_actions.auto_send_disable"),
                    key: "automaticallySendDate",
                }),

                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"primary"}
                            disabled={isPending}
                            icon={<CheckCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                sendMeterValue({ readingId: Number(params.id), autoSend: false })
                            }}
                            label={`${translate("grid_actions.manual_mark_sent")}`}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("index_was_send"),
                    key: "manual_send",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<SendIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            disabled={!params.row.canAutoSend}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                sendMeterValue({ readingId: Number(params.id), autoSend: true })
                            }}
                            label={translate("grid_actions.send_reading")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("grid_actions.send_reading"),
                    key: "auto_send",
                }),
            ],
        }),
        [translate, dispatch, editingItem],
    )

    return (
        <CustomDataGrid
            sortModel={sortModel}
            sortingMode='server'
            onSortModelChange={setSortModel}
            paginationMode={readingsList?.count === 20 && readingsList.items.length === 0 ? "client" : "server"}
            rowsLoadingMode={readingsList?.count === 20 && readingsList?.items.length === 0 ? "server" : "client"}
            hidePagination={readingsList?.count === 20 && readingsList.items.length === 0}
            columns={columns}
            rows={readingsList?.items ?? []}
            gridHeight={610}
            isLoading={isFetching && !(readingsList?.count === 20 && readingsList?.items.length === 0)}
            toolbarProps={{
                lastValue: readingsList ? readingsList.items[0]?.currentValue : null,
            }}
            idKey='id'
            totalCount={readingsList?.count}
            actionsColumn={[actionsColumn]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
        />
    )
}

export default DesktopPendingReadingsList
