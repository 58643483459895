import { SummaryCardType } from "../../queries/userData"
import { LoadingIcon } from "rentzz"
import SummaryCard from "../../components/SummaryCard"
import React from "react"
import { Box, Grid } from "@mui/material"

interface Props {
    summaryCards: SummaryCardType[]
}
const SummaryCardsRow = ({ summaryCards }: Props) => {
    if (!summaryCards) return <LoadingIcon />

    return (
        <>
            {summaryCards?.map((card) => (
                <Grid key={card.summaryCardId} item xs={12} sm={6} md={summaryCards?.length <= 4 ? 12 / summaryCards?.length : 3}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"stretch"} width={"100%"} height={"100%"} flexWrap={"wrap"}>
                        <SummaryCard cardId={card.summaryCardId} />
                    </Box>
                </Grid>
            ))}
            {(summaryCards?.length ?? 0) > 4 ? <Grid md={12 - (((summaryCards?.length ?? 0) * 3) % 12)} /> : <></>}
        </>
    )
}

export default SummaryCardsRow
