import { useDispatch, useSelector } from "../../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import { getReadingByIdQueryFn } from "../../../../../queries/meters"
import { UserFlags, useUserPropertiesQuery } from "../../../../../queries/userData"
import React, { useCallback, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { AppModals, setModalOpen } from "../../../../../redux/slices/App"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { getUnitFromId, useUnitsQueryFn } from "../../../../../queries/units"
import { useIntl } from "react-intl"
import { getPendingReadingLabelColor, getPendingReadingLabelText } from "../../utils"
import { MOBILE_ICON_SIZE } from "../../../../../utils/helpers"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import SendIcon from "@mui/icons-material/Send"
import { sendMeterValueMutation } from "../../../../../mutations/property/meters/meter-values"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function PendingReadingDetailsSheet() {
    const intl = useIntl()
    const { data: unitData } = useUnitsQueryFn()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const currentReading = getReadingByIdQueryFn(editingItem?.readingId)
    const { mutateAsync: sendMeterValue, isPending } = sendMeterValueMutation()
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())

    const options = useMemo((): CustomSheetOption[] => {
        if (currentReading == null) return []

        return [
            {
                icon: <StopCircleIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.auto_send_disable",
                hidden: currentReading.sendAutomaticallyAt == null || !areMetersProvidersEnabled,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.StopAutomaticReadingSending))
                },
            },
            {
                icon: <CheckCircleIcon color={isPending ? "inherit" : "primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "index_was_send",
                onClick: async () => {
                    await sendMeterValue({ readingId: Number(currentReading.id), autoSend: false })
                    onClose()
                },
                isDisabled: isPending,
            },
            {
                icon: <SendIcon color={currentReading.canAutoSend ? "primary" : "inherit"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.send_reading",
                onClick: async () => {
                    await sendMeterValue({ readingId: Number(currentReading.id), autoSend: true })
                    onClose()
                },
                isDisabled: !currentReading.canAutoSend,
            },
        ]
    }, [currentReading, dispatch, theme, sendMeterValue, isPending])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(AppModals.PendingReadings))
    }, [dispatch])

    const getValue = useCallback(
        (value: number, unit: number) => {
            if (value === -1) return "-"
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, unit).code}`
        },
        [unitData, intl],
    )

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PendingReadingDetails}
            onClose={onClose}
            isLoading={false}
            title={currentReading?.meterName}
            options={options}
            headerColor={theme.palette.warning.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:meter-electric",
                    `${translate("previous_index")}: ${currentReading && getValue(currentReading.previousValue, currentReading.unitId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:meter-electric",
                    `${translate("new_index")}: ${currentReading && getValue(currentReading.currentValue, currentReading.unitId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-outline",
                    `${translate("index_sent_date")} ${currentReading?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {renderActionSheetInfo(
                    "mdi:user",
                    `${translate("createdBy")} ${currentReading?.createdBy}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    properties?.find((p) => p.id === currentReading?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:timer-sand",
                    translate(getPendingReadingLabelText(currentReading?.status)),
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    getPendingReadingLabelColor(currentReading?.status),
                )}
            </Box>
        </DetailsSheet>
    )
}
