import {
    IncomeStatus,
    RentingPeriod,
    RentingPeriodMailNotificationStatus,
    RentingPeriodStatusCode,
    RentingPeriodUserAcceptanceStatus,
} from "../../../redux/slices/AddProperty"
import { Theme } from "@mui/material"
import { alpha } from "@mui/material/styles"

export const getLabelColor = (value: RentingPeriodStatusCode) => {
    switch (value) {
        case RentingPeriodStatusCode.Created:
        case RentingPeriodStatusCode.NotificationSent:
        case RentingPeriodStatusCode.ExtendNotificationSent:
            return "info"
        case RentingPeriodStatusCode.PendingTenantSignature:
        case RentingPeriodStatusCode.PartiallyAccepted:
            return "warning"
        case RentingPeriodStatusCode.Declined:
            return "error"
        case RentingPeriodStatusCode.Accepted:
            return "success"
    }
}

export const getUserAcceptanceStatusLabelColor = (value: RentingPeriodUserAcceptanceStatus) => {
    switch (value) {
        case RentingPeriodUserAcceptanceStatus.None:
            return "default"
        case RentingPeriodUserAcceptanceStatus.Accepted:
        case RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved:
            return "success"
        case RentingPeriodUserAcceptanceStatus.Declined:
            return "error"
        default:
            return "default"
    }
}

export const getMailNotificationStatusLabelColor = (value: RentingPeriodMailNotificationStatus) => {
    switch (value) {
        case RentingPeriodMailNotificationStatus.None:
        case RentingPeriodMailNotificationStatus.Notified:
            return "info"
        case RentingPeriodMailNotificationStatus.NotificationFailed:
            return "warning"
    }
}

export const getIncomeTitleColorFromStatus = (value: IncomeStatus, theme: Theme) => {
    switch (value) {
        case IncomeStatus.Waiting:
            return theme.palette.warning.main
        case IncomeStatus.Accepted:
            return theme.palette.success.main
        case IncomeStatus.Denied:
            return theme.palette.error.main
    }
}

export const getTooltipTitleWhenDeleteRentingPeriod = (tenant: RentingPeriod) => {
    if ([RentingPeriodStatusCode.Accepted, RentingPeriodStatusCode.PartiallyAccepted].includes(tenant.rentingPeriodStatus))
        return "delete_accepted_period"
    if ([RentingPeriodStatusCode.Finished].includes(tenant.rentingPeriodStatus)) return "renting_period_with_tenant"
    return undefined
}

export const getUserAcceptanceIcon = (value: RentingPeriodUserAcceptanceStatus) => {
    switch (value) {
        case RentingPeriodUserAcceptanceStatus.None:
            return "mdi:account-clock"
        case RentingPeriodUserAcceptanceStatus.Accepted:
        case RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved:
            return "mdi:account-check"
        case RentingPeriodUserAcceptanceStatus.Declined:
            return "mdi:account-remove"
    }
}

export const getMailNotificationIcon = (value: RentingPeriodMailNotificationStatus) => {
    switch (value) {
        case RentingPeriodMailNotificationStatus.None:
        case RentingPeriodMailNotificationStatus.Notified:
            return "mdi:account-clock"
        case RentingPeriodMailNotificationStatus.NotificationFailed:
            return "mdi:account-cancel"
    }
}

export const getUserAcceptanceAccountBackgroundColor = (value: RentingPeriodUserAcceptanceStatus, theme: Theme) => {
    switch (value) {
        case RentingPeriodUserAcceptanceStatus.None:
            return alpha(theme.palette.info.main, 0.1)
        case RentingPeriodUserAcceptanceStatus.Accepted:
        case RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved:
            return alpha(theme.palette.success.main, 0.1)
        case RentingPeriodUserAcceptanceStatus.Declined:
            return alpha(theme.palette.error.main, 0.1)
    }
}

export const getMailNotificationBackgroundColor = (value: RentingPeriodMailNotificationStatus, theme: Theme) => {
    switch (value) {
        case RentingPeriodMailNotificationStatus.None:
        case RentingPeriodMailNotificationStatus.Notified:
            return alpha(theme.palette.info.main, 0.1)
        case RentingPeriodMailNotificationStatus.NotificationFailed:
            return alpha(theme.palette.error.main, 0.1)
    }
}

export const getUserAcceptanceAccountColor = (value: RentingPeriodUserAcceptanceStatus, theme: Theme) => {
    switch (value) {
        case RentingPeriodUserAcceptanceStatus.None:
            return theme.palette.info.main
        case RentingPeriodUserAcceptanceStatus.Accepted:
        case RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved:
            return theme.palette.success.main
        case RentingPeriodUserAcceptanceStatus.Declined:
            return theme.palette.error.main
    }
}

export const getMailNotificationColor = (value: RentingPeriodMailNotificationStatus, theme: Theme) => {
    switch (value) {
        case RentingPeriodMailNotificationStatus.None:
        case RentingPeriodMailNotificationStatus.Notified:
            return theme.palette.info.main
        case RentingPeriodMailNotificationStatus.NotificationFailed:
            return theme.palette.warning.main
        default:
            return theme.palette.info.main
    }
}

export const getUserIcon = (value: RentingPeriodStatusCode) => {
    switch (value) {
        case RentingPeriodStatusCode.Accepted:
            return "mdi:account-check"
        case RentingPeriodStatusCode.Finished:
            return "mdi:account-cancel"
        case RentingPeriodStatusCode.Declined:
            return "mdi:account-remove"
        default:
            return "mdi:account-clock"
    }
}

export const getAccountBackgroundColor = (value: RentingPeriodStatusCode, theme: Theme) => {
    switch (value) {
        case RentingPeriodStatusCode.Created:
            return alpha(theme.palette.info.main, 0.1)
        case RentingPeriodStatusCode.NotificationSent:
        case RentingPeriodStatusCode.PartiallyAccepted:
        case RentingPeriodStatusCode.PendingTenantSignature:
        case RentingPeriodStatusCode.ExtendNotificationSent:
            return alpha(theme.palette.warning.main, 0.1)
        case RentingPeriodStatusCode.Declined:
            return alpha(theme.palette.error.main, 0.1)
        case RentingPeriodStatusCode.Accepted:
            return alpha(theme.palette.success.main, 0.1)
        case RentingPeriodStatusCode.Finished:
            return theme.palette.grey["200"]
    }
}

export const getAccountColor = (value: RentingPeriodStatusCode, theme: Theme) => {
    switch (value) {
        case RentingPeriodStatusCode.Created:
            return theme.palette.info.main
        case RentingPeriodStatusCode.NotificationSent:
        case RentingPeriodStatusCode.PartiallyAccepted:
        case RentingPeriodStatusCode.PendingTenantSignature:
        case RentingPeriodStatusCode.ExtendNotificationSent:
            return theme.palette.warning.main
        case RentingPeriodStatusCode.Declined:
            return theme.palette.error.main
        case RentingPeriodStatusCode.Accepted:
            return theme.palette.primary.main
        case RentingPeriodStatusCode.Finished:
            return theme.palette.common.black
    }
}
