import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "../../../redux/store"
import ChoosePaymentType from "../../../sections/expenses/online-payment/ChoosePaymentType"

export default function TenantPaymentGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentExpense, isPending } = useSingleExpenseQuery(editingItem?.id)

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.TenantPayment}
                onClose={onClose}
                isLoading={currentExpense == null || isPending}
                title={translate("want_do_you_want")}
                headerColor={editingItem?.backgroundColor}
            >
                <ChoosePaymentType onClose={onClose} />
            </DetailsSheet>

            <CustomDialog
                title={translate("want_do_you_want")}
                open={!isMobile && modalOpen === AppModals.TenantPayment}
                onClose={onClose}
                maxWidth={"sm"}
                showCloseIcon
                titleAlign={"center"}
                isFullScreen={false}
            >
                <ChoosePaymentType onClose={onClose} />
            </CustomDialog>
        </>
    )
}
