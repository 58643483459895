import { FormProvider, RHFDatePicker, RHFDateRangePicker, RHFUnitInput, useLocales } from "rentzz"
import { useRentingPeriodDetails } from "../../../../queries/tenants"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useForm } from "react-hook-form"
import React, { useCallback, useEffect } from "react"
import { useCurrencyQueryFn, useExchangeRateQuery } from "../../../../queries/currency"
import { DateTime } from "luxon"
import { Box, CircularProgress } from "@mui/material"
import { createRentingPeriodInvoice } from "../../../../mutations/tenants"
import { LoadingButton } from "@mui/lab"

const InvoicingForm = () => {
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(modalOpen === AppModals.CreateInvoice ? editingItem?.id : undefined)
    const { translate } = useLocales()
    const methods = useForm({
        defaultValues: {
            rentingPeriodCurrency: rentingPeriodDetails?.currencyId,
            rentingPeriodValue: rentingPeriodDetails?.value,
            invoiceCurrency: rentingPeriodDetails?.invoicingCurrencyId,
            invoiceValue: 0,
            from: DateTime.now().startOf("month").toISO(),
            to: DateTime.now().endOf("month").toISO(),
            invoiceDate: DateTime.now().startOf("day").toISO(),
        },
    })
    const { data: allCurrencies } = useCurrencyQueryFn()
    const dispatch = useDispatch()
    const { data: currencyExchange } = useExchangeRateQuery(
        rentingPeriodDetails?.currencyId,
        rentingPeriodDetails?.invoicingCurrencyId,
        DateTime.now(),
    )
    const { handleSubmit, setValue } = methods
    const { mutateAsync, isPending: isCreatingInvoice } = createRentingPeriodInvoice()

    useEffect(() => {
        if (currencyExchange && rentingPeriodDetails) setValue("invoiceValue", rentingPeriodDetails?.value * currencyExchange)
    }, [currencyExchange, rentingPeriodDetails])

    const onSubmit = useCallback(
        async ({ from, to, invoiceDate }: { from: string; to: string; invoiceDate: string }) => {
            if (rentingPeriodDetails == null) return
            const response = await mutateAsync({
                from: DateTime.fromISO(from).toISODate()!,
                to: DateTime.fromISO(to).toISODate()!,
                invoiceDate: DateTime.fromISO(invoiceDate).toISODate()!,
                rentingPeriodId: rentingPeriodDetails?.id,
            })

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response]))
                dispatch(
                    setEditingItem({
                        url: url,
                    }),
                )
                dispatch(setModalOpen(AppModals.PdfViewer))
            }
        },
        [mutateAsync, rentingPeriodDetails],
    )

    if (currencyExchange == null)
        return (
            <Box display={"flex"} justifyContent={"center"}>
                <CircularProgress size={"2rem"} />
            </Box>
        )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} py={2}>
                <RHFUnitInput
                    fullWidth
                    unitName={"rentingPeriodCurrency"}
                    name='rentingPeriodValue'
                    unitType={"currency"}
                    label={translate("renting_period_invoicing_value")}
                    data={allCurrencies ?? []}
                    disabled
                    size='small'
                />
                <RHFUnitInput
                    fullWidth
                    unitName={"invoiceCurrency"}
                    name='invoiceValue'
                    unitType={"currency"}
                    label={translate("invoicing_currency_invoicing_value")}
                    data={allCurrencies ?? []}
                    disabled
                    size='small'
                />
                <RHFDatePicker name='invoiceDate' label={translate("invoice_date")} />
                <RHFDateRangePicker fromName={"from"} toName={"to"} />
                <LoadingButton color='primary' loading={isCreatingInvoice} variant='contained' type='submit'>
                    {translate("create_invoice")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}

export default InvoicingForm
