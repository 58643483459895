import AddOrEditJournalGuard from "./AddOrEditJournalGuard"
import DeleteJournalGuard from "./DeleteJournalGuard"
import JournalDetailsSheet from "./JournalDetailsSheet"
import JournalItemDetails from "./JournalItemDetails"

export default function JournalGuard() {
    return (
        <>
            <AddOrEditJournalGuard />
            <DeleteJournalGuard />
            <JournalDetailsSheet />
            <JournalItemDetails />
        </>
    )
}
