import { Box, Typography, useTheme } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import React from "react"
import { IncomeStatus, PropertyIncome } from "../../../redux/slices/AddProperty"
import { useUserPropertiesQuery } from "../../../queries/userData"
import CustomCard from "../../../components/CustomCard"
import { DateTime } from "luxon"
import { getIncomeOrPaymentBackgroundColor, getIncomeOrPaymentStatusIcon } from "../../../utils/helpers"

interface IncomeCustomCardProps {
    income: PropertyIncome
    getAmount: (value: number, currencyId?: number) => string
    onClick: () => void
    getIncomeStatusColor: (status: IncomeStatus | undefined) => string | undefined
}

export default function IncomeCustomCard({ income, getAmount, onClick, getIncomeStatusColor }: IncomeCustomCardProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()

    return (
        <CustomCard key={income.id} onClick={onClick} isCardClickable={false} px={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={getIncomeOrPaymentStatusIcon(income.status)}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: getIncomeOrPaymentBackgroundColor(income.status, theme),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            backgroundColor: getIncomeOrPaymentBackgroundColor(income.status, theme),
                            color: getIncomeStatusColor(income.status),
                            minWidth: 35,
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {translate(`income-table.incomeType-${income.type}`)}
                        </Typography>
                        <Typography fontSize={12}>{userProperties?.find((p) => p.id === income.propertyId)?.label}</Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                        {getAmount(income.value, income.currencyId)}
                    </Typography>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {income.date.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
