import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"
import { InvitedManagerStatus, ManagerAcceptanceStatus } from "../../../../redux/slices/AddProperty"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { getInvitedManagerColor, getManagerAcceptanceAccountColor } from "../../../../sections/propertyDetails/General/utils"
import { resendManagerInvitationMutation } from "../../../../mutations"

export default function ResendManagerInvitationGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentPropertyId } = useSelector((state) => state.appState)
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { mutateAsync: resendInvitation, isPending: isResendInvitationLoading } = resendManagerInvitationMutation()

    const currentOwner = useMemo(
        () => propertyDetails?.finalManagers.find((owner) => owner.mail === editingItem?.mail),
        [propertyDetails, editingItem],
    )

    const onResendInvitation = useCallback(async () => {
        if (currentPropertyId == null || editingItem == null) return
        await resendInvitation({ propertyId: currentPropertyId, email: editingItem?.mail })
        dispatch(setModalOpen(undefined))
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ManagerDetails))
        }
    }, [currentPropertyId, editingItem, resendInvitation])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("resend")}
                onClose={() => dispatch(setModalOpen(undefined))}
                open={!isMobile && modalOpen === AppModals.ResendManagerInvitation}
                maxWidth='xs'
                onAccept={onResendInvitation}
                acceptText={translate("send")}
                closeText={translate("cancel")}
                closeButtonColor={"primary"}
                variantCloseButton={"text"}
                acceptButtonColor={"primary"}
                isDisabled={isResendInvitationLoading}
                isLoading={isResendInvitationLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>{translate("resend_invitation_content", { email: editingItem?.mail })}</Typography>
                </Box>
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("resend")}
                isOpen={isMobile && modalOpen === AppModals.ResendManagerInvitation}
                onClose={() => dispatch(setModalOpen(AppModals.ManagerDetails))}
                headerColor={
                    currentOwner?.name === "-"
                        ? getInvitedManagerColor(currentOwner?.status as InvitedManagerStatus, theme)
                        : getManagerAcceptanceAccountColor(currentOwner?.status as ManagerAcceptanceStatus, theme)
                }
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
                    <Typography padding={1} textAlign={"center"}>
                        {translate("resend_invitation_content", { email: currentOwner?.mail })}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onResendInvitation}
                            variant='contained'
                            color={"primary"}
                            fullWidth
                            loading={isResendInvitationLoading}
                            disabled={isResendInvitationLoading}
                        >
                            {translate("send")}
                        </LoadingButton>
                        <Button
                            disabled={isResendInvitationLoading}
                            onClick={() => dispatch(setModalOpen(AppModals.ManagerDetails))}
                            color={"primary"}
                            fullWidth
                        >
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>
        </>
    )
}
