import { useTheme } from "@mui/material/styles"
import { Iconify, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import React from "react"

const NoObservationsPage = () => {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={1} justifyContent={"center"} alignItems={"center"} paddingBottom={3}>
            <Iconify icon={"mdi:cash-remove"} width={30} height={30} color={theme.palette.primary.main} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ color: "text.secondary" }} fontWeight={"bold"} fontSize={17}>
                    {translate("no_observations")}
                </Typography>
            </Box>
        </Box>
    )
}

export default NoObservationsPage
