import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { useUserPropertiesQuery } from "../../../../../queries/userData"
import { useSelector } from "../../../../../redux/store"
import DesktopNotesList from "./desktop/DesktopNotesList"
import MobileNotesList from "./mobile/MobileNotesList"

export default function Notes() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()

    return (
        <Page
            title={translate("notes.title")}
            propertyName={userProperties?.find((p) => p.id === currentPropertyId)?.label}
            paddingTop={1}
            paddingBottom={2}
        >
            {isMobile ? <MobileNotesList /> : <DesktopNotesList />}
        </Page>
    )
}
