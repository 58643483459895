import { AppContext, FileManagerPage, Page, Type, useLocales, useTypedParams } from "rentzz"
import { useCallback, useMemo } from "react"
import { getOwnerFilesQuery, getTenantFilesQuery } from "../../queries/files"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { downloadMultiFile } from "../../utils/actions"
import { useSelector } from "../../redux/store"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import PropertySections from "./PropertySections"

export enum TableData {
    Properties,
    Sections,
    SectionDetails,
}

const TABLE_HEAD = [
    { id: "property_name", label: "property_name" },
    { id: "property_size", label: "property_size", width: 200 },
    { id: "numberOfFiles", label: "number_of_files", width: 250 },
    { id: "download", label: "download", width: 110 },
]

const EMPTY_TABLE_HEAD = [{ id: "1" }, { id: "2", width: 200 }, { id: "4", width: 250 }, { id: "5", width: 110 }]

export default function Files() {
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: userRentingPeriods } = useTenantRentingPeriodsQuery()
    const currentlyViewingPropertyId = useTypedParams<number | undefined>("currentFilesPropertyId", Type.Number)
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const tenantRentingPeriodId = useMemo(
        () => userRentingPeriods?.find((r) => r.propertyId === currentPropertyId)?.rentingPeriodId,
        [userRentingPeriods, currentPropertyId],
    )
    const { data: ownerFiles } = getOwnerFilesQuery(currentPropertyId ?? currentlyViewingPropertyId, currentRentingPeriodId)
    const { data: tenantFiles } = getTenantFilesQuery(tenantRentingPeriodId)
    const files = useMemo(() => (context === AppContext.Owner ? ownerFiles : tenantFiles), [context, ownerFiles, tenantFiles])

    const properties = useMemo(() => {
        return (
            userProperties?.map((p) => {
                return {
                    name: p.label,
                    size: p.currentSize,
                    maxSize: user?.features?.maxPropertySize,
                    numberOfExpenses: p.numberOfExpenses,
                    numberOfIncome: p.numberOfIncome,
                    numberOfDocuments: p.numberOfDocuments,
                    numberOfContracts: p.numberOfContracts,
                    numberOfInsurances: p.numberOfInsurances,
                    numberOfMeterValues: p.numberOfMeterValues,
                    numberOfObservations: p.numberOfObservations,
                    ownershipType: p.ownershipType,
                    id: `${p.id}`,
                }
            }) ?? []
        )
    }, [user, userProperties])

    const handlePropertyDownload = useCallback(
        async (id: string) => {
            if (!files) return
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                await downloadMultiFile({
                    ids: [
                        ...files.contracts.map((f) => f.id),
                        ...files.documents.map((f) => f.id),
                        ...files.expenses.map((f) => f.id),
                        ...files.insurance.map((f) => f.id),
                        ...files.income.map((f) => f.id),
                        ...files.meterValues.map((f) => f.id),
                        ...files.tasks.map((f) => f.id),
                        ...files.observations.map((f) => f.id),
                    ],
                    name: properties.find((p1) => p1.id === id)?.name ?? "",
                })
            } finally {
                closeSnackbar(key)
            }
        },
        [files, downloadMultiFile, properties, closeSnackbar, enqueueSnackbar],
    )

    const handleSelectedPropertiesDownload = useCallback(
        async (ids: string[]) => {
            if (!files) return
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                ids.forEach((id) =>
                    downloadMultiFile({
                        ids: [
                            ...files.contracts.map((f) => f.id),
                            ...files.documents.map((f) => f.id),
                            ...files.expenses.map((f) => f.id),
                            ...files.insurance.map((f) => f.id),
                            ...files.income.map((f) => f.id),
                            ...files.meterValues.map((f) => f.id),
                            ...files.tasks.map((f) => f.id),
                            ...files.observations.map((f) => f.id),
                        ],
                        name: properties.find((p1) => p1.id === id)?.name ?? "",
                    }),
                )
            } finally {
                closeSnackbar(key)
            }
        },
        [files, downloadMultiFile, properties, closeSnackbar, enqueueSnackbar],
    )

    const onDoublePropertyClick = useCallback(
        (id: string) => {
            navigate(id)
        },
        [navigate],
    )

    return (
        <>
            {context === AppContext.Owner ? (
                <Page title={translate("documents")} px={2} paddingBottom={2} paddingTop={1}>
                    <FileManagerPage
                        tableData={properties}
                        onSingleDownload={handlePropertyDownload}
                        onDoubleRowClick={onDoublePropertyClick}
                        onSelectedItemsDownload={handleSelectedPropertiesDownload}
                        tableType={TableData.Properties}
                        tableHeader={TABLE_HEAD}
                        emptyTableHeader={EMPTY_TABLE_HEAD}
                    />
                </Page>
            ) : (
                <PropertySections />
            )}
        </>
    )
}
