import { useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import * as React from "react"

interface Props {
    onSave: () => void
    onClose: () => void
    isLoading: boolean
}
export default function StopSendingAutomaticReadingForm({ onSave, isLoading, onClose }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between' gap={2}>
            <Typography padding={1}>{translate("stopAutomaticReading_content")}</Typography>
            <Box
                sx={{
                    alignItems: isMobile ? "center" : "flex-end",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row-reverse",
                    gap: 2,
                }}
            >
                <LoadingButton
                    onClick={onSave}
                    variant='contained'
                    color={"warning"}
                    fullWidth={isMobile}
                    loading={isLoading}
                    disabled={isLoading}
                    sx={{ color: "white" }}
                >
                    {translate("stop")}
                </LoadingButton>
                <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isLoading}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
