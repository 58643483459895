import { useSelector } from "../../../../../../../redux/store"
import * as React from "react"
import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import parse from "html-react-parser"

interface Props {
    logoWithTextPath: string
    isPreview: boolean
}
export default function TenantNotificationPreview({ logoWithTextPath, isPreview }: Props) {
    const theme = useTheme()
    const { editingItem } = useSelector((state) => state.appState)

    return (
        <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
            <div
                style={{
                    display: isPreview ? "flex" : "block",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 16,
                    paddingBottom: 16,
                    backgroundColor: theme.palette.grey.A200,
                    textAlign: "center",
                    marginLeft: isPreview ? undefined : "auto",
                    marginRight: isPreview ? undefined : "auto",
                    flexDirection: "column",
                }}
            >
                <img src={logoWithTextPath} alt='Logo' title='Logo' height={"80"} />
            </div>
            <h6 style={{ textAlign: "center", fontWeight: "bold", color: "#00A76F", fontSize: 20 }}>{editingItem?.subject}</h6>
            <Box>{parse(editingItem?.content ?? "")}</Box>
        </Box>
    )
}
