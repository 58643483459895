import { CustomDialog, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button } from "@mui/material"
import * as React from "react"
import { useState } from "react"
import SignatureContent from "./SignatureContent"
import { LoadingButton } from "@mui/lab"
import { useSelector } from "../../../../redux/store"

interface SignatureProps {
    onSave: (data: string) => void
    onBack: () => void
    defaultValue?: string
    isLoading: boolean
    onNext: (signature: string) => void
}
export default function Signature({ onSave, onBack, defaultValue, isLoading, onNext }: SignatureProps) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [isSignatureVisible, setIsSignatureVisible] = useState<boolean>(false)
    const { errorMessage } = useSelector((state) => state.appState)

    return (
        <Box>
            {isMobile ? (
                <>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, alignItems: "center", marginTop: 2 }} flexDirection={"column"}>
                        {defaultValue && (
                            <Box marginTop={2} display={"flex"} flexDirection={"column"}>
                                <img alt={""} style={{ height: 75, width: 150 }} src={defaultValue} />
                                <Button color={"primary"} onClick={() => setIsSignatureVisible(true)}>
                                    {translate("try_again")}
                                </Button>
                            </Box>
                        )}
                        {errorMessage && (
                            <Box marginTop={2}>
                                <Alert severity={"error"}>{errorMessage}</Alert>
                            </Box>
                        )}
                        <Box marginTop={2}>
                            <Alert severity={"error"}>{translate("contract_can_not_be_signed")}</Alert>
                        </Box>

                        <LoadingButton
                            variant={"contained"}
                            color={"primary"}
                            loading={isLoading}
                            disabled={isLoading || isMobile}
                            onClick={() => (defaultValue ? onSave(defaultValue) : setIsSignatureVisible(true))}
                        >
                            {translate(defaultValue ? "send_invitation" : "signContract")}
                        </LoadingButton>
                        <Button color={"primary"} onClick={onBack}>
                            {translate("back")}
                        </Button>
                    </Box>
                    <CustomDialog
                        open={isSignatureVisible}
                        isFullScreen={false}
                        title={translate("signContract")}
                        onClose={() => setIsSignatureVisible(false)}
                        titleAlign={"center"}
                    >
                        <SignatureContent
                            onSave={onSave}
                            onBack={() => setIsSignatureVisible(false)}
                            defaultValue={defaultValue}
                            isLoading={isLoading}
                            onNext={onNext}
                            onClose={() => setIsSignatureVisible(false)}
                        />
                    </CustomDialog>
                </>
            ) : (
                <SignatureContent onSave={onSave} onBack={onBack} defaultValue={defaultValue} isLoading={isLoading} />
            )}
        </Box>
    )
}
