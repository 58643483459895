import AddOrEditRevisionGuard from "./AddOrEditRevisionGuard"
import DeleteRevisionGuard from "./DeleteRevisionGuard"
import RevisionDetailsSheet from "../../../sections/propertyDetails/revision/RevisionDetailsSheet"
import RevisionStatusChangeModal from "./RevisionStatusChangeModal"
import AddOrEditObservationGuard from "./AddOrEditObservationGuard"
import DeleteObservationGuard from "./DeleteObservationGuard"
import ObservationsListGuard from "./ObservationsListGuard"
import ObservationDetailsSheet from "./ObservationDetailsSheet"

export default function RevisionGuard() {
    return (
        <>
            <AddOrEditRevisionGuard />
            <DeleteRevisionGuard />
            <DeleteObservationGuard />
            <RevisionDetailsSheet />
            <RevisionStatusChangeModal />
            <AddOrEditObservationGuard />
            <ObservationsListGuard />
            <ObservationDetailsSheet />
        </>
    )
}
