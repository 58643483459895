import NoDataPage from "../../../components/NoDataPage"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import React from "react"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { useDispatch } from "../../../redux/store"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useIsMobile } from "rentzz"

export default function NoRecurringExpensesPage() {
    const dispatch = useDispatch()
    const { data: userProperties } = useUserPropertiesQuery()
    const { canWrite } = usePermissions(PermissionType.RecurringExpense)
    const isMobile = useIsMobile()

    return (
        <NoDataPage
            isDisabled={userProperties?.length === 0}
            tooltipText={userProperties?.length === 0 ? "recurringExpenses.no_property" : undefined}
            onAddNewItem={canWrite && isMobile ? () => dispatch(setModalOpen(AppModals.AddRecurringExpense)) : undefined}
            icon={"mdi:tag-off"}
            noDataText={"no_recurring_expenses"}
            textButton={"recurringExpenses.add_recurring_expense"}
        />
    )
}
