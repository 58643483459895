import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { handleArchiveContractTemplate } from "../../../mutations/property/contracts"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"

export default function UpdateContractTemplateArchiveStatusGuard() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: handleArchiveContract, isPending: isHandleArchiveContractLoading } = handleArchiveContractTemplate()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [modalOpen, dispatch])

    const onUpdateArchiveStatus = useCallback(async () => {
        await handleArchiveContract({ currentTemplateId: editingItem?.contractId, isArchived: !editingItem?.isArchived })
        closeModal()
    }, [editingItem, handleArchiveContract, closeModal])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.HandleArchivedContractTemplates}
                onClose={closeModal}
                isLoading={false}
                title={translate(editingItem?.isArchived ? "unzip" : "archived", {
                    name: editingItem?.name,
                })}
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between' gap={2}>
                    <Typography padding={1} textAlign={"center"}>
                        {translate(editingItem?.isArchived ? "unzip_template_details" : "archived_template_details", {
                            name: editingItem?.name,
                        })}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onUpdateArchiveStatus}
                            variant='contained'
                            color={"primary"}
                            fullWidth
                            loading={isHandleArchiveContractLoading}
                            disabled={isHandleArchiveContractLoading}
                        >
                            {translate(editingItem?.isArchived ? "unzip" : "archived")}
                        </LoadingButton>
                        <Button onClick={closeModal} color={"primary"} fullWidth disabled={isHandleArchiveContractLoading}>
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>
            <CustomDialog
                isFullScreen={false}
                title={translate(editingItem?.isArchived ? "unzip" : "archived", {
                    name: editingItem?.name,
                })}
                onClose={closeModal}
                open={!isMobile && modalOpen === AppModals.HandleArchivedContractTemplates}
                maxWidth='xs'
                onAccept={onUpdateArchiveStatus}
                acceptText={translate(editingItem?.isArchived ? "unzip" : "archived")}
                closeText={translate("cancel")}
                closeButtonColor={"primary"}
                variantCloseButton={editingItem?.shouldNotifyForRenewal ? "contained" : "text"}
                acceptButtonColor={editingItem?.shouldNotifyForRenewal ? "error" : "primary"}
                isDisabled={isHandleArchiveContractLoading}
                isLoading={isHandleArchiveContractLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>
                        {translate(editingItem?.isArchived ? "unzip_template_details" : "archived_template_details", {
                            name: editingItem?.name,
                        })}
                    </Typography>
                </Box>
            </CustomDialog>
        </>
    )
}
