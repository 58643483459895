import { Page, useLocales, useResponsive } from "rentzz"
import React, { useCallback } from "react"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import DesktopRentingPeriodsList from "./Desktop/DesktopRentingPeriodsList"
import MobileTenantsList from "./Mobile/MobileTenantsList"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"
import NoPropertyPage from "../../../guards/no-property/NoPropertyPage"

export default function Tenants() {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const mdUp = useResponsive("up", "md")
    const { translate } = useLocales()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyId == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [intl, currencyData],
    )

    if (userProperties?.length === 0) return <NoPropertyPage />

    return (
        <Page
            title={translate("tenants")}
            propertyName={userProperties?.find((p) => p.id === currentPropertyId)?.label}
            px={currentPropertyId ? 0 : 2}
            paddingBottom={2}
            paddingTop={1}
        >
            {mdUp ? <DesktopRentingPeriodsList getAmount={getAmount} /> : <MobileTenantsList getAmount={getAmount} />}
        </Page>
    )
}
