import { Box } from "@mui/material"
import React from "react"
import OwnerNotificationsForm from "./OwnerNotificationsForm"

interface OwnerMobileNotificationsProps {
    isDisabled: boolean
}

export default function OwnerDesktopNotifications({ isDisabled }: OwnerMobileNotificationsProps) {
    return (
        <Box display='flex' height={500} overflow={"auto"} flexDirection='column' paddingLeft={5}>
            <OwnerNotificationsForm isDisabled={isDisabled} />
        </Box>
    )
}
