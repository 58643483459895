import { FormProvider, RHFDateRangePicker, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentingPeriodStatusSchema } from "../../validations/rentingPeriod"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { useRentingPeriodDetails } from "../../queries/tenants"
import { useSelector } from "../../redux/store"
import { useUserPropertiesQuery } from "../../queries/userData"
import { DateTime } from "luxon"

export interface RentingPeriodStatusRequest {
    moveInDate: string
    moveOutDate: string
}
interface Props {
    onClose: (save: boolean) => void
    handlePeriod: (data: RentingPeriodStatusRequest) => void
    isLoading: boolean
}
export default function RentingPeriodStatusForm({ handlePeriod, onClose, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const [isExtendingRentingPeriod, setIsExtendingRentingPeriod] = useState<boolean>(false)
    const { data: rentingPeriodDetails, isPending } = useRentingPeriodDetails(editingItem?.rentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()

    const methods = useForm<RentingPeriodStatusRequest>({
        resolver: yupResolver<RentingPeriodStatusRequest>(RentingPeriodStatusSchema),
        defaultValues: {
            moveInDate: rentingPeriodDetails?.moveInDate.toISO() ?? undefined,
            moveOutDate: rentingPeriodDetails?.moveOutDate.toISO() ?? undefined,
        },
    })

    const { reset, handleSubmit } = methods

    useEffect(() => {
        reset({
            moveInDate: rentingPeriodDetails?.moveInDate.toISO() ?? undefined,
            moveOutDate: rentingPeriodDetails?.moveOutDate.toISO() ?? undefined,
        })
    }, [reset, rentingPeriodDetails])

    const handleBack = useCallback(() => {
        if (isExtendingRentingPeriod) {
            setIsExtendingRentingPeriod(false)
        } else {
            onClose(false)
        }
    }, [isExtendingRentingPeriod, onClose])

    if (isPending) {
        return (
            <Box height={50} display='flex' justifyContent='center'>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(handlePeriod)}>
            <Box display={"flex"} flexDirection={"column"} paddingTop={2}>
                {isExtendingRentingPeriod && <RHFDateRangePicker fromName={"moveInDate"} toName={"moveOutDate"} isStartDisabled />}
                {!isExtendingRentingPeriod && (
                    <Typography textAlign={isMobile ? "center" : undefined}>
                        {translate("rentingPeriodStatusContent", {
                            propertyName: properties?.find((p) => p.id === rentingPeriodDetails?.propertyId)?.label,
                            endDate: rentingPeriodDetails?.moveOutDate.toLocaleString(DateTime.DATE_SHORT),
                        })}
                    </Typography>
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingX: 0,
                        paddingTop: 4,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={handleBack} disabled={isLoading}>
                        {translate(isExtendingRentingPeriod ? "back" : "remind_me_later")}
                    </Button>
                    <Box display={"flex"} gap={1} flexDirection={isMobile ? "column-reverse" : "row"}>
                        {!isExtendingRentingPeriod && (
                            <Button
                                color={"info"}
                                onClick={() => {
                                    setIsExtendingRentingPeriod(true)
                                }}
                                variant='contained'
                            >
                                {translate("extendRentingPeriod")}
                            </Button>
                        )}

                        {!isExtendingRentingPeriod && (
                            <Button color={"success"} variant='contained' onClick={() => onClose(true)}>
                                {translate("OK")}
                            </Button>
                        )}
                        {isExtendingRentingPeriod && (
                            <LoadingButton color={"primary"} variant='contained' type={"submit"} loading={isLoading} disabled={isLoading}>
                                {translate("extendRentingPeriod")}
                            </LoadingButton>
                        )}
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    )
}
