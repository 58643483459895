import { LightTooltip, useLocales } from "rentzz"
import { RoleName, useUserDataQueryFn } from "../queries/userData"
import { Button } from "@mui/material"
import React, { ReactElement } from "react"
import ChangePlanTooltipComponent from "./ChangePlanTooltipComponent"

interface Props {
    onClick: () => void
    isDisabled?: boolean
    buttonText: ReactElement | string
    blockedRoles: RoleName[]
    variant?: "text" | "outlined" | "contained"
}
export default function RoleLockedButton({ onClick, isDisabled, buttonText, blockedRoles, variant = "contained" }: Props) {
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    if (user == null) return null
    return (
        <LightTooltip arrow title={blockedRoles.includes(user?.roles.roleName) ? <ChangePlanTooltipComponent /> : ""}>
            <span>
                <Button
                    disabled={blockedRoles.includes(user?.roles.roleName) || isDisabled}
                    color='primary'
                    onClick={onClick}
                    variant={variant}
                    sx={{ textTransform: "none" }}
                >
                    {typeof buttonText === "string" ? translate(buttonText) : buttonText}
                </Button>
            </span>
        </LightTooltip>
    )
}
