import { AppContext, DetailsSheet, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useSectionItemsQueryFn, useTenantTasksQueryFn } from "../../../queries/kanban"
import EditTaskForm from "../../../sections/kanban/details/mobile/EditTaskForm"

export default function EditTaskDetailsSheetGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: ownerTasks } = useSectionItemsQueryFn(editingItem?.sectionId)
    const { data: tenantTasks } = useTenantTasksQueryFn(editingItem?.rentingPeriodId)

    const tasks = useMemo(() => (context === AppContext.Owner ? ownerTasks : tenantTasks), [context, ownerTasks, tenantTasks])

    const currentTask = useMemo(() => tasks?.find((task) => task.id === editingItem?.taskId), [tasks, editingItem])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(AppModals.TaskDetails))
    }, [dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.EditTask}
            onClose={onClose}
            isLoading={false}
            title={translate("edit_task")}
            options={[]}
            headerColor={theme.palette.primary.main}
        >
            <EditTaskForm currentTask={currentTask} onClose={onClose} />
        </DetailsSheet>
    )
}
