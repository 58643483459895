import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Alert, Box, CircularProgress, Typography } from "@mui/material"
import * as React from "react"
import { useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, DetailsSheet, ExpenseAssignee, useLocales } from "rentzz"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { useExpenseLinkedIncomesQuery, useSingleExpenseQuery } from "../../../queries/expenses"
import { useTheme } from "@mui/material/styles"
import AssignedIncomeCard from "./AssignedIncomeCard"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { UserFlags, useTenantRentingPeriodsQuery } from "../../../queries/userData"
import AddCardIcon from "@mui/icons-material/AddCard"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface Props {
    onClose: () => void
}

export default function AssignedIncomesModal({ onClose }: Props) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)
    const currentIncomesIds = useMemo(() => currentExpense?.propertyIncomes.map((income) => income.id), [currentExpense])
    const { data: expenseIncomes, isFetching: isLoading } = useExpenseLinkedIncomesQuery(currentIncomesIds, currentExpense?.id)
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const isPaymentOnlineEnabled = useFeatureIsOn(UserFlags.ExpenseOnlinePayment.toString())

    const currentTenantRentingPeriod = useMemo(
        () => tenantRentingPeriods?.find((t) => t.rentingPeriodId === currentRentingPeriodId),
        [tenantRentingPeriods, currentRentingPeriodId],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentExpense == null) return []
        if (currentExpense?.assignee === ExpenseAssignee.ME) return []
        return [
            {
                icon: <AddCardIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: context === AppContext.Owner ? "income-table.addIncome" : "payment.addPayment",
                onClick: () => {
                    if (context === AppContext.Owner) {
                        dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsOwner))
                    } else {
                        if (isPaymentOnlineEnabled) {
                            dispatch(setModalOpen(AppModals.TenantPayment))
                        } else {
                            dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
                        }
                    }
                },
                hidden:
                    context === AppContext.Owner
                        ? !currentExpense?.canWrite
                        : !currentTenantRentingPeriod?.acceptPartialPayments && currentExpense.propertyIncomesCount >= 1,
            },
        ]
    }, [currentExpense, dispatch, context, currentTenantRentingPeriod, isPaymentOnlineEnabled])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.AssignedIncomes}
            onClose={onClose}
            isLoading={currentExpense == null}
            title={`${translate("income")} ${currentExpense?.name}`}
            options={options}
            headerColor={currentExpense?.assignee === ExpenseAssignee.ME ? theme.palette.primary.main : editingItem?.backgroundColor}
        >
            {currentExpense?.assignee === ExpenseAssignee.ME ? (
                <Alert
                    sx={{
                        marginTop: 2,
                        alignItems: "center",
                        display: "flex",
                        textAlign: "center",
                        color: theme.palette.grey["700"],
                    }}
                    severity='info'
                >
                    <Typography sx={{ color: "text.secondary" }} textAlign={"center"}>
                        {translate("only_expenses_assigned")}
                    </Typography>
                </Alert>
            ) : (
                <>
                    {isLoading ? (
                        <Box display={"flex"} justifyContent={"center"} height={300}>
                            <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                        </Box>
                    ) : (
                        <Box sx={{ overflowY: "auto", maxHeight: 500 }}>
                            <Box gap={1} display={"flex"} flexDirection={"column"} px={1} py={2}>
                                {expenseIncomes?.map((income) => (
                                    <AssignedIncomeCard
                                        key={income.id}
                                        income={income}
                                        onClick={() => {
                                            if (context === AppContext.Owner) {
                                                dispatch(setModalOpen(AppModals.IncomeDetails))
                                                dispatch(
                                                    setEditingItem({
                                                        id: editingItem?.id,
                                                        incomeId: income.id,
                                                        isFromAnExpense: true,
                                                        backgroundColor: editingItem?.backgroundColor,
                                                    }),
                                                )
                                            } else {
                                                dispatch(setModalOpen(AppModals.PaymentDetails))
                                                dispatch(
                                                    setEditingItem({
                                                        id: editingItem?.id,
                                                        paymentId: income.id,
                                                        isFromAnExpense: true,
                                                        backgroundColor: editingItem?.backgroundColor,
                                                    }),
                                                )
                                            }
                                        }}
                                    />
                                ))}
                                {expenseIncomes === undefined && (
                                    <Alert
                                        sx={{
                                            marginTop: 2,
                                            alignItems: "center",
                                            display: "flex",
                                            textAlign: "center",
                                            color: theme.palette.grey["700"],
                                        }}
                                        severity='warning'
                                    >
                                        <Typography sx={{ color: "text.secondary" }}>{translate("no_income")}</Typography>
                                    </Alert>
                                )}
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </DetailsSheet>
    )
}
