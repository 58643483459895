import "simplebar-react/dist/simplebar.min.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "react-lazy-load-image-component/src/effects/opacity.css"
import "react-lazy-load-image-component/src/effects/black-and-white.css"
import "react-multi-email/dist/style.css"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { Provider as ReduxProvider } from "react-redux"
import "./locales/i18n"
import App from "./App"
import { CollapseDrawerProvider, MotionLazy, SettingsProvider, SnackbarProvider } from "rentzz"
import { store } from "./redux/store"
import { LicenseInfo } from "@mui/x-license-pro"
import { Config } from "./configs/Config"
import { defaultSettings } from "./config"
import ConfigurationProvider from "./ConfigurationProvider"
import Tracker from "@openreplay/tracker"
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react"
import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"
import { Worker } from "@react-pdf-viewer/core"
import { L10n, registerLicense } from "@syncfusion/ej2-base"
import ro from "@syncfusion/ej2-locale/src/ro.json"
import en from "@syncfusion/ej2-locale/src/en-GB.json"

L10n.load(ro)
L10n.load(en)

LicenseInfo.setLicenseKey(Config.MUI_LICENSE)

export const tracker = new Tracker({
    projectKey: "HgaSnwPNk6zNwcnldkU2",
    ingestPoint: "https://openreplay.rentzz.app/ingest",
    network: {
        capturePayload: true,
        sessionTokenHeader: false,
        failuresOnly: true,
        ignoreHeaders: false,
        captureInIframes: false,
    },
})

export const growthbook = new GrowthBook({
    apiHost: "https://flags-api.rentzz.app",
    clientKey: import.meta.env.VITE_APP_ENVIRONMENT_ID,
    subscribeToChanges: true,
})

// Initialize Firebase
const app = initializeApp({
    apiKey: Config.API_KEY,
    authDomain: Config.AUTH_DOMAIN_KEY,
    projectId: Config.PROJECT_ID,
    storageBucket: Config.STORAGE_BUCKET,
    messagingSenderId: Config.MESSAGING_SENDER_ID,
    appId: Config.APP_ID,
})

registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfeHVVR2ZZV0R0Xko=")

export const messaging = "Notification" in window ? getMessaging(app) : null

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <HelmetProvider>
        <Suspense>
            <ReduxProvider store={store}>
                <GrowthBookProvider growthbook={growthbook}>
                    <SettingsProvider defaultSettings={defaultSettings}>
                        <MotionLazy>
                            <ConfigurationProvider>
                                <SnackbarProvider>
                                    <CollapseDrawerProvider>
                                        <React.StrictMode>
                                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                                                <App />
                                            </Worker>
                                        </React.StrictMode>
                                    </CollapseDrawerProvider>
                                </SnackbarProvider>
                            </ConfigurationProvider>
                        </MotionLazy>
                    </SettingsProvider>
                </GrowthBookProvider>
            </ReduxProvider>
        </Suspense>
    </HelmetProvider>,
)
