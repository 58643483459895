import EditInsuranceNotification from "../../../sections/insurance/EditInsuranceNotification"
import { AppModals } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useSelector } from "../../../redux/store"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import * as jsonpatch from "fast-json-patch"
import { updateInsuranceMutation } from "../../../mutations/property/insurance"
import { getInsuranceByIdQueryFn } from "../../../queries"
import { useTheme } from "@mui/material/styles"
import ActionSheetInsuranceNotification from "../../../sections/insurance/mobile/ActionSheetInsuranceNotification"
import { Box, CircularProgress } from "@mui/material"
import { InsuranceType } from "../../../redux/slices/AddProperty"

interface UpdateInsuranceNotificationRequest {
    id: number | undefined
    shouldNotifyForRenewal: boolean
    propertyId: number
}

interface InsuranceNotificationGuardProps {
    onClose: () => void
}
export default function InsuranceNotificationGuard({ onClose }: InsuranceNotificationGuardProps) {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: editInsurance, isPending: isEditInsuranceLoading } = updateInsuranceMutation()
    const { data: currentInsurance } = getInsuranceByIdQueryFn(editingItem?.id)

    const updateInsuranceNotification = useCallback(
        async (data: UpdateInsuranceNotificationRequest) => {
            if (!data.id || currentInsurance == null) return
            const operations = jsonpatch.compare(
                { shouldNotifyForRenewal: currentInsurance?.shouldNotifyForRenewal },
                { shouldNotifyForRenewal: data.shouldNotifyForRenewal },
            )
            await editInsurance({
                insuranceId: currentInsurance.id,
                propertyId: data.propertyId,
                operations: operations,
            })
        },
        [currentInsurance, editInsurance],
    )

    const onSubmit = useCallback(async () => {
        if (currentInsurance == null) return
        await updateInsuranceNotification({ ...currentInsurance, shouldNotifyForRenewal: !currentInsurance.shouldNotifyForRenewal })
        onClose()
    }, [updateInsuranceNotification, currentInsurance, onClose])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("insurance.editNotificationTitle")}
                isOpen={isMobile && modalOpen === AppModals.EditInsuranceNotification}
                onClose={onClose}
                headerColor={currentInsurance?.insuranceType === InsuranceType.Mandatory ? theme.palette.warning.main : theme.palette.info.main}
            >
                {isEditInsuranceLoading ? (
                    <Box display={"flex"} justifyContent={"center"} height={150}>
                        <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <ActionSheetInsuranceNotification onClose={onClose} onSubmit={onSubmit} currentInsurance={currentInsurance} />
                )}
            </DetailsSheet>
            <EditInsuranceNotification
                isVisible={!isMobile && modalOpen === AppModals.EditInsuranceNotification}
                onClose={onClose}
                onSubmit={onSubmit}
                isLoading={isEditInsuranceLoading}
                currentInsurance={currentInsurance}
            />
        </>
    )
}
