import { useHealthCheck } from "../queries/app"
import { HealthCheck, Maintenance } from "rentzz"
import { ReactNode } from "react"

interface MaintenanceGuardProps {
    children: ReactNode
}

export default function MaintenanceGuard({ children }: MaintenanceGuardProps) {
    const { isError, data } = useHealthCheck()

    if (isError || data?.azure === HealthCheck.NonHealthy || data?.database === HealthCheck.NonHealthy) {
        return <Maintenance />
    }

    return <>{children}</>
}
