import { Page, Text } from "@react-pdf/renderer"
import React from "react"
import { DateTime } from "luxon"
import IndividualOwnerContact from "../IndividualOwnerContact"
import { useStyles } from "../useStyles"
import { useLocales } from "rentzz"
import PropertyInfoData from "../RentDetailsData"
import ContractConditions from "../ContractConditions"
import Signatures from "../Signatures"
import { Focus } from "../OwnerLivePreview"
import { ContactInfoData } from "./TenantContactInfo"
import IndividualTenantContactFinalData from "./IndividualTenantContactFinalData"
import PropertyDataPreviewForTenant from "./PropertyDataPreviewForTenant"
import { ContactInfoPersonType, ContactInfoType, ContractSignature } from "../../../../queries/userData"
import CompanyOwnerContact from "../CompanyOwnerContract"
import { PropertyDataInfo } from "../../../../redux/slices/AddProperty"
import CompanyTenantContactFinalData from "./CompanyTenantContactFinalData"

interface TenantLivePreviewProps {
    ownerInfo: Omit<ContactInfoData, "type"> | undefined
    tenantInfo: Omit<ContactInfoData, "type"> | undefined
    propertyData: PropertyDataInfo | undefined
    ownerObligations: string[] | undefined
    tenantObligations: string[] | undefined
    partiesRequirements: string[] | undefined
    litigation: string[] | undefined
    hasGDPR?: boolean
    moveInDate?: string
    moveOutDate?: string
    rentPrice?: string
    paymentPeriod?: string
    securityDeposit?: string
    ownerSignature: string | undefined
    tenantSignatures: ContractSignature[] | undefined
    contactInfos?: ContactInfoData[]
    bankAccount?: string
    bankName?: string
}
const TenantLivePreview = ({
    ownerInfo,
    tenantInfo,
    propertyData,
    ownerObligations,
    tenantObligations,
    partiesRequirements,
    litigation,
    hasGDPR,
    ownerSignature,
    tenantSignatures,
    moveOutDate,
    moveInDate,
    rentPrice,
    paymentPeriod,
    securityDeposit,
    contactInfos,
    bankAccount,
    bankName,
}: TenantLivePreviewProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <Page size='A4' style={{ ...styles.page, fontFamily: "Roboto" }}>
            <Text style={{ ...styles.mainTitle, ...styles.boldFont }}>{translate("rental_agreement")}</Text>
            <Text style={{ ...styles.date, ...styles.boldFont }}>{DateTime.now().toLocaleString(DateTime.DATE_SHORT)}</Text>
            <Text style={{ ...styles.partiesTitle, ...styles.boldFont }}>{translate("parties")}</Text>
            {ownerInfo?.contactInfoPersonType === ContactInfoPersonType.Individual ? (
                <IndividualOwnerContact ownerInfo={ownerInfo} isFocused={false} />
            ) : (
                <CompanyOwnerContact ownerInfo={ownerInfo} />
            )}

            {contactInfos &&
                contactInfos
                    .filter((info) => info.type === ContactInfoType.Tenant)
                    .map((contact) => (
                        <>
                            <Text style={{ ...styles.mainText, ...styles.boldFont }}>{translate("and")}</Text>
                            {contact.contactInfoPersonType === ContactInfoPersonType.Individual ? (
                                <IndividualTenantContactFinalData tenantInfoData={contact} />
                            ) : (
                                <CompanyTenantContactFinalData tenantInfoData={contact} />
                            )}
                        </>
                    ))}

            {tenantInfo && (
                <>
                    <Text style={{ ...styles.mainText, ...styles.boldFont }}>{translate("and")}</Text>
                    {tenantInfo.contactInfoPersonType === ContactInfoPersonType.Individual ? (
                        <IndividualTenantContactFinalData tenantInfoData={tenantInfo} />
                    ) : (
                        <CompanyTenantContactFinalData tenantInfoData={tenantInfo} />
                    )}

                    <Text style={styles.mainText}>{translate("agree_with_rental_agreement")}</Text>
                </>
            )}
            {propertyData && (
                <>
                    <Text style={styles.mainText}>{translate("agree_with_following_conditions")}</Text>
                    <PropertyDataPreviewForTenant propertyData={propertyData} />
                </>
            )}

            {ownerObligations && (
                <PropertyInfoData
                    rentPrice={rentPrice}
                    securityDeposit={securityDeposit}
                    moveInDate={moveInDate}
                    moveOutDate={moveOutDate}
                    paymentPeriod={paymentPeriod}
                    bankAccount={bankAccount}
                    bankName={bankName}
                />
            )}
            <ContractConditions
                ownerObligations={ownerObligations}
                tenantObligations={tenantObligations}
                partiesRequirements={partiesRequirements}
                litigation={litigation}
                focus={Focus.NONE}
                hasGDPR={hasGDPR}
            />
            {ownerObligations && tenantObligations && partiesRequirements && litigation && (
                <Signatures ownerFullName={ownerInfo?.fullName} ownerSignature={ownerSignature} tenantSignatures={tenantSignatures} />
            )}
        </Page>
    )
}

export default TenantLivePreview
