import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteContent from "../../../components/DeleteContent"
import { useDispatch, useSelector } from "../../../redux/store"

import { deleteBankStatementMutation } from "../../../mutations/bankAccounts"

export default function DeleteBankStatementGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteBankStatement, isPending: isDeleteBankStatementLoading } = deleteBankStatementMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (editingItem == null) return
        await deleteBankStatement(editingItem?.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [editingItem, deleteBankStatement, dispatch, onClose])

    return (
        <>
            <CustomDialog
                title={translate("delete_bank_statement")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteBankStatement}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bank_statement_content")}
                    isLoading={isDeleteBankStatementLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteBankStatement}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_bank_statement")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bank_statement_content")}
                    isLoading={isDeleteBankStatementLoading}
                />
            </DetailsSheet>
        </>
    )
}
