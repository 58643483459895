import { PropertyExpenseJournalDetails, PropertyExpenseJournalDetailsDto } from "../../../../../../queries/expenses"
import { Box, Typography } from "@mui/material"
import { useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../../../../../queries/userData"
import { useCallback, useMemo } from "react"
import { fromIsoToDateTime } from "../../../../../../utils/dateMagic"
import { DateTime } from "luxon"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../../../queries/currency"
import { useIntl } from "react-intl"

interface ExpenseCellRenderProps {
    data: PropertyExpenseJournalDetailsDto
}

const ExpenseCellRender = ({ data }: ExpenseCellRenderProps) => {
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const transformedExpense = useMemo((): PropertyExpenseJournalDetails => {
        return {
            ...data,
            date: fromIsoToDateTime(data.date),
            dueDate: fromIsoToDateTime(data.dueDate),
        }
    }, [data])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    if (userProperties == null) return null

    return (
        <Box display='flex' flexDirection='column' gap={1} p={1}>
            <Box>
                <Typography>{translate("expenses.name")}</Typography>
                <Typography>{transformedExpense.name}</Typography>
            </Box>
            <Box>
                <Typography>{translate("expenses.value")}</Typography>
                <Typography>{getAmount(transformedExpense.value, transformedExpense.currencyId)}</Typography>
            </Box>
            <Box>
                <Typography>{translate("expenses.date")}</Typography>
                <Typography>{transformedExpense.date.toLocaleString(DateTime.DATE_SHORT)}</Typography>
            </Box>
            <Box>
                <Typography>{translate("due_date")}</Typography>
                <Typography>{transformedExpense.dueDate.toLocaleString(DateTime.DATE_SHORT)}</Typography>
            </Box>
            {transformedExpense.providerName != null && transformedExpense.providerName.length > 0 && (
                <Box>
                    <Typography>{translate("expenses.providerName")}</Typography>
                    <Typography>{transformedExpense.providerName}</Typography>
                </Box>
            )}
        </Box>
    )
}

export default ExpenseCellRender
