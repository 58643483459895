import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import EditPropertyPercentageForm from "../../../sections/groups/groupSection/EditPropertyPercentageForm"
import { useUserPropertiesQuery } from "../../../queries/userData"

export default function EditPropertyPercentage() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()

    const currentProperty = useMemo(() => properties?.find((p) => p.id === editingItem?.id), [editingItem, properties])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(AppModals.PropertyGroupDetails))
    }, [dispatch])

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("edit_percentage")}
            isOpen={isMobile && modalOpen === AppModals.EditPropertyGroupPercentage}
            onClose={closeModal}
            headerColor={theme.palette.primary.main}
        >
            <EditPropertyPercentageForm onClose={closeModal} currentProperty={currentProperty} />
        </DetailsSheet>
    )
}
