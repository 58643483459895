import { Box, Button, Typography, useTheme } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import { useDispatch } from "../../redux/store"
import { AccountType, useUserDataQueryFn } from "../../queries/userData"
import { AppModals, setModalOpen } from "../../redux/slices/App"

export default function NoPropertyPage() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { data: userData } = useUserDataQueryFn()

    return (
        <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={1} justifyContent={"center"} alignItems={"center"} paddingTop={20}>
            <Iconify icon={"mdi:home-off"} width={50} height={50} color={theme.palette.primary.main} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography sx={{ color: "text.secondary" }} fontWeight={"bold"} fontSize={17}>
                    {translate("no_property")}
                </Typography>
                <Typography sx={{ color: "text.secondary" }} textAlign={"center"} marginTop={1}>
                    {translate(userData?.accountType === AccountType.WHITELABEL_USER ? "for_access_read" : "for_access")}
                </Typography>
            </Box>
            {userData?.accountType !== AccountType.WHITELABEL_USER && (
                <Button
                    size={"large"}
                    variant={"contained"}
                    color={"primary"}
                    sx={{ textTransform: "none", marginTop: 3 }}
                    onClick={() => dispatch(setModalOpen(AppModals.AddProperty))}
                >
                    {translate("add_property")}
                </Button>
            )}
        </Box>
    )
}
