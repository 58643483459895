import IncomeStatusDialog from "../../../sections/income/IncomeStatusDialog"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import { useCallback } from "react"
import { IncomeStatus, PropertyIncome } from "../../../redux/slices/AddProperty"
import * as jsonpatch from "fast-json-patch"
import { updateIncomeMutation } from "../../../mutations/incomes"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import ActionSheetIncomeStatus from "../../../sections/income/mobile/ActionSheetIncomeStatus"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { useTheme } from "@mui/material/styles"
import { useSingleIncomeQuery } from "../../../queries/income"

export default function IncomeStatusGuard() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateIncome, isPending: isUpdatingIncome } = updateIncomeMutation()
    const { data: currentIncome } = useSingleIncomeQuery(editingItem?.id)

    const closeModal = useCallback(() => {
        if (editingItem?.isFromAnExpense) {
            return dispatch(setModalOpen(AppModals.AssignedIncomes))
        }
        if (isMobile && modalOpen !== AppModals.IncomeDetails) {
            dispatch(setModalOpen(AppModals.IncomeDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch, isMobile, editingItem])

    const handleIncomeStatusChange = useCallback(
        async (data: PropertyIncome) => {
            const operations = jsonpatch.compare({ status: currentIncome?.status }, { status: data.status })
            if (!data.id) return
            await updateIncome({
                propertyId: data.propertyId,
                incomeId: data.id,
                operations,
            })
            closeModal()
        },
        [updateIncome, closeModal],
    )
    const getRentPrice = useCallback(() => {
        if (currencyData == null || currentIncome == null) return ""

        return intl.formatNumber(Number(currentIncome?.value), {
            style: "currency",
            currency: getCurrencyFromId(currencyData, currentIncome?.currencyId).code,
        })
    }, [currentIncome, currencyData, intl])

    const onAccept = useCallback(async () => {
        if (currentIncome == null) return
        await handleIncomeStatusChange({ ...currentIncome, status: IncomeStatus.Accepted })
        closeModal()
    }, [handleIncomeStatusChange, currentIncome, closeModal])

    const onDecline = useCallback(async () => {
        if (currentIncome == null) return
        await handleIncomeStatusChange({ ...currentIncome, status: IncomeStatus.Denied })
        closeModal()
    }, [handleIncomeStatusChange, currentIncome, closeModal])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("dialog_income_status_title")}
                isOpen={isMobile && modalOpen === AppModals.HandleIncomeStatus}
                onClose={closeModal}
                headerColor={theme.palette.warning.main}
            >
                <ActionSheetIncomeStatus
                    currentIncome={currentIncome}
                    isIncomeStatusLoading={isUpdatingIncome}
                    onAccept={onAccept}
                    onDecline={onDecline}
                    onClose={closeModal}
                    getRentPrice={getRentPrice}
                />
            </DetailsSheet>
            <IncomeStatusDialog
                isVisible={!isMobile && modalOpen === AppModals.HandleIncomeStatus}
                onClose={closeModal}
                currentIncome={currentIncome}
                isIncomeStatusLoading={isUpdatingIncome}
                onAccept={onAccept}
                onDecline={onDecline}
                getRentPrice={getRentPrice}
            />
        </>
    )
}
