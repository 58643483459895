import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { Iconify, LoadingIcon, useLocales } from "rentzz"
import { Box, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomMobileCard from "../../../components/CustomMobileCard"
import React, { useMemo } from "react"
import { useUserGroupsQueryFn } from "../../../queries/groups"

export default function MobileGroupPropertiesPage() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: groups } = useUserGroupsQueryFn()
    const { modalOpen, currentGroupId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [currentGroupId, groups])

    const currentProperties = useMemo(() => {
        if (currentGroup == null || properties == null) return

        return currentGroup.properties.map((p) => {
            const match = properties.find((property) => property.id == p.propertyId)
            const reverseMatch = currentGroup.properties.find((p) => p.propertyId === match?.id)
            return {
                id: match!.id,
                name: match!.label,
                county: match!.county,
                city: match!.city,
                expenseShare: reverseMatch?.expenseShare,
            }
        })
    }, [properties, currentGroup])

    if (currentProperties == null) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} sx={{ height: "inherit", paddingBottom: 2, paddingTop: 2 }}>
            {currentProperties.length > 0 && modalOpen == null && (
                <Fab
                    onClick={() => {
                        dispatch(setModalOpen(AppModals.EditGroupProperties))
                        dispatch(setEditingItem({ id: currentGroupId }))
                    }}
                    sx={{ position: "absolute", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}
            {currentProperties.map((property) => (
                <CustomMobileCard
                    key={property.id}
                    item={property}
                    onClick={() => {
                        dispatch(setModalOpen(AppModals.PropertyGroupDetails))
                        dispatch(setEditingItem({ id: property.id }))
                    }}
                    icon={"mdi:house"}
                    iconColor={theme.palette.primary.main}
                    title={property.name}
                    subtitle={`${translate("percentage")} ${(property.expenseShare ?? 0) * 100}%`}
                    rightFirstItem={property.county}
                    rightSecondItem={property.city}
                />
            ))}
        </Box>
    )
}
