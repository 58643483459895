import AddTaskGuard from "./AddTaskGuard"
import TaskDetailsGuard from "./TaskDetailsGuard"
import DeleteTaskGuard from "./DeleteTaskGuard"
import EditTaskDetailsSheetGuard from "./EditTaskDetailsSheetGuard"
import TaskCommentsDetailsSheetGuard from "./TaskCommentsDetailsSheetGuard"

export default function TasksGuard() {
    return (
        <>
            <AddTaskGuard />
            <TaskDetailsGuard />
            <EditTaskDetailsSheetGuard />
            <TaskCommentsDetailsSheetGuard />
            <DeleteTaskGuard />
        </>
    )
}
