import { CustomDialog, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button } from "@mui/material"
import * as React from "react"
import { useCallback, useState } from "react"
import { updateRentingPeriodWithContractMutation } from "../../../../mutations/user"
import ReactPDF, { Document } from "@react-pdf/renderer"
import { DateTime } from "luxon"
import { ContactInfoPersonType, ContactInfoType, SignatureType, useUserTenantRequests } from "../../../../queries/userData"
import { ContactInfoData } from "../../../contracts/livePreview/tenant/TenantContactInfo"
import { LoadingButton } from "@mui/lab"
import TenantLivePreview from "../../../contracts/livePreview/tenant/TenantLivePreview"
import { useSelector } from "../../../../redux/store"
import SignatureTenantContent from "./SignatureTenantContent"

export interface SignatureProps {
    onSignatureSubmit: (signature: string) => void
    onBackClick: () => void
    defaultValue: string | undefined
    tenantRequestId?: number
    rentPrice?: string
    securityDeposit?: string
    paymentPeriod?: string
    tenantInfo: Omit<ContactInfoData, "type"> | undefined
    onNext?: (signature: string) => void
}

export default function Signature({
    onSignatureSubmit,
    onBackClick,
    defaultValue,
    tenantRequestId,
    rentPrice,
    paymentPeriod,
    tenantInfo,
    securityDeposit,
    onNext,
}: SignatureProps) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: tenantRequests } = useUserTenantRequests()
    const { mutateAsync: onTenantSave, isPending: isTenantSaveLoading } = updateRentingPeriodWithContractMutation()
    const [isSignatureVisible, setIsSignatureVisible] = useState<boolean>(false)
    const { errorMessage } = useSelector((state) => state.appState)

    const generateUrl = useCallback(
        async (tenantSignature: string) => {
            if (tenantRequests == null) return
            return await ReactPDF.pdf(
                <Document>
                    <TenantLivePreview
                        rentPrice={rentPrice}
                        paymentPeriod={paymentPeriod}
                        securityDeposit={securityDeposit}
                        moveInDate={tenantRequests[0].moveInDate.toLocaleString(DateTime.DATE_SHORT)}
                        moveOutDate={tenantRequests[0].moveOutDate.toLocaleString(DateTime.DATE_SHORT)}
                        ownerInfo={tenantRequests[0].contractToBeSigned?.contactInfos.find((info) => info.type === ContactInfoType.Owner)}
                        litigation={tenantRequests[0].contractToBeSigned?.litigation}
                        partiesRequirements={tenantRequests[0].contractToBeSigned?.partiesRequirements}
                        tenantObligations={tenantRequests[0].contractToBeSigned?.tenantObligations}
                        ownerObligations={tenantRequests[0].contractToBeSigned?.ownerObligations}
                        tenantInfo={tenantInfo}
                        propertyData={tenantRequests[0].contractToBeSigned?.propertyData}
                        contactInfos={tenantRequests[0].contractToBeSigned?.contactInfos}
                        bankName={tenantRequests[0].bankName}
                        bankAccount={tenantRequests[0].bankName}
                        ownerSignature={
                            tenantRequests[0].contractToBeSigned?.signatures.find((s) => s.signatureType === SignatureType.Owner)?.signatureImage
                        }
                        tenantSignatures={[
                            ...(tenantRequests[0].contractToBeSigned?.signatures.filter((s) => s.signatureType === SignatureType.Tenant) ?? []),
                            { signatureType: SignatureType.Tenant, signatureImage: tenantSignature ?? "", name: tenantInfo?.fullName ?? "" },
                        ]}
                        hasGDPR={tenantRequests[0].contractToBeSigned?.hasGDPR}
                    />
                </Document>,
            ).toBlob()
        },
        [tenantRequests],
    )

    const onSubmit = useCallback(
        async (signature: string) => {
            if (tenantInfo == null) return
            onSignatureSubmit(signature)
            await onTenantSave({
                tenantId: tenantRequestId,
                tenantSignature: signature,
                tenantContactInfo: { ...tenantInfo, type: ContactInfoType.Tenant, contactInfoPersonType: ContactInfoPersonType.Individual },
                contract: await generateUrl(signature),
            })
        },
        [onSignatureSubmit, onTenantSave, tenantInfo],
    )

    return (
        <>
            {isMobile ? (
                <>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, alignItems: "center", marginTop: 2 }} flexDirection={"column"}>
                        {defaultValue && (
                            <Box marginTop={2} display={"flex"} flexDirection={"column"}>
                                <img alt={""} style={{ height: 75, width: 150 }} src={defaultValue} />
                                <Button color={"primary"} onClick={() => setIsSignatureVisible(true)}>
                                    {translate("try_again")}
                                </Button>
                            </Box>
                        )}
                        {errorMessage && (
                            <Box marginTop={2}>
                                <Alert severity={"error"}>{errorMessage}</Alert>
                            </Box>
                        )}

                        <Box marginTop={2}>
                            <Alert severity={"error"}>{translate("contract_can_not_be_signed")}</Alert>
                        </Box>

                        <LoadingButton
                            variant={"contained"}
                            color={"primary"}
                            loading={isTenantSaveLoading}
                            disabled={isTenantSaveLoading || isMobile}
                            onClick={() => (defaultValue ? onSubmit(defaultValue) : setIsSignatureVisible(true))}
                        >
                            {translate(defaultValue ? "accept_invitation" : "signContract")}
                        </LoadingButton>
                        <Button color={"primary"} onClick={onBackClick}>
                            {translate("back")}
                        </Button>
                    </Box>
                    <CustomDialog
                        open={isSignatureVisible}
                        isFullScreen={false}
                        title={translate("signContract")}
                        onClose={() => setIsSignatureVisible(false)}
                        titleAlign={"center"}
                    >
                        <SignatureTenantContent
                            onSave={onSubmit}
                            onClose={() => setIsSignatureVisible(false)}
                            onBack={() => {
                                setIsSignatureVisible(false)
                            }}
                            isLoading={isTenantSaveLoading}
                            onNext={onNext}
                            defaultValue={defaultValue}
                        />
                    </CustomDialog>
                </>
            ) : (
                <SignatureTenantContent onSave={onSubmit} onBack={onBackClick} isLoading={isTenantSaveLoading} />
            )}
        </>
    )
}
