import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { NotificationTemplateVariable } from "../queries/userData"
import * as jsonpatch from "fast-json-patch"
import { AddNewDocumentRequest } from "../sections/documents-templates/generalDocuments/AddOrEditGeneralDocument"
import { useSelector } from "../redux/store"

export const useAddNotificationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddNewDocumentRequest }) => Api.addNotificationTemplate(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.NotificationsTemplates])
            }
        },
    })
}

export const updateNotificationTemplateContentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ notificationId, content }: { notificationId: number; content: string | undefined }) =>
            Api.updateNotificationTemplateContent(notificationId, content),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.NotificationDetails])
            }
        },
    })
}
export const updateNotificationVariableMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ variable }: { variable: NotificationTemplateVariable }) =>
            Api.updateNotificationTemplateVariable(currentRentingPeriodId, variable),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.NotificationDetails, ComplexQueryIDs.RentingPeriodDetails])
            }
        },
    })
}

export const deleteNotificationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteNotification,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.NotificationsTemplates])
        },
    })
}

export const getPdfMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ body }: { body: string }) => Api.generatePdfRequest(body),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.NotificationsTemplates])
            }
        },
    })
}

export const updateRentingPeriodPaymentNotification = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    return useMutation({
        mutationFn: ({ notificationId }: { notificationId: number | null }) =>
            Api.updateRentingPeriodNotificationId(currentRentingPeriodId, notificationId),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.NotificationsTemplates, ComplexQueryIDs.RentingPeriodDetails])
            }
        },
    })
}

export const updateNotificationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ notificationId, operations }: { notificationId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateNotificationTemplate(notificationId, operations),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.NotificationDetails,
                    SimpleQueryIDs.NotificationsTemplates,
                    ComplexQueryIDs.RentingPeriodDetails,
                ])
            }
        },
    })
}
