import { AddProviderRequest } from "./types"
import { useAvailableExpenseProviders, useAvailableMeterProviders } from "../../../../../queries"
import { FormProvider, RHFCheckbox, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, CircularProgress, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useSelector } from "../../../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ReviewProviderSchema } from "../../../../../validations/ReviewProviderSchema"
import { LoadingButton } from "@mui/lab"
import { ProviderType } from "../../../../../redux/slices/AddProperty"
import { useMetersQueryFn } from "../../../../../queries/meters"

export interface ReviewProps {
    request: Partial<AddProviderRequest>
    onBack: () => void
    onNext: () => void
    isLoading: boolean
}

export interface ReviewForm {
    accepted: boolean
}

const Review = ({ request, onBack, onNext, isLoading }: ReviewProps) => {
    const isMobile = useIsMobile()
    const { data: expenseAvailableProviders } = useAvailableExpenseProviders()
    const { data: metersAvailableProviders } = useAvailableMeterProviders()
    const { data: meters } = useMetersQueryFn()
    const { editableProperties } = usePermissions(PermissionType.Properties)
    const { currentPropertyId, editingItem } = useSelector((state) => state.appState)
    const { translate } = useLocales()
    const availableProviders = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? expenseAvailableProviders : metersAvailableProviders),
        [editingItem, expenseAvailableProviders, metersAvailableProviders],
    )

    const ourProvider = useMemo(() => availableProviders?.find((p) => p.id === request?.providerId), [request, availableProviders])
    const currentProperty = useMemo(() => editableProperties?.find((p) => p.id === currentPropertyId), [editableProperties, currentPropertyId])
    const methods = useForm<ReviewForm>({
        resolver: yupResolver<ReviewForm>(ReviewProviderSchema),
        mode: "onChange",
        defaultValues: {
            accepted: false,
        },
    })
    const {
        handleSubmit,
        formState: { isValid },
    } = methods

    const innerOnSubmit = useCallback(() => {
        onNext()
    }, [onNext])

    if (availableProviders == null)
        return (
            <Box height={300} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Box display='flex' flexDirection='column' gap={2} pt={3}>
            <Alert severity='info'>
                <Box display='flex' flexDirection='row'>
                    <Typography>{translate("property") + ":"}&nbsp;</Typography>
                    <Typography>{currentProperty?.label}</Typography>
                </Box>
                <Box display='flex' flexDirection='row'>
                    <Typography>{translate("provider") + ":"}&nbsp;</Typography>
                    <Typography>{ourProvider?.name}</Typography>
                </Box>
                {editingItem?.providerType === ProviderType.Meter && (
                    <Box display='flex' flexDirection='row'>
                        <Typography>{translate("meter_name") + ":"}&nbsp;</Typography>
                        <Typography>{meters?.find((m) => m.id === editingItem?.id)?.name}</Typography>
                    </Box>
                )}
                {ourProvider?.requiredFields.map((f) => (
                    <Box key={f.name} display='flex' flexDirection='row'>
                        <Typography>{translate(f.label) + ":"}&nbsp;</Typography>
                        <Typography>{request?.fields![f.name] as string}</Typography>
                    </Box>
                ))}
            </Alert>
            <FormProvider methods={methods} onSubmit={handleSubmit(innerOnSubmit)}>
                <RHFCheckbox name={"accepted"} label={translate("gdpr_providers")} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <LoadingButton color={"primary"} onClick={onBack}>
                        {translate("previous")}
                    </LoadingButton>
                    <LoadingButton loading={isLoading} color={"primary"} type='submit' variant='contained' disabled={isLoading || !isValid}>
                        {translate("save")}
                    </LoadingButton>
                </Box>
            </FormProvider>
        </Box>
    )
}

export default Review
