import { PropertyIncomeJournalDetails, PropertyIncomeJournalDetailsDto } from "../../../../../../queries/expenses"
import { Box, Typography } from "@mui/material"
import { useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../../../../../queries/userData"
import { useCallback, useMemo } from "react"
import { fromIsoToDateTime } from "../../../../../../utils/dateMagic"
import { DateTime } from "luxon"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../../../queries/currency"
import { useIntl } from "react-intl"

interface IncomeCellRenderProps {
    data: PropertyIncomeJournalDetailsDto
}

const IncomeCellRender = ({ data }: IncomeCellRenderProps) => {
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const transformedIncome = useMemo((): PropertyIncomeJournalDetails => {
        return {
            ...data,
            date: fromIsoToDateTime(data.date),
        }
    }, [data])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    if (userProperties == null) return null

    return (
        <Box display='flex' flexDirection='column' gap={1} p={1}>
            <Box>
                <Typography>{translate("expenses.value")}</Typography>
                <Typography>{getAmount(transformedIncome.value, transformedIncome.currencyId)}</Typography>
            </Box>
            <Box>
                <Typography>{translate("expenses.date")}</Typography>
                <Typography>{transformedIncome.date.toLocaleString(DateTime.DATE_SHORT)}</Typography>
            </Box>
            <Box>
                <Typography>{translate("income_source")}</Typography>
                <Typography>{translate(`income-table.incomeType-${transformedIncome.type}`)}</Typography>
            </Box>
            <Box>
                <Typography>{translate("payment.status")}</Typography>
                <Typography>{translate(`payments-table.status-${transformedIncome.status}`)}</Typography>
            </Box>
            {transformedIncome.isAutoAccepted != null && (
                <Box>
                    <Typography>{translate("payment.auto_accepted")}</Typography>
                    <Typography>{translate(`${transformedIncome.isAutoAccepted ? "yes" : "no"}`)}</Typography>
                </Box>
            )}
            {transformedIncome.associatedExpenses != null && transformedIncome.associatedExpenses.length > 0 && (
                <Box>
                    <Typography>{translate("journal_income.associatedExpenses")}</Typography>
                    <Typography>{transformedIncome.associatedExpenses.map((i) => i.name).join(",")}</Typography>
                </Box>
            )}
        </Box>
    )
}

export default IncomeCellRender
