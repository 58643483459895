import { CircularProgress, Tab, Tabs, Theme } from "@mui/material"
import { Label, useIsMobile, useLocales } from "rentzz"
import { a11yProps } from "../../pages/PropertyDetails"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { IncomesFilteredOptions, incomesFilterState } from "../../utils/atom"
import { useAtom } from "jotai"
import { useTheme } from "@mui/material/styles"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"
import IncomesSummaryReport from "./IncomesSummaryReport"
import { getIncomesReportSummaryQueryFn } from "../../queries/income"

export const allIncomesFilterOptions = [
    IncomesFilteredOptions.Expenses,
    IncomesFilteredOptions.Damages,
    IncomesFilteredOptions.Rent,
    IncomesFilteredOptions.SecurityDeposit,
    IncomesFilteredOptions.Fees,
    IncomesFilteredOptions.AcceptedFromExternalSystem,
    IncomesFilteredOptions.Pending,
    IncomesFilteredOptions.NoAssignedExpenses,
]

export const getIncomeLabelColorByFilter = (filter: IncomesFilteredOptions, theme: Theme) => {
    switch (filter) {
        case IncomesFilteredOptions.All:
            return theme.palette.primary.main
        case IncomesFilteredOptions.Damages:
        case IncomesFilteredOptions.Rent:
        case IncomesFilteredOptions.SecurityDeposit:
        case IncomesFilteredOptions.Expenses:
        case IncomesFilteredOptions.AcceptedFromExternalSystem:
            return theme.palette.info.main
        case IncomesFilteredOptions.Pending:
        case IncomesFilteredOptions.NoAssignedExpenses:
        case IncomesFilteredOptions.MoreThanOneExpenseAssigned:
            return theme.palette.warning.main
        case IncomesFilteredOptions.Fees:
            return theme.palette.error.main
    }
}
export default function HeaderIncomes() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [page, setPage] = useState<IncomesFilteredOptions | undefined>(undefined)
    const [filterModel, setFilterModel] = useAtom(incomesFilterState)
    const { data: incomesSummaryStatus } = getIncomesReportSummaryQueryFn()
    const incomeOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)
    // const isMoreThanOneAssignedExpense = useFeatureValue<string | null>(UserFlags.MoreThanOneAssignedExpense, null)

    const theme = useTheme()

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: IncomesFilteredOptions) => {
            switch (newValue) {
                case IncomesFilteredOptions.All:
                    setFilterModel({ items: [] })
                    break
                case IncomesFilteredOptions.Expenses:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'Expense'" }] })
                    break
                case IncomesFilteredOptions.Damages:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'Damages'" }] })
                    break
                case IncomesFilteredOptions.Rent:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'Rent'" }] })
                    break
                case IncomesFilteredOptions.SecurityDeposit:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'SecurityDeposit'" }] })
                    break
                case IncomesFilteredOptions.Pending:
                    setFilterModel({ items: [{ field: "status", operator: "equals", value: "'Waiting'" }] })
                    break
                case IncomesFilteredOptions.NoAssignedExpenses:
                    setFilterModel({ items: [{ field: "associatedExpensesCount", operator: "eq", value: 0 }] })
                    break
                // case IncomesFilteredOptions.MoreThanOneExpenseAssigned:
                //     setFilterModel({ items: [{ field: "associatedExpensesCount", operator: "gt", value: 1 }] })
                //     break
                case IncomesFilteredOptions.AcceptedFromExternalSystem:
                    setFilterModel({ items: [{ field: "status", operator: "equals", value: "'AcceptedFromExternalSystem'" }] })
                    break
                case IncomesFilteredOptions.Fees:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'Fees'" }] })
                    break
            }
        },
        [setFilterModel],
    )

    // const customIncomesFilterOptions = useMemo(() => {
    //     const toReturn = [...allIncomesFilterOptions]
    //     if (isMoreThanOneAssignedExpense) {
    //         toReturn.push(IncomesFilteredOptions.MoreThanOneExpenseAssigned)
    //     }
    //     return toReturn
    // }, [isMoreThanOneAssignedExpense])

    useEffect(() => {
        if (filterModel.items?.length === 0) {
            setPage(IncomesFilteredOptions.All)
            return
        }

        const itemType = filterModel.items.find((i) => i.field === "type")
        if (itemType?.value === "'Expense'") setPage(IncomesFilteredOptions.Expenses)
        if (itemType?.value === "'Damages'") setPage(IncomesFilteredOptions.Damages)
        if (itemType?.value === "'Rent'") setPage(IncomesFilteredOptions.Rent)
        if (itemType?.value === "'SecurityDeposit'") setPage(IncomesFilteredOptions.SecurityDeposit)
        if (itemType?.value === "'Fees'") setPage(IncomesFilteredOptions.Fees)

        const itemStatus = filterModel.items.find((i) => i.field === "status")
        if (itemStatus?.value === "'Waiting'") setPage(IncomesFilteredOptions.Pending)
        if (itemStatus?.value === "'AcceptedFromExternalSystem'") setPage(IncomesFilteredOptions.AcceptedFromExternalSystem)

        const assignedItems = filterModel.items.find((i) => i.field === "associatedExpensesCount")
        if (assignedItems?.value === 0) setPage(IncomesFilteredOptions.NoAssignedExpenses)
        // if (assignedItems?.value === 1) setPage(IncomesFilteredOptions.MoreThanOneExpenseAssigned)
    }, [filterModel])

    const allCounts = useMemo(
        () =>
            incomesSummaryStatus
                ? incomesSummaryStatus[IncomesFilteredOptions.Expenses].count +
                  incomesSummaryStatus[IncomesFilteredOptions.Rent].count +
                  incomesSummaryStatus[IncomesFilteredOptions.SecurityDeposit].count +
                  incomesSummaryStatus[IncomesFilteredOptions.Damages].count +
                  incomesSummaryStatus[IncomesFilteredOptions.Fees].count
                : 0,
        [incomesSummaryStatus],
    )

    const customIncomesFilterOptions = useMemo(() => {
        return [IncomesFilteredOptions.All, ...allIncomesFilterOptions]
    }, [])

    if (page == null) return <CircularProgress size='1rem' />

    return (
        <>
            {!isMobile && <IncomesSummaryReport />}
            <Tabs value={page} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                {customIncomesFilterOptions.map((f, index) => (
                    <Tab
                        key={f}
                        value={f}
                        label={translate(`income_filter_options${incomeOverrideHeader ? "-" + incomeOverrideHeader : ""}-${f}`)}
                        icon={
                            <Label sx={{ mr: 1, color: "white", backgroundColor: getIncomeLabelColorByFilter(f, theme) }}>
                                {incomesSummaryStatus ? (f === IncomesFilteredOptions.All ? allCounts : incomesSummaryStatus[f].count) : "-"}
                            </Label>
                        }
                        style={{
                            textTransform: "none",
                        }}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
        </>
    )
}
