import { Box, CircularProgress, InputAdornment, MenuItem, Typography } from "@mui/material"
import { Iconify, LightTooltip, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { useCurrencyQueryFn } from "../../../../../queries/currency"
import { useCompanySeries } from "../../../../../queries/invoicing-configuration"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "../../../../../redux/store"

export default function InvoicingForRent() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: allCurrencies } = useCurrencyQueryFn()
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { data: allUserSeries } = useCompanySeries(rentingPeriodDetails?.invoicingCompanies.at(0)?.id)

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                <RHFSelect
                    name={"invoicingSeries"}
                    size='small'
                    variant={"outlined"}
                    label={translate("invoicing_series")}
                    disabled={!canWrite || allUserSeries == null || allUserSeries.length === 0}
                    fullWidth
                    required
                >
                    {allUserSeries ? (
                        allUserSeries.map((c) => (
                            <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                {c.name}
                            </MenuItem>
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </RHFSelect>

                <RHFSelect
                    name={"invoicingCurrencyId"}
                    size='small'
                    variant={"outlined"}
                    label={translate("invoicing_currency")}
                    disabled={!canWrite}
                    fullWidth
                    required
                >
                    {allCurrencies ? (
                        allCurrencies.map((c) => (
                            <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                {c.code}
                            </MenuItem>
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </RHFSelect>

                <RHFTextField
                    name={"invoiceMaxDays"}
                    size={"small"}
                    label={translate("invoice_max_days")}
                    type={"number"}
                    fullWidth
                    InputProps={{
                        inputProps: { min: 0 },
                    }}
                    required
                    disabled={!canWrite}
                />
            </Box>
            <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                <RHFTextField
                    name={"defaultInvoicingText"}
                    label={translate("invoicing_text")}
                    size='small'
                    disabled={!canWrite}
                    fullWidth
                    required
                />

                <RHFTextField
                    required
                    name={"defaultInvoicingTemplate"}
                    label={translate("invoicing_template")}
                    size='small'
                    disabled={!canWrite}
                    fullWidth
                />

                <RHFTextField
                    name={"invoicingPeriodStart"}
                    label={translate("invoicingPeriodStart")}
                    placeholder={"1"}
                    size='small'
                    type={"number"}
                    disabled={!canWrite}
                    required
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <LightTooltip
                                    title={
                                        <Box display={"flex"} flexDirection={"column"}>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipInfo")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("forExample")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-1")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-2")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-3")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-4")}
                                            </Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                </LightTooltip>
                            </InputAdornment>
                        ),
                        inputProps: { min: 0, max: 31 },
                    }}
                />

                <RHFTextField
                    required
                    name={"invoicingPeriodEnd"}
                    label={translate("invoicingPeriodEnd")}
                    placeholder={"31"}
                    size='small'
                    type={"number"}
                    disabled={!canWrite}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"}>
                                <LightTooltip
                                    title={
                                        <Box display={"flex"} flexDirection={"column"}>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipInfo")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("forExample")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-1")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-2")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-3")}
                                            </Typography>
                                            <Typography variant={"body2"} textAlign={"start"}>
                                                {translate("invoicingPeriodTooltipExample-4")}
                                            </Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                </LightTooltip>
                            </InputAdornment>
                        ),
                        inputProps: { min: 0, max: 31, type: "number" },
                    }}
                />
            </Box>
        </Box>
    )
}
