import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import UploadBankStatementForm from "../../guards/alertsGuard/user/nomenclature/bankAccounts/UploadBankStatementForm"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { useDispatch } from "react-redux"
import { AddBankStatementRequest, useAddBankStatementMutation } from "../../mutations/bankAccounts"
import { useSelector } from "../../redux/store"

export default function UploadBankStatement() {
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: addBankAccountStatement, isPending } = useAddBankStatementMutation()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onSave = useCallback(
        async (data: AddBankStatementRequest) => {
            await addBankAccountStatement({ data })
            // navigate("/document-templates/general-documents")
            closeModal()
        },
        [addBankAccountStatement, navigate, closeModal],
    )

    return (
        <>
            <CustomDialog
                title={translate("upload_file")}
                open={!isMobile && modalOpen === AppModals.UploadBankAccountStatement}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <UploadBankStatementForm onClose={closeModal} onSave={onSave} isLoading={isPending} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("upload_file")}
                isOpen={isMobile && modalOpen === AppModals.UploadBankAccountStatement}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <UploadBankStatementForm onClose={closeModal} onSave={onSave} isLoading={isPending} />
            </DetailsSheet>
        </>
    )
}
