import { useSelector } from "../../redux/store"
import { AppContext } from "rentzz"
import OwnerTasks from "./OwnerTasks"
import TenantTasks from "./TenantTasks"

export default function Tasks() {
    const { context } = useSelector((state) => state.appState)

    if (context === AppContext.Owner) return <OwnerTasks />
    return <TenantTasks />
}
