import React, { useCallback, useMemo } from "react"
import { PropertyFileType } from "../../../redux/slices/AddProperty"
import { AppContext, DetailsSheet, ExpenseAssignee, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store"
import { UserFlags, useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserLabelsQuery, useUserPropertiesQuery } from "../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { DateTime } from "luxon"
import { renderActionSheetInfo } from "../utils"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteIcon from "@mui/icons-material/Delete"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { BalanceBehavior, useSingleExpenseQuery } from "../../../queries/expenses"
import { ExpensePaymentStatus, getAssigneeLabelColor, getExpensePaymentStatus } from "../../../sections/expenses/utils"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import { sumBy } from "lodash"
import CustomLinearProgress from "../../../components/CustomLinearProgress"
import PaymentIcon from "@mui/icons-material/Payment"
import AddCardIcon from "@mui/icons-material/AddCard"

interface ModalProps {
    onClose: () => void
}
export default function ExpenseDetailsSheet({ onClose }: ModalProps) {
    const intl = useIntl()
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)
    const { data: currencyData, isFetching: isCurrencyDataLoading } = useCurrencyQueryFn()
    const { data: labels, isFetching: areLabelsLoading } = useUserLabelsQuery()
    const { context, editingItem, modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: userData, isFetching: isUserDataLoading } = useUserDataQueryFn()
    const { data: properties, isFetching: arePropertiesLoading } = useUserPropertiesQuery()
    const { data: currentExpense, isFetching: isCurrentExpenseLoading } = useSingleExpenseQuery(
        editingItem?.isFromAnIncome ? editingItem?.expenseId : editingItem?.id,
    )
    const percent = useFeatureValue(UserFlags.PercentExpensePayment, 0)
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const currentTenantRentingPeriod = useMemo(
        () => tenantRentingPeriods?.find((t) => t.rentingPeriodId === currentRentingPeriodId),
        [tenantRentingPeriods, currentRentingPeriodId],
    )

    const paidAmount = useMemo(() => sumBy(currentExpense?.propertyIncomes.map((income) => income.valueInExpenseCurrency)), [currentExpense])
    const isPaymentOnlineEnabled = useFeatureIsOn(UserFlags.ExpenseOnlinePayment.toString())

    const markAsPaid = useCallback(() => {
        if (context === AppContext.Owner) {
            dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsOwner))
        } else dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
    }, [dispatch, context])

    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [userData],
    )

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentExpense == null) return []

        if (context == AppContext.Tenant) {
            return [
                {
                    icon: <AddCardIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "payment.addPayment",

                    onClick: () => {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                backgroundColor: editingItem.backgroundColor,
                            }),
                        )
                        if (isPaymentOnlineEnabled) {
                            dispatch(
                                setEditingItem({
                                    ...editingItem,
                                    choosePayment: true,
                                }),
                            )
                            dispatch(setModalOpen(AppModals.TenantPayment))
                        } else {
                            dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
                        }
                    },

                    hidden:
                        (!currentTenantRentingPeriod?.acceptPartialPayments && currentExpense.propertyIncomesCount >= 1) || currentExpense.value <= 0,
                },
                {
                    icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "see_payments",
                    hidden: currentExpense.propertyIncomesCount === 0,
                    onClick: () => {
                        dispatch(setModalOpen(AppModals.AssignedIncomes))
                    },
                },
                {
                    icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "see_files",
                    onClick: () => {
                        dispatch(
                            setEditingItem({
                                backgroundColor: editingItem.backgroundColor,
                                id: currentExpense.id,
                                type: PropertyFileType.Expense,
                                name: currentExpense.name,
                                assignee: currentExpense.assignee,
                                canWrite: currentExpense.canWrite,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.EntityFileManager))
                    },
                },
            ]
        }

        return [
            {
                icon:
                    currentExpense.expenseBalanceBehavior === BalanceBehavior.RealValue ? (
                        <AttachMoneyIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"primary"} />
                    ) : (
                        <MoneyOffIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"inherit"} />
                    ),
                title: "expense_status_title",
                hidden: editingItem.isFromAnIncome || !currentExpense.canWrite || currentExpense.value >= 0,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.UpdateExpenseBalanceStatus))
                },
            },
            {
                icon: <PaymentIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "payment.addPayment",
                hidden: editingItem?.isFromAnIncome,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsOwner))
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            backgroundColor: editingItem.backgroundColor,
                        }),
                    )
                },
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_income",
                hidden: editingItem.isFromAnIncome || currentExpense.propertyIncomesCount === 0,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AssignedIncomes))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !currentExpense.canWrite || editingItem?.isFromAnIncome,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditExpense))
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: editingItem?.isFromAnIncome ? editingItem?.id : currentExpense.id,
                            type: PropertyFileType.Expense,
                            name: currentExpense.name,
                            assignee: currentExpense.assignee,
                            canWrite: currentExpense.canWrite,
                            isFromAnIncome: editingItem?.isFromAnIncome,
                            expenseId: editingItem?.expenseId,
                            backgroundColor: editingItem.backgroundColor,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentExpense.canDelete || editingItem?.isFromAnIncome,
                isDisabled: currentExpense.propertyIncomes?.map((income) => income.id).includes(currentExpense.id),
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteExpense))
                },
            },
        ]
    }, [currentExpense, dispatch, markAsPaid, context, editingItem, currentTenantRentingPeriod])

    const expensePaymentStatus = useMemo(() => {
        if (currentExpense == null) return
        return getExpensePaymentStatus(currentExpense.value, paidAmount, percent)
    }, [currentExpense, percent, paidAmount])

    const paymentStatusTitle = useMemo(() => {
        if (currentExpense == null) return
        if (currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.NotPaid)
            return translate("expense_was_partially_paid")
        if (currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.Overpaid)
            return translate("expense_overpaid", { value: getAmount(paidAmount - currentExpense.value, currentExpense.currencyId) })
        if (currentExpense && currentExpense.value <= 0) return translate("paid_expense")
        if (currentExpense?.propertyIncomes.length === 0) return translate(context === AppContext.Owner ? "has_no_incomes" : "has_no_payments")
        return translate(context === AppContext.Owner ? "expense_was_paid" : "has_payments")
    }, [currentExpense, translate, context, expensePaymentStatus])

    const headerColor = useMemo(() => {
        if (currentExpense == null) return

        if (currentExpense.assignee === ExpenseAssignee.ME) return theme.palette.primary.main
        if (currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.NotPaid) return theme.palette.warning.main
        if (currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.Overpaid) return theme.palette.error.main
        if (currentExpense.propertyIncomes.length > 0 || (currentExpense && currentExpense.value <= 0)) return theme.palette.success.main
        if (
            currentExpense.propertyIncomesCount === 0 &&
            currentExpense.value > 0 &&
            context === AppContext.Tenant &&
            currentExpense.dueDate > DateTime.now()
        )
            return theme.palette.warning.main
        return editingItem?.backgroundColor
    }, [currentExpense, editingItem, context, theme, expensePaymentStatus])

    const icon = useMemo(() => {
        if (currentExpense && currentExpense.propertyIncomes.length > 0 && expensePaymentStatus !== ExpensePaymentStatus.Paid)
            return "mdi:progress-check"
        if ((currentExpense && currentExpense.propertyIncomesCount > 0) || (currentExpense && currentExpense.value <= 0)) return "mdi:check-circle"
        return "gravity-ui:circle-exclamation-fill"
    }, [currentExpense])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ExpenseDetails}
            onClose={onClose}
            isLoading={isCurrencyDataLoading || isUserDataLoading || isCurrentExpenseLoading || areLabelsLoading || arePropertiesLoading}
            title={currentExpense?.name}
            options={options}
            subtitle={currentExpense?.providerName}
            headerColor={headerColor}
        >
            <Box p={1}>
                {(currentExpense?.assignee === ExpenseAssignee.TENANT || context === AppContext.Tenant) && (
                    <CustomLinearProgress
                        value={(paidAmount / (currentExpense ? currentExpense.value : 0)) * 100}
                        minValue={paidAmount}
                        maxValue={getAmount(currentExpense ? currentExpense.value : 0, currentExpense?.currencyId)}
                        isPaid={paidAmount === currentExpense?.value}
                    />
                )}

                {(currentExpense?.assignee === ExpenseAssignee.TENANT || context === AppContext.Tenant) &&
                    renderActionSheetInfo(icon, paymentStatusTitle, headerColor, theme.palette.grey["600"])}
                {currentExpense?.isAutomaticExpense &&
                    context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:settings",
                        `${translate("is_automatic_expense")}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {renderActionSheetInfo(
                    "mdi:cash",
                    `${getAmount(currentExpense?.value ?? 0, currentExpense?.currencyId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("createdAt")}: ${currentExpense?.date?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-clock",
                    `${translate("due_date")}: ${currentExpense?.dueDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {context === AppContext.Owner &&
                    currentExpense?.labelId &&
                    renderActionSheetInfo(
                        "mdi:label",
                        labels?.find((label) => label.id === currentExpense.labelId)?.value,
                        `#${getLabelColor(currentExpense.labelId)}`,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    currentExpense &&
                    renderActionSheetInfo(
                        "mdi:tag-multiple",
                        `${context === AppContext.Owner ? translate(`expenses.assignee-${currentExpense.assignee}${customPaidBy ? "-" + customPaidBy : ""}`) : ""}`,
                        currentExpense.assignee === ExpenseAssignee.ME ? theme.palette.secondary.main : theme.palette.primary.main,
                        theme.palette.grey["600"],
                        true,
                        "",
                        currentExpense ? getAssigneeLabelColor(currentExpense.assignee) : "default",
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:map-marker",
                        properties?.find((p) => p.id === currentExpense?.propertyId)?.label,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
