import { array, object, string } from "yup"

export const AddPropertyFacilitiesSchema = object().shape({
    utilities: array().optional(),
    features: array().optional(),
})

export const AddPropertyOwnerDetailsSchema = object().shape(
    {
        firstName: string().required("required").min(3, "min_3_characters"),
        lastName: string().required("required").min(3, "min_3_characters"),
        email: string().when("phoneNumber", ([phone], schema) => {
            const phoneRegex = /^\+40\d{9}$/
            if (phone == null || phone.length === 0 || !phoneRegex.test(phone)) {
                return schema.email("must_be_email").required("one_of_required")
            }

            return schema.optional()
        }),
        phoneNumber: string().when("email", ([email], schema) => {
            const mailRegex = /^\S+@\S+\.\S+$/
            if (email == null || email.length === 0 || !mailRegex.test(email)) {
                return schema.matches(/^\+40\d{9}$/, "Phone number is not correct").required("one_of_required")
            }

            return schema.optional()
        }),
    },
    [["email", "phoneNumber"]],
)
