import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import AddProviderForm from "../../../../sections/propertyDetails/configurations/providers/addProvider/AddProviderForm"
import { useTheme } from "@mui/material/styles"

export default function AddExpenseProviderGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [])

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.AddExpenseProvider}
                title={translate("add_provider")}
                titleAlign={"center"}
                onClose={onClose}
                showCloseIcon={true}
                maxWidth={"sm"}
                isFullScreen={false}
            >
                <AddProviderForm onClose={onClose} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.AddExpenseProvider}
                onClose={onClose}
                isLoading={false}
                title={translate("add_provider")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddProviderForm onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
