import { Box, CircularProgress } from "@mui/material"
import { RHFSwitch, useIsMobile, useLocales, varFade } from "rentzz"
import React, { useMemo } from "react"
import { AppNotifications } from "../../../../../../redux/slices/AddProperty"
import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import { useTheme } from "@mui/material/styles"
import { m } from "framer-motion"
import { useSelector } from "../../../../../../redux/store"

export const allPossibleNotifications = [
    AppNotifications.DocumentAddedByOwnerNotification,
    AppNotifications.ExpenseCreatedAssignedToTenant,
    AppNotifications.ExpenseDeletedAssignedToTenant,
    AppNotifications.MeterReadingPeriodStarted,
    AppNotifications.MeterReadingPeriodEnding,
    AppNotifications.RentingPeriodPaymentPeriodStarted,
    AppNotifications.RentingPeriodPaymentPeriodEnding,
    AppNotifications.IncomeWithExpenseAddedByOwner,
    AppNotifications.ExpenseDueDateNotification,
    AppNotifications.ExpenseOverdueNotification,
    AppNotifications.TaskCreated,
    AppNotifications.TaskDeleted,
    AppNotifications.NewTaskComment,
    AppNotifications.TaskStatusChanged,
    AppNotifications.TenantMonthlyReport,
]

interface Props {
    expanded?: boolean
}
export default function NotificationsForm({ expanded }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails, isLoading } = useRentingPeriodDetails(currentRentingPeriodId)

    const notifications = useMemo(() => {
        if (isMobile && expanded) return allPossibleNotifications.slice(5)
        return allPossibleNotifications
    }, [isMobile, expanded, allPossibleNotifications])

    return (
        <>
            {isLoading ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                    <CircularProgress size={"1.5rem"} />
                </Box>
            ) : (
                <>
                    {isMobile &&
                        notifications.map((notification) => (
                            <RHFSwitch
                                disabled={(rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0) || !rentingPeriodDetails?.canWrite}
                                key={notification}
                                name={notification.toString()}
                                label={translate(`notification.${notification}`)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    justifyContent: "space-between",
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    color: theme.palette.primary.dark,
                                    ".MuiTypography-root ": {
                                        fontSize: 13,
                                        fontWeight: 800,
                                    },
                                }}
                            />
                        ))}

                    <m.div variants={varFade().in} style={{ display: "flex", gap: 2, flexDirection: "column" }}>
                        {notifications.map((notification) => (
                            <RHFSwitch
                                key={notification}
                                disabled={(rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0) || !rentingPeriodDetails?.canWrite}
                                name={notification.toString()}
                                label={translate(`notification.${notification}`)}
                                sx={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    justifyContent: "space-between",
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    color: theme.palette.primary.dark,
                                    ".MuiTypography-root ": {
                                        fontSize: 13,
                                        fontWeight: 800,
                                    },
                                }}
                            />
                        ))}
                    </m.div>
                </>
            )}
        </>
    )
}
