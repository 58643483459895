import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useTheme } from "@mui/material/styles"
import { getMailNotificationColor, getUserAcceptanceAccountColor } from "./utils"
import { RentingPeriodMailNotificationStatus, RentingPeriodUserAcceptanceStatus } from "../../../redux/slices/AddProperty"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import AcceptOnTenantBehalfForm from "./rentingPeriodDetails/general/tenants/AcceptOnTenantBehalfForm"

export default function AcceptOnTenantBehalf() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    const currentTenant = useMemo(
        () => rentingPeriodDetails?.rentingPeriodTenants.find((tenant) => tenant.mail === editingItem?.mail),
        [rentingPeriodDetails, editingItem],
    )

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.TenantDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [isMobile, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("accept-on-tenant-behalf")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.AcceptOnTenantBehalf}
                maxWidth='xs'
            >
                <AcceptOnTenantBehalfForm onClose={onClose} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("accept-on-tenant-behalf")}
                isOpen={isMobile && modalOpen === AppModals.AcceptOnTenantBehalf}
                onClose={onClose}
                headerColor={
                    currentTenant?.name === "-"
                        ? getMailNotificationColor(currentTenant?.status as RentingPeriodMailNotificationStatus, theme)
                        : getUserAcceptanceAccountColor(currentTenant?.status as RentingPeriodUserAcceptanceStatus, theme)
                }
            >
                <AcceptOnTenantBehalfForm onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
