import { Text, View } from "@react-pdf/renderer"
import React from "react"
import { useLocales } from "rentzz"
import useStyles from "./useStyles"

interface RentDetailsData {
    moveInDate?: string
    moveOutDate?: string
    rentPrice?: string
    paymentPeriod?: string
    securityDeposit?: string
    bankAccount?: string | null
    bankName?: string
}

export default function PropertyInfoData({
    rentPrice,
    moveInDate,
    moveOutDate,
    paymentPeriod,
    securityDeposit,
    bankName,
    bankAccount,
}: RentDetailsData) {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <>
            <View style={styles.mainText}>
                <Text>
                    <Text style={styles.boldFont}>{translate("art", { number: 2 })}</Text> {translate("rental_property_is_not")}
                </Text>
                <Text>
                    <Text style={styles.boldFont}>{translate("art", { number: 3 })}</Text>
                    {translate("owner_rent_tenant_the_property")}
                    <Text style={styles.boldFont}>{moveInDate || "__________"}.</Text>
                </Text>
            </View>
            <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                <Text>{translate("rent_price_and_payment_method")}</Text>
            </View>
            <View style={styles.mainText}>
                <Text>
                    <Text style={styles.boldFont}>{translate("art", { number: 4 })}</Text> {translate("the_rent_is")}
                    <Text style={styles.boldFont}>{rentPrice || "__________________________"}</Text>
                </Text>
                <Text>{translate("rent_payment_method")}</Text>
                <Text style={styles.bullet}>{translate("how_the_rent_is_paid")}</Text>
                <Text style={styles.bullet}>
                    {translate("when_the_rent_is_paid")}
                    <Text style={{ ...styles.boldFont }}>{paymentPeriod || "__________"}</Text>
                    <Text>{translate("of")}</Text>
                </Text>
                {bankAccount && bankAccount?.length > 0 && (
                    <Text style={styles.bullet}>
                        {translate("rent_payment_is_made_in")}
                        <Text style={{ ...styles.boldFont }}>{bankAccount}</Text>
                        <Text>{translate("the_account_is_opened")}</Text>
                        <Text style={{ ...styles.boldFont }}>{bankName}</Text>
                    </Text>
                )}
                {securityDeposit && securityDeposit?.length > 0 && (
                    <Text style={styles.bullet}>
                        {translate("security_deposit")}
                        <Text style={styles.boldFont}>{securityDeposit}</Text>
                        <Text>{translate("security_deposit_end")}</Text>
                    </Text>
                )}
            </View>
            <View style={{ ...styles.partiesTitle, ...styles.boldFont }}>
                <Text>{translate("rental_period")}</Text>
            </View>
            <View style={styles.mainText}>
                <Text>
                    <Text style={styles.boldFont}>{translate("art", { number: 5 })}</Text> {translate("rental_agreement_final_data_review")}
                    <Text style={{ ...styles.boldFont }}>{moveInDate || "____________"}</Text>
                    <Text>{translate("until_date")}</Text>
                    <Text style={{ ...styles.boldFont }}>{moveOutDate || "____________"}.</Text>
                </Text>
                <Text>
                    <Text style={styles.boldFont}>{translate("art", { number: 6 })}</Text> {translate("final_terms")}
                </Text>
            </View>
        </>
    )
}
