import { Text, View } from "@react-pdf/renderer"
import React from "react"
import { useStyles } from "./useStyles"
import { useLocales } from "rentzz"

interface ObligationsProps {
    obligations: string[] | undefined
    isFocused: boolean
    countStart: number
}

const Obligations = ({ obligations, isFocused, countStart }: ObligationsProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <View style={isFocused ? styles.focused : undefined}>
            {obligations?.map((o, index) => (
                <View key={o}>
                    <Text wrap break style={styles.mainText}>
                        <Text style={styles.boldFont}>{translate("art", { number: countStart + index + 1 })}</Text>
                        {o}
                    </Text>
                </View>
            ))}
        </View>
    )
}

export default Obligations
