import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { DetailsSheet, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import ProviderSendAutomaticallyForm from "./ProviderSendAutomaticallyForm"

export default function ProviderSendAutomaticallyToTenant() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("sendAutomaticallyToTenant")}
            isOpen={modalOpen === AppModals.HandleProviderAutomaticSendToTenant}
            onClose={() => {
                dispatch(setModalOpen(AppModals.ProviderDetails))
            }}
            headerColor={editingItem?.backgroundColor}
        >
            <ProviderSendAutomaticallyForm providerId={editingItem?.id} onClose={() => dispatch(setModalOpen(AppModals.ProviderDetails))} />
        </DetailsSheet>
    )
}
