import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { Box, Button } from "@mui/material"
import { setModalOpen } from "../../../redux/slices/App"
import { useDispatch } from "../../../redux/store"
import { AddPropertyPage, setActiveStep, setLabelId, setPropertyName } from "../../../redux/slices/AddProperty"
import { PropertyNameSchema } from "../../../validations/propertyName"
import RHFLabelInput from "../../../components/RHFLabelInput"

type AddPropertyNameRequest = {
    propertyName: string
    labelId?: number
}

export default function AddPropertyName() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    const methods = useForm<AddPropertyNameRequest>({
        resolver: yupResolver(PropertyNameSchema),
        mode: "onBlur",
        defaultValues: {
            propertyName: "",
            labelId: undefined,
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddPropertyNameRequest) => {
            dispatch(setPropertyName(data.propertyName))
            dispatch(setLabelId(data.labelId))
            dispatch(setActiveStep(AddPropertyPage.Details))
        },
        [dispatch],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
                <Box display='flex' marginY={1} marginX={5}>
                    <RHFTextField name='propertyName' label={translate("property_name")} required />
                </Box>
                <Box display='flex' marginX={5}>
                    <RHFLabelInput name={"labelId"} />
                </Box>
                <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' alignItems='center' gap={2}>
                    <Button fullWidth={isMobile} size='medium' color='primary' onClick={() => dispatch(setModalOpen(undefined))}>
                        {translate("cancel")}
                    </Button>
                    <Button fullWidth={isMobile} type='submit' size='medium' variant='contained' color={"primary"}>
                        {translate("next")}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}
