import { FormProvider, RHFAutocomplete, RHFCheckbox, RHFSelect, RHFTextField, useIsMobile, useLocales, useResponsive } from "rentzz"
import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddTenantPropertyInfoSchema } from "../../../../validations/addTenantPropertyInfoSchema"
import { useCitiesQueryFn, useCountiesQueryFn, usePropertyDetailsQueryFn } from "../../../../queries"
import { availableStories } from "../../../../config"
import { useUserContractTemplatesQuery } from "../../../../queries/userData"

export interface AddTenantPropertyInfo {
    county: string
    city: string
    street?: string
    streetNumber?: string
    buildingNumber?: string
    stair?: string
    floor?: number
    apartment?: string
    savePropertyInfo: boolean
}

interface PropertyInfoProps {
    contractId: string
    propertyId: number
    onNext: (data: AddTenantPropertyInfo) => void
    onBack: () => void
    defaultValues?: AddTenantPropertyInfo
}

export default function PropertyInfo({ contractId, propertyId, onNext, onBack, defaultValues }: PropertyInfoProps) {
    const { translate } = useLocales()
    const mdUp = useResponsive("up", "md")
    const isMobile = useIsMobile()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const { data: propertyData } = usePropertyDetailsQueryFn(propertyId)
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)
    const { data: userContracts } = useUserContractTemplatesQuery(false)
    const currentContract = userContracts?.find((contract) => contract.id === contractId)

    const methods = useForm<AddTenantPropertyInfo>({
        resolver: yupResolver(AddTenantPropertyInfoSchema(currentContract?.propertyDataRequiredInfo)),
        mode: "onChange",
    })

    const { handleSubmit, watch, setValue, reset, trigger } = methods

    useEffect(() => {
        if (propertyData == null) return
        if (defaultValues) reset(defaultValues)
        else {
            if (propertyData.county) {
                setValue("county", propertyData.county, { shouldValidate: true })
                setCounty(propertyData.county)
            }
            if (propertyData.city) setValue("city", propertyData.city, { shouldValidate: true })
            if (propertyData.street) setValue("street", propertyData.street, { shouldValidate: true })
            if (propertyData.streetNumber) setValue("streetNumber", propertyData.streetNumber, { shouldValidate: true })
            if (propertyData.buildingNumber) setValue("buildingNumber", propertyData.buildingNumber, { shouldValidate: true })
            if (propertyData.stair) setValue("stair", propertyData.stair, { shouldValidate: true })
            if (propertyData.floor != null) setValue("floor", propertyData.floor, { shouldValidate: true })
            if (propertyData.apartment) setValue("apartment", propertyData.apartment, { shouldValidate: true })
            setValue("savePropertyInfo", true)
        }
    }, [propertyData, setValue, setCounty, defaultValues])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "county") {
                setCounty(allCounties?.find((c) => c.name === v.county)?.name)
                setValue("city", "")
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, allCounties])

    const onSubmit = useCallback(
        (data: AddTenantPropertyInfo) => {
            onNext(data)
        },
        [onNext],
    )

    const getWidth2 = useCallback(() => {
        if (
            (currentContract?.propertyDataRequiredInfo.isStairRequired &&
                !currentContract?.propertyDataRequiredInfo.isFloorRequired &&
                !currentContract?.propertyDataRequiredInfo.isApartmentRequired) ||
            (!currentContract?.propertyDataRequiredInfo.isStairRequired &&
                currentContract?.propertyDataRequiredInfo.isFloorRequired &&
                !currentContract?.propertyDataRequiredInfo.isApartmentRequired) ||
            (!currentContract?.propertyDataRequiredInfo.isStairRequired &&
                !currentContract?.propertyDataRequiredInfo.isFloorRequired &&
                currentContract?.propertyDataRequiredInfo.isApartmentRequired)
        )
            return "50%"
        return "100%"
    }, [currentContract])

    const getWidth1 = useCallback(() => {
        if (
            (!currentContract?.propertyDataRequiredInfo.isStreetRequired &&
                !currentContract?.propertyDataRequiredInfo.isStreetNumberRequired &&
                currentContract?.propertyDataRequiredInfo.isBuildingNumberRequired) ||
            (currentContract?.propertyDataRequiredInfo.isStreetRequired &&
                !currentContract?.propertyDataRequiredInfo.isStreetNumberRequired &&
                !currentContract?.propertyDataRequiredInfo.isBuildingNumberRequired) ||
            (!currentContract?.propertyDataRequiredInfo.isStreetRequired &&
                currentContract?.propertyDataRequiredInfo.isStreetNumberRequired &&
                !currentContract?.propertyDataRequiredInfo.isBuildingNumberRequired)
        )
            return "50%"
        return "100%"
    }, [currentContract])

    const renderFirstDetails = useCallback(
        () => (
            <Box
                display='flex'
                flexDirection={isMobile ? "column" : "row"}
                width={isMobile ? "100%" : getWidth1()}
                gap={2}
                paddingRight={getWidth1() === "50%" ? 1 : 0}
            >
                {currentContract?.propertyDataRequiredInfo.isStreetRequired && <RHFTextField name='street' label={translate("street")} required />}
                {currentContract?.propertyDataRequiredInfo.isStreetNumberRequired && (
                    <RHFTextField name='streetNumber' label={translate("number")} required />
                )}
                {currentContract?.propertyDataRequiredInfo.isBuildingNumberRequired && (
                    <RHFTextField name='buildingNumber' label={translate("buildingNumber")} required />
                )}
            </Box>
        ),
        [currentContract, translate, getWidth1, isMobile],
    )

    const renderSecondDetails = useCallback(
        () => (
            <Box
                display='flex'
                flexDirection={isMobile ? "column" : "row"}
                width={isMobile ? "100%" : getWidth2()}
                gap={2}
                paddingRight={!(getWidth2() === "50%" && isMobile) ? 0 : 1}
            >
                {currentContract?.propertyDataRequiredInfo.isStairRequired && <RHFTextField name='stair' label={translate("stair")} required />}
                {currentContract?.propertyDataRequiredInfo.isFloorRequired && (
                    <RHFSelect name='floor' label={translate("floor")} required>
                        {availableStories.map((option) => (
                            <MenuItem key={option.value} value={option.value} style={{ textTransform: "none" }}>
                                {option.value === 0 ? translate("ground") : translate("floor_with_number", { floor: option.value })}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                )}
                {currentContract?.propertyDataRequiredInfo.isApartmentRequired && (
                    <RHFTextField name='apartment' label={translate("apartment")} required />
                )}
            </Box>
        ),
        [currentContract, isMobile, getWidth2],
    )

    if (propertyId == null || propertyData == null)
        return (
            <Box display={"flex"} justifyContent={"center"} height={200}>
                <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
            </Box>
        )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' width='100%' marginTop={4} flexDirection={"column"} rowGap={2}>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} width='100%' gap={2}>
                    {currentContract?.propertyDataRequiredInfo.isCountyRequired && (
                        <RHFAutocomplete
                            name={"county"}
                            required
                            options={allCounties?.map((c) => c.name) ?? []}
                            label={translate("county")}
                            loading={allCountiesFetching}
                            autocompleteProps={{
                                sx: { minWidth: 200, maxWidth: mdUp ? "50%" : "100%" },
                                noOptionsText: translate("no_value_found"),
                            }}
                            freeSolo={false}
                        />
                    )}

                    {currentContract?.propertyDataRequiredInfo.isCityRequired && (
                        <RHFAutocomplete
                            textFieldProps={{
                                disabled: allCities?.length === 0,
                            }}
                            required
                            name={"city"}
                            options={allCities?.map((c) => c.name) ?? []}
                            loading={allCitiesFetching}
                            label={translate("city")}
                            autocompleteProps={{
                                disabled: allCities == null || allCities.length === 0,
                                sx: { minWidth: 200, maxWidth: mdUp ? "50%" : "100%" },
                                noOptionsText: translate("no_value_found"),
                            }}
                            freeSolo={false}
                        />
                    )}
                </Box>

                {getWidth1() === "50%" && getWidth2() === "50%" ? (
                    <Box display={"flex"} gap={2}>
                        {renderFirstDetails()}
                        {renderSecondDetails()}
                    </Box>
                ) : (
                    <>
                        {renderFirstDetails()}
                        {renderSecondDetails()}
                    </>
                )}
                <RHFCheckbox name='savePropertyInfo' label={translate("save_property_info")} />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }} flexDirection={isMobile ? "column-reverse" : "row"}>
                    <Button color={"primary"} onClick={onBack}>
                        {translate("back")}
                    </Button>
                    <Button color={"primary"} type='submit' variant='contained'>
                        {translate("next")}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}
