import { useIsMobile } from "rentzz"
import TaskDetailsSheet from "../../../sections/kanban/details/mobile/TaskDetailsSheet"
import DesktopTaskDetails from "../../../sections/kanban/details/desktop/DesktopTaskDetails"
import React from "react"

export default function TaskDetailsGuard() {
    const isMobile = useIsMobile()

    return <>{isMobile ? <TaskDetailsSheet /> : <DesktopTaskDetails />}</>
}
