import React, { useCallback } from "react"
import { AppContext, FormProvider, RHFTextField, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TaskDescriptionSchema } from "../../../validations/kanban"
import * as jsonpatch from "fast-json-patch"
import { updateTaskMutation, updateTenantTaskMutation } from "../../../mutations/kanban"
import { useSelector } from "../../../redux/store"

interface TaskDescriptionRequest {
    description: string
}
interface Props {
    taskDescription?: string
    taskId: string
    sectionId?: string
    canEdit?: boolean
}
export default function TaskDescriptionForm({ taskDescription, taskId, sectionId, canEdit }: Props) {
    const { translate } = useLocales()
    const { mutateAsync: editTaskDescription } = updateTaskMutation()
    const { mutateAsync: editTenantTaskDescription } = updateTenantTaskMutation()
    const { context, editingItem } = useSelector((state) => state.appState)

    const methods = useForm<TaskDescriptionRequest>({
        resolver: yupResolver(TaskDescriptionSchema),
        defaultValues: {
            description: taskDescription,
        },
    })

    const { getValues, handleSubmit } = methods

    const onSubmit = useCallback(async () => {
        const operations = jsonpatch.compare({ description: taskDescription }, { description: getValues("description") })
        if (taskDescription === getValues("description")) return
        if (context === AppContext.Owner) {
            if (sectionId == null) return
            await editTaskDescription({
                sectionId,
                taskId,
                operations,
                newIndex: -1,
            })
        } else {
            await editTenantTaskDescription({
                rentingPeriodId: editingItem?.rentingPeriodId,
                taskId,
                operations,
            })
        }
    }, [sectionId, taskId, editTaskDescription, getValues, taskDescription, editTenantTaskDescription, editingItem])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                multiline
                maxRows={10}
                minRows={3}
                name='description'
                fullWidth
                placeholder={translate("task_description_placeholder")}
                onBlur={onSubmit}
                required
                disabled={!canEdit}
            />
        </FormProvider>
    )
}
