import { Box, CircularProgress, Divider, Grid, Paper, Stack, Typography, useTheme } from "@mui/material"
import { Iconify, Label, useIsMobile, useLocales } from "rentzz"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useState } from "react"
import { setModalOpen, setShowSubscriptionPage } from "../../../redux/slices/App"
import Api from "../../../api/Api"
import { RoleName, useUserBillingQuery, useUserDataQueryFn } from "../../../queries/userData"
import BronzePlan from "../../../assets/icons/BronzePlan.png"
import SilverPlan from "../../../assets/icons/SilverPlan.png"
import GoldPlan from "../../../assets/icons/GoldPlan.png"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useDispatch } from "../../../redux/store"
import { getSubscriptionQuery } from "../../../queries/subscription"

export default function SubscriptionDetails() {
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data, isLoading } = useUserBillingQuery()
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { data: subscriptions } = getSubscriptionQuery()
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false)

    const handleOnClick = useCallback(async () => {
        if (!data) {
            dispatch(setModalOpen(undefined))
            dispatch(setShowSubscriptionPage(true))
        } else {
            setIsPageLoading(true)
            window.open(await Api.fetchCustomerPortalURL(), "_target")
            setIsPageLoading(false)
        }
    }, [dispatch, data])

    const renderPlans = subscriptions?.map((subscription) => (
        <Grid xs={12} md={4} key={subscription.name} sx={{ paddingRight: isMobile ? undefined : 2, paddingBottom: isMobile ? 2 : undefined }}>
            <Stack
                component={Paper}
                variant='outlined'
                sx={{
                    p: 2.5,
                    position: "relative",
                    ...(userData?.roles.roleName !== subscription.name && {
                        opacity: 0.48,
                        cursor: "default",
                    }),
                    ...(userData?.roles.roleName === subscription.name && {
                        boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
                    }),
                }}
            >
                {userData?.roles.roleName === subscription.name && (
                    <Label color='info' startIcon={<Iconify icon='eva:star-fill' />} sx={{ position: "absolute", top: 8, right: 8 }}>
                        {translate("current")}
                    </Label>
                )}

                <Box sx={{ width: 48, height: 48 }}>
                    {subscription.name === RoleName.BRONZE && <img src={BronzePlan} alt='Bronze plan' />}
                    {subscription.name === RoleName.SILVER && <img src={SilverPlan} alt='Silver plan' />}
                    {subscription.name === RoleName.GOLD && <img src={GoldPlan} alt='Gold plan' />}
                </Box>

                <Box
                    sx={{
                        typography: "subtitle2",
                        mt: 2,
                        mb: 0.5,
                        textTransform: "capitalize",
                    }}
                >
                    {subscription.name}
                </Box>

                <Stack direction='row' alignItems='center' sx={{ typography: "h4" }}>
                    {subscription.name === RoleName.BRONZE
                        ? subscription.prices[0].price
                        : subscription?.prices.find((item) => item.numberOfMonths === 1)?.price}{" "}
                    RON
                    <Box component='span' sx={{ typography: "body2", color: "text.disabled", ml: 0.5 }}>
                        /{translate("month")}
                    </Box>
                </Stack>
            </Stack>
        </Grid>
    ))

    return (
        <Paper elevation={6} sx={{ padding: 2, color: theme.palette.grey.A700, marginTop: 2 }}>
            <Stack spacing={1.5} sx={{ px: 1, pt: 0 }}>
                <Typography variant={"subtitle1"} fontWeight={"bold"}>
                    {translate("subscriptions_info")}
                </Typography>
                <Divider sx={{ borderStyle: "dashed" }} />
                <Grid container spacing={2} sx={{ p: 3 }}>
                    {renderPlans}
                </Grid>
                {isLoading || !currencyData ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200} marginTop={3}>
                        <CircularProgress />
                    </Box>
                ) : !data ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} height={"100%"} paddingBottom={2}>
                        <Iconify icon={"mdi:text-box-edit-outline"} width={30} height={30} style={{ color: theme.palette.primary.main }} />
                        <Typography textAlign={"center"} marginTop={1} fontSize={15} color={theme.palette.grey["500"]}>
                            {translate("no_billing_data")}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <Stack spacing={2} sx={{ p: 4, pt: 2, typography: "body2" }}>
                            <Grid container spacing={{ xs: 0.5, md: 2 }}>
                                <Grid xs={12} md={4} sx={{ color: "text.secondary" }}>
                                    {translate("name")}
                                </Grid>
                                <Grid xs={12} md={8}>
                                    {data?.name}
                                </Grid>
                            </Grid>

                            <Grid container spacing={{ xs: 0.5, md: 2 }}>
                                <Grid xs={12} md={4} sx={{ color: "text.secondary" }}>
                                    {translate("address")}
                                </Grid>
                                <Grid xs={12} md={8}>
                                    {data?.address}
                                </Grid>
                            </Grid>
                            <Grid container spacing={{ xs: 0.5, md: 2 }}>
                                <Grid xs={12} md={4} sx={{ color: "text.secondary" }}>
                                    {translate("available_until")}
                                </Grid>
                                <Grid xs={12} md={8}>
                                    {data?.expiry.toLocaleString()}
                                </Grid>
                            </Grid>
                        </Stack>
                    </>
                )}
                <Box display={"flex"} justifyContent={isMobile ? undefined : "flex-end"}>
                    <LoadingButton
                        loading={isPageLoading}
                        disabled={isPageLoading}
                        color={"primary"}
                        onClick={handleOnClick}
                        variant={"contained"}
                        style={{ textTransform: "none" }}
                        fullWidth={isMobile}
                    >
                        {translate(!data ? "change_plan" : "manage_billing")}
                    </LoadingButton>
                </Box>
            </Stack>
        </Paper>
    )
}
