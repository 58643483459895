import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteContent from "../../../../components/DeleteContent"
import { deleteGeneralDocumentMutation } from "../../../../mutations/generalDocuments"
import { useGeneralDocumentsQuery } from "../../../../queries/generalDocuments"

export default function DeleteGeneralDocumentGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteDocument, isPending: isDeleteDocumentLoading } = deleteGeneralDocumentMutation()
    const { data: generalDocuments } = useGeneralDocumentsQuery()

    const currentDocument = useMemo(() => generalDocuments?.items?.find((doc) => doc.id === editingItem?.id), [generalDocuments, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.GeneralDocumentDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentDocument == null) return
        await deleteDocument(currentDocument.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentDocument, deleteDocument, dispatch, onClose])

    return (
        <>
            <CustomDialog
                title={translate("delete_document")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteGeneralDocument}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_document_content", { name: currentDocument?.name })}
                    isLoading={isDeleteDocumentLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteGeneralDocument}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_document")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_document_content", { name: currentDocument?.name })}
                    isLoading={isDeleteDocumentLoading}
                />
            </DetailsSheet>
        </>
    )
}
