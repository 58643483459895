import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useIsMobile } from "rentzz"
import DeleteExpenseGuard from "./DeleteExpenseGuard"
import ExpenseDetailsSheet from "./ExpenseDetailsSheet"
import AddOrEditExpenseGuard from "./AddOrEditExpenseGuard"
import RecurringExpensesGuard from "./RecurringExpensesGuard"
import AssignedIncomesModal from "./AssignedIncomesModal"
import PendingExpenseDetailsSheet from "./PendingExpenseDetailsSheet"
import AddPendingExpenseGuard from "./AddPendingExpenseGuard"
import DeactivateSendingExpense from "./DeactivateSendingExpense"
import UpdateExpenseStatus from "./UpdateExpenseStatus"
import ExpenseOnlinePaymentGuard from "./ExpenseOnlinePaymentGuard"
import AddCardPaymentDataGuard from "./AddCardPaymentDataGuard"
import TenantPaymentGuard from "./TenantPaymentGuard"
import ExpenseAIExtractGuard from "./ExpenseAIExtractGuard"

export default function ExpenseGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        dispatch(setErrorMessage(undefined))
        if (modalOpen === AppModals.AssignedIncomes) return dispatch(setModalOpen(AppModals.ExpenseDetails))

        if (isMobile && modalOpen !== AppModals.ExpenseDetails && modalOpen !== AppModals.AddExpense && !editingItem?.isFromCalendar) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        }
        if (isMobile && modalOpen === AppModals.ExpenseDetails && editingItem?.isFromAnIncome) {
            dispatch(setModalOpen(AppModals.AssignedExpenses))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [modalOpen, dispatch, isMobile, editingItem])

    const onClose = useCallback(() => {
        if (isMobile && modalOpen !== AppModals.PendingExpenseDetails) {
            dispatch(setModalOpen(AppModals.PendingExpenseStatus))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [modalOpen, dispatch, isMobile, editingItem])

    return (
        <>
            <ExpenseDetailsSheet onClose={closeModal} />
            <AddOrEditExpenseGuard />
            <DeleteExpenseGuard />
            <RecurringExpensesGuard />
            <AssignedIncomesModal onClose={closeModal} />
            <PendingExpenseDetailsSheet onClose={onClose} />
            <AddPendingExpenseGuard />
            <DeactivateSendingExpense />
            <UpdateExpenseStatus />
            <ExpenseOnlinePaymentGuard />
            <AddCardPaymentDataGuard />
            <TenantPaymentGuard />
            <ExpenseAIExtractGuard />
        </>
    )
}
