export class Config {
    public static SHOW_DEVTOOLS = import.meta.env.VITE_SHOW_DEVTOOLS ?? "true"
    public static MUI_LICENSE = import.meta.env.VITE_MUI_LICENSE ?? ""
    public static LICENSE = import.meta.env.VITE_LICENSE ?? ""
    public static CLERK_KEY = import.meta.env.VITE_APP_CLERK_PUBLISHABLE_KEY
    public static API_KEY = import.meta.env.VITE_APP_API_KEY_FIREBASE
    public static AUTH_DOMAIN_KEY = import.meta.env.VITE_APP_AUTH_DOMAIN_FIREBASE
    public static PROJECT_ID = import.meta.env.VITE_APP_PROJECT_ID_FIREBASE
    public static STORAGE_BUCKET = import.meta.env.VITE_APP_STORAGE_BUCKET_FIREBASE
    public static APP_ID = import.meta.env.VITE_APP_APP_ID_FIREBASE
    public static MESSAGING_SENDER_ID = import.meta.env.VITE_APP_MESSAGING_SENDER_ID_FIREBASE
}
