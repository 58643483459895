import { Box, CircularProgress, Typography } from "@mui/material"
import { Iconify, Label, useLocales } from "rentzz"
import { PropertyMeterValue } from "../../../../../redux/slices/AddProperty"
import CustomCard from "../../../../../components/CustomCard"
import React, { useCallback } from "react"
import { useUserPropertiesQuery } from "../../../../../queries/userData"
import { getUnitFromId, useUnitsQueryFn } from "../../../../../queries/units"
import { useIntl } from "react-intl"
import { alpha, useTheme } from "@mui/material/styles"
import { getPendingReadingLabelColor, getPendingReadingLabelText } from "../../utils"

interface Props {
    reading: PropertyMeterValue | undefined
    onClick: () => void
}
export default function PendingReadingCard({ reading, onClick }: Props) {
    const { translate } = useLocales()
    const intl = useIntl()
    const theme = useTheme()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: unitData } = useUnitsQueryFn()

    const getValue = useCallback(
        (value: number | undefined, unit: number | undefined) => {
            if (value === -1) return "-"
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, unit).code}`
        },
        [unitData, intl],
    )

    if (reading == null) return <CircularProgress />

    return (
        <CustomCard isCardClickable={false} px={1} py={1} onClick={onClick}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:timer-sand"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(theme.palette.warning.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: theme.palette.warning.main,
                            backgroundColor: alpha(theme.palette.warning.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                        <Typography fontWeight={"bold"} fontSize={15}>
                            {reading?.meterName}
                        </Typography>

                        <Typography fontSize={12}>{userProperties?.find((p) => p.id === Number(reading?.propertyId))?.label}</Typography>
                    </Box>
                </Box>
                <Box display={"flex"} flexDirection={"column"} style={{ gap: 2 }}>
                    <Box display={"flex"} paddingLeft={1}>
                        <Typography
                            fontSize={12}
                            fontWeight={reading?.previousValue === -1 ? "" : "bold"}
                            color={reading?.previousValue === -1 ? theme.palette.grey.A400 : ""}
                            alignSelf={"center"}
                        >
                            {reading?.previousValue === -1 ? translate("new_meter") : getValue(reading?.previousValue, reading?.unitId)}
                        </Typography>
                        <Iconify icon={"mdi:arrow-right"} alignSelf={"center"} width={12} height={15} />
                        <Typography fontSize={14} fontWeight={"bold"} color={theme.palette.grey.A700}>
                            {getValue(reading?.currentValue, reading?.unitId)}
                        </Typography>
                    </Box>
                    <Label color={getPendingReadingLabelColor(reading.status)} style={{ textTransform: "none" }}>
                        {translate(getPendingReadingLabelText(reading.status))}
                    </Label>
                </Box>
            </Box>
        </CustomCard>
    )
}
