import { useTheme } from "@mui/material/styles"
import { Box, CircularProgress } from "@mui/material"
import UserCard from "../../../../../components/UserCard"
import { InvitedManagerStatus, ManagerAcceptanceStatus } from "../../../../../redux/slices/AddProperty"
import { usePropertyDetailsQueryFn } from "../../../../../queries"
import React from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useDispatch } from "../../../../../redux/store"
import {
    getInvitedManagerBackgroundColor,
    getInvitedManagerColor,
    getInvitedManagerIcon,
    getInvitedManagerStatusLabelColor,
    getManagerAcceptanceAccountBackgroundColor,
    getManagerAcceptanceAccountColor,
    getManagerAcceptanceIcon,
    getManagerAcceptanceStatusLabelColor,
} from "../../utils"

export default function MobileManagersList() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { data: propertyDetails, isLoading: arePropertyDetailsLoading } = usePropertyDetailsQueryFn()

    return (
        <>
            {arePropertyDetailsLoading ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box padding={2} display={"flex"} flexDirection={"column"} rowGap={1}>
                    {propertyDetails?.finalManagers.map((owner, index) => (
                        <UserCard
                            key={index}
                            user={owner}
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.ManagerDetails))
                                dispatch(setEditingItem({ mail: owner.mail }))
                            }}
                            backgroundColor={
                                owner.name === "-"
                                    ? getInvitedManagerBackgroundColor(owner.status as InvitedManagerStatus, theme)
                                    : getManagerAcceptanceAccountBackgroundColor(owner.status as ManagerAcceptanceStatus, theme)
                            }
                            icon={
                                owner.name === "-"
                                    ? getInvitedManagerIcon(owner.status as InvitedManagerStatus)
                                    : getManagerAcceptanceIcon(owner.status as ManagerAcceptanceStatus)
                            }
                            iconColor={
                                owner.name === "-"
                                    ? getInvitedManagerColor(owner.status as InvitedManagerStatus, theme)
                                    : getManagerAcceptanceAccountColor(owner.status as ManagerAcceptanceStatus, theme)
                            }
                            labelColor={
                                owner.name === "-"
                                    ? getInvitedManagerStatusLabelColor(owner.status as InvitedManagerStatus)
                                    : getManagerAcceptanceStatusLabelColor(owner.status as ManagerAcceptanceStatus)
                            }
                        />
                    ))}
                </Box>
            )}
        </>
    )
}
