import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import SyncPropertiesForm from "../../../../pages/properties/SyncPropertiesForm"

export default function SyncPropertiesGuard() {
    const { modalOpen } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate("sync-properties")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.SyncProperties}
                maxWidth='xs'
                isFullScreen={isMobile}
                titleAlign={"center"}
            >
                <SyncPropertiesForm onClose={onClose} />
            </CustomDialog>

            <DetailsSheet
                title={translate("sync-properties")}
                isOpen={isMobile && modalOpen === AppModals.SyncProperties}
                onClose={onClose}
                isLoading={false}
            >
                <SyncPropertiesForm onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
