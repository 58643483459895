import { Box, Divider, Typography, useTheme } from "@mui/material"
import { useLocales } from "rentzz"
import { PropertyAdDetails } from "../../../../queries/userData"
import React, { useCallback } from "react"

export const detailsToTake = [
    "size",
    "partition",
    "floor",
    "furniture",
    "rooms",
    "bathrooms",
    "tv",
    "hasHeater",
    "hasAC",
    "hasFridge",
    "hasDishwasher",
    "hasWashingMachine",
    "hasHood",
    "hasElevator",
    "hasDoubleGlazing",
    "hasInsulation",
]

interface DetailsPreviewProps {
    adData?: PropertyAdDetails
}

export const otherDetails = [
    "hasHeater",
    "hasAC",
    "hasFridge",
    "hasDishwasher",
    "hasWashingMachine",
    "hasHood",
    "hasElevator",
    "hasDoubleGlazing",
    "hasInsulation",
]

export default function DetailsLivePreview({ adData }: DetailsPreviewProps) {
    const { translate } = useLocales()
    const theme = useTheme()

    const getValue = useCallback(
        (detail: string) => {
            if (adData == null) return ""
            if (otherDetails.includes(detail)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (adData[detail] === true) return "DA"
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (detail === "floor" && adData[detail] === 0) return translate("ground")
            if (detail === "partition") return translate(`partition-${Number(adData[detail])}`)
            if (detail === "furniture") return translate(`furniture-${adData[detail]}`)
            if (detail === "tv") return translate(`tvType-${adData[detail]}`)

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return translate(adData[detail])
        },
        [adData, translate],
    )

    const renderDetails = useCallback(
        (detail: string, index: number) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (adData == null || adData[detail] === "" || adData[detail] === false || adData[detail] == null) return null

            return (
                <Box key={index} width={"50%"} marginTop={2} paddingRight={2}>
                    <Box display={"flex"} justifyContent={"space-between"} paddingRight={0.5}>
                        <Typography fontSize={14} color={theme.palette.grey["500"]}>
                            {translate(detail)}:
                        </Typography>
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography fontSize={14} marginLeft={0.5}>
                                {getValue(detail)}
                            </Typography>
                            {/*  TODO */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/*  @ts-ignore */}
                            {detail === "size" && adData["size"] !== "" && (
                                <Typography paddingLeft={0.5} fontSize={13} paddingBottom={0.2}>
                                    m<sup>2</sup>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Divider />
                </Box>
            )
        },
        [adData, translate, getValue],
    )

    if (adData == null) return <></>

    return (
        <Box marginTop={4} paddingLeft={2}>
            <Typography fontWeight={"bold"} fontSize={14}>
                {translate("marketing.specifications")}
            </Typography>
            <Box display={"flex"} flexWrap={"wrap"}>
                {detailsToTake.map((detail, index) => renderDetails(detail, index))}
            </Box>
        </Box>
    )
}
