import { CustomDataGrid, LoadingIcon, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { GridColDef } from "@mui/x-data-grid-pro"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import EditPropertyPercentageForm from "./EditPropertyPercentageForm"
import { useUserGroupsQueryFn } from "../../../queries/groups"
import CustomToolbar from "../../../components/CustomToolbar"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"

export interface GroupProperty {
    id: number
    name: number
    county: string
    city: string
    expenseShare: number
}
export default function DesktopGroupPropertiesPage() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: groups } = useUserGroupsQueryFn()
    const { currentGroupId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [currentGroupId, groups])
    const navigate = useNavigate()

    const currentProperties = useMemo(() => {
        if (currentGroup == null || properties == null) return

        return currentGroup.properties.map((p) => {
            const match = properties.find((property) => property.id == p.propertyId)
            const reverseMatch = currentGroup.properties.find((p) => p.propertyId === match?.id)
            return {
                id: match!.id,
                name: match!.label,
                county: match!.county,
                city: match!.city,
                expenseShare: reverseMatch?.expenseShare,
            }
        })
    }, [properties, currentGroup])

    const propertiesColumns = useMemo(() => {
        const columns: GridColDef<GroupProperty>[] = [
            {
                field: "name",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("properties"),
                renderCell: ({ value, row }) => {
                    return (
                        <Button color='primary' onClick={() => navigate(`/properties/${row.id}/general`)}>
                            {value}
                        </Button>
                    )
                },
            },
            {
                field: "county",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("county"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "city",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("city"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "expenseShare",
                type: "number",
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("percentage"),
                renderCell: (v) => {
                    return <EditPropertyPercentageForm currentProperty={v.row} />
                },
            },
        ]

        return columns
    }, [translate])

    if (currentGroup == null) return <LoadingIcon />

    return (
        <>
            <CustomToolbar
                onAddClick={() => {
                    dispatch(setModalOpen(AppModals.EditGroupProperties))
                    dispatch(setEditingItem({ id: currentGroupId }))
                }}
                numberOfItems={currentProperties?.length}
                buttonText={"handle_properties"}
            />
            <CustomDataGrid
                sortingMode='client'
                columns={propertiesColumns}
                idKey={"id"}
                getRowId={(row) => row.id}
                actionsColumn={[]}
                rows={currentProperties ?? []}
            />
        </>
    )
}
