import { DetailsSheet, Iconify, MotionContainer, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import React, { useMemo, useState } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { PropertyFileType } from "../../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../../utils"
import { DateTime } from "luxon"
import { DiaryType, getJournalByIdQueryFn } from "../../../../queries/journal"
import { RemoveRedEye } from "@mui/icons-material"
import * as humps from "humps"
import { useAtomValue } from "jotai/index"
import { journalsFilterState } from "../../../../utils/atom"

export default function JournalDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const filterModel = useAtomValue(journalsFilterState)
    const currentJournal = getJournalByIdQueryFn(editingItem?.id, [{ field: "createdAt", sort: "desc" }], filterModel)
    const [expanded, setExpanded] = useState<boolean>(false)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentJournal == null) return []
        return [
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: currentJournal.id,
                            type: PropertyFileType.Journal,
                            name: currentJournal.title,
                            canWrite: currentJournal.canWrite,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <RemoveRedEye color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.view_details",
                hidden: !currentJournal.isSystemDiaryEntry || currentJournal.type === DiaryType.DeliveredMail,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.JournalItemDetails))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !currentJournal.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditJournal))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentJournal.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteJournal))
                },
            },
        ]
    }, [currentJournal, dispatch, context])

    const journalTitle = useMemo(() => {
        if (currentJournal?.isSystemDiaryEntry) {
            if (currentJournal.type === DiaryType.DeliveredMail)
                return translate("mail_to", humps.camelizeKeys(JSON.parse(currentJournal.parameters)))
            return translate(currentJournal.title)
        } else return currentJournal?.title
    }, [currentJournal, translate])

    if (editingItem == null) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.JournalDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={currentJournal == null}
            title={journalTitle}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                    <Iconify icon={"mdi:information-variant"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
                    <Box display={"flex"} flexDirection={"column"}>
                        {currentJournal?.isSystemDiaryEntry && (
                            <Typography
                                fontSize={13}
                                color={theme.palette.grey["600"]}
                                sx={{
                                    overflow: "hidden",
                                    textAlign: "left",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {currentJournal.type === DiaryType.DeliveredMail
                                    ? JSON.parse(currentJournal.parameters)["Title"]
                                    : translate(currentJournal.description)}
                            </Typography>
                        )}

                        {!currentJournal?.isSystemDiaryEntry && (
                            <Typography
                                fontSize={13}
                                color={theme.palette.grey["600"]}
                                sx={{
                                    overflow: "hidden",
                                    textAlign: "left",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {currentJournal?.description.substring(0, 120)}
                                {currentJournal && currentJournal?.description.length > 120 && !expanded && (
                                    <>
                                        ...
                                        <Button
                                            size='small'
                                            color='primary'
                                            sx={{ marginBottom: 0.4 }}
                                            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                                        >
                                            {translate("view_all")}
                                        </Button>
                                    </>
                                )}
                            </Typography>
                        )}

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                <Typography
                                    fontSize={13}
                                    color={theme.palette.grey["600"]}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {currentJournal?.description.substring(121, currentJournal?.description.length - 1)}

                                    <Button size='small' color='primary' onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                                        {translate("close")}
                                    </Button>
                                </Typography>
                            </MotionContainer>
                        )}
                    </Box>
                </Box>
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("notes.updatedAt")} ${currentJournal?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("notes.addedAt")} ${currentJournal?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    userProperties?.find((p) => p.id === currentJournal?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
