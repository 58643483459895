import { C168AddressConfig, DefaultCountry, FormProvider, Page, PersonalData, PersonalDataSchema, PersonType, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCallback } from "react"
import Api from "../../../api/Api"
import { useAddC168AddressMutation } from "../../../mutations/c168"
import { useUserDataQueryFn } from "../../../queries/userData"
import { object, string } from "yup"

export const C168AddressBaseSchema = () =>
    PersonalDataSchema().shape({
        id: string().optional(),
        lastModifiedAt: string().optional(),
    })

export const C168AddressConfigSchema = object().shape({
    owner: C168AddressBaseSchema(),
})

export interface C168AddressRequest {
    owner: PersonalData
}

interface Props {
    onClose: () => void
}
export default function C168AddressConfiguration({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()

    const methods = useForm<C168AddressRequest>({
        resolver: yupResolver<C168AddressRequest>(C168AddressConfigSchema),
        defaultValues: {
            owner: {
                personType: PersonType.Natural,
                country: DefaultCountry,
                email: userData?.email,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
            },
        },
    })
    const { handleSubmit } = methods
    const { mutateAsync: addC168Address, isPending: isAddC168AddressPending } = useAddC168AddressMutation()

    const onSubmit = useCallback(
        async (data: C168AddressRequest) => {
            await addC168Address({ data: data.owner })
            onClose()
        },
        [addC168Address],
    )

    return (
        <Page title={translate("nomenclature")} propertyName={"C168"} paddingX={isMobile ? 1 : undefined} paddingBottom={isMobile ? 2 : undefined}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <C168AddressConfig api={Api} showCompleteInfo={false} isLoading={isAddC168AddressPending} />
            </FormProvider>
        </Page>
    )
}
