import { array, number, object, string } from "yup"
import { isValidIBAN } from "ibantools"

export const AddBankAccountSchema = (isAddingOnlyProperties: boolean | undefined) =>
    object().shape({
        holder: string().required("required"),
        iban: string()
            .required("required")
            .test("test", "iban_validation", (value) => {
                const noSpaceIban = value.replace(/\s/g, "")
                return isValidIBAN(noSpaceIban)
            }),
        bank: number().required("required"),
        currencyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        properties: array().test("test", "required", (value) => {
            if (isAddingOnlyProperties) {
                return Array.isArray(value) && value.length > 0
            }
            return true
        }),
    })
