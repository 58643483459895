import React, { useCallback, useState } from "react"
import { ClickAwayListener, IconButton } from "@mui/material"
import { LightTooltip } from "rentzz"
import GeneralCellRender from "./cell-renders"
import * as humps from "humps"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../../../utils/helpers"
import { GridCellParams } from "@mui/x-data-grid-pro"
import { DiaryType, Journal } from "../../../../../queries/journal"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

const TooltipRender = (v: GridCellParams<Journal, string>) => {
    const [open, setOpen] = useState(false)

    const handleTooltipClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleTooltipOpen = useCallback(() => {
        setOpen(true)
    }, [])

    if (!v.value || v.row.type === DiaryType.DeliveredMail) return null

    return (
        <ClickAwayListener key={v.row.id} onClickAway={handleTooltipClose}>
            <LightTooltip
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                placement='right-start'
                arrow
                slotProps={{
                    tooltip: {
                        sx: {
                            maxWidth: 500,
                        },
                    },
                }}
                title={<GeneralCellRender data={humps.camelizeKeys(JSON.parse(v.value))} type={v.row.type} />}
            >
                <IconButton onClick={open ? handleTooltipClose : handleTooltipOpen}>
                    {open ? (
                        <VisibilityOffIcon color='primary' sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    ) : (
                        <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                    )}
                </IconButton>
            </LightTooltip>
        </ClickAwayListener>
    )
}

export default TooltipRender
