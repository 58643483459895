import { JournalItemChanged, PropertyExpenseJournalDetailsDto } from "../../../../../../queries/expenses"
import { Box, Divider } from "@mui/material"
import ExpenseCellRender from "./Expense"
import { KeyboardDoubleArrowDown, KeyboardDoubleArrowRight } from "@mui/icons-material"
import { useIsMobile } from "rentzz"

interface ExpenseCellRenderProps {
    data: JournalItemChanged<PropertyExpenseJournalDetailsDto>
}

const ExpenseCellComparerRender = ({ data }: ExpenseCellRenderProps) => {
    const isMobile = useIsMobile()

    return (
        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1} p={1}>
            <Box display='flex' flexDirection='column' gap={1} p={1}>
                <ExpenseCellRender data={data.old} />
            </Box>
            <Divider orientation={isMobile ? "horizontal" : "vertical"} flexItem>
                {isMobile ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
            </Divider>

            <Box display='flex' flexDirection='column' gap={1} p={1}>
                <ExpenseCellRender data={data.new} />
            </Box>
        </Box>
    )
}

export default ExpenseCellComparerRender
