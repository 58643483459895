import NoPropertyPage from "./NoPropertyPage"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { AppContext } from "rentzz"
import { useSelector } from "../../redux/store"
import { ReactNode } from "react"

interface NoPropertyProps {
    children: ReactNode
}

export default function NoPropertyGuard({ children }: NoPropertyProps) {
    const { data: user } = useUserDataQueryFn()
    const { context } = useSelector((state) => state.appState)
    const { data: userProperties, isLoading: arePropertiesLoading } = useUserPropertiesQuery()

    if (user && context === AppContext.Owner && (userProperties?.length ?? 0) === 0 && !arePropertiesLoading) {
        return <NoPropertyPage />
    }

    return <>{children}</>
}
