import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { AddNewDocumentRequest } from "../sections/documents-templates/generalDocuments/AddOrEditGeneralDocument"

export const useAddGeneralDocumentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddNewDocumentRequest }) => Api.addGeneralDocument(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.GeneralDocuments])
            }
        },
    })
}

export const updateGeneralDocumentContentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ documentId, content }: { documentId: number; content: string | undefined }) =>
            Api.updateGeneralDocumentContent(documentId, content),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.GeneralDocumentDetails])
            }
        },
    })
}

export const updateGeneralDocumentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ documentId, operations }: { documentId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateGeneralDocument(documentId, operations),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.GeneralDocumentDetails, SimpleQueryIDs.GeneralDocuments])
            }
        },
    })
}

export const getGeneralDocumentPdfMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ body }: { body: string }) => Api.generateGeneralDocumentPdfRequest(body),
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.GeneralDocuments])
            }
        },
    })
}

export const deleteGeneralDocumentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteGeneralDocument,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.GeneralDocuments])
        },
    })
}
