import ExportForm from "../../sections/expenses/export/ExportForm"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { useCallback, useMemo } from "react"
import { ExportType } from "../../utils/actions"
import { Box } from "@mui/material"

export default function ExportGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        if (editingItem?.type === ExportType.Reading) {
            dispatch(setModalOpen(AppModals.MeterValuesHistory))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, editingItem])

    const sheetTitle = useMemo(() => {
        if (editingItem?.type === ExportType.Expense) return "export_expenses"
        if (editingItem?.type === ExportType.Income) return "export_incomes"
        if (editingItem?.type === ExportType.Reading) return "export_readings"
        if (editingItem?.type === ExportType.Balance) return "generate_balance_report"
    }, [editingItem])

    return (
        <>
            <CustomDialog
                title={translate(sheetTitle)}
                open={!isMobile && modalOpen === AppModals.ExportDetailsSheet && editingItem?.isFromTenantsTable}
                onClose={onClose}
                isFullScreen={false}
                maxWidth={"xs"}
            >
                <Box paddingY={2}>
                    <ExportForm type={editingItem?.type} onSubmit={onClose} />
                </Box>
            </CustomDialog>
            <DetailsSheet
                title={translate(sheetTitle)}
                isOpen={isMobile && modalOpen === AppModals.ExportDetailsSheet}
                onClose={onClose}
                isLoading={false}
            >
                <Box paddingY={2}>
                    <ExportForm type={editingItem?.type} />
                </Box>
            </DetailsSheet>
        </>
    )
}
