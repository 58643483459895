import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { SyntheticEvent, useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import { useRevisionObservationsQueryFn } from "../../../queries/revision"
import { deleteObservationMutation } from "../../../mutations/revison"

export default function DeleteObservationGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const { data: observations } = useRevisionObservationsQueryFn(editingItem?.revisionId)
    const { mutateAsync: deleteObservation, isPending: isDeleteLoading } = deleteObservationMutation()

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ObservationsList))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const currentObservation = useMemo(() => {
        return observations?.find((o) => o.id === editingItem?.observationId)
    }, [observations, editingItem])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (!editingItem) return
            await deleteObservation({ revisionId: editingItem?.revisionId, observationId: editingItem?.observationId })

            onClose()
        },
        [deleteObservation, editingItem, onClose, editingItem],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_observation")}
                isOpen={isMobile && modalOpen === AppModals.DeleteObservation}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_observation_content", { name: currentObservation?.title })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteObservation}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_observation")}
                content={translate("delete_observation_content", { name: currentObservation?.title })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}
