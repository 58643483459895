import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import AddCardPaymentDataForm, { AddCardPaymentDataRequest } from "../../../sections/expenses/online-payment/AddCardPaymentDataForm"
import { addCardPaymentDataMutation } from "../../../mutations/user"

export default function AddCardPaymentDataGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: addPaymentDetails, isPending } = addCardPaymentDataMutation()

    const closeModal = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, isMobile])

    const onSave = useCallback(
        async (data: AddCardPaymentDataRequest) => {
            await addPaymentDetails({ data })
            dispatch(setModalOpen(AppModals.ExpensePayment))
        },
        [addPaymentDetails, dispatch],
    )

    return (
        <>
            <CustomDialog
                title={translate("add_card_payment_data")}
                open={!isMobile && modalOpen === AppModals.AddCardPaymentData}
                onClose={closeModal}
                maxWidth={"xs"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddCardPaymentDataForm onClose={closeModal} isLoading={isPending} onSave={onSave} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("add_card_payment_data")}
                isOpen={isMobile && modalOpen === AppModals.AddCardPaymentData}
                onClose={closeModal}
            >
                <AddCardPaymentDataForm onClose={closeModal} isLoading={isPending} onSave={onSave} />
            </DetailsSheet>
        </>
    )
}
