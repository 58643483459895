import { CustomPopover, useLocales, usePopover } from "rentzz"
import { Button } from "@mui/material"
import { MouseEvent, useCallback } from "react"
import ExportForm from "./ExportForm"
import { ExportType } from "../../../utils/actions"
import { useDispatch } from "../../../redux/store"
import { setEditingItem } from "../../../redux/slices/App"

interface ExpensesExportButtonProps {
    isDisabled: boolean
    type: ExportType
    itemId?: number
    text?: string
}
const ExportButton = ({ isDisabled, type, itemId, text }: ExpensesExportButtonProps) => {
    const dispatch = useDispatch()
    const popover = usePopover()
    const { translate } = useLocales()

    const onButtonClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            popover.setOpen(event.currentTarget)
            if (itemId) {
                dispatch(setEditingItem({ id: itemId }))
            }
        },
        [popover, itemId],
    )

    return (
        <>
            <Button
                size={type === ExportType.Reading ? "small" : "medium"}
                disabled={isDisabled}
                color='primary'
                variant={type === ExportType.Reading ? "text" : "contained"}
                onClick={onButtonClick}
            >
                {text ? translate(text) : translate("export")}
            </Button>
            <CustomPopover
                open={popover.open}
                sx={{
                    mt: 1.5,
                    p: 2,
                    "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
                }}
            >
                <ExportForm onSubmit={popover.onClose} type={type} />
            </CustomPopover>
        </>
    )
}

export default ExportButton
