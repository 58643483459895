import PDFViewer from "../components/PDFViewer"
import ExcelViewer from "../components/ExcelViewer"

export default function PdfViewerGuard() {
    return (
        <>
            <ExcelViewer />
            <PDFViewer />
        </>
    )
}
