import { alpha, useTheme } from "@mui/material/styles"
import CustomCard from "../../../components/CustomCard"
import { Box, Typography } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import { DateTime } from "luxon"
import React from "react"

interface Props {
    onClick: () => void
    name: string
    createdAt: DateTime
    order: number
}
export default function SectionCard({ onClick, name, createdAt, order }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <CustomCard isCardClickable onClick={onClick} px={1} py={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:to-do"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(theme.palette.primary.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                        <Typography fontWeight={"bold"} variant={"subtitle2"} color={theme.palette.grey.A700}>
                            {name}
                        </Typography>
                        <Typography variant={"caption"} color={theme.palette.grey.A700}>
                            {translate("section_order")}: {order}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {createdAt?.toLocaleString(DateTime.DATE_SHORT) ?? "-"}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
