import { Iconify, LightTooltip, RHFAutocomplete, useLocales } from "rentzz"
import React from "react"
import { useUserProvidersQuery } from "../queries/userData"
import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "../redux/store"
import { setEditingItem, setModalOpen } from "../redux/slices/App"

interface RHFProviderInputProps {
    name: string
}

export default function RHFProviderInput({ name }: RHFProviderInputProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: providers, isLoading: areProvidersLoading } = useUserProvidersQuery()

    return (
        <>
            <RHFAutocomplete
                name={name}
                textFieldProps={{ size: "small" }}
                options={providers?.map((p) => ({ id: p.id, label: p.name })) ?? []}
                matchId
                label={translate("provider")}
                loading={areProvidersLoading}
                freeSolo={false}
                autocompleteProps={{
                    size: "small",
                    noOptionsText: (
                        <LightTooltip title={translate("add_provider")}>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    dispatch(setModalOpen(undefined))
                                    dispatch(setEditingItem(undefined))
                                    navigate("/nomenclature/providers")
                                }}
                            >
                                <Typography>{translate("noProvider")}</Typography>
                                <Iconify sx={{ color: theme.palette.primary.main }} icon={"mdi:add-circle-outline"} />
                            </Box>
                        </LightTooltip>
                    ),
                }}
            />
        </>
    )
}
