import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useLocales } from "rentzz"
import { ContactInfoData } from "./TenantContactInfo"
import useStyles from "../useStyles"

interface TenantInfoPreviewProps {
    tenantInfoData?: Omit<ContactInfoData, "type"> | undefined
}

const CompanyTenantContactFinalData = ({ tenantInfoData }: TenantInfoPreviewProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    const renderEmptyIfNoValue = useCallback(
        (value: number | string | undefined, label?: string) => {
            if ((typeof value === "number" && value === 0) || value == null || (typeof value === "string" && value.length === 0)) {
                return null
            }
            return (
                <Text style={{ display: "flex", flexDirection: "row" }}>
                    {translate(label)}
                    <Text style={{ ...styles.boldFont }}>{value}</Text>,{" "}
                </Text>
            )
        },
        [styles, translate],
    )

    return (
        <View>
            <Text wrap style={styles.mainText}>
                {renderEmptyIfNoValue(tenantInfoData?.companyName)}
                {translate("contracts.companyAddress")} {tenantInfoData?.county === "Bucuresti" ? "" : translate("contracts.county")}
                {renderEmptyIfNoValue(tenantInfoData?.county)}
                {tenantInfoData?.county === "Bucuresti" ? "" : translate("contracts.city")}
                {renderEmptyIfNoValue(tenantInfoData?.city)}
                {renderEmptyIfNoValue(tenantInfoData?.street, "contracts.street")}
                {renderEmptyIfNoValue(tenantInfoData?.streetNumber, "contracts.number")}
                {renderEmptyIfNoValue(tenantInfoData?.buildingNumber, "contracts.buildingNumber")}
                {renderEmptyIfNoValue(tenantInfoData?.stair, "contracts.stair")}
                {tenantInfoData?.floor ? translate("contracts.floor") : ""}
                {tenantInfoData?.floor ? (
                    <Text>
                        <Text style={{ ...styles.boldFont }}>
                            {translate(tenantInfoData?.floor === "0" ? "ground" : "floor_with_number", { floor: Number(tenantInfoData.floor) })}
                        </Text>
                        ,{" "}
                    </Text>
                ) : (
                    ""
                )}
                {renderEmptyIfNoValue(tenantInfoData?.apartment, "contracts.apartment")}
                {renderEmptyIfNoValue(tenantInfoData?.identitySeries, "contracts.identitySeries")}
                {renderEmptyIfNoValue(tenantInfoData?.identityNumber, "contracts.number")}
                {renderEmptyIfNoValue(tenantInfoData?.phone, "contracts.phone")}
                {renderEmptyIfNoValue(tenantInfoData?.email, "contracts.email")}
                {renderEmptyIfNoValue(tenantInfoData?.fullName, "contracts.companyRepresentative")}
                {translate("contracts.companyRepresentativeFunction")}
                <Text style={{ ...styles.boldFont }}>{tenantInfoData?.companyPosition}</Text>
                {translate("ofTheCompany")}
                {translate("contracts.as")} <Text style={{ ...styles.boldFont, textTransform: "uppercase" }}>{translate("tenant")}</Text>
            </Text>
        </View>
    )
}

export default CompanyTenantContactFinalData
