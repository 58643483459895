import { InvitedManagerStatus, ManagerAcceptanceStatus, MeterValueStatus } from "../../../redux/slices/AddProperty"
import { Theme } from "@mui/material"
import { alpha } from "@mui/material/styles"

export const getManagerAcceptanceStatusLabelColor = (value: ManagerAcceptanceStatus) => {
    switch (value) {
        case ManagerAcceptanceStatus.NONE:
            return "default"
        case ManagerAcceptanceStatus.ACCEPTED:
            return "success"
        case ManagerAcceptanceStatus.REJECTED:
            return "error"
    }
}

export const getInvitedManagerStatusLabelColor = (value: InvitedManagerStatus) => {
    switch (value) {
        case InvitedManagerStatus.CREATED:
        case InvitedManagerStatus.NOTIFIED:
            return "info"
        case InvitedManagerStatus.NOTIFICATION_FAILED:
            return "warning"
    }
}

export const getManagerAcceptanceIcon = (value: ManagerAcceptanceStatus) => {
    switch (value) {
        case ManagerAcceptanceStatus.NONE:
            return "mdi:account-clock"
        case ManagerAcceptanceStatus.ACCEPTED:
            return "mdi:account-check"
        case ManagerAcceptanceStatus.REJECTED:
            return "mdi:account-remove"
    }
}

export const getInvitedManagerIcon = (value: InvitedManagerStatus) => {
    switch (value) {
        case InvitedManagerStatus.CREATED:
        case InvitedManagerStatus.NOTIFIED:
            return "mdi:account-clock"
        case InvitedManagerStatus.NOTIFICATION_FAILED:
            return "mdi:account-cancel"
    }
}

export const getManagerAcceptanceAccountBackgroundColor = (value: ManagerAcceptanceStatus, theme: Theme) => {
    switch (value) {
        case ManagerAcceptanceStatus.NONE:
            return alpha(theme.palette.info.main, 0.1)
        case ManagerAcceptanceStatus.ACCEPTED:
            return alpha(theme.palette.success.main, 0.1)
        case ManagerAcceptanceStatus.REJECTED:
            return alpha(theme.palette.error.main, 0.1)
    }
}

export const getManagerAcceptanceAccountColor = (value: ManagerAcceptanceStatus, theme: Theme) => {
    switch (value) {
        case ManagerAcceptanceStatus.NONE:
            return theme.palette.info.main
        case ManagerAcceptanceStatus.ACCEPTED:
            return theme.palette.primary.main
        case ManagerAcceptanceStatus.REJECTED:
            return theme.palette.error.main
    }
}

export const getInvitedManagerColor = (value: InvitedManagerStatus, theme: Theme) => {
    switch (value) {
        case InvitedManagerStatus.CREATED:
        case InvitedManagerStatus.NOTIFIED:
            return theme.palette.info.main
        case InvitedManagerStatus.NOTIFICATION_FAILED:
            return theme.palette.warning.main
    }
}

export const getInvitedManagerBackgroundColor = (value: InvitedManagerStatus, theme: Theme) => {
    switch (value) {
        case InvitedManagerStatus.CREATED:
        case InvitedManagerStatus.NOTIFIED:
            return alpha(theme.palette.info.main, 0.1)
        case InvitedManagerStatus.NOTIFICATION_FAILED:
            return alpha(theme.palette.error.main, 0.1)
    }
}

export const getPendingReadingLabelColor = (status: MeterValueStatus | undefined) => {
    if (status === MeterValueStatus.Created) return "warning"
    if (status === MeterValueStatus.Sending) return "info"
    if (status === MeterValueStatus.FailedToSend) return "error"
    if (status === MeterValueStatus.Sent) return "success"
}

export const getPendingReadingLabelText = (status: MeterValueStatus | undefined) => {
    if (status === MeterValueStatus.Created) return "pending"
    if (status === MeterValueStatus.Sending) return "sending"
    if (status === MeterValueStatus.FailedToSend) return "sending_failed"
    if (status === MeterValueStatus.Sent) return "index_send"
}
