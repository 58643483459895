import { updateUserSectionMutation } from "../../../mutations/kanban"
import { AppContext, FormProvider, RHFSelect, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SectionIsLastSchema } from "../../../validations/kanban"
import React, { useEffect } from "react"
import * as jsonpatch from "fast-json-patch"
import { Box, MenuItem } from "@mui/material"

interface TaskDescriptionRequest {
    isFinal: string
}
interface Props {
    isFinal: boolean
    sectionId?: string
    canEdit?: boolean
}
export default function LastSectionForm({ isFinal, sectionId, canEdit }: Props) {
    const { mutateAsync: editTask } = updateUserSectionMutation()
    const { translate } = useLocales()
    const { context } = useSelector((state) => state.appState)

    const methods = useForm<TaskDescriptionRequest>({
        resolver: yupResolver(SectionIsLastSchema),
        defaultValues: {
            isFinal: `${isFinal}`,
        },
    })

    const { handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "isFinal") {
                const operations = jsonpatch.compare({ isFinal: isFinal }, { isFinal: v.isFinal === "true" })
                if (isFinal === (v.isFinal === "true")) return
                if (context === AppContext.Owner) {
                    if (sectionId == null) return
                    editTask({
                        sectionId,
                        operations,
                    })
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, isFinal])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
            <Box display='flex' flexDirection='row' gap={1}>
                <RHFSelect name='isFinal' required disabled={!canEdit}>
                    <MenuItem value={"true"}>{translate("yes")}</MenuItem>
                    <MenuItem value={"false"}>{translate("no")}</MenuItem>
                </RHFSelect>
            </Box>
        </FormProvider>
    )
}
