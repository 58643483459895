import { array, boolean, number, object, string } from "yup"
import { DateTime } from "luxon"

export const ExpensesSchema = (currentGroupId?: number) =>
    object().shape({
        id: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        name: string().required("name_required"),
        assignee: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required()
            .min(0)
            .max(1),
        date: string()
            .required("date_required")
            .test((date: string | undefined) => {
                if (date == null) return false
                const newDate = DateTime.fromISO(date)

                return newDate.isValid
            }),
        dueDate: string()
            .required()
            .test((date: string | undefined) => {
                if (date == null) return false

                const newDate = DateTime.fromISO(date)
                return newDate.isValid
            }),
        value: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("value_required"),
        files: array().required(),
        associatedProviderId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("test", "property_required", function (value) {
                if (currentGroupId) {
                    return true
                }
                return value !== 0
            }),
        labelId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
        currencyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required(),
        shouldNotifyOtherParty: boolean().optional(),
        shouldCreateInvoice: boolean().optional(),
        invoicingData: object().optional(),
        ___: number().optional(),
        paymentType: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required()
            .min(0)
            .max(4),
        groupId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .test("test", "group_required", function (value) {
                if (!currentGroupId) {
                    return true
                }
                return value !== 0
            }),
    })

export const ReinvoicingSchema = object().shape({
    invoiceDate: string()
        .required("date_required")
        .test((date: string | undefined) => {
            if (date == null) return false
            const newDate = DateTime.fromISO(date)

            return newDate.isValid
        }),
    invoiceDueDate: string()
        .required()
        .test((date: string | undefined) => {
            if (date == null) return false

            const newDate = DateTime.fromISO(date)
            return newDate.isValid
        }),
    modifyExpenseWithInvoiceData: boolean().required(),
})

export const AddCardPaymentDataSchema = object().shape({
    country: string().required("country_required"),
    county: string().required("county_required"),
    city: string().required("city_required"),
    address: string().required("address_required"),
    zipCode: string().required("zipCode_required"),
    phoneNumber: string().required("phone_required"),
})
