import { CustomDialog, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { updatePropertyDetailsMutation } from "../../mutations"

export default function UpdatePropertiesArchivedStatus() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: editProperty, isPending: isEditPropertyLoading } = updatePropertyDetailsMutation(editingItem?.id)

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [modalOpen, dispatch])

    const handleArchive = useCallback(async () => {
        await editProperty({
            operations: [{ op: "replace", path: "/isArchived", value: !editingItem?.isArchived }],
        })
        closeModal()
    }, [editProperty, editingItem, closeModal])

    return (
        <CustomDialog
            isFullScreen={false}
            title={translate(editingItem?.isArchived ? "unzip" : "archived", {
                name: editingItem?.name,
            })}
            onClose={closeModal}
            open={modalOpen === AppModals.HandleArchivedProperties}
            maxWidth='xs'
            onAccept={handleArchive}
            acceptText={translate(editingItem?.isArchived ? "unzip" : "archived")}
            closeText={translate("cancel")}
            closeButtonColor={"primary"}
            variantCloseButton={editingItem?.shouldNotifyForRenewal ? "contained" : "outlined"}
            acceptButtonColor={editingItem?.shouldNotifyForRenewal ? "error" : "primary"}
            isDisabled={isEditPropertyLoading}
            isLoading={isEditPropertyLoading}
        >
            <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                <Typography>
                    {translate(editingItem?.isArchived ? "unzip_properties" : "archived_properties", {
                        name: editingItem?.name,
                    })}
                </Typography>
            </Box>
        </CustomDialog>
    )
}
