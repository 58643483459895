import { Page, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Paper, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { styled, useTheme } from "@mui/material/styles"
import { setContext } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"

const NoRentingPeriods = () => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const handleContextReset = () => {
        dispatch(setContext(undefined))
    }

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile, theme],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    return (
        <Page title={translate("add_renting_period")}>
            <ContentStyle>
                <PaperStyle elevation={5}>
                    <Typography color={theme.palette.primary.dark} variant='h5'>
                        {translate("renting_period_notifications.no_renting_period")}
                    </Typography>
                    <Typography color={theme.palette.grey.A700} variant='body2'>
                        {translate("renting_period_notifications.no_renting_period_description")}
                    </Typography>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' alignItems='center' gap={2}>
                        <Button size='medium' color='primary' onClick={handleContextReset}>
                            {translate("change_context")}
                        </Button>
                    </Box>
                </PaperStyle>
            </ContentStyle>
        </Page>
    )
}

export default NoRentingPeriods
