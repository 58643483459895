import { useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { useUserTenantRequests } from "../../../queries/userData"
import NotificationHeader from "./NotificationHeader"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUnitsQueryFn } from "../../../queries/units"
import { updateRentingPeriod } from "../../../mutations/user"
import { setContext } from "../../../redux/slices/App"
import { useDispatch } from "../../../redux/store"
import { LoadingButton } from "@mui/lab"
import MetersListAddTenant from "../../propertyDetails/Tenants/addTenant/MetersListAddTenant"

interface RentingPeriodNotificationProps {
    nextText: string
    onNextClick: () => void
    isNextLoading: boolean
}

const RentingPeriodNotification = ({ nextText, onNextClick, isNextLoading }: RentingPeriodNotificationProps) => {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { data: tenantRequests } = useUserTenantRequests()
    const { translate } = useLocales()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: units } = useUnitsQueryFn()
    const dispatch = useDispatch()
    const { mutateAsync: updateTenant, isPending: isLoading } = updateRentingPeriod()

    const handleUpdate = useCallback(
        async (tenantId: number, accept: boolean) => {
            await updateTenant({ accept, tenantId })
        },
        [updateTenant],
    )

    const handleContextReset = () => {
        dispatch(setContext(undefined))
    }

    if (tenantRequests == null || tenantRequests.length == 0 || currencies == null || units == null) return null

    return (
        <>
            <NotificationHeader request={tenantRequests[0]} />
            <Box display='flex' flexDirection='column' width='100%' gap={2} marginTop={2}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate("meters")}
                </Typography>
                <MetersListAddTenant meters={tenantRequests[0].meters} isDisabled={true} />
            </Box>
            <Box display='flex' flexDirection='row-reverse' width='100%' gap={2} paddingTop={isMobile ? 2 : undefined}>
                <LoadingButton
                    color={"primary"}
                    loading={isNextLoading || isLoading}
                    disabled={isNextLoading || isLoading}
                    variant='contained'
                    onClick={onNextClick}
                >
                    {nextText}
                </LoadingButton>
                <LoadingButton
                    loading={isLoading}
                    disabled={isLoading}
                    variant='contained'
                    onClick={() => handleUpdate(tenantRequests[0].id, false)}
                    color='error'
                >
                    {translate("decline")}
                </LoadingButton>
                <Button size='medium' color='primary' onClick={handleContextReset}>
                    {translate("change_context")}
                </Button>
            </Box>
        </>
    )
}

export default RentingPeriodNotification
