import { Box, Button } from "@mui/material"
import React, { useCallback } from "react"
import { useIsMobile, useLocales } from "rentzz"
import ChooseCardPage from "../../income/add-income/ChooseCardPage"
import { InvoicingTypes } from "./Invoicing"
import { invoicingTypeAtom } from "../../../utils/atom"
import { useSetAtom } from "jotai"

interface Props {
    onClose: () => void
}
export default function ChooseInvoicingTypePage({ onClose }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const setInvoicingType = useSetAtom(invoicingTypeAtom)

    const handleDryRunInvoicing = useCallback(() => {
        setInvoicingType(InvoicingTypes.DryRunInvoicing)
    }, [setInvoicingType])

    const handleInvoicing = useCallback(() => {
        setInvoicingType(InvoicingTypes.Invoicing)
    }, [setInvoicingType])

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={2}>
            <>
                <ChooseCardPage title={"dry-run-invoicing"} icon={"mdi:invoice-list-outline"} onClick={handleDryRunInvoicing} />
                <ChooseCardPage title={translate("invoicing")} icon={"mdi:invoice-multiple-outline"} onClick={handleInvoicing} />
            </>

            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={onClose} fullWidth={isMobile} variant={isMobile ? "contained" : "text"}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
