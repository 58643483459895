import { FormProvider, RHFDayPicker, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddRecurringExpenseRecurrenceStepSchema } from "../../../validations/recurringexpense.recurrenceStep"
import { Box, Button, Typography } from "@mui/material"
import React, { useEffect } from "react"

export interface AddRecurringExpenseRecurrenceStepData {
    name: string
    recurrencePeriod: number[]
}

export interface AddRecurringExpenseRecurrenceStepProps {
    presetValues?: AddRecurringExpenseRecurrenceStepData
    onSave: (data: AddRecurringExpenseRecurrenceStepData) => void
    onClose: () => void
}

const AddRecurringExpenseRecurrenceStep = ({ presetValues, onSave, onClose }: AddRecurringExpenseRecurrenceStepProps) => {
    const methods = useForm<AddRecurringExpenseRecurrenceStepData>({
        resolver: yupResolver(AddRecurringExpenseRecurrenceStepSchema),
        mode: "onChange",
        defaultValues: presetValues,
    })

    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { handleSubmit, trigger, watch } = methods

    useEffect(() => {
        const subscription = watch(() => {
            trigger("recurrencePeriod")
        })
        return () => subscription.unsubscribe()
    }, [])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box justifyContent='center' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                        <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                            <RHFTextField
                                name='name'
                                label={translate("name")}
                                placeholder={translate("examples.recurring_expense_name")}
                                size='small'
                                required
                            />
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection={"column"} alignItems={"center"}>
                        <Typography alignSelf={"center"} marginTop={1} fontSize={14} fontWeight={"bold"}>
                            {translate("choose_recurring_days")} *
                        </Typography>
                        <RHFDayPicker name={"recurrencePeriod"} defaultValue={presetValues?.recurrencePeriod ?? []} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={onClose}>
                        {translate("cancel")}
                    </Button>
                    <Button type='submit' variant='contained' color={"primary"}>
                        {translate("next")}
                    </Button>
                </Box>
            </FormProvider>
        </>
    )
}

export default AddRecurringExpenseRecurrenceStep
