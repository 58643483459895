import { ExpenseProviderFieldDefinition, MeterFieldType, MeterProviderFieldDefinition, ProviderType } from "../../../../../redux/slices/AddProperty"
import { useForm } from "react-hook-form"
import { FormProvider, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { useSelector } from "../../../../../redux/store"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../queries/userData"

export interface ProviderCredentialsProps {
    onBack: () => void
    onSubmit: (fields: Record<string, unknown>) => void
    fields: ExpenseProviderFieldDefinition[] | MeterProviderFieldDefinition[]
}

const ProviderCredentials = ({ fields, onBack, onSubmit }: ProviderCredentialsProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { editingItem } = useSelector((state) => state.appState)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const methods = useForm<Record<string, unknown>>({
        mode: "onChange",
    })

    const { handleSubmit } = methods

    const innerOnSubmit = useCallback(
        (data: Record<string, unknown>) => {
            onSubmit(data)
        },
        [onSubmit],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(innerOnSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} pt={3}>
                {fields.map((f) => (
                    <RHFTextField
                        required
                        type={f.fieldType === MeterFieldType.Password ? "password" : "text"}
                        key={f.name}
                        name={f.name}
                        label={translate(f.label)}
                        fieldDescription={translate(f.description)}
                    />
                ))}
                {editingItem?.providerType === ProviderType.Expense && (
                    <RHFSelect name={"assignee"} size='small' variant={"outlined"} label={translate("expense_provider_assignee")} required>
                        <MenuItem value={0} style={{ textTransform: "none" }}>
                            {translate(`expenses.assignee-0${customPaidBy ? "-" + customPaidBy : ""}`)}
                        </MenuItem>
                        <MenuItem value={1} style={{ textTransform: "none" }}>
                            {translate("expenses.assignee-1")}
                        </MenuItem>
                    </RHFSelect>
                )}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={onBack}>
                    {translate("previous")}
                </Button>
                <Button color={"primary"} type='submit' variant='contained'>
                    {translate("next")}
                </Button>
            </Box>
        </FormProvider>
    )
}

export default ProviderCredentials
