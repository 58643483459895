import { Tab, Tabs } from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { a11yProps } from "../../pages/PropertyDetails"
import { useSelector } from "../../redux/store"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import { useLocales } from "rentzz"
import { useAtom } from "jotai"
import { propertyDetailsPage, PropertyPages } from "../../utils/atom"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function PropertyDetailsPageTabs() {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { page } = useParams()
    const navigate = useNavigate()
    const { translate } = useLocales()
    const [propertyPage, setPropertyPage] = useAtom(propertyDetailsPage)
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    useEffect(() => {
        switch (page) {
            case "general":
                setPropertyPage(PropertyPages.GENERAL)
                break
            case "notes":
                setPropertyPage(PropertyPages.NOTES)
                break
            case "expenses":
                setPropertyPage(PropertyPages.EXPENSES)
                break
            case "meters":
                setPropertyPage(PropertyPages.METERS)
                break
            case "tenants":
                setPropertyPage(PropertyPages.TENANTS)
                break
            case "income":
                setPropertyPage(PropertyPages.INCOME)
                break
            case "insurance":
                setPropertyPage(PropertyPages.INSURANCE)
                break
            case "documents":
                setPropertyPage(PropertyPages.DOCUMENTS)
                break
            case "revision":
                setPropertyPage(PropertyPages.REVISION)
                break
            case "configurations":
                setPropertyPage(PropertyPages.CONFIGURATIONS)
                break
            case "marketing":
                setPropertyPage(PropertyPages.MARKETING)
                break
            default:
                setPropertyPage(PropertyPages.GENERAL)
        }
    }, [page])

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: PropertyPages) => {
            switch (newValue) {
                case PropertyPages.GENERAL:
                    navigate(`/properties/${currentPropertyId}`)
                    break
                case PropertyPages.NOTES:
                    navigate(`/properties/${currentPropertyId}/notes`)
                    break
                case PropertyPages.EXPENSES:
                    navigate(`/properties/${currentPropertyId}/expenses`)
                    break
                case PropertyPages.METERS:
                    navigate(`/properties/${currentPropertyId}/meters`)
                    break
                case PropertyPages.TENANTS:
                    navigate(`/properties/${currentPropertyId}/tenants`)
                    break
                case PropertyPages.INCOME:
                    navigate(`/properties/${currentPropertyId}/income`)
                    break
                case PropertyPages.INSURANCE:
                    navigate(`/properties/${currentPropertyId}/insurance`)
                    break
                case PropertyPages.DOCUMENTS:
                    navigate(`/properties/${currentPropertyId}/documents`)
                    break
                case PropertyPages.REVISION:
                    navigate(`/properties/${currentPropertyId}/revision`)
                    break
                case PropertyPages.CONFIGURATIONS:
                    navigate(`/properties/${currentPropertyId}/configurations`)
                    break
                case PropertyPages.MARKETING:
                    navigate(`/properties/${currentPropertyId}/marketing`)
                    break
            }
        },
        [navigate, currentPropertyId],
    )

    return (
        <Tabs value={propertyPage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
            <Tab label={translate("general")} {...a11yProps(0)} />
            <Tab label={translate("notes.title")} {...a11yProps(1)} />
            <Tab label={translate("expenses_title")} {...a11yProps(2)} />
            <Tab label={translate("income")} {...a11yProps(3)} />
            <Tab label={translate("meters")} {...a11yProps(4)} />
            <Tab label={translate("tenants")} {...a11yProps(5)} />
            <Tab label={translate("insurance.name")} {...a11yProps(6)} />
            <Tab label={translate("files")} {...a11yProps(7)} />
            <Tab label={translate("revisions")} {...a11yProps(8)} />
            <Tab label={translate("marketing")} {...a11yProps(9)} />
            {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <Tab label={translate("configurations")} {...a11yProps(10)} />
            )}
        </Tabs>
    )
}
