import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { AddNoteRequest } from "../sections/propertyDetails/General/details/notes/AddNoteForm"
import * as jsonpatch from "fast-json-patch"

export const addNoteMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddNoteRequest }) => Api.addNote(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Notes])
            }
        },
    })
}

export const deleteNoteMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ noteId }: { noteId: number; propertyId?: number }) => Api.deleteNote({ noteId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Notes])
            }
        },
    })
}

export const updateNoteMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ noteId, operations }: { propertyId?: number; noteId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateNotes({
                noteId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Notes])
            }
        },
    })
}
