import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import AddGroupForm, { AddGroupRequest } from "../../../sections/groups/AddGroupForm"
import { addGroupMutation, updateGroupPropertiesMutation } from "../../../mutations/groups"
import { useUserGroupsQueryFn } from "../../../queries/groups"

export default function AddGroupGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addGroup, isPending: isAddGroupLoading } = addGroupMutation()
    const { mutateAsync: updateGroupProperties, isPending: arePropertiesUpdatingLoading } = updateGroupPropertiesMutation()
    const { data: groups } = useUserGroupsQueryFn()

    const currentGroup = useMemo(() => groups?.find((g) => g.id === editingItem?.id), [editingItem, groups])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onSave = useCallback(
        async (data: AddGroupRequest) => {
            if (currentGroup != null && modalOpen === AppModals.EditGroupProperties) {
                const currentGroupPropertiesIds = currentGroup.properties.map((p) => p.propertyId)
                const propertiesToAdd = data.properties.filter((property) => !currentGroupPropertiesIds.includes(property))
                const propertiesToRemove = currentGroupPropertiesIds.filter((property) => !data.properties.includes(property))

                if (propertiesToAdd.length === 0 && propertiesToRemove.length === 0) {
                    closeModal()
                    return
                }

                await Promise.all(
                    propertiesToAdd.map(async (propertyId) => {
                        await updateGroupProperties({
                            groupId: currentGroup.id,
                            propertyId: propertyId,
                            operationType: 0,
                        })
                    }),
                )

                await Promise.all(
                    propertiesToRemove.map(async (propertyId) => {
                        await updateGroupProperties({
                            groupId: currentGroup.id,
                            propertyId: propertyId,
                            operationType: 1,
                        })
                    }),
                )

                closeModal()
            } else {
                await addGroup({ data })
                closeModal()
            }
        },
        [addGroup, closeModal, currentGroup, modalOpen, updateGroupProperties],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.EditGroupProperties ? "handle_properties" : "add_group")}
                open={!isMobile && (modalOpen === AppModals.AddGroup || modalOpen === AppModals.EditGroupProperties)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddGroupForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddGroupLoading || arePropertiesUpdatingLoading}
                    defaultValues={currentGroup}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditGroupProperties ? "handle_properties" : "add_group")}
                isOpen={isMobile && (modalOpen === AppModals.AddGroup || modalOpen === AppModals.EditGroupProperties)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddGroupForm
                        onClose={closeModal}
                        onSave={onSave}
                        isLoading={isAddGroupLoading || arePropertiesUpdatingLoading}
                        defaultValues={currentGroup}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
