// @mui
import { enUS, roRO } from "@mui/material/locale"
import { SettingsValueProps } from "rentzz"
// components

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
}

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
}

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
}

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
    themeMode: "light",
    themeDirection: "ltr",
    themeContrast: "default",
    themeLayout: "horizontal",
    themeColorPresets: "default",
    themeStretch: false,
}

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: "English",
        value: "en",
        systemValue: enUS,
        icon: "/assets/icons/flags/ic_flag_en.svg",
    },
    {
        label: "Romana",
        value: "ro",
        systemValue: roRO,
        icon: "/assets/icons/flags/ic_flag_ro.svg",
    },
]

export const availableStories = [
    {
        value: 0,
        label: "ground",
    },
    ...Array.from(Array(20).keys())
        .filter((v) => v > 0)
        .map((v) => ({
            value: v,
            label: "floor",
        })),
]

export const defaultLang = allLangs[0] // English

Array.prototype.swap = function (a: number, b: number) {
    if (a < 0 || a >= this.length || b < 0 || b >= this.length) {
        return
    }

    const temp = this[a]
    this[a] = this[b]
    this[b] = temp
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> {
        swap(a: number, b: number): void
    }
}
