import { array, number, object, string } from "yup"

export const AddGeneralDocumentSchema = object().shape({
    name: string().required("name_required").min(3, "min_3_characters"),
    content: string()
        .min(3, "min_3_characters")
        .test("test", "text_required", (value, context) => {
            const { files } = context.parent
            return value || (files && files.length > 0)
        }),
    files: array().test("test", "file_required", (value, context) => {
        const { content } = context.parent
        if (!content) {
            return value && value.length === 1
        }
        return !value || value.length === 0
    }),
})

export const ConfigureNotificationSchema = object().shape({
    notificationId: number().required("name_required"),
    variables: array().required(),
})
