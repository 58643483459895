import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useNotificationsTemplatesQuery } from "../../../../queries/userData"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteContent from "../../../../components/DeleteContent"
import { deleteNotificationMutation } from "../../../../mutations/notifications"

export default function DeleteNotificationGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteNotification, isPending: isDeleteNotificationPending } = deleteNotificationMutation()
    const { data: notifications } = useNotificationsTemplatesQuery()

    const currentNotification = useMemo(
        () => notifications?.items?.find((notification) => notification.id === editingItem?.id),
        [notifications, editingItem],
    )

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onDelete = useCallback(async () => {
        if (currentNotification == null) return
        await deleteNotification(currentNotification.id)
        onClose()
    }, [currentNotification, deleteNotification, dispatch, onClose])

    return (
        <>
            <CustomDialog
                title={translate("delete_notification")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteNotificationTemplate}
                onClose={onClose}
                isFullScreen={false}
                variantCloseButton={"text"}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_notification_content", { name: currentNotification?.name })}
                    isLoading={isDeleteNotificationPending}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteNotificationTemplate}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_notification")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_notification_content", { name: currentNotification?.name })}
                    isLoading={isDeleteNotificationPending}
                />
            </DetailsSheet>
        </>
    )
}
