import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { AppContext } from "rentzz"
import { useSelector } from "../redux/store"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags, useUserDataQueryFn } from "./userData"
import { taskFilterState } from "../utils/atom"
import { useAtomValue } from "jotai"

export const useAllSectionsQueryFn = () => {
    const { context } = useSelector((state) => state.appState)
    return useQuery({
        queryFn: Api.fetchUserSections,
        queryKey: [SimpleQueryIDs.UserTaskSections],
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const useSectionItemsQueryFn = (id: string) => {
    const { context } = useSelector((state) => state.appState)
    const taskFilter = useAtomValue(taskFilterState)
    const { data: userData } = useUserDataQueryFn()

    return useQuery({
        queryFn: () => Api.fetchItemsSection(id, taskFilter, userData?.id),
        queryKey: [ComplexQueryIDs.SectionTasks, { sectionId: id, taskFilter, userId: userData?.id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!id && userData != null,
    })
}

export const usePossibleAssignedUsersQuery = (taskId: string, sectionId: string | undefined) => {
    const { context } = useSelector((state) => state.appState)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    return useQuery({
        queryFn: Api.fetchTaskPossibleUsers,
        queryKey: [ComplexQueryIDs.TaskPossibleUsers, { sectionId, taskId }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!taskId && !!sectionId && (isWhitelabel || import.meta.env.DEV),
    })
}

export const useTaskCommentsQueryFn = (taskId: string, sectionId: string | undefined) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTaskComments,
        queryKey: [ComplexQueryIDs.TaskComments, { sectionId, taskId }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!taskId && !!sectionId,
    })
}

export const useTenantTasksQueryFn = (rentingPeriodId: number | undefined) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTenantTasks,
        queryKey: [ComplexQueryIDs.TenantTasks, { rentingPeriodId }],
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && !!rentingPeriodId,
    })
}

export const useTenantTaskCommentsQueryFn = (rentingPeriodId: number | undefined, taskId: string) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryFn: Api.fetchTenantTaskComments,
        queryKey: [ComplexQueryIDs.TenantTaskComments, { rentingPeriodId, taskId }],
        staleTime: Infinity,
        enabled: context === AppContext.Tenant && !!rentingPeriodId && !!rentingPeriodId,
    })
}
