import { Page, useLocales } from "rentzz"
import { useParams } from "react-router"
import React from "react"
import { useGeneralDocumentDetailsQuery } from "../../../queries/generalDocuments"
import AddOrEditGeneralDocument from "./AddOrEditGeneralDocument"

export default function AddOrEditGeneralDocumentPage() {
    const { translate } = useLocales()
    const { generalDocumentId } = useParams()
    const { data: generalDocumentDetails } = useGeneralDocumentDetailsQuery(Number(generalDocumentId))

    return (
        <Page title={translate("add_document")} paddingBottom={2}>
            <AddOrEditGeneralDocument defaultValues={generalDocumentDetails} />
        </Page>
    )
}
