import { RHFAutocomplete, RHFTextField, RHFUpload, useLocales } from "rentzz"
import { Box } from "@mui/material"
import * as React from "react"
import { useRentingPeriodDetails } from "../../../../../../../queries/tenants"
import { useSelector } from "../../../../../../../redux/store"

export default function SendTenantNotificationDetails() {
    const { translate } = useLocales()
    const { editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId ?? editingItem?.rentingPeriodId)

    return (
        <Box display={"flex"} flexDirection={"column"} paddingTop={2} gap={2}>
            <RHFTextField name={"subject"} placeholder={translate("payment-notifications-title")} label={translate("subject")} fullWidth required />
            <RHFAutocomplete
                autocompleteProps={{
                    sx: {
                        width: "100%",
                    },
                    noOptionsText: translate("no_value_found"),
                }}
                matchId
                multiple
                freeSolo={false}
                name={"emails"}
                options={rentingPeriodDetails?.invitedUsers.map((user) => ({ id: user.mail, label: user.mail })) ?? []}
                label={translate("notification_to")}
                required
            />
            <RHFUpload name={"files"} multiple icon={"mdi:file-document-plus-outline"} />
        </Box>
    )
}
