import { Box, CircularProgress } from "@mui/material"
import React, { useEffect, useMemo, useRef } from "react"
import { useInfinitePendingMeterValuesQueryFn } from "../../../../../queries/meters"
import PendingReadingCard from "./PendingReadingCard"
import { useDispatch } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"

export default function MobilePendingReadingsList() {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { data: pendingMeters, isLoading: arePendingMetersLoading, hasNextPage, fetchNextPage } = useInfinitePendingMeterValuesQueryFn()

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) fetchNextPage()
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    return (
        <>
            {arePendingMetersLoading ? (
                <Box display={"flex"} justifyContent={"center"} height={300}>
                    <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                </Box>
            ) : (
                <Box sx={{ overflowY: "auto", maxHeight: 500 }}>
                    <Box gap={1} display={"flex"} flexDirection={"column"} px={1} py={2}>
                        {pendingMeters?.pages.map((group, i) => (
                            <React.Fragment key={i}>
                                {group.items.map((reading) => (
                                    <PendingReadingCard
                                        key={reading.id}
                                        reading={reading}
                                        onClick={() => {
                                            dispatch(setModalOpen(AppModals.PendingReadingDetails))
                                            dispatch(setEditingItem({ readingId: reading.id, meterId: reading.meterId }))
                                        }}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                        {hasNextPage && (
                            <Box ref={ref} display={"flex"} justifyContent={"center"} paddingTop={2}>
                                <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}
