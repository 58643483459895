import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import { useRevisionObservationsQueryFn } from "../../../queries/revision"
import { addObservationMutation, updateObservationMutation } from "../../../mutations/revison"
import AddObservationForm, { AddObservationRequest } from "../../../sections/propertyDetails/revision/AddObservationForm"
import * as jsonpatch from "fast-json-patch"
import { DateTime } from "luxon"

export default function AddOrEditObservationGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addObservation, isPending: isAddObservationLoading } = addObservationMutation()
    const { mutateAsync: updateObservation, isPending: isUpdateObservation } = updateObservationMutation()
    const { data: observations } = useRevisionObservationsQueryFn(editingItem?.revisionId)

    const currentObservation = useMemo(() => observations?.find((obs) => obs.id === editingItem?.observationId), [observations, editingItem])

    const closeModal = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.AddObservation) {
                dispatch(setModalOpen(AppModals.ObservationsList))
            } else dispatch(setModalOpen(AppModals.ObservationDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile, modalOpen])

    const onSave = useCallback(
        async (data: AddObservationRequest) => {
            if (currentObservation != null && editingItem != null) {
                const operations = jsonpatch.compare(
                    { ...currentObservation, files: [], observationDate: currentObservation?.observationDate.toISODate() },
                    { ...data, files: [], observationDate: DateTime.fromISO(data.observationDate).toISODate() },
                )
                if (!data.id) return
                await updateObservation({
                    revisionId: editingItem.revisionId,
                    observationId: currentObservation.id,
                    operations,
                })
            } else {
                await addObservation({ data })
            }
        },
        [addObservation, currentObservation, editingItem, updateObservation],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.EditObservation ? "edit_observation" : "add_observation")}
                open={!isMobile && (modalOpen === AppModals.AddObservation || modalOpen === AppModals.EditObservation)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddObservationForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddObservationLoading || isUpdateObservation}
                    defaultValues={currentObservation}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditObservation ? "edit_observation" : "add_observation")}
                isOpen={isMobile && (modalOpen === AppModals.AddObservation || modalOpen === AppModals.EditObservation)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddObservationForm
                        onClose={closeModal}
                        onSave={onSave}
                        isLoading={isAddObservationLoading || isUpdateObservation}
                        defaultValues={modalOpen === AppModals.EditObservation ? currentObservation : undefined}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
