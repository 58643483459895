import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material"
import React, { SyntheticEvent, useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteSheetContent from "../../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../../dialogs/DeleteDialog"
import { blockUserMutation } from "../../../../mutations/user"
import { useInfiniteActiveUsers } from "../../../../queries/userData"

export default function BlockUserGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: blockUser, isPending: isBlockUserLoading } = blockUserMutation()
    const { data: activeUsers } = useInfiniteActiveUsers([{ field: "lastActive", sort: "desc" }])

    const currentUser = useMemo(
        () => activeUsers?.pages.map((page) => page.items.find((item) => item.id === editingItem?.id)),
        [activeUsers, editingItem],
    )

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onBlock = useCallback(
        async (e: SyntheticEvent) => {
            if (currentUser == null || currentUser[0] == null) return
            e.stopPropagation()
            if (!currentUser) return
            await blockUser({ userId: currentUser[0].id })

            onClose()
        },
        [currentUser, blockUser, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={`${translate("block_user")}?`}
                isOpen={isMobile && modalOpen === AppModals.BlockUser}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("block_user_content", { name: (currentUser ?? [])[0]?.fullName })}
                    onClose={onClose}
                    isDeleteLoading={isBlockUserLoading}
                    onAccept={onBlock}
                    submitText={"block"}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.BlockUser}
                isDeleteLoading={isBlockUserLoading}
                title={`${translate("block_user")}?`}
                content={translate("block_user_content", { name: (currentUser ?? [])[0]?.fullName })}
                onClose={onClose}
                onAccept={onBlock}
                submitText={"block"}
            />
        </>
    )
}
