import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import DesktopIncomeList from "./DesktopIncomeList"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../queries/currency"
import MobileIncomesList from "./mobile/MobileIncomesList"
import { useIsMobile } from "rentzz"

const Income = () => {
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const isMobile = useIsMobile()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    return isMobile ? <MobileIncomesList getAmount={getAmount} /> : <DesktopIncomeList getAmount={getAmount} />
}

export default Income
