import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import PropertyDetails from "../../../dialogs/add-edit-property/steps/PropertyDetails"
import { usePropertyDetailsQueryFn } from "../../../queries"

export default function EditPropertyGuard() {
    const { modalOpen } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [])

    return (
        <>
            <CustomDialog
                title={translate("edit_property")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.EditProperty}
                maxWidth='sm'
                isFullScreen={isMobile}
                titleAlign={"center"}
            >
                <PropertyDetails defaultValues={propertyDetails} isEditDialogOpen={modalOpen === AppModals.EditProperty} closeDialog={onClose} />
            </CustomDialog>
            <DetailsSheet
                title={translate("edit_property")}
                isOpen={isMobile && modalOpen === AppModals.EditProperty}
                onClose={onClose}
                isLoading={false}
            >
                <PropertyDetails defaultValues={propertyDetails} isEditDialogOpen={modalOpen === AppModals.EditProperty} closeDialog={onClose} />
            </DetailsSheet>
        </>
    )
}
