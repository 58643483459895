import React, { useCallback, useMemo } from "react"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../redux/store"
import MapPicker from "./steps/MapPicker"
import AddPropertyName from "./steps/AddPropertyName"
import PropertyDetails from "./steps/PropertyDetails"
import { AddPropertyPage } from "../../redux/slices/AddProperty"

export default function AddProperty() {
    const { activeStep } = useSelector((state) => state.addProperty)
    const isMobile = useIsMobile()
    const { translate, currentLang } = useLocales()

    const steps = useMemo(
        () => [
            {
                title: translate("name"),
            },
            {
                title: translate("details"),
            },
            {
                title: translate("location"),
            },
        ],
        [translate, currentLang],
    )

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddPropertyPage.Name:
                return <AddPropertyName />
            case AddPropertyPage.Details:
                return <PropertyDetails />
            case AddPropertyPage.Location:
                return <MapPicker />
        }
    }, [activeStep])

    return (
        <Box paddingTop={isMobile ? 0 : 2}>
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                {steps.map((step, index) => {
                    return (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    )
                })}
            </Stepper>
            {!isMobile && renderActiveStep()}
        </Box>
    )
}
