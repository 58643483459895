import CustomCard from "../../../../components/CustomCard"
import { Box, Typography } from "@mui/material"
import { AppContext, Iconify, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { PropertyFileType, PropertyMeterValue } from "../../../../redux/slices/AddProperty"
import { alpha, useTheme } from "@mui/material/styles"
import { DateTime } from "luxon"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"

interface Props {
    reading: PropertyMeterValue
    getValue: (value: number) => string
    isFirstReading: boolean
}

export default function ReadingCustomCard({ reading, getValue, isFirstReading }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { editingItem, context } = useSelector((state) => state.appState)

    const handleOnClick = useCallback(() => {
        if (context === AppContext.Tenant && isFirstReading) return
        dispatch(setEditingItem({ ...editingItem, readingId: reading.id, type: PropertyFileType.MeterValue, createdBy: reading.createdBy }))
        dispatch(setModalOpen(AppModals.EntityFileManager))
    }, [reading, editingItem, dispatch, context, isFirstReading])

    return (
        <CustomCard key={reading?.id} onClick={handleOnClick} isCardClickable={true} px={1} height={70}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:file-send"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(theme.palette.primary.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {reading.createdBy}
                        </Typography>

                        <Typography variant='caption' color={theme.palette.grey.A400}>
                            {reading.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Typography fontSize={12} fontWeight={"bold"} alignSelf={"center"}>
                        {getValue(reading?.currentValue)}
                    </Typography>
                    <Iconify icon={"mdi:arrow-up"} alignSelf={"center"} width={12} height={15} />
                    <Typography
                        fontSize={12}
                        fontWeight={reading.previousValue === -1 ? "" : "bold"}
                        color={reading.previousValue === -1 ? theme.palette.grey.A400 : ""}
                        alignSelf={"center"}
                    >
                        {reading.previousValue === -1 ? translate("new_meter") : getValue(reading?.previousValue)}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
