import { useSelector } from "../redux/store"
import { useQuery } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { AppContext } from "rentzz"
import { DateTime } from "luxon"

export interface GeneralDocumentDetails {
    id: number
    content: string
    variables: { pattern: string }[]
    name: string
}
export interface GeneralDocumentBase {
    id: number
    name: string
}

export interface GeneralDocumentDto extends GeneralDocumentBase {
    createdAt: string
    lastModifiedAt: string
}

export interface GeneralDocument extends GeneralDocumentBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface GeneralDocumentResponseBase {
    count: number
}

export interface GeneralDocumentResponseDto extends GeneralDocumentResponseBase {
    items: GeneralDocumentDto[]
}
export interface GeneralDocumentResponse extends GeneralDocumentResponseBase {
    items: GeneralDocument[]
}
export const useGeneralDocumentsQuery = () => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.GeneralDocuments],
        queryFn: Api.fetchGeneralDocuments,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
    })
}

export const useGeneralDocumentDetailsQuery = (documentId?: number) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.GeneralDocumentDetails, { documentId }],
        queryFn: Api.getGeneralDocumentDetails,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!documentId,
    })
}
