import { CustomDialog, useLocales } from "rentzz"
import { Box, Breakpoint } from "@mui/material"
import React, { ReactNode, SyntheticEvent } from "react"

export interface DeleteDialogProps {
    isVisible: boolean
    title?: string
    content?: string | ReactNode
    onClose: (e: SyntheticEvent) => void
    onAccept: (e: SyntheticEvent) => Promise<void>
    isDeleteDisabled?: boolean
    isDeleteLoading: boolean
    submitText?: string
    maxWidth?: false | Breakpoint | undefined
    acceptButtonColor?: "error" | "inherit" | "primary" | "secondary" | "success" | "info" | "warning" | undefined
}

export default function DeleteDialog({
    isVisible,
    title,
    onClose,
    onAccept,
    content,
    isDeleteDisabled,
    isDeleteLoading,
    submitText,
    acceptButtonColor,
    maxWidth,
}: DeleteDialogProps) {
    const { translate } = useLocales()

    return (
        <CustomDialog
            isFullScreen={false}
            title={title}
            onClose={onClose}
            open={isVisible}
            maxWidth={maxWidth ?? "xs"}
            onAccept={onAccept}
            acceptText={translate(submitText ?? "delete")}
            closeText={translate("cancel")}
            acceptButtonColor={acceptButtonColor ?? "error"}
            variantCloseButton={"text"}
            isDisabled={isDeleteDisabled}
            isLoading={isDeleteLoading}
            closeButtonColor={"primary"}
        >
            <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                {content}
            </Box>
        </CustomDialog>
    )
}
