import { useQuery } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { AppContext } from "rentzz"

export const useSearchQueryFn = (query?: string) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.Search,
            {
                query,
                context,
            },
        ],
        queryFn: () => Api.search(query!),
        staleTime: 100000,
        enabled: context === AppContext.Owner && query != null && query.length >= 3,
    })
}
