import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import { FormProvider, LightTooltip, RHFSelect, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { LoadingButton } from "@mui/lab"
import { updateMeterMutation } from "../../../mutations/property/meters/meters"
import { useMetersQueryFn } from "../../../queries/meters"
import { ConfiguredMeterProvider, OwnerPropertyMeter, ProviderStatus } from "../../../redux/slices/AddProperty"

interface MaintainingValueWhenNoReadingSentFormProps {
    meterId: number
    propertyId: number
    onClose?: () => void
    configuredProvider?: ConfiguredMeterProvider
}

const MaintainingValueWhenNoReadingSentForm = ({ meterId, propertyId, onClose, configuredProvider }: MaintainingValueWhenNoReadingSentFormProps) => {
    const isMobile = useIsMobile()
    const { data: meters } = useMetersQueryFn()
    const { translate } = useLocales()
    const { mutateAsync: updateMeter, isPending: isMeterUpdating } = updateMeterMutation()
    const currentMeter = useMemo(() => {
        return meters?.find((p) => p.id === meterId)
    }, [meters, meterId])
    const methods = useForm({
        defaultValues: {
            isMaintainingValueWhenNoReadingSent: (currentMeter as OwnerPropertyMeter)?.isMaintainingValueWhenNoReadingSent ?? false,
        },
    })
    const isProviderActive = useMemo(() => configuredProvider?.status === ProviderStatus.Active, [configuredProvider])

    const { handleSubmit, watch, setValue, getValues } = methods

    const onSave = useCallback(async () => {
        if (currentMeter == null || currentMeter.id === -1) return
        if ((currentMeter as OwnerPropertyMeter).isMaintainingValueWhenNoReadingSent !== getValues("isMaintainingValueWhenNoReadingSent")) {
            await updateMeter({
                meterId,
                propertyId,
                operations: [
                    { op: "replace", path: "/isMaintainingValueWhenNoReadingSent", value: getValues("isMaintainingValueWhenNoReadingSent") },
                ],
            })
            if (onClose) {
                onClose()
            }
        }
    }, [currentMeter, updateMeter, onClose, getValues])

    useEffect(() => {
        const subscription = watch((value) => {
            const isTrue = `${value.isMaintainingValueWhenNoReadingSent}` === "true"
            if (currentMeter && isTrue !== (currentMeter as OwnerPropertyMeter).isMaintainingValueWhenNoReadingSent && !isMobile) {
                onSave()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, meterId, currentMeter, isMobile, onSave])

    useEffect(() => {
        setValue("isMaintainingValueWhenNoReadingSent", (currentMeter as OwnerPropertyMeter)?.isMaintainingValueWhenNoReadingSent ?? false)
    }, [currentMeter])

    if (meters == null) return null
    if (isMeterUpdating && !isMobile) return <CircularProgress size='1rem' />

    return (
        <Box display='flex' flexDirection='column' width='100%' sx={{ textAlign: "center" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                {isMobile ? (
                    <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                        <LightTooltip
                            title={
                                isProviderActive
                                    ? (currentMeter as OwnerPropertyMeter).isMaintainingValueWhenNoReadingSent
                                        ? translate("maintainingReading")
                                        : translate("noMaintainingReading")
                                    : translate("active_provider_required")
                            }
                            arrow
                        >
                            <RHFSelect disabled={!isProviderActive} fullWidth name={"isMaintainingValueWhenNoReadingSent"} size='small'>
                                <MenuItem value={"true"} style={{ textTransform: "none" }}>
                                    {translate("yes")}
                                </MenuItem>
                                <MenuItem value={"false"} style={{ textTransform: "none" }}>
                                    {translate("nope")}
                                </MenuItem>
                            </RHFSelect>
                        </LightTooltip>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingX: 0,
                                paddingTop: 2,
                                gap: 2,
                                flexDirection: isMobile ? "column-reverse" : "row",
                            }}
                        >
                            <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isMeterUpdating}>
                                {translate("cancel")}
                            </Button>
                            <LoadingButton
                                type={"submit"}
                                variant='contained'
                                color={"primary"}
                                fullWidth={isMobile}
                                loading={isMeterUpdating}
                                disabled={isMeterUpdating}
                            >
                                {translate("update")}
                            </LoadingButton>
                        </Box>
                    </Box>
                ) : (
                    <Box display={"flex"} paddingY={1}>
                        <LightTooltip
                            title={
                                isProviderActive
                                    ? (currentMeter as OwnerPropertyMeter).isMaintainingValueWhenNoReadingSent
                                        ? translate("maintainingReading")
                                        : translate("noMaintainingReading")
                                    : translate("active_provider_required")
                            }
                            arrow
                            placement={"top"}
                        >
                            <RHFSelect disabled={!isProviderActive} fullWidth name={"isMaintainingValueWhenNoReadingSent"} size='small'>
                                <MenuItem value={"true"} style={{ textTransform: "none" }}>
                                    {translate("yes")}
                                </MenuItem>
                                <MenuItem value={"false"} style={{ textTransform: "none" }}>
                                    {translate("nope")}
                                </MenuItem>
                            </RHFSelect>
                        </LightTooltip>
                    </Box>
                )}
            </FormProvider>
        </Box>
    )
}

export default MaintainingValueWhenNoReadingSentForm
