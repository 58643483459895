import { alpha, styled } from "@mui/material/styles"
import { DateTime } from "luxon"
import { Theme } from "@mui/material"

export const hideScroll = {
    x: {
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    y: {
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
} as const

type BgBlurProps = {
    blur?: number
    opacity?: number
    color?: string
    imgUrl?: string
}
export function bgBlur(props?: BgBlurProps) {
    const color = props?.color || "#000000"
    const blur = props?.blur || 6
    const opacity = props?.opacity || 0.8
    const imgUrl = props?.imgUrl

    if (imgUrl) {
        return {
            position: "relative",
            backgroundImage: `url(${imgUrl})`,
            "&:before": {
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 9,
                content: "",
                width: "100%",
                height: "100%",
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity),
            },
        } as const
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity),
    }
}

export const StyledLabel = styled("span")(({ theme }) => ({
    ...theme.typography.caption,
    width: 120,
    flexShrink: 0,
    color: theme.palette.text.secondary,
    fontWeight: "bold",
}))

export function stringAvatar(name: string) {
    return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    }
}

export const stringAvatarWithTheme = (name: string, theme: Theme) => {
    if (name == null) return
    const nameParts = name.split(" ")
    let initials

    if (nameParts.length === 1) {
        initials = `${nameParts[0][0]}${nameParts[0][1]}`
    } else {
        initials = `${nameParts[0][0]}${nameParts[1][0]}`
    }

    return {
        sx: {
            bgcolor: theme.palette.primary.main,
            color: "white",
            width: 35,
            height: 35,
            padding: 1,
            fontSize: 16,
        },
        children: initials,
    }
}

export function fToNow(date: DateTime, locale: string) {
    const diffInSeconds = Math.floor((DateTime.now().toMillis() - date.toMillis()) / 1000)

    if (diffInSeconds >= 0 && diffInSeconds < 60) {
        if (locale === "ro") return "chiar acum"
        return "just now"
    } else {
        return DateTime.fromISO(date.toString()).toRelative({
            style: "long",
        })
    }
}
