import { Page, useIsMobile, useLocales } from "rentzz"
import { Container } from "@mui/material"
import { ContentStyle } from "../../../pages/PropertyDetails"
import React from "react"
import MobileGeneralDocumentsList from "./MobileGeneralDocumentsList"
import DesktopGeneralDocumentsList from "./DesktopGeneralDocumentsList"

export default function GeneralDocumentsPage() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Page title={translate("documents_type")} propertyName={translate("general_documents")}>
            <Container maxWidth={false}>
                <ContentStyle>{isMobile ? <MobileGeneralDocumentsList /> : <DesktopGeneralDocumentsList />}</ContentStyle>
            </Container>
        </Page>
    )
}
