import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { CreateNewLabelRequest } from "../sections/nomenclature/labels/AddOrEditNewLabel"
import { SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import * as jsonpatch from "fast-json-patch"

export const addNewLabelMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: CreateNewLabelRequest) => Api.addNewLabel({ data }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserLabels])
            }
        },
    })
}

export const updateLabelMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ labelId, operations }: { labelId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateLabelAsync({
                labelId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserLabels])
            }
        },
    })
}

export const deleteLabelMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteLabel,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.UserLabels])
        },
    })
}
