import React, { useMemo } from "react"
import { OwnerPropertyMeter, ProviderStatus, ProviderType } from "../../../../redux/slices/AddProperty"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../utils"
import { DateTime } from "luxon"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, Iconify, useLocales } from "rentzz"
import { useMetersQueryFn } from "../../../../queries/meters"
import { UserFlags, useUserPropertiesQuery } from "../../../../queries/userData"
import { Box } from "@mui/material"
import { headerMeterSheetColor } from "../AddOrEditMeterGuard"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

interface OwnerMeterDetailsProps {
    onClose: () => void
    getValue: (value: number, unitId: number) => string | undefined
}
export default function OwnerMeterDetails({ onClose, getValue }: OwnerMeterDetailsProps) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()
    const { data: properties } = useUserPropertiesQuery()
    const areMetersProvidersEnabled = useFeatureIsOn(UserFlags.MeterProviders.toString())
    const isNewReportingPeriod = useFeatureIsOn(UserFlags.IsNewReportingPeriod.toString())

    const currentMeter = useMemo(
        () => (meters as (OwnerPropertyMeter & { canWrite: boolean; canDelete: boolean })[])?.find((meter) => meter?.id === editingItem?.id),
        [meters, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentMeter == null) return []
        const getIconColor = () => {
            if (currentMeter.configuredProvider.status === ProviderStatus.Pending) return theme.palette.warning.main
            if (currentMeter.configuredProvider.status === ProviderStatus.Rejected) return theme.palette.error.main
            return theme.palette.success.main
        }
        return [
            {
                icon: currentMeter.requirePhotoForReading ? (
                    <CameraAltIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                ) : (
                    <NoPhotographyIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                ),
                title: currentMeter.requirePhotoForReading ? "meter_photo_yes" : "no_meter_photo",
                onClick: () => dispatch(setModalOpen(AppModals.EditMeterPhotoRequired)),
                isDisabled: !currentMeter.canWrite,
            },
            {
                icon: (
                    <Iconify icon={"mdi:numbers"} sx={{ fontSize: DESKTOP_ICON_SIZE }} color={currentMeter.requireInteger ? "primary" : "inherit"} />
                ),
                title: "integer_readings_format",
                onClick: () => dispatch(setModalOpen(AppModals.HandleReadingIntegerNumber)),
                isDisabled: !currentMeter.canWrite,
            },
            {
                icon: <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_reading",
                hidden: !currentMeter.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AddNewIndex))
                },
            },
            {
                icon:
                    currentMeter.configuredProvider == null ? (
                        <PersonAddIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    ) : (
                        <Iconify color={getIconColor} icon={"mdi:account-tie"} sx={{ fontSize: MOBILE_ICON_SIZE }} />
                    ),
                title:
                    currentMeter.configuredProvider == null
                        ? "add_new_provider"
                        : `rejected_provider_label-${currentMeter.configuredProvider.status}`,
                hidden: !areMetersProvidersEnabled || (currentMeter.configuredProvider == null ? !currentMeter.canWrite : false),
                onClick: () => {
                    if (currentMeter.configuredProvider == null) {
                        dispatch(setModalOpen(AppModals.AddMeterProvider))
                        dispatch(setEditingItem({ ...editingItem, providerType: ProviderType.Meter }))
                    } else {
                        dispatch(setModalOpen(AppModals.ProviderDetails))
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                providerId: currentMeter.configuredProvider.id,
                                name: currentMeter.configuredProvider.providerName,
                                providerType: ProviderType.Meter,
                            }),
                        )
                    }
                },
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_history",
                onClick: () => dispatch(setModalOpen(AppModals.MeterValuesHistory)),
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => dispatch(setModalOpen(AppModals.EditMeter)),
                hidden: !currentMeter.canWrite,
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => dispatch(setModalOpen(AppModals.DeleteMeter)),
                hidden: !currentMeter.canDelete,
            },
        ]
    }, [currentMeter, dispatch, theme, editingItem])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.MeterDetails}
            onClose={onClose}
            isLoading={currentMeter == null}
            title={currentMeter?.name}
            options={options}
            headerColor={headerMeterSheetColor(currentMeter?.lastModified, theme, false)}
            isMultiRow={true}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:meter-electric",
                    `${translate("initial_index")}: ${currentMeter && getValue(currentMeter.initialValue, currentMeter.unitId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:meter-electric",
                    `${translate("last_index")}: ${currentMeter && getValue(currentMeter.currentValue, currentMeter.unitId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-outline",
                    `${translate("last_index_date")} ${currentMeter?.lastModified?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("reading_period")}: ${currentMeter && (isNewReportingPeriod ? `${currentMeter.reportingPeriodStartDay}-${currentMeter.reportingPeriodEndDay}` : arrayOfNumbersToPeriod(currentMeter.reportingPeriod)?.join(", "))}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    "info",
                )}
                {renderActionSheetInfo(
                    "mdi:numbers",
                    `${currentMeter?.requireInteger ? translate("tenant_must_sent_integer_number") : translate("tenant_not_integer_number")}`,
                    currentMeter?.requireInteger ? theme.palette.primary.main : theme.palette.grey.A700,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    properties?.find((p) => p.id === currentMeter?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
