import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import UserCard from "../../../../../../components/UserCard"
import { Box } from "@mui/material"
import {
    getMailNotificationBackgroundColor,
    getMailNotificationColor,
    getMailNotificationIcon,
    getMailNotificationStatusLabelColor,
    getUserAcceptanceAccountBackgroundColor,
    getUserAcceptanceAccountColor,
    getUserAcceptanceIcon,
    getUserAcceptanceStatusLabelColor,
} from "../../../utils"
import { RentingPeriodMailNotificationStatus, RentingPeriodUserAcceptanceStatus } from "../../../../../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../../../redux/store"

export default function MobileRentingPeriodTenants() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    return (
        <Box padding={2} display={"flex"} flexDirection={"column"} rowGap={1}>
            {rentingPeriodDetails?.rentingPeriodTenants.map((tenant, index) => (
                <UserCard
                    key={index}
                    user={tenant}
                    onClick={() => {
                        dispatch(setModalOpen(AppModals.TenantDetails))
                        dispatch(setEditingItem({ mail: tenant.mail }))
                    }}
                    backgroundColor={
                        tenant.name === "-"
                            ? getMailNotificationBackgroundColor(tenant.status as RentingPeriodMailNotificationStatus, theme)
                            : getUserAcceptanceAccountBackgroundColor(tenant.status as RentingPeriodUserAcceptanceStatus, theme)
                    }
                    icon={
                        tenant.name === "-"
                            ? getMailNotificationIcon(tenant.status as RentingPeriodMailNotificationStatus)
                            : getUserAcceptanceIcon(tenant.status as RentingPeriodUserAcceptanceStatus)
                    }
                    iconColor={
                        tenant.name === "-"
                            ? getMailNotificationColor(tenant.status as RentingPeriodMailNotificationStatus, theme)
                            : getUserAcceptanceAccountColor(tenant.status as RentingPeriodUserAcceptanceStatus, theme)
                    }
                    labelColor={
                        tenant.name === "-"
                            ? getMailNotificationStatusLabelColor(tenant.status as RentingPeriodMailNotificationStatus)
                            : getUserAcceptanceStatusLabelColor(tenant.status as RentingPeriodUserAcceptanceStatus)
                    }
                />
            ))}
        </Box>
    )
}
