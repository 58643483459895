import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { SimpleQueryIDs } from "../hooks/useQueryInvalidator"

export type SubscriptionBase = {
    name: string
    isPopular: boolean
    features: {
        title: string
        isAvailable: boolean
    }[]
}

export interface SubscriptionPriceBase {
    id: string
    numberOfMonths: number
    trialPeriod: number
    price: number
}

export interface SubscriptionPriceDto extends SubscriptionPriceBase {
    currencyId: number
}

export interface SubscriptionPrice extends SubscriptionPriceBase {
    currency: string
}

export interface SubscriptionDto extends SubscriptionBase {
    prices: SubscriptionPriceDto[]
}

export interface Subscription extends SubscriptionBase {
    prices: SubscriptionPrice[]
}

export const getSubscriptionQuery = () => {
    return useQuery({
        staleTime: Infinity,
        queryKey: [SimpleQueryIDs.UserSubscription],
        queryFn: Api.getSubscriptions,
    })
}
