import { useTheme } from "@mui/material/styles"
import React, { useCallback, useMemo } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { useConfiguredExpenseProviders } from "../../../../queries"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import { getProviderStatusIcon, MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { renderActionSheetInfo } from "../../utils"
import { DetailsSheet, Iconify, useLocales } from "rentzz"
import { ConfiguredExpenseProvider, OwnerPropertyMeter, ProviderStatus, ProviderType } from "../../../../redux/slices/AddProperty"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { useMetersQueryFn } from "../../../../queries/meters"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import EditIcon from "@mui/icons-material/Edit"

export default function ProviderDetailsSheet() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: expenseProviders, isLoading: areExpenseProvidersLoading } = useConfiguredExpenseProviders()
    const { data: meters, isLoading: areMetersLoading } = useMetersQueryFn()
    const meterProviders = useMemo(
        () => (meters as OwnerPropertyMeter[])?.filter((meter) => meter.configuredProvider != null).map((m) => m.configuredProvider),
        [meters],
    )
    const { canDelete } = usePermissions(PermissionType.Properties)

    const availableProviders = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? expenseProviders : meterProviders),
        [editingItem, expenseProviders, meterProviders],
    )
    const areCurrentProvidersLoading = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? areExpenseProvidersLoading : areMetersLoading),
        [editingItem, areMetersLoading, areExpenseProvidersLoading],
    )

    const currentProvider = useMemo(() => {
        const currentId = editingItem?.providerType === ProviderType.Expense ? editingItem?.id : editingItem?.providerId
        return availableProviders?.find((provider) => provider.id === currentId)
    }, [availableProviders, editingItem])

    const reason = useMemo(() => (currentProvider?.rejectReason != undefined ? currentProvider.rejectReason : ""), [currentProvider])
    const headerColor = useMemo(() => {
        if (currentProvider?.status === ProviderStatus.Pending) return theme.palette.warning.main
        if (currentProvider?.status === ProviderStatus.Rejected) return theme.palette.error.main
        return theme.palette.success.main
    }, [theme, currentProvider])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentProvider == null) return []
        return [
            {
                icon: <EditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.HandleProviderAutomaticSendToTenant))
                    dispatch(setEditingItem({ ...editingItem, backgroundColor: headerColor }))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !canDelete,
                onClick: () => dispatch(setModalOpen(AppModals.DeleteProvider)),
            },
        ]
    }, [currentProvider, dispatch])

    const handleClose = useCallback(() => {
        if (editingItem?.providerType === ProviderType.Expense) {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        } else {
            dispatch(setModalOpen(AppModals.MeterDetails))
        }
    }, [editingItem, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ProviderDetails}
            onClose={handleClose}
            isLoading={areCurrentProvidersLoading}
            title={currentProvider?.providerName}
            options={options}
            headerColor={headerColor}
        >
            {renderActionSheetInfo(
                "mdi:account",
                `${translate("user")}: ${currentProvider?.fields.find((f) => f.name === "email")?.value}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                <Iconify icon={"mdi:lock"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />

                <Typography fontSize={13} color={theme.palette.grey["600"]} width={"inherit"} flexWrap={"wrap"} sx={{ wordBreak: "break-all" }}>
                    {`${translate("password")}: ${currentProvider?.fields.find((f) => f.name === "password")?.value}`}
                </Typography>
            </Box>

            {editingItem?.providerType === ProviderType.Expense &&
                renderActionSheetInfo(
                    "mdi:calendar-month",
                    (currentProvider as ConfiguredExpenseProvider)?.lastExpenseCreationDate.year === 1
                        ? translate("no_expense_added")
                        : `${translate("lastExpenseDate")}: ${(currentProvider as ConfiguredExpenseProvider)?.lastExpenseCreationDate?.toLocaleString(
                              DateTime.DATE_SHORT,
                          )}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

            {renderActionSheetInfo(
                "mdi:send-variant",
                `${translate("sendAutomaticallyToTenant")} - ${(currentProvider as ConfiguredExpenseProvider)?.sendAutomaticallyToTenant === false ? translate("no") : translate("yes")}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:sync",
                `${translate("arePaymentsSync")} - ${(currentProvider as ConfiguredExpenseProvider)?.arePaymentsSync === false ? translate("no") : translate("yes")}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                getProviderStatusIcon(currentProvider?.status),
                currentProvider?.status === ProviderStatus.Active
                    ? translate("active_provider")
                    : translate(`rejected_provider_tooltip-${currentProvider?.status}${reason}`),
                headerColor,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
