import { useSelector } from "../../redux/store"
import { AppContext } from "rentzz"
import OwnerSettingsPage from "./OwnerSettingsPage"
import TenantSettingsPage from "./TenantSettingsPage"

export default function SettingsPage() {
    const { context } = useSelector((state) => state.appState)
    if (context === AppContext.Owner) return <OwnerSettingsPage />
    return <TenantSettingsPage />
}
