import { Box, Fab } from "@mui/material"
import CustomAddCard from "./CustomAddCard"
import React, { ReactNode } from "react"
import { Iconify, IKanbanTask } from "rentzz"
import { useSelector } from "../redux/store"
import { PropertyIncome } from "../redux/slices/AddProperty"

interface CustomMobileListProps {
    items: PropertyIncome[] | IKanbanTask[] | undefined
    buttonText: string
    onAddClick: () => void
    addCardIcon: string
    addCardText: string
    isAddDisabled?: boolean
    addTooltipCardText?: string
    children: ReactNode
}
export default function CustomMobileList({
    items,
    onAddClick,
    addCardIcon,
    addCardText,
    addTooltipCardText,
    isAddDisabled,
    children,
}: CustomMobileListProps) {
    const { modalOpen } = useSelector((state) => state.appState)

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} px={1} style={{ height: "inherit" }}>
            {children}
            {items?.length === 0 && (
                <CustomAddCard
                    onCardClick={onAddClick}
                    icon={addCardIcon}
                    text={addCardText}
                    isAddDisabled={isAddDisabled}
                    tooltipTitle={addTooltipCardText}
                />
            )}

            {(items ?? []).length > 0 && modalOpen == null && (
                <Fab onClick={onAddClick} sx={{ position: "absolute", bottom: 10, right: 10 }} color='primary' aria-label='add'>
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}
        </Box>
    )
}
