import { AppContext, Page, SectionsPage, Type, useIsMobile, useLocales, useTypedParams } from "rentzz"
import { useDispatch, useSelector } from "../../redux/store"
import React, { useCallback, useEffect, useMemo } from "react"
import { getOwnerFilesQuery, getTenantFilesQuery } from "../../queries/files"
import { UserFlags, useTenantRentingPeriodsQuery, useUserPropertiesQuery } from "../../queries/userData"
import { sum } from "lodash"
import { useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { downloadMultiFile } from "../../utils/actions"
import { useSnackbar } from "notistack"
import { useRentingPeriodDetails } from "../../queries/tenants"
import { setEditingItem, setModalOpen } from "../../redux/slices/App"
import { Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export enum PropertySectionsType {
    Expenses = "expenses",
    Incomes = "incomes",
    Insurances = "insurances",
    Documents = "documents",
    Meters = "meters",
    Contracts = "contracts",
    Tasks = "tasks",
    RevisionObservations = "revision-observation",
}

export default function PropertySections() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { data: userProperties } = useUserPropertiesQuery()
    const currentlyViewingPropertyId = useTypedParams<number | undefined>("currentFilesPropertyId", Type.Number)
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const isTaskItemsOn = useFeatureIsOn(UserFlags.TaskItems.toString())

    const usedPropertyId = useMemo(() => {
        if (currentRentingPeriodId) return rentingPeriodDetails?.propertyId
        if (currentPropertyId) return currentPropertyId
        return currentlyViewingPropertyId
    }, [currentPropertyId, currentRentingPeriodId, rentingPeriodDetails])

    const { data: userRentingPeriods } = useTenantRentingPeriodsQuery()
    const tenantRentingPeriodId = useMemo(
        () => userRentingPeriods?.find((r) => r.propertyId === currentPropertyId)?.rentingPeriodId,
        [userRentingPeriods, currentPropertyId],
    )
    const { data: ownerFiles } = getOwnerFilesQuery(usedPropertyId, currentRentingPeriodId)
    const { data: tenantFiles } = getTenantFilesQuery(tenantRentingPeriodId)

    const files = useMemo(() => (context === AppContext.Owner ? ownerFiles : tenantFiles), [context, ownerFiles, tenantFiles])

    const allSections = useMemo(() => {
        let docs = [
            {
                id: PropertySectionsType.Expenses,
                name: translate("expenses.expenses"),
                files: files?.expenses ?? [],
                icon: "mdi:tag-multiple",
                size: sum(files?.expenses.map((f) => f.size)).toString(),
            },
            {
                id: PropertySectionsType.Incomes,
                name: translate(context === AppContext.Owner ? "income" : "payments"),
                files: files?.income ?? [],
                icon: "mdi:finance",
                size: sum(files?.income.map((f) => f.size)).toString(),
            },
            {
                id: PropertySectionsType.Documents,
                name: translate("documents"),
                files: files?.documents ?? [],
                icon: "mdi:file-document-multiple",
                size: sum(files?.documents.map((f) => f.size)).toString(),
            },

            {
                id: PropertySectionsType.Insurances,
                name: translate("insurance.name"),
                files: files?.insurance ?? [],
                icon: "mdi:shield-home",
                size: sum(files?.insurance.map((f) => f.size)).toString(),
            },
            {
                id: PropertySectionsType.Contracts,
                name: translate("contracts.title"),
                files: files?.contracts ?? [],
                icon: "mdi:account-multiple",
                size: sum(files?.contracts.map((f) => f.size)).toString(),
            },
            {
                id: PropertySectionsType.Meters,
                name: translate("meters_readings"),
                files: files?.meterValues ?? [],
                icon: "mdi:meter-electric",
                size: sum(files?.meterValues.map((f) => f.size)).toString(),
            },
            {
                id: PropertySectionsType.Tasks,
                name: translate("tasks"),
                files: files?.tasks ?? [],
                icon: "mdi:format-list-checks",
                size: sum(files?.tasks.map((f) => f.size)).toString(),
            },

            {
                id: PropertySectionsType.RevisionObservations,
                name: translate("revisions"),
                files: files?.observations ?? [],
                icon: "mdi:file-document-edit",
                size: sum(files?.observations?.map((f) => f.size)).toString(),
            },
        ]

        if (!isTaskItemsOn) {
            docs = docs.filter((d) => d.id !== PropertySectionsType.Tasks)
        }

        if (context === AppContext.Tenant) return docs.filter((doc) => doc.id !== PropertySectionsType.Insurances)
        if (context === AppContext.Owner && currentRentingPeriodId) {
            return docs.filter((doc) => doc.id !== PropertySectionsType.Insurances)
        }

        return docs
    }, [context, files, currentRentingPeriodId, translate, isTaskItemsOn])

    useEffect(() => {
        if (currentPropertyId) {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [currentPropertyId])

    const onDoubleSectionClick = useCallback(
        (id: string | PropertySectionsType) => {
            if (context === AppContext.Tenant) navigate(currentPropertyId + `/${id}`)
            else navigate(id)
        },
        [navigate, context, currentPropertyId],
    )

    const getFolderName = useCallback(() => {
        const propertyName = userProperties?.find((property) => property.id === currentlyViewingPropertyId)?.label
        return `${translate("files")}_${propertyName}_${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}`
    }, [userProperties, translate, currentlyViewingPropertyId])

    const onMultiDownload = useCallback(
        async (ids: string[]) => {
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                await downloadMultiFile({
                    ids: ids,
                    name: getFolderName(),
                    extension: "zip",
                })
            } finally {
                closeSnackbar(key)
            }
        },
        [files, enqueueSnackbar, closeSnackbar],
    )

    const handleAllFilesDownload = useCallback(
        async (id: string) => {
            if (!files) return
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                if (id === PropertySectionsType.Expenses)
                    return downloadMultiFile({
                        ids: [...files.expenses.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("Expenses")}`,
                    })
                if (id === PropertySectionsType.Incomes)
                    return downloadMultiFile({
                        ids: [...files.income.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("income")}`,
                    })
                if (id === PropertySectionsType.Insurances)
                    return downloadMultiFile({
                        ids: [...files.insurance.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("insurance.name")}`,
                    })
                if (id === PropertySectionsType.Contracts)
                    return downloadMultiFile({
                        ids: [...files.contracts.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("contracts.title")}`,
                    })
                if (id === PropertySectionsType.Meters)
                    return downloadMultiFile({
                        ids: [...files.meterValues.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("meters")}`,
                    })
                if (id === PropertySectionsType.Documents)
                    return downloadMultiFile({
                        ids: [...files.documents.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("files")}`,
                    })
                if (id === PropertySectionsType.Tasks)
                    return downloadMultiFile({
                        ids: [...files.tasks.map((f) => f.id)],
                        name: `${userProperties?.find((p1) => p1.id === currentlyViewingPropertyId)?.label}/${translate("tasks")}`,
                    })
            } finally {
                closeSnackbar(key)
            }
        },
        [files, downloadMultiFile, closeSnackbar, enqueueSnackbar],
    )

    return (
        <Page
            title={translate(currentRentingPeriodId ? "tenants" : "documents")}
            propertyName={currentRentingPeriodId ? translate("documents") : userProperties?.find((p) => p.id === currentPropertyId)?.label}
            px={context === AppContext.Owner ? (currentRentingPeriodId || currentPropertyId ? 0 : 2) : 1}
            paddingBottom={2}
        >
            {isMobile && context === AppContext.Tenant && (
                <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                    {translate("documents")}
                </Typography>
            )}
            <SectionsPage
                onDownload={handleAllFilesDownload}
                onDoubleRowClick={onDoubleSectionClick}
                tableData={allSections}
                onMultipleDownload={onMultiDownload}
            />
        </Page>
    )
}
