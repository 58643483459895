import { Box } from "@mui/material"
import { FormProvider, RHFCheckbox, RHFDatePicker, RHFTextField, useLocales } from "rentzz"
import React, { useCallback, useEffect } from "react"
import { LoadingButton } from "@mui/lab"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { createInvoiceFromExpenseMutation } from "../../../mutations/expenses"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ReinvoicingSchema } from "../../../validations/expenses"
import { useSinglePendingExpenseQueryFn } from "../../../queries/expenses"

interface Props {
    onClose: () => void
}

export interface ReinvoiceExpenseRequest {
    invoiceDueDate: string
    invoiceDate: string
    modifyExpenseWithInvoiceData: boolean
}

export interface ReinvoiceForm extends ReinvoiceExpenseRequest {
    providerInvoiceNumber?: string
    invoiceValue?: string
    providerInvoiceDate?: string
}

export default function ReinvoicingForm({ onClose }: Props) {
    const { translate } = useLocales()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: currentPendingExpense } = useSinglePendingExpenseQueryFn(editingItem?.id)
    const { mutateAsync: createInvoiceFromExpense, isPending: isCreatingInvoice } = createInvoiceFromExpenseMutation()
    const methods = useForm<ReinvoiceForm>({
        resolver: yupResolver(ReinvoicingSchema),
        mode: "onBlur",
        defaultValues: {
            invoiceDate: DateTime.now().toISO() ?? undefined,
            invoiceDueDate: DateTime.now().plus({ day: 3 }).toISO() ?? undefined,
            modifyExpenseWithInvoiceData: false,
        },
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (currentPendingExpense) {
            reset({
                invoiceDate: DateTime.now().toISO() ?? undefined,
                invoiceDueDate: DateTime.now().plus({ day: 3 }).toISO() ?? undefined,
                modifyExpenseWithInvoiceData: false,
                providerInvoiceDate: currentPendingExpense.providerInvoiceDate?.toISO() ?? undefined,
                providerInvoiceNumber: currentPendingExpense.providerInvoiceNumber,
                invoiceValue: currentPendingExpense.invoiceValue,
            })
        }
    }, [currentPendingExpense])

    const handleCreateInvoiceFromExpense = useCallback(
        async (data: ReinvoiceExpenseRequest) => {
            const key = enqueueSnackbar(translate("creating_invoice_in_progress"), { variant: "info", key: DateTime.now().toMillis() })
            if (currentPendingExpense == null) return
            try {
                await createInvoiceFromExpense({
                    expenseId: currentPendingExpense.id,
                    invoiceDate: DateTime.fromISO(data.invoiceDate).toISODate()!,
                    invoiceDueDate: DateTime.fromISO(data.invoiceDueDate).toISODate()!,
                    modifyExpenseWithInvoiceData: data.modifyExpenseWithInvoiceData,
                })
            } finally {
                closeSnackbar(key)
                onClose()
            }
        },
        [createInvoiceFromExpense, editingItem, enqueueSnackbar, closeSnackbar],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleCreateInvoiceFromExpense)}>
            <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={1}>
                <RHFTextField
                    name='invoiceValue'
                    defaultValue={currentPendingExpense?.invoiceValue}
                    label={translate("invoiceValue")}
                    size='small'
                    required
                    disabled
                />
                <RHFTextField
                    name='providerInvoiceNumber'
                    defaultValue={currentPendingExpense?.providerInvoiceNumber}
                    label={translate("invoiceProviderNumber")}
                    size='small'
                    required
                    disabled
                />
                <RHFDatePicker
                    name='providerInvoiceDate'
                    defaultValue={currentPendingExpense?.providerInvoiceDate?.toISO()}
                    label={translate("providerInvoiceDate")}
                    required
                    disabled
                />
                <RHFDatePicker name='invoiceDate' label={translate("invoiceDate")} required />
                <RHFDatePicker name='invoiceDueDate' label={translate("invoiceDueDate")} required />
                <RHFCheckbox name={"modifyExpenseWithInvoiceData"} label={translate("modifyExpenseWithInvoiceData")} />

                <LoadingButton variant='contained' color={"primary"} type={"submit"} disabled={isCreatingInvoice} loading={isCreatingInvoice}>
                    {translate("create_invoice_expense_now")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
