import { Page, useIsMobile, useLocales } from "rentzz"
import { Box, Paper, Typography } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import { LoadingButton } from "@mui/lab"
import { DateTimePicker } from "@mui/x-date-pickers-pro"
import React, { useCallback, useMemo, useState } from "react"
import { DateTime } from "luxon"
import { useRevisionTenantResponseMutation } from "../../../mutations/tenants"
import { useSelector } from "../../../redux/store"
import { useTenantRevisionsQuery } from "../../../queries/revision"

export default function ShowRevisionForm() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [dateValue, setDateValue] = useState<DateTime | null>(null)
    const { mutateAsync: revisionTenantResponse, isPending } = useRevisionTenantResponseMutation()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: revisions } = useTenantRevisionsQuery()

    const currentRevision = useMemo(() => revisions?.find((revision) => revision.showDatePicker), [revisions])

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile, theme],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const onSendRevision = useCallback(async () => {
        if (currentRentingPeriodId == null || dateValue == null || currentRevision == null) return
        await revisionTenantResponse({
            rentingPeriodId: currentRentingPeriodId,
            date: dateValue.toISODate(),
            maintenanceScheduleId: currentRevision.id,
        })
    }, [currentRentingPeriodId, revisionTenantResponse, dateValue, currentRevision])

    return (
        <Page title={translate("revision_form")}>
            <ContentStyle>
                <PaperStyle elevation={5}>
                    <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={2}>
                        <Box display={"flex"} flexDirection={"column"} gap={3}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography variant={"body2"} fontWeight={"bold"} paddingBottom={2}>
                                    {translate("pick_a_revision_date")}
                                </Typography>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {translate("revision_name", { name: currentRevision?.name })}
                                </Typography>
                                <Typography variant={"caption"} paddingBottom={2}>
                                    {currentRevision?.description}
                                </Typography>
                            </Box>

                            <DateTimePicker
                                label={translate("insurance.endDate")}
                                viewRenderers={{
                                    hours: null,
                                    minutes: null,
                                    seconds: null,
                                }}
                                views={["year", "month", "day"]}
                                slotProps={{ textField: { size: "small" } }}
                                value={dateValue}
                                onChange={(value) => setDateValue(value)}
                            />
                            <LoadingButton
                                variant={"contained"}
                                color={"primary"}
                                onClick={onSendRevision}
                                disabled={dateValue == null || isPending}
                                loading={isPending}
                            >
                                {translate("send_to_owner")}
                            </LoadingButton>
                        </Box>
                    </Box>
                </PaperStyle>
            </ContentStyle>
        </Page>
    )
}
