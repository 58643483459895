import { Paper } from "@mui/material"
import React, { ReactNode, useMemo } from "react"
import { styled, useTheme } from "@mui/material/styles"
import { Page, useIsMobile, useLocales } from "rentzz"

interface RentingPeriodNotificationContainerProps {
    children: ReactNode[] | ReactNode
    maxWidth?: number
    height?: number
    title: string
}
const NotificationContainer = ({ children, maxWidth, height, title }: RentingPeriodNotificationContainerProps) => {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: maxWidth ?? 500,
                width: isMobile ? "100%" : undefined,
                height,
                minWidth: isMobile ? undefined : maxWidth ?? 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(10, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    return (
        <Page title={translate(title)}>
            <ContentStyle>
                <PaperStyle elevation={6}>{children}</PaperStyle>
            </ContentStyle>
        </Page>
    )
}

export default NotificationContainer
