import { CustomFile, FormProvider, RHFCheckbox, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddNoteSchema } from "../../../../../validations/addNote"
import { useSelector } from "../../../../../redux/store"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useEffect } from "react"
import { Notes } from "../../../../../queries/notes"
import { getOwnerMaxSize } from "../../../../../utils/actions"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../../../../queries/userData"

interface Props {
    onClose: () => void
    onSave: (data: AddNoteRequest) => void
    isLoading: boolean
    defaultValues?: Notes
}

export interface AddNoteRequest {
    noteId?: number
    propertyId: number
    title: string
    text: string
    isVisibleToTenant: boolean
    files: CustomFile[]
}

export default function AddNoteForm({ onSave, onClose, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()

    const methods = useForm<AddNoteRequest>({
        resolver: yupResolver<AddNoteRequest>(AddNoteSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                title: defaultValues.title,
                text: defaultValues.text,
                isVisibleToTenant: defaultValues.isVisibleToTenant,
                propertyId: defaultValues.propertyId,
                files: [],
            })
        }

        if (defaultValues == null) {
            reset({
                isVisibleToTenant: false,
                propertyId: currentPropertyId,
                files: [],
            })
        }
    }, [defaultValues, reset])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFTextField
                        name={"title"}
                        label={translate("title")}
                        size={"small"}
                        required
                        placeholder={translate("notes.titlePlaceholder")}
                    />
                    <RHFTextField
                        name={"text"}
                        label={translate("text")}
                        multiline
                        required
                        minRows={3}
                        maxRows={3}
                        placeholder={translate("notes.textPlaceholder")}
                    />
                    {defaultValues == null && (
                        <RHFCheckbox name={"isVisibleToTenant"} label={translate("notes.isVisibleToTenantText")} sx={{ paddingLeft: 1 }} />
                    )}
                    {defaultValues == null && (
                        <RHFUpload
                            name={"files"}
                            multiple
                            maxSize={getOwnerMaxSize(properties, userData?.features?.maxPropertySize, currentPropertyId)}
                            icon={"mdi:file-document-plus-outline"}
                        />
                    )}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
