import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import { AppModals, setModalOpen } from "../../../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useUserDataQueryFn } from "../../../../../../queries/userData"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { acceptOnTenantBehalfMutation } from "../../../../../../mutations/tenants"
import { useTheme } from "@mui/material/styles"

interface AcceptOnTenantBehalfForm {
    tenantFirstName: string
    tenantLastName: string
}

interface Props {
    onClose: () => void
}
export default function AcceptOnTenantBehalfForm({ onClose }: Props) {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const { mutateAsync: acceptOnTenantBehalf, isPending: isAcceptingOnTenantBehalf } = acceptOnTenantBehalfMutation()

    const methods = useForm<AcceptOnTenantBehalfForm>({
        mode: "onChange",
    })
    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (modalOpen === AppModals.AcceptOnTenantBehalf) {
            reset({ tenantFirstName: "", tenantLastName: "" })
        }
    }, [modalOpen, userData])

    const onResendInvitation = useCallback(
        async (data: AcceptOnTenantBehalfForm) => {
            if (currentRentingPeriodId == null || editingItem == null) return
            await acceptOnTenantBehalf({
                rentingPeriodId: currentRentingPeriodId,
                email: editingItem?.mail,
                tenantLastName: data.tenantLastName,
                tenantFirstName: data.tenantFirstName,
            })
            dispatch(setModalOpen(undefined))
            if (isMobile) {
                dispatch(setModalOpen(AppModals.TenantDetails))
            }
        },
        [currentRentingPeriodId, editingItem, acceptOnTenantBehalf],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onResendInvitation)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                <Typography color={theme.palette.grey.A700} variant={"body2"} textAlign={"center"}>
                    {translate("accept_on_tenant_behalf_content", { email: editingItem?.mail })}
                </Typography>
                <RHFTextField name='tenantFirstName' label={translate("tenant_first_name")} placeholder='Ion' />
                <RHFTextField name='tenantLastName' label={translate("tenant_last_name")} placeholder='Popescu' />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button disabled={isAcceptingOnTenantBehalf} onClick={onClose} color={"primary"}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color={"primary"}
                        loading={isAcceptingOnTenantBehalf}
                        disabled={isAcceptingOnTenantBehalf}
                    >
                        {translate("send")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
