import React, { useCallback } from "react"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import CustomCard from "../../../../../components/CustomCard"
import { Iconify, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../../queries/currency"
import { useIntl } from "react-intl"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { UserFlags, useUserDataQueryFn } from "../../../../../queries/userData"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { useSelector } from "../../../../../redux/store"

export default function RentingPeriodActionCards() {
    const intl = useIntl()
    const navigate = useNavigate()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const invoicingConfigurations = useFeatureValue(UserFlags.InvoicingConfigurations.toString(), null)
    const { data: rentingPeriodDetails, isLoading: isRentingPeriodDetailsLoading } = useRentingPeriodDetails(currentRentingPeriodId)

    const actionCard = useCallback(
        (data: number | string, navigateTo: string, icon: string, text: string, noDataText?: string) => (
            <Grid item xs={12} md={4} paddingBottom={1} paddingRight={isMobile || text === "renting_period_files_number" ? "" : 2}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"stretch"} width={"100%"} height={"100%"} flexWrap={"wrap"}>
                    <CustomCard
                        onClick={() => navigate(`/tenants/${currentRentingPeriodId}/${navigateTo}`)}
                        alignItems={"center"}
                        flex={1}
                        flexGrow={1}
                        width={"100%"}
                        isCardClickable
                    >
                        {isRentingPeriodDetailsLoading ? (
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                                <CircularProgress size={"1.5rem"} />
                            </Box>
                        ) : (
                            <Box display={"flex"} justifyContent={isMobile ? "" : "center"} marginTop={1} alignItems={"center"}>
                                <Iconify icon={icon} width={26} height={26} alignSelf={"center"} marginRight={2} color={theme.palette.primary.dark} />
                                <Box>
                                    <Typography textAlign={"left"} fontWeight={"bold"} fontSize={14} color={theme.palette.grey.A700}>
                                        {data === 0 ? translate(noDataText) : data}
                                    </Typography>
                                    {data !== 0 && (
                                        <Typography textAlign={"left"} color={theme.palette.grey["600"]} fontSize={14}>
                                            {translate(text)}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </CustomCard>
                </Box>
            </Grid>
        ),
        [translate, theme, isMobile, isRentingPeriodDetailsLoading, currentRentingPeriodId],
    )

    const getAmount = useCallback(
        (value: number) => {
            if (currencyData == null || userData?.currency.id == null) return ""
            if (value === 0) return 0
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, userData?.currency.id).code,
            })
        },
        [currencyData, userData, intl],
    )

    return (
        <Grid container paddingTop={invoicingConfigurations ? 2 : undefined}>
            {actionCard(
                getAmount(rentingPeriodDetails?.totalExpenses ?? 0),
                "expenses",
                "mdi:tag-multiple",
                "renting_period_expenses",
                "tenant_no_expense",
            )}
            {actionCard(
                getAmount(rentingPeriodDetails?.totalIncome ?? 0),
                "incomes",
                "mdi:finance",
                "renting_period_incomes",

                "no_income_this_renting_period",
            )}
            {actionCard(
                rentingPeriodDetails?.totalFiles ?? 0,
                "documents",
                "mdi:file-eye-outline",
                "renting_period_files_number",

                "no_files",
            )}
        </Grid>
    )
}
