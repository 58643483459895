import { useIsMobile } from "rentzz"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import React, { useCallback } from "react"
import DesktopPendingExpensesList from "./DesktopPendingExpenses"
import MobilePendingExpensesList from "./MobilePendingExpensesList"

export default function PendingExpenses() {
    const isMobile = useIsMobile()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    return <>{isMobile ? <MobilePendingExpensesList getAmount={getAmount} /> : <DesktopPendingExpensesList getAmount={getAmount} />}</>
}
