import { DetailsSheet, FormProvider, RHFPhoneNumber, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { Box, Typography } from "@mui/material"
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteSheetContent from "../../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../../dialogs/DeleteDialog"
import { updateSendCodeViaSMSMutation } from "../../../../mutations/user"
import { useForm } from "react-hook-form"
import { useSingleActiveUserQuery } from "../../../../queries/userData"
import { yupResolver } from "@hookform/resolvers/yup"
import { UpdateUserPhoneSchema } from "../../../../validations/ActiveUsers"
import { UpdateUserPhoneRequest } from "../../../../sections/dashboard/Tables/UpdateUserPhone"

export default function SendSMSCodeGuard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateSendCodeBySMS, isPending: isSendCodeBySMSPending } = updateSendCodeViaSMSMutation()
    const { data: currentUser } = useSingleActiveUserQuery(editingItem?.id)

    const methods = useForm<UpdateUserPhoneRequest>({
        resolver: yupResolver(UpdateUserPhoneSchema),
        mode: "onBlur",
        defaultValues: {
            phoneNumber: currentUser?.phoneNumber,
        },
    })

    const {
        getValues,
        setValue,
        reset,
        formState: { isValid },
    } = methods

    useEffect(() => {
        reset({ phoneNumber: currentUser?.phoneNumber })
    }, [reset, currentUser])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ActiveUserDetailsSheet))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }

        setValue("phoneNumber", "")
    }, [dispatch, setValue, isMobile])

    const onActivate = useCallback(
        async (e: SyntheticEvent) => {
            if (currentUser == null) return
            e.stopPropagation()
            if (!currentUser) return

            if (currentUser?.isSMSCodeActive) {
                await updateSendCodeBySMS({ userId: currentUser.id, phoneNumber: undefined, isRequired: false })
            } else {
                if (!isValid) return
                await updateSendCodeBySMS({
                    userId: currentUser.id,
                    phoneNumber: getValues("phoneNumber") === currentUser?.phoneNumber ? undefined : getValues("phoneNumber"),
                    isRequired: true,
                })
            }

            onClose()
        },
        [currentUser, updateSendCodeBySMS, onClose, getValues, isValid],
    )

    const content = useMemo(
        () => (
            <FormProvider methods={methods}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Typography>
                        {translate(currentUser?.isSMSCodeActive ? "remove_send_code_by_sms_content" : "send_code_by_sms_content", {
                            name: currentUser?.fullName,
                        })}
                    </Typography>
                    {!currentUser?.isSMSCodeActive && <RHFPhoneNumber name={"phoneNumber"} label={translate("phone")} required />}
                </Box>
            </FormProvider>
        ),
        [currentUser],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate(currentUser?.isSMSCodeActive ? "remove_code_by_sms" : "send_code_by_sms")}
                isOpen={isMobile && modalOpen === AppModals.SendLoginCodeBySMS}
                onClose={onClose}
            >
                <DeleteSheetContent
                    content={content}
                    onClose={onClose}
                    isDeleteLoading={isSendCodeBySMSPending}
                    colorSubmitButton={currentUser?.isSMSCodeActive ? "error" : "primary"}
                    onAccept={onActivate}
                    submitText={currentUser?.isSMSCodeActive ? "deactivate" : "activate"}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.SendLoginCodeBySMS}
                isDeleteLoading={isSendCodeBySMSPending}
                title={translate(currentUser?.isSMSCodeActive ? "remove_code_by_sms" : "send_code_by_sms")}
                content={content}
                onClose={onClose}
                onAccept={onActivate}
                submitText={currentUser?.isSMSCodeActive ? "deactivate" : "activate"}
                acceptButtonColor={currentUser?.isSMSCodeActive ? "error" : "primary"}
            />
        </>
    )
}
