import { useDispatch, useSelector } from "../../../redux/store"
import React, { useEffect, useMemo, useRef } from "react"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { AppContext, LoadingIcon } from "rentzz"
import { sum } from "lodash"
import { Box, CircularProgress } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import NoPendingExpensesPage from "./NoPendingExpensesPage"
import ExpenseCustomCard from "../mobile/ExpenseCustomCard"
import { useInfiniteExpensesQueryFn } from "../../../queries/expenses"
import { useTheme } from "@mui/material/styles"

interface Props {
    getAmount: (value: number, expenseId?: number) => string
}

const MobilePendingExpensesList = ({ getAmount }: Props) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: pendingExpenses, fetchNextPage, hasNextPage, isLoading } = useInfiniteExpensesQueryFn(true, [{ field: "date", sort: "desc" }])

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (pendingExpenses == null || userProperties == null) return <LoadingIcon />

    if (sum(pendingExpenses?.pages.flatMap((p) => p.items.length)) === 0 && context === AppContext.Owner) return <NoPendingExpensesPage />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {pendingExpenses.pages.map((group, i) => (
                <Box
                    key={i}
                    paddingTop={pendingExpenses.pages.some((p) => p.items.length === 0) ? 0 : 2}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    {group.items.map((pendingExpense) => (
                        <ExpenseCustomCard
                            key={pendingExpense.id}
                            getAmount={getAmount}
                            expense={pendingExpense}
                            properties={userProperties}
                            onClick={() => {
                                dispatch(setEditingItem({ id: pendingExpense.id, canWrite: pendingExpense.canWrite }))
                                dispatch(setModalOpen(AppModals.PendingExpenseDetails))
                            }}
                            backgroundColor={theme.palette.warning.main}
                        />
                    ))}
                </Box>
            ))}
            {hasNextPage && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                    <CircularProgress />
                </div>
            )}
            {isLoading && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", height: 300 }}>
                    <CircularProgress />
                </div>
            )}
        </Box>
    )
}

export default MobilePendingExpensesList
