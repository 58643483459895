import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useStyles } from "./useStyles"
import { useLocales } from "rentzz"
import { PropertyDataRequiredInfo } from "../newContract/PropertyData"
import { PropertyDataInfo } from "../../../redux/slices/AddProperty"

interface PropertyDataProps {
    propertyData: PropertyDataInfo | undefined
    propertyRequiredData?: PropertyDataRequiredInfo | undefined
}

const PropertyDataPreviewWhenInvitingTenant = ({ propertyData, propertyRequiredData }: PropertyDataProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    const renderEmptyIfNoValue = useCallback(
        (value: number | string | undefined | null, label?: string) => {
            if ((typeof value === "number" && value === 0) || value == null || (typeof value === "string" && value.length === 0)) {
                return null
            }
            return (
                <Text style={{ display: "flex", flexDirection: "row" }}>
                    {translate(label, {})}
                    <Text style={{ ...styles.boldFont }}>{value}</Text>,{" "}
                </Text>
            )
        },
        [styles],
    )

    return (
        <View>
            <Text wrap style={styles.mainText}>
                <Text style={styles.boldFont}>{translate("art", { number: 1 })}</Text>
                {translate("contracts.owner_assures_tenant")} {renderEmptyIfNoValue(propertyData?.county)}
                {renderEmptyIfNoValue(propertyData?.city)}
                {propertyRequiredData?.isStreetRequired ? renderEmptyIfNoValue(propertyData?.street, "contracts.street") : ""}
                {propertyRequiredData?.isStreetNumberRequired ? renderEmptyIfNoValue(propertyData?.streetNumber, "contracts.number") : ""}
                {propertyRequiredData?.isBuildingNumberRequired ? renderEmptyIfNoValue(propertyData?.buildingNumber, "contracts.buildingNumber") : ""}
                {propertyRequiredData?.isStairRequired ? renderEmptyIfNoValue(propertyData?.stair, "contracts.stair") : ""}
                {propertyRequiredData?.isFloorRequired ? (
                    <Text>
                        <Text style={{ ...styles.boldFont }}>
                            {translate(propertyData?.floor === 0 ? "ground" : "floor_with_number", { floor: propertyData?.floor })}
                        </Text>
                        ,{" "}
                    </Text>
                ) : (
                    ""
                )}
                {propertyRequiredData?.isApartmentRequired ? renderEmptyIfNoValue(propertyData?.apartment, "contracts.apartment") : ""}
            </Text>
        </View>
    )
}

export default PropertyDataPreviewWhenInvitingTenant
