import { array, boolean, number, object, string } from "yup"
import { DateTime } from "luxon"

export const AddRevisionSchema = object().shape({
    id: string()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    name: string().required("title_required").min(3, "min_3_characters"),
    description: string().required("description_required").min(3, "min_3_characters"),
    date: string()
        .required()
        .test((date: string | undefined) => {
            if (date == null) return false

            const newDate = DateTime.fromISO(date)
            return newDate.isValid
        }),
    files: array().required(),
    visibleToTenant: boolean().required(),
    allowTenantChoseDate: boolean().required(),
})

export const AddObservationSchema = object().shape({
    id: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    maintenanceScheduleId: number().required(),
    title: string().required("title_required").min(3, "min_3_characters"),
    description: string().required("text_required").min(3, "min_3_characters"),
    files: array().required(),
    observationDate: string()
        .required("date_required")
        .test((date: string | undefined) => {
            if (date == null) return false
            const newDate = DateTime.fromISO(date)

            return newDate.isValid
        }),
})
