import { Page, useIsMobile, useLocales } from "rentzz"
import DesktopInsuranceList from "./DesktopInsuranceList"
import MobileInsuranceList from "./mobile/MobileInsuranceList"
import { useUserPropertiesQuery } from "../../queries/userData"
import React, { useCallback } from "react"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../queries/currency"
import { useIntl } from "react-intl"
import { useSelector } from "../../redux/store"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"

export default function Insurance() {
    const { translate } = useLocales()
    const intl = useIntl()
    const isMobile = useIsMobile()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { currentPropertyId } = useSelector((state) => state.appState)

    const getAmount = useCallback(
        (value: number, currency: number) => {
            if (currencyData == null) return ""
            if (!currency) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currency).code,
            })
        },
        [currencyData, intl],
    )

    if (userProperties?.length === 0) return <NoPropertyPage />

    return (
        <Page
            title={translate("insurance.name")}
            propertyName={userProperties?.find((p) => p.id === currentPropertyId)?.label}
            px={currentPropertyId ? 0 : 2}
            paddingBottom={2}
            paddingTop={1}
        >
            {isMobile ? <MobileInsuranceList getAmount={getAmount} /> : <DesktopInsuranceList getAmount={getAmount} />}
        </Page>
    )
}
