import { Box, CardHeader, Divider, Paper, Switch, Typography } from "@mui/material"
import { FormProvider, Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { AppNotifications } from "../../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"
import { useUserDataQueryFn } from "../../../queries/userData"
import { allPossibleOwnerNotifications } from "./OwnerNotificationsForm"
import OwnerDesktopNotifications from "./OwnerDesktopNotifications"
import { updateUserNotificationsMutation } from "../../../mutations/user"
import * as jsonpatch from "fast-json-patch"
import OwnerMobileNotifications from "./OwnerMobileNotifications"
import { isEqual } from "lodash"

export default function NotificationCard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData, isFetching } = useUserDataQueryFn()
    const [areAllPushNotificationsEnabled, setAreAllPushNotificationsEnabled] = useState(false)
    const [areAllEmailNotificationsEnabled, setAreAllEmailNotificationsEnabled] = useState(false)
    const methods = useForm<{
        email: Record<AppNotifications, boolean>
        push: Record<AppNotifications, boolean>
    }>()
    const { watch } = methods
    const { mutateAsync: updateUserData, isPending: isUpdatingUserNotifications } = updateUserNotificationsMutation()

    useEffect(() => {
        setAreAllEmailNotificationsEnabled(allPossibleOwnerNotifications.every((n) => userData?.enabledEmailNotifications?.includes(n)))
        setAreAllPushNotificationsEnabled(allPossibleOwnerNotifications.every((n) => userData?.enabledPushNotifications?.includes(n)))
    }, [userData])

    const updateUser = useCallback(
        (emailEnabledItems?: unknown, pushEnabledItems?: unknown) => {
            if (userData == null) return
            const operations = jsonpatch.compare(
                {
                    userPreferences: {
                        enabledEmailNotifications: emailEnabledItems ? userData.enabledEmailNotifications : [],
                        enabledPushNotifications: pushEnabledItems ? userData.enabledPushNotifications : [],
                    },
                },
                {
                    userPreferences: {
                        enabledEmailNotifications: emailEnabledItems ?? [],
                        enabledPushNotifications: pushEnabledItems ?? [],
                    },
                },
            )
            if (operations.length > 0) {
                updateUserData({
                    operations,
                })
            }
        },
        [userData],
    )

    useEffect(() => {
        const subscription = watch((v) => {
            if (userData == null || v.email == null || v.push == null) return

            const emailsAllEnabled = Object.values(v.email).every((val) => val)
            const pushAllEnabled = Object.values(v.push).every((val) => val)
            setAreAllPushNotificationsEnabled(pushAllEnabled)
            setAreAllEmailNotificationsEnabled(emailsAllEnabled)

            const emailEnabledItems = Object.keys(v.email)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .filter((k) => v.email[k] === true)
                .map(Number)

            const pushEnabledItems = Object.keys(v.push)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .filter((k) => v.push[k] === true)
                .map(Number)
            updateUser(emailEnabledItems, pushEnabledItems)
        })
        return () => subscription.unsubscribe()
    }, [watch, updateUserData, userData])

    const handleToggleAllEmails = useCallback(() => {
        if (userData == null) return
        setAreAllEmailNotificationsEnabled((prevAreAllEnabled) => {
            if (isEqual(userData.enabledEmailNotifications, allPossibleOwnerNotifications)) {
                updateUser(prevAreAllEnabled ? [] : allPossibleOwnerNotifications)
            } else {
                const differentItems = userData.enabledEmailNotifications.filter((item) => !allPossibleOwnerNotifications.includes(item))
                updateUser(prevAreAllEnabled ? differentItems : allPossibleOwnerNotifications)
            }

            return !prevAreAllEnabled
        })
    }, [updateUser, userData, isEqual])

    const handleToggleAllPush = useCallback(() => {
        if (userData == null) return
        setAreAllPushNotificationsEnabled((prevAreAllEnabled) => {
            if (isEqual(userData.enabledPushNotifications, allPossibleOwnerNotifications)) {
                updateUser(undefined, prevAreAllEnabled ? [] : allPossibleOwnerNotifications)
            } else {
                const differentItems = userData.enabledPushNotifications.filter((item) => !allPossibleOwnerNotifications.includes(item))
                updateUser(undefined, prevAreAllEnabled ? differentItems : allPossibleOwnerNotifications)
            }

            return !prevAreAllEnabled
        })
    }, [updateUser, userData, isEqual])

    return (
        <Paper elevation={6} sx={{ padding: 2, marginTop: 2, color: theme.palette.grey.A700 }}>
            <FormProvider methods={methods}>
                <CardHeader
                    title={translate("grid_actions.notifyPeriodStatus")}
                    subheader={
                        isMobile ? (
                            <LightTooltip title={translate("choose_notification_owner")} arrow>
                                <Iconify icon={"mdi:information"} />
                            </LightTooltip>
                        ) : (
                            `(${translate("choose_notification_owner")})`
                        )
                    }
                    sx={{
                        p: 2,
                        color: theme.palette.grey.A700,
                        ".MuiCardHeader-content": { display: "flex", gap: 1, alignItems: "center" },
                        ".MuiTypography-root": { display: "flex", marginTop: 0 },
                    }}
                    action={
                        <Box display={"flex"} gap={isMobile ? 1 : 4}>
                            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={1} alignItems={"center"}>
                                <Typography variant={"body2"}>Email</Typography>
                                <Switch
                                    checked={areAllEmailNotificationsEnabled}
                                    onChange={handleToggleAllEmails}
                                    sx={{ marginRight: isMobile ? 0.5 : 1 }}
                                    disabled={isFetching || isUpdatingUserNotifications}
                                />
                            </Box>
                            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={1} alignItems={"center"}>
                                <Typography variant={"body2"}>{translate("mobile_notification")}</Typography>
                                <Switch
                                    checked={areAllPushNotificationsEnabled}
                                    onChange={handleToggleAllPush}
                                    sx={{ marginRight: isMobile ? 0.5 : 1 }}
                                    disabled={isFetching || isUpdatingUserNotifications}
                                />
                            </Box>
                        </Box>
                    }
                />
                <Divider />
                {isMobile ? (
                    <OwnerMobileNotifications isDisabled={isUpdatingUserNotifications} />
                ) : (
                    <OwnerDesktopNotifications isDisabled={isUpdatingUserNotifications} />
                )}
            </FormProvider>
        </Paper>
    )
}
