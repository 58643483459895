import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { DashboardChartType, SummaryCardType } from "../queries/userData"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { AddGroupRequest } from "../sections/groups/AddGroupForm"
import * as jsonpatch from "fast-json-patch"
import { UserGroup } from "../queries/groups"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"

export const updateGroupSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId, newPosition }: { summaryCardId: number; newPosition: number; summaryCards: SummaryCardType[] }) =>
            Api.updateGroupSummaryCard(summaryCardId, newPosition, currentGroupId!),
        onMutate: ({ summaryCards }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, summaryCards: [...summaryCards] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([ComplexQueryIDs.GroupSummaryCards, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const updateGroupChartMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId, newPosition }: { chartId: number; newPosition: number; charts: DashboardChartType[] }) =>
            Api.updateGroupChart(chartId, newPosition, currentGroupId!),
        onMutate: ({ charts }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, dashboardCharts: [...charts] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([ComplexQueryIDs.GroupSummaryCards, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId }: { summaryCardId: number; summaryCards: SummaryCardType[] }) =>
            Api.deleteGroupSummaryCard({ groupId: currentGroupId!, summaryCardId }),
        onMutate: ({ summaryCards }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, summaryCards: [...summaryCards] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupChartMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId }: { chartId: number; charts: DashboardChartType[] }) => Api.deleteGroupChart({ groupId: currentGroupId!, chartId }),
        onMutate: ({ charts }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, dashboardCharts: [...charts] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupSummaryCardMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId }: { summaryCardId: number }) => Api.addGroupSummaryCard(summaryCardId, currentGroupId!),
        onSettled: async (_, error, __) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SummaryCard, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const addGroupChartMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId }: { chartId: number }) => Api.addGroupChart(chartId, currentGroupId!),
        onSettled: async (_, error, __) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SummaryCard, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const updateGroupPropertyPercentageMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ percentage, groupId, propertyId }: { percentage: number; groupId: number; propertyId: number }) =>
            Api.updatePropertyPercentage({
                percentage,
                groupId,
                propertyId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId }: { groupId: number }) => Api.deleteGroup(groupId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddGroupRequest }) => Api.addGroup(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const updateGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, operations }: { groupId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateGroup({
                groupId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const updateGroupPropertiesMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, propertyId, operationType }: { groupId: number; propertyId: number; operationType: number }) =>
            Api.updateGroupProperties({
                groupId,
                propertyId,
                operationType,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupAccessMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, email, accessType }: { groupId: number; accessType: ManagerType; email: string }) =>
            Api.addGroupAccess({
                groupId,
                accessType,
                email,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods, ComplexQueryIDs.GroupAccess])
            }
        },
    })
}

export const deleteGroupAccessMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, userId }: { groupId: number; userId: string }) =>
            Api.deleteGroupAccess({
                groupId,
                userId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods, ComplexQueryIDs.GroupAccess])
            }
        },
    })
}
