import { getIncomeOrPaymentStatusColor, getIncomeOrPaymentStatusIcon, MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useSingleIncomeQuery } from "../../../queries/income"
import React, { useCallback, useMemo } from "react"
import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { renderActionSheetInfo } from "../utils"
import { DateTime } from "luxon"
import { getIncomeTitleStatus } from "../../../sections/income/utils"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { IncomeStatus, PaymentType, PropertyFileType, PropertyIncomePaymentMethod } from "../../../redux/slices/AddProperty"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

interface PaymentDetailsProps {
    onClose: () => void
}
export default function PaymentDetailsSheet({ onClose }: PaymentDetailsProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const intl = useIntl()
    const dispatch = useDispatch()
    const { data: currencyData } = useCurrencyQueryFn()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentPayment } = useSingleIncomeQuery(editingItem?.isFromAnExpense ? editingItem?.paymentId : editingItem?.id)

    const getAmount = useCallback(
        (value: number) => {
            if (currencyData == null || currentPayment == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currentPayment.currencyId).code,
            })
        },
        [currentPayment, currencyData, intl],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentPayment == null) return []

        return [
            {
                icon: <ModeEditIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"primary"} />,
                title: "grid_actions.edit",
                hidden:
                    currentPayment.status === IncomeStatus.Accepted ||
                    editingItem?.isFromAnExpense ||
                    currentPayment.paymentMethod === PropertyIncomePaymentMethod.Online,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditPayment))
                },
            },
            {
                icon: <CloudDownloadIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"info"} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: editingItem?.isFromAnExpense ? editingItem?.id : currentPayment.id,
                            type: PropertyFileType.Income,
                            name: currentPayment.type,
                            status: currentPayment.status,
                            canWrite: currentPayment.canWrite,
                            isFromAnExpense: editingItem?.isFromAnExpense,
                            paymentId: editingItem?.paymentId,
                            backgroundColor: editingItem?.backgroundColor,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon sx={{ fontSize: MOBILE_ICON_SIZE }} color={"error"} />,
                title: "grid_actions.delete",
                hidden: currentPayment.status === IncomeStatus.Accepted || editingItem?.isFromAnExpense,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeletePayment))
                },
            },
        ]
    }, [currentPayment, dispatch, theme, editingItem])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PaymentDetails}
            onClose={onClose}
            isLoading={currentPayment == null}
            title={
                currentPayment?.associatedExpenses && currentPayment?.type === PaymentType.Expense
                    ? currentPayment?.associatedExpenses[0]?.name
                    : translate(`income-table.incomeType-${currentPayment?.type}`)
            }
            subtitle={
                currentPayment?.associatedExpenses && currentPayment?.type === PaymentType.Expense
                    ? translate(`income-table.incomeType-${currentPayment?.type}`)
                    : ""
            }
            options={options}
            headerColor={currentPayment ? getIncomeOrPaymentStatusColor(currentPayment.status, theme) : theme.palette.primary.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:cash",
                    `${getAmount(currentPayment?.value ?? 0)} ( ${translate(`income-table.paymentMethod-${currentPayment?.paymentMethod}`)} )`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    currentPayment?.date?.toLocaleString(DateTime.DATE_SHORT),
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {currentPayment != null &&
                    renderActionSheetInfo(
                        getIncomeOrPaymentStatusIcon(currentPayment.status),
                        currentPayment.status === IncomeStatus.Accepted
                            ? translate("owner_accepted_the_payment")
                            : translate(getIncomeTitleStatus(currentPayment.status)),
                        getIncomeOrPaymentStatusColor(currentPayment.status, theme),
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
