import { FormProvider, RHFAutocomplete, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useSelector } from "../../../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SelectProviderSchema } from "../../../../../validations/providers"
import { useAvailableExpenseProviders, useAvailableMeterProviders } from "../../../../../queries"
import { ProviderType } from "../../../../../redux/slices/AddProperty"
import { useMetersQueryFn } from "../../../../../queries/meters"

interface SelectProviderRequest {
    providerId: string
    propertyId?: number
    meterId?: number
}

interface Props {
    onClose: () => void
    onSubmit: (providerId: string, propertyId: number, meterId?: number) => void
}
export default function SelectProvider({ onClose, onSubmit }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId, editingItem } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.Properties)
    const { data: expenseAvailableProviders, isFetching: areProvidersLoading } = useAvailableExpenseProviders()
    const { data: metersAvailableProviders, isFetching: areMetersProvidersLoading } = useAvailableMeterProviders()
    const { data: meters } = useMetersQueryFn()
    const currentProperty = useMemo(() => editableProperties?.find((p) => p.id === currentPropertyId), [editableProperties, currentPropertyId])

    const availableProviders = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? expenseAvailableProviders : metersAvailableProviders),
        [editingItem, expenseAvailableProviders, metersAvailableProviders],
    )
    const areCurrentProviderLoading = useMemo(
        () => (editingItem?.providerType === ProviderType.Expense ? areProvidersLoading : areMetersProvidersLoading),
        [editingItem, areProvidersLoading, areMetersProvidersLoading],
    )

    const methods = useForm<SelectProviderRequest>({
        resolver: yupResolver<SelectProviderRequest>(SelectProviderSchema),
        mode: "onChange",
        defaultValues: {
            propertyId: currentPropertyId,
            meterId: editingItem?.providerType === ProviderType.Meter ? editingItem?.id : undefined,
        },
    })

    const { handleSubmit } = methods

    const innerOnSubmit = useCallback(
        async (data: SelectProviderRequest) => {
            onSubmit(data.providerId, data.propertyId!, data.meterId)
        },
        [onSubmit],
    )

    if (areCurrentProviderLoading || availableProviders == null)
        return (
            <Box height={250} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(innerOnSubmit)}>
            <Box py={3} display={"flex"} flexDirection={"column"} gap={2}>
                <RHFSelect disabled name={"propertyId"} size='small' label={translate("property")} required={currentPropertyId == null}>
                    <MenuItem key={currentPropertyId} value={currentProperty?.id} sx={{ textTransform: "none" }}>
                        {currentProperty?.label}
                    </MenuItem>
                </RHFSelect>
                {editingItem?.providerType === ProviderType.Meter && (
                    <RHFSelect
                        disabled={!editingItem?.areAllMeters}
                        name={"meterId"}
                        size='small'
                        label={translate("meter_name")}
                        required={currentPropertyId == null}
                    >
                        {meters?.map((meter) => (
                            <MenuItem key={meter.id} value={meter?.id} sx={{ textTransform: "none" }}>
                                {meter?.name}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                )}

                <RHFAutocomplete
                    name={"providerId"}
                    textFieldProps={{ size: "small" }}
                    matchId
                    options={availableProviders.map((p) => ({ id: p.id, label: p.name }))}
                    label={translate("provider")}
                    loading={areCurrentProviderLoading}
                    autocompleteProps={{
                        size: "small",
                        noOptionsText: translate("no_value_found"),
                    }}
                    freeSolo={false}
                    required
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={onClose}>
                    {translate("cancel")}
                </Button>
                <Button color={"primary"} type='submit' variant='contained'>
                    {translate("next")}
                </Button>
            </Box>
        </FormProvider>
    )
}
