import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { Iconify, LoadingIcon } from "rentzz"
import InsuranceCustomCard from "./InsuranceCustomCard"
import { useInfiniteUserInsuranceQueryFn } from "../../../queries"
import { RoleName, useUserDataQueryFn, useUserPropertiesQuery } from "../../../queries/userData"
import { sum } from "lodash"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import NoDataPage from "../../../components/NoDataPage"

interface MobileInsuranceProps {
    getAmount: (value: number, currencyId: number) => string
}

export default function MobileInsuranceList({ getAmount }: MobileInsuranceProps) {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { data: insuranceList, fetchNextPage, hasNextPage } = useInfiniteUserInsuranceQueryFn()
    const { data: properties } = useUserPropertiesQuery()
    const { modalOpen } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Insurances)
    const { data: user } = useUserDataQueryFn()

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) fetchNextPage()
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (insuranceList == null) return <LoadingIcon />

    if (sum(insuranceList?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite)
        return <NoDataPage icon={"mdi:shield-off"} noDataText={"insurance.noInsurance"} />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {insuranceList.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && user?.roles.roleName !== RoleName.FREE && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddInsurance))}
                    sx={{ position: "absolute", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            {insuranceList.pages.map((group, i) => (
                <React.Fragment key={i}>
                    {group.items.map((insurance) => (
                        <InsuranceCustomCard key={insurance.id} getAmount={getAmount} insurance={insurance} />
                    ))}
                </React.Fragment>
            ))}
            {hasNextPage && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                    <CircularProgress />
                </div>
            )}

            {sum(insuranceList.pages.flatMap((p) => p.items.length)) === 0 && canWrite && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddInsurance))}
                    icon={"mdi:shield-plus"}
                    text={"insurance.addInsurance"}
                    isAddDisabled={properties?.length === 0 || user?.roles.roleName === RoleName.FREE}
                    tooltipTitle={user?.roles.roleName === RoleName.FREE ? "permission_denied_action" : "no_property_no_insurance"}
                />
            )}
        </Box>
    )
}
