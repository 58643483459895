import { RHFSelect, Type, useLocales, useTypedParams } from "rentzz"
import { MenuItem } from "@mui/material"
import { DateTime } from "luxon"
import React from "react"
import { usePropertyRentingPeriods, useRentingPeriodDetails } from "../../../queries/tenants"
import { orderBy } from "lodash"
import { useSelector } from "../../../redux/store"

interface Props {
    propertyId?: number
}
export default function PropertySelector({ propertyId }: Props) {
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const currentRentingPeriodId = useTypedParams<number | undefined>("currentRentingPeriodId", Type.Number)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: propertyRentingPeriods } = usePropertyRentingPeriods(propertyId)

    return (
        <RHFSelect
            disabled={rentingPeriodDetails != null || editingItem?.currentRentingPeriodId}
            name={"rentingPeriodId"}
            size='small'
            label={translate("tenant-data.rentingPeriod")}
            required
        >
            <MenuItem value={-1} style={{ textTransform: "none" }}>
                {translate("all_property")}
            </MenuItem>

            {propertyRentingPeriods && propertyRentingPeriods.length === 0 ? (
                <MenuItem style={{ textTransform: "none" }} disabled>
                    {translate("no_renting_period_for_this_property")}
                </MenuItem>
            ) : (
                orderBy(propertyRentingPeriods, ["moveOutDate"], ["desc"])?.map((period) => (
                    <MenuItem key={period.id} value={period.id} style={{ textTransform: "none" }}>
                        {`${period.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${period.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}`}
                    </MenuItem>
                ))
            )}
        </RHFSelect>
    )
}
