import { Box, Button, MenuItem } from "@mui/material"
import { AppContext, FormProvider, IKanbanTask, RHFSelect, RHFTextField, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { LoadingButton } from "@mui/lab"
import * as React from "react"
import { useCallback, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { EditTaskSchema } from "../../../../validations/kanban"
import { setEditingItem } from "../../../../redux/slices/App"
import { orderBy } from "lodash"
import * as jsonpatch from "fast-json-patch"
import { updateTaskMutation, updateTenantTaskMutation } from "../../../../mutations/kanban"
import { useAllSectionsQueryFn } from "../../../../queries/kanban"
import { usePropertyRentingPeriods } from "../../../../queries/tenants"
import { useDispatch, useSelector } from "../../../../redux/store"

interface EditTaskRequest {
    name: string
    sectionId: string
    rentingPeriodId: number
    description: string
}
interface Props {
    currentTask: IKanbanTask | undefined
    onClose: () => void
}
export default function EditTaskForm({ currentTask, onClose }: Props) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { editingItem, context } = useSelector((state) => state.appState)
    const { data: allSections } = useAllSectionsQueryFn()
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(currentTask?.propertyId)
    const { mutateAsync: editTask, isPending: isEditTaskLoading } = updateTaskMutation()
    const { mutateAsync: tenantEditTask, isPending: isTenantEditTaskLoading } = updateTenantTaskMutation()

    const methods = useForm<EditTaskRequest>({
        resolver: yupResolver(EditTaskSchema),
        mode: "onChange",
    })

    const { reset, handleSubmit } = methods

    useEffect(() => {
        if (currentTask == null) return
        reset({
            name: currentTask.name,
            sectionId: currentTask.sectionId,
            rentingPeriodId: currentTask.rentingPeriodId ?? -1,
            description: currentTask.description,
        })
    }, [currentTask, reset])

    const onSubmit = useCallback(
        async (data: EditTaskRequest) => {
            if (currentTask == null) return
            if (context === AppContext.Owner) {
                const operations = jsonpatch.compare(
                    {
                        name: currentTask.name,
                        sectionId: currentTask.sectionId,
                        rentingPeriodId: currentTask.rentingPeriodId,
                        description: currentTask.description,
                    },
                    {
                        name: data.name,
                        sectionId: data.sectionId,
                        rentingPeriodId: data.rentingPeriodId === -1 ? undefined : data.rentingPeriodId,
                        description: data.description,
                    },
                )

                await editTask({ sectionId: currentTask.sectionId, taskId: currentTask.id, operations, newIndex: -1 })
                if (currentTask.sectionId !== data.sectionId) {
                    dispatch(setEditingItem({ ...editingItem, sectionId: data.sectionId }))
                }
            } else {
                const operations = jsonpatch.compare(
                    {
                        name: currentTask.name,
                        description: currentTask.description,
                    },
                    {
                        name: data.name,
                        description: data.description,
                    },
                )

                await tenantEditTask({ rentingPeriodId: editingItem?.rentingPeriodId, taskId: currentTask.id, operations })
            }

            onClose()
        },
        [currentTask, dispatch, editingItem, onClose, context, editingItem],
    )

    const orderedRentingPeriods = useMemo(() => orderBy(rentingPeriodsFromAProperty, ["moveOutDate"], ["desc"]), [rentingPeriodsFromAProperty])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                <RHFTextField name='name' label={translate("name")} required />

                {context === AppContext.Owner && (
                    <>
                        <RHFSelect name={"sectionId"} label={translate("section")} required>
                            {allSections?.map((section) => (
                                <MenuItem key={section.taskSectionId} value={section.taskSectionId}>
                                    {section.name}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFSelect name={"rentingPeriodId"} label={translate("tenant-data.rentingPeriod")} required>
                            <MenuItem selected={currentTask?.rentingPeriodId == null} value={-1} style={{ textTransform: "none" }}>
                                {translate("no_renting_period")}
                            </MenuItem>
                            {orderedRentingPeriods?.map((period) => (
                                <MenuItem
                                    selected={currentTask?.rentingPeriodId === period.id}
                                    key={period.id}
                                    value={period.id}
                                    style={{ textTransform: "none" }}
                                >
                                    {`${period.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${period.moveOutDate.toLocaleString(
                                        DateTime.DATE_SHORT,
                                    )}`}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </>
                )}

                <RHFTextField
                    multiline
                    maxRows={10}
                    minRows={3}
                    name='description'
                    fullWidth
                    placeholder={translate("task_description_placeholder")}
                    label={translate("task_description")}
                    required
                />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: "column-reverse",
                    }}
                >
                    <Button disabled={isEditTaskLoading || isTenantEditTaskLoading} color={"primary"} onClick={onClose}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color={"primary"}
                        loading={isEditTaskLoading || isTenantEditTaskLoading}
                        disabled={isEditTaskLoading || isTenantEditTaskLoading}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
