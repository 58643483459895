import { Box, CircularProgress, Fab, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import ExpenseCustomCard from "./ExpenseCustomCard"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { AppContext, ExpenseAssignee, Iconify, LoadingIcon, useLocales } from "rentzz"
import CustomAddCard from "../../../components/CustomAddCard"
import NoDataPage from "../../../components/NoDataPage"
import { UserFlags, useUserPropertiesQuery } from "../../../queries/userData"
import { PropertyExpense, useInfiniteExpensesQueryFn } from "../../../queries/expenses"
import { sum, sumBy } from "lodash"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { DateTime } from "luxon"
import AddIcon from "@mui/icons-material/Add"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { ExportType } from "../../../utils/actions"
import { CloseIcon } from "yet-another-react-lightbox"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { expensesFilterState, expensesPageAtom, ExpensesPages } from "../../../utils/atom"
import HeaderExpenses from "../HeaderExpenses"
import { useAtomValue } from "jotai"
import { useTheme } from "@mui/material/styles"
import { ExpensePaymentStatus, getExpensePaymentStatus } from "../utils"

interface MobileExpensesListProps {
    getAmount: (value: number, currencyId?: number) => string
}

const MobileExpensesList = ({ getAmount }: MobileExpensesListProps) => {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { modalOpen, currentGroupId } = useSelector((state) => state.appState)
    const filterModel = useAtomValue(expensesFilterState)
    const {
        data: expenses,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteExpensesQueryFn(false, [{ field: "date", sort: "desc" }], undefined, filterModel)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)
    const activePage = useAtomValue(expensesPageAtom)
    const percent = useFeatureValue(UserFlags.PercentExpensePayment, 0)
    const isExpensesAIExtractorOn = useFeatureIsOn(UserFlags.ExpensesAIExtractor.toString())

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    const daysUntilWarning = useFeatureValue(UserFlags.ExpenseDaysWarning, 3)

    const backgroundColor = useCallback(
        (expense: PropertyExpense) => {
            const paidAmount = sumBy(expense.propertyIncomes.map((income) => income.valueInExpenseCurrency))
            const paymentStatus = getExpensePaymentStatus(expense.value, paidAmount, percent)

            if (expense.propertyIncomesCount > 0 && paymentStatus === ExpensePaymentStatus.Overpaid) return theme.palette.error.main
            if (expense.propertyIncomesCount > 0 && paymentStatus === ExpensePaymentStatus.NotPaid) return theme.palette.warning.main
            if (context === AppContext.Owner) {
                if (
                    expense.propertyIncomesCount === 0 &&
                    expense.assignee === ExpenseAssignee.TENANT &&
                    expense.dueDate < DateTime.now() &&
                    expense.value > 0
                )
                    return theme.palette.error.main
                if (
                    expense.propertyIncomesCount === 0 &&
                    expense.assignee === ExpenseAssignee.TENANT &&
                    expense.dueDate > DateTime.now() &&
                    expense.value > 0
                )
                    return theme.palette.warning.main

                return theme.palette.success.main
            }

            if (expense.propertyIncomes.length === 0 && expense.dueDate < DateTime.now() && expense.value > 0) return theme.palette.error.main
            if (expense.propertyIncomesCount === 0 && expense.value > 0 && context === AppContext.Tenant) return theme.palette.warning.main
            if (
                expense.propertyIncomes.length === 0 &&
                expense.dueDate.diffNow("days").days <= daysUntilWarning &&
                expense.dueDate > DateTime.now() &&
                expense.value > 0
            )
                return theme.palette.warning.main
            return theme.palette.success.main
        },
        [context, daysUntilWarning, context],
    )

    const expenseStatus = useCallback(
        (expense: PropertyExpense) => {
            const paidAmount = sumBy(expense.propertyIncomes.map((income) => income.valueInExpenseCurrency))
            const paymentStatus = getExpensePaymentStatus(expense.value, paidAmount, percent)
            if (expense.propertyIncomesCount > 0 && paymentStatus === ExpensePaymentStatus.Overpaid)
                return translate("expense_paid_more", { value: getAmount(paidAmount - expense.value, expense.currencyId) })
            if (expense.propertyIncomesCount > 0 && paymentStatus === ExpensePaymentStatus.NotPaid) return translate("expense_was_partially_paid")
            if (expense && (expense.value <= 0 || expense.propertyIncomesCount > 0)) return translate("expense_paid")
            if (expense?.propertyIncomes.length === 0) return translate("it_was_not_paid")
        },
        [translate],
    )

    if (expenses == null || (userProperties == null && context === AppContext.Owner)) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {activePage === ExpensesPages.ADDED && <HeaderExpenses />}
                {sum(expenses?.pages.flatMap((p) => p.items.length)) === 0 && context === AppContext.Tenant && (
                    <NoDataPage icon={"mdi:tag-off"} noDataText={"tenant_view_no_expense"} />
                )}
                {sum(expenses?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite && context === AppContext.Owner && (
                    <NoDataPage icon={"mdi:tag-off"} noDataText={"no_expenses"} />
                )}
                {expenses.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && !currentGroupId && (
                    <SpeedDial
                        sx={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                        }}
                        color='primary'
                        ariaLabel='expenseActions'
                        icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<MoreVertIcon />} />}
                    >
                        {isExpensesAIExtractorOn && (
                            <SpeedDialAction
                                tooltipTitle={translate("ai_recognition")}
                                tooltipOpen
                                key={"add-ai"}
                                icon={<AddIcon />}
                                onClick={() => dispatch(setModalOpen(AppModals.ExpenseAIExtractorUpload))}
                            />
                        )}
                        <SpeedDialAction
                            tooltipTitle={translate("add")}
                            tooltipOpen
                            key={"add"}
                            icon={<AddIcon />}
                            onClick={() => dispatch(setModalOpen(AppModals.AddExpense))}
                        />
                        <SpeedDialAction
                            key={"export"}
                            icon={<FileDownloadIcon />}
                            tooltipTitle={translate("export")}
                            tooltipOpen
                            onClick={() => {
                                dispatch(setEditingItem({ type: ExportType.Expense }))
                                dispatch(setModalOpen(AppModals.ExportDetailsSheet))
                            }}
                        />
                    </SpeedDial>
                )}

                {currentGroupId && expenses.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && (
                    <Fab
                        onClick={() => dispatch(setModalOpen(AppModals.AddExpense))}
                        sx={{ position: "absolute", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                {expenses.pages.map((group, i) => (
                    <Box
                        key={i}
                        paddingTop={expenses.pages.some((p) => p.items.length === 0) ? 0 : context === AppContext.Tenant ? 0 : 1}
                        gap={1}
                        display={"flex"}
                        flexDirection={"column"}
                        paddingX={context === AppContext.Tenant ? 1 : undefined}
                    >
                        {group.items.map((expense) => (
                            <ExpenseCustomCard
                                key={expense.id}
                                getAmount={getAmount}
                                expense={expense}
                                properties={userProperties}
                                backgroundColor={backgroundColor(expense)}
                                onClick={() => {
                                    dispatch(setEditingItem({ id: expense.id, backgroundColor: backgroundColor(expense) }))
                                    dispatch(setModalOpen(AppModals.ExpenseDetails))
                                }}
                                subtitle={expenseStatus(expense)}
                            />
                        ))}
                    </Box>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {expenses.pages.some((p) => p.items.length === 0) && context === AppContext.Owner && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddExpense))}
                        icon={"mdi:tag-plus"}
                        text={"expenses.addExpense"}
                        isAddDisabled={userProperties?.length === 0}
                        tooltipTitle={userProperties?.length === 0 ? "no_property_no_expense" : "no_tenants_no_expense"}
                    />
                )}
            </Box>
        </>
    )
}

export default MobileExpensesList
