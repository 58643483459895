import { FormProvider, RHFCheckbox, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Box } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { AddRecurringExpenseRequest } from "../../../mutations/expenses"
import { AddRecurringExpenseReviewStepSchema } from "../../../validations/recurringexpense.reviewStep"
import { RecurringType } from "../../../queries/expenses"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { LoadingButton } from "@mui/lab"

interface AddRecurringExpenseReviewData {
    isConfirmed: boolean
}

export interface AddRecurringExpenseReviewStepProps {
    presetValues?: Partial<AddRecurringExpenseRequest>
    onSave: () => void
    onClose: () => void
    isLoading: boolean
}

const AddRecurringExpenseReviewStep = ({ presetValues, onSave, onClose, isLoading }: AddRecurringExpenseReviewStepProps) => {
    const methods = useForm<AddRecurringExpenseReviewData>({
        resolver: yupResolver<AddRecurringExpenseReviewData>(AddRecurringExpenseReviewStepSchema),
        mode: "onChange",
        defaultValues: {
            isConfirmed: false,
        },
    })
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const {
        handleSubmit,
        formState: { isValid },
    } = methods

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const getExpensePeriodByRecurringType = useCallback(() => {
        switch (presetValues?.recurringType) {
            case RecurringType.Monthly:
                return "recurring_expense_description_monthly"
            case RecurringType.Weekly:
                return "recurring_expense_description_weekly"
            case RecurringType.Daily:
                return "recurring_expense_description_daily"
            default:
                throw new Error("Invalid recurring type")
        }
    }, [presetValues])

    const getFirstDate = useCallback(() => {
        const creationDate = DateTime.now().set({ day: presetValues?.creationDay })
        if (creationDate.startOf("day") >= DateTime.now().startOf("day")) return creationDate
        else return creationDate.plus({ month: 1 })
    }, [presetValues])

    const propertyName = useMemo(() => {
        return userProperties?.find((p) => p.id === presetValues?.propertyId)?.label
    }, [userProperties, presetValues])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box justifyContent='center' display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                        <Alert severity='info' sx={{ whiteSpace: "pre-wrap" }}>
                            {translate("recurring_expense_description", {
                                value: getAmount(presetValues?.value ?? 0, presetValues?.currencyId),
                                propertyName,
                                period: translate(getExpensePeriodByRecurringType()),
                                expenseName: presetValues?.name,
                                firstDate: getFirstDate()?.toLocaleString(DateTime.DATE_SHORT),
                                secondDate: getFirstDate()?.plus({ month: 1 }).toLocaleString(DateTime.DATE_SHORT),
                            })}
                        </Alert>
                        <RHFCheckbox name='isConfirmed' label={translate("recurring_expense.accept")} required />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <LoadingButton disabled={isLoading} color={"primary"} onClick={onClose}>
                        {translate("back")}
                    </LoadingButton>
                    <LoadingButton loading={isLoading} type='submit' variant='contained' color={"primary"} disabled={!isValid}>
                        {translate("next")}
                    </LoadingButton>
                </Box>
            </FormProvider>
        </>
    )
}

export default AddRecurringExpenseReviewStep
