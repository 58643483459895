import { array, boolean, number, object, string } from "yup"
import { DateTime } from "luxon"
import { isValidIBAN } from "ibantools"

export const RentingPeriodDetailsSchema = object().shape({
    value: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("minimum_price")
        .min(1, "minimum_price")
        .positive("invalid_value"),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    moveInDate: string()
        .required()
        .test("t", "date_before", (val: string | undefined, context) => {
            if (val == null || context.parent == null) return false
            const moveOutDate = DateTime.fromISO(context.parent.moveOutDate)
            const moveInDate = DateTime.fromISO(val)

            return moveInDate < moveOutDate
        }),
    moveOutDate: string()
        .required()
        .test("t", "date_before", (val: string | undefined, context) => {
            if (val == null || context.parent == null) return false
            const moveOutDate = DateTime.fromISO(val)
            const moveInDate = DateTime.fromISO(context.parent.moveInDate)

            return moveInDate < moveOutDate
        }),
    removeTenantAccessAt: string()
        .nullable()
        .test("t", "date_after_move_out_date", (val: string | undefined | null, context) => {
            if (val == null || context.parent == null) return true
            const removeTenantAccessAt = DateTime.fromISO(val)
            const moveOutDate = DateTime.fromISO(context.parent.moveOutDate)

            return moveOutDate <= removeTenantAccessAt
        }),
    paymentPeriod: array().required().min(1, "payment_period_required"),
    shouldSendC168: boolean().required(),
    bankName: string().test("test", "bank_name_validation", (value, context) => {
        const isBankAccount = context?.parent.bankAccount != null && context.parent.bankAccount.trim() !== ""
        return !(isBankAccount && (value == null || value.trim() === ""))
    }),
    bankAccount: string().test("test", "iban_validation", (value) => {
        if (value == null || value.trim() === "") return true
        const noSpaceIban = value.replace(/\s/g, "")
        return isValidIBAN(noSpaceIban)
    }),
})

export const AddJournalSchema = object().shape({
    id: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    rentingPeriodId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required(),
    title: string().required("title_required").min(3, "min_3_characters"),
    description: string().required("text_required").min(3, "min_3_characters"),
    files: array().required(),
})

export const RentingPeriodStatusSchema = object().shape({
    moveInDate: string()
        .required()
        .test("t", "date_before", (val: string | undefined, context) => {
            if (val == null || context.parent == null) return false
            const moveOutDate = DateTime.fromISO(context.parent.moveOutDate)
            const moveInDate = DateTime.fromISO(val)

            return moveInDate < moveOutDate
        }),
    moveOutDate: string()
        .required()
        .test("t", "date_before", (val: string | undefined, context) => {
            if (val == null || context.parent == null) return false
            const moveOutDate = DateTime.fromISO(val)
            const moveInDate = DateTime.fromISO(context.parent.moveInDate)

            const today = DateTime.now()
            return (
                moveInDate < moveOutDate &&
                (moveOutDate.toLocaleString(DateTime.DATE_SHORT) === today.toLocaleString(DateTime.DATE_SHORT) || moveOutDate > today)
            )
        }),
})

export const SendTenantNotificationDetailsSchema = object().shape({
    subject: string().required("subject_required").min(3, "min_3_characters"),
    emails: array().required("email_required").min(1, "min_1_email"),
    files: array().required(),
})
