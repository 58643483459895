import React from "react"
import ChooseInvoicingTypePage from "./ChooseInvoicingTypePage"
import { InvoicingTypes } from "./Invoicing"
import DryRunInvoicingView from "./DryRunInvoicingView"
import { invoicingTypeAtom } from "../../../utils/atom"
import InvoicingView from "./InvoicingView"
import { useAtomValue } from "jotai"

interface InvoicingContentProps {
    onClose: () => void
}
export default function InvoicingContent({ onClose }: InvoicingContentProps) {
    const invoicingType = useAtomValue(invoicingTypeAtom)

    return (
        <>
            {invoicingType == null ? (
                <ChooseInvoicingTypePage onClose={onClose} />
            ) : (
                <>{invoicingType === InvoicingTypes.DryRunInvoicing ? <DryRunInvoicingView /> : <InvoicingView />}</>
            )}
        </>
    )
}
