import { Box, TextField } from "@mui/material"
import * as React from "react"
import { alpha, useTheme } from "@mui/material/styles"

interface Props {
    defaultValue: string
    name: string
    handleBlur: (newValue: string) => void
}
export default function CustomInlineEditComponent({ defaultValue, handleBlur, name }: Props) {
    const theme = useTheme()
    return (
        <Box paddingTop={1} width={"100%"}>
            <TextField
                required
                defaultValue={defaultValue}
                onBlur={(event) => handleBlur(event.target.value)}
                label={name}
                size={"small"}
                sx={{
                    width: "100%",
                    color: theme.palette.grey.A700,
                    ":hover": {
                        backgroundColor: alpha(theme.palette.grey.A400, 0.1),
                    },
                }}
            />
        </Box>
    )
}
