import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useAddGeneralDocumentMutation } from "../../../mutations/generalDocuments"
import { useNavigate } from "react-router-dom"
import { AddNewDocumentRequest } from "./AddOrEditGeneralDocument"
import UploadGeneralDocumentForm from "../../../guards/alertsGuard/user/generalDocuments/UploadGeneralDocumentForm"

export default function UploadGeneralDocument() {
    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync: addGeneralDocument, isPending } = useAddGeneralDocumentMutation()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onSave = useCallback(
        async (data: AddNewDocumentRequest) => {
            await addGeneralDocument({ data })
            navigate("/document-templates/general-documents")
            closeModal()
        },
        [addGeneralDocument, navigate, closeModal],
    )

    return (
        <>
            <CustomDialog
                title={translate("upload_file")}
                open={!isMobile && modalOpen === AppModals.UploadGeneralDocument}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <UploadGeneralDocumentForm onClose={closeModal} onSave={onSave} isLoading={isPending} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("upload_file")}
                isOpen={isMobile && modalOpen === AppModals.UploadGeneralDocument}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <UploadGeneralDocumentForm onClose={closeModal} onSave={onSave} isLoading={isPending} />
            </DetailsSheet>
        </>
    )
}
