import { IconButton } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import React, { ReactElement } from "react"
import { useTheme } from "@mui/material/styles"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import { LightTooltip, useLocales } from "rentzz"

function CustomDetailPanelToggle(
    props: Pick<GridRenderCellParams, "id" | "value"> & {
        title?: string
        expandedIcon?: ReactElement
        collapsedIcon?: ReactElement
        disabled?: boolean
    },
) {
    const theme = useTheme()
    const { value: isExpanded, title, expandedIcon, collapsedIcon, disabled } = props
    const { translate } = useLocales()

    return (
        <LightTooltip title={translate(title)}>
            <IconButton size='small' tabIndex={-1} aria-label={isExpanded ? "Close" : "Open"} disabled={disabled}>
                {isExpanded
                    ? (expandedIcon ?? <VisibilityOffIcon sx={{ color: theme.palette.primary.main, fontSize: DESKTOP_ICON_SIZE }} />)
                    : (collapsedIcon ?? <RemoveRedEyeIcon sx={{ color: theme.palette.primary.main, fontSize: DESKTOP_ICON_SIZE }} />)}
            </IconButton>
        </LightTooltip>
    )
}

export default CustomDetailPanelToggle
