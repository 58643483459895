import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import AddNewTenantInvitationForm from "../../../sections/propertyDetails/Tenants/rentingPeriodDetails/general/tenants/AddNewTenantInvitationForm"

export default function InviteTenantGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("tenant-data.addTenant")}
            isOpen={modalOpen === AppModals.InviteTenant}
            onClose={() => dispatch(setModalOpen(undefined))}
            headerColor={theme.palette.primary.main}
        >
            <AddNewTenantInvitationForm onClose={() => dispatch(setModalOpen(undefined))} />
        </DetailsSheet>
    )
}
