import { Box } from "@mui/material"
import NotificationsForm from "./NotificationsForm"
import React from "react"

export const RentingPeriodNotificationDesktopContent = () => {
    return (
        <Box display='flex' height={230} overflow={"auto"} flexDirection='column' paddingLeft={5}>
            <NotificationsForm />
        </Box>
    )
}
