import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { Unit, Units } from "rentzz"

export const useUnitsQueryFn = () => {
    return useQuery({ staleTime: Infinity, queryKey: [SimpleQueryIDs.Units], queryFn: Api.fetchUnits })
}

export const getUnitFromId = (data: Unit[] | undefined, unitId: number | undefined): Unit => {
    if (unitId == null || data == null) {
        console.error("Invalid unit or data requested too fast")
        return {
            id: -1,
            code: Units.m3,
        }
    }

    const unit = data.find((c) => c.id === unitId)
    if (unit == null) throw new Error("Invalid unit provided")

    return unit
}
