import { Box, CircularProgress, Divider, MenuItem } from "@mui/material"
import { NotificationTemplateVariable, NotificationVariableType, SystemConfiguredVariables } from "../../../queries/userData"
import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { FormProvider, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { updateNotificationVariableMutation } from "../../../mutations/notifications"
import { useForm } from "react-hook-form"
import CloseIcon from "@mui/icons-material/Close"

export const notificationPattern = /[{{}}]/g

interface Props {
    variable: NotificationTemplateVariable
    handleNotificationPreview: () => void
}

export default function VariableContent({ variable, handleNotificationPreview }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { mutateAsync: updateVariable, isPending } = updateNotificationVariableMutation()
    const methods = useForm()
    const { handleSubmit, getValues, watch, reset } = methods
    const onSubmit = useCallback((_: unknown) => {}, [])

    useEffect(() => {
        reset({
            pattern: variable.pattern.replace(notificationPattern, ""),
            type: variable.type,
            value: variable.value,
        })
    }, [variable, reset])

    useEffect(() => {
        const subscription = watch(async (v, { name }) => {
            if (name === "type") {
                if (
                    (v.pattern === variable.pattern.replace(notificationPattern, "") && v.type === variable.type) ??
                    (NotificationVariableType.Unknown && v.value === variable.value)
                )
                    return
                await updateVariable({
                    variable: {
                        pattern: `{{${v.pattern}}}`,
                        value: "",
                        type: v.type,
                    },
                })
                handleNotificationPreview()
            }
            if (name === "value" && v.type === NotificationVariableType.SystemConfigured) {
                await updateVariable({
                    variable: {
                        pattern: `{{${v.pattern}}}`,
                        value: v.value,
                        type: v.type,
                    },
                })
                handleNotificationPreview()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, variable, handleNotificationPreview])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2} paddingRight={1} paddingTop={2} alignItems={"center"}>
                <RHFTextField name={"pattern"} label={translate("variable")} size={"small"} disabled fullWidth />
                <RHFSelect label={translate("variable_type")} name={"type"} disabled={isPending}>
                    <MenuItem value={NotificationVariableType.Unknown.toString()}>{translate("unknown")}</MenuItem>
                    <MenuItem value={NotificationVariableType.UserConfigured.toString()}>{translate("userDefined")}</MenuItem>
                    <MenuItem value={NotificationVariableType.SystemConfigured.toString()}>{translate("system_configured_variable")}</MenuItem>
                </RHFSelect>

                {(variable.type === NotificationVariableType.UserConfigured || variable.type === NotificationVariableType.Unknown) && (
                    <RHFTextField
                        name='value'
                        label={translate("value")}
                        size={"small"}
                        fullWidth
                        disabled={isPending || variable.type === NotificationVariableType.Unknown}
                        onBlur={async (event) => {
                            if (getValues("type") === NotificationVariableType.UserConfigured) {
                                await updateVariable({
                                    variable: {
                                        pattern: `{{${getValues("pattern")}}}`,
                                        value: event.target.value,
                                        type: NotificationVariableType.UserConfigured,
                                    },
                                })
                                handleNotificationPreview()
                            }
                        }}
                    />
                )}

                {variable.type === NotificationVariableType.SystemConfigured && (
                    <RHFSelect name='value' label={translate("value")} size={"small"} fullWidth disabled={isPending}>
                        <MenuItem value={SystemConfiguredVariables.None.toString()}>{translate("unknown")}</MenuItem>

                        {(Object.keys(SystemConfiguredVariables) as Array<keyof typeof SystemConfiguredVariables>)
                            .filter((v) => Number.isNaN(Number(v)))
                            .filter((v) => SystemConfiguredVariables[v].toString() !== "0")
                            .map((key) => (
                                <MenuItem key={key} value={SystemConfiguredVariables[key].toString()}>
                                    {translate(`system_configured_variable-${SystemConfiguredVariables[key]}`)}
                                </MenuItem>
                            ))}
                    </RHFSelect>
                )}

                <Box width={isMobile ? undefined : 102} height={22}>
                    {isPending && <CircularProgress size={"1rem"} />}

                    {variable.value != null && variable.value !== "" && !isPending && <CheckIcon color={"success"} fontSize={"small"} />}
                    {(variable.value == null || variable.value === "") && !isPending && <CloseIcon color={"error"} fontSize={"small"} />}
                </Box>
            </Box>
            {isMobile && <Divider sx={{ marginTop: 1, backgroundColor: theme.palette.grey["500"] }} />}
        </FormProvider>
    )
}
