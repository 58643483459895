import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { DetailsSheet, useLocales } from "rentzz"
import NotificationsDrawerContent from "../../../sections/notifications/NotificationsDrawerContent"

export default function MobileNotificationSheet() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen } = useSelector((state) => state.appState)
    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.NotificationsList}
            onClose={() => dispatch(setModalOpen(undefined))}
            isLoading={false}
            title={translate("grid_actions.notifyPeriodStatus")}
        >
            <NotificationsDrawerContent />
        </DetailsSheet>
    )
}
