import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { ContractData } from "../../sections/contracts/newContract/Wizard"
import { UserContractTemplate } from "../../queries/userData"

export const createContractMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: ContractData & { label: string } }) => Api.addNewContract(data),
        onSettled: async () => {
            await invalidateQueries([{ query: ComplexQueryIDs.ContractTemplates, params: { isArchived: false } }])
        },
    })
}

export const deleteContractTemplateMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ currentContractTemplateId }: { isArchived: boolean; currentContractTemplateId: string }) =>
            Api.deleteContractTemplate({ contractTemplateId: currentContractTemplateId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ContractTemplates])
            }
        },
    })
}

export const handleArchiveContractTemplate = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ currentTemplateId, isArchived }: { currentTemplateId: string; isArchived: boolean }) =>
            Api.handleArchiveContractTemplate(currentTemplateId, isArchived),
        onMutate: async ({ currentTemplateId, isArchived }) => {
            await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.ContractTemplates, { isArchived: true }] })
            await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.ContractTemplates, { isArchived: false }] })

            const previousArchivedContracts =
                (queryClient.getQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: true }]) as UserContractTemplate[]) ?? []
            const previousActiveContracts =
                (queryClient.getQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: false }]) as UserContractTemplate[]) ?? []

            let contract: UserContractTemplate

            if (isArchived) {
                contract = previousActiveContracts.find((c) => c.id === currentTemplateId)!
            } else contract = previousArchivedContracts.find((c) => c.id === currentTemplateId)!

            queryClient.setQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: true }], (oldData) => {
                const converted = oldData as UserContractTemplate[]
                let toReturn: UserContractTemplate[]

                if (isArchived) {
                    toReturn = [...(converted ?? []), contract]
                } else {
                    toReturn = converted.filter((c) => c.id !== currentTemplateId)
                }

                return toReturn
            })

            queryClient.setQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: false }], (oldData) => {
                const converted = oldData as UserContractTemplate[]
                let toReturn: UserContractTemplate[]

                if (isArchived) {
                    toReturn = converted.filter((c) => c.id !== currentTemplateId)
                } else {
                    toReturn = [...converted, contract]
                }

                return toReturn
            })

            return { previousArchivedContracts, previousActiveContracts }
        },

        onSettled: async (data, error, _, context) => {
            if (error) {
                queryClient.setQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: true }], context?.previousArchivedContracts)
                queryClient.setQueryData([ComplexQueryIDs.ContractTemplates, { isArchived: false }], context?.previousActiveContracts)
            }
            await invalidateQueries([
                { query: ComplexQueryIDs.ContractTemplates, params: { isArchived: true } },
                { query: ComplexQueryIDs.ContractTemplates, params: { isArchived: false } },
            ])
        },
    })
}
