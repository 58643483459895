import AddOrEditPropertyUtilitiesAndFeaturesGuard from "./AddOrEditPropertyUtilitiesAndFeaturesGuard"
import AddOrEditOwnerDetails from "./AddOrEditOwnerDetails"

export default function MarketingGuard() {
    return (
        <>
            <AddOrEditPropertyUtilitiesAndFeaturesGuard />
            <AddOrEditOwnerDetails />
        </>
    )
}
