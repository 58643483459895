import NotificationContainer from "../NotificationContainer"
import { Step, StepContent, StepLabel, Stepper } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { useIsMobile, useLocales } from "rentzz"
import { useUserDataQueryFn, useUserTenantRequests } from "../../../../queries/userData"
import RentingPeriodNotification from "../RentingPeriodNotification"
import TenantContactInfo, { ContactInfoData } from "../../../contracts/livePreview/tenant/TenantContactInfo"
import Signature from "./Signature"
import CheckContractByTenant from "./CheckContractByTenant"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { useIntl } from "react-intl"

export interface TenantWithContract {
    tenantId: number | undefined
    tenantSignature: string | undefined
    tenantContactInfo: ContactInfoData | undefined
    contract: any
}

enum RentingPeriodTenantNotificationSteps {
    Details,
    TenantInfo,
    CheckContract,
    Signature,
}

const RentingPeriodWizard = () => {
    const [activeStep, setActiveStep] = useState<RentingPeriodTenantNotificationSteps>(RentingPeriodTenantNotificationSteps.Details)
    const [tenantInfo, setTenantInfo] = useState<Omit<ContactInfoData, "type"> | undefined>(undefined)
    const [signature, setSignature] = useState<string>("")
    const { data: tenantRequests } = useUserTenantRequests()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()

    const tenantRequestId = useMemo(() => {
        if (tenantRequests == null) return undefined
        return tenantRequests[0].id
    }, [tenantRequests])

    const getRentPrice = useCallback(() => {
        if (tenantRequests) {
            return intl.formatNumber(Number(tenantRequests[0].value), {
                style: "currency",
                currency: getCurrencyFromId(currencyData, tenantRequests[0].currencyId).code,
            })
        }
    }, [tenantRequests, currencyData])

    const getSecurityDeposit = useCallback(() => {
        if (tenantRequests) {
            return intl.formatNumber(Number(tenantRequests[0].securityDeposit), {
                style: "currency",
                currency: getCurrencyFromId(currencyData, tenantRequests[0].securityDepositCurrencyId).code,
            })
        }
    }, [tenantRequests, currencyData])

    const getPaymentPeriod = useCallback(() => {
        if (tenantRequests) {
            return arrayOfNumbersToPeriod(tenantRequests[0].paymentPeriod).join(", ")
        }
    }, [tenantRequests])

    const getSteps = useCallback(() => {
        return [
            {
                title: translate("rent_details_notification"),
            },
            {
                title: translate("personal_details"),
            },
            {
                title: translate("contract"),
            },
            {
                title: translate("signature"),
            },
        ]
    }, [translate, userData, tenantRequestId])

    const onSubmit = useCallback(
        async (signatureData: string) => {
            setSignature(signatureData)
        },
        [setSignature],
    )

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case 0:
                return (
                    <RentingPeriodNotification
                        isNextLoading={false}
                        onNextClick={() => setActiveStep(RentingPeriodTenantNotificationSteps.TenantInfo)}
                        nextText={translate("next")}
                    />
                )
            case 1:
                return (
                    <TenantContactInfo
                        onBackClick={() => setActiveStep(RentingPeriodTenantNotificationSteps.Details)}
                        defaultValues={tenantInfo}
                        onSubmit={(data: Omit<ContactInfoData, "type">) => {
                            setTenantInfo({ ...data, email: userData?.email ?? "" })
                            setActiveStep(RentingPeriodTenantNotificationSteps.CheckContract)
                        }}
                        requiredInfo={tenantRequests![0].contractToBeSigned!.tenantRequiredInfo}
                    />
                )
            case 2:
                return (
                    <CheckContractByTenant
                        goForward={() => setActiveStep(RentingPeriodTenantNotificationSteps.Signature)}
                        goBack={() => setActiveStep(RentingPeriodTenantNotificationSteps.TenantInfo)}
                        tenantInfoData={tenantInfo}
                        tenantSignature={signature}
                        rentPrice={getRentPrice()}
                        paymentPeriod={getPaymentPeriod()}
                        securityDeposit={tenantRequests && tenantRequests[0].securityDeposit ? getSecurityDeposit() : ""}
                        bankName={tenantRequests ? tenantRequests[0].bankName : undefined}
                        bankAccount={tenantRequests ? tenantRequests[0].bankAccount : undefined}
                    />
                )
            case 3:
                return (
                    <Signature
                        onBackClick={() => setActiveStep(RentingPeriodTenantNotificationSteps.CheckContract)}
                        onSignatureSubmit={onSubmit}
                        defaultValue={signature}
                        tenantRequestId={tenantRequestId}
                        rentPrice={getRentPrice()}
                        securityDeposit={tenantRequests && tenantRequests[0].securityDeposit ? getSecurityDeposit() : ""}
                        paymentPeriod={getPaymentPeriod()}
                        tenantInfo={tenantInfo}
                        onNext={(signature) => setSignature(signature)}
                    />
                )
        }
    }, [activeStep, setActiveStep, tenantRequests, signature])

    return (
        <NotificationContainer maxWidth={700} title={translate("add_renting_period")}>
            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                {getSteps().map((step, index) => {
                    return (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    )
                })}
            </Stepper>
            {!isMobile && renderActiveStep()}
        </NotificationContainer>
    )
}

export default RentingPeriodWizard
