import { FormProvider, RHFPhoneNumber, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, CircularProgress, InputAdornment } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect } from "react"
import { ActiveUser } from "../../../queries/userData"
import { UpdateUserPhoneSchema } from "../../../validations/ActiveUsers"
import { updateSendCodeViaSMSMutation } from "../../../mutations/user"

export interface UpdateUserPhoneRequest {
    phoneNumber: string
}
interface Props {
    onClose?: () => void
    currentUser: ActiveUser | undefined
}
export default function UpdateUserPhone({ onClose, currentUser }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: updateSendCodeBySMS, isPending: isSendCodeBySMSPending } = updateSendCodeViaSMSMutation()

    const methods = useForm<UpdateUserPhoneRequest>({
        resolver: yupResolver(UpdateUserPhoneSchema),
        mode: "onBlur",
        defaultValues: {
            phoneNumber: currentUser?.phoneNumber,
        },
    })

    const {
        handleSubmit,
        getValues,
        reset,
        formState: { isValid },
        setError,
    } = methods

    useEffect(() => {
        reset({ phoneNumber: currentUser?.phoneNumber })
    }, [reset, currentUser])

    const onSave = useCallback(
        async (data: UpdateUserPhoneRequest) => {
            if (currentUser == null) return
            await updateSendCodeBySMS({ userId: currentUser.id, phoneNumber: data.phoneNumber, isRequired: currentUser.isSMSCodeActive })
            if (onClose) {
                onClose()
            }
        },
        [currentUser, updateSendCodeBySMS, onClose],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            {isMobile ? (
                <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                    <RHFPhoneNumber name={"phoneNumber"} label={translate("phone")} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isSendCodeBySMSPending}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton
                            type={"submit"}
                            variant='contained'
                            color={"primary"}
                            fullWidth={isMobile}
                            loading={isSendCodeBySMSPending}
                            disabled={isSendCodeBySMSPending}
                        >
                            {translate("update")}
                        </LoadingButton>
                    </Box>
                </Box>
            ) : (
                <Box display={"flex"} paddingY={1}>
                    <RHFPhoneNumber
                        name={"phoneNumber"}
                        label={translate("phone")}
                        onBlur={async (event) => {
                            if (currentUser == null || currentUser.phoneNumber === getValues("phoneNumber")) return
                            if (!isValid) {
                                setError("phoneNumber", { message: "Phone number is not correct" })
                            } else {
                                await updateSendCodeBySMS({
                                    userId: currentUser.id,
                                    phoneNumber: event.target.value,
                                    isRequired: currentUser.isSMSCodeActive,
                                })
                            }
                        }}
                        disabled={isSendCodeBySMSPending}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    {isSendCodeBySMSPending ? <CircularProgress size={"1rem"} /> : <></>}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
        </FormProvider>
    )
}
