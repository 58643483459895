import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { useUserBankAccountsQuery } from "../../../../../queries/userData"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import AddBankAccountForm from "../../../../../sections/nomenclature/bankAccounts/AddBankAccountForm"

export default function AddOrEditBankAccountGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: bankAccounts } = useUserBankAccountsQuery()

    const currentBankAccount = useMemo(() => bankAccounts?.items.find((c) => c.id === editingItem?.id), [bankAccounts, editingItem])

    const handleClose = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.EditBankAccount) {
                dispatch(setModalOpen(AppModals.BankAccountDetails))
            }
            if (modalOpen === AppModals.AddBankAccount) {
                dispatch(setModalOpen(undefined))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch])

    const title = useMemo(() => {
        if (editingItem?.isAssociatingProperties && currentBankAccount) return "add_property"
        if (!editingItem?.isAssociatingProperties && currentBankAccount) return "edit_bank_account"
        return "add_bank_account"
    }, [editingItem, currentBankAccount])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && (modalOpen === AppModals.AddBankAccount || modalOpen === AppModals.EditBankAccount)}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(title)}
            >
                <AddBankAccountForm defaultValues={currentBankAccount} onClose={handleClose} isAssociatingProperties={editingItem?.isAssociatingProperties} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddBankAccount || modalOpen === AppModals.EditBankAccount)}
                onClose={handleClose}
                isLoading={false}
                title={translate(title)}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddBankAccountForm defaultValues={currentBankAccount} onClose={handleClose} isAssociatingProperties={editingItem?.isAssociatingProperties} />
            </DetailsSheet>
        </>
    )
}
