import { IncomeStatus, PropertyIncomePaymentMethod, ProviderStatus } from "../redux/slices/AddProperty"
import { Theme } from "@mui/material"
import { ExpenseAssignee } from "rentzz"
import { alpha } from "@mui/material/styles"

export const getReverseTextColor = (bgColor: string) => {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
    const red = parseInt(color.substring(0, 2), 16)
    const green = parseInt(color.substring(2, 4), 16)
    const blue = parseInt(color.substring(4, 6), 16)
    if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) return "black"
    return "white"
}

export const MOBILE_ICON_SIZE = 20
export const DESKTOP_ICON_SIZE = 18

export const getMarkAsPaidTooltipTitle = (assignee: ExpenseAssignee) => {
    if (assignee === ExpenseAssignee.ME) return "only_expenses_assigned"

    return "mark_as_paid"
}

export const getIncomeOrPaymentStatusColor = (status: IncomeStatus, theme: Theme) => {
    switch (status) {
        case IncomeStatus.Waiting:
        case IncomeStatus.CardPaymentWaiting:
            return theme.palette.warning.main
        case IncomeStatus.Accepted:
        case IncomeStatus.AcceptedFromExternalSystem:
        case IncomeStatus.CardPaymentSucceeded:
            return theme.palette.success.main
        case IncomeStatus.Denied:
        case IncomeStatus.CardPaymentFailed:
            return theme.palette.error.main
    }
}

export const getIncomeOrPaymentStatusIcon = (status: IncomeStatus) => {
    switch (status) {
        case IncomeStatus.Waiting:
        case IncomeStatus.CardPaymentWaiting:
            return "mdi:cash-sync"
        case IncomeStatus.AcceptedFromExternalSystem:
        case IncomeStatus.Accepted:
        case IncomeStatus.CardPaymentSucceeded:
            return "mdi:cash-check"
        case IncomeStatus.Denied:
        case IncomeStatus.CardPaymentFailed:
            return "mdi:cash-remove"
    }
}

export const getProviderStatusIcon = (status?: ProviderStatus) => {
    switch (status) {
        case ProviderStatus.Pending:
            return "mdi:sync"
        case ProviderStatus.Active:
            return "mdi:check"
        case ProviderStatus.Rejected:
            return "mdi:cancel"
        default:
            return ""
    }
}

export const getIncomeOrPaymentBackgroundColor = (status: IncomeStatus, theme: Theme) => {
    switch (status) {
        case IncomeStatus.Waiting:
        case IncomeStatus.CardPaymentWaiting:
            return alpha(theme.palette.warning.main, 0.1)
        case IncomeStatus.AcceptedFromExternalSystem:
        case IncomeStatus.Accepted:
        case IncomeStatus.CardPaymentSucceeded:
            return alpha(theme.palette.success.main, 0.1)
        case IncomeStatus.Denied:
        case IncomeStatus.CardPaymentFailed:
            return alpha(theme.palette.error.main, 0.1)
    }
}

export const getIncomeLabelColorByStatus = (status: IncomeStatus | undefined) => {
    switch (status) {
        case IncomeStatus.Waiting:
        case IncomeStatus.CardPaymentWaiting:
            return "warning"
        case IncomeStatus.AcceptedFromExternalSystem:
        case IncomeStatus.CardPaymentSucceeded:
        case IncomeStatus.Accepted:
            return "success"
        case IncomeStatus.Denied:
        case IncomeStatus.CardPaymentFailed:
            return "error"
    }
}

export const getIncomeLabelColorByPaymentMethod = (value: PropertyIncomePaymentMethod) => {
    switch (value) {
        case PropertyIncomePaymentMethod.Cash:
            return "info"
        case PropertyIncomePaymentMethod.Bank:
            return "primary"
        case PropertyIncomePaymentMethod.Online:
            return "secondary"
    }
}


