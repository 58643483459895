import { Box, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React from "react"
import { useIsMobile, useLocales } from "rentzz"
import { PropertyInsurance } from "../../../redux/slices/AddProperty"

interface ActionSheetInsuranceNotificationProps {
    onClose: () => void
    onSubmit: () => void
    currentInsurance: (PropertyInsurance & { canWrite: boolean }) | null | undefined
}
export default function ActionSheetInsuranceNotification({ onClose, onSubmit, currentInsurance }: ActionSheetInsuranceNotificationProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Typography paddingY={2} textAlign={"center"}>
                {currentInsurance?.shouldNotifyForRenewal
                    ? translate("insurance.editNotificationContentOff")
                    : translate("insurance.editNotificationContentOn")}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <LoadingButton onClick={onClose} variant={"text"} color={"primary"}>
                    {translate("cancel")}
                </LoadingButton>

                <LoadingButton onClick={onSubmit} variant='contained' color={currentInsurance?.shouldNotifyForRenewal ? "error" : "primary"}>
                    {currentInsurance?.shouldNotifyForRenewal ? translate("stop") : translate("yes")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
