import { DateTime } from "luxon"
import { PropertyInsurance } from "../../redux/slices/AddProperty"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"

export const getNotificationIcon = (
    value: PropertyInsurance,
    fontSize: number,
    color: "disabled" | "action" | "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
) => {
    if (value.endDate < DateTime.now()) {
        return <NotificationsOffIcon sx={{ fontSize }} color={color} />
    } else if (value.shouldNotifyForRenewal) {
        return <NotificationsActiveIcon sx={{ fontSize }} color={color} />
    }
    return <NotificationsNoneIcon sx={{ fontSize }} color={color} />
}

export const getNotificationIconInsideActionSheet = (value: PropertyInsurance) => {
    if (value.endDate < DateTime.now()) {
        return "mdi:bell-off"
    } else if (value.shouldNotifyForRenewal) {
        return "mdi:bell-ring"
    }
    return "mdi:bell-outline"
}

export const getTooltipNotificationTile = (value: PropertyInsurance) => {
    if (value.endDate < DateTime.now()) {
        return "insurance.notificationCanceled"
    } else if (!value.shouldNotifyForRenewal) {
        return "insurance.notificationOn"
    } else return "insurance.notificationOff"
}

export const getNotificationTile = (value: PropertyInsurance) => {
    if (value.endDate < DateTime.now()) {
        return "insurance.notificationCanceled"
    } else if (!value.shouldNotifyForRenewal) {
        return "insurance.notificationInfoOnMobile"
    } else return "insurance.notificationInfoOffMobile"
}
