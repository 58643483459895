import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { SyntheticEvent, useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import { deleteGroupAccessMutation } from "../../../mutations/groups"

export default function DeleteGroupAccessGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const { mutateAsync: deleteGroup, isPending: isDeleteLoading } = deleteGroupAccessMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            await deleteGroup({ groupId: editingItem.id, userId: editingItem.userId })

            onClose()
        },
        [editingItem, deleteGroup, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_group_access")}
                isOpen={isMobile && modalOpen === AppModals.DeleteGroupAccess}
                onClose={() => {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                }}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_group_access_content")}
                    onClose={() => {
                        dispatch(setModalOpen(AppModals.GroupDetails))
                    }}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteGroupAccess}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_group_access")}
                content={translate("delete_group_access_content")}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}
