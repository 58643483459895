import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useSingleActiveUserQuery } from "../../../../queries/userData"
import React, { useCallback } from "react"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import UpdateUserPhone from "../../../../sections/dashboard/Tables/UpdateUserPhone"

export default function EditUserPhone() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentUser } = useSingleActiveUserQuery(editingItem?.id)

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(AppModals.ActiveUserDetailsSheet))
    }, [dispatch])

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("edit_phone")}
            isOpen={isMobile && modalOpen === AppModals.EditActiveUserPhone}
            onClose={closeModal}
        >
            <UpdateUserPhone onClose={closeModal} currentUser={currentUser ?? undefined} />
        </DetailsSheet>
    )
}
