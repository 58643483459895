import { AppModals, setModalOpen } from "../../../redux/slices/App"
import { AppContext, DetailsSheet, ExpenseAssignee, useLocales } from "rentzz"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../utils"
import { DateTime } from "luxon"
import { getAssigneeLabelColor } from "../../../sections/expenses/utils"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { UserFlags, useUserDataQueryFn, useUserLabelsQuery, useUserPropertiesQuery } from "../../../queries/userData"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useSinglePendingExpenseQueryFn } from "../../../queries/expenses"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"

interface ModalProps {
    onClose: () => void
}
export default function PendingExpenseDetailsSheet({ onClose }: ModalProps) {
    const intl = useIntl()
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isAutoSendEnabled = useFeatureIsOn(UserFlags.SendAutomaticallyExpenseToProvider as string)
    const { data: currencyData, isFetching: isCurrencyDataLoading } = useCurrencyQueryFn()
    const { data: labels, isFetching: areLabelsLoading } = useUserLabelsQuery()
    const { context, editingItem, modalOpen } = useSelector((state) => state.appState)
    const { data: userData, isFetching: isUserDataLoading } = useUserDataQueryFn()
    const { data: properties, isFetching: arePropertiesLoading } = useUserPropertiesQuery()
    const { data: currentPendingExpense, isFetching: isCurrentPendingExpenseLoading } = useSinglePendingExpenseQueryFn(editingItem?.id)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [userData],
    )

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentPendingExpense == null) return []
        return [
            {
                icon: <StopCircleIcon color={"warning"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "stop_sending_expense_title",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeactivateSendingExpenseToTenant))
                },
                hidden: !currentPendingExpense.canWrite || !isAutoSendEnabled || currentPendingExpense.dateAutomaticallySentToTenant == null,
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "details",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.PendingExpenseStatus))
                },
                hidden: !currentPendingExpense.canWrite,
            },
        ]
    }, [currentPendingExpense, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PendingExpenseDetails}
            onClose={onClose}
            isLoading={isCurrencyDataLoading || isUserDataLoading || isCurrentPendingExpenseLoading || areLabelsLoading || arePropertiesLoading}
            title={currentPendingExpense?.name}
            options={options}
            subtitle={currentPendingExpense?.providerName}
            headerColor={theme.palette.warning.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:cash",
                    `${getAmount(currentPendingExpense?.value ?? 0, currentPendingExpense?.currencyId)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("createdAt")}: ${currentPendingExpense?.date?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-clock",
                    `${translate("due_date")}: ${currentPendingExpense?.dueDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {currentPendingExpense?.labelId &&
                    renderActionSheetInfo(
                        "mdi:label",
                        labels?.find((label) => label.id === currentPendingExpense.labelId)?.value,
                        `#${getLabelColor(currentPendingExpense.labelId)}`,
                        theme.palette.grey["600"],
                    )}
                {currentPendingExpense &&
                    renderActionSheetInfo(
                        "mdi:tag-multiple",
                        `${context === AppContext.Owner ? translate(`expenses.assignee-${currentPendingExpense.assignee}${customPaidBy ? "-" + customPaidBy : ""}`) : ""}`,
                        currentPendingExpense.assignee === ExpenseAssignee.ME ? theme.palette.secondary.main : theme.palette.primary.main,
                        theme.palette.grey["600"],
                        true,
                        "",
                        currentPendingExpense ? getAssigneeLabelColor(currentPendingExpense.assignee) : "default",
                    )}
                {renderActionSheetInfo(
                    "mdi:map-marker",
                    properties?.find((p) => p.id === currentPendingExpense?.propertyId)?.label,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {isAutoSendEnabled &&
                    currentPendingExpense?.dateAutomaticallySentToTenant != null &&
                    renderActionSheetInfo(
                        "mdi:information-variant",
                        translate("stop_sending_expense_sheet", {
                            sendingDate: currentPendingExpense?.dateAutomaticallySentToTenant.toLocaleString(DateTime.DATE_SHORT),
                        }),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
