import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { getGroupAccessList } from "../../../queries/groups"
import DeleteIcon from "@mui/icons-material/Delete"
import { DateTime } from "luxon"

export default function GroupAccessDetails() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: groupAccess } = getGroupAccessList()
    const { modalOpen, editingItem, currentGroupId } = useSelector((state) => state.appState)
    const currentAccess = useMemo(() => groupAccess?.find((ga) => ga.userId == editingItem?.id), [groupAccess, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentAccess == null) return []
        return [
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteGroupAccess))
                    dispatch(setEditingItem({ id: currentGroupId, userId: currentAccess.userId }))
                },
            },
        ]
    }, [dispatch, currentAccess, currentGroupId])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.GroupAccessDetails}
            onClose={() => {
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentAccess?.fullName}
            subtitle={translate(`managerType-${currentAccess?.ownershipType}`)}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo("mdi:account", `${currentAccess?.fullName}`, theme.palette.primary.main, theme.palette.grey["600"])}
            {renderActionSheetInfo(
                "mdi:info",
                translate(`managerType-${currentAccess?.ownershipType}`),
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar",
                `${currentAccess?.date.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
