import { FormProvider, Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import React, { useCallback, useEffect, useState } from "react"
import { Card, CardHeader, Divider, Switch } from "@mui/material"
import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import { AppNotifications } from "../../../../../../redux/slices/AddProperty"
import * as jsonpatch from "fast-json-patch"
import { updateRentingPeriodMutation } from "../../../../../../mutations/tenants"
import { allPossibleNotifications } from "./NotificationsForm"
import { useForm } from "react-hook-form"
import { RentingPeriodNotificationMobileContent } from "./RentingPeriodNotificationMobileContent"
import { RentingPeriodNotificationDesktopContent } from "./RentingPeriodNotificationDesktopContent"
import { useSelector } from "../../../../../../redux/store"

export default function RentingPeriodNotificationCard() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const [areAllEnabled, setAreAllEnabled] = useState(false)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const methods = useForm<Record<AppNotifications, boolean>>()
    const { handleSubmit, watch, reset, getValues } = methods

    useEffect(() => {
        setAreAllEnabled(allPossibleNotifications.every((n) => rentingPeriodDetails?.enabledNotifications.includes(n)))
    }, [rentingPeriodDetails])

    useEffect(() => {
        if (rentingPeriodDetails) {
            const notificationsStatus = {} as Record<AppNotifications, boolean>
            const offNotifications = allPossibleNotifications.filter(
                (notification) => !rentingPeriodDetails.enabledNotifications.includes(notification),
            )
            rentingPeriodDetails.enabledNotifications.forEach((notification: AppNotifications) => (notificationsStatus[notification] = true))
            offNotifications.forEach((notification: AppNotifications) => (notificationsStatus[notification] = false))

            reset(notificationsStatus)
        }
    }, [rentingPeriodDetails, reset])

    const handleUpdate = useCallback(
        async (enabledItems: number[]) => {
            const operations = jsonpatch.compare(
                { enabledNotifications: rentingPeriodDetails?.enabledNotifications },
                { enabledNotifications: enabledItems },
            )

            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    useEffect(() => {
        const subscription = watch((values) => {
            const enabledItems = Object.keys(values)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .filter((key) => values[key] === true)
                .map(Number)

            handleUpdate(enabledItems)
        })

        return () => subscription.unsubscribe()
    }, [watch, rentingPeriodDetails])

    const handleToggleAll = useCallback(() => {
        setAreAllEnabled((prevAreAllEnabled) => {
            const notificationsStatus = {} as Record<AppNotifications, boolean>

            if (prevAreAllEnabled) {
                allPossibleNotifications.forEach((n) => (notificationsStatus[n] = false))
            } else {
                allPossibleNotifications.forEach((n) => (notificationsStatus[n] = true))
            }
            reset(notificationsStatus)

            return !prevAreAllEnabled
        })
    }, [getValues, reset])

    return (
        <Card elevation={6}>
            <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
                <CardHeader
                    title={translate("grid_actions.notifyPeriodStatus")}
                    subheader={
                        isMobile ? (
                            <LightTooltip title={translate("choose_notification")} arrow>
                                <Iconify icon={"mdi:information"} />
                            </LightTooltip>
                        ) : (
                            `(${translate("choose_notification")})`
                        )
                    }
                    sx={{
                        p: 2,
                        color: theme.palette.grey.A700,
                        ".MuiCardHeader-content": { display: "flex", gap: 1, alignItems: "center" },
                        ".MuiTypography-root": { display: "flex", marginTop: 0 },
                    }}
                    action={
                        rentingPeriodDetails?.canWrite && (
                            <LightTooltip title={rentingPeriodDetails.invitedUsers.length === 0 ? translate("disabled_when_no_mails") : ""} arrow>
                                <span>
                                    <Switch
                                        checked={areAllEnabled}
                                        onChange={handleToggleAll}
                                        sx={{ marginRight: isMobile ? 0.5 : 1 }}
                                        disabled={isRentingPeriodUpdating || rentingPeriodDetails.invitedUsers.length === 0}
                                    />
                                </span>
                            </LightTooltip>
                        )
                    }
                />
                <Divider />
                {isMobile ? <RentingPeriodNotificationMobileContent /> : <RentingPeriodNotificationDesktopContent />}
            </FormProvider>
        </Card>
    )
}
