import { Alert, Box, Button, IconButton } from "@mui/material"
import { Close, FileDownload, IosShare, KeyboardArrowDown, NotificationsActive } from "@mui/icons-material"
import React, { useCallback, useEffect, useState } from "react"
import { AppContext, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags, useUserDataQueryFn } from "../queries/userData"
import { useSelector } from "../redux/store"
import { updateBackendPushNotificationsStatus } from "../mutations/user"
import { useSnackbar } from "notistack"
import CancelIcon from "@mui/icons-material/Cancel"

let deferredPrompt: Event
window.addEventListener("beforeinstallprompt", (e) => {
    // Prevents the default mini-infobar or install dialog from appearing on mobile
    e.preventDefault()
    // Save the event because you'll need to trigger it later.
    deferredPrompt = e
    // Show your customized install prompt for your PWA
    // Your own UI doesn't have to be a single element, you
    // can have buttons in different locations, or wait to prompt
    // as part of a critical journey.
    // showInAppInstallPromotion();
})

const nextInstallPromptDate = localStorage.getItem("nextInstallPrompt")
const nextNotificationPromptDate = localStorage.getItem("nextNotificationsPrompt")
const appIsNotInstalled = window.matchMedia("(display-mode: browser)").matches
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
}

const PWANotifications = () => {
    const isMobile = useIsMobile()
    const isInstallPromptEnabled = useFeatureIsOn(UserFlags.InstallPrompt.toString())
    const { data: userData } = useUserDataQueryFn()
    const isNotificationsPromptEnabled = useFeatureIsOn(UserFlags.NotificationPrompt.toString())
    const { enqueueSnackbar } = useSnackbar()
    const { context } = useSelector((state) => state.appState)
    const [showIosNextStep, setShowIosNextStep] = useState(false)
    const [hasNotificationPermission, setHasNotificationPermission] = useState(
        "Notification" in window && window.Notification.permission === "granted",
    )
    const { translate } = useLocales()
    const { mutate } = updateBackendPushNotificationsStatus()
    const [showInstallPrompt, setShowInstallPrompt] = useState<boolean>(
        appIsNotInstalled &&
            (nextInstallPromptDate ? DateTime.fromISO(localStorage.getItem("nextInstallPrompt") ?? "") : DateTime.now()) <= DateTime.now(),
    )
    const [showNotificationPrompt, setShowNotificationPrompt] = useState<boolean>(
        "Notification" in window &&
            (nextNotificationPromptDate ? DateTime.fromISO(localStorage.getItem("nextNotificationsPrompt") ?? "") : DateTime.now()) <= DateTime.now(),
    )

    const handleInstall = useCallback(async () => {
        if (isIos()) {
            setShowIosNextStep(true)
            return
        }

        if (deferredPrompt == null) {
            setShowInstallPrompt(false)
            localStorage.setItem("nextInstallPrompt", DateTime.now().plus({ day: 2 }).toISO())
            enqueueSnackbar(translate("not_available_device"), { autoHideDuration: 10000, variant: "error" })
            return
        }
        // @ts-expect-error prompt is not known in TS
        deferredPrompt.prompt()
        // @ts-expect-error prompt is not known in TS
        const { outcome } = await deferredPrompt.userChoice
        // @ts-expect-error prompt is not known in TS
        deferredPrompt = null
        if (outcome === "accepted") {
            if (!isIos()) {
                setTimeout(() => {
                    window.open("./", "_blank")
                }, 5000)
            }
            setShowInstallPrompt(false)
            console.log("User accepted the install prompt.")
        } else if (outcome === "dismissed") {
            console.log("User dismissed the install prompt")
        }
    }, [])

    const handleNotifications = useCallback(async () => {
        window.Notification.requestPermission().then((r) => {
            setShowNotificationPrompt(false)
            localStorage.setItem("nextNotificationsPrompt", DateTime.now().plus({ day: 2 }).toISO())
            setHasNotificationPermission(r === "granted")
            if (r === "granted") {
                mutate({ notificationsActive: true })
            }
        })
    }, [])

    useEffect(() => {
        if (userData) {
            mutate({ notificationsActive: window.Notification?.permission === "granted" })
        }
    }, [userData])

    const handlePromptClose = useCallback(() => {
        setShowInstallPrompt(false)
        localStorage.setItem("nextInstallPrompt", DateTime.now().plus({ day: 2 }).toISO())
    }, [])

    const handleNotificationsPromptClose = useCallback(() => {
        setShowNotificationPrompt(false)
        localStorage.setItem("nextNotificationsPrompt", DateTime.now().plus({ day: 2 }).toISO())
    }, [])

    useEffect(() => {
        if (import.meta.env.DEV || (showInstallPrompt && isIos() && isInstallPromptEnabled)) {
            setTimeout(() => {
                window.scrollTo(0, 1)
            }, 200)
        }
    }, [showInstallPrompt, isInstallPromptEnabled])

    return (
        <>
            {isMobile && showInstallPrompt && isInstallPromptEnabled && !showIosNextStep && (
                <Alert
                    severity='info'
                    action={
                        <IconButton onClick={handlePromptClose} color='inherit' size='small'>
                            <Close />
                        </IconButton>
                    }
                >
                    <Box display='flex' flexDirection='column'>
                        {translate(context === AppContext.Owner ? "install_prompt" : "install_prompt_tenant")}
                        <Button startIcon={isIos() ? null : <FileDownload />} onClick={handleInstall}>
                            {translate(isIos() ? "next" : "download_app")}
                        </Button>
                    </Box>
                </Alert>
            )}
            {(!isMobile || !showInstallPrompt) && !hasNotificationPermission && showNotificationPrompt && isNotificationsPromptEnabled && (
                <Alert
                    severity='info'
                    action={
                        <IconButton onClick={handleNotificationsPromptClose} color='inherit' size='small'>
                            <Close />
                        </IconButton>
                    }
                    sx={{
                        width: "100%",
                        "& .MuiAlert-message": {
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        },
                        "& .MuiAlert-icon": {
                            display: !isMobile ? "none" : "flex",
                        },
                        "& .MuiAlert-action": {
                            alignItems: "center",
                        },
                    }}
                >
                    <Box display='flex' flexDirection='column'>
                        {translate(context === AppContext.Owner ? "notifications_prompt" : "notifications_prompt_tenant")}
                        <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between'>
                            {!isMobile && (
                                <Button startIcon={<CancelIcon />} color={"error"} onClick={handleNotificationsPromptClose}>
                                    {translate("ask_later")}
                                </Button>
                            )}
                            <Button startIcon={<NotificationsActive />} onClick={handleNotifications}>
                                {translate("activate")}
                            </Button>
                        </Box>
                    </Box>
                </Alert>
            )}
            {showIosNextStep && isMobile && isIos() && showInstallPrompt && isInstallPromptEnabled && (
                <Alert severity='info' icon={false} sx={{ position: "absolute", bottom: 0, zIndex: 99999999, textAlign: "center", pt: 0 }}>
                    <Box display='flex' flexDirection='row-reverse'>
                        <IconButton onClick={handlePromptClose} color='inherit' size='small'>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                        {translate("install_prompt_ios_1")}
                        <LightTooltip title={translate("not_this_icon_ios")}>
                            <IosShare />
                        </LightTooltip>
                        {translate("install_prompt_ios_2")}
                        <LightTooltip title={translate("not_this_icon_ios")}>
                            <KeyboardArrowDown fontSize='small' />
                        </LightTooltip>
                    </Box>
                </Alert>
            )}
        </>
    )
}

export default PWANotifications
