import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { SyntheticEvent, useCallback } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useTheme } from "@mui/material"
import { getNoteByIdQueryFn } from "../../../queries/notes"
import { deleteNoteMutation } from "../../../mutations/notes"

const DeleteNoteGuard = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem, currentPropertyId } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const currentNote = getNoteByIdQueryFn(editingItem?.id, [{ field: "addedAt", sort: "desc" }])
    const { mutateAsync: deleteNote, isPending: isDeleteLoading } = deleteNoteMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (!currentNote) return
            await deleteNote({ noteId: currentNote.noteId, propertyId: currentPropertyId })

            onClose()
        },
        [currentPropertyId, deleteNote, currentNote, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_note")}
                isOpen={isMobile && modalOpen === AppModals.DeleteNote}
                onClose={() => {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                }}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_note_content", { name: currentNote?.title })}
                    onClose={() => {
                        dispatch(setModalOpen(AppModals.NoteDetails))
                    }}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteNote}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_note")}
                content={translate("delete_note_content", { name: currentNote?.title })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}

export default DeleteNoteGuard
