import { Box, CircularProgress, MenuItem, Select, Typography } from "@mui/material"
import { LightTooltip, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback } from "react"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { updatePropertyDetailsMutation } from "../../mutations"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { useTheme } from "@mui/material/styles"
import { usePropertyDetailsQueryFn } from "../../queries"

export default function ConfigPropertyCrm() {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: currentProperty } = usePropertyDetailsQueryFn()
    const { canWrite } = usePermissions(PermissionType.Properties)
    const { mutateAsync: updateProperty, isPending: isUpdatePropertyPending } = updatePropertyDetailsMutation()

    const handleCRMStatus = useCallback(
        async (event: SelectChangeEvent<string>) => {
            const newValue = event.target?.value === "true"
            if (newValue === currentProperty?.marketingSettings?.isListed) return
            await updateProperty({
                operations: [{ op: "replace", path: "/marketingSettings/isListed", value: newValue }],
            })
        },
        [updateProperty, currentProperty],
    )

    return (
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
            <Typography variant='body2' fontWeight={"bold"}>
                {translate("property_sent_to_CRM")}
            </Typography>
            <Box display={"flex"} justifyContent={"flex-end"} alignContent={"center"} width={130}>
                {!isUpdatePropertyPending && (
                    <LightTooltip title={!canWrite ? translate("only_read_no_write") : ""}>
                        <span>
                            <Select
                                defaultValue={
                                    currentProperty?.marketingSettings?.isListed
                                        ? currentProperty.marketingSettings.isListed.toString()
                                        : false.toString()
                                }
                                onChange={handleCRMStatus}
                                size='small'
                                sx={{ color: theme.palette.grey.A700 }}
                                disabled={!canWrite}
                                fullWidth={isMobile}
                                style={{ width: 130 }}
                            >
                                <MenuItem value={"false"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("no")}
                                </MenuItem>
                                <MenuItem value={"true"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("yes")}
                                </MenuItem>
                            </Select>
                        </span>
                    </LightTooltip>
                )}
                {isUpdatePropertyPending && <CircularProgress size={18} />}
            </Box>
        </Box>
    )
}
