import { FormProvider, RHFPhoneNumber, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../redux/store"
import { updatePropertyDetailsMutation } from "../../mutations"
import { useForm } from "react-hook-form"
import { AddPropertyOwnerDetailsSchema } from "../../validations/marketing"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCallback } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"

export interface AddPropertyOwnerDetailsRequest {
    firstName: string
    lastName: string
    email?: string
    phoneNumber?: string
}

interface Props {
    defaultValues?: any
    onClose: () => void
}

export default function AddPropertyOwnerDetailsForm({ defaultValues, onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { mutateAsync: updatePropertyDetails, isPending: isUpdatePropertyDetailsPending } = updatePropertyDetailsMutation()

    const methods = useForm<AddPropertyOwnerDetailsRequest>({
        resolver: yupResolver<AddPropertyOwnerDetailsRequest>(AddPropertyOwnerDetailsSchema),
        mode: "onBlur",
        defaultValues: defaultValues,
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddPropertyOwnerDetailsRequest) => {
            if (currentPropertyId) {
                await updatePropertyDetails({
                    operations: [
                        { op: "replace", path: "/marketingSettings/firstName", value: data.firstName },
                        { op: "replace", path: "/marketingSettings/lastName", value: data.lastName },
                        { op: "replace", path: "/marketingSettings/email", value: data.email },
                        { op: "replace", path: "/marketingSettings/phoneNumber", value: data.phoneNumber },
                    ],
                })
                onClose()
            }
        },
        [defaultValues, updatePropertyDetails, currentPropertyId, onClose],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFTextField name='firstName' label={translate("first_name")} required />
                    <RHFTextField name='lastName' label={translate("last_name")} required />
                </Box>
                <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                    <RHFTextField name='email' label={translate("email")} />
                    <RHFPhoneNumber variant='outlined' defaultCountry='RO' forceCallingCode={true} name={"phoneNumber"} label={translate("phone")} />
                </Box>
                <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"} marginTop={2}>
                    {onClose && (
                        <Button color={"primary"} onClick={onClose} disabled={isUpdatePropertyDetailsPending}>
                            {translate("cancel")}
                        </Button>
                    )}

                    <LoadingButton
                        color={"primary"}
                        loading={isUpdatePropertyDetailsPending}
                        type='submit'
                        variant='contained'
                        disabled={isUpdatePropertyDetailsPending}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
