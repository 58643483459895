import { ReactNode } from "react"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { LoadingScreen } from "rentzz"
import PrivacyPolicyAccept from "./PrivacyPolicyAccept"
import TOSAccept from "./TOSAccept"
import { AccountCreationAccept } from "./AccountCreationAccept"
import { useFeatureValue } from "@growthbook/growthbook-react"

type AuthGuardProps = {
    children: ReactNode
}

export default function AcceptanceGuard({ children }: AuthGuardProps) {
    const { data: userData } = useUserDataQueryFn()
    const privacyPolicyUrl = useFeatureValue(UserFlags.PrivacyPolicyUrl.toString(), "")
    const tosURL = useFeatureValue(UserFlags.TosUrl.toString(), "")
    if (userData == null) {
        return <LoadingScreen />
    }

    if (userData.showTOS && userData.showPrivacyPolicy && privacyPolicyUrl.length > 0 && tosURL.length > 0) {
        return <AccountCreationAccept />
    }

    if (userData.showTOS) return <TOSAccept />
    if (userData.showPrivacyPolicy) return <PrivacyPolicyAccept />

    return <>{children}</>
}
