import { Box, CircularProgress, MenuItem, Paper, Select, Stack, Typography } from "@mui/material"
import { Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import React, { ReactElement } from "react"
import { useTheme } from "@mui/material/styles"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { useCurrencyQueryFn } from "../../../../../queries/currency"

interface Props {
    title: string
    isLoading: boolean
    canWrite: boolean | undefined
    defaultValue: string
    handleSelect: (event: SelectChangeEvent<string>) => void

    isActionDisabled?: boolean
    icon?: string
    tooltipTitle?: string | ReactElement
    disabledTooltipTitle?: string
    onCurrencyChange?: (currencyId: SelectChangeEvent<string>) => void
    defaultCurrency?: number
}
export default function ConfigCustomCard({
    title,
    tooltipTitle,
    icon,
    isLoading,
    canWrite,
    defaultValue,
    handleSelect,
    disabledTooltipTitle,
    isActionDisabled,
    onCurrencyChange,
    defaultCurrency,
}: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: currencies } = useCurrencyQueryFn()

    if (currencies == null) return

    return (
        <Paper elevation={3} square={false}>
            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent='space-between' p={2}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Typography variant='subtitle1' sx={{ color: "text.secondary" }}>
                        {translate(title)}
                    </Typography>
                    {icon && (
                        <LightTooltip
                            arrow
                            slotProps={{
                                tooltip: {
                                    sx: {
                                        maxWidth: isMobile ? undefined : 500,
                                    },
                                },
                            }}
                            title={isActionDisabled ? "" : tooltipTitle}
                        >
                            <Iconify icon={icon} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                        </LightTooltip>
                    )}
                </Box>

                <Box
                    display={"flex"}
                    gap={2}
                    flexDirection={isMobile ? "column" : "row"}
                    width={isMobile ? "100%" : undefined}
                    paddingTop={isMobile ? 2 : undefined}
                >
                    <LightTooltip arrow title={disabledTooltipTitle}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={1}>
                            {!isLoading && (
                                <Select
                                    defaultValue={defaultValue}
                                    onChange={handleSelect}
                                    size='small'
                                    sx={{ color: theme.palette.grey.A700 }}
                                    disabled={!canWrite || isActionDisabled}
                                    fullWidth={isMobile}
                                >
                                    <MenuItem value={"false"} sx={{ color: theme.palette.grey.A700 }}>
                                        {translate("no")}
                                    </MenuItem>
                                    <MenuItem value={"true"} sx={{ color: theme.palette.grey.A700 }}>
                                        {translate("yes")}
                                    </MenuItem>
                                </Select>
                            )}
                            {onCurrencyChange && !isLoading && (
                                <Select
                                    defaultValue={defaultCurrency?.toString()}
                                    onChange={onCurrencyChange}
                                    size='small'
                                    sx={{ color: theme.palette.grey.A700 }}
                                    disabled={!canWrite || isActionDisabled}
                                    fullWidth={isMobile}
                                >
                                    {currencies.map((c) => (
                                        <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                            {c.code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            {isLoading && <CircularProgress size={18} />}
                        </Box>
                    </LightTooltip>
                </Box>
            </Stack>
        </Paper>
    )
}
