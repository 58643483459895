import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
// config
//
import { BackendFetch, DevTools, I18nextPlugin, Tolgee, withTolgee } from "@tolgee/i18next"

// ----------------------------------------------------------------------
const tolgee = Tolgee()
    .use(BackendFetch())
    .use(DevTools())
    .use(I18nextPlugin())
    .init({
        // for development
        apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
        apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    })

withTolgee(i18n, tolgee)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem("i18nextLng") || "ro",
        fallbackLng: "ro",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
