import { Alert, Box, Button, List, ListItem, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { FormProvider, RHFCheckbox, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentingPeriodDeleteSchema } from "../../../../validations/rentingPeriodDeleteSchema"
import { useRentingPeriodSummaryData, useSingleRentingPeriodQuery } from "../../../../queries/tenants"

interface DeleteRentingPeriodReviewStepProps {
    onBack: () => void
    onNext: (sendCopy: boolean) => void
}

const DeleteRentingPeriodReviewStep = ({ onBack, onNext }: DeleteRentingPeriodReviewStepProps) => {
    const { data: properties } = useUserPropertiesQuery()
    const { editingItem } = useSelector((state) => state.appState)
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { data: currentRentingPeriodSummaryData } = useRentingPeriodSummaryData(editingItem?.id)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(editingItem?.rentingPeriodId)
    const methods = useForm({
        resolver: yupResolver(RentingPeriodDeleteSchema),
        defaultValues: {
            ownerWantsACopy: false,
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        (data: { ownerWantsACopy: boolean }) => {
            onNext(data.ownerWantsACopy)
        },
        [onNext],
    )

    return (
        <Box display='flex' flexDirection='column' paddingTop={2}>
            <Alert severity={"error"} sx={{ marginY: 2 }}>
                {translate("delete_renting_period_review_alert")}
            </Alert>
            <Typography fontSize={15} color={theme.palette.grey.A700}>
                {translate("delete_renting_period_details", {
                    date: `${currentRentingPeriod?.moveInDate.toLocaleString(
                        DateTime.DATE_SHORT,
                    )} - ${currentRentingPeriod?.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}`,
                    property: properties?.find((property) => property.id === currentRentingPeriod?.propertyId)?.label,
                })}
            </Typography>
            <List sx={{ fontSize: 15, color: theme.palette.grey.A700, listStyleType: "disc", paddingLeft: 2 }}>
                {currentRentingPeriodSummaryData?.numberOfExpenses !== 0 && (
                    <ListItem sx={{ display: "list-item", "&.MuiListItem-root": { paddingLeft: 0 } }}>
                        {translate("expense_renting_periods_summary_data", {
                            expenses: currentRentingPeriodSummaryData?.numberOfExpenses,
                            expenseFiles: currentRentingPeriodSummaryData?.numberOfExpenseFiles,
                        })}
                    </ListItem>
                )}

                {currentRentingPeriodSummaryData?.numberOfIncome !== 0 && (
                    <ListItem sx={{ display: "list-item", "&.MuiListItem-root": { paddingLeft: 0 } }}>
                        {translate("income_renting_periods_summary_data", {
                            incomes: currentRentingPeriodSummaryData?.numberOfIncome,
                            incomeFiles: currentRentingPeriodSummaryData?.numberOfIncomeFiles,
                        })}
                    </ListItem>
                )}
            </List>

            <Alert severity={"info"} sx={{ marginBottom: 1 }}>
                {translate("a_copy_will_be_sent")}
            </Alert>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <RHFCheckbox name={"ownerWantsACopy"} label={translate("copy_with_info")} />
                <Box display='flex' justifyContent={isMobile ? "center" : "flex-end"}>
                    <Box
                        display='flex'
                        width={isMobile ? "100%" : "max-content"}
                        flexDirection={isMobile ? "column-reverse" : "row"}
                        alignItems='center'
                        gap={2}
                    >
                        <Button onClick={onBack} color={"primary"} fullWidth>
                            {translate("back")}
                        </Button>
                        <Button type='submit' variant='contained' color={"error"} fullWidth>
                            {translate("next")}
                        </Button>
                    </Box>
                </Box>
            </FormProvider>
        </Box>
    )
}

export default DeleteRentingPeriodReviewStep
