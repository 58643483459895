import { AppModals } from "../../../redux/slices/App"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import SendMessageDialog from "./SendMessageDialog"
import { AddMessageRequest } from "./types"
import SendMessageForm from "./SendMessageForm"
import { sendMessageMutation } from "../../../mutations/user"
import AgencyContactPage from "./AgencyContactPage"
import { UserFlags } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface Props {
    onClose: () => void
}
export default function SendMessageGuard({ onClose }: Props) {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)
    const { mutateAsync, isPending: isSendingMessage } = sendMessageMutation()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    const onSendMessage = useCallback(
        async (data: AddMessageRequest) => {
            await mutateAsync(data)
        },
        [mutateAsync],
    )

    return (
        <>
            <SendMessageDialog
                isSendMessageLoading={isSendingMessage}
                isVisible={!isMobile && modalOpen === AppModals.Contact}
                onSave={onSendMessage}
                onClose={onClose}
            />

            <DetailsSheet
                isLoading={isSendingMessage}
                title={translate("support.contact")}
                isOpen={isMobile && modalOpen === AppModals.Contact}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                {isWhitelabel ? (
                    <AgencyContactPage />
                ) : (
                    <SendMessageForm onSave={onSendMessage} isSendingMessageLoading={isSendingMessage} onClose={onClose} />
                )}
            </DetailsSheet>
        </>
    )
}
