import { CustomDialog, useLocales } from "rentzz"
import { Typography } from "@mui/material"
import { PropertyIncome } from "../../redux/slices/AddProperty"

interface DialogIncomeStatusProps {
    isVisible: boolean
    onClose: () => void
    currentIncome: (PropertyIncome & { canWrite: boolean }) | null | undefined
    isIncomeStatusLoading: boolean
    onAccept: () => void
    onDecline: () => void
    getRentPrice: () => string
}
export default function IncomeStatusDialog({
    isVisible,
    onClose,
    currentIncome,
    isIncomeStatusLoading,
    onAccept,
    onDecline,
    getRentPrice,
}: DialogIncomeStatusProps) {
    const { translate } = useLocales()

    return (
        <CustomDialog
            title={translate("dialog_income_status_title")}
            open={isVisible}
            onClose={onClose}
            isFullScreen={false}
            maxWidth={"xs"}
            closeButtonColor={"primary"}
            closeText={translate("cancel")}
            variantCloseButton={"text"}
            acceptButtonColor={"primary"}
            acceptText={translate("accept")}
            onAccept={onAccept}
            isLoading={isIncomeStatusLoading}
            isDisabled={isIncomeStatusLoading}
            declineText={translate("decline")}
            onDecline={onDecline}
        >
            <Typography marginTop={2}>
                {translate("dialog_income_status_content", {
                    tenant: currentIncome?.createdBy,
                    incomeSource: translate(`incomeSource.${currentIncome?.type}`),
                    value: getRentPrice(),
                })}
            </Typography>
            <Typography marginY={2} fontWeight={"bold"} color={"error"}>
                {translate("dialog_income_status_content_two")}
            </Typography>
        </CustomDialog>
    )
}
