import { ComplexQueryIDs, useQueryInvalidator } from "../../../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import { AddProviderRequest } from "../../../sections/propertyDetails/configurations/providers/addProvider/types"
import Api from "../../../api/Api"

export const addConfiguredMeterProvider = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddProviderRequest }) => Api.addConfiguredMeterProvider(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Meters])
            }
        },
    })
}

export const deleteConfiguredMeterProvider = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ providerId }: { providerId: string }) => Api.deleteMeterProvider(providerId),
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.Meters])
        },
    })
}
