import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, useTheme } from "@mui/material"
import { AppContext, CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useCallback, useRef, useState } from "react"
import { PlayArrow } from "@mui/icons-material"
import { useAtom } from "jotai"
import { tutorialAtom } from "../../../utils/atom"
import ReactPlayer from "react-player"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"
import { findDOMNode } from "react-dom"

export interface TenantGuide {
    url: string
    guideId: string
    device: string
    title: string
}

const UserHelpGuard = () => {
    const { translate, currentLang } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, context } = useSelector((state) => state.appState)
    const [activeGuide, setActiveGuide] = useAtom(tutorialAtom)
    const allGuides = useFeatureValue<Record<string, TenantGuide[]>>(UserFlags.TenantGuides, {})
    const [fullscreenMode, setFullscreenMode] = useState(false)
    const player = useRef(null)
    const onStart = useCallback(() => {
        if (fullscreenMode) {
            findDOMNode(player.current)
                // @ts-ignore
                ?.requestFullscreen()
                .catch((err: Error) => {
                    console.log(err)
                })
        }
    }, [])

    const onEnded = useCallback(() => {
        setFullscreenMode(document.fullscreenElement !== null)
    }, [])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
        setActiveGuide(undefined)
    }, [dispatch])

    const handleGuide = useCallback(
        (id: string) => {
            const ourGuide = allGuides[currentLang.value].find((g) => g.guideId === id)
            setActiveGuide({ guideId: id, url: ourGuide!.url, title: ourGuide!.title })
        },
        [setActiveGuide, dispatch, currentLang],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("help")}
                isOpen={isMobile && modalOpen === AppModals.Help}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                {activeGuide && (
                    <Box height={"80vh"} sx={{ textAlign: "center" }}>
                        <Typography variant='subtitle2'>{translate(activeGuide.title)}</Typography>
                        <ReactPlayer
                            playing
                            onStart={onStart}
                            onEnded={onEnded}
                            ref={player}
                            controls
                            width='100%'
                            height={"90%"}
                            url={activeGuide.url}
                        />
                    </Box>
                )}

                {!activeGuide &&
                    context === AppContext.Tenant &&
                    allGuides[currentLang.value]
                        .filter((g) => g.device === "mobile")
                        .map((g) => (
                            <ListItem key={g.guideId}>
                                <ListItemSecondaryAction>
                                    <IconButton color='primary' onClick={() => handleGuide(g.guideId)}>
                                        <PlayArrow />
                                    </IconButton>
                                </ListItemSecondaryAction>
                                <ListItemText primary={<Typography maxWidth='80%'>{translate(g.title)}</Typography>} />
                            </ListItem>
                        ))}
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("help")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.Help}
                maxWidth='md'
                closeButtonColor={"primary"}
                titleAlign={"center"}
            >
                {activeGuide && (
                    <Box height={500} sx={{ textAlign: "center" }}>
                        <Typography variant='subtitle2'>{translate(activeGuide.title)}</Typography>
                        <ReactPlayer
                            playing
                            onStart={onStart}
                            onEnded={onEnded}
                            ref={player}
                            controls
                            width='100%'
                            height={"100%"}
                            url={activeGuide.url}
                        />
                    </Box>
                )}
                {!activeGuide && (
                    <List>
                        {context === AppContext.Tenant &&
                            allGuides[currentLang.value]
                                .filter((g) => g.device === "desktop")
                                .map((g) => (
                                    <ListItem key={g.guideId}>
                                        <ListItemSecondaryAction>
                                            <IconButton color='primary' onClick={() => handleGuide(g.guideId)}>
                                                <PlayArrow />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        <ListItemText primary={translate(g.title)} />
                                    </ListItem>
                                ))}
                    </List>
                )}
            </CustomDialog>
        </>
    )
}

export default UserHelpGuard
