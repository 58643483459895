import { DetailsSheet, Iconify, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { Dialog, DialogContent, IconButton } from "@mui/material"
import CustomCarousel, { CarouselItemType, DocumentItemProps } from "../../../../sections/tenantContext/rentingPeriodNotification/CustomCarousel"
import React, { useCallback, useEffect, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useTheme } from "@mui/material/styles"
import { useLocation } from "react-router-dom"

export default function GeneralDocumentsLearnMoreGuard() {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const location = useLocation()

    const learnMoreItems: DocumentItemProps[] = useMemo(
        () => [
            {
                title: "add_document",
                subtitle: "press_add_document_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%201mob.png" : "https://cdn.rentzz.app/generaldocuments_1.png",
            },
            {
                title: "choose_a_name",
                subtitle: "choose_a_name_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%202mob.png" : "https://cdn.rentzz.app/generaldocuments_2.png",
            },
            {
                title: "write_or_paste",
                subtitle: "write_or_paste_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%203mob.png" : "https://cdn.rentzz.app/generaldocuments_%203.png",
            },
            {
                title: "create_variables",
                subtitle: "create_variables_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%204mob.png" : "https://cdn.rentzz.app/generaldocuments_%204.png",
            },
            {
                title: "preview_your_document",
                subtitle: "preview_your_document_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%205mob.png" : "https://cdn.rentzz.app/generaldocuments_%205.png",
            },
            {
                title: "save_your_document",
                subtitle: "save_your_document_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%206mob.png" : "https://cdn.rentzz.app/generaldocuments_%206.png",
            },
            {
                title: "configure_your_document",
                subtitle: "configure_your_document_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%207mob.png" : "https://cdn.rentzz.app/generaldocuments_%207.png",
            },
            {
                title: "use_your_document",
                subtitle: "use_your_document_content",
                image: isMobile ? "https://cdn.rentzz.app/generaldocuments_%208mob.png" : "https://cdn.rentzz.app/generaldocuments_%208.png",
            },
        ],
        [isMobile],
    )

    const onClose = useCallback(() => {
        localStorage.setItem("firstOnGeneralDocumentsPage", "false")
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const content = useMemo(
        () => (
            <>
                {!isMobile && (
                    <IconButton onClick={onClose} sx={{ alignSelf: "self-end", paddingTop: 1, paddingRight: 1, margin: 0 }}>
                        <Iconify icon='eva:close-fill' color='black' />
                    </IconButton>
                )}

                <DialogContent
                    sx={{
                        paddingY: 0,
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <CustomCarousel rows={1} list={learnMoreItems} item={CarouselItemType.LearnMore} />
                </DialogContent>
            </>
        ),
        [onClose, isMobile],
    )

    useEffect(() => {
        dispatch(setEditingItem({ currentIndex: 0 }))
    }, [])

    const sheetTitle = useMemo(() => learnMoreItems?.find((item, index) => index === editingItem?.currentIndex)?.title, [editingItem])

    return (
        <>
            <Dialog
                open={
                    (!isMobile && modalOpen === AppModals.ShowLearnMore) ||
                    (localStorage.getItem("firstOnGeneralDocumentsPage") === null &&
                        location.pathname === "/document-templates/general-documents" &&
                        !isMobile)
                }
                onClose={onClose}
                PaperProps={{ style: { margin: 0, color: theme.palette.grey["700"], paddingBottom: 16 } }}
                maxWidth={"md"}
            >
                {content}
            </Dialog>

            <DetailsSheet
                isOpen={
                    (isMobile && modalOpen === AppModals.ShowLearnMore) ||
                    (localStorage.getItem("firstOnGeneralDocumentsPage") === null &&
                        location.pathname === "/document-templates/general-documents" &&
                        isMobile)
                }
                onClose={onClose}
                isLoading={false}
                title={`${editingItem?.currentIndex + 1}. ${translate(sheetTitle)}`}
            >
                {content}
            </DetailsSheet>
        </>
    )
}
