import { useMutation } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { AddInsuranceRequest } from "../../sections/insurance/AddInsurance"
import * as jsonpatch from "fast-json-patch"
import { setErrorMessage } from "../../redux/slices/App"
import { AxiosError } from "axios"
import { useDispatch } from "../../redux/store"
import { useIsMobile } from "rentzz"
import { BackendError } from "../../sections/types/user"

export const addInsuranceMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ data }: { data: AddInsuranceRequest }) => Api.addUserInsurance(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Insurance,
                    ComplexQueryIDs.Insurances,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    SimpleQueryIDs.UserProperties,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}

export const deleteInsuranceMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ insuranceId }: { insuranceId: number; propertyId?: number }) => Api.deleteUserInsurance({ insuranceId }),
        onSettled: async (data, error, { propertyId }) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Insurance,
                    ComplexQueryIDs.Insurances,
                    ComplexQueryIDs.TenantPropertyFiles,
                    {
                        query: ComplexQueryIDs.OwnerPropertyFiles,
                        params: { id: propertyId },
                    },
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.Property,
                ])
            }
        },
    })
}

export const updateInsuranceMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: ({ insuranceId, operations }: { insuranceId: number; propertyId?: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInsuranceAsync({
                insuranceId,
                operations,
            }),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Insurances,
                    ComplexQueryIDs.Insurance,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.Property,
                    {
                        query: ComplexQueryIDs.OwnerPropertyFiles,
                        params: { id: data?.propertyId },
                    },
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
