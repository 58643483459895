import { Box, Card, CardContent, IconButton, Typography, useTheme } from "@mui/material"
import { AppContext, Iconify, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { useDispatch } from "../../redux/store"
import { setContext } from "../../redux/slices/App"

interface Props {
    name: string
    icon: string
}
const OwnerContext = ({ name, icon }: Props) => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()

    const handleContextSelect = () => {
        dispatch(setContext(AppContext.Owner))
    }
    return (
        <Card
            onClick={handleContextSelect}
            sx={{
                display: "flex",
                justifyContent: "center",
                mx: 1,
                height: isMobile ? 130 : "100%",
                width: isMobile ? "100%" : undefined,
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                borderStyle: "dashed",
                cursor: "pointer",
                ":hover": { backgroundColor: theme.palette.action.hover },
            }}
        >
            <CardContent sx={{ justifyContent: "center", display: "flex" }}>
                <Box
                    onClick={handleContextSelect}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 2,
                        cursor: "pointer",
                    }}
                >
                    <Typography color={theme.palette.primary.dark} variant='h5'>
                        {translate(name)}
                    </Typography>
                    <Box
                        sx={{
                            alignSelf: "center",
                        }}
                    >
                        <IconButton
                            onClick={handleContextSelect}
                            sx={{
                                borderWidth: 2,
                                borderColor: theme.palette.primary.main,
                                borderStyle: "solid",
                                ":hover": { backgroundColor: "transparent" },
                            }}
                        >
                            <Iconify icon={icon} color={theme.palette.primary.main} />
                        </IconButton>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

export default OwnerContext
