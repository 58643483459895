import { Analytic, AnalyticSkeleton, Scrollbar, useLocales } from "rentzz"
import { Card, Divider, Stack, Theme } from "@mui/material"
import React, { useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { getExpensesReportSummaryQueryFn } from "../../queries/expenses"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { ExpensesFilteredOptions } from "../../utils/atom"

export const allFilterOptions = [
    ExpensesFilteredOptions.GeneralExpense,
    ExpensesFilteredOptions.Rent,
    ExpensesFilteredOptions.SecurityDeposit,
    ExpensesFilteredOptions.Fees,
    ExpensesFilteredOptions.Overdue,
]

export const getLabelColorByFilter = (filter: ExpensesFilteredOptions, theme: Theme) => {
    switch (filter) {
        case ExpensesFilteredOptions.All:
            return theme.palette.primary.main
        case ExpensesFilteredOptions.Overdue:
        case ExpensesFilteredOptions.Fees:
            return theme.palette.error.main
        case ExpensesFilteredOptions.GeneralExpense:
            return theme.palette.info.main
        case ExpensesFilteredOptions.Rent:
        case ExpensesFilteredOptions.Damages:
            return theme.palette.info.main
        case ExpensesFilteredOptions.MoreThanOneIncomeAssigned:
            return theme.palette.info.main
        case ExpensesFilteredOptions.SecurityDeposit:
            return theme.palette.info.main
    }
}

export const getIconByFilter = (filter: ExpensesFilteredOptions) => {
    switch (filter) {
        case ExpensesFilteredOptions.All:
            return "mdi:invoice-text-multiple-outline"
        case ExpensesFilteredOptions.Overdue:
            return "mdi:invoice-text-remove-outline"
        case ExpensesFilteredOptions.GeneralExpense:
            return "mdi:invoice-list-outline"
        case ExpensesFilteredOptions.Rent:
            return "mdi:account-cash-outline"
        case ExpensesFilteredOptions.SecurityDeposit:
            return "mdi:cash-lock"
        case ExpensesFilteredOptions.MoreThanOneIncomeAssigned:
            return "mdi:invoice-text-multiple-outline"
        case ExpensesFilteredOptions.Fees:
            return "lucide:receipt"
        case ExpensesFilteredOptions.Damages:
            return "mdi:account-service"
    }
}

const ExpensesSummaryReport = () => {
    const theme = useTheme()
    const { data: expensesSummaryStatus } = getExpensesReportSummaryQueryFn()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const expenseOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)

    const allAmounts = useMemo(
        () =>
            expensesSummaryStatus
                ? expensesSummaryStatus[ExpensesFilteredOptions.GeneralExpense].sum +
                  expensesSummaryStatus[ExpensesFilteredOptions.Rent].sum +
                  expensesSummaryStatus[ExpensesFilteredOptions.Damages].sum +
                  expensesSummaryStatus[ExpensesFilteredOptions.Fees].sum +
                  expensesSummaryStatus[ExpensesFilteredOptions.SecurityDeposit].sum
                : 0,
        [expensesSummaryStatus],
    )

    if (userData == null) return null

    return (
        <Card
            sx={{
                mb: { xs: 3, md: 3 },
            }}
        >
            <Scrollbar>
                <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: "dashed" }} />} sx={{ py: 2 }}>
                    {expensesSummaryStatus == null && (
                        <>
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                            <AnalyticSkeleton />
                        </>
                    )}
                    {expensesSummaryStatus != null &&
                        allFilterOptions.map((f) => (
                            <Analytic
                                key={f}
                                icon={getIconByFilter(f)}
                                title={translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-${f}`)}
                                total={expensesSummaryStatus[f].count}
                                percent={(expensesSummaryStatus[f].sum / allAmounts) * 100}
                                price={expensesSummaryStatus[f].sum}
                                currency={userData?.currency.code}
                                color={getLabelColorByFilter(f, theme)}
                                itemName={translate("expenses.expenses").toLowerCase()}
                            />
                        ))}
                </Stack>
            </Scrollbar>
        </Card>
    )
}

export default ExpensesSummaryReport
