import React from "react"
import { Viewer } from "@react-pdf-viewer/core"
import { useDispatch, useSelector } from "../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales, useSettingsContext } from "rentzz"
import { AppModals, setModalOpen } from "../redux/slices/App"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { Box } from "@mui/material"

export default function PDFViewer() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { themeMode } = useSettingsContext()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const defaultLayoutPluginInstance = defaultLayoutPlugin()

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.PdfViewer}
                isFullScreen={isMobile}
                maxWidth={"md"}
                title={""}
                onClose={() => {
                    dispatch(setModalOpen(editingItem?.previousModal))
                }}
            >
                <Box height={750}>
                    <Viewer theme={themeMode} fileUrl={editingItem?.url} plugins={[defaultLayoutPluginInstance]} />
                </Box>
            </CustomDialog>
            <DetailsSheet
                isLoading={false}
                title={translate("preview")}
                isOpen={isMobile && modalOpen === AppModals.PdfViewer}
                onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
            >
                <Box height={"75vh"}>
                    <Viewer theme={themeMode} fileUrl={editingItem?.url} plugins={[defaultLayoutPluginInstance]} />
                </Box>
            </DetailsSheet>
        </>
    )
}
