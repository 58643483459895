import React, { useCallback, useState } from "react"
// @mui
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Dialog from "@mui/material/Dialog"
import ListItem from "@mui/material/ListItem"
import TextField from "@mui/material/TextField"
import DialogTitle from "@mui/material/DialogTitle"
import ListItemText from "@mui/material/ListItemText"
import DialogContent from "@mui/material/DialogContent"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import { Iconify, Scrollbar, SearchNotFound, TaskAssignedUser, useLocales } from "rentzz"
import { usePossibleAssignedUsersQuery } from "../../../queries/kanban"
import Api from "../../../api/Api"
import { CircularProgress } from "@mui/material"
import { assignOrRemoveUserToTaskItem } from "../../../mutations/kanban"
import { LoadingButton } from "@mui/lab"

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64

type Props = {
    taskId: string
    sectionId: string
    open: boolean
    onClose: VoidFunction
    assignee?: TaskAssignedUser[]
}

export default function KanbanContactsDialog({ assignee = [], open, onClose, taskId, sectionId }: Props) {
    const [searchContact, setSearchContact] = useState("")
    const { translate } = useLocales()
    const { data: possibleAssignedUsers, isLoading: isLoadingData } = usePossibleAssignedUsersQuery(taskId, sectionId)
    const { mutate: assignOrRemove, isPending: isAssigningOrRemoving } = assignOrRemoveUserToTaskItem()
    const handleUserAvatar = useCallback((userId: string) => {
        return `${Api.API}/user/${userId}/profile-picture`
    }, [])
    const handleSearchContacts = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchContact(event.target.value)
    }, [])

    const handleAssignOrRemove = useCallback(
        (userId: string, isAssigned: boolean) => {
            assignOrRemove({
                userId,
                type: isAssigned ? "remove" : "add",
                taskId,
                sectionId,
            })
        },
        [taskId, sectionId],
    )

    const dataFiltered = applyFilter({
        inputData: possibleAssignedUsers ?? [],
        query: searchContact,
    })

    const notFound = !dataFiltered.length && !!searchContact

    return (
        <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
            <DialogTitle sx={{ pb: 0 }}>{translate("possible_assignees")}</DialogTitle>

            <Box sx={{ px: 3, py: 2.5 }}>
                <TextField
                    fullWidth
                    value={searchContact}
                    onChange={handleSearchContacts}
                    placeholder={translate("search")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Iconify icon='eva:search-fill' sx={{ color: "text.disabled" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <DialogContent sx={{ p: 0 }}>
                {notFound ? (
                    <SearchNotFound query={searchContact} sx={{ mt: 3, mb: 10 }} />
                ) : isLoadingData ? (
                    <Box width='100%' display='flex' justifyContent='center' py={2}>
                        <CircularProgress size={"2rem"} />
                    </Box>
                ) : (
                    <Scrollbar
                        sx={{
                            px: 2.5,
                            height: ITEM_HEIGHT * 6,
                        }}
                    >
                        {dataFiltered.map((contact) => {
                            const checked = assignee.map((person) => person.userId).includes(contact.userId)

                            return (
                                <ListItem
                                    key={contact.userId}
                                    disableGutters
                                    secondaryAction={
                                        <LoadingButton
                                            loading={isAssigningOrRemoving}
                                            size='small'
                                            color={checked ? "error" : "primary"}
                                            startIcon={<Iconify width={16} icon={checked ? "mdi:delete" : "mdi:add"} sx={{ mr: -0.5 }} />}
                                            onClick={() => handleAssignOrRemove(contact.userId, checked)}
                                        >
                                            {checked ? translate("delete_assignee") : translate("add")}
                                        </LoadingButton>
                                    }
                                    sx={{ height: ITEM_HEIGHT }}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={handleUserAvatar(contact.userId)} />
                                    </ListItemAvatar>

                                    <ListItemText
                                        primaryTypographyProps={{ typography: "subtitle2", sx: { mb: 0.25 } }}
                                        secondaryTypographyProps={{ typography: "caption" }}
                                        primary={contact.fullName}
                                        // secondary={contact.email}
                                    />
                                </ListItem>
                            )
                        })}
                    </Scrollbar>
                )}
            </DialogContent>
        </Dialog>
    )
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, query }: { inputData: TaskAssignedUser[]; query: string }) {
    if (query) {
        inputData = inputData.filter((contact) => contact.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }

    return inputData
}
