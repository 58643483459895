import { useIsMobile, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import React from "react"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"

interface Props {
    onClose: () => void
    onAccept: () => void
    deleteText: any
    isLoading: boolean
    submitText?: string
}
export default function DeleteContent({ onClose, deleteText, isLoading, onAccept, submitText }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
            <Typography paddingY={2} sx={{ color: theme.palette.grey.A700 }} textAlign={isMobile ? "center" : undefined}>
                {deleteText}
            </Typography>
            <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' alignItems='center' gap={2}>
                <LoadingButton fullWidth={isMobile} color='primary' onClick={onClose} disabled={isLoading}>
                    {translate("cancel")}
                </LoadingButton>
                <LoadingButton fullWidth={isMobile} variant='contained' color={"error"} onClick={onAccept} loading={isLoading} disabled={isLoading}>
                    {translate(submitText ?? "delete")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
