import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import MobileSectionsList from "./MobileSectionsList"
import DesktopSectionsList from "./DesktopSectionsList"

export default function SectionsPage() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    return (
        <Page title={translate("nomenclature")} propertyName={translate("sections")} px={2} paddingBottom={2}>
            {isMobile ? <MobileSectionsList /> : <DesktopSectionsList />}
        </Page>
    )
}
