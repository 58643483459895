import { Box, Button, MenuItem, Typography } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, RHFAutocomplete, RHFPhoneNumber, RHFRadioGroup, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import React, { useEffect, useState } from "react"
import { useCitiesQueryFn, useCountiesQueryFn } from "../../../../queries"
import { OwnerContactSchema } from "../../../../validations/ownerContactSchema"
import { availableStories } from "../../../../config"
import { ContactInfoData } from "../../../contracts/livePreview/tenant/TenantContactInfo"
import { ContactInfoPersonType, useUserBankAccountsQuery, useUserDataQueryFn } from "../../../../queries/userData"
import { useSelector } from "../../../../redux/store"

interface OwnerContactProps {
    onNext: (data: Omit<ContactInfoData, "type">) => void
    onChange: (data: Omit<ContactInfoData, "type">) => void
    onBack: () => void
}

const OwnerContact = ({ onNext, onBack, onChange }: OwnerContactProps) => {
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const [contactInfoPersonType, setContactInfoPersonType] = useState<ContactInfoPersonType>(ContactInfoPersonType.Individual)
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)
    const isMobile = useIsMobile()
    const { data: user } = useUserDataQueryFn()
    const { data: bankAccounts } = useUserBankAccountsQuery(editingItem?.propertyId)

    const methods = useForm<Omit<ContactInfoData, "type">>({
        resolver: yupResolver<Omit<ContactInfoData, "type">>(OwnerContactSchema()),
        mode: "onChange",
        defaultValues: {
            fullName: `${user?.firstName} ${user?.lastName}`,
            // phone: user?.phoneNumber ?? "",
            email: user?.email ?? "",
            contactInfoPersonType: ContactInfoPersonType.Individual,
            bankAccount: "-1",
            bankName: "",
        },
    })

    const {
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = methods
    console.log(errors)

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            onChange(v as Omit<ContactInfoData, "type">)
            if (name === "county") {
                setCounty(allCounties?.find((c) => c.name === v.county)?.name ?? "")
                setValue("city", "")
                setValue("street", "")
                setValue("streetNumber", "")
                setValue("buildingNumber", "")
                setValue("stair", "")
                setValue("floor", "")
                setValue("apartment", "")
            }
            if (name === "contactInfoPersonType" && v.contactInfoPersonType) {
                setContactInfoPersonType(Number.parseInt(v.contactInfoPersonType.toString()))
                setValue("county", "")
                setValue("city", "")
                setValue("street", "")
                setValue("streetNumber", "")
                setValue("buildingNumber", "")
                setValue("stair", "")
                setValue("floor", "")
                setValue("apartment", "")
                setValue("fullName", "")
                setValue("identitySeries", undefined)
                setValue("identityNumber", undefined)
                setValue("phone", "")
                setValue("companyName", undefined)
                setValue("companyRegistrationNumber", undefined)
                setValue("companyPosition", undefined)
                setValue("bankName", undefined)
                setValue("bankAccount", undefined)
            }
            if (name === "bankAccount") {
                const bankAccount = v.bankAccount || null
                if (!bankAccount || bankAccount === "-1") {
                    setValue("bankName", "", { shouldValidate: false })
                    setValue("bankAccount", "-1", { shouldValidate: false })
                } else {
                    const currentBank = bankAccounts?.items?.find((bankAccount) => bankAccount.iban === v.bankAccount)
                    if (currentBank) {
                        setValue("bankName", translate(`bank-name-${currentBank.bank}`, { shouldValidate: false }))
                        setValue("bankAccount", currentBank.iban, { shouldValidate: false })
                    } else {
                        setValue("bankName", "", { shouldValidate: false })
                        setValue("bankAccount", "", { shouldValidate: false })
                    }
                }
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, allCounties, onChange, setValue, bankAccounts])

    return (
        <Box display='flex' width='100%' flexDirection='column' height={"100%"} flex={1} marginTop={4}>
            {!isMobile && (
                <Typography variant={isMobile ? "subtitle1" : "h5"} alignSelf='center' textAlign={"center"} marginRight={2}>
                    {translate("owner_message")}
                </Typography>
            )}
            <FormProvider methods={methods} onSubmit={handleSubmit(onNext)}>
                <Box display={"flex"} justifyContent={"center"} marginTop={isMobile ? 0 : 1} marginBottom={isMobile ? 1 : 0}>
                    <RHFRadioGroup
                        name={"contactInfoPersonType"}
                        options={[
                            { label: translate("individual"), value: ContactInfoPersonType.Individual, moreInfo: "" },
                            { label: translate("company"), value: ContactInfoPersonType.Company, moreInfo: "" },
                        ]}
                    />
                </Box>

                <Box
                    display='flex'
                    flexDirection='column'
                    rowGap={2}
                    width='100%'
                    height='100%'
                    justifyContent='space-between'
                    marginTop={isMobile ? 0 : 4}
                >
                    <Box display='flex' flexWrap={"wrap"} rowGap={2} justifyContent={"center"}>
                        {contactInfoPersonType === ContactInfoPersonType.Individual && (
                            <>
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name='fullName'
                                    label={translate("fullName")}
                                    required
                                />
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name={"identitySeries"}
                                    required
                                    label={translate("identity_series")}
                                />
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name='identityNumber'
                                    required
                                    label={translate("identity_number")}
                                />
                            </>
                        )}

                        {contactInfoPersonType === ContactInfoPersonType.Company && (
                            <>
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name={"companyName"}
                                    required
                                    label={translate("company_name")}
                                />
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name='companyRegistrationNumber'
                                    required
                                    label={translate("company_registration_number")}
                                />
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name='fullName'
                                    label={translate("full_name_company")}
                                    required
                                />
                                <RHFTextField
                                    sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                                    name='companyPosition'
                                    label={translate("contracts.asUppercase")}
                                    required
                                />
                            </>
                        )}

                        <RHFAutocomplete
                            name={"county"}
                            required
                            textFieldProps={{
                                size: "small",
                            }}
                            options={allCounties?.map((c) => c.name) ?? []}
                            label={translate("county")}
                            loading={allCountiesFetching}
                            autocompleteProps={{
                                size: "small",
                                sx: { minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 },
                                noOptionsText: translate("no_value_found"),
                            }}
                            freeSolo={false}
                        />
                        <RHFAutocomplete
                            textFieldProps={{
                                disabled: allCities?.length === 0,
                                size: "small",
                            }}
                            required
                            name={"city"}
                            options={allCities?.map((c) => c.name) ?? []}
                            loading={allCitiesFetching}
                            label={translate("city")}
                            autocompleteProps={{
                                size: "small",
                                disabled: allCities == null || allCities.length === 0,
                                sx: { minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 },
                                noOptionsText: translate("no_value_found"),
                            }}
                            freeSolo={false}
                        />
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='street'
                            label={translate("street")}
                        />
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='streetNumber'
                            label={translate("number")}
                        />
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='buildingNumber'
                            label={translate("buildingNumber")}
                        />
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='stair'
                            label={translate("stair")}
                        />
                        <RHFSelect
                            name='floor'
                            label={translate("floor")}
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2, textAlign: "start" }}
                            size={"small"}
                        >
                            {availableStories.map((option) => (
                                <MenuItem key={option.value} value={option.value} style={{ textTransform: "none" }}>
                                    {translate(option.value === 0 ? "ground" : "floor_with_number", { floor: option.value })}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='apartment'
                            label={translate("apartment")}
                        />
                        <Box sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", width: "100%", paddingRight: 2 }}>
                            <RHFPhoneNumber name='phone' label={translate("phone")} />
                        </Box>
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "30%", paddingRight: 2 }}
                            name='email'
                            label={translate("email")}
                            disabled
                        />

                        <RHFSelect
                            name={"bankAccount"}
                            size='small'
                            variant={"outlined"}
                            label={translate("iban")}
                            sx={{
                                minWidth: 200,
                                maxWidth: isMobile ? "100%" : "30%",
                                paddingRight: 2,
                            }}
                        >
                            <MenuItem value={"-1"}>{translate("no_bank_account")}</MenuItem>
                            {bankAccounts?.items.map((bankAccount) => (
                                <MenuItem key={bankAccount.id.toString()} value={bankAccount.iban.toString()}>
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Typography variant={"caption"}>{bankAccount.iban}</Typography>
                                        <Typography variant={"caption"} fontWeight={"bold"}>
                                            {translate(`bank-name-${bankAccount.bank}`)}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }} flexDirection={isMobile ? "column-reverse" : "row"}>
                        <Button color={"primary"} onClick={onBack}>
                            {translate("back")}
                        </Button>
                        <Button color={"primary"} type='submit' variant='contained'>
                            {translate("next")}
                        </Button>
                    </Box>
                </Box>
            </FormProvider>
        </Box>
    )
}

export default OwnerContact
