import { Box, Card, CircularProgress, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useUserCardPaymentDetailsQuery } from "../../../queries/userData"
import CustomInlineEditComponent from "../../../components/CustomInlineEditComponent"
import { addCardPaymentDataMutation } from "../../../mutations/user"

export default function CardPaymentDetails() {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: paymentDetails } = useUserCardPaymentDetailsQuery()
    const { mutateAsync: addPaymentDetails } = addCardPaymentDataMutation()

    const onCountryChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.country) return
            await addPaymentDetails({
                data: {
                    country: newValue,
                    county: paymentDetails.county,
                    city: paymentDetails.city,
                    address: paymentDetails.address,
                    zipCode: paymentDetails.zipCode,
                    phoneNumber: paymentDetails.phoneNumber,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    const onCountyChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.county) return
            await addPaymentDetails({
                data: {
                    country: paymentDetails.country,
                    county: newValue,
                    city: paymentDetails.city,
                    address: paymentDetails.address,
                    zipCode: paymentDetails.zipCode,
                    phoneNumber: paymentDetails.phoneNumber,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    const onCityChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.city) return
            await addPaymentDetails({
                data: {
                    country: paymentDetails.country,
                    county: paymentDetails.county,
                    city: newValue,
                    address: paymentDetails.address,
                    zipCode: paymentDetails.zipCode,
                    phoneNumber: paymentDetails.phoneNumber,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    const onAddressChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.address) return
            await addPaymentDetails({
                data: {
                    country: paymentDetails.country,
                    county: paymentDetails.county,
                    city: paymentDetails.city,
                    address: newValue,
                    zipCode: paymentDetails.zipCode,
                    phoneNumber: paymentDetails.phoneNumber,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    const onZipCodeChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.zipCode) return
            await addPaymentDetails({
                data: {
                    country: paymentDetails.country,
                    county: paymentDetails.county,
                    city: paymentDetails.city,
                    address: paymentDetails.address,
                    zipCode: newValue,
                    phoneNumber: paymentDetails.phoneNumber,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    const onPhoneNumberChange = useCallback(
        async (newValue: string) => {
            if (paymentDetails == null || newValue == null || newValue === "" || newValue === paymentDetails.phoneNumber) return
            await addPaymentDetails({
                data: {
                    country: paymentDetails.country,
                    county: paymentDetails.county,
                    city: paymentDetails.city,
                    address: paymentDetails.address,
                    zipCode: paymentDetails.zipCode,
                    phoneNumber: newValue,
                },
            })
        },
        [paymentDetails, addPaymentDetails],
    )

    return (
        <Card sx={{ marginBottom: 2, display: "flex", p: 2, gap: 2, flexDirection: "column", width: "100%" }}>
            <Typography variant={"body1"} fontWeight={"bold"} color={"primary"} justifyContent={"center"}>
                {translate("info_card_payment")}
            </Typography>

            {paymentDetails == null ? (
                <Box display={"flex"} justifyContent={"center"} height={100}>
                    <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                </Box>
            ) : (
                <Box
                    sx={{
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        paddingRight: 1,
                        color: theme.palette.grey.A700,
                    }}
                >
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2} width={"100%"}>
                            <CustomInlineEditComponent
                                defaultValue={paymentDetails.country}
                                handleBlur={onCountryChange}
                                name={translate("country")}
                            />
                            <CustomInlineEditComponent defaultValue={paymentDetails.county} handleBlur={onCountyChange} name={translate("county")} />
                        </Box>
                        <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                            <CustomInlineEditComponent defaultValue={paymentDetails.city} handleBlur={onCityChange} name={translate("city")} />
                            <CustomInlineEditComponent
                                defaultValue={paymentDetails.address}
                                handleBlur={onAddressChange}
                                name={translate("address")}
                            />
                        </Box>
                        <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                            <CustomInlineEditComponent
                                defaultValue={paymentDetails.zipCode}
                                handleBlur={onZipCodeChange}
                                name={translate("postalCode")}
                            />
                            <CustomInlineEditComponent
                                defaultValue={paymentDetails.phoneNumber}
                                handleBlur={onPhoneNumberChange}
                                name={translate("phone")}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Card>
    )
}
