import { Box, Button } from "@mui/material"
import { useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { useMetersQueryFn } from "../../../../queries/meters"
import MetersListAddTenant from "./MetersListAddTenant"

interface MetersProps {
    onNext: (data: { id: number; value: number }[]) => void
    onBack: () => void
    isLoading: boolean
    propertyId: number
}

export default function Meters({ onNext, onBack, isLoading, propertyId }: MetersProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: meters } = useMetersQueryFn(propertyId)
    const [modifiedMeters, setModifiedMeters] = useState<{ id: number; value: number }[]>([])

    const handleBlur = useCallback(
        (meterId: number, newValue: number) => {
            setModifiedMeters((prevState) => {
                const existingMeter = prevState.find((meter) => meter.id === meterId)
                if (existingMeter) {
                    return prevState.map((meter) => (meter.id === meterId ? { ...meter, value: newValue } : meter))
                } else {
                    return [...prevState, { id: meterId, value: newValue }]
                }
            })
        },
        [setModifiedMeters],
    )

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <MetersListAddTenant meters={meters} handleBlur={handleBlur} isDisabled={false} />

            <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"}>
                <Button color={"primary"} onClick={onBack} disabled={isLoading}>
                    {translate("back")}
                </Button>
                <LoadingButton color={"primary"} variant='contained' onClick={() => onNext(modifiedMeters)} loading={isLoading} disabled={isLoading}>
                    {translate("next")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
