import { FormProvider, RHFColorPicker, useIsMobile, useLocales } from "rentzz"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { CreateNewLabelSchema } from "../../../validations/createNewLabel"
import { LabelDescription } from "../../../queries/userData"
import { addNewLabelMutation, updateLabelMutation } from "../../../mutations/labels"
import * as jsonpatch from "fast-json-patch"

interface CreateNewLabelProps {
    onPopUpClose: () => void
    defaultValues?: LabelDescription
}

export interface CreateNewLabelRequest {
    value: string
    color: string
}

export default function AddOrEditNewLabel({ onPopUpClose, defaultValues }: CreateNewLabelProps) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: addNewLabel, isPending: isAddNewLabelLoading } = addNewLabelMutation()
    const { mutateAsync: updateLabel, isPending: isLabelUpdating } = updateLabelMutation()

    const methods = useForm<CreateNewLabelRequest>({
        resolver: yupResolver(CreateNewLabelSchema),
        mode: "onChange",
    })
    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                color: `#${defaultValues.color}`,
            })
        }

        if (defaultValues == null) {
            reset({
                color: `#${Math.random().toString(16).slice(-6)}`,
            })
        }
    }, [])

    const onSave = useCallback(
        async (data: CreateNewLabelRequest) => {
            if (defaultValues) {
                const operations = jsonpatch.compare(
                    { value: defaultValues?.value, color: defaultValues?.color.replace("#", "") },
                    { value: data.value, color: data.color.replace("#", "") },
                )
                await updateLabel({
                    labelId: defaultValues.id,
                    operations,
                })
            } else {
                await addNewLabel({ value: data.value, color: data.color.replace("#", "") })
            }
            onPopUpClose()
        },
        [addNewLabel, onPopUpClose, defaultValues],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                <RHFColorPicker name='value' colorName='color' label={translate("labelName")} size='small' required />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onPopUpClose} disabled={isAddNewLabelLoading || isLabelUpdating}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isAddNewLabelLoading || isLabelUpdating}
                        loading={isAddNewLabelLoading || isLabelUpdating}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
