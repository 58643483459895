import { FormProvider, RHFAutocomplete, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddGroupSchema } from "../../validations/group"
import { UserGroup } from "../../queries/groups"
import { useUserPropertiesQuery } from "../../queries/userData"

export interface AddGroupRequest {
    name: string
    description: string
    properties: number[]
    createdAt?: string
}
interface Props {
    onClose: () => void
    onSave: (data: AddGroupRequest) => void
    defaultValues?: UserGroup
    isLoading: boolean
}
export default function AddGroupForm({ defaultValues, onClose, onSave, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()

    const methods = useForm<AddGroupRequest>({
        resolver: yupResolver<AddGroupRequest>(AddGroupSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                properties: defaultValues.properties.map((p) => p.propertyId),
                createdAt: defaultValues.createdAt.toISO() ?? undefined,
            })
        }
        if (defaultValues == null) {
            reset()
        }
    }, [defaultValues, reset])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    {defaultValues == null && (
                        <>
                            <RHFTextField name={"name"} label={translate("group_name")} size={"small"} required />
                            <RHFTextField name={"description"} label={translate("description")} multiline required minRows={3} maxRows={3} />
                        </>
                    )}

                    <RHFAutocomplete
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                            },
                            noOptionsText: translate("no_value_found"),
                        }}
                        matchId
                        multiple
                        name={"properties"}
                        options={userProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                        label={translate("properties")}
                        freeSolo={false}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
