import React, { useCallback } from "react"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { deleteTenantMutation } from "../../../mutations/tenants"
import { useTheme } from "@mui/material/styles"
import DeleteRentingPeriodContent from "./DeleteRentingPeriodContent"

export default function DeleteRentingPeriod() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteTenant, isPending: isDeletingTenant } = deleteTenantMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onAccept = useCallback(
        async (sendDataToOwner: boolean) => {
            await deleteTenant({ tenantId: editingItem?.rentingPeriodId, propertyId: editingItem?.propertyId, sendDataToOwner })
            onClose()
        },
        [deleteTenant, editingItem, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={isDeletingTenant}
                title={translate("delete_renting_period")}
                isOpen={isMobile && modalOpen === AppModals.DeleteTenant}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteRentingPeriodContent onAccept={onAccept} onClose={onClose} isLoading={isDeletingTenant} />
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("delete_renting_period")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.DeleteTenant}
                maxWidth='sm'
                closeButtonColor={"primary"}
                titleAlign={"center"}
            >
                <DeleteRentingPeriodContent onAccept={onAccept} onClose={onClose} isLoading={isDeletingTenant} />
            </CustomDialog>
        </>
    )
}
