import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, CircularProgress, InputAdornment } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { PropertyPercentageSchema } from "../../../validations/group"
import PercentIcon from "@mui/icons-material/Percent"
import { useSelector } from "../../../redux/store"
import { PropertySummary } from "../../../queries/userData"
import { GroupProperty } from "./DesktopGroupPropertiesPage"
import { updateGroupPropertyPercentageMutation } from "../../../mutations/groups"

export interface PropertyPercentageRequest {
    percentage: number
}
interface Props {
    onClose?: () => void
    currentProperty: PropertySummary | GroupProperty | undefined
}
export default function EditPropertyPercentageForm({ onClose, currentProperty }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentGroupId } = useSelector((state) => state.appState)
    const { mutateAsync: updatePercentage, isPending: isUpdatePercentageLoading } = updateGroupPropertyPercentageMutation()

    const methods = useForm<PropertyPercentageRequest>({
        resolver: yupResolver(PropertyPercentageSchema),
        mode: "onBlur",
    })

    const { handleSubmit, getValues } = methods

    const onSave = useCallback(
        async (data: PropertyPercentageRequest) => {
            if (currentProperty == null || currentGroupId == null) return
            await updatePercentage({
                percentage: data.percentage,
                groupId: currentGroupId,
                propertyId: currentProperty.id,
            })
            if (onClose) {
                onClose()
            }
        },
        [currentGroupId, currentProperty, updatePercentage, onClose],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            {isMobile ? (
                <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                    <RHFTextField
                        name={"percentage"}
                        type={"number"}
                        label={translate("new_percentage")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <PercentIcon fontSize={"small"} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isUpdatePercentageLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton
                            type={"submit"}
                            variant='contained'
                            color={"primary"}
                            fullWidth={isMobile}
                            loading={isUpdatePercentageLoading}
                            disabled={isUpdatePercentageLoading}
                        >
                            {translate("update")}
                        </LoadingButton>
                    </Box>
                </Box>
            ) : (
                <Box display={"flex"} paddingY={1}>
                    <RHFTextField
                        name={"percentage"}
                        type={"number"}
                        label={translate("percentage")}
                        defaultValue={(currentProperty as GroupProperty).expenseShare * 100}
                        onBlur={async (event) => {
                            if (Number(event.target.value) === getValues("percentage") || currentProperty == null || currentGroupId == null) return
                            await updatePercentage({
                                percentage: Number(event.target.value),
                                groupId: currentGroupId,
                                propertyId: currentProperty.id,
                            })
                        }}
                        disabled={isUpdatePercentageLoading}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    {isUpdatePercentageLoading ? <CircularProgress size={"1rem"} /> : <PercentIcon fontSize={"small"} />}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
        </FormProvider>
    )
}
