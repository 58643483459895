import { FormProvider, RHFAutocomplete, useIsMobile, useLocales } from "rentzz"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddPropertyFacilitiesSchema } from "../../validations/marketing"
import { useCallback, useMemo } from "react"
import { Features, Utilities } from "../../redux/slices/AddProperty"
import { updatePropertyDetailsMutation } from "../../mutations"
import { usePropertyDetailsQueryFn } from "../../queries"

export interface AddPropertyFacilitiesRequest {
    utilities?: string[]
    features?: string[]
}
interface Props {
    onClose: () => void
}
export default function AddOrEditUtilitiesAndFeaturesForm({ onClose }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()
    const { mutateAsync: updateProperty, isPending: isUpdatePropertyPending } = updatePropertyDetailsMutation()

    const methods = useForm<AddPropertyFacilitiesRequest>({
        resolver: yupResolver<AddPropertyFacilitiesRequest>(AddPropertyFacilitiesSchema),
        mode: "onChange",
        defaultValues: {
            features: propertyDetails?.marketingSettings?.features.map((p) => p.toString()),
            utilities: propertyDetails?.marketingSettings?.utilities.map((u) => u.toString()),
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddPropertyFacilitiesRequest) => {
            await updateProperty({
                operations: [
                    { op: "replace", path: "/marketingSettings/utilities", value: data.utilities },
                    { op: "replace", path: "/marketingSettings/features", value: data.features },
                ],
            })
            onClose()
        },
        [updateProperty],
    )

    const utilities = useMemo(() => Object.keys(Utilities).filter((key) => !Number.isNaN(Number(key))), [])
    const features = useMemo(() => Object.keys(Features).filter((key) => !Number.isNaN(Number(key))), [])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFAutocomplete
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                            },
                            noOptionsText: translate("no_value_found"),
                        }}
                        matchId
                        multiple
                        name={"utilities"}
                        options={utilities?.map((utility) => ({ id: utility, label: translate(`utility-${utility}`) })) ?? []}
                        label={translate("utilities")}
                        freeSolo={false}
                    />
                    <RHFAutocomplete
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                            },
                            noOptionsText: translate("no_value_found"),
                        }}
                        matchId
                        multiple
                        name={"features"}
                        options={features?.map((f) => ({ id: f, label: translate(`feature-${f}`) })) ?? []}
                        label={translate("features")}
                        freeSolo={false}
                    />

                    <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"} marginTop={2}>
                        <Button color={"primary"} onClick={onClose} disabled={isUpdatePropertyPending}>
                            {translate("cancel")}
                        </Button>

                        <LoadingButton
                            color={"primary"}
                            loading={isUpdatePropertyPending}
                            type='submit'
                            variant='contained'
                            disabled={isUpdatePropertyPending}
                        >
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
