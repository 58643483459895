import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useCallback } from "react"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import { updateMeterValuesMutation } from "../../../../mutations/property/meters/meter-values"
import StopSendingAutomaticReadingForm from "../../../../sections/propertyDetails/Meters/StopSendingAutomaticReadingForm"

export default function StopSendingAutomaticReading() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateReading, isPending: isUpdateReading } = updateMeterValuesMutation()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(AppModals.PendingReadings))
    }, [dispatch])

    const onSave = useCallback(async () => {
        if (editingItem?.readingId == null) return
        await updateReading({
            meterId: editingItem?.meterId,
            readingId: editingItem?.readingId,
            operations: [{ op: "replace", path: "/sendAutomaticallyAt", value: null }],
        })
        dispatch(setModalOpen(AppModals.PendingReadings))
    }, [updateReading, editingItem, closeModal])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("stopAutomaticReading_title")}
                isOpen={isMobile && modalOpen === AppModals.StopAutomaticReadingSending}
                onClose={closeModal}
                headerColor={theme.palette.warning.main}
            >
                <StopSendingAutomaticReadingForm onSave={onSave} onClose={closeModal} isLoading={isUpdateReading} />
            </DetailsSheet>
            <CustomDialog
                title={translate("stopAutomaticReading_title")}
                isFullScreen={false}
                maxWidth={"sm"}
                open={!isMobile && modalOpen === AppModals.StopAutomaticReadingSending}
                onClose={closeModal}
            >
                <StopSendingAutomaticReadingForm onSave={onSave} onClose={closeModal} isLoading={isUpdateReading} />
            </CustomDialog>
        </>
    )
}
