import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deleteUserSectionMutation } from "../../../../../mutations/kanban"
import { useAllSectionsQueryFn } from "../../../../../queries/kanban"

export default function DeleteSectionGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteSection, isPending: isDeleteSectionPending } = deleteUserSectionMutation()
    const { data: allSections } = useAllSectionsQueryFn()

    const currentSection = useMemo(() => allSections?.find((section) => section.taskSectionId === editingItem?.id), [allSections, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.SectionDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentSection == null) return
        await deleteSection(currentSection.taskSectionId)

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentSection, deleteSection, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete_section")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteSection}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_section_content", { name: currentSection?.name })}
                    isLoading={isDeleteSectionPending}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteSection}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_section")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_section_content", { name: currentSection?.name })}
                    isLoading={isDeleteSectionPending}
                />
            </DetailsSheet>
        </>
    )
}
