import { Alert, Box, Divider, TextField, Typography } from "@mui/material"
import { AppContext, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { deleteUserAccountMutation, useLogout } from "../../mutations/user"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "../../redux/store"
import { setContext, setModalOpen } from "../../redux/slices/App"
import { DateTime } from "luxon"

export default function DeleteAccount() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { mutateAsync: deleteUserAccount, isPending: isDeleteUserLoading } = deleteUserAccountMutation()
    const { data: properties } = useUserPropertiesQuery()
    const { data: userData } = useUserDataQueryFn()
    const { mutateAsync: signOut, isPending: isSignOutLoading } = useLogout()
    const [deleteValue, setDeleteValue] = useState<string | undefined>("")
    const { context } = useSelector((state) => state.appState)
    const { data: rentingPeriods } = useTenantRentingPeriodsQuery()

    const hasTenantActiveContracts = useMemo(() => {
        if (context === AppContext.Owner) return false
        return rentingPeriods?.some((c) => c.to.startOf("day") > DateTime.now().startOf("day"))
    }, [rentingPeriods, context])

    const deleteAccount = useCallback(async () => {
        await deleteUserAccount()
        await signOut()
        dispatch(setModalOpen(undefined))
        dispatch(setContext(undefined))
        navigate("/login")
    }, [deleteUserAccount, navigate, signOut, dispatch])

    const isDeleteUserDisabled = useMemo(() => {
        if (deleteValue !== `${userData?.firstName} ${userData?.lastName}`) return true
        if (context === AppContext.Owner) {
            if (properties && properties.length > 0) return true
            return properties == null
        } else {
            if (hasTenantActiveContracts) return true
        }
    }, [deleteValue, userData, properties, hasTenantActiveContracts])

    const canDelete = useMemo(() => {
        if (context === AppContext.Owner) return properties?.length === 0 || properties == null

        return !hasTenantActiveContracts
    }, [properties, hasTenantActiveContracts])

    return (
        <Box paddingTop={2} paddingX={1} minHeight={179} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
            <Typography variant={"subtitle1"} fontWeight={"bold"} sx={{ marginBottom: isMobile ? 1 : undefined }}>
                {translate("delete_account")}
            </Typography>
            <Divider sx={{ borderStyle: "dashed" }} />

            <Alert severity={"error"} sx={{ marginY: isMobile ? 2 : undefined }}>
                {translate(canDelete ? "delete_account_content" : "delete_account_info", {
                    contextInfo1: context === AppContext.Owner ? translate("owner_context_info1") : translate("tenant_context_info1"),
                    contextInfo2: context === AppContext.Owner ? translate("owner_context_info2") : translate("tenant_context_info2"),
                })}
            </Alert>

            {canDelete && (
                <Box width={"100%"} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} paddingY={2} gap={1}>
                    <Typography variant={"caption"} textAlign={"center"} fontSize={14}>
                        {translate("confirm_delete", { name: `${userData?.firstName} ${userData?.lastName}` })}
                    </Typography>
                    <TextField
                        required
                        value={deleteValue}
                        size={"small"}
                        sx={{ width: 330 }}
                        onChange={(event) => setDeleteValue(event.target.value)}
                    />
                </Box>
            )}
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            >
                <LoadingButton
                    loading={isDeleteUserLoading || isSignOutLoading}
                    variant={"contained"}
                    color={"error"}
                    onClick={deleteAccount}
                    fullWidth={isMobile}
                    disabled={isDeleteUserDisabled || isDeleteUserLoading || isSignOutLoading}
                >
                    {translate("delete_account")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
