import { array, number, object } from "yup"

export const BankStatementSchema = object().shape({
    bankAccountId: number().required("required"),
    files: array().test("test", "required", (value, context) => {
        const { content } = context.parent
        if (!content) {
            return value && value.length === 1
        }
        return !value || value.length === 0
    }),
})
