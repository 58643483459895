import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useCallback } from "react"
import RentingPeriodStatusForm, { RentingPeriodStatusRequest } from "../../../sections/user/RentingPeriodStatusForm"
import { updateRentingPeriodMutation } from "../../../mutations/tenants"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { Operation } from "fast-json-patch"

export default function RentingPeriodStatusGuard() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(editingItem?.rentingPeriodId)

    const onClose = useCallback(
        (save: boolean) => {
            if (save) localStorage.setItem(`${rentingPeriodDetails?.id}-firstOnRentingPeriodStatusPopup`, "true")
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        },
        [dispatch, rentingPeriodDetails],
    )

    const handlePeriod = useCallback(
        async (data: RentingPeriodStatusRequest) => {
            if (rentingPeriodDetails == null) return
            const originalMoveOutDate = rentingPeriodDetails.moveOutDate.toISO()
            const newMoveOutDate = data.moveOutDate
            const operations: Operation[] = []

            if (originalMoveOutDate !== newMoveOutDate) {
                operations.push({
                    op: "replace",
                    path: "/moveOutDate",
                    value: newMoveOutDate,
                })
            }

            await updateRentingPeriod({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations: operations.map((o) => ({
                    ...o,
                    path: o.path.replace("/moveOutDate", "/to"),
                })),
            })
            onClose(true)
        },
        [updateRentingPeriod, rentingPeriodDetails],
    )

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.RentingPeriodStatus}
                isFullScreen={false}
                showCloseIcon={false}
                onClose={console.log}
                maxWidth={"md"}
                title={translate("rentingPeriod_is_almost_over")}
                paddingDialogTop={16}
            >
                <RentingPeriodStatusForm onClose={onClose} handlePeriod={handlePeriod} isLoading={isRentingPeriodUpdating} />
            </CustomDialog>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.RentingPeriodStatus}
                onClose={() => onClose(false)}
                isLoading={false}
                title={translate("rentingPeriod_is_almost_over")}
            >
                <RentingPeriodStatusForm onClose={onClose} handlePeriod={handlePeriod} isLoading={isRentingPeriodUpdating} />
            </DetailsSheet>
        </>
    )
}
