import { FormProvider, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddGeneralDocumentSchema } from "../../../../validations/notificationsTemplates"
import { AddNewDocumentRequest } from "../../../../sections/documents-templates/generalDocuments/AddOrEditGeneralDocument"

interface Props {
    onSave: (data: AddNewDocumentRequest) => void
    onClose: () => void
    isLoading: boolean
}
export default function UploadGeneralDocumentForm({ onSave, onClose, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()

    const methods = useForm<AddNewDocumentRequest>({
        resolver: yupResolver(AddGeneralDocumentSchema),
        mode: "onBlur",
        defaultValues: {
            name: "",
            files: [],
        },
    })

    const { handleSubmit } = methods

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFTextField name={"name"} label={translate("document_name")} size={"small"} required />

                    <RHFUpload
                        name={"files"}
                        icon={"mdi:file-document-plus-outline"}
                        multiple={true}
                        maxFiles={1}
                        customAcceptedFiles={{ "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [] }}
                    />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
