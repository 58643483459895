import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { AddRevisionRequest } from "../sections/propertyDetails/revision/AddOrEditRevisionForm"
import * as jsonpatch from "fast-json-patch"
import { AddObservationRequest } from "../sections/propertyDetails/revision/AddObservationForm"

export const addRevisionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddRevisionRequest }) => Api.addRevision(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Revisions])
            }
        },
    })
}

export const updateRevisionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ revisionId, operations }: { propertyId: number; revisionId: string; operations: jsonpatch.Operation[] }) =>
            Api.updateRevision({
                revisionId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Revisions])
            }
        },
    })
}

export const deleteRevisionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ revisionId }: { revisionId: string; propertyId?: number }) => Api.deleteRevision({ revisionId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Revisions])
            }
        },
    })
}

export const deleteObservationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ revisionId, observationId }: { revisionId: string; observationId: string }) =>
            Api.deleteObservation({ revisionId, observationId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RevisionObservations])
            }
        },
    })
}

export const addObservationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddObservationRequest }) => Api.addObservation(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RevisionObservations, ComplexQueryIDs.OwnerPropertyFiles])
            }
        },
    })
}

export const updateObservationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ observationId, revisionId, operations }: { observationId: number; revisionId: string; operations: jsonpatch.Operation[] }) =>
            Api.updateObservation({
                revisionId,
                observationId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RevisionObservations])
            }
        },
    })
}
