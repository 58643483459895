import { Text, View } from "@react-pdf/renderer"
import React, { useCallback } from "react"
import { useStyles } from "../useStyles"
import { useLocales } from "rentzz"
import { PropertyDataInfo } from "../../../../redux/slices/AddProperty"

interface PropertyDataProps {
    propertyData: PropertyDataInfo | undefined
}

const PropertyDataPreviewForTenant = ({ propertyData }: PropertyDataProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    const renderEmptyIfNoValue = useCallback(
        (value: number | string | undefined | null, label?: string) => {
            if ((typeof value === "number" && value === 0) || value == null || (typeof value === "string" && value.length === 0)) {
                return null
            }
            return (
                <Text style={{ display: "flex", flexDirection: "row" }}>
                    {translate(label, {})}
                    <Text style={{ ...styles.boldFont }}>{value}</Text>,{" "}
                </Text>
            )
        },
        [styles],
    )

    return (
        <View>
            <Text wrap style={styles.mainText}>
                <Text style={styles.boldFont}>{translate("art", { number: 1 })}</Text>
                {translate("contracts.owner_assures_tenant")} {renderEmptyIfNoValue(propertyData?.county)}
                {renderEmptyIfNoValue(propertyData?.city)}
                {renderEmptyIfNoValue(propertyData?.street, "contracts.street")}
                {renderEmptyIfNoValue(propertyData?.streetNumber, "contracts.number")}
                {renderEmptyIfNoValue(propertyData?.buildingNumber, "contracts.buildingNumber")}
                {renderEmptyIfNoValue(propertyData?.stair, "contracts.stair")}
                {renderEmptyIfNoValue(propertyData?.floor, "contracts.floor")}
                {renderEmptyIfNoValue(propertyData?.apartment, "contracts.apartment")}
            </Text>
        </View>
    )
}

export default PropertyDataPreviewForTenant
