import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import { PropertyFileType } from "../redux/slices/AddProperty"
import { useRentingPeriodDetails } from "./tenants"
import { useSelector } from "../redux/store"
import { AppContext } from "rentzz"

export const getOwnerFilesQuery = (propertyId?: number, rentingPeriodId?: number) => {
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(rentingPeriodId)
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        staleTime: Infinity,
        queryKey: [ComplexQueryIDs.OwnerPropertyFiles, { propertyId: propertyId ?? rentingPeriodDetails?.propertyId, rentingPeriodId }],
        queryFn: () => Api.getOwnerPropertyFiles(propertyId, rentingPeriodId),
        enabled: (!!propertyId || !!rentingPeriodDetails) && context === AppContext.Owner,
    })
}

export const getTenantFilesQuery = (rentingPeriodId?: number) => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        staleTime: Infinity,
        queryKey: [ComplexQueryIDs.TenantPropertyFiles, { id: rentingPeriodId }],
        queryFn: () => Api.getTenantPropertyFiles(rentingPeriodId),
        enabled: !!rentingPeriodId && context === AppContext.Tenant,
    })
}

export const getEntityFilesQuery = (entityId: string, entityType: PropertyFileType) => {
    return useQuery({
        staleTime: Infinity,
        queryKey: [ComplexQueryIDs.EntityFiles, { entityId, entityType }],
        queryFn: () => Api.getEntityFiles(entityId, entityType),
        enabled: !!entityType && !!entityId,
    })
}
