import { Box, Card, IconButton, Typography } from "@mui/material"
import { Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { useTheme } from "@mui/material/styles"

interface Props {
    onClick?: () => void
    icon: string
    title: string
    backgroundColor?: string
    isDisabled?: boolean
    disabledTooltipText?: string
}
const ChooseCardPage = ({ onClick, icon, title, backgroundColor, isDisabled, disabledTooltipText }: Props) => {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Card
            onClick={onClick}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isMobile ? 50 : 110,
                borderWidth: 2,
                borderColor: isDisabled ? theme.palette.action.disabled : theme.palette.primary.main,
                borderStyle: "dashed",
                cursor: isDisabled ? "initial" : "pointer",
                backgroundColor: backgroundColor,
                ":hover": { backgroundColor: backgroundColor || isDisabled ? undefined : theme.palette.action.hover },
            }}
        >
            <LightTooltip title={isDisabled ? translate(disabledTooltipText) : ""} arrow>
                <span>
                    <Box
                        onClick={onClick}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: isMobile ? "row-reverse" : "column",
                            alignItems: "center",
                            gap: 1,
                            cursor: isDisabled ? "initial" : "pointer",
                        }}
                    >
                        <Typography
                            color={isDisabled ? theme.palette.action.disabled : theme.palette.primary.dark}
                            variant={isMobile ? "subtitle2" : "subtitle1"}
                            fontWeight={"bold"}
                        >
                            {translate(title)}
                        </Typography>
                        <Box
                            sx={{
                                alignSelf: "center",
                            }}
                        >
                            <IconButton
                                disabled={isDisabled}
                                onClick={onClick}
                                sx={{
                                    borderWidth: 2,
                                    borderColor: isDisabled ? theme.palette.action.disabled : theme.palette.primary.main,
                                    borderStyle: isMobile ? "" : "solid",
                                    ":hover": { backgroundColor: "transparent" },
                                }}
                            >
                                <Iconify icon={icon} color={isDisabled ? theme.palette.action.disabled : theme.palette.primary.main} />
                            </IconButton>
                        </Box>
                    </Box>
                </span>
            </LightTooltip>
        </Card>
    )
}

export default ChooseCardPage
