import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PropertyNameChangeRequest, PropertyNameChangeSchema } from "./Header"
import { updatePropertyNameMutation } from "../../../mutations"

interface Props {
    propertyId: number
    onClose: () => void
}
export default function EditPropertyNameForm({ propertyId, onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: editPropertyName, isPending: isEditPropertyNameLoading } = updatePropertyNameMutation()

    const methods = useForm<PropertyNameChangeRequest>({
        resolver: yupResolver(PropertyNameChangeSchema),
        mode: "onBlur",
    })

    const { handleSubmit } = methods

    const onSubmit = async (data: PropertyNameChangeRequest) => {
        await editPropertyName({ operations: [{ op: "replace", path: "/name", value: data.newPropertyName }], propertyId })
        onClose()
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} gap={isMobile ? 2 : undefined} paddingTop={isMobile ? 2 : undefined} flexDirection={"column"}>
                <RHFTextField name={"newPropertyName"} label={translate("new_property_name")} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <LoadingButton
                        type={"submit"}
                        variant='contained'
                        color={"primary"}
                        fullWidth
                        loading={isEditPropertyNameLoading}
                        disabled={isEditPropertyNameLoading}
                    >
                        {translate("update")}
                    </LoadingButton>
                    {isMobile && (
                        <Button onClick={onClose} color={"primary"} fullWidth disabled={isEditPropertyNameLoading}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}
