import { Box, useTheme } from "@mui/material"
import { Carousel, CarouselArrows, CarouselDots, MotionContainer } from "rentzz"
import { useCallback, useRef, useState } from "react"

export const photosList = [
    {
        title: "Picture",
        location: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2",
    },
    {
        title: "Picture",
        location: "https://picsum.photos/seed/picsum/200/300",
    },
    {
        title: "Picture",
        location: "https://picsum.photos/200/300?grayscale",
    },
    {
        title: "Picture",
        location: "https://picsum.photos/200/300/?blur",
    },
]

export default function GalleryLivePreview() {
    const theme = useTheme()
    const carouselRef = useRef<Carousel>(null)

    const [currentIndex, setCurrentIndex] = useState(theme.direction === "rtl" ? photosList.length - 1 : 0)

    const carouselSettings = {
        speed: 800,
        dots: true,
        infinite: false,
        arrows: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: Boolean(theme.direction === "rtl"),
        beforeChange: (current: number, next: number) => setCurrentIndex(next),
        ...CarouselDots({
            rounded: true,
            sx: { mt: 3 },
        }),
    }

    const handlePrev = useCallback(() => {
        carouselRef.current?.slickPrev()
    }, [carouselRef])

    const handleNext = useCallback(() => {
        carouselRef.current?.slickNext()
    }, [carouselRef])

    return (
        <Box
            sx={{
                position: "relative",
            }}
        >
            <CarouselArrows
                filled
                leftButtonProps={{
                    size: "small",
                    disabled: currentIndex === (theme.direction === "rtl" ? photosList.length - 1 : 0),
                    sx: {
                        display: currentIndex === (theme.direction === "rtl" ? photosList.length - 1 : 0) ? "none" : undefined,
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                }}
                rightButtonProps={{
                    disabled: currentIndex + 1 === (theme.direction === "rtl" ? 0 : photosList.length),
                    size: "small",
                    sx: {
                        display: currentIndex + 1 === (theme.direction === "rtl" ? 0 : photosList.length) ? "none" : undefined,
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                }}
                shape='circular'
                onNext={handleNext}
                onPrev={handlePrev}
            >
                <Carousel ref={carouselRef} {...carouselSettings}>
                    {photosList.map((photo, index) => (
                        <MotionContainer action key={index}>
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignContent={"center"} gap={2} width={"100%"}>
                                <img src={photo.location} alt={photo.title} width={"50%"} style={{ maxHeight: 210 }} />
                                {index + 1 < photosList.length && (
                                    <img src={photosList[index + 1].location} alt={photo.title} width={"50%"} style={{ maxHeight: 210 }} />
                                )}
                            </Box>
                        </MotionContainer>
                    ))}
                </Carousel>
            </CarouselArrows>
        </Box>
    )
}

type CarouselItemProps = {
    isActive?: boolean
    src?: string
    alt?: string
    secondSrc?: string
    secondAlt?: string
}

function CarouselItem({ isActive, src, secondSrc, secondAlt, alt }: CarouselItemProps) {
    return (
        <MotionContainer action animate={isActive}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignContent={"center"} gap={2} width={"100%"}>
                <Box
                    component='img'
                    sx={{
                        width: "50%",
                        maxHeight: { xs: 233, md: 210 },
                    }}
                    alt={alt}
                    src={src}
                />
                <Box
                    component='img'
                    sx={{
                        width: "50%",
                        maxHeight: { xs: 233, md: 210 },
                        margin: "auto",
                    }}
                    alt={secondAlt}
                    src={secondSrc}
                />
            </Box>
        </MotionContainer>
    )
}
