import { CustomPopover } from "rentzz"
import React, { MouseEvent } from "react"
import AddNewManagerInvitationForm from "./AddNewManagerInvitationForm"

interface Props {
    open: HTMLElement | null
    setOpen: (e: HTMLElement | null) => void
}
export default function AddNewInvitation({ open, setOpen }: Props) {
    return (
        <CustomPopover
            open={open}
            anchorEl={open}
            arrow={"top-center"}
            onClose={(event: MouseEvent<HTMLElement>) => {
                event.stopPropagation()
                setOpen(null)
            }}
            sx={{
                mt: 1.5,
                ml: 0.75,
                width: 280,
                "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
            }}
        >
            <AddNewManagerInvitationForm onClose={() => setOpen(null)} />
        </CustomPopover>
    )
}
