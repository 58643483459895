import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "../../../redux/store"
import UploadAIExtractExpenseForm from "../../../sections/expenses/UploadAIExtractExpenseForm"

export default function ExpenseAIExtractGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.ExpenseAIExtractorUpload}
                onClose={onClose}
                isLoading={false}
                title={translate("upload_expense_ai_extractor")}
                headerColor={editingItem?.backgroundColor}
            >
                <UploadAIExtractExpenseForm />
            </DetailsSheet>

            <CustomDialog
                title={translate("upload_expense_ai_extractor")}
                open={!isMobile && modalOpen === AppModals.ExpenseAIExtractorUpload}
                onClose={onClose}
                maxWidth={"sm"}
                showCloseIcon
                titleAlign={"center"}
                isFullScreen={false}
            >
                <UploadAIExtractExpenseForm />
            </CustomDialog>
        </>
    )
}
