import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import React, { SyntheticEvent, useCallback, useMemo } from "react"
import { deleteExpenseMutation } from "../../../mutations/expenses"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useSingleExpenseQuery, useSinglePendingExpenseQueryFn } from "../../../queries/expenses"

const DeleteExpenseGuard = () => {
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteExpense, isPending: isDeleteLoading } = deleteExpenseMutation()
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)
    const { data: currentPendingExpense } = useSinglePendingExpenseQueryFn(editingItem?.id)
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()

    const currentItem = useMemo(
        () => (modalOpen === AppModals.DeleteExpense ? currentExpense : currentPendingExpense),
        [modalOpen, currentExpense, currentPendingExpense],
    )

    const onClose = useCallback(() => {
        if (isMobile && !editingItem?.isFromCalendar) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [isMobile, dispatch, editingItem])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            if (currentItem == null) return
            await deleteExpense({ expenseId: currentItem.id, propertyId: currentItem.propertyId })

            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [modalOpen, dispatch, deleteExpense, currentItem],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_expense")}
                isOpen={isMobile && (modalOpen === AppModals.DeleteExpense || modalOpen === AppModals.DeletePendingExpense)}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_expense_details", { expenseName: currentItem?.name })}
                    onClose={onClose}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && (modalOpen === AppModals.DeleteExpense || modalOpen === AppModals.DeletePendingExpense)}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_expense")}
                content={translate("delete_expense_details", { expenseName: currentItem?.name })}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}

export default DeleteExpenseGuard
