import { LoadingScreen, ProgressBarStyle, useLocales } from "rentzz"
import { IntlProvider } from "react-intl"
import "./App.css"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { Settings } from "luxon"
import React, { useCallback, useEffect, useState } from "react"
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"
import "yet-another-react-lightbox/plugins/thumbnails.css"
import { AxiosError } from "axios"
import { useSnackbar } from "notistack"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import SnackbarAction from "./components/SnackbarAction"
import Api from "./api/Api"
import { SimpleQueryIDs } from "./hooks/useQueryInvalidator"
import { useFeatureIsOn, useGrowthBook } from "@growthbook/growthbook-react"
import { Font } from "@react-pdf/renderer"
import { UserFlags } from "./queries/userData"
import { RouterProvider } from "react-router-dom"
import router from "./routes/routerdef"
import { BackendError } from "./sections/types/user"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
})
export default function App() {
    const { currentLang, translate } = useLocales()
    const [client, setClient] = useState<QueryClient>()
    const { enqueueSnackbar } = useSnackbar()
    const [showReactQueryDebug, setShowReactQueryDebug] = useState(false)
    const growthbook = useGrowthBook()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)

    const handleQueryClientError = useCallback(
        async (err: unknown) => {
            const error = err as AxiosError
            if (error.response?.status === 401) {
                await Api.logout()
                localStorage.removeItem("currentPropertyId")
                localStorage.removeItem("currentGroupId")
                localStorage.removeItem("currentRentingPeriodId")
                client?.removeQueries()
                window.location.reload()
                return
            }
            const errorResponse = error.response?.data as BackendError
            console.error(errorResponse)
            if (errorResponse?.Message) {
                enqueueSnackbar(`${translate(errorResponse?.Message)}`, {
                    variant: "error",
                    preventDuplicate: true,
                    style: { whiteSpace: "pre-line" },
                    action: isWhitelabel ? undefined : <SnackbarAction executionId={errorResponse.ExecutionId} />,
                })
            } else {
                enqueueSnackbar(translate("error_happened"), {
                    variant: "error",
                    preventDuplicate: true,
                    style: { whiteSpace: "pre-line", zIndex: 999999999999999 },
                    action: isWhitelabel ? undefined : <SnackbarAction />,
                })
            }
            const errorCasted = err as Error
            console.error(errorCasted.message)
            console.error(errorCasted.stack)
        },
        [enqueueSnackbar, translate, isWhitelabel],
    )

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "E") {
                event.preventDefault()
                setShowReactQueryDebug((prevState) => !prevState)
            }
        },
        [setShowReactQueryDebug],
    )

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress)

        return () => {
            window.removeEventListener("keydown", handleKeyPress)
        }
    }, [])

    useEffect(() => {
        setClient(
            new QueryClient({
                queryCache: new QueryCache({
                    onError: async (e, query) => {
                        if (query.queryKey[0] == SimpleQueryIDs.UserData) {
                            await Api.logout()
                            growthbook?.setAttributes({})
                            client?.removeQueries({
                                exact: true,
                                predicate: (q) => {
                                    return q.queryKey[0] !== SimpleQueryIDs.UserData
                                },
                            })
                        } else {
                            await handleQueryClientError(e)
                        }
                    },
                }),
                defaultOptions: {
                    mutations: {
                        onError: handleQueryClientError,
                    },
                },
            }),
        )
    }, [])

    useEffect(() => {
        Settings.defaultLocale = currentLang.value
        client?.invalidateQueries()
    }, [currentLang, client])

    if (!client) {
        return <LoadingScreen />
    }

    return (
        <QueryClientProvider client={client}>
            <LocalizationProvider
                dateAdapter={AdapterLuxon}
                adapterLocale={currentLang.value}
                localeText={{ start: translate("tenant-data.moveInDate"), end: translate("tenant-data.moveOutDate") }}
            >
                <IntlProvider locale={currentLang.value}>
                    <ProgressBarStyle />
                    <RouterProvider router={router} />
                    {(!import.meta.env.PROD || showReactQueryDebug) && <ReactQueryDevtools buttonPosition={"bottom-left"} initialIsOpen={false} />}
                </IntlProvider>
            </LocalizationProvider>
        </QueryClientProvider>
    )
}
