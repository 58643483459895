import { Box, CircularProgress, InputAdornment, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { FormProvider, Iconify, LightTooltip, RHFAutocomplete, RHFSelect, RHFSwitch, RHFTextField, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useAllUserInvoicingCompanies, useCompanyClients, useCompanySeries } from "../../../../../queries/invoicing-configuration"
import { allLangs } from "../../../../../config"
import * as jsonpatch from "fast-json-patch"
import { updateRentingPeriodInvoicing, updateRentingPeriodMutation } from "../../../../../mutations/tenants"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../queries/userData"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InvoicingSchema } from "../../../../../validations/invoicing-configuration"
import { LoadingButton } from "@mui/lab"
import InvoicingForRent from "./InvoicingForRent"
import InvoicingForExpenses from "./InvoicingForExpenses"
import { useSelector } from "../../../../../redux/store"

interface InvoicingRequest {
    invoicingLanguage: string
    invoicingCompany: string
    invoicingClientId: number
    invoicingSeries?: string
    defaultInvoicingTemplate?: string
    defaultInvoicingText?: string
    invoicingCurrencyId?: number
    invoiceMaxDays?: number
    invoicingPeriodStart?: number | null
    invoicingPeriodEnd?: number | null
    reinvoicingSeries?: string
    reinvoicingVAT?: number
    reinvoicingPercentage?: number
    defaultReinvoicingText?: string
    sendToSPV: boolean
    createInvoice: boolean
    createInvoiceDay?: number
}

export default function Invoicing() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { mutateAsync: updateRentingPeriodInvoicingAction, isPending: isRentingPeriodInvoicingUpdating } = updateRentingPeriodInvoicing()
    const { mutateAsync: updateRentingPeriodAction, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const { data: allUserSeries } = useCompanySeries(rentingPeriodDetails?.invoicingCompanies.at(0)?.id)
    const { data: allUserCompanies } = useAllUserInvoicingCompanies()
    const { data: allUserClients, isLoading: areAllUserClientsPending } = useCompanyClients(rentingPeriodDetails?.invoicingCompanies.at(0)?.id)
    const defaultInvoicingTemplatesByLanguage = useFeatureValue<Record<string, Record<string, string>>>(
        UserFlags.DefaultInvoicingTemplateByLanguage,
        {},
    )
    const showReinvoicing = useFeatureIsOn(UserFlags.ShowReinvoicingFields.toString())

    const methods = useForm<InvoicingRequest>({
        resolver: yupResolver<InvoicingRequest>(InvoicingSchema),
    })

    const { watch, handleSubmit, reset, setValue } = methods

    useEffect(() => {
        if (rentingPeriodDetails) {
            reset({
                invoicingCompany: rentingPeriodDetails?.invoicingCompanies.at(0)?.id.toString(),
                invoicingClientId: rentingPeriodDetails.invoicingClientId,
                invoicingLanguage: rentingPeriodDetails?.invoicingLanguage,
                createInvoiceDay: rentingPeriodDetails?.createInvoiceDay ?? undefined,
                invoicingSeries: rentingPeriodDetails?.invoicingSeries.at(0)?.id.toString(),
                invoicingCurrencyId: rentingPeriodDetails?.invoicingCurrencyId,
                invoiceMaxDays: rentingPeriodDetails?.invoiceMaxDays,
                defaultInvoicingTemplate: rentingPeriodDetails.defaultInvoicingTemplate,
                defaultInvoicingText: rentingPeriodDetails.defaultInvoicingText,
                invoicingPeriodStart: rentingPeriodDetails.invoicingPeriodStart,
                invoicingPeriodEnd: rentingPeriodDetails.invoicingPeriodEnd,
                reinvoicingSeries: rentingPeriodDetails.reinvoicingSeries.at(0)?.id.toString(),
                reinvoicingVAT: rentingPeriodDetails.reinvoicingVATs.at(0)?.id,
                defaultReinvoicingText: rentingPeriodDetails.defaultReinvoicingText ?? "",
                reinvoicingPercentage: rentingPeriodDetails.reinvoicingPercentage ?? 100,
                sendToSPV: rentingPeriodDetails.sendToSPV ?? false,
                createInvoice: rentingPeriodDetails.createInvoice ?? false,
            })
        }
    }, [rentingPeriodDetails])

    useEffect(() => {
        const subscription = watch(async (v, { name }) => {
            if (rentingPeriodDetails == null) return
            if (name === "invoicingCompany") {
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "company",
                    id: Number(v.invoicingCompany),
                })
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "series",
                })
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "reinvoicing-series",
                })
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "reinvoicing-vat",
                })
            }

            if (name === "invoicingSeries") {
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "series",
                    id: Number(v.invoicingSeries),
                })
            }

            if (name === "reinvoicingSeries") {
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "reinvoicing-series",
                    id: Number(v.reinvoicingSeries),
                })
            }

            if (name === "reinvoicingVAT") {
                await updateRentingPeriodInvoicingAction({
                    rentingPeriodId: rentingPeriodDetails.id,
                    type: "reinvoicing-vat",
                    id: Number(v.reinvoicingVAT),
                })
            }

            if (name === "invoicingLanguage") {
                const invoicingLanguage = v.invoicingLanguage as string

                setValue("defaultReinvoicingText", defaultInvoicingTemplatesByLanguage.reinvoicing[invoicingLanguage], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                })
                setValue("defaultInvoicingTemplate", defaultInvoicingTemplatesByLanguage.invoicing[invoicingLanguage], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                })
            }
        })
        return () => subscription.unsubscribe()
    }, [rentingPeriodDetails, updateRentingPeriodInvoicingAction, defaultInvoicingTemplatesByLanguage, setValue])

    const onSubmit = useCallback(
        async (data: InvoicingRequest) => {
            if (rentingPeriodDetails == null || data == null) return
            const operations = jsonpatch.compare(
                {
                    invoicingClientId: rentingPeriodDetails.invoicingClientId,
                    invoicingLanguage: rentingPeriodDetails.invoicingLanguage,
                    createInvoiceDay: rentingPeriodDetails.createInvoiceDay,
                    defaultInvoicingTemplate: rentingPeriodDetails.defaultInvoicingTemplate,
                    invoicingCurrencyId: rentingPeriodDetails.invoicingCurrencyId,
                    defaultInvoicingText: rentingPeriodDetails.defaultInvoicingText,
                    invoiceMaxDays: rentingPeriodDetails.invoiceMaxDays,
                    invoicingPeriodStart: rentingPeriodDetails.invoicingPeriodStart,
                    invoicingPeriodEnd: rentingPeriodDetails.invoicingPeriodEnd,
                    defaultReinvoicingText: rentingPeriodDetails.defaultReinvoicingText,
                    reinvoicingPercentage: rentingPeriodDetails.reinvoicingPercentage,
                    sendToSPV: rentingPeriodDetails.sendToSPV,
                    createInvoice: rentingPeriodDetails.createInvoice,
                },
                { ...data, invoicingSeries: undefined, invoicingCompany: undefined, reinvoicingSeries: undefined, reinvoicingVAT: undefined },
            )

            await updateRentingPeriodAction({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations,
            })
        },
        [rentingPeriodDetails, updateRentingPeriodAction],
    )

    if (rentingPeriodDetails == null || allUserCompanies == null)
        return (
            <Box height={100} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Paper elevation={3} square={false}>
            <Stack direction={"column"} p={2} gap={2}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2} paddingBottom={2}>
                        <Typography variant='subtitle1' sx={{ color: theme.palette.grey.A700 }}>
                            {translate("invoicing_configuration_renting_period")}
                        </Typography>
                        <Box display={"flex"} gap={2}>
                            {!isMobile && (
                                <>
                                    <RHFSwitch name={"createInvoice"} label={translate("create_invoice_automatically")} labelPlacement={"start"} />

                                    <RHFSwitch name={"sendToSPV"} label={translate("SPV")} labelPlacement={"start"} />
                                </>
                            )}

                            <LoadingButton
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                loading={isRentingPeriodUpdating || isRentingPeriodInvoicingUpdating}
                            >
                                {translate("save")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    {isMobile && (
                        <Box display={"flex"} gap={2} paddingBottom={2}>
                            <RHFSwitch name={"createInvoice"} label={translate("create_invoice_automatically")} labelPlacement={"start"} />

                            <RHFSwitch name={"sendToSPV"} label={translate("SPV")} labelPlacement={"start"} />
                        </Box>
                    )}
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                            <RHFSelect
                                name={"invoicingCompany"}
                                size='small'
                                variant={"outlined"}
                                label={translate("invoicing_company")}
                                disabled={!canWrite || allUserCompanies.length === 0}
                                fullWidth
                                required
                            >
                                {allUserCompanies ? (
                                    allUserCompanies.map((c) => (
                                        <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                            {c.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <CircularProgress />
                                )}
                            </RHFSelect>

                            <RHFAutocomplete
                                name={"invoicingClientId"}
                                textFieldProps={{ size: "small" }}
                                options={allUserClients?.map((p) => ({ id: p.id, label: p.name })) ?? []}
                                matchId
                                label={translate("invoicing_client")}
                                loading={areAllUserClientsPending}
                                autocompleteProps={{
                                    size: "small",
                                }}
                                required
                                disabled={
                                    !canWrite ||
                                    allUserSeries == null ||
                                    allUserSeries.length === 0 ||
                                    rentingPeriodDetails.invoicingCompanies.length === 0
                                }
                            />
                            <RHFSelect
                                name={"invoicingLanguage"}
                                size='small'
                                variant={"outlined"}
                                label={translate("invoicing_language")}
                                disabled={!canWrite}
                                fullWidth
                                required
                            >
                                {allLangs?.map((c) => (
                                    <MenuItem key={c.value} value={c.value} sx={{ color: theme.palette.grey.A700 }}>
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField
                                name={"createInvoiceDay"}
                                size={"small"}
                                label={translate("createInvoiceDay")}
                                type={"number"}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={"end"}>
                                            <LightTooltip title={translate("createInvoiceDayTooltip")} arrow>
                                                <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                            </LightTooltip>
                                        </InputAdornment>
                                    ),
                                    inputProps: { min: 0, max: 31 },
                                }}
                                required
                                disabled={!canWrite}
                            />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Typography variant='body2' fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                                {translate("rent_invoicing")}
                            </Typography>
                        </Box>
                        <InvoicingForRent />
                    </Box>

                    {showReinvoicing && (
                        <>
                            <Box display={"flex"} alignItems={"center"} gap={1} paddingY={2}>
                                <Typography variant='body2' fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("expense_reinvoicing")}
                                </Typography>
                            </Box>
                            <InvoicingForExpenses />
                        </>
                    )}
                </FormProvider>
            </Stack>
        </Paper>
    )
}
