import { useSelector } from "../redux/store"
import { useQuery } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { DateTime } from "luxon"
import Api from "../api/Api"
import { DashboardChartType, SummaryCardType } from "./userData"
import { AppContext } from "rentzz"
import { orderBy } from "lodash"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"

export interface GroupProperty {
    propertyId: number
    expenseShare: number
}
export interface UserGroupBase {
    id: number
    name: string
    description: string
    properties: GroupProperty[]
    summaryCards: SummaryCardType[]
    dashboardCharts: DashboardChartType[]
}

export interface UserGroupDto extends UserGroupBase {
    createdAt: string
    lastModifiedAt: string
}
export interface UserGroup extends UserGroupBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface GroupAccessBase {
    userId: string
    fullName: string
    ownershipType: ManagerType
}

export interface GroupAccessDto extends GroupAccessBase {
    date: string
}

export interface GroupAccess extends GroupAccessBase {
    date: DateTime
}

export interface GroupRentingPeriodDto {
    [key: string]: { from: string; to: string; propertyName: string }[]
}

export interface GroupRentingPeriod {
    [key: string]: { from: DateTime; to: DateTime; propertyName: string }[]
}

export const useUserGroupsQueryFn = () => {
    const { context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [SimpleQueryIDs.Groups],
        queryFn: Api.fetchUserGroups,
        staleTime: Infinity,
        enabled: context === AppContext.Owner,
        select: (data: UserGroup[] | undefined) => {
            if (data == null) return

            return data.map((group) => {
                const orderedCards = orderBy(group.summaryCards, ["order"], "asc")
                const orderedCharts = orderBy(group.dashboardCharts, ["order"], "asc")
                return {
                    ...group,
                    summaryCards: orderedCards,
                    dashboardCharts: orderedCharts,
                }
            })
        },
    })
}

export const getGroupRentingPeriods = () => {
    const { currentGroupId, context } = useSelector((s) => s.appState)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.GroupRentingPeriods,
            { currentGroupId, from: DateTime.now().minus({ year: 1 }).toISODate(), to: DateTime.now().plus({ year: 1 }).toISODate() },
        ],
        queryFn: Api.fetchGroupRentingPeriods,
        enabled: !!currentGroupId && context === AppContext.Owner,
    })
}

export const getGroupAccessList = () => {
    const { currentGroupId, context } = useSelector((s) => s.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.GroupAccess, { currentGroupId }],
        queryFn: Api.fetchGroupAccess,
        enabled: !!currentGroupId && context === AppContext.Owner,
    })
}
