import { Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React from "react"
import { useIsMobile, useLocales } from "rentzz"
import { PropertyIncome } from "../../../redux/slices/AddProperty"

interface ActionSheetIncomeStatus {
    currentIncome: (PropertyIncome & { canWrite: boolean }) | null | undefined
    getRentPrice: () => string
    isIncomeStatusLoading: boolean
    onAccept: () => void
    onDecline: () => void
    onClose: () => void
}
export default function ActionSheetIncomeStatus({
    currentIncome,
    isIncomeStatusLoading,
    onDecline,
    onClose,
    onAccept,
    getRentPrice,
}: ActionSheetIncomeStatus) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Typography marginTop={2} textAlign={"center"}>
                {translate("dialog_income_status_content", {
                    tenant: currentIncome?.createdBy,
                    incomeSource: translate(`incomeSource.${currentIncome?.type}`),
                    value: getRentPrice(),
                })}
            </Typography>
            <Typography textAlign={"center"} marginY={2} fontWeight={"bold"} color={"error"}>
                {translate("dialog_income_status_content_two")}
            </Typography>
            <Box
                sx={{
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column-reverse" : "row",
                    justifyContent: "flex-end",
                    padding: 2,
                    gap: 2,
                }}
            >
                <Button onClick={onClose} variant={"text"} color={"primary"} disabled={isIncomeStatusLoading} fullWidth={isMobile}>
                    {translate("cancel")}
                </Button>

                <LoadingButton
                    onClick={onDecline}
                    variant='contained'
                    color={"error"}
                    loading={isIncomeStatusLoading}
                    disabled={isIncomeStatusLoading}
                    fullWidth={isMobile}
                >
                    {translate("decline")}
                </LoadingButton>

                <LoadingButton
                    onClick={onAccept}
                    variant='contained'
                    color={"primary"}
                    loading={isIncomeStatusLoading}
                    disabled={isIncomeStatusLoading}
                    fullWidth={isMobile}
                >
                    {translate("accept")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
