import { CustomDialog, useLocales } from "rentzz"
import React from "react"
import EntityFileManagerForm from "./EntityFileManagerForm"

interface EntityFileManagerProps {
    isVisible: boolean
    handleClose: () => void
}

export default function EntityFileManagerDialog({ isVisible, handleClose }: EntityFileManagerProps) {
    const { translate } = useLocales()

    return (
        <CustomDialog
            maxWidth={"sm"}
            titleAlign={"center"}
            isFullScreen={false}
            title={translate("entityDialogTitle")}
            open={isVisible}
            onClose={handleClose}
        >
            <EntityFileManagerForm />
        </CustomDialog>
    )
}
