import { FormProvider, RHFPhoneNumber, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddCardPaymentDataSchema } from "../../../validations/expenses"
import { Alert, Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useEffect } from "react"
import { useTheme } from "@mui/material/styles"

export interface AddCardPaymentDataRequest {
    country: string
    county: string
    city: string
    address: string
    zipCode: string
    phoneNumber: string
}

interface Props {
    onSave: (data: AddCardPaymentDataRequest) => void
    onClose?: () => void
    isLoading: boolean
    defaultValues?: AddCardPaymentDataRequest
}

export default function AddCardPaymentDataForm({ onSave, onClose, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()

    const methods = useForm<AddCardPaymentDataRequest>({
        resolver: yupResolver<AddCardPaymentDataRequest>(AddCardPaymentDataSchema),
        mode: "onChange",
        defaultValues: {
            country: defaultValues?.country ?? "",
            county: defaultValues?.county ?? "",
            city: defaultValues?.city ?? "",
            address: defaultValues?.address ?? "",
            zipCode: defaultValues?.zipCode ?? "",
            phoneNumber: defaultValues?.phoneNumber ?? "",
        },
    })
    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                country: defaultValues.country,
                county: defaultValues.county,
                city: defaultValues.city,
                address: defaultValues.address,
                zipCode: defaultValues.zipCode,
                phoneNumber: defaultValues.phoneNumber,
            })
        }
    }, [defaultValues, reset])
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2} justifyContent={"center"} color={theme.palette.grey.A700}>
                {defaultValues ? (
                    <Typography variant={"subtitle1"} fontWeight={"bold"}>
                        {translate("card_payment")}
                    </Typography>
                ) : (
                    <Alert severity={"info"}>{translate("more_info_from_you")}</Alert>
                )}
                <Box display={"flex"} gap={2} flexDirection={defaultValues && !isMobile ? "row" : "column"}>
                    <RHFTextField name={"country"} label={translate("country")} required />
                    <RHFTextField name={"county"} label={translate("county")} required />{" "}
                </Box>
                <Box display={"flex"} gap={2} flexDirection={defaultValues && !isMobile ? "row" : "column"}>
                    <RHFTextField name={"city"} label={translate("city")} required />
                    <RHFTextField name={"address"} label={translate("address")} required />
                </Box>

                <Box display={"flex"} gap={2} flexDirection={defaultValues && !isMobile ? "row" : "column"}>
                    <RHFTextField name={"zipCode"} label={translate("postalCode")} required />
                    <RHFPhoneNumber name='phoneNumber' label={translate("phone")} required />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                {!defaultValues && (
                    <Button color={"primary"} onClick={onClose} fullWidth={isMobile} disabled={isLoading}>
                        {translate("cancel")}
                    </Button>
                )}

                <LoadingButton color={"primary"} type='submit' variant='contained' disabled={isLoading} loading={isLoading} fullWidth={isMobile}>
                    {translate(defaultValues ? "save" : "submit")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
