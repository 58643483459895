import { number, object, string } from "yup"
import { ContactInfoPersonType } from "../queries/userData"
import { isValidIBAN } from "ibantools"

export const OwnerContactSchema = () => {
    return object().shape({
        fullName: string().required("name_required").min(3, "min_3_characters"),
        county: string().required("county_required"),
        city: string().required("city_required"),
        street: string().optional(),
        streetNumber: string().optional(),
        buildingNumber: string().optional(),
        stair: string().optional(),
        floor: string().optional(),
        apartment: string().optional(),
        identitySeries: string()
            .optional()
            .min(2, "two_characters")
            .max(2, "two_characters")
            .matches(/^[A-Z]+$/, "must_be_uppercase")
            .test("test", "identity_series_required", (val, context) => {
                if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val != null && val.trim() !== ""
                if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) {
                    return val == null || val.trim() === ""
                }
            }),

        identityNumber: string()
            .optional()
            .min(6, "six_characters")
            .max(6, "six_characters")
            .test("test", "identity_number_required", (val, context) => {
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Individual) return val != null && val.trim() !== ""
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Company) return val == null || val.trim() === ""
            }),
        phone: string().optional(),
        email: string().email("valid_email_required").optional(),
        contactInfoPersonType: number().required(),
        companyName: string().test("test", "required_companyName", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyRegistrationNumber: string().test("test", "required_companyRegistrationNumber", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyPosition: string().test("test", "required_as", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        bankName: string().test("test", "bank_name_validation", (value, context) => {
            const isBankAccount =
                context?.parent.bankAccount != null && context.parent.bankAccount.trim() !== "" && context.parent.bankAccount !== "-1"
            return !(isBankAccount && (value == null || value.trim() === ""))
        }),
        bankAccount: string()
            .nullable()
            .test("test", "iban_validation", (value) => {
                if (value == null || value.trim() === "" || value === "-1") return true
                const noSpaceIban = value.replace(/\s/g, "")
                return isValidIBAN(noSpaceIban)
            }),
    })
}
