import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useAllSectionsQueryFn } from "../../../../../queries/kanban"
import AddOrEditSectionForm from "../../../../../sections/nomenclature/sections/AddOrEditSectionForm"

export default function AddOrEditSectionGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: allSections } = useAllSectionsQueryFn()

    const currentSection = useMemo(() => allSections?.find((section) => section.taskSectionId === editingItem?.id), [allSections, editingItem])

    const handleClose = useCallback(() => {
        if (isMobile && modalOpen === AppModals.EditSection) {
            dispatch(setModalOpen(AppModals.SectionDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, isMobile])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && (modalOpen === AppModals.AddSection || modalOpen === AppModals.EditSection)}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(currentSection ? "edit_section" : "add_section")}
            >
                <AddOrEditSectionForm onClose={handleClose} defaultValues={currentSection} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddSection || modalOpen === AppModals.EditSection)}
                onClose={handleClose}
                isLoading={false}
                title={translate(currentSection ? "edit_section" : "add_section")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditSectionForm onClose={handleClose} defaultValues={currentSection} />
            </DetailsSheet>
        </>
    )
}
