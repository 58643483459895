import { Grid } from "@mui/material"
import PropertyDetailsCard from "./PropertyDetailsCard"
import { useIsMobile } from "rentzz"
import React from "react"
import PropertyActions from "./PropertyActions"
import { useTheme } from "@mui/material/styles"
import { UserFlags } from "../../../../queries/userData"
import PropertyBankAccountsTable from "../../configurations/PropertyBankAccountsTable"
import ProvidersTable from "../../configurations/providers/ProvidersTable"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

const General = () => {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())

    return (
        <Grid container sx={{ color: theme.palette.grey.A700 }}>
            <Grid item sm={12} md={6} xl={6}>
                <PropertyDetailsCard />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={isMobile ? 2 : ""}>
                <PropertyActions />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingTop={2} display='flex' flexGrow={1}>
                <PropertyBankAccountsTable />
            </Grid>
            <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={2} display='flex' flexGrow={1}>
                {areExpenseProvidersEnabled && <ProvidersTable />}
            </Grid>
        </Grid>
    )
}

export default General
