import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { deleteTaskMutation, tenantDeleteTaskMutation } from "../../../mutations/kanban"
import { useSectionItemsQueryFn, useTenantTasksQueryFn } from "../../../queries/kanban"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteContent from "../../../components/DeleteContent"
import { useTenantRentingPeriodsQuery } from "../../../queries/userData"

export default function DeleteTaskGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem, context, currentPropertyId } = useSelector((state) => state.appState)
    const { mutateAsync: deleteTask, isPending: isDeleteTaskLoading } = deleteTaskMutation()
    const { mutateAsync: tenantDeleteTask, isPending: isTenantDeleteTaskLoading } = tenantDeleteTaskMutation()
    const { data: ownerTasks } = useSectionItemsQueryFn(editingItem?.sectionId)
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()
    const currentRentingPeriodId = useMemo(
        () => tenantRentingPeriods?.find((r) => r.propertyId === currentPropertyId)?.rentingPeriodId,
        [tenantRentingPeriods, currentPropertyId],
    )
    const { data: tenantTasks } = useTenantTasksQueryFn(currentRentingPeriodId)

    const tasks = useMemo(() => (context === AppContext.Owner ? ownerTasks : tenantTasks), [context, ownerTasks, tenantTasks])

    const currentTask = useMemo(() => tasks?.find((task) => task.id === editingItem?.taskId), [tasks, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.TaskDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentTask == null) return
        if (context === AppContext.Owner) {
            await deleteTask({ taskId: currentTask.id, sectionId: currentTask.sectionId })
        } else {
            if (currentRentingPeriodId == null) return
            await tenantDeleteTask({ taskId: currentTask.id, rentingPeriodId: currentRentingPeriodId })
        }

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [deleteTask, currentTask, dispatch, tenantDeleteTask, currentRentingPeriodId])

    return (
        <>
            <CustomDialog
                title={translate("delete_task")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteTask}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_task_content", { name: currentTask?.name })}
                    isLoading={isDeleteTaskLoading || isTenantDeleteTaskLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteTask}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_task")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_task_content", { name: currentTask?.name })}
                    isLoading={isDeleteTaskLoading || isTenantDeleteTaskLoading}
                />
            </DetailsSheet>
        </>
    )
}
