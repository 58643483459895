import { Alert, Box, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../redux/store"
import { BalanceBehavior, useSingleExpenseQuery } from "../../queries/expenses"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../queries/currency"
import { useIntl } from "react-intl"

export interface Props {
    onClose: () => void
    handleExpenseBalanceStatus: () => void
    isLoading: boolean
}
export default function ExpenseBalanceForm({ onClose, isLoading, handleExpenseBalanceStatus }: Props) {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)

    const getAmount = useCallback(() => {
        if (currencyData == null || currentExpense == null) return ""
        return intl.formatNumber(currentExpense.value as number, {
            style: "currency",
            currency: getCurrencyFromId(currencyData, currentExpense.currencyId).code,
        })
    }, [currencyData, currentExpense])

    return (
        <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
            <Typography paddingY={2} sx={{ color: theme.palette.grey.A700 }} textAlign={isMobile ? "center" : undefined}>
                {translate("expenseBalanceRealValueContent-1", {
                    name: currentExpense?.name,
                    value: currentExpense?.expenseBalanceBehavior === BalanceBehavior.RealValue ? getAmount() : 0,
                })}
            </Typography>
            <Alert severity={"warning"} sx={{ marginBottom: 2 }}>
                {translate("expenseBalanceRealValueContent-2", {
                    name: currentExpense?.name,
                    value: getAmount(),
                    newValue: currentExpense?.expenseBalanceBehavior === BalanceBehavior.RealValue ? 0 : getAmount(),
                })}
            </Alert>
            <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' alignItems='center' gap={2}>
                <LoadingButton fullWidth={isMobile} color='primary' onClick={onClose} disabled={isLoading}>
                    {translate("cancel")}
                </LoadingButton>
                <LoadingButton
                    fullWidth={isMobile}
                    variant='contained'
                    color={"primary"}
                    onClick={handleExpenseBalanceStatus}
                    loading={isLoading}
                    disabled={isLoading}
                >
                    {translate("edit_behavior")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
