import { FieldValues, Path, useFormContext } from "react-hook-form"
import { useCallback, useMemo } from "react"
import { AppForm, FIELDS_TO_RESET } from "./fieldsResetConfig"

export default function useFormResetter<T extends FieldValues>() {
    const { resetField } = useFormContext<T>()

    const resetFields = useCallback(
        (form: AppForm, field: Path<T>) => {
            const fieldToResetForCurrentForm = FIELDS_TO_RESET[form][field] as Path<T>[]
            if (fieldToResetForCurrentForm == null) throw new Error("No fields configured for this reset")

            fieldToResetForCurrentForm.forEach((f) => resetField(f))
        },
        [resetField],
    )

    return useMemo(
        () => ({
            resetFields,
        }),
        [],
    )
}
