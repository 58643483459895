import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../../queries/userData"
import React, { useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { useUserGroupsQueryFn } from "../../../queries/groups"

export default function GroupPropertyDetails() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()
    const { modalOpen, editingItem, currentGroupId } = useSelector((state) => state.appState)

    const currentProperty = useMemo(() => properties?.find((p) => p.id === editingItem?.id), [properties, editingItem])
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [groups, currentGroupId])
    const currentGroupProperty = useMemo(
        () => currentGroup?.properties.find((p) => p.propertyId === currentProperty?.id),
        [currentProperty, currentGroup],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentProperty == null) return []

        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditPropertyGroupPercentage))
                },
            },
        ]
    }, [currentProperty, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PropertyGroupDetails}
            onClose={() => {
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentProperty?.label}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo(
                "mdi:percent",
                `${translate("expense_percentage")}: ${(currentGroupProperty?.expenseShare ?? 0) * 100}%`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}

            {renderActionSheetInfo(
                "mdi:home-city",
                `${currentProperty?.county}, ${currentProperty?.city}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
