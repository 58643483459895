import { Box, Paper, Typography, useTheme } from "@mui/material"
import { RHFSwitch, useLocales } from "rentzz"
import React from "react"

interface OtherDetailsProps {
    isDisabled?: boolean
}

export default function OtherDetails({ isDisabled }: OtherDetailsProps) {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <Paper
            sx={{
                paddingY: 2,
                paddingLeft: 2,
                borderColor: theme.palette.grey["300"],
                borderWidth: 1,
                borderStyle: "solid",
            }}
        >
            <Typography color={theme.palette.grey.A400} fontSize={14} paddingBottom={1}>
                {translate("marketing.more_specifications")}
            </Typography>

            <Box display={"flex"} flexWrap={"wrap"}>
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasHeater'
                    label={translate("hasHeater")}
                    disabled={isDisabled}
                />
                <RHFSwitch sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }} name='hasAC' label={translate("hasAC")} disabled={isDisabled} />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasFridge'
                    label={translate("hasFridge")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasDishwasher'
                    label={translate("hasDishwasher")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasWashingMachine'
                    label={translate("hasWashingMachine")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasHood'
                    label={translate("hasHood")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasElevator'
                    label={translate("hasElevator")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasDoubleGlazing'
                    label={translate("hasDoubleGlazing")}
                    disabled={isDisabled}
                />
                <RHFSwitch
                    sx={{ minWidth: 210, maxWidth: "25%", paddingRight: 2 }}
                    name='hasInsulation'
                    label={translate("hasInsulation")}
                    disabled={isDisabled}
                />
            </Box>
        </Paper>
    )
}
