import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteContent from "../../../components/DeleteContent"
import { updateExpenseMutation } from "../../../mutations/expenses"
import { useSinglePendingExpenseQueryFn } from "../../../queries/expenses"

export default function DeactivateSendingExpense() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateExpense, isPending: isUpdateExpenseLoading } = updateExpenseMutation()
    const { data: currentExpense } = useSinglePendingExpenseQueryFn(editingItem?.id)

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.PendingExpenseDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, isMobile])

    const deactivateExpense = useCallback(async () => {
        if (currentExpense == null) return
        await updateExpense({
            propertyId: currentExpense.propertyId,
            expenseId: currentExpense.id,
            operations: [{ op: "replace", path: "/dateAutomaticallySentToTenant", value: null }],
        })

        if (isMobile) {
            dispatch(setModalOpen(AppModals.PendingExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [currentExpense, updateExpense, isMobile])

    return (
        <>
            <CustomDialog
                title={translate("deactivate_sending_expense")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeactivateSendingExpenseToTenant}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={deactivateExpense}
                    deleteText={translate("deactivate_sending_expense_content", {
                        expenseName: currentExpense?.name,
                    })}
                    isLoading={isUpdateExpenseLoading}
                    submitText={"deactivate"}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeactivateSendingExpenseToTenant}
                onClose={onClose}
                isLoading={false}
                title={translate("deactivate_sending_expense")}
                options={[]}
                headerColor={theme.palette.warning.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={deactivateExpense}
                    deleteText={translate("deactivate_sending_expense_content", {
                        expenseName: currentExpense?.name,
                    })}
                    isLoading={isUpdateExpenseLoading}
                    submitText={"deactivate"}
                />
            </DetailsSheet>
        </>
    )
}
