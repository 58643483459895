import { Label, LightTooltip, useLocales } from "rentzz"
import React from "react"
import { getLabelColor } from "../utils"
import { RentingPeriodStatusCode } from "../../../../redux/slices/AddProperty"

interface DesktopRentingPeriodStatusProps {
    value: RentingPeriodStatusCode
}

const DesktopRentingPeriodStatus = ({ value }: DesktopRentingPeriodStatusProps) => {
    const { translate } = useLocales()

    return (
        <LightTooltip title={value === RentingPeriodStatusCode.Finished ? "" : translate(`rentingPeriodTooltipStatus.${value}`)} arrow>
            <Label color={getLabelColor(value)} style={{ textTransform: "none" }}>
                {translate(`rentingPeriodStatus.${value}`)}
            </Label>
        </LightTooltip>
    )
}

export default DesktopRentingPeriodStatus
