import CustomCard from "../../../../../../components/CustomCard"
import { Box, Typography } from "@mui/material"
import { Iconify } from "rentzz"
import React from "react"
import { Notes } from "../../../../../../queries/notes"
import { alpha, useTheme } from "@mui/material/styles"

interface NoteCustomCardProps {
    note: Notes
    onClick: () => void
}
export default function NoteCustomCard({ note, onClick }: NoteCustomCardProps) {
    const theme = useTheme()

    return (
        <CustomCard key={note.noteId} onClick={onClick} isCardClickable={false} px={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:information-variant"}
                        width={35}
                        height={35}
                        minWidth={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(theme.palette.primary.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            color: theme.palette.primary.main,
                            minWidth: 35,
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontWeight={"bold"}
                            fontSize={14}
                            sx={{ display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}
                        >
                            {note.title}
                        </Typography>
                        <Typography
                            fontSize={12}
                            sx={{ overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 1, textAlign: "left", display: "-webkit-box" }}
                        >
                            {note.text}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </CustomCard>
    )
}
