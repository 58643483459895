import { Paper } from "@mui/material"
import DeleteAccount from "./DeleteAccount"
import { useTheme } from "@mui/material/styles"

export default function Security() {
    const theme = useTheme()

    return (
        <Paper elevation={6} sx={{ padding: 2, marginTop: 2, color: theme.palette.grey.A700 }}>
            <DeleteAccount />
        </Paper>
    )
}
