import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useInfiniteMeterValuesHistoryQueryFn, useMetersQueryFn } from "../../../../queries/meters"
import React, { useCallback, useMemo } from "react"
import { OwnerPropertyMeter, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import AddOrEditIndexForm from "../../../../sections/propertyDetails/Meters/MetersHistory/AddOrEditIndexForm"
import { DateTime } from "luxon"
import { Box } from "@mui/material"

export default function AddNewIndexGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn(editingItem?.tableId ? editingItem?.propertyId : undefined)
    const { data: readings } = useInfiniteMeterValuesHistoryQueryFn()

    const currentMeter = useMemo(
        () => (meters as (TenantPropertyMeter | OwnerPropertyMeter)[])?.find((meter) => meter?.id === editingItem?.id),
        [meters, editingItem],
    )

    const currentReading = useMemo(() => {
        const arrayOfReadings = readings?.pages.map((r) => r.items[0])
        return arrayOfReadings?.find((r) => r?.id === editingItem?.readingId)
    }, [readings, editingItem])

    const handleClose = useCallback(() => {
        if (editingItem?.tableId) {
            dispatch(setModalOpen(AppModals.MeterValuesHistory))
            dispatch(setEditingItem({ ...editingItem, readingId: undefined }))
            return
        }

        if (isMobile) {
            dispatch(setEditingItem({ ...editingItem, readingId: undefined }))
            dispatch(setModalOpen(AppModals.MeterValuesHistory))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile, editingItem])

    const meterHeaderColor = useMemo(() => {
        if (modalOpen === AppModals.AddNewIndex && editingItem == undefined) return theme.palette.primary.main
        return currentMeter?.lastModified?.month === DateTime.now().month ? theme.palette.success.main : theme.palette.warning.main
    }, [currentMeter, modalOpen, editingItem])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && (modalOpen === AppModals.AddNewIndex || modalOpen === AppModals.EditReading)}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(currentReading ? "edit_reading" : "add_reading_short")}
            >
                <AddOrEditIndexForm onClose={handleClose} defaultValues={currentReading} />
            </CustomDialog>
            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddNewIndex || modalOpen === AppModals.EditReading)}
                onClose={handleClose}
                isLoading={currentMeter == null}
                title={translate(currentReading ? "edit_reading" : "add_reading_short")}
                subtitle={currentMeter?.name}
                options={[]}
                headerColor={meterHeaderColor}
            >
                <Box p={1}>
                    <AddOrEditIndexForm onClose={handleClose} defaultValues={currentReading} />
                </Box>
            </DetailsSheet>
        </>
    )
}
