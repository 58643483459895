import { Box, Card, CardContent, CircularProgress, useTheme } from "@mui/material"
import { useDashboardChartQuery } from "../queries"
import React from "react"
import { Chart as RentzzChart, useChart, useIsMobile, useLocales } from "rentzz"
import { useUserDataQueryFn } from "../queries/userData"
import { useIntl } from "react-intl"
import { DashboardDataType } from "../redux/slices/AddProperty"
import { getCurrencyFromId, useCurrencyQueryFn } from "../queries/currency"
import { useSelector } from "../redux/store"
import { DateTime } from "luxon"
import { groupBy } from "lodash"
import { getUnitFromId, useUnitsQueryFn } from "../queries/units"

interface ChartProps {
    chartId: number
    isPreview?: boolean
}
const Chart = ({ chartId, isPreview }: ChartProps) => {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: chartData } = useDashboardChartQuery(chartId, currentPropertyId)
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: units } = useUnitsQueryFn()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const intl = useIntl()
    const { translate } = useLocales()
    let series = []

    if (chartData?.dataType === DashboardDataType.Meters) {
        const chartValues = chartData.values as {
            sequenceName: string
            dateTime: DateTime
            value: string
            unitId: number
        }[]
        const groupedChartValues = groupBy(chartValues, "sequenceName")
        for (const sequenceName in groupedChartValues) {
            const values = groupedChartValues[sequenceName].map((s) => ({
                x: s.dateTime.toISO(),
                y: Number(s.value),
                meta: s.unitId,
            }))
            series.push({
                name: sequenceName,
                data: values,
            })
        }
    } else {
        series = [
            {
                name: translate(chartData?.tooltipTitle),
                data: (chartData?.values ?? []).map((v) => ({
                    x: v.dateTime.toISO(),
                    y: Number(v.value),
                })),
            },
        ]
    }

    const chartOptions = useChart({
        xaxis: {
            type: "datetime",
            tickAmount: 5,
            labels: {
                format: "MMM",
                showDuplicates: false,
            },
        },
        title: {
            text: translate(`${chartData?.title}`),
            offsetX: 5,
            offsetY: 5,
            floating: false,
        },
        tooltip: {
            x: {
                show: true,
                format: "MMM",
            },
            y: {
                formatter(val: unknown, { seriesIndex, dataPointIndex, w }): string {
                    if (!chartData || !user || !val) return ""
                    const currency = user?.currency.id
                    if (chartData.dataType === DashboardDataType.Money) {
                        return intl.formatNumber(Number(val), {
                            style: "currency",
                            currency: getCurrencyFromId(currencies, currency).code,
                        })
                    } else if (chartData.dataType === DashboardDataType.Number) {
                        return intl.formatNumber(Number(val))
                    } else if (chartData.dataType === DashboardDataType.Meters) {
                        const unit = w.globals.initialSeries[seriesIndex].data[dataPointIndex].meta
                        return `${intl.formatNumber(Number(val), {
                            style: "decimal",
                            useGrouping: false,
                        })} ${getUnitFromId(units, unit).code}`
                    }
                    return (val as any).toString()
                },
            },
            marker: { show: false },
        },
    })
    if (user == null) return null

    return (
        <Card
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                minWidth: isMobile ? "100%" : isPreview ? 200 : window.innerWidth / 2 - 100,
                transition: isMobile ? undefined : "transform 0.5s",
                ":hover": { transform: isMobile ? undefined : "scale(1.02)" },
                cursor: "pointer",
                flex: 1,
                flexGrow: 1,
            }}
        >
            <CardContent
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                    px: 1,
                    flexDirection: "column",
                    ":hover": { backgroundColor: theme.palette.action.hover },
                    minHeight: 100,
                }}
            >
                {!chartData && (
                    <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                )}
                {chartData && <RentzzChart type='line' series={series} options={chartOptions} height={isPreview ? 320 : 250} />}
            </CardContent>
        </Card>
    )
}

export default Chart
