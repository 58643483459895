import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { addRecurringExpenseMutation, AddRecurringExpenseRequest } from "../../../mutations/expenses"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import AddRecurrentExpenseForm from "./AddRecurrentExpenseForm"
import { useSingleRecurringExpenseQuery } from "../../../queries/expenses"
import { useTheme } from "@mui/material/styles"

const AddRecurringExpense = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addRecurringExpense, isPending: isAddExpenseLoading } = addRecurringExpenseMutation()
    const { data: currentRecurringExpense } = useSingleRecurringExpenseQuery(editingItem?.id, [{ field: "createdAt", sort: "desc" }])

    const handleOnClose = useCallback(() => {
        if (isMobile && modalOpen !== AppModals.AddRecurringExpense) {
            dispatch(setModalOpen(AppModals.RecurringExpenseDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch, setModalOpen])

    const handleOnSave = useCallback(
        async (data: AddRecurringExpenseRequest) => {
            await addRecurringExpense({ data })
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [addRecurringExpense, dispatch, setModalOpen],
    )

    return (
        <>
            <CustomDialog
                open={!isMobile && (modalOpen === AppModals.AddRecurringExpense || modalOpen === AppModals.EditRecurringExpense)}
                title={
                    currentRecurringExpense == null
                        ? translate("recurringExpenses.add_recurring_expense")
                        : translate("recurringExpenses.edit_recurring_expense")
                }
                titleAlign={"center"}
                onClose={handleOnClose}
                showCloseIcon={true}
                maxWidth={"sm"}
                isFullScreen={isMobile}
            >
                <AddRecurrentExpenseForm onSave={handleOnSave} isLoading={isAddExpenseLoading} onClose={handleOnClose} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={
                    currentRecurringExpense == null
                        ? translate("recurringExpenses.add_recurring_expense")
                        : translate("recurringExpenses.edit_recurring_expense")
                }
                isOpen={isMobile && (modalOpen === AppModals.AddRecurringExpense || modalOpen === AppModals.EditRecurringExpense)}
                onClose={handleOnClose}
                headerColor={
                    currentRecurringExpense?.isActive || modalOpen === AppModals.AddRecurringExpense
                        ? theme.palette.primary.main
                        : theme.palette.warning.main
                }
            >
                <AddRecurrentExpenseForm onSave={handleOnSave} isLoading={isAddExpenseLoading} onClose={handleOnClose} />
            </DetailsSheet>
        </>
    )
}

export default AddRecurringExpense
