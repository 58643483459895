import { useTheme } from "@mui/material/styles"
import { store, useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import { sendTenantNotificationMutation } from "../../../mutations/tenants"
import SendTenantNotificationForm from "../../../sections/propertyDetails/Tenants/rentingPeriodDetails/general/details/sendTenantNotification/SendTenantNotificationForm"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"
import ReactDOMServer from "react-dom/server"
import TenantNotificationPreview from "../../../sections/propertyDetails/Tenants/rentingPeriodDetails/general/details/sendTenantNotification/TenantNotificationPreview"
import { Provider } from "react-redux"

export default function SendTenantNotification() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: sendNotification, isPending: isSendNotificationLoading } = sendTenantNotificationMutation(editingItem?.rentingPeriodId)
    const logoForNotification = useFeatureValue(UserFlags.LogoForNotification, "")

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onSave = useCallback(async () => {
        const html = ReactDOMServer.renderToString(
            <Provider store={store}>
                <TenantNotificationPreview logoWithTextPath={logoForNotification} isPreview={false} />
            </Provider>,
        )
        if (editingItem == null) return

        await sendNotification({
            content: html.toString(),
            subject: editingItem?.subject,
            to: editingItem.emails,
            files: editingItem.files,
        })
        closeModal()
    }, [closeModal, modalOpen, dispatch, editingItem])

    return (
        <>
            <CustomDialog
                title={translate("send_notification")}
                open={!isMobile && modalOpen === AppModals.SendTenantNotification}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <SendTenantNotificationForm onClose={closeModal} onSave={onSave} isLoading={isSendNotificationLoading} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("send_notification")}
                isOpen={isMobile && modalOpen === AppModals.SendTenantNotification}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <SendTenantNotificationForm onClose={closeModal} onSave={onSave} isLoading={isSendNotificationLoading} />
                </Box>
            </DetailsSheet>
        </>
    )
}
