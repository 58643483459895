import CustomCard from "../../../../components/CustomCard"
import { Box, Typography, useTheme } from "@mui/material"
import { AppContext, Iconify, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import { useIntl } from "react-intl"
import { OwnerPropertyMeter, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { alpha } from "@mui/material/styles"

interface MeterMobileCardProps {
    meter: (OwnerPropertyMeter & { canWrite: boolean }) | (TenantPropertyMeter & { canWrite: boolean })
}

export default function MeterMobileCard({ meter }: MeterMobileCardProps) {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const theme = useTheme()
    const intl = useIntl()
    const { context } = useSelector((state) => state.appState)
    const { data: unitData } = useUnitsQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()

    const getValue = useCallback(
        (value: number, unitId: number) => {
            if (value === -1) return "-"
            if (unitId == null) return
            return `${intl.formatNumber(value as number, {
                style: "decimal",
                useGrouping: false,
            })} ${getUnitFromId(unitData, unitId).code}`
        },
        [unitData, intl],
    )

    const showName = useCallback(() => {
        if (meter.name.length > 20) {
            return `${meter.name.substring(0, 21)}...`
        }
        return meter.name
    }, [meter])

    return (
        <CustomCard
            isCardClickable
            onClick={() => {
                dispatch(setEditingItem({ id: meter.id, canWrite: meter.canWrite }))
                dispatch(setModalOpen(AppModals.MeterDetails))
            }}
            px={1}
            py={1}
        >
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:meter-electric"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor:
                                meter?.lastModified?.month === DateTime.now().month
                                    ? alpha(theme.palette.success.main, 0.1)
                                    : alpha(theme.palette.warning.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: meter?.lastModified?.month === DateTime.now().month ? theme.palette.success.main : theme.palette.warning.main,
                            backgroundColor:
                                meter?.lastModified?.month === DateTime.now().month
                                    ? alpha(theme.palette.success.main, 0.1)
                                    : alpha(theme.palette.warning.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                        <Typography fontWeight={"bold"} fontSize={15}>
                            {showName()}
                        </Typography>
                        {context === AppContext.Owner && (
                            <Typography fontSize={12}>{userProperties?.find((p) => p.id === meter.propertyId)?.label}</Typography>
                        )}
                        {meter?.lastModified?.month !== DateTime.now().month && context === AppContext.Tenant && (
                            <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                                {translate("no_reading_this_month")}
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Typography fontSize={14} fontWeight={"bold"}>
                        {getValue(meter.currentValue, meter.unitId)}
                    </Typography>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {meter.lastModified?.toLocaleString(DateTime.DATE_SHORT) ?? "-"}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
