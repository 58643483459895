import { Box, Card, CardProps, useTheme } from "@mui/material"
import { useCallback, useMemo, useRef, useState } from "react"
import { Carousel, CarouselArrows, CarouselDots, useLocales } from "rentzz"
import { CarouselMeterItem } from "../../propertyDetails/Tenants/addTenant/CarouselMeterItem"
import { CarouselDocumentItem } from "../../documents-templates/generalDocuments/CarouselDocumentItem"
import { useDispatch } from "../../../redux/store"
import { setEditingItem } from "../../../redux/slices/App"

export type MeterItemProps = {
    name: string
    unitId: number
    reportingPeriod: number[]
    currentValue: number
}

export type DocumentItemProps = {
    title: string
    subtitle: string
    image?: string
}

export enum CarouselItemType {
    Meter,
    LearnMore,
}

interface Props extends CardProps {
    rows: number
    list: MeterItemProps[] | DocumentItemProps[]
    item: CarouselItemType
}

export default function CustomCarousel({ list, rows, item, ...other }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const carouselRef = useRef<Carousel>(null)

    const [currentIndex, setCurrentIndex] = useState(theme.direction === "rtl" ? list.length - 1 : 0)

    const carouselSettings = {
        speed: 800,
        rows,
        dots: true,
        infinite: false,
        arrows: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: Boolean(theme.direction === "rtl"),
        beforeChange: (current: number, next: number) => setCurrentIndex(next),
        ...CarouselDots({
            rounded: true,
            sx: { mt: 3 },
        }),
    }

    const handlePrev = useCallback(() => {
        dispatch(setEditingItem({ currentIndex: currentIndex - 1 }))
        carouselRef.current?.slickPrev()
    }, [dispatch, currentIndex, carouselRef])

    const handleNext = useCallback(() => {
        dispatch(setEditingItem({ currentIndex: currentIndex + 1 }))
        carouselRef.current?.slickNext()
    }, [dispatch, currentIndex, carouselRef])

    if (list.length === 0) {
        return (
            <Card
                {...other}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 100,
                    backgroundColor: theme.palette.action.hover,
                    position: "relative",
                    "& .slick-list": {
                        borderRadius: 2,
                    },
                }}
            >
                {translate("no_meters")}
            </Card>
        )
    }

    const commonComponent = useMemo(
        () => (
            <CarouselArrows
                filled
                leftButtonProps={{
                    size: "small",
                    disabled: currentIndex === (theme.direction === "rtl" ? (list.length - 1) / rows : 0),
                    sx: {
                        display: currentIndex === (theme.direction === "rtl" ? (list.length - 1) / rows : 0) ? "none" : undefined,
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                }}
                rightButtonProps={{
                    disabled: currentIndex + 1 === (theme.direction === "rtl" ? 0 : list.length / rows),
                    size: "small",
                    sx: {
                        display: currentIndex + 1 === (theme.direction === "rtl" ? 0 : list.length / rows) ? "none" : undefined,
                        backgroundColor: theme.palette.primary.main,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                }}
                shape='circular'
                onNext={handleNext}
                onPrev={handlePrev}
            >
                <Carousel ref={carouselRef} {...carouselSettings}>
                    {list.map((listItem, index) => {
                        if (item === CarouselItemType.Meter) {
                            return <CarouselMeterItem key={index} item={listItem as MeterItemProps} isActive={index === currentIndex} />
                        }
                        if (item === CarouselItemType.LearnMore) {
                            return (
                                <CarouselDocumentItem
                                    key={index}
                                    item={listItem as DocumentItemProps}
                                    isActive={index === currentIndex}
                                    itemNumber={currentIndex + 1}
                                />
                            )
                        }
                    })}
                </Carousel>
            </CarouselArrows>
        ),
        [currentIndex, list, theme],
    )

    return (
        <>
            {item === CarouselItemType.LearnMore && (
                <Box
                    {...other}
                    sx={{
                        position: "relative",
                        "& .slick-list": {
                            borderRadius: 2,
                        },
                    }}
                >
                    {commonComponent}
                </Box>
            )}
            {item === CarouselItemType.Meter && (
                <Card
                    {...other}
                    sx={{
                        position: "relative",
                        "& .slick-list": {
                            borderRadius: 2,
                        },
                    }}
                >
                    {commonComponent}
                </Card>
            )}
        </>
    )
}
