import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { CreateNewProviderSchema } from "../../../validations/providers"
import { createNewProviderMutation, updateCustomProvidersMutation } from "../../../mutations/user"
import { CustomProvider } from "../../../queries/userData"
import * as jsonpatch from "fast-json-patch"

interface Props {
    onClose: () => void
    defaultValues?: CustomProvider
}

export interface CreateNewProviderRequest {
    name: string
}

export default function AddOrEditNewProvider({ onClose, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: createNewProvider, isPending: isNewProviderCreating } = createNewProviderMutation()
    const { mutateAsync: updateProvider, isPending: isUpdateProviderLoading } = updateCustomProvidersMutation()

    const methods = useForm<CreateNewProviderRequest>({
        resolver: yupResolver(CreateNewProviderSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
            })
        }
    }, [defaultValues, reset])

    const onSave = useCallback(
        async (data: CreateNewProviderRequest) => {
            if (defaultValues) {
                const operations = jsonpatch.compare({ name: defaultValues?.name }, { name: data.name })
                await updateProvider({
                    providerId: defaultValues.id,
                    operations,
                })
            } else {
                await createNewProvider({ name: data.name })
            }

            onClose()
        },
        [createNewProvider, onClose, updateProvider, defaultValues],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={1} flexDirection={"column"}>
                <RHFTextField name='name' label={translate("provider")} size='small' placeholder={"Rentzz"} />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onClose} disabled={isNewProviderCreating || isUpdateProviderLoading}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isNewProviderCreating || isUpdateProviderLoading}
                        loading={isNewProviderCreating || isUpdateProviderLoading}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
