import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import UserAccountTypeForm from "../../../../sections/dashboard/Tables/UserAccountTypeForm"

export default function ActiveUserAccountTypeGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    return (
        <DetailsSheet
            isLoading={false}
            title={translate("accountType")}
            isOpen={modalOpen === AppModals.HandleActiveUserAccountType}
            onClose={() => {
                dispatch(setModalOpen(AppModals.ActiveUserDetailsSheet))
            }}
        >
            <UserAccountTypeForm userId={editingItem?.id} onClose={() => dispatch(setModalOpen(AppModals.ActiveUserDetailsSheet))} />
        </DetailsSheet>
    )
}
