import { useDispatch } from "../redux/store"
import { useLocales } from "rentzz"
import { Button } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../redux/slices/App"
import React, { ReactElement } from "react"

interface SnackbarActionProps {
    executionId?: string
}

const SnackbarAction = ({ executionId }: SnackbarActionProps): ReactElement => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    return (
        <Button
            variant={"contained"}
            color={"error"}
            onClick={() => {
                dispatch(setModalOpen(AppModals.Contact))
                dispatch(setEditingItem({ executionId: executionId }))
            }}
        >
            {translate("contact_us")}
        </Button>
    )
}

export default SnackbarAction
