import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useUserC168AddressesQuery } from "../../../../../queries/userData"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deleteC168AddressMutation } from "../../../../../mutations/c168"

export default function DeleteC168Address() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteAddress, isPending: isDeleteAddressLoading } = deleteC168AddressMutation()
    const { data: c168Address } = useUserC168AddressesQuery()

    const currentAddress = useMemo(() => c168Address?.items?.find((address) => address.id === editingItem?.id), [c168Address, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.C168AddressDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentAddress?.id == null) return
        await deleteAddress(currentAddress.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentAddress, deleteAddress, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteC168Address}
                onClose={onClose}
                isFullScreen={false}
                variantCloseButton={"text"}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_c168Address_content")}
                    isLoading={isDeleteAddressLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteC168Address}
                onClose={onClose}
                isLoading={false}
                title={translate("delete")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_c168Address_content")}
                    isLoading={isDeleteAddressLoading}
                />
            </DetailsSheet>
        </>
    )
}
