import { AccountType, UserFlags, useUserDataQueryFn } from "../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { LoadingIcon } from "rentzz"
import { Navigate } from "react-router-dom"
import React, { ReactElement } from "react"

interface FeaturesGuardProps {
    children: ReactElement
}

export default function WhiteLabelAdminGuard({ children }: FeaturesGuardProps) {
    const { data: userData, isLoading } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)
    if (userData == null || isLoading) return <LoadingIcon />

    if (isWhitelabel && userData.accountType !== AccountType.WHITELABEL_ADMIN_USER) return <Navigate replace to={"/dashboard"} />
    return <>{children}</>
}
