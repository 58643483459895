import { CustomFile, FormProvider, RHFUpload, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ExpenseAIExtractorSchema } from "../../validations/expenseaiextractor"
import { dispatch } from "../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { sendExpenseToAIExtract, UploadExpenseExtractorAI } from "../../mutations/property/documents"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"

const UploadAIExtractExpenseForm = () => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { mutate, isPending } = sendExpenseToAIExtract()
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    const methods = useForm<UploadExpenseExtractorAI>({
        resolver: yupResolver(ExpenseAIExtractorSchema),
        mode: "onChange",
        defaultValues: {
            files: [],
        },
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    const onSubmit = useCallback(
        (_data: UploadExpenseExtractorAI) => {
            mutate(
                { data: { files: _data.files as CustomFile[] } },
                {
                    onSuccess: (data) => {
                        dispatch(
                            setEditingItem({
                                date: areInvoicingConfigurationOn ? undefined : data.date,
                                dueDate: areInvoicingConfigurationOn ? undefined : data.dueDate,
                                value: data.amount,
                                files: _data.files,
                                currencyId: data.currencyId,
                                name: data.name,
                                prefilledExpense: true,
                                invoicingData:
                                    data.date != null && data.name != null && data.name.length > 0 && data.amount != null
                                        ? {
                                              providerInvoiceDate: data.date?.toISODate(),
                                              providerInvoiceNumber: data.name,
                                              providerInvoiceAmount: data.amount,
                                          }
                                        : undefined,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.AddExpense))
                    },
                },
            )
        },
        [areInvoicingConfigurationOn],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={2} flexDirection={"column"} marginTop={2}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <RHFUpload name={"files"} disabled={isPending} multiple icon={"mdi:file-document-plus-outline"} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={() => dispatch(setModalOpen(undefined))}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            type='submit'
                            variant='contained'
                            loading={isSubmitting || isPending}
                            disabled={isSubmitting || isPending}
                        >
                            {translate("next")}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default UploadAIExtractExpenseForm
