import { AppContext, DetailsSheet, Iconify, MotionContainer, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useMemo, useState } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { PropertyFileType } from "../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../utils"
import { DateTime } from "luxon"
import { useRevisionObservationsQueryFn, useTenantRevisionObservationsQueryFn } from "../../../queries/revision"

export default function ObservationDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: ownerObservations } = useRevisionObservationsQueryFn(editingItem?.revisionId)
    const { data: tenantObservations } = useTenantRevisionObservationsQueryFn(editingItem?.revisionId)
    const currentObservation = useMemo(
        () => (context === AppContext.Owner ? ownerObservations : tenantObservations)?.find((obs) => obs.id === editingItem?.observationId),
        [tenantObservations, ownerObservations, editingItem, context],
    )
    const [expanded, setExpanded] = useState<boolean>(false)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentObservation == null) return []
        if (context === AppContext.Tenant)
            return [
                {
                    icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "see_files",
                    onClick: () => {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                observationId: currentObservation.id,
                                type: PropertyFileType.RevisionObservation,
                                name: currentObservation.title,
                                canWrite: false,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.EntityFileManager))
                    },
                },
            ]

        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !editingItem?.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditObservation))
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            observationId: currentObservation.id,
                            type: PropertyFileType.RevisionObservation,
                            name: currentObservation.title,
                            canWrite: editingItem?.canWrite,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },

            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !editingItem?.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteObservation))
                },
            },
        ]
    }, [currentObservation, dispatch, context, editingItem])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ObservationDetails}
            onClose={() => {
                dispatch(setModalOpen(AppModals.ObservationsList))
            }}
            isLoading={currentObservation == null}
            title={currentObservation?.title}
            options={options}
        >
            <Box p={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                    <Iconify icon={"mdi:information-variant"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontSize={13}
                            color={theme.palette.grey["600"]}
                            sx={{
                                overflow: "hidden",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {currentObservation?.description.substring(0, 120)}
                            {currentObservation && currentObservation?.description.length > 120 && !expanded && (
                                <>
                                    ...
                                    <Button
                                        size='small'
                                        color='primary'
                                        sx={{ marginBottom: 0.4 }}
                                        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                                    >
                                        {translate("view_all")}
                                    </Button>
                                </>
                            )}
                        </Typography>

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                <Typography
                                    fontSize={13}
                                    color={theme.palette.grey["600"]}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {currentObservation?.description.substring(121, currentObservation?.description.length - 1)}

                                    <Button size='small' color='primary' onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                                        {translate("close")}
                                    </Button>
                                </Typography>
                            </MotionContainer>
                        )}
                    </Box>
                </Box>
                {renderActionSheetInfo(
                    "mdi:calendar",
                    `${translate("date")} ${currentObservation?.observationDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-edit-outline",
                        `${translate("lastModifiedAt")} ${currentObservation?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("createdAt")} ${currentObservation?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
