import { PaymentType, PropertyIncome, PropertyIncomePaymentMethod } from "../../../redux/slices/AddProperty"
import {
    AppContext,
    CustomFile,
    FormProvider,
    LightTooltip,
    RHFDatePicker,
    RHFSelect,
    RHFUnitInput,
    RHFUpload,
    useIsMobile,
    useLocales,
} from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { UserFlags, useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserPropertiesQuery } from "../../../queries/userData"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { PropertyExpense, useInfiniteExpensesQueryFn } from "../../../queries/expenses"
import { useForm } from "react-hook-form"
import { Alert, Box, Button, MenuItem } from "@mui/material"
import RHFLazyLoadSelect from "../../../components/RHFLazyLoadSelect"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { renderMenuItem } from "../../income/utils"
import { DateTime } from "luxon"
import { useTheme } from "@mui/material/styles"
import { useIntl } from "react-intl"
import { LoadingButton } from "@mui/lab"
import { yupResolver } from "@hookform/resolvers/yup"
import { PaymentSchema } from "../../../validations/payments"
import { setErrorMessage } from "../../../redux/slices/App"

export interface AddPaymentRequest {
    id?: number
    value: number
    currencyId: number
    date: string
    type: PaymentType
    paymentMethod: PropertyIncomePaymentMethod
    referenceId?: number
    files: CustomFile[]
    propertyId: number
}
interface Props {
    defaultValues: PropertyIncome | PropertyExpense | undefined | null
    isLinkingExpense?: boolean
    onClose: () => void
    onSave: (data: AddPaymentRequest) => Promise<void>
}

export default function AddPaymentForm({ defaultValues, isLinkingExpense, onClose, onSave }: Props) {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId, context, errorMessage, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: currencies } = useCurrencyQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: userRentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: user } = useUserDataQueryFn()
    const { data: userExpenses, hasNextPage, fetchNextPage, isFetching } = useInfiniteExpensesQueryFn(false, [{ field: "date", sort: "desc" }])
    const [isNoExpense, setIsNoExpense] = useState(false)
    const [referenceHasType, setReferenceHasType] = useState(false)
    const expenseOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)

    const methods = useForm<AddPaymentRequest>({
        resolver: yupResolver(PaymentSchema),
        mode: "onChange",
        defaultValues: {
            currencyId: user?.currency.id,
            date: DateTime.now().toISO() ?? undefined,
            paymentMethod: PropertyIncomePaymentMethod.Bank,
            files: [],
            propertyId: currentPropertyId,
        },
    })

    const {
        handleSubmit,
        formState: { isSubmitting },
        watch,
        setValue,
        getValues,
        reset,
    } = methods

    const onSubmit = useCallback(
        async (data: AddPaymentRequest) => {
            await onSave({ ...data, referenceId: data.referenceId === -1 ? undefined : data.referenceId })
        },
        [onSave],
    )

    const currentTenantRentingPeriod = useMemo(
        () => userRentingPeriods?.find((r) => r.rentingPeriodId === currentRentingPeriodId),
        [userRentingPeriods, currentRentingPeriodId],
    )
    useEffect(() => {
        if (defaultValues && !isLinkingExpense) {
            reset({
                value: (defaultValues as PropertyIncome).value,
                currencyId: (defaultValues as PropertyIncome).currencyId,
                date: (defaultValues as PropertyIncome).date.toString(),
                type: (defaultValues as PropertyIncome).type,
                paymentMethod: (defaultValues as PropertyIncome).paymentMethod,
                referenceId: (defaultValues as PropertyIncome).associatedExpenses?.at(0)?.id,
                files: [],
                propertyId: (defaultValues as PropertyIncome).propertyId,
            })
            return
        }

        if (isLinkingExpense && defaultValues) {
            reset({
                type: (defaultValues as PropertyExpense).paymentType,
                value: defaultValues?.value,
                currencyId: defaultValues.currencyId,
                date: defaultValues.date.toString(),
                paymentMethod: PropertyIncomePaymentMethod.Bank,
                referenceId: defaultValues.id,
                files: [],
                propertyId: defaultValues.propertyId,
            })
            setReferenceHasType(true)
            return
        }
    }, [defaultValues, reset, isLinkingExpense])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "referenceId") {
                setIsNoExpense(v.referenceId === -1)
                if (v.referenceId === -1) {
                    setValue("type", PaymentType.Rent)
                }
                const reference = userExpenses?.pages.flatMap((r) => r.items)?.find((expense) => expense.id === v.referenceId)
                if (reference) {
                    setValue("type", reference.paymentType, { shouldValidate: true })
                    setValue("value", reference.value, { shouldValidate: true })
                    setValue("currencyId", reference.currencyId, { shouldValidate: true })
                    setReferenceHasType(true)
                    if (!currentTenantRentingPeriod?.acceptPartialPayments && reference.propertyIncomes.length >= 1) {
                        dispatch(setErrorMessage(`${translate("this_expense_has_an_assigned_payment")}`))
                    } else {
                        dispatch(setErrorMessage(undefined))
                    }
                } else {
                    setReferenceHasType(false)
                }
            }
            if (name === "type") {
                if (v.type === PaymentType.Rent) {
                    if (currentTenantRentingPeriod == null) return
                    setValue("value", currentTenantRentingPeriod.rentPrice, { shouldValidate: true })
                    setValue("currencyId", currentTenantRentingPeriod.currencyId, { shouldValidate: true })
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, userProperties, setErrorMessage, currentTenantRentingPeriod])

    const showUpload = useMemo(() => {
        if (defaultValues != null && isLinkingExpense) return true
        return defaultValues == null && !isLinkingExpense
    }, [defaultValues, isLinkingExpense])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2} justifyContent={"center"}>
                <LightTooltip title={isLinkingExpense ? translate("can_not_edit_field") : ""} arrow>
                    <RHFLazyLoadSelect
                        label={translate("add_payment_choose")}
                        name='referenceId'
                        size='small'
                        required
                        hasNext={hasNextPage}
                        isLoading={isFetching}
                        fetchNext={fetchNextPage}
                        disabled={isLinkingExpense}
                    >
                        {!currentTenantRentingPeriod?.addIncomeOnlyWithAssignedExpense && (
                            <MenuItem value={-1} style={{ textTransform: "none" }}>
                                {translate("no_expense_payment")}
                            </MenuItem>
                        )}

                        {userExpenses?.pages
                            .flatMap((r) => r.items)
                            ?.map((expense) =>
                                renderMenuItem(
                                    "",
                                    expense.name ?? "",
                                    {
                                        value: expense.id,
                                    },
                                    translate,
                                    `${getAmount(expense.value, expense.currencyId as number)}`,
                                    theme,
                                    `${translate("due_date")}: ${expense.dueDate.toLocaleString(DateTime.DATE_SHORT)}`,
                                ),
                            )}
                    </RHFLazyLoadSelect>
                </LightTooltip>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <LightTooltip title={referenceHasType ? translate("can_not_edit_field") : ""} arrow>
                        <RHFSelect disabled={referenceHasType} name='type' label={translate("income-table.choosePaymentType")} size='small'>
                            {!isNoExpense && (
                                <MenuItem value={PaymentType.Expense}>
                                    {translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-1`)}
                                </MenuItem>
                            )}
                            <MenuItem value={PaymentType.Rent}>{translate("income-table.incomeType-1")}</MenuItem>
                            <MenuItem value={PaymentType.SecurityDeposit}>{translate("income-table.incomeType-2")}</MenuItem>
                            <MenuItem value={PaymentType.Fees}>{translate("income-table.incomeType-3")}</MenuItem>
                            <MenuItem value={PaymentType.Damages}>{translate("income-table.incomeType-4")}</MenuItem>
                        </RHFSelect>
                    </LightTooltip>
                    <RHFDatePicker
                        name='date'
                        disabled={getValues("type") === undefined}
                        disableFuture
                        inputProps={{ fullWidth: true, size: "small" }}
                        label={translate("date")}
                    />
                </Box>

                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName='currencyId'
                        unitType='currency'
                        name='value'
                        size='small'
                        label={translate("amount")}
                        required
                        disabled={context === AppContext.Tenant && !currentTenantRentingPeriod?.acceptPartialPayments}
                    />
                    <RHFSelect name='paymentMethod' label={translate("income-table.paymentMethod")} size='small'>
                        <MenuItem value={PropertyIncomePaymentMethod.Cash}>{translate("income-table.paymentMethod-0")}</MenuItem>
                        <MenuItem value={PropertyIncomePaymentMethod.Bank}>{translate("income-table.paymentMethod-1")}</MenuItem>
                    </RHFSelect>
                </Box>
            </Box>
            {showUpload && (
                <RHFUpload
                    name={"files"}
                    multiple
                    maxSize={currentTenantRentingPeriod?.remainingUploadSize}
                    icon={"mdi:file-document-plus-outline"}
                />
            )}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={onClose} fullWidth={isMobile} disabled={isSubmitting}>
                    {translate("cancel")}
                </Button>
                <LoadingButton
                    color={"primary"}
                    type='submit'
                    variant='contained'
                    disabled={isSubmitting || errorMessage !== undefined}
                    loading={isSubmitting}
                    fullWidth={isMobile}
                >
                    {translate("submit")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
