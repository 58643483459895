import { DateTime } from "luxon"

export function fromIsoToDateTime(date: string) {
    return DateTime.fromISO(date, { zone: "UTC" }).setZone("local")
}

export const arrayOfNumbersToPeriod = (period: number[]) => {
    if (period.length === 1) {
        return [`${period[0]}`]
    }
    let previousDay = period[0]
    let startIndex = 0
    let currentIndex = 1
    let periods: string[] = []
    do {
        const d = period[currentIndex]
        if (!(previousDay + 1 === d)) {
            const newArray = period.slice(startIndex, currentIndex)
            if (newArray.length === 1) {
                periods.push(`${newArray[0]}`)
            } else {
                periods.push(`${newArray[0]}-${newArray[newArray.length - 1]}`)
            }
            startIndex = currentIndex
        }
        previousDay = d
        currentIndex++
    } while (currentIndex < period.length)
    if (periods.length === 0 || startIndex - 1 < currentIndex) {
        if (currentIndex - startIndex === 1 || (currentIndex === startIndex && period.length === 1)) {
            periods.push(`${period[startIndex]}`)
        } else {
            periods.push(`${period[startIndex]}-${period[currentIndex - 1]}`)
        }
    }
    if (periods.some((p) => p.endsWith("31")) && periods.some((p) => p.startsWith("1-"))) {
        // let's merge last with first
        const periodWithLast = periods.find((p) => p.endsWith("31"))
        const periodWithFirst = periods.find((p) => p.startsWith("1-"))
        if (periodWithLast && periodWithFirst) {
            periods = [
                `${periodWithLast.split("-")[0]}-${periodWithFirst.split("-")[1]}`,
                ...periods.filter((p) => !p.endsWith("31") && !p.startsWith("1-")),
            ]
        }
    }
    return periods
}
