import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import * as React from "react"
import { getIncomeOrPaymentStatusColor } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box, CircularProgress } from "@mui/material"
import { useIncomeLinkedExpensesQuery, useSingleIncomeQuery } from "../../../queries/income"
import AssignedExpenseCard from "./AssignedExpenseCard"

interface Props {
    onClose: () => void
}
export default function AssignedExpensesModal({ onClose }: Props) {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentIncome } = useSingleIncomeQuery(editingItem?.id)
    const { data: incomeExpenses, isFetching: isLoading } = useIncomeLinkedExpensesQuery(
        currentIncome?.associatedExpenses?.map((i) => i.id),
        currentIncome?.id,
    )
    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.AssignedExpenses}
            onClose={onClose}
            isLoading={currentIncome == null}
            title={translate("assigned_expenses")}
            headerColor={currentIncome ? getIncomeOrPaymentStatusColor(currentIncome.status, theme) : theme.palette.primary.main}
        >
            <>
                {isLoading ? (
                    <Box display={"flex"} justifyContent={"center"} height={300}>
                        <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <Box sx={{ overflowY: "auto", maxHeight: 500 }}>
                        <Box gap={1} display={"flex"} flexDirection={"column"} px={1} py={2}>
                            {incomeExpenses?.map((expense) => (
                                <AssignedExpenseCard
                                    key={expense.id}
                                    expense={expense}
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.ExpenseDetails))
                                        dispatch(setEditingItem({ id: editingItem?.id, expenseId: expense.id, isFromAnIncome: true }))
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </>
        </DetailsSheet>
    )
}
