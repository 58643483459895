import { Button, Card, CardHeader, Divider, Typography } from "@mui/material"
import { CustomDataGrid, Iconify, Label, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { useRentingPeriodDetails, useSingleRentingPeriodQuery } from "../../../../../../queries/tenants"
import { RentingPeriodStatusCode, RentingPeriodUser, RentingPeriodUserAcceptanceStatus } from "../../../../../../redux/slices/AddProperty"
import React, { MouseEvent, useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { getMailNotificationStatusLabelColor, getUserAcceptanceStatusLabelColor } from "../../../utils"
import ActionWithTooltip from "../../../../../../utils/ActionWithTooltip"
import { alpha, useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import AddNewInvitation from "./AddNewInvitation"
import MobileRentingPeriodTenants from "./MobileRentingPeriodTenants"
import DeleteIcon from "@mui/icons-material/Delete"
import SyncIcon from "@mui/icons-material/Sync"
import { DESKTOP_ICON_SIZE } from "../../../../../../utils/helpers"
import { VerifiedUser } from "@mui/icons-material"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../../queries/userData"

export interface NewInvitationRequest {
    rentingPeriodId: number
    email: string
    invitationLanguage: string
}
export default function RentingPeriodTenantsCard() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails, isLoading: isRentingPeriodLoading } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(currentRentingPeriodId)
    const isAcceptOnTenantBehalfEnabled = useFeatureIsOn(UserFlags.AcceptOnTenantBehalf.toString())

    const tenantsFromRentingPeriodColumns: GridColDef<RentingPeriodUser>[] = useMemo(
        () => [
            {
                field: "mail",
                type: "string",
                minWidth: 120,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("email"),
            },
            {
                field: "name",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "date",
                flex: 0.3,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                filterable: true,
                type: "number",
                headerName: translate("date"),
                valueFormatter: (value) => {
                    return (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-"
                },
            },
            {
                field: "status",
                minWidth: 100,
                flex: 0.3,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    const hasName = v.api.getRow(v.id).name !== "-"

                    return (
                        <LightTooltip
                            title={translate(hasName ? `user_acceptance_status_tooltip.${v.value}` : `mail_notification_status_tooltip.${v.value}`)}
                            arrow
                        >
                            <Label
                                color={hasName ? getUserAcceptanceStatusLabelColor(v.value) : getMailNotificationStatusLabelColor(v.value)}
                                style={{ textTransform: "none" }}
                            >
                                {translate(hasName ? `userAcceptanceStatus.${v.value}` : `mail_notification_status.${v.value}`)}
                            </Label>
                        </LightTooltip>
                    )
                },
            },
        ],
        [translate],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 100,
            type: "actions",
            headerName: translate("actions"),
            getActions: (params: GridRowParams) => {
                return [
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={
                                    params.row.name === "-"
                                        ? "info"
                                        : params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                            params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                          ? "default"
                                          : "info"
                                }
                                icon={<VerifiedUser sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.AcceptOnTenantBehalf))
                                    dispatch(setEditingItem({ mail: params.row.mail }))
                                }}
                                label={
                                    params.row.name === "-"
                                        ? translate("accept-on-tenant-behalf")
                                        : translate(
                                              params.row.status === RentingPeriodUserAcceptanceStatus.Accepted
                                                  ? "accepted_invitation_behalf"
                                                  : "accept-on-tenant-behalf",
                                          )
                                }
                                sx={{ p: 1 }}
                                disabled={
                                    params.row.name === "-"
                                        ? false
                                        : params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                          params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                }
                            />
                        ),
                        tooltipText:
                            params.row.name === "-"
                                ? translate("accept-on-tenant-behalf")
                                : translate(
                                      params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                          params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                          ? "accepted_invitation_behalf"
                                          : "accept-on-tenant-behalf",
                                  ),
                        key: "resend",
                        hidden: !rentingPeriodDetails?.canWrite || !isAcceptOnTenantBehalfEnabled,
                    }),
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={
                                    params.row.name === "-"
                                        ? "primary"
                                        : params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                            params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                          ? "default"
                                          : "primary"
                                }
                                icon={<SyncIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.ResendInvitation))
                                    dispatch(setEditingItem({ mail: params.row.mail }))
                                }}
                                label={
                                    params.row.name === "-"
                                        ? translate("resend")
                                        : translate(
                                              params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                                  params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                                  ? "accepted_invitation"
                                                  : "resend",
                                          )
                                }
                                sx={{ p: 1 }}
                                disabled={
                                    params.row.name === "-"
                                        ? false
                                        : params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ||
                                          params.row.status === RentingPeriodUserAcceptanceStatus.AcceptedButAccessRemoved
                                }
                            />
                        ),
                        tooltipText:
                            params.row.name === "-"
                                ? translate("resend")
                                : translate(params.row.status === RentingPeriodUserAcceptanceStatus.Accepted ? "accepted_invitation" : "resend"),
                        key: "resend",
                        hidden: !rentingPeriodDetails?.canWrite,
                    }),
                    ActionWithTooltip({
                        element: (
                            <GridActionsCellItem
                                color={"error"}
                                icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.RemoveInvitation))
                                    dispatch(setEditingItem({ mail: params.row.mail }))
                                }}
                                label={translate("delete")}
                                sx={{ p: 1 }}
                            />
                        ),
                        tooltipText: "",
                        key: "delete",
                        hidden: !rentingPeriodDetails?.canWrite,
                    }),
                    ActionWithTooltip({
                        element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                        tooltipText: translate("only_read_no_write"),
                        key: "unavailable",
                        hidden: rentingPeriodDetails?.canWrite,
                    }),
                ]
            },
        }),
        [translate, dispatch, isAcceptOnTenantBehalfEnabled, rentingPeriodDetails],
    )

    return (
        <Card>
            <CardHeader
                title={translate("tenants")}
                sx={{ p: 2, color: theme.palette.grey.A700 }}
                action={
                    rentingPeriodDetails?.canWrite && (
                        <LightTooltip
                            title={
                                currentRentingPeriod?.rentingPeriodStatus === RentingPeriodStatusCode.Finished
                                    ? translate("renting_period_ended_no_invite")
                                    : ""
                            }
                            arrow
                        >
                            <span>
                                <Button
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        borderRadius: 1,
                                        backgroundColor: open ? alpha(theme.palette.primary.main, 0.1) : "",
                                        ":hover": { borderRadius: 1, backgroundColor: alpha(theme.palette.primary.main, 0.1) },
                                    }}
                                    variant={"text"}
                                    color={"primary"}
                                    onClick={(event: MouseEvent<HTMLElement>) => {
                                        if (isMobile) {
                                            dispatch(setModalOpen(AppModals.InviteTenant))
                                        } else {
                                            event.stopPropagation()
                                            setOpen(event.currentTarget)
                                        }
                                    }}
                                    disabled={currentRentingPeriod?.rentingPeriodStatus === RentingPeriodStatusCode.Finished}
                                >
                                    <Iconify icon={"mdi:add"} />
                                    <Typography>{translate("tenant-data.addTenant")}</Typography>
                                </Button>
                            </span>
                        </LightTooltip>
                    )
                }
            />
            <Divider />

            {isMobile ? (
                <MobileRentingPeriodTenants />
            ) : (
                <CustomDataGrid
                    sortingMode='server'
                    columns={tenantsFromRentingPeriodColumns}
                    idKey={"mail"}
                    getRowId={(row) => row.mail}
                    actionsColumn={[actionsColumn]}
                    rows={rentingPeriodDetails?.rentingPeriodTenants ?? []}
                    infoColumn={[]}
                    gridHeight={300}
                    isLoading={isRentingPeriodLoading}
                />
            )}

            <AddNewInvitation open={open} setOpen={setOpen} />
        </Card>
    )
}
