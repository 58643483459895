import { useDispatch } from "../../../redux/store"
import { CustomDataGrid, Iconify, LoadingIcon, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import NoDataPage from "../../../components/NoDataPage"
import GeneralDocumentsToolbar from "./GeneralDocumentsToolbar"
import { GeneralDocument, useGeneralDocumentsQuery } from "../../../queries/generalDocuments"
import { useNavigate } from "react-router-dom"
import ModeEditIcon from "@mui/icons-material/ModeEdit"

export default function DesktopGeneralDocumentsList() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { data: generalDocuments, isLoading: areGeneralDocumentsLoading } = useGeneralDocumentsQuery()

    const metersColumns = useMemo(() => {
        const columns: GridColDef<GeneralDocument>[] = [
            {
                field: "name",
                type: "string",
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                flex: 0.6,
                headerName: translate("document_name"),
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "lastModifiedAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            minWidth: 50,
            flex: 0.3,
            type: "actions",
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                <ActionWithTooltip
                    key='config'
                    element={
                        <GridActionsCellItem
                            key='edit'
                            color='primary'
                            icon={<Iconify icon={"mdi:file-settings-cog"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("grid_actions.edit")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.id }))
                                navigate(`${params.id}/configure`)
                            }}
                            sx={{ p: 1 }}
                        />
                    }
                    tooltipText={translate("configure_document")}
                />,
                <ActionWithTooltip
                    key='edit'
                    element={
                        <GridActionsCellItem
                            key='edit'
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("grid_actions.edit")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                navigate(`${params.id}`)
                            }}
                            sx={{ p: 1 }}
                        />
                    }
                    tooltipText={translate("edit_document")}
                />,
                <ActionWithTooltip
                    element={
                        <GridActionsCellItem
                            key='delete'
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteGeneralDocument))
                                dispatch(setEditingItem({ id: params.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    }
                    tooltipText={""}
                    key={"delete"}
                />,
            ],
        }),
        [translate],
    )

    if (areGeneralDocumentsLoading) return <LoadingIcon />

    return (
        <>
            <GeneralDocumentsToolbar
                onAddClick={() => navigate("/document-templates/general-documents/new")}
                onLearnMore={() => {
                    dispatch(setModalOpen(AppModals.ShowLearnMore))
                }}
                numberOfItems={generalDocuments?.count}
                canExport={false}
                onUploadFile={() => dispatch(setModalOpen(AppModals.UploadGeneralDocument))}
            />
            <CustomDataGrid
                columns={metersColumns}
                rows={generalDocuments?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage icon={"mdi:file-document-box-remove"} noDataText={"no_general_documents"} />,
                }}
                infoColumn={[]}
                idKey={"id"}
                actionsColumn={[actionsColumn]}
            />
        </>
    )
}
