import React, { ReactElement, ReactNode } from "react"
import { LightTooltip, useIsMobile } from "rentzz"

interface Props {
    element: ReactElement
    tooltipText: ReactNode
    key?: string
    hidden?: boolean
}
const ActionWithTooltip = ({ element, tooltipText, key, hidden }: Props) => {
    const isMobile = useIsMobile()

    if (hidden) return <></>

    if (element && !isMobile) {
        return (
            <LightTooltip key={key} title={tooltipText} enterTouchDelay={isMobile ? 1 : undefined} arrow sx={{ whiteSpace: "pre-wrap" }}>
                <span>{element}</span>
            </LightTooltip>
        )
    } else {
        return element
    }
}

export default ActionWithTooltip
