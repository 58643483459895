import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useUserProvidersQuery } from "../../../../../queries/userData"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deleteCustomProviderMutation } from "../../../../../mutations/user"

export default function DeleteProviderGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteProvider, isPending: isDeleteProviderPending } = deleteCustomProviderMutation()
    const { data: providers } = useUserProvidersQuery()

    const currentLabel = useMemo(() => providers?.find((provider) => provider.id === editingItem?.id), [providers, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.CustomProviderDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentLabel == null) return
        await deleteProvider(currentLabel.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentLabel, deleteProvider, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete_provider")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteCustomProvider}
                onClose={onClose}
                isFullScreen={false}
                variantCloseButton={"text"}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_provider_content", { name: currentLabel?.name })}
                    isLoading={isDeleteProviderPending}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteCustomProvider}
                onClose={onClose}
                isLoading={false}
                title={translate("delete_provider")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_provider_content", { name: currentLabel?.name })}
                    isLoading={isDeleteProviderPending}
                />
            </DetailsSheet>
        </>
    )
}
