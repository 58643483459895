import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useUserDataQueryFn } from "../../../../queries/userData"

interface Props {
    title: string
    value: string | number | undefined
    onAdd?: () => void
    tooltipTitle?: string
    valueTooltipTitle?: string
}
export default function DetailRow({ title, value, onAdd, tooltipTitle, valueTooltipTitle }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    return (
        <Box display='flex' flexDirection='row' alignItems={"center"}>
            <Box flex={1}>
                <Typography letterSpacing={0.5} textTransform='uppercase' fontWeight={800} fontSize={13} color={theme.palette.primary.dark}>
                    {translate(title)}
                </Typography>
            </Box>

            <Box display='flex' flex={1} justifyContent='flex-end' alignItems={"center"} gap={1}>
                <Tooltip title={valueTooltipTitle}>
                    <Typography variant={"body2"}>{value}</Typography>
                </Tooltip>

                {onAdd && (
                    <Tooltip title={translate(tooltipTitle)} arrow>
                        <IconButton color={"primary"} onClick={onAdd}>
                            <Iconify icon={"mdi:plus-circle-outline"} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Box>
    )
}
