import { useTenantRevisionsQuery } from "../../../queries/revision"
import { CustomDataGrid, Label, LoadingIcon, useLocales } from "rentzz"
import React, { useCallback, useEffect, useMemo } from "react"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro"
import { RevisionStatus, TenantRevision } from "../../../redux/slices/AddProperty"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { Typography } from "@mui/material"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"
import ObservationsDetailsPanel from "../../propertyDetails/revision/ObservationsDetailsPanel"
import CustomDetailPanelToggle from "../../expenses/CustomDetailPanelToggle"

export default function DesktopTenantRevisionsList() {
    const { translate } = useLocales()
    const { data: revisions, isFetching } = useTenantRevisionsQuery()
    const api = useGridApiRef()

    useEffect(() => {
        if (revisions?.length === 0 && api.current?.setRows) {
            api.current?.setRows([])
        }
    }, [revisions, isFetching])

    const columns = useMemo(() => {
        const columns: GridColDef<TenantRevision>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"see_observations"} />
                },
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("title"),
            },
            {
                field: "description",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("description"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 25)}</Typography>,
                        tooltipText: v.value.length > 25 ? v.value : "",
                    }),
            },
            {
                field: "tenantChosenDate",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("revisionDate"),
                filterable: true,
                valueFormatter: (value) => ((value as DateTime | undefined) == null ? "-" : (value as DateTime).toLocaleString(DateTime.DATE_SHORT)),
            },
            {
                field: "status",
                minWidth: 140,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("payment.status"),
                renderCell: (v) => {
                    return (
                        <Label color={v.value === RevisionStatus.Done ? "primary" : "warning"} style={{ textTransform: "none" }}>
                            {translate(v.value === RevisionStatus.Done ? "completed" : "pending")}
                        </Label>
                    )
                },
            },
        ]

        return columns
    }, [translate])

    const getObservationsPanel = useCallback((row: GridRowParams<TenantRevision>) => {
        return <ObservationsDetailsPanel currentRevision={row.row} />
    }, [])

    if (revisions == null || isFetching) return <LoadingIcon />

    return (
        <>
            <CustomDataGrid
                apiRef={api}
                isLoading={isFetching}
                columns={columns}
                rows={revisions ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:wrench-clock"} noDataText={"no_tenant_revisions"} />,
                }}
                idKey={"id"}
                sortingMode='server'
                totalCount={revisions.length}
                actionsColumn={[]}
                getRowId={(row) => row.id}
                getDetailPanelContent={getObservationsPanel}
            />
        </>
    )
}
