import React, { useEffect, useMemo, useRef } from "react"
import { Box, CircularProgress, Fab, Typography } from "@mui/material"
import CustomAddCard from "../../../../../../components/CustomAddCard"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import { useInfiniteNotesQueryFn } from "../../../../../../queries/notes"
import { AppContext, Iconify, LoadingIcon, useLocales } from "rentzz"
import NoteCustomCard from "./NoteCustomCard"
import { sum } from "lodash"
import NoDataPage from "../../../../../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../../../../../hooks/usePermissions"
import { useTheme } from "@mui/material/styles"

export default function MobileNotesList() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { context } = useSelector((state) => state.appState)
    const { data: userNotes, fetchNextPage, hasNextPage } = useInfiniteNotesQueryFn([{ field: "addedAt", sort: "desc" }])
    const { modalOpen } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Properties)
    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (sum(userNotes?.pages.flatMap((p) => p.items.length)) === 0 && context === AppContext.Tenant)
        return <NoDataPage icon={"mdi:file-minus"} noDataText={"notes.tenantNoNotes"} />

    if (userNotes == null) return <LoadingIcon />

    return (
        <>
            <Box
                gap={1}
                display={"flex"}
                flexDirection={"column"}
                style={{ height: "inherit", paddingBottom: 55 }}
                paddingX={context === AppContext.Tenant ? 1 : undefined}
            >
                {context === AppContext.Tenant && userNotes.pages.some((p) => p.items.length > 0) && (
                    <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                        {translate("notes.title")}
                    </Typography>
                )}

                {userNotes.pages.some((p) => p.items.length > 0) && context === AppContext.Owner && modalOpen == null && canWrite && (
                    <Fab
                        onClick={() => dispatch(setModalOpen(AppModals.AddNote))}
                        sx={{ position: "absolute", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                {userNotes.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((note) => (
                            <NoteCustomCard
                                key={note.noteId}
                                note={note}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.NoteDetails))
                                    dispatch(setEditingItem({ id: note.noteId }))
                                }}
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {userNotes.pages.some((p) => p.items.length === 0) && context === AppContext.Owner && canWrite && (
                    <CustomAddCard onCardClick={() => dispatch(setModalOpen(AppModals.AddNote))} icon={"mdi:file-plus"} text={"notes.addNote"} />
                )}
            </Box>
        </>
    )
}
