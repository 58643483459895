import { number, object, string } from "yup"

export const SelectProviderSchema = object().shape({
    providerId: string().required("provider_required"),
    propertyId: number().required(),
    meterId: number().optional(),
})

export const CreateNewProviderSchema = object().shape({
    name: string().required("required_name"),
})
