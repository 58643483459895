import { useIsMobile, useLocales } from "rentzz"
import { Box, Button, Checkbox, Typography } from "@mui/material"
import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { useIntl } from "react-intl"
import { arrayOfNumbersToPeriod } from "../../../../utils/dateMagic"
import { DateTime } from "luxon"
import { Document, usePDF } from "@react-pdf/renderer"
import { useUserContractTemplatesQuery } from "../../../../queries/userData"
import OwnerFinalPreview from "../../../contracts/livePreview/OwnerFinalPreview"
import { AddTenantRequest } from "../../../types/AddTenant"

interface CheckContractProps {
    onNext: () => void
    data: AddTenantRequest
    onBack: () => void
}

export default function CheckContract({ onNext, data, onBack }: CheckContractProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: contractTemplates } = useUserContractTemplatesQuery(false)
    const { data: currencyData } = useCurrencyQueryFn()
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const intl = useIntl()

    const currentContractTemplate = useMemo(
        () => contractTemplates?.find((template) => template.id === data.contractData?.contractId),
        [data, contractTemplates],
    )

    const getRentPrice = useCallback(() => {
        if (data.rentDetails == null) return ""
        return intl.formatNumber(Number(data.rentDetails.value), {
            style: "currency",
            currency: getCurrencyFromId(currencyData, data.rentDetails.currencyId).code,
        })
    }, [data, currencyData, intl])

    const getSecurityDepositPrice = useCallback(() => {
        if (
            data.rentDetails == null ||
            data.rentDetails.securityDeposit == null ||
            data.rentDetails.securityDepositCurrencyId == null ||
            data.rentDetails.securityDeposit === 0
        )
            return ""
        return intl.formatNumber(Number(data.rentDetails.securityDeposit), {
            style: "currency",
            currency: getCurrencyFromId(currencyData, data.rentDetails.securityDepositCurrencyId).code,
        })
    }, [data, currencyData, intl])

    const getPaymentPeriod = useCallback(() => {
        if (data.rentDetails == null) return ""
        return arrayOfNumbersToPeriod(data.rentDetails.paymentPeriod).join(", ")
    }, [data.rentDetails])

    const moveOutDate = useCallback(() => {
        if (data.details == null) return ""

        return DateTime.fromISO(data.details.moveOutDate).toLocaleString(DateTime.DATE_SHORT)
    }, [data])

    const moveInDate = useCallback(() => {
        if (data.details == null) return ""

        return DateTime.fromISO(data.details.moveInDate).toLocaleString(DateTime.DATE_SHORT)
    }, [data])

    const getDocument = useCallback(
        () => (
            <Document>
                <OwnerFinalPreview
                    rentPrice={getRentPrice()}
                    securityDeposit={getSecurityDepositPrice()}
                    paymentPeriod={getPaymentPeriod()}
                    moveInDate={moveInDate()}
                    moveOutDate={moveOutDate()}
                    ownerInfo={data?.ownerContactInfo}
                    tenantRequiredInfo={currentContractTemplate?.tenantRequiredInfo}
                    litigation={currentContractTemplate?.litigation}
                    partiesRequirements={currentContractTemplate?.partiesRequirements}
                    tenantObligations={currentContractTemplate?.tenantObligations}
                    ownerObligations={currentContractTemplate?.ownerObligations}
                    propertyData={data.propertyInfo}
                    propertyRequiredData={currentContractTemplate?.propertyDataRequiredInfo}
                    ownerSignature={data.contractData?.signature}
                    hasGDPR={currentContractTemplate?.hasGDPR}
                    bankAccount={data.ownerContactInfo?.bankAccount}
                    bankName={data.ownerContactInfo?.bankName}
                />
            </Document>
        ),
        [data, currentContractTemplate],
    )

    const [instance, update] = usePDF({ document: getDocument() })

    const handlePDFDownloadClick = useCallback(() => {
        update(getDocument())
        if (instance.url == null) return

        window.open(instance.url, "_blank")
    }, [update, instance, getDocument])

    return (
        <Box>
            <Box display='flex' flexDirection='column' alignItems='center' gap={3} py={3}>
                <Typography variant='subtitle1' align='center'>
                    {translate("check_contract_details", { context: translate("lessee") })}
                </Typography>
                <Button onClick={handlePDFDownloadClick} variant='contained' sx={{ width: 300 }} color={"primary"}>
                    {translate("view_contract")}
                </Button>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Checkbox value={isChecked} onChange={() => setIsChecked(!isChecked)} />
                <Typography fontSize={14}>{translate("contract_is_ok")}</Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }} flexDirection={isMobile ? "column-reverse" : "row"}>
                <Button color={"primary"} onClick={onBack}>
                    {translate("back")}
                </Button>
                <Button color={"primary"} onClick={onNext} variant='contained' disabled={!isChecked}>
                    {translate("next")}
                </Button>
            </Box>
        </Box>
    )
}
