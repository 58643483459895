import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { usePropertyDetailsQueryFn } from "../../../queries"
import MapPicker from "../../../dialogs/add-edit-property/steps/MapPicker"

export default function EditPropertyLocationGuard() {
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: location } = usePropertyDetailsQueryFn()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate("change_pin")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.EditPropertyLocation}
                maxWidth='sm'
                isFullScreen={isMobile}
                titleAlign={"center"}
            >
                <MapPicker
                    afterSubmit={onClose}
                    isEditDialogOpen={modalOpen === AppModals.EditPropertyLocation}
                    closeDialog={onClose}
                    defaultLng={location?.lng}
                    defaultLat={location?.lat}
                    defaultMarkerLng={editingItem?.lng}
                    defaultMarkerLat={editingItem?.lat}
                />
            </CustomDialog>
            <DetailsSheet
                title={translate("change_pin")}
                isOpen={isMobile && modalOpen === AppModals.EditPropertyLocation}
                onClose={onClose}
                isLoading={false}
            >
                <MapPicker
                    afterSubmit={onClose}
                    isEditDialogOpen={modalOpen === AppModals.EditPropertyLocation}
                    closeDialog={onClose}
                    defaultLng={location?.lng}
                    defaultLat={location?.lat}
                    defaultMarkerLng={editingItem?.lng}
                    defaultMarkerLat={editingItem?.lat}
                />
            </DetailsSheet>
        </>
    )
}
