import { useTheme } from "@mui/material/styles"
import { AppContext, DetailsSheet, Iconify, IKanbanTask, MotionContainer, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import React, { useCallback, useMemo, useState } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, Theme, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useAllSectionsQueryFn, useSectionItemsQueryFn, useTenantTasksQueryFn } from "../../../../queries/kanban"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { useSingleRentingPeriodQuery } from "../../../../queries/tenants"
import TaskAssignee from "../TaskAssignee"
import { useSetAtom } from "jotai/index"
import { showTaskAssignDialogAtom } from "../../../../utils/atom"
import { Add } from "@mui/icons-material"
import TaskDeadlineForm from "../TaskDeadlineForm"

export const getDeadlineSheetColor = (task: IKanbanTask, theme: Theme, isFinalSection: boolean) => {
    if (task.deadline == null || isFinalSection) {
        return theme.palette.primary.main
    }
    if (task.deadline.diffNow("day").days > 1 && task.deadline.diffNow("day").days <= 4) {
        return theme.palette.warning.main
    }
    if (task.deadline.diffNow("day").days <= 1) {
        return theme.palette.error.main
    }

    return theme.palette.primary.main
}

export default function TaskDetailsSheet() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { editingItem, modalOpen, context } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const { data: allSections } = useAllSectionsQueryFn()
    const { data: ownerTasks } = useSectionItemsQueryFn(editingItem?.sectionId)
    const { data: tenantTasks } = useTenantTasksQueryFn(editingItem?.rentingPeriodId)
    const [expanded, setExpanded] = useState<boolean>(false)
    const setShowAssignDialog = useSetAtom(showTaskAssignDialogAtom)

    const tasks = useMemo(() => (context === AppContext.Owner ? ownerTasks : tenantTasks), [context, ownerTasks, tenantTasks])
    const currentTask = useMemo(() => tasks?.find((task) => task.id === editingItem?.taskId), [tasks, editingItem])
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(currentTask?.rentingPeriodId)
    const currentSection = useMemo(() => allSections?.find((section) => section.taskSectionId === editingItem?.sectionId), [allSections, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentTask == null) return []

        const canEdit = context === AppContext.Owner ? currentSection?.canEdit : currentTask.canEdit

        return [
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_comments",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.TaskComments))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !canEdit,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditTask))
                },
            },
            {
                icon: <Add color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.add_assignee",
                hidden: !canEdit || context === AppContext.Tenant,
                onClick: () => {
                    setShowAssignDialog(true)
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            sectionId: currentTask.sectionId,
                            type: PropertyFileType.TaskFile,
                            name: currentTask.name,
                            canWrite: canEdit,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !canEdit,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteTask))
                },
            },
        ]
    }, [currentSection, dispatch, currentTask, editingItem, context])

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    const rentingPeriod = useCallback(() => {
        if (currentTask?.rentingPeriodId == null) return translate("no_renting_period")
        return `${currentRentingPeriod?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${currentRentingPeriod?.moveOutDate.toLocaleString(
            DateTime.DATE_SHORT,
        )}`
    }, [currentRentingPeriod, currentTask])

    if (currentTask == null || currentSection == null) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.TaskDetails}
            onClose={onClose}
            isLoading={false}
            title={currentTask?.name}
            options={options}
            subtitle={
                <Box display='flex' flexDirection='column'>
                    {currentTask.deadline != null
                        ? translate("task_deadline") + ": " + currentTask.deadline.toLocaleString(DateTime.DATE_SHORT)
                        : null}
                    <Typography variant='caption'>{currentSection?.name}</Typography>
                </Box>
            }
            headerColor={getDeadlineSheetColor(currentTask, theme, currentSection?.isFinal ?? false)}
            isMultiRow
        >
            <Box p={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                    <Iconify icon={"mdi:information-variant"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontSize={13}
                            color={theme.palette.grey["600"]}
                            sx={{
                                overflow: "hidden",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {currentTask?.description?.substring(0, 120)}
                            {currentTask?.description && currentTask.description.length > 120 && !expanded && (
                                <>
                                    ...
                                    <Button
                                        size='small'
                                        color='primary'
                                        sx={{ marginBottom: 0.4 }}
                                        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                                    >
                                        {translate("view_all")}
                                    </Button>
                                </>
                            )}
                        </Typography>

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                <Typography
                                    fontSize={13}
                                    color={theme.palette.grey["600"]}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {currentTask?.description?.substring(121, currentTask?.description.length - 1)}

                                    <Button size='small' color='primary' onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                                        {translate("close")}
                                    </Button>
                                </Typography>
                            </MotionContainer>
                        )}
                    </Box>
                </Box>
                {context === AppContext.Owner &&
                    renderActionSheetInfo("mdi:user", <TaskAssignee task={currentTask!} />, theme.palette.primary.main, theme.palette.grey["600"])}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-month",
                        <TaskDeadlineForm
                            taskId={currentTask.id!}
                            deadline={currentTask.deadline}
                            canEdit={currentTask.canEdit}
                            sectionId={currentTask.sectionId}
                        />,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {renderActionSheetInfo(
                    "mdi:user",
                    `${translate("createdBy")}: ${currentTask?.createdBy}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("createdAt")}: ${currentTask?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:map-marker",
                        properties?.find((p) => p.id === currentTask?.propertyId)?.label,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:calendar-search",
                        `${translate("tenant-data.rentingPeriod")}: ${rentingPeriod()}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
