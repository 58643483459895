import { FormProvider, RHFTextField, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { updateRentingPeriodMutation } from "../../../../mutations/tenants"
import { object, string } from "yup"
import { Operation } from "fast-json-patch"
import { useRentingPeriodDetails } from "../../../../queries/tenants"
import { useSelector } from "../../../../redux/store"

interface Props {
    onClose: () => void
}

export interface ExternalSystemCodeNameChangeRequest {
    newExternalSystemCodeName: string
}

export const ExternalSystemCodeNameSchema = object().shape({
    newExternalSystemCodeName: string().required("external_system_code_name_required").min(3, "minimum_characters"),
})
export default function EditExternalSystemCodeNameForm({ onClose }: Props) {
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { mutateAsync: editExternalSystemCode, isPending: isEditExternalSystemCode } = updateRentingPeriodMutation()

    const methods = useForm<ExternalSystemCodeNameChangeRequest>({
        resolver: yupResolver(ExternalSystemCodeNameSchema),
        mode: "onBlur",
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: ExternalSystemCodeNameChangeRequest) => {
            const operations: Operation[] = [{ op: "replace", path: "/externalSystemId", value: data.newExternalSystemCodeName }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await editExternalSystemCode({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
            onClose()
        },
        [rentingPeriodDetails, onClose],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={1} flexDirection={"column"}>
                <RHFTextField name={"newExternalSystemCodeName"} label={translate("new_external_system_code_name")} />
                <LoadingButton loading={isEditExternalSystemCode} disabled={isEditExternalSystemCode} color={"primary"} type={"submit"}>
                    {translate("update")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
