import { Box, Button, Typography } from "@mui/material"
import { Iconify, LightTooltip, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import React, { ReactNode } from "react"

interface NoDataPageProps {
    icon?: string
    noDataText: string
    onAddNewItem?: () => void
    textButton?: string
    tooltipText?: string
    isDisabled?: boolean
    paddingTop?: number
    paddingY?: number
    children?: ReactNode
}
export default function NoDataPage({
    onAddNewItem,
    isDisabled,
    icon,
    noDataText,
    tooltipText,
    textButton,
    paddingTop,
    children,
    paddingY,
}: NoDataPageProps) {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={paddingTop}
            paddingY={paddingY ?? undefined}
            height={"100%"}
        >
            {icon && <Iconify icon={icon} width={40} height={40} color={theme.palette.primary.main} />}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography textAlign={"center"} sx={{ color: "text.secondary" }} fontWeight={"bold"} fontSize={17}>
                    {translate(noDataText)}
                </Typography>
            </Box>
            {onAddNewItem && (
                <LightTooltip title={isDisabled ? translate(tooltipText) : ""} arrow>
                    <span
                        onClick={async (event: React.MouseEvent<HTMLElement>) => {
                            event.stopPropagation()
                            event.preventDefault()
                        }}
                    >
                        <Button
                            disabled={isDisabled}
                            size={"large"}
                            variant={"contained"}
                            color={"primary"}
                            sx={{ textTransform: "none", marginTop: 2 }}
                            onClick={onAddNewItem}
                        >
                            {translate(textButton)}
                        </Button>
                    </span>
                </LightTooltip>
            )}
            {children}
        </Box>
    )
}
