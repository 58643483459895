import React, { ReactNode } from "react"
import { Navigate } from "react-router-dom"
import { AccountType, useUserDataQueryFn } from "../queries/userData"

interface PropertiesGuardProps {
    children: ReactNode
}
export default function WhitelabelUserGuard({ children }: PropertiesGuardProps) {
    const { data: userData } = useUserDataQueryFn()
    if (userData?.accountType === AccountType.WHITELABEL_USER) return <Navigate to='/' replace />
    return <>{children}</>
}
