import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, Iconify, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { DateTime } from "luxon"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../utils"
import { useGeneralDocumentsQuery } from "../../../../queries/generalDocuments"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useNavigate } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"

export default function GeneralDocumentDetailsSheet() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: generalDocuments } = useGeneralDocumentsQuery()
    const currentDocument = useMemo(() => generalDocuments?.items.find((doc) => doc.id === editingItem?.id), [generalDocuments, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentDocument == null) return []
        return [
            {
                icon: <Iconify icon={"mdi:file-settings-cog"} sx={{ fontSize: MOBILE_ICON_SIZE, color: theme.palette.primary.main }} />,
                title: "configure_document",
                onClick: () => {
                    dispatch(setEditingItem(undefined))
                    dispatch(setModalOpen(undefined))
                    navigate(`/document-templates/general-documents/${currentDocument.id}/configure`)
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                    navigate(`/document-templates/general-documents/${currentDocument.id}`)
                },
            },

            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteGeneralDocument))
                },
            },
        ]
    }, [currentDocument, dispatch, theme, navigate])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.GeneralDocumentDetails}
            onClose={onClose}
            isLoading={currentDocument == null}
            title={currentDocument?.name}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("lastModifiedAt")}: ${currentDocument?.lastModifiedAt.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("createdAt")}: ${currentDocument?.createdAt.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
