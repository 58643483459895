import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import AddOrEditUtilitiesAndFeaturesForm from "../../../sections/marketing/AddOrEditUtilitiesAndFeaturesForm"
import { usePropertyDetailsQueryFn } from "../../../queries"

export default function AddOrEditPropertyUtilitiesAndFeaturesGuard() {
    const { modalOpen } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate(
                    propertyDetails?.marketingSettings?.features?.length === 0 && propertyDetails?.marketingSettings?.utilities?.length === 0
                        ? "add_utilities_and_properties"
                        : "edit_utilities_and_features",
                )}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.UpdatePropertyFeaturesAndUtilities}
                maxWidth='sm'
                isFullScreen={isMobile}
                titleAlign={"center"}
            >
                <AddOrEditUtilitiesAndFeaturesForm onClose={onClose} />
            </CustomDialog>
            <DetailsSheet
                title={translate(
                    propertyDetails?.marketingSettings?.features?.length === 0 && propertyDetails?.marketingSettings?.utilities?.length === 0
                        ? "add_utilities_and_properties"
                        : "edit_utilities_and_features",
                )}
                isOpen={isMobile && modalOpen === AppModals.UpdatePropertyFeaturesAndUtilities}
                onClose={onClose}
                isLoading={false}
            >
                <AddOrEditUtilitiesAndFeaturesForm onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
