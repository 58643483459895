import { Box } from "@mui/material"
import { RHFAutocomplete, RHFTextField, useLocales } from "rentzz"
import React, { useEffect, useState } from "react"
import { useCitiesQueryFn, useCountiesQueryFn } from "../../../../queries"
import { useFormContext } from "react-hook-form"

interface AddressProps {
    isDisabled?: boolean
}

export default function Address({ isDisabled }: AddressProps) {
    const { translate } = useLocales()
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const { watch } = useFormContext()
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "county") {
                setCounty(value.county)
            }
        })

        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <>
            <Box display={"flex"} gap={2}>
                <RHFAutocomplete
                    name={"county"}
                    textFieldProps={{ size: "small" }}
                    required
                    options={allCounties?.map((c) => c.name).concat([""]) ?? [""]}
                    label={translate("county")}
                    loading={allCountiesFetching}
                    autocompleteProps={{
                        size: "small",
                        disabled: isDisabled,
                        noOptionsText: translate("no_value_found"),
                    }}
                    disabled={isDisabled}
                    freeSolo={false}
                />
                <RHFAutocomplete
                    required
                    name={"city"}
                    options={allCities?.map((c) => c.name).concat([""]) ?? [""]}
                    loading={allCitiesFetching}
                    label={translate("city")}
                    autocompleteProps={{
                        disabled: allCities == null || allCities.length === 0 || isDisabled,
                        size: "small",
                        noOptionsText: translate("no_value_found"),
                    }}
                    freeSolo={false}
                    disabled={isDisabled}
                />
            </Box>
            <RHFTextField name={"address"} size='small' label={translate("address")} required disabled={isDisabled} />
        </>
    )
}
