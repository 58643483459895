import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Box, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SyncPropertiesSchema } from "../../validations/syncProperties"
import { updateUserImoCRMAgencyIdMutation } from "../../mutations/user"
import { useUserDataQueryFn } from "../../queries/userData"

interface Props {
    onClose: () => void
}
export type SyncImoCRMAgencyIdRequest = {
    agencyId: string
}
export default function SyncPropertiesForm({ onClose }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { mutateAsync: updateImoCRMAgencyId, isPending: isUpdateImoCRMAgencyIdLoading } = updateUserImoCRMAgencyIdMutation()
    const { data: user } = useUserDataQueryFn()

    const methods = useForm<SyncImoCRMAgencyIdRequest>({
        resolver: yupResolver(SyncPropertiesSchema),
        mode: "onBlur",
        defaultValues: {
            agencyId: "",
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: SyncImoCRMAgencyIdRequest) => {
            await updateImoCRMAgencyId(data)
            onClose()
        },
        [updateImoCRMAgencyId, onClose],
    )
    const onDeactivate = useCallback(async () => {
        await updateImoCRMAgencyId(null)
        onClose()
    }, [updateImoCRMAgencyId, onClose])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between' gap={2}>
                <Typography sx={{ color: theme.palette.grey.A700 }} textAlign={isMobile ? "center" : undefined}>
                    {translate(user?.imoCRMAgency == null ? "sync-properties-content" : "deactivate-sync-properties-content")}
                </Typography>
                {user?.imoCRMAgency == null && <RHFTextField name={"agencyId"} label={translate("agency-id")} />}
                <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' alignItems='center' gap={2}>
                    <LoadingButton fullWidth={isMobile} color='primary' onClick={onClose} disabled={isUpdateImoCRMAgencyIdLoading}>
                        {translate("cancel")}
                    </LoadingButton>
                    <LoadingButton
                        fullWidth={isMobile}
                        variant='contained'
                        color={"primary"}
                        loading={isUpdateImoCRMAgencyIdLoading}
                        disabled={isUpdateImoCRMAgencyIdLoading}
                        type={user?.imoCRMAgency == null ? "submit" : undefined}
                        onClick={user?.imoCRMAgency != null ? () => onDeactivate() : undefined}
                    >
                        {user?.imoCRMAgency == null ? translate("active-sync") : translate("deactivate-sync")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
