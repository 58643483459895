import { FormProvider, useIsMobile, useLocales } from "rentzz"
import { PaymentType, PropertyIncome } from "../../../redux/slices/AddProperty"
import React, { useCallback, useEffect, useState } from "react"
import { Path, useForm } from "react-hook-form"
import { AddIncomeRequest } from "./types"
import { yupResolver } from "@hookform/resolvers/yup"
import { IncomeSchema } from "../../../validations/income"
import ChooseIncomeOrPaymentTypePage from "./ChooseIncomeOrPaymentTypePage"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import AddIncomeTypeExpenseForm from "./AddIncomeTypeExpenseForm"
import AddIncomeTypeRentForm from "./AddIncomeTypeRentForm"
import { AppForm, FIELDS_TO_RESET } from "../../../hooks/useFormResetter"
import { useUserDataQueryFn } from "../../../queries/userData"
import { PropertyExpense } from "../../../queries/expenses"

interface AddIncomeContentProps {
    onClose: () => void
    defaultValues: PropertyIncome | PropertyExpense | undefined | null
    onSave: (data: AddIncomeRequest) => Promise<void>
    isLinkingExpense: boolean
    isLoading: boolean
}
export default function AddIncomeContent({ onSave, isLinkingExpense, defaultValues, onClose, isLoading }: AddIncomeContentProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [type, setType] = useState<PaymentType | undefined>(undefined)
    const { data: userData } = useUserDataQueryFn()

    const resetFields = useCallback((form: AppForm, field: Path<AddIncomeRequest>) => {
        const fieldToResetForCurrentForm = FIELDS_TO_RESET[form][field] as Path<AddIncomeRequest>[]
        if (fieldToResetForCurrentForm == null) throw new Error("No fields configured for this reset")

        fieldToResetForCurrentForm.forEach((f) => resetField(f))
    }, [])

    const methods = useForm<AddIncomeRequest>({
        resolver: yupResolver(IncomeSchema),
        mode: "onChange",
    })

    const { handleSubmit, watch, resetField, setValue } = methods

    useEffect(() => {
        if (!defaultValues) return
        if (isLinkingExpense && defaultValues) {
            setType(PaymentType.Expense)
        }
        if ("type" in defaultValues) {
            setType(defaultValues.type)
        }
    }, [defaultValues, isLinkingExpense])

    const onSubmit = useCallback(
        async (data: AddIncomeRequest) => {
            await onSave(data)
        },
        [onSave],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "propertyId") {
                resetFields(AppForm.AddIncome, "propertyId")
                setValue("currencyId", userData?.currency.id ?? 0)
            }
            if (name === "type") setType(v.type)
        })
        return () => subscription.unsubscribe()
    }, [watch, resetFields, setValue, userData])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!defaultValues && type == null ? (
                <ChooseIncomeOrPaymentTypePage onClose={onClose} />
            ) : (
                <>
                    {type === PaymentType.Expense ? (
                        <AddIncomeTypeExpenseForm defaultValues={defaultValues as PropertyIncome} isLinkingExpense={isLinkingExpense} />
                    ) : (
                        <AddIncomeTypeRentForm defaultValues={defaultValues as PropertyIncome} />
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button
                            color={"primary"}
                            disabled={isLoading}
                            onClick={() => {
                                if (defaultValues) {
                                    onClose()
                                } else {
                                    resetField("referenceId")
                                    resetField("rentingPeriodId")
                                    resetField("value")
                                    resetField("paymentMethod")
                                    resetField("propertyId")
                                    resetField("date")
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setValue("type", undefined)
                                }
                            }}
                            fullWidth={isMobile}
                        >
                            {translate(defaultValues ? "cancel" : "back")}
                        </Button>
                        <LoadingButton
                            color={"primary"}
                            type='submit'
                            variant='contained'
                            disabled={isLoading}
                            loading={isLoading}
                            fullWidth={isMobile}
                        >
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </>
            )}
        </FormProvider>
    )
}
