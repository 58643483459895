import { StyleSheet } from "@react-pdf/renderer"
import { useTheme } from "@mui/material/styles"
import { useMemo } from "react"

export const useStyles = () => {
    const theme = useTheme()
    return useMemo(
        () =>
            StyleSheet.create({
                page: {
                    flexDirection: "column",
                    paddingHorizontal: 50,
                    paddingTop: 50,
                    gap: 5,
                },
                mainTitle: {
                    fontSize: 20,
                    fontWeight: "bold",
                    textAlign: "center",
                },
                date: {
                    fontSize: 12,
                    textAlign: "center",
                },
                partiesTitle: {
                    fontSize: 14,
                    paddingVertical: 10,
                    textAlign: "left",
                },
                mainText: {
                    fontSize: 13,
                    textAlign: "left",
                },
                boldFont: {
                    fontFamily: "Times-Bold",
                },
                bullet: {
                    paddingHorizontal: 35,
                    fontSize: 13,
                    textAlign: "left",
                },
                userFilledText: {
                    fontWeight: "bold",
                },
                focused: {
                    border: 2,
                    borderRadius: 5,
                    marginLeft: -15,
                    paddingLeft: 15,
                    marginRight: -15,
                    paddingRight: 15,
                    borderColor: "#00AB55",
                },
            }),
        [theme],
    )
}
export default useStyles
