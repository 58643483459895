import { Box, BoxProps, Paper, Skeleton } from "@mui/material"

export default function TaskSkeleton({ ...other }: BoxProps) {
    return (
        <Box gridTemplateColumns='repeat(4, 1fr)' {...other}>
            <Paper variant='outlined' sx={{ p: 1 }} style={{ display: "flex", flexDirection: "column", width: 280, height: 90 }}>
                <Box width={100}>
                    <Skeleton variant='text' sx={{ fontSize: 10 }} />
                </Box>
                <Box width='100%'>
                    <Skeleton variant='text' height={40} />
                </Box>
                <Box width={30}>
                    <Skeleton variant='text' height={20} sx={{ fontSize: 4 }} />
                </Box>
            </Paper>
        </Box>
    )
}
