import { Page, useIsMobile, useLocales } from "rentzz"
import { Box, Paper, Typography } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { alpha, styled, useTheme } from "@mui/material/styles"
import ChooseCardPage from "../income/add-income/ChooseCardPage"
import { DateTimePicker } from "@mui/x-date-pickers-pro"
import { DateTime } from "luxon"
import { useTenantResponseMutation } from "../../mutations/tenants"
import { useTenantRentingPeriodsQuery } from "../../queries/userData"
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"

export enum ExpirationType {
    Extend,
    End,
}

export enum ExpirationPage {
    Notified,
    LeavePeriod,
}

export enum ExpirationReason {
    Continue,
    Leave,
}
export default function ShowExpiration() {
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [selectedType, setSelectedType] = useState<ExpirationType | undefined>(undefined)
    const [selectedPage, setSelectedPage] = useState<ExpirationPage | undefined>(undefined)
    const [dateValue, setDateValue] = useState<DateTime | null>(null)
    const { data: userRentingPeriods, refetch } = useTenantRentingPeriodsQuery()
    const { mutateAsync: tenantResponse, isPending } = useTenantResponseMutation()

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile, theme],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const currentRentingPeriod = useMemo(() => userRentingPeriods?.find((rentingPeriod) => rentingPeriod.showExpirationForm), [userRentingPeriods])

    const handleNext = useCallback(async () => {
        if (selectedType === ExpirationType.Extend) {
            if (currentRentingPeriod == null) return
            await tenantResponse({
                tenantId: currentRentingPeriod.rentingPeriodId,
                response: ExpirationReason.Continue,
            })
            setSelectedPage(ExpirationPage.Notified)
        } else {
            setSelectedPage(ExpirationPage.LeavePeriod)
        }
    }, [selectedType, setSelectedPage, currentRentingPeriod, tenantResponse])

    const onEndPeriod = useCallback(async () => {
        if (currentRentingPeriod == null || dateValue == null) return
        await tenantResponse({
            tenantId: currentRentingPeriod.rentingPeriodId,
            response: ExpirationReason.Leave,
            leaveDate: dateValue.toISODate(),
        })
        setSelectedPage(ExpirationPage.Notified)
    }, [currentRentingPeriod, tenantResponse, dateValue, setSelectedPage])

    useEffect(() => {
        if (selectedPage !== ExpirationPage.Notified) return

        const timeout = setTimeout(async () => {
            await refetch()
            navigate("/dashboard")
        }, 5000)

        return () => clearTimeout(timeout)
    }, [selectedPage, navigate, refetch])

    return (
        <Page title={translate("notification_form")}>
            <ContentStyle>
                <PaperStyle elevation={5}>
                    <Box display={"flex"} flexDirection={"column"} gap={2} paddingTop={2}>
                        {selectedPage === undefined && (
                            <>
                                <ChooseCardPage
                                    onClick={() => setSelectedType(ExpirationType.Extend)}
                                    icon={"mdi:calendar-plus"}
                                    title={"extend_period"}
                                    backgroundColor={selectedType === ExpirationType.Extend ? alpha(theme.palette.primary.main, 0.1) : undefined}
                                />
                                <ChooseCardPage
                                    onClick={() => setSelectedType(ExpirationType.End)}
                                    icon={"mdi:calendar-remove"}
                                    title={"end_period"}
                                    backgroundColor={selectedType === ExpirationType.End ? alpha(theme.palette.primary.main, 0.1) : undefined}
                                />
                                <LoadingButton
                                    variant={"contained"}
                                    color={"primary"}
                                    disabled={selectedType === undefined || isPending}
                                    onClick={handleNext}
                                    loading={isPending}
                                >
                                    {translate("next")}
                                </LoadingButton>
                            </>
                        )}

                        {selectedPage === ExpirationPage.LeavePeriod && (
                            <Box display={"flex"} flexDirection={"column"} gap={3}>
                                <Typography variant={"body2"} fontWeight={"bold"} paddingBottom={2}>
                                    {translate("pick_a_leave_date")}
                                </Typography>
                                <DateTimePicker
                                    label={translate("insurance.endDate")}
                                    viewRenderers={{
                                        hours: null,
                                        minutes: null,
                                        seconds: null,
                                    }}
                                    views={["year", "month", "day"]}
                                    slotProps={{ textField: { size: "small" } }}
                                    value={dateValue}
                                    onChange={(value) => setDateValue(value)}
                                />
                                <LoadingButton
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={onEndPeriod}
                                    disabled={dateValue == null || isPending}
                                    loading={isPending}
                                >
                                    {translate("send_to_owner")}
                                </LoadingButton>
                            </Box>
                        )}
                        {selectedPage === ExpirationPage.Notified && (
                            <Box display={"flex"} flexDirection={"column"} gap={3}>
                                <Typography variant={"body2"} fontWeight={"bold"}>
                                    {translate("ownerHasBeenNotified")}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </PaperStyle>
            </ContentStyle>
        </Page>
    )
}
