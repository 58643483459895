import { useCallback, useEffect, useMemo, useRef } from "react"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, LoadingIcon, useLocales } from "rentzz"
import { useInfiniteIncomesQueryFn } from "../../../queries/income"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useAtomValue } from "jotai"
import { incomesFilterState } from "../../../utils/atom"
import { IncomeStatus } from "../../../redux/slices/AddProperty"
import { sum } from "lodash"
import NoDataPage from "../../../components/NoDataPage"
import { Box, CircularProgress, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import { CloseIcon } from "yet-another-react-lightbox"
import AddIcon from "@mui/icons-material/Add"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { ExportType } from "../../../utils/actions"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IncomeCustomCard from "./IncomeCustomCard"
import CustomAddCard from "../../../components/CustomAddCard"
import HeaderIncomes from "../HeaderIncomes"

interface MobileIncomesProps {
    getAmount: (value: number, incomeId?: number) => string
}

export default function MobileIncomesList({ getAmount }: MobileIncomesProps) {
    const { translate } = useLocales()
    const ref = useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const dispatch = useDispatch()
    const filterModel = useAtomValue(incomesFilterState)
    const { modalOpen } = useSelector((state) => state.appState)
    const { context } = useSelector((state) => state.appState)
    const { data: incomes, fetchNextPage, hasNextPage } = useInfiniteIncomesQueryFn([{ field: "date", sort: "desc" }], filterModel)
    const { canWrite } = usePermissions(PermissionType.Incomes)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    const getIncomeStatusColor = useCallback(
        (status: IncomeStatus | undefined) => {
            switch (status) {
                case IncomeStatus.Waiting:
                case IncomeStatus.CardPaymentWaiting:
                    return theme.palette.warning.main
                case IncomeStatus.Accepted:
                case IncomeStatus.CardPaymentSucceeded:
                case IncomeStatus.AcceptedFromExternalSystem:
                    return theme.palette.success.main
                case IncomeStatus.Denied:
                case IncomeStatus.CardPaymentFailed:
                    return theme.palette.error.main
            }
        },
        [theme],
    )

    if (incomes == null) return <LoadingIcon />

    if (sum(incomes?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite) return <NoDataPage icon={"mdi:cash-remove"} noDataText={"no_income"} />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            <HeaderIncomes />

            {incomes.pages.some((p) => p.items.length > 0) &&
                modalOpen == null &&
                (context === AppContext.Tenant || (context === AppContext.Owner && canWrite)) && (
                    <SpeedDial
                        sx={{
                            position: "absolute",
                            bottom: 10,
                            right: 10,
                        }}
                        color='primary'
                        ariaLabel='incomeActions'
                        icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<MoreVertIcon />} />}
                    >
                        <SpeedDialAction
                            tooltipTitle={translate("add")}
                            tooltipOpen
                            key={"add"}
                            icon={<AddIcon />}
                            onClick={() => dispatch(setModalOpen(AppModals.AddIncome))}
                        />
                        <SpeedDialAction
                            key={"export"}
                            icon={<FileDownloadIcon />}
                            tooltipTitle={translate("export")}
                            tooltipOpen
                            onClick={() => {
                                dispatch(setEditingItem({ type: ExportType.Income }))
                                dispatch(setModalOpen(AppModals.ExportDetailsSheet))
                            }}
                        />
                    </SpeedDial>
                )}
            {incomes.pages.map((group, i) => (
                <Box key={i} gap={1} display={"flex"} flexDirection={"column"}>
                    {group.items.map((income) => (
                        <IncomeCustomCard
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.IncomeDetails))
                                dispatch(setEditingItem({ id: income.id }))
                            }}
                            key={income.id}
                            income={income}
                            getAmount={getAmount}
                            getIncomeStatusColor={getIncomeStatusColor}
                        />
                    ))}
                </Box>
            ))}
            {hasNextPage && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                    <CircularProgress />
                </div>
            )}
            {incomes.pages.some((p) => p.items.length === 0) && canWrite && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddIncome))}
                    icon={"mdi:cash-plus"}
                    text={"income-table.addIncome"}
                />
            )}
        </Box>
    )
}
