import { Page, useIsMobile, useLocales } from "rentzz"
import MobileJournalList from "./MobileJournalList"
import DesktopJournalList from "./DesktopJournalList"
import { useSelector } from "../../../../../redux/store"

export default function JournalPage() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return (
        <Page
            title={translate(currentRentingPeriodId ? "tenants" : "journals")}
            propertyName={currentRentingPeriodId ? translate("journal") : ""}
            paddingBottom={2}
            paddingTop={isMobile ? 1 : 0}
        >
            {isMobile ? <MobileJournalList /> : <DesktopJournalList />}
        </Page>
    )
}
