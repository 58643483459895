import { ReactNode, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserPropertiesQuery, useUserTenantRequests } from "../queries/userData"
import { useSelector } from "../redux/store"
import { AppContext, LoadingScreen } from "rentzz"
import NoRentingPeriods from "../sections/tenantContext/NoRentingPeriods"
import RentingPeriodNotificationSelector from "../sections/tenantContext/rentingPeriodNotification/RentingPeriodNotificationSelector"
import ShowExpiration from "../sections/tenantContext/ShowExpiration"
import { useTenantRevisionsQuery } from "../queries/revision"
import ShowRevisionForm from "../sections/tenantContext/rentingPeriodNotification/ShowRevisionForm"
import ChooseRentingPeriod from "../sections/tenantContext/ChooseRentingPeriod"

type AuthGuardProps = {
    children: ReactNode
}

export default function PropertyGuard({ children }: AuthGuardProps) {
    const { data: user } = useUserDataQueryFn()
    const { pathname } = useLocation()
    const { data: tenantRequests, isFetching: areTenantRequestsFetching } = useUserTenantRequests()
    const { isLoading: arePropertiesLoading } = useUserPropertiesQuery()
    const { data: tenantRentingPeriods, isLoading: areUserRentingPeriodsLoading } = useTenantRentingPeriodsQuery()
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
    const { data: revisions } = useTenantRevisionsQuery()

    if (!user) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
    }

    if (context === AppContext.Owner && arePropertiesLoading) return <LoadingScreen />
    if (context === AppContext.Tenant && (areUserRentingPeriodsLoading || areTenantRequestsFetching)) return <LoadingScreen />

    if (user && context === AppContext.Tenant && tenantRequests && tenantRequests.length !== 0) {
        return <RentingPeriodNotificationSelector />
    }

    if (user && context === AppContext.Tenant && (tenantRentingPeriods?.length ?? 0) === 0) {
        return <NoRentingPeriods />
    }
    if (user && context === AppContext.Tenant && tenantRentingPeriods?.find((rentingPeriod) => rentingPeriod.showExpirationForm)) {
        return <ShowExpiration />
    }
    if (user && context === AppContext.Tenant && revisions?.find((revision) => revision.showDatePicker)) {
        return <ShowRevisionForm />
    }

    if (user && context === AppContext.Tenant && (currentRentingPeriodId == null || currentPropertyId == null)) {
        return <ChooseRentingPeriod />
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}
