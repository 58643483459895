import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useIsMobile } from "rentzz"
import IncomeDetailsSheet from "./IncomeDetailsSheet"
import IncomeStatusGuard from "./IncomeStatusGuard"
import DeleteIncomeGuard from "./DeleteIncomeGuard"
import AddOrEditIncomeGuard from "./AddOrEditIncomeGuard"
import AssignedExpensesModal from "./AssignedExpensesModal"

export default function IncomeGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        dispatch(setErrorMessage(undefined))
        if (isMobile && modalOpen !== AppModals.AddIncome && modalOpen !== AppModals.IncomeDetails && !editingItem?.isFromCalendar) {
            if (modalOpen === AppModals.LinkExpenseToIncomeAsOwner) {
                dispatch(setModalOpen(AppModals.ExpenseDetails))
            } else {
                dispatch(setModalOpen(AppModals.IncomeDetails))
            }
        } else if (isMobile && modalOpen === AppModals.IncomeDetails && editingItem?.isFromAnExpense) {
            dispatch(setModalOpen(AppModals.AssignedIncomes))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch, isMobile, editingItem])

    if (modalOpen == null) return null

    return (
        <>
            <IncomeStatusGuard />
            <DeleteIncomeGuard />
            <IncomeDetailsSheet onClose={closeModal} />
            <AddOrEditIncomeGuard onClose={closeModal} />
            <AssignedExpensesModal onClose={closeModal} />
        </>
    )
}
