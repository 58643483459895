import { LoadingIcon, Page, useIsMobile, useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../../queries/userData"
import React, { useCallback } from "react"
import DesktopMetersList from "./DesktopMetersList"
import MobileMetersList from "./mobile/MobileMetersList"
import { useMetersQueryFn } from "../../../queries/meters"
import { useUnitsQueryFn } from "../../../queries/units"
import { useSelector } from "../../../redux/store"

const PropertyMetersList = () => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { isLoading: areMetersLoading } = useMetersQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { isLoading: areUnitsLoading } = useUnitsQueryFn()

    const propertyName = useCallback(() => {
        return userProperties?.find((p) => p.id === currentPropertyId)?.label
    }, [userProperties])

    if (areMetersLoading || !currentPropertyId || areUnitsLoading) return <LoadingIcon />

    return (
        <Page title={translate("meters")} propertyName={propertyName()} paddingBottom={2} paddingTop={1}>
            {isMobile ? <MobileMetersList /> : <DesktopMetersList />}
        </Page>
    )
}

export default PropertyMetersList
