import CustomCard from "../../../components/CustomCard"
import { Box, Typography, useTheme } from "@mui/material"
import { AppContext, ExpenseAssignee, Iconify } from "rentzz"
import React, { useMemo } from "react"
import { useSelector } from "../../../redux/store"
import { PropertySummary, UserFlags } from "../../../queries/userData"
import { DateTime } from "luxon"
import { PropertyExpense } from "../../../queries/expenses"
import { alpha } from "@mui/material/styles"
import { useAtomValue } from "jotai"
import { expensesPageAtom, ExpensesPages } from "../../../utils/atom"
import { sumBy } from "lodash"
import { ExpensePaymentStatus, getExpensePaymentStatus } from "../utils"
import { useFeatureValue } from "@growthbook/growthbook-react"

interface ExpenseCustomCard {
    getAmount: (value: number, expenseId?: number) => string
    expense: PropertyExpense
    properties?: PropertySummary[]
    onClick: () => void
    backgroundColor: string
    subtitle?: string
}

const ExpenseCustomCard = ({ expense, getAmount, properties, onClick, backgroundColor, subtitle }: ExpenseCustomCard) => {
    const theme = useTheme()
    const { context } = useSelector((state) => state.appState)
    const activePage = useAtomValue(expensesPageAtom)
    const percent = useFeatureValue(UserFlags.PercentExpensePayment, 0)

    const paidAmount = useMemo(() => sumBy(expense?.propertyIncomes.map((income) => income.valueInExpenseCurrency)), [expense])

    const expensePaymentStatus = useMemo(() => {
        if (expense == null) return
        return getExpensePaymentStatus(expense.value, paidAmount, percent)
    }, [expense, percent, paidAmount])

    const icon = useMemo(() => {
        if (expense && expense.propertyIncomes.length > 0 && expensePaymentStatus !== ExpensePaymentStatus.Paid) return "mdi:progress-check"
        if ((expense && expense.propertyIncomesCount > 0) || (expense && expense.value <= 0))
            return context === AppContext.Owner ? "mdi:check-bold" : "mdi:check-circle"
        return context === AppContext.Owner ? "mdi:exclamation-thick" : "gravity-ui:circle-exclamation-fill"
    }, [backgroundColor, theme, expense, percent])

    return (
        <CustomCard key={expense.id} onClick={onClick} isCardClickable px={1} py={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={expense.assignee === ExpenseAssignee.TENANT && activePage === ExpensesPages.ADDED ? icon : "mdi:tag-multiple"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: backgroundColor,
                            backgroundColor: alpha(backgroundColor, 0.1),
                            borderColor: alpha(backgroundColor, 0.1),
                            minWidth: 35,
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Box display={"flex"} gap={1}>
                            <Typography
                                fontWeight={"bold"}
                                fontSize={14}
                                sx={{ display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}
                            >
                                {expense.name}
                            </Typography>
                        </Box>

                        {context === AppContext.Owner && (
                            <Typography fontSize={12}>{properties?.find((p) => p.id === expense.propertyId)?.label}</Typography>
                        )}
                        {context === AppContext.Tenant && (
                            <Box display={"flex"} gap={1} alignItems={"center"}>
                                <Typography fontSize={12}>{subtitle}</Typography>
                                <Iconify icon={icon} color={backgroundColor} width={15} height={15} />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box display={"flex"} alignItems={"center"} flexDirection={"column"} justifyContent={"center"}>
                    <Box display={"flex"} gap={1}>
                        <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                            {getAmount(expense.value, expense.currencyId)}
                        </Typography>
                    </Box>

                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {context === AppContext.Owner
                            ? expense.date.toLocaleString(DateTime.DATE_SHORT)
                            : expense.dueDate.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}

export default React.memo(ExpenseCustomCard)
