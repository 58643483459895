import React, { useCallback, useEffect } from "react"
import { AppContext, closeSnackbar, CustomFile, DownloadFileCard, enqueueSnackbar, FormProvider, RHFUpload, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TaskFilesSchema } from "../../../validations/kanban"
import { Box, CircularProgress, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { downloadFile } from "../../../utils/actions"
import { getEntityFilesQuery } from "../../../queries/files"
import { PropertyFileType } from "../../../redux/slices/AddProperty"
import { deleteEntityFileMutation, uploadEntityFileMutation } from "../../../mutations/entityFile"
import { useDispatch, useSelector } from "../../../redux/store"
import Api from "../../../api/Api"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"

interface TaskFilesRequest {
    files: CustomFile[]
}

interface Props {
    taskId: string
    canEdit?: boolean
}

export default function TaskDetailsFilesForm({ taskId, canEdit }: Props) {
    const { translate } = useLocales()
    const { context, editingItem } = useSelector((state) => state.appState)
    const { data: files, isPending: areFilesLoading } = getEntityFilesQuery(taskId, PropertyFileType.TaskFile)
    const { mutate: uploadFile, isPending: isLoading } = uploadEntityFileMutation()
    const { mutateAsync: deleteFile, isPending: isDeleteLoading } = deleteEntityFileMutation()
    const dispatch = useDispatch()
    const methods = useForm<TaskFilesRequest>({
        resolver: yupResolver(TaskFilesSchema),
        defaultValues: {
            files: [],
        },
    })

    const { handleSubmit, watch, setValue } = methods

    const handleDeleteEntityFile = useCallback(
        async (fileId: string) => {
            if (files == null) return
            await deleteFile({ fileId })
        },
        [deleteFile, files],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "files" && v.files != null) {
                v.files.forEach((f) => {
                    uploadFile({
                        data: {
                            uploadType: PropertyFileType.TaskFile,
                            referenceId: taskId,
                            files: [f],
                        },
                    })
                })
                setValue("files", [])
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, uploadFile, taskId])

    const handleDownload = useCallback(
        async (id: string) => {
            const fileToDownload = files?.find((f) => f.id === id)
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                if (fileToDownload?.extension?.toLowerCase() === "pdf") {
                    const fileUrl = await Api.downloadMultiFile([fileToDownload.id])
                    const url = window.URL.createObjectURL(new Blob([fileUrl]))
                    if (url) {
                        dispatch(
                            setEditingItem({
                                ...editingItem,
                                url: url,
                                previousModal: AppModals.TaskDetails,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.PdfViewer))
                    }
                } else {
                    await downloadFile({
                        downloadId: id,
                        downloadName: fileToDownload?.name ?? "unknown",
                        extension: fileToDownload?.extension,
                    })
                }
            } finally {
                closeSnackbar(key)
            }
        },
        [files, enqueueSnackbar, closeSnackbar, editingItem],
    )

    const onSubmit = useCallback(() => {}, [])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={"column"} gap={1} justifyContent={"center"}>
                <DownloadFileCard
                    files={files}
                    onDownload={handleDownload}
                    onDelete={handleDeleteEntityFile}
                    isMobileCard={true}
                    onFileNameChange={undefined}
                    isActionDisabled={isDeleteLoading}
                    isActionHidden={!canEdit || context === AppContext.Tenant}
                    isEditAction={!canEdit || context === AppContext.Tenant}
                />
                {areFilesLoading && (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection='column' height={100}>
                        <CircularProgress size={20} />
                    </Box>
                )}
                {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection='column' height={100}>
                        <CircularProgress size={20} />
                        <Typography variant='body2'>{translate("file_uploading")}</Typography>
                    </Box>
                ) : (
                    <Box height={100} width={"98%"}>
                        <RHFUpload
                            disabled={!canEdit}
                            name={"files"}
                            multiple
                            icon={"mdi:cloud-upload"}
                            uploadText={""}
                            thumbnail={false}
                            iconSize={26}
                            padding={1}
                        />
                    </Box>
                )}
            </Box>
        </FormProvider>
    )
}
