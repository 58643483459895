import { Iconify, useLocales } from "rentzz"
import { Box, Typography, useTheme } from "@mui/material"
import { InsuranceType, PropertyInsurance } from "../../../redux/slices/AddProperty"
import CustomCard from "../../../components/CustomCard"
import React from "react"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { DateTime } from "luxon"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch } from "../../../redux/store"
import { alpha } from "@mui/material/styles"

interface InsuranceCustomCardProps {
    getAmount: (value: number, currencyId: number) => string
    insurance: PropertyInsurance
}

export default function InsuranceCustomCard({ getAmount, insurance }: InsuranceCustomCardProps) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: userProperties } = useUserPropertiesQuery()

    return (
        <CustomCard
            key={insurance.id}
            onClick={() => {
                dispatch(setEditingItem({ id: insurance.id }))
                dispatch(setModalOpen(AppModals.InsuranceDetails))
            }}
            isCardClickable={false}
            px={1}
            py={1}
        >
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:shield-home"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor:
                                insurance.insuranceType === InsuranceType.Optional
                                    ? alpha(theme.palette.info.main, 0.1)
                                    : alpha(theme.palette.warning.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: insurance.insuranceType === InsuranceType.Optional ? theme.palette.info.main : theme.palette.warning.main,
                            backgroundColor:
                                insurance.insuranceType === InsuranceType.Optional
                                    ? alpha(theme.palette.info.main, 0.1)
                                    : alpha(theme.palette.warning.main, 0.1),
                            minWidth: 35,
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {insurance.insurer}
                        </Typography>{" "}
                        <Typography fontSize={12}>{userProperties?.find((p) => p.id === insurance.propertyId)?.label}</Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                        {getAmount(insurance.value, insurance.currencyId)}
                    </Typography>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {translate("available_until")} {insurance.startDate.toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
