import { Avatar, Button, Paper, Stack, Typography } from "@mui/material"
import React, { ReactElement } from "react"
import { alpha, useTheme } from "@mui/material/styles"
import { useLocales } from "rentzz"

export interface NotificationType {
    icon: ReactElement
    title: string
    subtitle: string
    action: () => void
    actionText: string
    number: number
}
interface Props {
    notification: NotificationType
}
export default function NotificationCard({ notification }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()

    return (
        <Paper
            variant='outlined'
            sx={{
                p: 1,
                paddingBottom: 2,
                borderBottomColor: theme.palette.grey["300"],
                borderBottomStyle: "dashed",
                color: theme.palette.grey.A700,
                width: "100%",
                ":hover": { bgcolor: "background.neutral", cursor: "pointer" },
            }}
            onClick={notification.action}
        >
            <Stack direction='row' spacing={2}>
                <Avatar sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.2), color: theme.palette.primary.main }}>
                    {notification.icon}
                </Avatar>

                <Stack direction='column' alignItems='flex-start' justifyContent='space-between'>
                    <Typography variant='subtitle2' fontWeight={"bold"}>
                        {translate(notification.title)} ({notification.number})
                    </Typography>
                    <Typography variant='caption'> {translate(notification.subtitle)}</Typography>
                    <Button color={"primary"} variant={"contained"} size={"small"} sx={{ marginTop: 1 }}>
                        {translate(notification.actionText)}
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    )
}
