import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useMetersQueryFn } from "../../../../queries/meters"
import React, { useCallback, useMemo } from "react"
import { OwnerPropertyMeter } from "../../../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"
import { Box, Button, Typography } from "@mui/material"
import * as jsonpatch from "fast-json-patch"
import { updateMeterMutation } from "../../../../mutations/property/meters/meters"
import { LoadingButton } from "@mui/lab"
import { AddMeterRequest } from "../../../../sections/propertyDetails/Meters/AddMeterForm"

export default function IntegerMeterReading() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()
    const { mutateAsync: editMeter, isPending: isEditMeterLoading } = updateMeterMutation()
    const currentMeter = useMemo(() => (meters as OwnerPropertyMeter[])?.find((meter) => meter?.id === editingItem?.id), [meters, editingItem])

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.MeterDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const updateMeterNotification = useCallback(
        async (data: AddMeterRequest) => {
            if (!data.id || currentMeter == null) return
            const operations = jsonpatch.compare({ requireInteger: currentMeter?.requireInteger }, { requireInteger: data.requireInteger })
            await editMeter({
                meterId: currentMeter.id,
                propertyId: data.propertyId,
                operations: operations,
            })
        },
        [editMeter, currentMeter],
    )

    const onSubmit = useCallback(async () => {
        if (currentMeter == null) return
        await updateMeterNotification({ ...currentMeter, requireInteger: !currentMeter.requireInteger, files: [] })
        onClose()
    }, [currentMeter, onClose, updateMeterNotification])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.HandleReadingIntegerNumber}
                onClose={onClose}
                isLoading={currentMeter == null}
                title={translate("integer_reading_title")}
                headerColor={currentMeter?.isActive ? theme.palette.success.main : theme.palette.warning.main}
            >
                <Box display={"flex"} flexDirection={"column"} paddingTop={2}>
                    <Typography textAlign={"center"}>
                        {currentMeter?.requireInteger ? translate("not_integer_reading") : translate("yes_integer_reading")}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onSubmit}
                            variant='contained'
                            color={"primary"}
                            loading={isEditMeterLoading}
                            disabled={isEditMeterLoading}
                            fullWidth
                        >
                            {translate(translate("yes"))}
                        </LoadingButton>
                        <Button onClick={onClose} variant={"text"} color={"primary"} fullWidth disabled={isEditMeterLoading}>
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("integer_reading_title")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.HandleReadingIntegerNumber}
                maxWidth='xs'
                onAccept={onSubmit}
                acceptText={translate("yes")}
                closeText={translate("cancel")}
                variantCloseButton={"text"}
                acceptButtonColor={"primary"}
                closeButtonColor={"primary"}
                isDisabled={isEditMeterLoading}
                isLoading={isEditMeterLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>{currentMeter?.requireInteger ? translate("not_integer_reading") : translate("yes_integer_reading")}</Typography>
                </Box>
            </CustomDialog>
        </>
    )
}
