import { Box, CircularProgress, Divider, Paper, Typography } from "@mui/material"
import * as React from "react"
import { useEffect } from "react"
import { CustomFile, FormProvider, RHFUpload, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { usePropertyPhotosQueryFn } from "../../queries"
import PhotosCarousel from "./PhotosCarousel"
import { useSelector } from "../../redux/store"
import { uploadPropertyPhotoMutation } from "../../mutations"
import NoDataPage from "../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"

export default function PropertyPhotos() {
    const { translate } = useLocales()
    const { mutate: uploadPhoto } = uploadPropertyPhotoMutation()
    const { data: files, isPending: areFilesLoading } = usePropertyPhotosQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Properties, currentPropertyId)

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            presentationFiles: [],
        },
    })

    const { setValue, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "presentationFiles" && v.presentationFiles != null && currentPropertyId != null) {
                v.presentationFiles.forEach((file) => {
                    if (file != null && "path" in file)
                        uploadPhoto({
                            data: {
                                propertyId: currentPropertyId,
                                presentationFiles: [file as CustomFile],
                            },
                        })
                })
                setValue("presentationFiles", [])
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, uploadPhoto, currentPropertyId, setValue])

    return (
        <Paper elevation={3} square={false}>
            <FormProvider methods={methods}>
                <Box display={"flex"} justifyContent={"space-between"} paddingY={1} paddingX={2} alignItems={"center"} color={"text.secondary"}>
                    <Typography fontWeight={"bold"}>{translate("property_photos")}</Typography>
                    {canWrite && <RHFUpload name={"presentationFiles"} multiple icon={""} uploadText={"add_photo"} thumbnail={true} padding={0.7} />}
                </Box>
                <Divider sx={{ marginX: 1 }} />

                {areFilesLoading ? (
                    <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
                        <CircularProgress size={"1rem"} />
                    </Box>
                ) : (
                    <>
                        {files && files.length === 0 ? (
                            <Box display={"flex"} padding={2} justifyContent={"center"} height={445}>
                                <NoDataPage noDataText={"no_photos"} icon={"mdi:image-off-outline"} />
                            </Box>
                        ) : (
                            <>
                                <PhotosCarousel files={files} />
                            </>
                        )}
                    </>
                )}
            </FormProvider>
        </Paper>
    )
}
