import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { usePropertyDetailsQueryFn } from "../../../queries"
import React, { useCallback } from "react"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import AddPropertyOwnerDetailsForm from "../../../sections/marketing/AddOrEditOwnerDetailsForm"

export default function AddOrEditOwnerDetails() {
    const { modalOpen } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: propertyDetails } = usePropertyDetailsQueryFn()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate(
                    propertyDetails?.marketingSettings?.firstName &&
                        propertyDetails?.marketingSettings?.lastName &&
                        (propertyDetails?.marketingSettings?.phoneNumber || propertyDetails?.marketingSettings?.email)
                        ? "edit_owner_details"
                        : "add_owner_details",
                )}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.EditPropertyOwnerDetails}
                maxWidth='sm'
                isFullScreen={isMobile}
                titleAlign={"center"}
            >
                <AddPropertyOwnerDetailsForm
                    onClose={onClose}
                    defaultValues={{
                        lastName: propertyDetails?.marketingSettings?.lastName,
                        firstName: propertyDetails?.marketingSettings?.firstName,
                        email: propertyDetails?.marketingSettings?.email,
                        phoneNumber: propertyDetails?.marketingSettings?.phoneNumber,
                    }}
                />
            </CustomDialog>
            <DetailsSheet
                title={translate(
                    propertyDetails?.marketingSettings?.firstName &&
                        propertyDetails?.marketingSettings?.lastName &&
                        (propertyDetails?.marketingSettings?.phoneNumber || propertyDetails?.marketingSettings?.email)
                        ? "edit_owner_details"
                        : "add_owner_details",
                )}
                isOpen={isMobile && modalOpen === AppModals.EditPropertyOwnerDetails}
                onClose={onClose}
                isLoading={false}
            >
                <AddPropertyOwnerDetailsForm
                    onClose={onClose}
                    defaultValues={{
                        lastName: propertyDetails?.marketingSettings?.lastName,
                        firstName: propertyDetails?.marketingSettings?.firstName,
                        email: propertyDetails?.marketingSettings?.email,
                        phoneNumber: propertyDetails?.marketingSettings?.phoneNumber,
                    }}
                />
            </DetailsSheet>
        </>
    )
}
