import { LoadingIcon, Page, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import General from "./details/General"
import { usePropertyDetailsQueryFn } from "../../../queries"

export default function GeneralPage() {
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)

    const { data: currentProperty } = usePropertyDetailsQueryFn(currentPropertyId)

    if (currentProperty == null) return <LoadingIcon />

    return (
        <Page title={translate("general")} propertyName={currentProperty?.name} paddingTop={1} paddingBottom={2}>
            <General />
        </Page>
    )
}
