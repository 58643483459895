import { useIsMobile } from "rentzz"
import MobileGroupAccessList from "./MobileGroupAccessList"
import DesktopGroupAccessList from "./DesktopGroupAccessList"

const GroupAccessPage = () => {
    const isMobile = useIsMobile()

    return isMobile ? <MobileGroupAccessList /> : <DesktopGroupAccessList />
}

export default GroupAccessPage
