import { ReactNode } from "react"
import AuthGuard from "../AuthGuard"
import { useSelector } from "../../redux/store"
import ChooseContext from "./ChooseContext"
import AcceptanceGuard from "../acceptance/AcceptanceGuard"

type AuthGuardProps = {
    children: ReactNode
}

export default function ContextGuard({ children }: AuthGuardProps) {
    const { context } = useSelector((state) => state.appState)
    if (context == null) {
        return (
            <AuthGuard>
                <AcceptanceGuard>
                    <ChooseContext />
                </AcceptanceGuard>
            </AuthGuard>
        )
    }

    return (
        <AuthGuard>
            <AcceptanceGuard>{children}</AcceptanceGuard>
        </AuthGuard>
    )
}
