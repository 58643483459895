import * as React from "react"
import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { deleteBankAccountMutation } from "../../../../../mutations/user"
import { useUserBankAccountsQuery } from "../../../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"

export default function DeleteBankAccountGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteBankAccount, isPending: isBankAccountLoading } = deleteBankAccountMutation()
    const { data: bankAccounts } = useUserBankAccountsQuery()

    const currentBankAccount = useMemo(
        () => bankAccounts?.items.find((bankAccount) => bankAccount.id === editingItem?.id),
        [bankAccounts, editingItem],
    )

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.BankAccountDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (currentBankAccount == null) return
        await deleteBankAccount(currentBankAccount.id)

        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [currentBankAccount, deleteBankAccount, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteBankAccount}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bankAccount_content", { iban: currentBankAccount?.iban, holder: currentBankAccount?.holder })}
                    isLoading={isBankAccountLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteBankAccount}
                onClose={onClose}
                isLoading={false}
                title={translate("delete")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bankAccount_content", { iban: currentBankAccount?.iban, holder: currentBankAccount?.holder })}
                    isLoading={isBankAccountLoading}
                />
            </DetailsSheet>
        </>
    )
}
