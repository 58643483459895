import { array, boolean, mixed, number, object, string } from "yup"
import { emptyStringToNull } from "../dialogs/add-edit-property/utils"
import { Furniture, TVType } from "rentzz"
import { Partition } from "../sections/propertyDetails/marketing/addNewAd/details/Details"

export const AdDataSchema = object().shape({
    title: string().required("marketing.requiredTitle"),
    county: string().required("county_required"),
    city: string().required("city_required"),
    currencyId: number().required(),
    propertyId: number().required(),
    address: string().required(),
    rentPrice: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("marketing.rentPriceRequired"),
    size: number()
        .required()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .min(10, "at_least_10mp")
        .max(300, "at_most_300mp")
        .transform(emptyStringToNull),
    partition: mixed<Partition>().optional(),
    floor: string().optional(),
    furniture: mixed<Furniture>().optional(),
    rooms: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    bathrooms: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    tv: mixed<TVType>().optional(),
    hasHeater: boolean().required(),
    hasAC: boolean().required(),
    hasFridge: boolean().required(),
    hasDishwasher: boolean().required(),
    hasWashingMachine: boolean().required(),
    hasHood: boolean().required(),
    hasElevator: boolean().required(),
    hasDoubleGlazing: boolean().required(),
    hasInsulation: boolean().required(),
    description: string().optional(),
    phone: string().optional().required("phone_required"),
    photos: array().required(),
})
