import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DesktopPendingReadingsList from "../../../../sections/propertyDetails/General/management/pending-readings/DesktopPendingReadingsList"
import { useTheme } from "@mui/material/styles"
import MobilePendingReadingsList from "../../../../sections/propertyDetails/General/management/pending-readings/MobilePendingReadingsList"

export default function PendingReadingsProviderGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [])

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.PendingReadings}
                title={translate("pending_readings")}
                titleAlign={"center"}
                onClose={onClose}
                showCloseIcon={true}
                maxWidth={"xl"}
                isFullScreen={false}
            >
                <DesktopPendingReadingsList />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.PendingReadings}
                onClose={onClose}
                isLoading={false}
                title={translate("pending_readings")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <MobilePendingReadingsList />
            </DetailsSheet>
        </>
    )
}
