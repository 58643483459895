import { Box, Typography } from "@mui/material"
import { Iconify, Label, useLocales } from "rentzz"
import {
    getIncomeLabelColorByPaymentMethod,
    getIncomeLabelColorByStatus,
    getIncomeOrPaymentBackgroundColor,
    getIncomeOrPaymentStatusColor,
    getIncomeOrPaymentStatusIcon,
} from "../../../utils/helpers"
import { DateTime } from "luxon"
import CustomCard from "../../../components/CustomCard"
import React, { useCallback } from "react"
import { PropertyIncome } from "../../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"

interface Props {
    income: PropertyIncome
    onClick: () => void
}
export default function AssignedIncomeCard({ income, onClick }: Props) {
    const intl = useIntl()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: currencyData } = useCurrencyQueryFn()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    return (
        <CustomCard key={income.id} onClick={onClick} isCardClickable={false} px={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={getIncomeOrPaymentStatusIcon(income.status)}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: getIncomeOrPaymentBackgroundColor(income.status, theme),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            backgroundColor: getIncomeOrPaymentBackgroundColor(income.status, theme),
                            color: getIncomeOrPaymentStatusColor(income.status, theme),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Label color={getIncomeLabelColorByStatus(income.status)} style={{ textTransform: "none" }}>
                            {translate(`payments-table.status-${income.status as number}`)}
                        </Label>
                        <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }} textAlign={"center"}>
                            {income.date.toLocaleString(DateTime.DATE_SHORT)}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Typography alignSelf={"center"} fontWeight={"bold"} fontSize={14}>
                        {getAmount(income.value, income.currencyId)}
                    </Typography>
                    <Label color={getIncomeLabelColorByPaymentMethod(income.paymentMethod)} style={{ textTransform: "none" }}>
                        {translate(`income-table.paymentMethod-${income.paymentMethod}`)}
                    </Label>
                </Box>
            </Box>
        </CustomCard>
    )
}
