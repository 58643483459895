import { ExpenseAssignee, FormProvider, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import AddExpenseForm, { AddExpenseRequest } from "../../../sections/expenses/AddExpenseForm"
import { LoadingButton } from "@mui/lab"
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import { AddExpenseSteps } from "./AddOrEditExpenseGuard"
import * as jsonpatch from "fast-json-patch"
import { DateTime } from "luxon"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ExpensesSchema } from "../../../validations/expenses"
import { PaymentType } from "../../../redux/slices/AddProperty"
import SharingGroupExpense from "../../../sections/expenses/SharingGroupExpense"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { useSelector } from "../../../redux/store"
import { addExpenseMutation, updateExpenseMutation } from "../../../mutations/expenses"
import { PropertyExpense } from "../../../queries/expenses"
import { useUserGroupsQueryFn } from "../../../queries/groups"

interface Props {
    currentExpense: PropertyExpense | undefined | null
    closeModal: () => void
    activeStep: AddExpenseSteps
    setActiveStep: Dispatch<SetStateAction<AddExpenseSteps>>
}
export default function AddExpenseContent({ currentExpense, closeModal, activeStep, setActiveStep }: Props) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, currentGroupId, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { mutateAsync: updateExpense, isPending: isUpdateExpenseLoading } = updateExpenseMutation()
    const { mutateAsync: addExpense, isPending: isAddExpenseLoading } = addExpenseMutation()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: groups } = useUserGroupsQueryFn()
    const { data: user } = useUserDataQueryFn()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const defaultAssignee = useFeatureValue<ExpenseAssignee>(UserFlags.DefaultAssignee, ExpenseAssignee.ME)
    const { editableProperties } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [groups, currentGroupId])

    const methods = useForm<AddExpenseRequest>({
        resolver: yupResolver<AddExpenseRequest>(ExpensesSchema(currentGroupId)),
        mode: "onChange",
        defaultValues: {
            assignee: rentingPeriodDetails ? ExpenseAssignee.TENANT : defaultAssignee,
            date: DateTime.now().toISO() ?? undefined,
            dueDate: DateTime.now().plus({ day: 1 }).toISO() ?? undefined,
            files: [],
            currencyId: user?.currency.id,
            propertyId: currentGroupId ? undefined : (currentPropertyId ?? rentingPeriodDetails?.propertyId ?? (editableProperties ?? [])[0]?.id),
            paymentType: PaymentType.Expense,
            rentingPeriodId: rentingPeriodDetails?.id ?? undefined,
            groupId: currentGroupId,
        },
    })
    const { reset, handleSubmit } = methods

    const steps = useMemo(
        () => [
            {
                title: translate("general"),
            },
            {
                title: translate("shared_expense"),
            },
        ],
        [translate],
    )

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddExpenseSteps.General:
                return (
                    <AddExpenseForm
                        isAddExpenseLoading={isAddExpenseLoading}
                        defaultValues={editingItem?.prefilledExpense ? editingItem : currentExpense}
                        onClose={closeModal}
                    />
                )
            case AddExpenseSteps.SharedExpense:
                return (
                    <Box display={"flex"} flexDirection={"column"} gap={2} paddingY={2} maxHeight={400} sx={{ overflowY: "auto" }}>
                        {currentGroup?.properties.map((property) => <SharingGroupExpense key={property.propertyId} property={property} />)}
                    </Box>
                )
        }
    }, [activeStep, isAddExpenseLoading, currentExpense, closeModal, currentGroup, editingItem])

    const onAddExpenseSave = useCallback(
        async (data: AddExpenseRequest) => {
            if (currentGroupId && activeStep === AddExpenseSteps.General) {
                return setActiveStep(AddExpenseSteps.SharedExpense)
            }

            if (currentExpense == null) {
                await addExpense({ data })
            } else {
                if (!data.id) return
                const operations = jsonpatch.compare(
                    {
                        ...currentExpense,
                        date: currentExpense?.date.toISODate(),
                        dueDate: currentExpense?.dueDate.toISODate(),
                        files: [],
                        ___: undefined,
                        shouldCreateInvoice: false,
                        shouldNotifyOtherParty: false,
                    },
                    {
                        ...data,
                        assignee: Number(data.assignee),
                        files: [],
                        dueDate: DateTime.fromISO(data.dueDate).toISODate(),
                        date: DateTime.fromISO(data.date).toISODate(),
                        labelId: data.labelId === 0 ? null : data.labelId,
                        associatedProviderId: data.associatedProviderId === 0 ? null : data.associatedProviderId,
                        ___: undefined,
                        shouldCreateInvoice: false,
                        shouldNotifyOtherParty: false,
                    },
                )
                await updateExpense({ propertyId: data.propertyId, expenseId: data.id, operations })
            }
            closeModal()
            reset()
        },
        [currentExpense, updateExpense, addExpense, modalOpen, currentGroupId, activeStep],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onAddExpenseSave)}>
            {currentGroupId ? (
                <>
                    <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                        {steps.map((step, index) => {
                            return (
                                <Step key={step.title} completed={index < (activeStep ?? 0)}>
                                    <StepLabel>{step.title}</StepLabel>
                                    {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                                </Step>
                            )
                        })}
                    </Stepper>
                    {!isMobile && renderActiveStep()}
                </>
            ) : (
                <AddExpenseForm
                    isAddExpenseLoading={isAddExpenseLoading}
                    defaultValues={editingItem?.prefilledExpense ? editingItem : currentExpense}
                    onClose={closeModal}
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button
                    disabled={isAddExpenseLoading || isUpdateExpenseLoading}
                    color={"primary"}
                    onClick={activeStep === AddExpenseSteps.SharedExpense ? () => setActiveStep(AddExpenseSteps.General) : closeModal}
                >
                    {translate("cancel")}
                </Button>
                <LoadingButton
                    type={"submit"}
                    variant='contained'
                    color={"primary"}
                    loading={isAddExpenseLoading || isUpdateExpenseLoading}
                    disabled={isAddExpenseLoading || isUpdateExpenseLoading}
                >
                    {translate(currentGroupId ? "next" : "submit")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}
