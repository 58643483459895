import { Box, Card, Paper, Typography } from "@mui/material"
import { Iconify, Page, useIsMobile, useLocales } from "rentzz"
import { useTenantRentingPeriodsQuery } from "../../queries/userData"
import { alpha, styled, useTheme } from "@mui/material/styles"
import { useDispatch } from "../../redux/store"
import { setCurrentPropertyId, setCurrentRentingPeriodId } from "../../redux/slices/App"
import { useMemo } from "react"
import { DateTime } from "luxon"
import { orderBy } from "lodash"

export default function ChooseRentingPeriod() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const renderContent = useMemo(
        () => (
            <>
                <Typography textAlign={"center"} variant={"h6"} sx={{ color: theme.palette.primary.dark }}>
                    {translate("choose_address")}
                </Typography>
                {orderBy(tenantRentingPeriods, ["to"], "desc")?.map((contract) => (
                    <Card
                        onClick={() => {
                            dispatch(setCurrentRentingPeriodId(contract.rentingPeriodId))
                            dispatch(setCurrentPropertyId(contract.propertyId))
                        }}
                        key={contract.rentingPeriodId}
                        sx={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: theme.palette.primary.dark,
                            borderRadius: 1,
                            padding: 2,
                            paddingX: isMobile ? undefined : 4,
                            transition: isMobile ? undefined : "transform 0.5s",
                            cursor: "pointer",
                            ":hover": {
                                backgroundColor: isMobile ? "" : alpha(theme.palette.primary.light, 0.1),
                                transform: isMobile ? undefined : "scale(1.02)",
                            },
                            gap: 1,
                            display: "flex",
                            flexDirection: "column",
                            width: isMobile ? "100%" : undefined,
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={isMobile ? "column-reverse" : "row"}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? "flex-start" : "center"}
                        >
                            <Typography variant={"subtitle2"} sx={{ color: theme.palette.primary.dark }}>
                                {contract.ownerName}
                            </Typography>
                            <Box display={"flex"} gap={1} justifyContent={"center"}>
                                {contract.to > DateTime.now() ? (
                                    <>
                                        <Typography variant={"caption"} sx={{ color: theme.palette.primary.dark }}>
                                            {translate("active_contract")}
                                        </Typography>
                                        <Iconify icon={"mdi:done"} color={theme.palette.success.main} />
                                    </>
                                ) : (
                                    <>
                                        <Typography variant={"caption"} sx={{ color: theme.palette.error.main }}>
                                            {translate("end_contract")}
                                        </Typography>
                                        <Iconify icon={"mdi:stop-remove"} color={theme.palette.error.main} />
                                    </>
                                )}
                            </Box>
                        </Box>

                        <Box
                            display={"flex"}
                            flexDirection={isMobile ? "column" : "row"}
                            justifyContent={"space-between"}
                            alignItems={isMobile ? "flex-start" : "center"}
                        >
                            <Typography variant={"caption"} sx={{ color: theme.palette.primary.dark }}>
                                {contract.county}, {contract.city}
                            </Typography>
                            <Typography variant={"caption"} sx={{ color: theme.palette.primary.dark }}>
                                {contract.from.toLocaleString(DateTime.DATE_SHORT)} - {contract.to.toLocaleString(DateTime.DATE_SHORT)}
                            </Typography>
                        </Box>
                    </Card>
                ))}
            </>
        ),
        [tenantRentingPeriods, isMobile, theme, dispatch],
    )

    return (
        <Page
            title={translate("choose_address")}
            sx={{ display: "flex", alignContent: "center", height: "100%", width: "100%", justifyContent: "center" }}
        >
            {isMobile ? (
                <Box
                    display='flex'
                    gap={1}
                    alignItems='center'
                    flexDirection={"column"}
                    width={"100%"}
                    height={"100%"}
                    style={{ backgroundColor: theme.palette.action.hover }}
                    padding={3}
                >
                    {renderContent}
                </Box>
            ) : (
                <ContentStyle>
                    <PaperStyle elevation={5}>{renderContent}</PaperStyle>
                </ContentStyle>
            )}
        </Page>
    )
}
