export enum AppForm {
    AddIncome,
}

export const FIELDS_TO_RESET: Record<AppForm, Record<string, string[]>> = {
    [AppForm.AddIncome]: {
        propertyId: ["type", "value", "rentingPeriodId"],
        type: ["rentingPeriodId", "value"],
        rentingPeriodId: ["referenceId", "value"],
        referenceId: ["value"],
    },
}
