import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { useUserDataQueryFn } from "../../../queries/userData"
import React, { SyntheticEvent, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useAllSectionsQueryFn } from "../../../queries/kanban"

export default function SectionDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: allSections } = useAllSectionsQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const currentSection = useMemo(() => allSections?.find((section) => section.taskSectionId === editingItem?.id), [allSections, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentSection == null) return []
        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !currentSection.canEdit || userData?.roles == null,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditSection))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentSection.canEdit,
                onClick: () => dispatch(setModalOpen(AppModals.DeleteSection)),
            },
        ]
    }, [currentSection, dispatch, userData])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.SectionDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentSection?.name}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentSection?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:map-marker",
                `${translate("section_order")}: ${currentSection?.order}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
