import { ComplexQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../../api/Api"
import { AddProviderRequest } from "../../sections/propertyDetails/configurations/providers/addProvider/types"
import * as jsonpatch from "fast-json-patch"

export const addConfiguredExpenseProvider = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddProviderRequest }) => Api.addConfiguredExpenseProvider(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ConfiguredExpenseProviders])
            }
        },
    })
}

export const updateExpenseProvider = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ providerId, operations }: { providerId: string; operations: jsonpatch.Operation[] }) =>
            Api.updateExpenseProvider({ providerId, operations }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.ConfiguredExpenseProviders])
            }
        },
    })
}

export const deleteConfiguredExpenseProvider = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ providerId }: { providerId: string }) => Api.deleteProvider(providerId),
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.ConfiguredExpenseProviders])
        },
    })
}
