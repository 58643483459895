import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { PropertyFileDto } from "../redux/slices/AddProperty"
import { useSnackbar } from "notistack"
import { useLocales } from "rentzz"
import { AddFileToEntityRequest } from "../sections/files/EntityFileManagerForm"

export const uploadEntityFileMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddFileToEntityRequest }) => Api.uploadFileToData(data),
        onSettled: async () => {
            await invalidateQueries([
                ComplexQueryIDs.EntityFiles,
                ComplexQueryIDs.OwnerPropertyFiles,
                ComplexQueryIDs.TenantPropertyFiles,
                ComplexQueryIDs.SectionTasks,
                ComplexQueryIDs.TaskComments,
                ComplexQueryIDs.TenantTaskComments,
            ])
        },
    })
}

export const deleteEntityFileMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ fileId }: { fileId: string }) => Api.deleteFile({ fileId }),
        onMutate: async ({ fileId }) => {
            await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.EntityFiles] })

            const previousFiles = queryClient.getQueryData([ComplexQueryIDs.EntityFiles])

            if (previousFiles)
                queryClient.setQueryData([ComplexQueryIDs.EntityFiles], (oldData) => {
                    return removeFileFromEntity(oldData, fileId)
                })

            return { previousFiles, fileId }
        },
        onSuccess: async () => {
            await invalidateQueries([
                ComplexQueryIDs.EntityFiles,
                ComplexQueryIDs.OwnerPropertyFiles,
                ComplexQueryIDs.TenantPropertyFiles,
                SimpleQueryIDs.PendingExpense,
                ComplexQueryIDs.Expenses,
                SimpleQueryIDs.UserProperties,
            ])
        },
        onSettled: async (data, error, _, context) => {
            if (error && context) {
                queryClient.setQueryData([ComplexQueryIDs.EntityFiles], context?.previousFiles)
            }
        },
    })
}

export const updateEntityFileNameMutation = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { translate } = useLocales()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ fileId, newFileName }: { fileId: string; newFileName: string }) => Api.updateEntityFileName(fileId, newFileName),
        onSuccess: async (data) => {
            if (data === "") enqueueSnackbar(translate("updated"), { variant: "success" })
            await invalidateQueries([ComplexQueryIDs.EntityFiles, ComplexQueryIDs.OwnerPropertyFiles, ComplexQueryIDs.TenantPropertyFiles])
        },
    })
}

function removeFileFromEntity(oldData: unknown, data: unknown) {
    const converted = oldData as PropertyFileDto[]

    return [...converted.filter((e) => e.id !== data)]
}
