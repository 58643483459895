import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import { useTheme } from "@mui/material/styles"
import { deleteConfiguredExpenseProvider } from "../../../../mutations/expenses"
import { deleteConfiguredMeterProvider } from "../../../../mutations/property/meters/meterProviders"
import { ProviderType } from "../../../../redux/slices/AddProperty"

export default function DeleteProvider() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteExpenseProvider, isPending: isDeleteExpenseProviderLoading } = deleteConfiguredExpenseProvider()
    const { mutateAsync: deleteMeterProvider, isPending: isDeleteMeterProviderLoading } = deleteConfiguredMeterProvider()

    const onDelete = useCallback(async () => {
        if (editingItem == null) return
        if (editingItem?.providerType === ProviderType.Expense) {
            await deleteExpenseProvider({
                providerId: editingItem.id,
            })
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        } else {
            await deleteMeterProvider({
                providerId: editingItem.providerId,
            })
            if (isMobile) return dispatch(setModalOpen(AppModals.MeterDetails))
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [editingItem, deleteMeterProvider, deleteExpenseProvider, isMobile])

    const onClose = useCallback(() => {
        if (isMobile) {
            if (editingItem?.providerType === ProviderType.Expense) return dispatch(setModalOpen(AppModals.ProviderDetails))
            return dispatch(setModalOpen(AppModals.MeterDetails))
        }
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [isMobile, editingItem, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                title={translate("delete")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.DeleteProvider}
                maxWidth='xs'
                onAccept={onDelete}
                acceptText={translate("delete")}
                closeText={translate("cancel")}
                closeButtonColor={"primary"}
                variantCloseButton={"text"}
                acceptButtonColor={"error"}
                isDisabled={isDeleteMeterProviderLoading || isDeleteMeterProviderLoading}
                isLoading={isDeleteExpenseProviderLoading || isDeleteMeterProviderLoading}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>{translate("delete_provider_content", { name: editingItem?.name })}</Typography>
                </Box>
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("delete")}
                isOpen={isMobile && modalOpen === AppModals.DeleteProvider}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <Box display='flex' height='100%' flexDirection={"column"} justifyContent='space-between'>
                    <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                        <Typography textAlign={"center"}>{translate("delete_provider_content", { name: editingItem?.name })}</Typography>
                    </Box>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onDelete}
                            variant='contained'
                            color={"error"}
                            fullWidth
                            disabled={isDeleteMeterProviderLoading || isDeleteMeterProviderLoading}
                            loading={isDeleteExpenseProviderLoading || isDeleteMeterProviderLoading}
                        >
                            {translate("delete")}
                        </LoadingButton>
                        <Button disabled={isDeleteMeterProviderLoading || isDeleteMeterProviderLoading} onClick={onClose} color={"primary"} fullWidth>
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>
        </>
    )
}
