import { Page, Text } from "@react-pdf/renderer"
import React from "react"
import { DateTime } from "luxon"
import { useStyles } from "./useStyles"
import TenantContactRequiredInfo from "./TenantContactRequiredInfo"
import { TenantRequiredInfo } from "../newContract/TenantRequiredData"
import { PropertyDataRequiredInfo } from "../newContract/PropertyData"
import PropertyDataPreviewWhenCreatingContract from "./PropertyDataRequiredInfo"
import { useLocales } from "rentzz"
import PropertyInfoData from "./RentDetailsData"
import ContractConditions from "./ContractConditions"
import Signatures from "./Signatures"
import OwnerContactDefaultInfo from "./OwnerContactDefaultInfo"

export enum Focus {
    TENANT,
    PROPERTY_DATA,
    OWNER_OBLIGATIONS,
    TENANT_OBLIGATIONS,
    PARTIES_REQUIREMENTS,
    LITIGATION,
    REVIEW,
    NONE,
}

interface OwnerLivePreviewProps {
    tenantRequiredInfo: TenantRequiredInfo | undefined
    propertyRequiredData: PropertyDataRequiredInfo
    focus: Focus
    ownerObligations: string[] | undefined
    tenantObligations: string[] | undefined
    partiesRequirements: string[] | undefined
    litigation: string[] | undefined
    hasGDPR?: boolean
}
const OwnerLivePreview = ({
    tenantRequiredInfo,
    focus,
    propertyRequiredData,
    ownerObligations,
    tenantObligations,
    partiesRequirements,
    litigation,
    hasGDPR,
}: OwnerLivePreviewProps) => {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <Page size='A4' style={{ ...styles.page, fontFamily: "Roboto" }}>
            <Text style={{ ...styles.mainTitle, ...styles.boldFont }}>{translate("rental_agreement")}</Text>
            <Text style={{ ...styles.date, ...styles.boldFont }}>{DateTime.now().toLocaleString(DateTime.DATE_SHORT)}</Text>
            <Text style={{ ...styles.partiesTitle, ...styles.boldFont }}>{translate("parties")}</Text>
            <OwnerContactDefaultInfo />

            {tenantRequiredInfo && (
                <>
                    <Text style={{ ...styles.mainText, ...styles.boldFont }}>{translate("and")}</Text>
                    <TenantContactRequiredInfo tenantInfo={tenantRequiredInfo} isFocused={focus === Focus.TENANT} />
                    <Text style={styles.mainText}>{translate("agree_with_rental_agreement")}</Text>
                </>
            )}
            {propertyRequiredData && (
                <>
                    <Text style={styles.mainText}>{translate("agree_with_following_conditions")}</Text>
                    <PropertyDataPreviewWhenCreatingContract propertyData={propertyRequiredData} isFocused={focus === Focus.PROPERTY_DATA} />
                </>
            )}

            {ownerObligations && <PropertyInfoData />}
            <ContractConditions
                ownerObligations={ownerObligations}
                tenantObligations={tenantObligations}
                partiesRequirements={partiesRequirements}
                litigation={litigation}
                focus={focus}
                hasGDPR={hasGDPR}
            />
            {ownerObligations && tenantObligations && partiesRequirements && litigation && <Signatures />}
        </Page>
    )
}

export default OwnerLivePreview
