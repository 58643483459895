import CustomMobileList from "../../../components/CustomMobileList"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback, useMemo } from "react"
import { IKanbanTask, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTenantRentingPeriodsQuery } from "../../../queries/userData"
import { useTenantTasksQueryFn } from "../../../queries/kanban"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { useTheme } from "@mui/material/styles"
import { DateTime } from "luxon"
import { Typography } from "@mui/material"
import { seenByTenantMutation } from "../../../mutations/kanban"

export default function MobileTenantTasksList() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { currentPropertyId, context } = useSelector((state) => state.appState)
    const { data: rentingPeriods } = useTenantRentingPeriodsQuery()
    const currentRentingPeriod = useMemo(() => rentingPeriods?.find((r) => r.propertyId === currentPropertyId), [rentingPeriods, currentPropertyId])
    const { data: tasks } = useTenantTasksQueryFn(currentRentingPeriod?.rentingPeriodId)
    const { mutateAsync: seenByTenant } = seenByTenantMutation()

    const handleDetails = useCallback(
        async (task: IKanbanTask) => {
            dispatch(setModalOpen(AppModals.TaskDetails))
            dispatch(setEditingItem({ taskId: task.id, rentingPeriodId: currentRentingPeriod?.rentingPeriodId }))

            if (task.rentingPeriodId == null || task.id == null) return
            await seenByTenant({
                rentingPeriodId: task.rentingPeriodId,
                taskId: task.id,
            })
        },
        [dispatch, context, seenByTenant],
    )

    return (
        <CustomMobileList
            items={tasks}
            onAddClick={() => {
                dispatch(setModalOpen(AppModals.AddTask))
            }}
            addCardIcon={"mdi:table-large-add"}
            addCardText={translate("add_task")}
            buttonText={"add_task"}
        >
            {tasks && tasks.length > 0 && (
                <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                    {translate("tasks")}
                </Typography>
            )}
            {tasks?.map((task) => (
                <CustomMobileCard
                    onClick={() => handleDetails(task)}
                    key={task.id}
                    item={task}
                    title={task.name}
                    subtitle={`${translate("createdBy")}: ${task.createdBy}`}
                    rightFirstItem={translate("createdAt")}
                    rightSecondItem={task.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                    icon={"mdi:subtasks"}
                    iconColor={theme.palette.primary.main}
                />
            ))}
        </CustomMobileList>
    )
}
