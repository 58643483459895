import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import EditPropertyNameForm from "../../../../sections/propertyDetails/add-property-card/EditPropertyNameForm"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"

export default function EditPropertyNameGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    return (
        <>
            <DetailsSheet
                title={translate("edit_property_name")}
                isOpen={isMobile && modalOpen === AppModals.EditPropertyName}
                onClose={() => {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                }}
                isLoading={false}
                headerColor={theme.palette.primary.main}
            >
                <EditPropertyNameForm
                    propertyId={editingItem?.id}
                    onClose={() => {
                        dispatch(setModalOpen(undefined))
                        dispatch(setEditingItem(undefined))
                    }}
                />
            </DetailsSheet>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.EditPropertyName}
                title={translate("edit_property_name")}
                maxWidth={"sm"}
                onClose={() => {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                }}
                isFullScreen={false}
            >
                <Box p={2}>
                    <EditPropertyNameForm
                        propertyId={editingItem?.id}
                        onClose={() => {
                            dispatch(setModalOpen(undefined))
                            dispatch(setEditingItem(undefined))
                        }}
                    />
                </Box>
            </CustomDialog>
        </>
    )
}
