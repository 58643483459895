import CustomCard from "./CustomCard"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { Iconify, Label, LabelColor, useLocales } from "rentzz"
import React from "react"
import { useTheme } from "@mui/material/styles"
import { NewInvitedUsers } from "../queries/tenants"
import { NewInvitedManager } from "../queries"

interface Props {
    user: NewInvitedUsers | NewInvitedManager
    iconColor: string
    labelColor: LabelColor | undefined
    backgroundColor: string
    icon: string
    onClick: () => void
}
export default function UserCard({ user, iconColor, icon, labelColor, backgroundColor, onClick }: Props) {
    const { translate } = useLocales()
    const theme = useTheme()

    return (
        <CustomCard key={user.mail} onClick={onClick} isCardClickable px={1} py={1}>
            <Box display={"flex"} justifyContent={"flex-start"}>
                <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }} alignSelf={"center"}>
                    {user.date.toLocaleString(DateTime.DATE_SHORT)}
                </Typography>
            </Box>

            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Box
                        width={35}
                        height={35}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{
                            borderWidth: 1,
                            borderColor: backgroundColor,
                            borderStyle: "solid",
                            borderRadius: 50,
                            backgroundColor: backgroundColor,
                        }}
                    >
                        <Iconify
                            icon={icon}
                            style={{
                                color: iconColor,
                                minWidth: 35,
                            }}
                        />
                    </Box>

                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontWeight={"bold"}
                            fontSize={14}
                            color={theme.palette.grey.A700}
                            maxWidth={160}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                        >
                            {user.name === "-" ? user.mail : user.name}
                        </Typography>
                        <Typography fontSize={12} color={theme.palette.grey.A700} maxWidth={150}>
                            {user.name === "-" ? "" : user.mail}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Label color={labelColor} style={{ textTransform: "none" }}>
                        {translate(user.name === "-" ? `mail_notification_status.${user.status}` : `userAcceptanceStatus.${user.status}`)}
                    </Label>
                </Box>
            </Box>
        </CustomCard>
    )
}
