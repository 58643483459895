import { Avatar, Button, IconButton, Paper, Stack, Typography } from "@mui/material"
import { fToNow, stringAvatar } from "./utils"
import { getReverseTextColor } from "../../utils/helpers"
import { CommentType, Iconify, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { PropertyFileType } from "../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../redux/store"

interface Props {
    comment: CommentType
    onDelete?: () => void
    isLoading?: boolean
}
export default function CommentCard({ comment, onDelete, isLoading }: Props) {
    const theme = useTheme()
    const { currentLang, translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const handleViewFiles = useCallback(() => {
        dispatch(
            setEditingItem({
                ...editingItem,
                taskCommentId: comment.id,
                type: PropertyFileType.TaskComment,
                canWrite: comment.canAddFiles,
            }),
        )
        dispatch(setModalOpen(AppModals.EntityFileManager))
    }, [editingItem, comment])

    return (
        <Stack direction='row' spacing={2} sx={{ px: 2, paddingTop: 2 }}>
            <Avatar
                {...stringAvatar(`${comment.createdBy}`)}
                sx={{ backgroundColor: theme.palette.primary.dark, color: getReverseTextColor(theme.palette.primary.dark) }}
            />
            <Paper
                variant='outlined'
                sx={{
                    px: 1,
                    paddingBottom: 2,
                    borderRadius: 1,
                    bgcolor: "background.neutral",
                    borderColor: "background.neutral",
                    color: theme.palette.grey.A700,
                    width: "100%",
                }}
            >
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='subtitle2'> {comment.createdBy}</Typography>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant='caption' sx={{ color: "text.disabled" }}>
                            {fToNow(comment.createdAt, currentLang.value)}
                        </Typography>

                        {onDelete && (
                            <IconButton size='small' color={"error"} onClick={onDelete} disabled={isLoading}>
                                <Iconify icon={"mdi:delete"} sx={{ width: 18 }} />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>

                <Typography variant='body2'>{comment.text}</Typography>
                <Button color='primary' variant='text' onClick={handleViewFiles}>
                    {translate("view_files", { numberOfFiles: comment.files.length })}
                </Button>
            </Paper>
        </Stack>
    )
}
