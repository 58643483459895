import { SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { PersonalData } from "rentzz"

export const useAddC168AddressMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: PersonalData }) => Api.addC168Address(data),

        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.C168Addresses])
            }
        },
    })
}

export const deleteC168AddressMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteAddress,
        onSettled: async () => {
            await invalidateQueries([SimpleQueryIDs.C168Addresses])
        },
    })
}
