import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import { Box, Button, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../../../../../../guards/alertsGuard/utils"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { getNoteByIdQueryFn } from "../../../../../../queries/notes"
import { useUserPropertiesQuery } from "../../../../../../queries/userData"
import { DateTime } from "luxon"
import { AppContext, DetailsSheet, Iconify, MotionContainer, useLocales } from "rentzz"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { MOBILE_ICON_SIZE } from "../../../../../../utils/helpers"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../../../../redux/slices/AddProperty"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

export default function NoteDetailsActionSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const currentNote = getNoteByIdQueryFn(editingItem?.id, [{ field: "addedAt", sort: "desc" }])
    const [expanded, setExpanded] = useState<boolean>(false)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentNote == null) return []
        if (context === AppContext.Tenant)
            return [
                {
                    icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                    title: "see_files",
                    onClick: () => {
                        dispatch(
                            setEditingItem({
                                id: currentNote.noteId,
                                type: PropertyFileType.Note,
                                name: currentNote.title,
                                canWrite: false,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.EntityFileManager))
                    },
                },
            ]

        return [
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "notes.changeStatus",
                hidden: !currentNote.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.NoteStatus))
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: currentNote.noteId,
                            type: PropertyFileType.Note,
                            name: currentNote.title,
                            canWrite: currentNote.canWrite,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: !currentNote.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditNote))
                },
            },

            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentNote.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteNote))
                },
            },
        ]
    }, [currentNote, dispatch, context])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.NoteDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={currentNote == null}
            title={currentNote?.title}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"} padding={1}>
                    <Iconify icon={"mdi:information-variant"} sx={{ color: theme.palette.primary.main, fontSize: MOBILE_ICON_SIZE }} minWidth={22} />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography
                            fontSize={13}
                            color={theme.palette.grey["600"]}
                            sx={{
                                overflow: "hidden",
                                textAlign: "left",
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {currentNote?.text.substring(0, 120)}
                            {currentNote && currentNote?.text.length > 120 && !expanded && (
                                <>
                                    ...
                                    <Button
                                        size='small'
                                        color='primary'
                                        sx={{ marginBottom: 0.4 }}
                                        onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
                                    >
                                        {translate("view_all")}
                                    </Button>
                                </>
                            )}
                        </Typography>

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                <Typography
                                    fontSize={13}
                                    color={theme.palette.grey["600"]}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {currentNote?.text.substring(121, currentNote?.text.length - 1)}

                                    <Button size='small' color='primary' onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                                        {translate("close")}
                                    </Button>
                                </Typography>
                            </MotionContainer>
                        )}
                    </Box>
                </Box>
                {renderActionSheetInfo(
                    "mdi:eye",
                    currentNote?.isVisibleToTenant ? translate("notes.isVisible") : translate("notes.isNotVisible"),
                    currentNote?.isVisibleToTenant ? theme.palette.primary.main : theme.palette.warning.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("notes.updatedAt")} ${currentNote?.updatedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("notes.addedAt")} ${currentNote?.addedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {context === AppContext.Owner &&
                    renderActionSheetInfo(
                        "mdi:map-marker",
                        userProperties?.find((p) => p.id === currentNote?.propertyId)?.label,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
            </Box>
        </DetailsSheet>
    )
}
