import { Box, Link, Typography, useTheme } from "@mui/material"
import { Iconify, useIsMobile, useLocales } from "rentzz"
import { RoleName, useUserDataQueryFn } from "../queries/userData"
import React, { useCallback } from "react"
import { useDispatch } from "../redux/store"
import { setShowSubscriptionPage } from "../redux/slices/App"
import Api from "../api/Api"

type RoleBasedGuardProp = {
    hasContent?: boolean
    roles?: RoleName[]
    children: React.ReactNode
}

export default function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
    const { data: user } = useUserDataQueryFn()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    const handleOnClick = useCallback(async () => {
        if (user?.roles == null) dispatch(setShowSubscriptionPage(true))
        else window.open(await Api.fetchCustomerPortalURL(), "_self")
    }, [user])

    if (user?.roles == undefined || (typeof roles !== "undefined" && !roles.includes(user.roles.roleName))) {
        return hasContent ? (
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                width={"100%"}
                height={"100%"}
                alignItems={"center"}
                paddingBottom={10}
            >
                <Typography variant='h3' paragraph>
                    {translate("permission_denied")}
                </Typography>

                <Typography sx={{ color: "text.secondary" }} marginBottom={1} textAlign={"center"}>
                    {translate("do_not_have_permission")}
                </Typography>
                <Box display={"flex"} gap={0.5}>
                    <Typography sx={{ color: "text.secondary" }}>{translate("can_do_here")}</Typography>
                    <Link style={{ cursor: "pointer" }} component={"button"} onClick={handleOnClick} fontSize={16}>
                        {translate("here2")}
                    </Link>
                </Box>

                <Iconify
                    icon={"mdi:hand-back-right-off-outline"}
                    color={theme.palette.primary.main}
                    width={50}
                    height={50}
                    marginTop={isMobile ? 3 : 6}
                />
            </Box>
        ) : null
    }

    return <>{children}</>
}
