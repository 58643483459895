import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import MobileTenantTasksList from "../../sections/kanban/tenant/MobileTenantTasksList"
import DesktopTenantTasksList from "../../sections/kanban/tenant/DesktopTenantTasksList"

export default function TenantTasks() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Page title={translate("tasks")} paddingBottom={2}>
            {isMobile ? <MobileTenantTasksList /> : <DesktopTenantTasksList />}
        </Page>
    )
}
