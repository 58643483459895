import { useUserDataQueryFn, useUserTenantRequests } from "../../../queries/userData"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUnitsQueryFn } from "../../../queries/units"
import RentingPeriodWizard from "./wizard/RentingPeriodWizard"
import NoContractRentingPeriodNotification from "./NoContractRentingPeriodNotification"
import { LoadingScreen } from "rentzz"

const RentingPeriodNotificationSelector = () => {
    const { data: tenantRequests } = useUserTenantRequests()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: units } = useUnitsQueryFn()
    const { data: userData } = useUserDataQueryFn()

    if (tenantRequests == null || tenantRequests.length == 0 || currencies == null || units == null || userData == null) {
        return <LoadingScreen />
    }
    if (tenantRequests[0].contractToBeSigned != null) return <RentingPeriodWizard />

    return <NoContractRentingPeriodNotification />
}

export default RentingPeriodNotificationSelector
