import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { Container } from "@mui/material"
import { ContentStyle } from "../../../pages/PropertyDetails"
import MobileNotificationsList from "./mobile/MobileNotificationsList"
import DesktopNotificationsList from "./desktop/DesktopNotificationsList"

export default function NotificationsPage() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Page title={translate("documents_type")} propertyName={translate("notifications-templates")}>
            <Container maxWidth={false}>
                <ContentStyle>{isMobile ? <MobileNotificationsList /> : <DesktopNotificationsList />}</ContentStyle>
            </Container>
        </Page>
    )
}
