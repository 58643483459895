import { AppContext, Label, useIsMobile, useLocales } from "rentzz"
import { useAtom } from "jotai"
import { ExpensesFilteredOptions, expensesFilterState } from "../../utils/atom"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { CircularProgress, Tab, Tabs } from "@mui/material"
import { a11yProps } from "../../pages/PropertyDetails"
import { DateTime } from "luxon"
import { GridLogicOperator } from "@mui/x-data-grid"
import { useSelector } from "../../redux/store"
import { useTheme } from "@mui/material/styles"
import ExpensesSummaryReport, { allFilterOptions, getLabelColorByFilter } from "./ExpensesSummaryReport"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"
import { getExpensesReportSummaryQueryFn } from "../../queries/expenses"

export default function HeaderExpenses() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [page, setPage] = useState<ExpensesFilteredOptions | undefined>(undefined)
    const [filterModel, setFilterModel] = useAtom(expensesFilterState)
    const { context } = useSelector((state) => state.appState)
    const expenseOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)
    const { data: expensesSummaryStatus } = getExpensesReportSummaryQueryFn()
    const theme = useTheme()

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: ExpensesFilteredOptions) => {
            switch (newValue) {
                case ExpensesFilteredOptions.All:
                    setFilterModel({ items: [] })
                    break
                case ExpensesFilteredOptions.GeneralExpense:
                    setFilterModel({ items: [{ field: "paymentType", operator: "eq", value: "'Expense'" }] })
                    break
                case ExpensesFilteredOptions.Damages:
                    setFilterModel({ items: [{ field: "paymentType", operator: "eq", value: "'Damages'" }] })
                    break
                case ExpensesFilteredOptions.Rent:
                    setFilterModel({ items: [{ field: "paymentType", operator: "eq", value: "'Rent'" }] })
                    break
                case ExpensesFilteredOptions.SecurityDeposit:
                    setFilterModel({ items: [{ field: "paymentType", operator: "eq", value: "'SecurityDeposit'" }] })

                    break
                case ExpensesFilteredOptions.Overdue:
                    setFilterModel({
                        items: [
                            { field: "dueDate", operator: "<", value: DateTime.now().toISODate(), id: 0 },
                            { field: "propertyIncomesCount", operator: "=", value: 0, id: 1 },
                            { field: "assignee", operator: "=", value: "'TENANT'", id: 2 },
                            { field: "value", operator: ">", value: 0, id: 3 },
                        ],
                        logicOperator: GridLogicOperator.And,
                    })
                    break
                case ExpensesFilteredOptions.MoreThanOneIncomeAssigned:
                    setFilterModel({ items: [{ field: "propertyIncomesCount", operator: ">", value: 1 }] })
                    break

                case ExpensesFilteredOptions.Fees:
                    setFilterModel({ items: [{ field: "paymentType", operator: "eq", value: "'Fees'" }] })
                    break
            }
        },
        [setFilterModel],
    )

    useEffect(() => {
        if (filterModel.items?.length === 0) {
            setPage(ExpensesFilteredOptions.All)
            return
        }
        if (filterModel.items?.length === 3) {
            setPage(ExpensesFilteredOptions.Overdue)
        }
        const paymentTypeItem = filterModel.items.find((i) => i.field === "paymentType")
        if (paymentTypeItem?.value === "'Expense'") setPage(ExpensesFilteredOptions.GeneralExpense)
        if (paymentTypeItem?.value === "'Rent'") setPage(ExpensesFilteredOptions.Rent)
        if (paymentTypeItem?.value === "'Damages'") setPage(ExpensesFilteredOptions.Damages)
        if (paymentTypeItem?.value === "'SecurityDeposit'") setPage(ExpensesFilteredOptions.SecurityDeposit)
        if (paymentTypeItem?.value === "'Fees'") setPage(ExpensesFilteredOptions.Fees)
        const assignedItem = filterModel.items.find((i) => i.field === "propertyIncomesCount" && i.operator === ">")
        if (assignedItem?.value === 1) setPage(ExpensesFilteredOptions.MoreThanOneIncomeAssigned)

        const assignedNextItem = filterModel.items.find((i) => i.field === "propertyIncomesCount" && i.operator === "=")

        if (assignedNextItem?.value === 0) setPage(ExpensesFilteredOptions.Overdue)
    }, [filterModel])

    const customExpensesFilterOptions = useMemo(() => {
        const toReturn = [...allFilterOptions]
        if (context === AppContext.Owner) {
            toReturn.push(ExpensesFilteredOptions.MoreThanOneIncomeAssigned)
        }
        toReturn.splice(2, 0, ExpensesFilteredOptions.Damages)
        toReturn.splice(0, 0, ExpensesFilteredOptions.All)
        return toReturn
    }, [context])

    const allCounts = useMemo(
        () =>
            expensesSummaryStatus
                ? expensesSummaryStatus[ExpensesFilteredOptions.GeneralExpense].count +
                  expensesSummaryStatus[ExpensesFilteredOptions.Rent].count +
                  expensesSummaryStatus[ExpensesFilteredOptions.Damages].count +
                  expensesSummaryStatus[ExpensesFilteredOptions.Fees].count +
                  expensesSummaryStatus[ExpensesFilteredOptions.SecurityDeposit].count
                : 0,
        [expensesSummaryStatus],
    )

    if (page == null) return <CircularProgress size='1rem' />

    return (
        <>
            {!isMobile && <ExpensesSummaryReport />}
            <Tabs value={page} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                {customExpensesFilterOptions.map((f, index) => (
                    <Tab
                        key={f}
                        value={f}
                        label={translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-${f}`)}
                        icon={
                            <Label sx={{ mr: 1, color: "white", backgroundColor: getLabelColorByFilter(f, theme) }}>
                                {expensesSummaryStatus ? (f === ExpensesFilteredOptions.All ? allCounts : expensesSummaryStatus[f].count) : "-"}
                            </Label>
                        }
                        style={{
                            textTransform: "none",
                        }}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
        </>
    )
}
