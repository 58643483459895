import { updateUserLanguage } from "../mutations/user"
import * as jsonpatch from "fast-json-patch"
import { useUserDataQueryFn } from "../queries/userData"

export const useChangeLanguage = () => {
    const { mutateAsync: updateLang } = updateUserLanguage()
    const { data: user } = useUserDataQueryFn()

    const handleLangOption = async (language: string) => {
        const operations = jsonpatch.compare(
            {
                userPreferences: {
                    languageCode: user?.languageCode,
                },
            },
            {
                userPreferences: {
                    languageCode: language,
                },
            },
        )

        await updateLang({
            operations: operations.map((o) => ({ ...o, path: `${o.path}` })),
        })
    }

    return { handleLangOption }
}
