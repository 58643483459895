import { AppContext, LoadingScreen } from "rentzz"
import { ReactNode } from "react"
import { useUserPropertiesQuery } from "../queries/userData"
import { useSelector } from "../redux/store"
import SubscriptionPage from "../sections/subscription/SubscriptionPage"

type SubscriptionGuardProps = {
    children: ReactNode
}

export default function SubscriptionGuard({ children }: SubscriptionGuardProps) {
    const { data: userProperties } = useUserPropertiesQuery()
    const { context, showSubscriptionPage } = useSelector((state) => state.appState)

    if (userProperties == null && context === AppContext.Owner) return <LoadingScreen />

    if (showSubscriptionPage) {
        return <SubscriptionPage />
    }

    return <>{children}</>
}
