import {
    CustomFile,
    FormProvider,
    LightTooltip,
    RHFAutocomplete,
    RHFCheckbox,
    RHFSelect,
    RHFTextField,
    RHFUpload,
    useIsMobile,
    useLocales,
} from "rentzz"
import { Box, Button, MenuItem, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddDocumentSchema } from "../../validations/documents"
import { useUserDataQueryFn } from "../../queries/userData"
import { useSelector } from "../../redux/store"
import { usePropertyRentingPeriods, useRentingPeriodDetails } from "../../queries/tenants"
import { RentingPeriodStatusCode } from "../../redux/slices/AddProperty"
import { useTheme } from "@mui/material/styles"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { DateTime } from "luxon"

interface AddDocumentsProps {
    onClose: () => void
    onSubmit: (data: AddDocumentsRequest) => Promise<void>
}

export interface AddDocumentsRequest {
    files: CustomFile[]
    propertyId: number
    name: string
    rentingPeriodId: number
    notifyOtherParty: boolean
}

export default function AddDocumentForm({ onClose, onSubmit }: AddDocumentsProps) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: user } = useUserDataQueryFn()
    const { currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [tenantId, setTenantId] = useState(-1)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const [propertyId, setPropertyId] = useState(currentPropertyId ?? rentingPeriodDetails?.propertyId)
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(propertyId)
    const { editableProperties } = usePermissions(PermissionType.Documents)

    useEffect(() => {
        if (rentingPeriodDetails != null) return
        setTenantId(currentRentingPeriodId ?? -1)
        reset({
            files: [],
            propertyId: propertyId,
            name: undefined,
            notifyOtherParty: false,
            rentingPeriodId: currentRentingPeriodId ?? -1,
        })
    }, [propertyId, rentingPeriodDetails, setTenantId])

    const methods = useForm<AddDocumentsRequest>({
        resolver: yupResolver(AddDocumentSchema),
        mode: "onChange",
        defaultValues: {
            files: [],
            propertyId: propertyId,
            notifyOtherParty: false,
            rentingPeriodId: -1,
        },
    })

    const {
        watch,
        setValue,
        getValues,
        reset,
        resetField,
        handleSubmit,
        formState: { isSubmitting },
    } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "rentingPeriodId") setTenantId(v.rentingPeriodId!)
            if (name === "propertyId") {
                setPropertyId(v.propertyId!)
                const currentRentingPeriodId = rentingPeriodsFromAProperty?.find(
                    (period) => period.rentingPeriodStatus === RentingPeriodStatusCode.Accepted,
                )?.id
                setValue("rentingPeriodId", currentRentingPeriodId ?? -1)
                setTenantId(currentRentingPeriodId ?? -1)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, setValue, setTenantId, rentingPeriodsFromAProperty])

    useEffect(() => {
        if (rentingPeriodDetails == null) return
        setTimeout(() => {
            resetField("rentingPeriodId", {defaultValue:rentingPeriodDetails.id})
            resetField("propertyId", {defaultValue:rentingPeriodDetails.propertyId})
        }, 300)

    }, [resetField, rentingPeriodDetails])

    const getMaxSize = useCallback(() => {
        if (editableProperties == null || user == null) return 0
        const property = editableProperties.find((p) => p.id === propertyId || getValues("propertyId"))
        if (property == null) return 0
        if (user.features?.maxPropertySize) {
            return user.features.maxPropertySize - property.currentSize
        }
    }, [user, propertyId, editableProperties])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={2} flexDirection={"column"} marginTop={2}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                     <LightTooltip title={currentRentingPeriodId != null ? translate("only_read_no_write") : ""} arrow>
                        <span>
                              <RHFAutocomplete
                                  autocompleteProps={{
                                      sx: {
                                          width: "100%",
                                      },
                                      noOptionsText: translate("no_value_found"),
                                  }}
                                  freeSolo={false}
                                  matchId
                                  name={"propertyId"}
                                  options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                  label={translate("property")}
                                  required
                                  disabled={currentRentingPeriodId != null}
                              />
                        </span>
                     </LightTooltip>

                    {currentRentingPeriodId && rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length > 0 && (
                        <Typography fontSize={14} fontWeight={"bold"} textAlign={"center"} color={theme.palette.primary.dark}>
                            {translate("document_visible_for_tenant")}
                        </Typography>
                    )}
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFTextField name={"name"} label={translate("document_name")} required />
                        <LightTooltip title={currentRentingPeriodId != null ? translate("only_read_no_write") : ""} arrow>
                            <span>
                                <RHFSelect name='rentingPeriodId' label={translate("visible_for")} size='small' disabled={currentRentingPeriodId != null}>
                                    {currentRentingPeriodId == null &&
                                        <MenuItem value={-1} key={-1} style={{ textTransform: "none" }}>
                                            {translate("no_tenant")}
                                        </MenuItem>
                                    }
                                    {rentingPeriodsFromAProperty?.map((r) => (
                                        <MenuItem value={r?.id} key={r?.id} style={{ textTransform: "none" }} >
                                            {r?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - {r?.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </span>
                        </LightTooltip>
                    </Box>
                    {tenantId !== -1 && (
                        <LightTooltip
                            title={rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0 ? translate("disabled_when_no_mails") : ""}
                            arrow
                        >
                            <span>
                                <RHFCheckbox
                                    name={"notifyOtherParty"}
                                    label={translate("notify_tenant_upload_document")}
                                    disabled={rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0}
                                />
                            </span>
                        </LightTooltip>
                    )}
                    <RHFUpload name={"files"} multiple maxSize={getMaxSize()} icon={"mdi:file-document-plus-outline"} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton color={"primary"} type='submit' variant='contained' loading={isSubmitting} disabled={isSubmitting}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    )
}
