import React from "react"
import Income from "./Income"
import { Page, useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../queries/userData"
import { useSelector } from "../../redux/store"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"

const IncomePage = () => {
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)

    if (userProperties?.length === 0) return <NoPropertyPage />

    return (
        <Page
            title={translate(currentRentingPeriodId ? "tenants" : "income")}
            propertyName={currentRentingPeriodId ? translate("income") : userProperties?.find((p) => p.id === currentPropertyId)?.label}
            paddingBottom={2}
            paddingTop={currentRentingPeriodId || currentGroupId ? 0 : 1}
            px={currentPropertyId || currentRentingPeriodId || currentGroupId ? 0 : 2}
        >
            <Income />
        </Page>
    )
}

export default IncomePage
