import { Box, CircularProgress, Typography } from "@mui/material"
import { FormProvider, LightTooltip, RHFUnitInput, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback, useEffect, useMemo } from "react"
import { updatePropertyDetailsMutation } from "../../mutations"
import { useTheme } from "@mui/material/styles"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { useForm } from "react-hook-form"
import { useUserDataQueryFn } from "../../queries/userData"
import { useCurrencyQueryFn } from "../../queries/currency"
import { usePropertyDetailsQueryFn } from "../../queries"
import { debounce } from "lodash"
import { useSelector } from "../../redux/store"

export default function ConfigUnit() {
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { canWrite } = usePermissions(PermissionType.Properties)
    const { data: currentProperty } = usePropertyDetailsQueryFn(currentPropertyId)
    const { mutateAsync: updateProperty, isPending: isUpdatePropertyPending } = updatePropertyDetailsMutation()
    const updatePropertyDebounced = debounce(updateProperty, 1000)

    const desiredDefaultValue = useMemo(() => {
        if (currentProperty?.marketingSettings?.desiredPriceCurrencyId != null && currentProperty?.marketingSettings?.desiredPrice != null) {
            return { currencyId: currentProperty.marketingSettings.desiredPriceCurrencyId, value: currentProperty?.marketingSettings?.desiredPrice }
        }
        return undefined
    }, [currentProperty])

    const methods = useForm({
        defaultValues: desiredDefaultValue,
    })
    const { reset, handleSubmit, watch } = methods

    useEffect(() => {
        if (desiredDefaultValue) {
            reset(desiredDefaultValue)
        } else {
            reset({
                currencyId: userData?.currency.id,
                value: undefined,
            })
        }
    }, [desiredDefaultValue, userData])

    const handleDesiredPriceChange = useCallback(
        async (data: { currencyId: number; value: number }) => {
            await updatePropertyDebounced({
                operations: [
                    { op: "replace", path: "/marketingSettings/desiredPrice", value: data.value },
                    { op: "replace", path: "/marketingSettings/desiredPriceCurrencyId", value: data.currencyId },
                ],
            })
        },
        [updateProperty, currentProperty],
    )

    useEffect(() => {
        const subscription = watch((v) => {
            if (v.currencyId != null && v.value != null) handleDesiredPriceChange({ currencyId: v.currencyId, value: v.value })
        })
        return () => subscription.unsubscribe()
    }, [watch, handleDesiredPriceChange])

    return (
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
            <Typography variant='body2' fontWeight={"bold"}>
                {translate("desired_property_price")}
            </Typography>
            <LightTooltip arrow title={!canWrite ? translate("only_read_no_write") : ""}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={130}>
                    {!isUpdatePropertyPending && (
                        <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
                            <RHFUnitInput
                                unitType='currency'
                                unitName='currencyId'
                                name='value'
                                size='small'
                                data={currencies ?? []}
                                sx={{ color: theme.palette.grey.A700 }}
                                disabled={!canWrite}
                                fullWidth={isMobile}
                            />
                        </FormProvider>
                    )}
                    {isUpdatePropertyPending && <CircularProgress size={18} />}
                </Box>
            </LightTooltip>
        </Box>
    )
}
