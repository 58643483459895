import { Alert, Box, MenuItem } from "@mui/material"
import { PaymentType, PropertyIncome, PropertyIncomePaymentMethod, RentingPeriodStatusCode } from "../../../redux/slices/AddProperty"
import { LightTooltip, RHFAutocomplete, RHFDatePicker, RHFSelect, RHFUnitInput, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { getReferenceLabelTitle, isReferenceDisabled, renderMenuItem } from "../utils"
import { getOwnerMaxSize } from "../../../utils/actions"
import React, { useEffect, useMemo, useState } from "react"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUserDataQueryFn } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"
import { AppForm, useFormResetter } from "../../../hooks/useFormResetter"
import { AddIncomeRequest } from "./types"
import { useFormContext } from "react-hook-form"
import { usePropertyRentingPeriods, useRentingPeriodDetails, useSingleRentingPeriodQuery } from "../../../queries/tenants"
import { DateTime } from "luxon"
import { useInfiniteExpensesQueryFn } from "../../../queries/expenses"
import RHFLazyLoadSelect from "../../../components/RHFLazyLoadSelect"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useTheme } from "@mui/material/styles"

interface Props {
    defaultValues: PropertyIncome | undefined
    isLinkingExpense: boolean
}
export default function AddIncomeTypeExpenseForm({ defaultValues, isLinkingExpense }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId, errorMessage, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [propertyId, setPropertyId] = useState<number | undefined>(undefined)
    const { data: currencies } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(propertyId)
    const { data: rentingPeriod } = useSingleRentingPeriodQuery(currentRentingPeriodId)
    const { resetFields } = useFormResetter<AddIncomeRequest>()
    const { setValue, watch, reset, getValues } = useFormContext<AddIncomeRequest>()
    const {
        data: expenses,
        hasNextPage,
        fetchNextPage,
        isFetching,
    } = useInfiniteExpensesQueryFn(
        false,
        [
            { field: "assignee", sort: "desc" },
            { field: "date", sort: "desc" },
        ],
        !currentPropertyId && !currentRentingPeriodId && !currentGroupId ? getValues("propertyId") : undefined,
    )
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editableProperties } = usePermissions(PermissionType.Incomes)

    const references = useMemo(
        () =>
            expenses?.pages
                .flatMap((r) => r.items)
                ?.map((r) =>
                    renderMenuItem(
                        isReferenceDisabled(r, true) ? translate("expense_assigned_to_owner") : "",
                        r.name ?? "",
                        {
                            value: r.id,
                        },
                        translate,
                        `${translate("due_date")}: ${r.dueDate.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme,
                    ),
                ),

        [translate, expenses],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            const accepted = rentingPeriodsFromAProperty?.find((t) => t.rentingPeriodStatus === RentingPeriodStatusCode.Accepted)

            const finished = rentingPeriodsFromAProperty?.find((t) => t.rentingPeriodStatus === RentingPeriodStatusCode.Finished)

            const currentRentingPeriod = rentingPeriod ?? accepted ?? finished

            if (v.propertyId != null) {
                setPropertyId(v.propertyId)
            }
            if (name === "referenceId") {
                const reference = (expenses?.pages.flatMap((r) => r.items) ?? []).find((r) => r.id.toString() === v.referenceId?.toString())
                if (reference) {
                    setValue("value", reference.value, { shouldValidate: true })
                    setValue("currencyId", reference.currencyId, { shouldValidate: true })
                    setValue("rentingPeriodId", currentRentingPeriodId ?? currentRentingPeriod?.id ?? 0)
                } else {
                    resetFields(AppForm.AddIncome, "referenceId")
                    setValue("currencyId", userData?.currency.id ?? 1, { shouldValidate: true })
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, resetFields, setValue, userData, rentingPeriod, setPropertyId, expenses, currentRentingPeriodId])

    useEffect(() => {
        let newFormValues: unknown | undefined

        if (isLinkingExpense && defaultValues) {
            newFormValues = {
                rentingPeriodId: defaultValues.rentingPeriodId,
                value: defaultValues?.value,
                currencyId: defaultValues.currencyId,
                type: PaymentType.Expense,
                date: DateTime.now().toISO() ?? undefined,
                paymentMethod: PropertyIncomePaymentMethod.Bank,
                referenceId: defaultValues.id.toString(),
                files: [],
                propertyId: defaultValues.propertyId,
            }
        } else if (defaultValues) {
            newFormValues = {
                ...defaultValues,
                date: defaultValues.date.toISO() ?? undefined,
                type: defaultValues.type,
                files: [],
                referenceId: defaultValues.associatedExpenses?.at(0),
            }
        } else {
            newFormValues = {
                currencyId: userData?.currency.id,
                date: DateTime.now().toISO() ?? undefined,
                paymentMethod: PropertyIncomePaymentMethod.Bank,
                files: [],
                propertyId: currentPropertyId ?? propertyId ?? rentingPeriodDetails?.propertyId ?? (editableProperties ?? [])[0]?.id,
                type: getValues("type"),
            }
        }

        reset(newFormValues as AddIncomeRequest)
    }, [defaultValues, userData, editableProperties, isLinkingExpense, rentingPeriodDetails, propertyId, currentPropertyId])

    return (
        <>
            <Box display='flex' flexDirection='column' gap={2} marginY={2} justifyContent={"center"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <Box display='flex' width='100%' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        {!isLinkingExpense && (
                            <RHFLazyLoadSelect
                                name='referenceId'
                                label={translate(getReferenceLabelTitle(true))}
                                size='small'
                                disabled={isLinkingExpense}
                                required
                                hasNext={hasNextPage}
                                isLoading={isFetching}
                                fetchNext={fetchNextPage}
                            >
                                {references?.length === 0 ? (
                                    <MenuItem style={{ textTransform: "none" }} disabled>
                                        {translate("no_expenses_found_for_property")}
                                    </MenuItem>
                                ) : (
                                    references
                                )}
                            </RHFLazyLoadSelect>
                        )}
                    </Box>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName='currencyId'
                        unitType='currency'
                        name='value'
                        size='small'
                        label={translate("amount")}
                        required
                    />
                    <RHFSelect name='paymentMethod' label={translate("income-table.paymentMethod")} size='small'>
                        <MenuItem value={PropertyIncomePaymentMethod.Cash}>{translate("income-table.paymentMethod-0")}</MenuItem>
                        <MenuItem value={PropertyIncomePaymentMethod.Bank}>{translate("income-table.paymentMethod-1")}</MenuItem>
                    </RHFSelect>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    {!isLinkingExpense && (
                        <LightTooltip
                            title={
                                currentPropertyId != null || defaultValues != null || rentingPeriodDetails != null
                                    ? translate("can_not_edit_field")
                                    : ""
                            }
                            arrow
                        >
                            <span style={{ width: "100%" }}>
                                <RHFAutocomplete
                                    freeSolo={false}
                                    autocompleteProps={{
                                        sx: {
                                            width: "100%",
                                        },
                                        noOptionsText: translate("no_value_found"),
                                    }}
                                    matchId
                                    name={"propertyId"}
                                    options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                    label={translate("property")}
                                    required={currentPropertyId == null}
                                    disabled={currentPropertyId != null || defaultValues != null || rentingPeriodDetails != null}
                                />
                            </span>
                        </LightTooltip>
                    )}
                    <RHFDatePicker
                        name='date'
                        disableFuture
                        inputProps={{ fullWidth: true, size: "small" }}
                        label={translate("date")}
                        minDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveInDate : undefined}
                        maxDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveOutDate : undefined}
                    />
                </Box>
            </Box>
            {(defaultValues == null || isLinkingExpense) && (
                <RHFUpload
                    name={"files"}
                    multiple
                    maxSize={getOwnerMaxSize(editableProperties, userData?.features?.maxPropertySize, currentPropertyId ?? propertyId)}
                    icon={"mdi:file-document-plus-outline"}
                />
            )}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
        </>
    )
}
