import { Viewer } from "@react-pdf-viewer/core"
import * as React from "react"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { CustomDialog, useSettingsContext } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"

export default function NotificationPreviewGuard() {
    const dispatch = useDispatch()
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    const { themeMode } = useSettingsContext()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)

    return (
        <CustomDialog
            open={modalOpen === AppModals.NotificationPreview}
            isFullScreen={true}
            onClose={() => {
                dispatch(setModalOpen(undefined))
            }}
        >
            <Viewer theme={themeMode} fileUrl={editingItem?.pdfUrl} plugins={[defaultLayoutPluginInstance]} />
        </CustomDialog>
    )
}
