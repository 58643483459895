import { LoadingIcon, Page, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../redux/store"
import { usePropertyDetailsQueryFn } from "../../queries"
import { Grid } from "@mui/material"
import Features from "../propertyDetails/General/details/Features"
import Map from "../propertyDetails/General/details/Map"
import React from "react"
import PropertyDescription from "./PropertyDescription"
import PropertyPhotos from "./PropertyPhotos"
import OwnerDetails from "./OwnerDetails"
import PropertyDetails from "./PropertyDetails"

export default function Marketing() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: currentProperty } = usePropertyDetailsQueryFn(currentPropertyId)

    if (currentProperty == null) return <LoadingIcon />

    return (
        <Page title={translate("marketing")} propertyName={currentProperty?.name} paddingBottom={2}>
            <Grid container sx={{ color: "text.primary" }}>
                <Grid item xs={12} md={6} xl={6} paddingTop={isMobile ? 2 : 1}>
                    <OwnerDetails />
                </Grid>
                <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={isMobile ? 2 : 1}>
                    <PropertyDetails />
                </Grid>
                <Grid item xs={12} md={6} xl={6} paddingTop={2}>
                    <PropertyDescription />
                </Grid>
                <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={2}>
                    <PropertyPhotos />
                </Grid>
                <Grid item xs={12} md={6} xl={6} paddingTop={2}>
                    <Features />
                </Grid>
                <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={2}>
                    <Map />
                </Grid>
            </Grid>
        </Page>
    )
}
