import { Text, View } from "@react-pdf/renderer"
import React from "react"
import useStyles from "./useStyles"
import { useLocales } from "rentzz"
import DefaultData from "../newContract/DefaultObligations.json"

interface GDPRProps {
    firstArticleNum: number
}

export default function GDPR({ firstArticleNum }: GDPRProps) {
    const styles = useStyles()
    const { translate } = useLocales()

    return (
        <>
            <View style={styles.mainText}>
                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 1 })}</Text>
                    {DefaultData.GDPR[0]}
                </Text>

                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 2 })}</Text> {DefaultData.GDPR[1]}
                </Text>

                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 3 })}</Text> {DefaultData.GDPR[2]}
                </Text>
                <Text style={styles.bullet}>{translate("GDPR.name")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.address")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.contact")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.online")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.bank")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.id")}</Text>
                <Text style={styles.bullet}>{translate("GDPR.signature")}</Text>

                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 4 })}</Text> {DefaultData.GDPR[3]}
                </Text>
                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 5 })}</Text> {DefaultData.GDPR[4]}
                </Text>
                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 6 })}</Text> {DefaultData.GDPR[5]}
                </Text>
                <Text style={styles.mainText}>
                    <Text style={styles.boldFont}>{translate("art", { number: firstArticleNum + 7 })}</Text> {DefaultData.GDPR[6]}
                </Text>
            </View>
        </>
    )
}
