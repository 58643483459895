import { Controller, ControllerRenderProps, useFormContext } from "react-hook-form"
import { InputBase, InputBaseProps } from "@mui/material"
import { useCallback } from "react"
import { useTheme } from "@mui/material/styles"

interface IProps {
    name: string
}
type Props = IProps & InputBaseProps
export default function RHFEditableInput({ name, sx, ...other }: Props) {
    const { control } = useFormContext()
    const theme = useTheme()

    const getFieldValue = useCallback((field: ControllerRenderProps) => {
        if (typeof field.value === "number" && field.value === 0) {
            return ""
        }
        return field.value
    }, [])

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputBase
                    {...field}
                    value={getFieldValue(field)}
                    error={!!error}
                    sx={{
                        color: theme.palette.grey.A700,
                        ...sx,
                    }}
                    {...other}
                />
            )}
        />
    )
}
