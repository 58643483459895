import { LoadingScreen, Option, Page, PromoCodeRequest, SubscriptionsList, useLocales } from "rentzz"
import { getSubscriptionQuery, Subscription } from "../../queries/subscription"
import { useCurrencyQueryFn } from "../../queries/currency"
import React, { useCallback, useMemo, useState } from "react"
import { AppModals, setContext, setCurrentPropertyId, setModalOpen, setShowSubscriptionPage } from "../../redux/slices/App"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { useDispatch, useSelector } from "../../redux/store"
import { checkCodeMutation, getSubscriptionCheckoutLinkMutation, useLogout } from "../../mutations/user"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useTheme } from "@mui/material/styles"

export default function SubscriptionPage() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isPromoCodeOn = useFeatureIsOn("promo-code")
    const { showSubscriptionPage } = useSelector((state) => state.appState)
    const { data: subscriptions } = getSubscriptionQuery()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: user, refetch: refetchUserData } = useUserDataQueryFn()
    const { mutate: signOut } = useLogout()
    const { mutate: subscriptionCheckout, isPending: isCheckoutLoading } = getSubscriptionCheckoutLinkMutation()
    const { mutate: checkPromoCode, isPending: isCheckPromoCodeLoading, isError: isCheckPromoCodeError, data } = checkCodeMutation()
    const [currentPromoCode, setCurrentPromoCode] = useState<string | undefined>(undefined)
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const hasColoredBar = useFeatureIsOn(UserFlags.ColoredBar.toString())

    const handleOnGoBack = useCallback(() => {
        dispatch(setShowSubscriptionPage(false))
    }, [dispatch])

    const subscriptionsWithCurrency = useCallback(() => {
        if (subscriptions == null) return []

        const parsed: Subscription[] = subscriptions.map((subscription) => ({
            ...subscription,
            prices: subscription.prices.map((price) => ({
                ...price,
                currency: currencies?.find((currency) => currency.id === price.currencyId)?.code ?? "",
            })),
        }))
        return parsed
    }, [subscriptions, currencies])

    const onPlanChoose = useCallback(
        async (id: string) => {
            subscriptionCheckout(
                { id, promotionalCode: currentPromoCode },
                {
                    onSuccess: (trialLink) => {
                        if (trialLink !== "") {
                            window.open(trialLink, "_self")
                        } else {
                            refetchUserData()
                        }
                    },
                },
            )
        },
        [subscriptionCheckout, refetchUserData, currentPromoCode],
    )

    const handleCheckPromoCode = useCallback(
        async (data: PromoCodeRequest) => {
            checkPromoCode(data, {
                onSuccess: () => {
                    setCurrentPromoCode(data.promoCode)
                },
            })
        },
        [checkPromoCode],
    )

    const options = useMemo((): Option[] => {
        const toReturn = [
            {
                icon: "mdi:cog",
                text: translate("settings"),
                hasDivider: true,
                onClick: () => dispatch(setModalOpen(AppModals.Settings)),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:email",
                text: translate("contact"),
                hasDivider: true,
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:account-reactivate",
                text: translate("change_context"),
                hasDivider: true,
                onClick: () => dispatch(setContext(undefined)),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:text-box-outline",
                text: translate("policies"),
                hasDivider: true,
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
            },
            {
                icon: "mdi:logout",
                text: translate("logout"),
                hasDivider: false,
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    signOut()
                },
                color: theme.palette.error.main,
            },
        ]
        if (policiesPageUrl.length === 0) {
            toReturn.splice(3, 1)
        }
        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl])

    if (subscriptions == null || currencies == null || user == null) {
        return <LoadingScreen />
    }

    return (
        <Page title={translate("subscriptions")} display={"flex"} justifyContent={"center"} alignItems={"center"} p={3} flexDirection={"column"}>
            <SubscriptionsList
                hasColoredBar={hasColoredBar}
                subscriptionExpired={user.isSubscriptionExpired}
                canTrial={user.canTrial}
                options={options}
                subscriptions={subscriptionsWithCurrency()}
                onChoose={onPlanChoose}
                mail={user?.email}
                fullName={`${user?.firstName} ${user?.lastName}`}
                isButtonLoading={isCheckoutLoading}
                showSubscriptionPage={showSubscriptionPage}
                onGoBack={handleOnGoBack}
                handlePromoCode={handleCheckPromoCode}
                promoCodeDetails={data}
                isError={isCheckPromoCodeError}
                isLoading={isCheckPromoCodeLoading}
                onRemovePromoCode={() => {
                    setCurrentPromoCode(undefined)
                }}
                currentPromoCode={currentPromoCode}
                isPromoCodeInputVisible={isPromoCodeOn && user.canTrial}
                currentPlan={user.roles.roleName}
            />
        </Page>
    )
}
