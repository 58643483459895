import { RHFUpload, useLocales } from "rentzz"
import React from "react"
import { Box, Paper, Typography, useTheme } from "@mui/material"

interface GalleryProps {
    isDisabled?: boolean
}

export default function Gallery({ isDisabled }: GalleryProps) {
    const { translate } = useLocales()
    const theme = useTheme()

    return (
        <Box>
            <Paper sx={{ padding: 2, borderColor: theme.palette.grey["300"], borderWidth: 1, borderStyle: "solid" }}>
                <Typography color={theme.palette.grey.A400} fontSize={14} paddingBottom={1}>
                    {translate("marketing.more_customers")}
                </Typography>

                <RHFUpload
                    name={"photos"}
                    multiple
                    onlyPhotos={true}
                    maxSize={10485760}
                    disabled={isDisabled ?? false}
                    uploadText={"add_photos"}
                    icon={"mdi:image-plus-outline"}
                />

                <Typography color={theme.palette.grey.A400} fontSize={14} paddingTop={1}>
                    {translate("marketing.maxSize")}
                </Typography>
            </Paper>
        </Box>
    )
}
