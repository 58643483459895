import { FormProvider, Image, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { NewInvitationRequest } from "./RentingPeriodTenantsCard"
import { yupResolver } from "@hookform/resolvers/yup"
import { NewInvitationSchema } from "../../../../../../validations/addTenant"
import { addNewInvitationMutation } from "../../../../../../mutations/tenants"
import { useRentingPeriodDetails } from "../../../../../../queries/tenants"
import { allLangs } from "../../../../../../config"
import { useUserDataQueryFn } from "../../../../../../queries/userData"
import { useSelector } from "../../../../../../redux/store"

interface Props {
    onClose: () => void
}
export default function AddNewTenantInvitationForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { mutateAsync: addNewInvitation, isPending: isAddNewInvitationLoading } = addNewInvitationMutation()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    const methods = useForm<NewInvitationRequest>({
        resolver: yupResolver(NewInvitationSchema()),
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            rentingPeriodId: currentRentingPeriodId,
            invitationLanguage: userData?.languageCode,
        },
    })

    const { handleSubmit, setError } = methods

    const onSubmit = useCallback(
        async (data: NewInvitationRequest) => {
            if (data == null) return
            const notifiedEmails = rentingPeriodDetails?.invitedUsers.map((user) => user.mail)
            if (notifiedEmails?.some((email) => email === data.email)) {
                setError("email", { message: "tenant_exists" })
                return
            }
            await addNewInvitation({ rentingPeriodId: data.rentingPeriodId, email: data.email, invitationLanguage: data.invitationLanguage })
            onClose()
        },
        [addNewInvitation, rentingPeriodDetails, onClose, setError],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={2} flexDirection={"column"} paddingY={1}>
                <RHFTextField name='email' label={translate("email")} required />
                <RHFSelect name={"invitationLanguage"} size='small' label={translate("invitation_language")} required>
                    {allLangs.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Box display='flex' flexDirection='row'>
                                <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
                                {option.label}
                            </Box>
                        </MenuItem>
                    ))}
                </RHFSelect>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isAddNewInvitationLoading}
                        disabled={isAddNewInvitationLoading}
                        color={"primary"}
                        type={"submit"}
                        fullWidth
                        variant={isMobile ? "contained" : "text"}
                    >
                        {translate("send")}
                    </LoadingButton>
                    {isMobile && (
                        <Button color={"primary"} onClick={onClose} disabled={isAddNewInvitationLoading}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}
