import { Box, Fab, Typography } from "@mui/material"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import CustomAddCard from "../../../../components/CustomAddCard"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppContext, Iconify, useLocales } from "rentzz"
import { useMetersQueryFn } from "../../../../queries/meters"
import { OwnerPropertyMeter, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import MeterMobileCard from "./MeterMobileCard"
import NoDataPage from "../../../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useTheme } from "@mui/material/styles"

export default function MobileMetersList() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen } = useSelector((state) => state.appState)
    const { data: meters } = useMetersQueryFn()
    const { context } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Meters)

    const handleMetersContext = useCallback(() => {
        if (context === AppContext.Tenant) {
            return (meters as (TenantPropertyMeter & { canWrite: boolean })[])?.map((meter) => <MeterMobileCard key={meter.id} meter={meter} />)
        }

        return (meters as (OwnerPropertyMeter & { canWrite: boolean })[])?.map((meter) => <MeterMobileCard key={meter.id} meter={meter} />)
    }, [context, meters])

    if (meters?.length === 0 && !canWrite && context === AppContext.Owner) return <NoDataPage icon={"mdi:timer-cancel"} noDataText={"no_meters"} />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {context === AppContext.Tenant && meters && meters.length > 0 && (
                <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                    {translate("meters")}
                </Typography>
            )}
            {meters?.length !== 0 && context === AppContext.Owner && modalOpen == null && canWrite && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddMeter))}
                    sx={{ position: "absolute", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            <Box paddingX={context === AppContext.Tenant ? 1 : undefined} display={"flex"} flexDirection={"column"} gap={1}>
                {handleMetersContext()}
            </Box>

            {meters?.length === 0 && context === AppContext.Owner && canWrite && (
                <CustomAddCard onCardClick={() => dispatch(setModalOpen(AppModals.AddMeter))} icon={"mdi:timer-plus-outline"} text={"add_meter"} />
            )}
            {meters?.length === 0 && context === AppContext.Tenant && <NoDataPage icon={"mdi:timer-cancel"} noDataText={"tenant_no_meters"} />}
        </Box>
    )
}
