import { LoadingIcon, Page, useLocales } from "rentzz"
import { Box, Container } from "@mui/material"
import React from "react"
import { ContentStyle, TabPanel } from "../../../../pages/PropertyDetails"
import Expenses from "../../../expenses/Expenses"
import Income from "../../../income/IncomePage"
import GeneralPage from "./general/GeneralPage"
import { useRentingPeriodDetails } from "../../../../queries/tenants"
import JournalPage from "./journal/JournalPage"
import C168 from "./C168/C168"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import PropertySections from "../../../files/PropertySections"
import RentingPeriodDetailsPageTabs from "./RentingPeriodDetailsPageTabs"
import { useAtomValue } from "jotai"
import { rentingPeriodDetailsPage, TenantsPage } from "../../../../utils/atom"
import Configurations from "./configurations/Configurations"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../../../queries/userData"
import PaymentNotifications from "./payment-notifications/PaymentNotifications"
import { useSelector } from "../../../../redux/store"

export default function RentingPeriodDetails() {
    const rentingPeriodPage = useAtomValue(rentingPeriodDetailsPage)
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: userData } = useUserDataQueryFn()
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    if (rentingPeriodPage == null || rentingPeriodDetails == null) {
        return <LoadingIcon />
    }

    return (
        <Page title={translate("details")}>
            <Container maxWidth={false}>
                <ContentStyle>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderColor: "divider" }}>
                            <RentingPeriodDetailsPageTabs />
                        </Box>

                        <TabPanel value={rentingPeriodPage} index={TenantsPage.GENERAL}>
                            <GeneralPage />
                        </TabPanel>
                        <TabPanel value={rentingPeriodPage} index={TenantsPage.EXPENSES}>
                            <Page title={translate("tenants")} propertyName={translate("expenses.expenses")}>
                                <Expenses />
                            </Page>
                        </TabPanel>
                        <TabPanel value={rentingPeriodPage} index={TenantsPage.INCOMES}>
                            <Income />
                        </TabPanel>
                        <TabPanel value={rentingPeriodPage} index={TenantsPage.DOCUMENTS}>
                            <PropertySections />
                        </TabPanel>
                        <TabPanel value={rentingPeriodPage} index={TenantsPage.JOURNAL}>
                            <JournalPage />
                        </TabPanel>

                        {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                            <TabPanel value={rentingPeriodPage} index={TenantsPage.CONFIGURATIONS}>
                                <Configurations />
                            </TabPanel>
                        )}

                        {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                            <TabPanel value={rentingPeriodPage} index={TenantsPage.C168}>
                                <C168 />
                            </TabPanel>
                        )}

                        {showPaymentNotifications &&
                            (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                                <TabPanel value={rentingPeriodPage} index={TenantsPage.PAYMENT_NOTIFICATIONS}>
                                    <PaymentNotifications />
                                </TabPanel>
                            )}
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    )
}
