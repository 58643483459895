import { useForm } from "react-hook-form"
import React, { useEffect, useState } from "react"
import { useCitiesQueryFn, useCountiesQueryFn } from "../../../../queries"
import { FormProvider, RHFAutocomplete, RHFPhoneNumber, RHFRadioGroup, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { Box, Button, MenuItem } from "@mui/material"
import { TenantRequiredInfo } from "../../newContract/TenantRequiredData"
import { yupResolver } from "@hookform/resolvers/yup"
import { TenantInfoSchema } from "../../../../validations/tenantInfoSchema"
import { availableStories } from "../../../../config"
import { ContactInfoPersonType, ContactInfoType, useUserDataQueryFn } from "../../../../queries/userData"

export interface ContactInfoData {
    type: ContactInfoType
    contactInfoPersonType: ContactInfoPersonType
    fullName: string
    city: string
    county: string
    identitySeries?: string
    identityNumber?: string
    street?: string
    streetNumber?: string
    buildingNumber?: string
    stair?: string
    floor?: string
    apartment?: string
    phone?: string
    email?: string
    companyName?: string
    companyRegistrationNumber?: string
    companyPosition?: string
    bankName?: string
    bankAccount?: string | null
}

interface TenantInfoProps {
    onSubmit: (data: Omit<ContactInfoData, "type">) => void
    defaultValues?: Omit<ContactInfoData, "type">
    requiredInfo: TenantRequiredInfo
    onBackClick: () => void
}

const TenantContactInfo = ({ defaultValues, onSubmit, requiredInfo, onBackClick }: TenantInfoProps) => {
    const { data: user } = useUserDataQueryFn()
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const [contactInfoPersonType, setContactInfoPersonType] = useState<ContactInfoPersonType>(ContactInfoPersonType.Individual)
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)
    const isMobile = useIsMobile()
    const { translate } = useLocales()

    const methods = useForm<Omit<ContactInfoData, "type" | "email">>({
        resolver: yupResolver<Omit<ContactInfoData, "type" | "email" | "bankName" | "bankAccount">>(TenantInfoSchema(requiredInfo)),
        mode: "onChange",
        defaultValues: defaultValues ?? {
            fullName: requiredInfo.isFullNameRequired ? `${user?.firstName} ${user?.lastName}` : undefined,
            contactInfoPersonType: ContactInfoPersonType.Individual,
        },
    })

    const { handleSubmit, watch, resetField, setValue } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "county") {
                setCounty(allCounties?.find((c) => c.name === v.county)?.name ?? "")
                resetField("city")
                if (requiredInfo.isStreetRequired) resetField("street")
                if (requiredInfo.isStreetNumberRequired) resetField("streetNumber")
                if (requiredInfo.isBuildingNumberRequired) resetField("buildingNumber")
                if (requiredInfo.isStairRequired) resetField("stair")
                if (requiredInfo.isFloorRequired) resetField("floor")
                if (requiredInfo.isApartmentRequired) resetField("apartment")
                if (requiredInfo.isPhoneRequired) resetField("phone")
            }
            if (name === "contactInfoPersonType" && v.contactInfoPersonType != null) {
                setContactInfoPersonType(Number.parseInt(v.contactInfoPersonType.toString()))
                setValue("fullName", "")
                setValue("city", "")
                setValue("county", "")
                setValue("identitySeries", undefined)
                setValue("identityNumber", undefined)
                setValue("street", "")
                setValue("streetNumber", "")
                setValue("buildingNumber", "")
                setValue("stair", "")
                setValue("floor", "")
                setValue("apartment", "")
                setValue("phone", "")
                setValue("companyName", undefined)
                setValue("companyRegistrationNumber", undefined)
                setValue("companyPosition", undefined)
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, allCounties, requiredInfo, setValue])

    useEffect(() => {
        if (defaultValues?.county) {
            setCounty(defaultValues.county)
        }
    }, [])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
                display='flex'
                flexDirection='column'
                rowGap={2}
                width='100%'
                height='100%'
                justifyContent='space-between'
                marginTop={isMobile ? 0 : 2}
            >
                <Box display={"flex"} justifyContent={"center"} marginBottom={2}>
                    <RHFRadioGroup
                        name={"contactInfoPersonType"}
                        options={[
                            { label: translate("individual"), value: ContactInfoPersonType.Individual, moreInfo: "" },
                            { label: translate("company"), value: ContactInfoPersonType.Company, moreInfo: "" },
                        ]}
                    />
                </Box>
                <Box display='flex' flexWrap={"wrap"} rowGap={2}>
                    {contactInfoPersonType === ContactInfoPersonType.Individual && (
                        <>
                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='fullName'
                                label={translate("fullName")}
                                required
                            />

                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='identitySeries'
                                required
                                label={translate("identity_series")}
                            />

                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='identityNumber'
                                required
                                label={translate("identity_number")}
                            />
                        </>
                    )}

                    {contactInfoPersonType === ContactInfoPersonType.Company && (
                        <>
                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name={"companyName"}
                                required
                                label={translate("company_name")}
                            />
                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='companyRegistrationNumber'
                                required
                                label={translate("company_registration_number")}
                            />
                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='fullName'
                                label={translate("full_name_company")}
                                required
                            />
                            <RHFTextField
                                sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                                name='companyPosition'
                                label={translate("contracts.asUppercase")}
                                required
                            />
                        </>
                    )}

                    <RHFAutocomplete
                        name={"county"}
                        required
                        options={allCounties?.map((c) => c.name) ?? []}
                        label={translate("county")}
                        loading={allCountiesFetching}
                        freeSolo={false}
                        autocompleteProps={{
                            sx: { minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2, noOptionsText: translate("no_value_found") },
                        }}
                    />

                    <RHFAutocomplete
                        textFieldProps={{
                            disabled: allCities?.length === 0,
                        }}
                        required
                        name={"city"}
                        options={allCities?.map((c) => c.name) ?? []}
                        loading={allCitiesFetching}
                        label={translate("city")}
                        freeSolo={false}
                        autocompleteProps={{
                            disabled: allCities == null || allCities.length === 0,
                            sx: { minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 },
                            noOptionsText: translate("no_value_found"),
                        }}
                    />

                    {requiredInfo.isStreetRequired && (
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            name='street'
                            required
                            label={translate("street")}
                        />
                    )}
                    {requiredInfo.isStreetNumberRequired && (
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            name='streetNumber'
                            required
                            label={translate("number")}
                        />
                    )}
                    {requiredInfo.isBuildingNumberRequired && (
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            name='buildingNumber'
                            required
                            label={translate("buildingNumber")}
                        />
                    )}
                    {requiredInfo.isStairRequired && (
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            name='stair'
                            required
                            label={translate("stair")}
                        />
                    )}
                    {requiredInfo.isFloorRequired && (
                        <RHFSelect
                            name='floor'
                            label={translate("floor")}
                            sx={{ textAlign: "start", minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            size={"small"}
                            required
                        >
                            {availableStories.map((option) => (
                                <MenuItem key={option.value} value={option.value} style={{ textTransform: "none" }}>
                                    {translate(option.value === 0 ? "ground" : "floor_with_number", { floor: option.value })}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    )}
                    {requiredInfo.isApartmentRequired && (
                        <RHFTextField
                            sx={{ minWidth: 200, maxWidth: isMobile ? "100%" : "50%", paddingRight: 2 }}
                            name='apartment'
                            required
                            label={translate("apartment")}
                        />
                    )}
                    {requiredInfo.isPhoneRequired && (
                        <Box sx={{ minWidth: isMobile ? "100%" : 325, paddingRight: 2 }}>
                            <RHFPhoneNumber name='phone' label={translate("phone")} inputRequired style={{ color: "red" }} />
                        </Box>
                    )}
                </Box>
                <Box display='flex' flex={1} flexDirection='row-reverse'>
                    <Button
                        type='submit'
                        color={"primary"}
                        sx={{ width: isMobile ? "100%" : undefined, alignSelf: "flex-start", marginRight: 2, marginTop: 4 }}
                        variant='contained'
                    >
                        {translate("next")}
                    </Button>
                    <Button
                        onClick={onBackClick}
                        sx={{ width: isMobile ? "100%" : undefined, alignSelf: "flex-start", marginRight: 2, marginTop: 4 }}
                        color={"primary"}
                    >
                        {translate("previous")}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default TenantContactInfo
