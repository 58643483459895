import React, { useCallback, useEffect } from "react"
import { useIsMobile } from "rentzz"
import { useIntl } from "react-intl"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../queries/currency"
import MobileExpensesList from "./mobile/MobileExpensesList"
import DesktopExpensesList from "./DesktopExpensesList"
import { useSelector } from "../../redux/store"
import { ComplexQueryIDs, useQueryInvalidator } from "../../hooks/useQueryInvalidator"
import { useDispatch } from "react-redux"
import { setIsRedirectPaymentButtonPressed } from "../../redux/slices/App"

const Expenses = () => {
    const isMobile = useIsMobile()
    const intl = useIntl()
    const dispatch = useDispatch()
    const { data: currencyData } = useCurrencyQueryFn()
    const { isRedirectPaymentButtonPressed } = useSelector((state) => state.appState)
    const { invalidateQueries } = useQueryInvalidator()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const handleFocusRefresh = useCallback(async () => {
        await invalidateQueries([ComplexQueryIDs.ExpenseIncomes, ComplexQueryIDs.Expenses])
    }, [invalidateQueries])

    useEffect(() => {
        if (isRedirectPaymentButtonPressed) {
            setTimeout(() => dispatch(setIsRedirectPaymentButtonPressed(false)), 50000)

            window.addEventListener("focus", handleFocusRefresh)
            return () => {
                window.removeEventListener("focus", handleFocusRefresh)
            }
        }
    }, [isRedirectPaymentButtonPressed])

    return <>{isMobile ? <MobileExpensesList getAmount={getAmount} /> : <DesktopExpensesList getAmount={getAmount} />}</>
}

export default Expenses
