import { Box, Button, CircularProgress, Divider, IconButton, ListItemText, Paper, Stack, Typography } from "@mui/material"
import { DateTime } from "luxon"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import React, { useCallback, useState } from "react"
import { Iconify, MotionContainer, useLocales } from "rentzz"
import { UserInvoice, useUserInvoices } from "../../../queries/userData"
import { downloadFile } from "../../../utils/actions"
import { useTheme } from "@mui/material/styles"

export default function Invoices() {
    const theme = useTheme()
    const { translate } = useLocales()
    const [expanded, setExpanded] = useState<boolean>(false)
    const { data: invoices, isFetching: areInvoicesFetching } = useUserInvoices()

    const invoiceCard = useCallback(
        (invoice: UserInvoice) => {
            return (
                <Stack key={invoice.id} direction='row' alignItems='center'>
                    <ListItemText
                        primary={invoice.name}
                        secondary={invoice.date.toLocaleString(DateTime.DATE_SHORT)}
                        primaryTypographyProps={{
                            typography: "body2",
                        }}
                        secondaryTypographyProps={{
                            mt: 0.5,
                            component: "span",
                            typography: "caption",
                            color: "text.disabled",
                        }}
                    />

                    <Typography variant='body2' sx={{ textAlign: "right", mr: 5 }}>
                        {invoice.amount}
                    </Typography>

                    <IconButton
                        color={"info"}
                        onClick={async () => {
                            if (invoice == null) return
                            await downloadFile({
                                downloadId: invoice.id.toString(),
                                downloadName: invoice.name ?? "unknown",
                                extension: "pdf",
                            })
                        }}
                    >
                        <CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                    </IconButton>
                </Stack>
            )
        },
        [downloadFile],
    )

    return (
        <Paper elevation={6} sx={{ padding: 2, color: theme.palette.grey.A700 }}>
            <Stack spacing={1.5} sx={{ px: 3, pt: 0 }}>
                <Typography variant={"subtitle1"} fontWeight={"bold"}>
                    {translate("invoices")}
                </Typography>
                <Divider sx={{ borderStyle: "dashed" }} />
                {areInvoicesFetching ? (
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200} marginTop={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box maxHeight={500} overflow={"auto"} paddingRight={1}>
                        {(invoices && invoices.length < 8 ? invoices : (invoices ?? []).slice(0, 8))?.map((invoice) => invoiceCard(invoice))}

                        {expanded && (
                            <MotionContainer action animate={expanded} sx={{ position: "relative" }}>
                                {invoices?.slice(9).map((invoice) => invoiceCard(invoice))}
                            </MotionContainer>
                        )}
                    </Box>
                )}

                <Divider sx={{ borderStyle: "dashed" }} />
            </Stack>

            <Button
                size='small'
                color='inherit'
                startIcon={<Iconify icon={invoices && invoices.length < 8 ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"} />}
                onClick={() => setExpanded(!expanded)}
            >
                {translate(expanded ? "close" : "view_all")}
            </Button>
        </Paper>
    )
}
