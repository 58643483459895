import React from "react"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import AcceptPage from "./AcceptPage"
import { acceptPrivacyPolicyMutation } from "../../mutations/user"
import { useFeatureValue } from "@growthbook/growthbook-react"

export default function PrivacyPolicyAccept() {
    const { data: userData } = useUserDataQueryFn()
    const { mutateAsync, isPending: isLoading } = acceptPrivacyPolicyMutation()
    const privacyPolicyUrl = useFeatureValue(UserFlags.PrivacyPolicyUrl.toString(), "")
    if (userData == null) return null

    return (
        <AcceptPage
            title={"privacy_policy"}
            consultText={"privacy_policy_consult_text"}
            consultURL={privacyPolicyUrl}
            acceptanceText={"privacy_policy_acceptance_text"}
            buttonText={"accept"}
            onAccept={mutateAsync}
            isLoading={isLoading}
            agreeWithTermsCheckTitle={"agree_with_policy"}
        />
    )
}
