import { AppContext, useIsMobile } from "rentzz"
import { Grid } from "@mui/material"
import { UserFlags } from "../../../queries/userData"
import React from "react"
import Invoices from "./Invoices"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import SubscriptionDetails from "./SubscriptionDetails"
import { GeneralProfile } from "./GeneralProfile"
import { useSelector } from "../../../redux/store"

export default function GeneralPage() {
    const isMobile = useIsMobile()
    const showInvoices = useFeatureIsOn(UserFlags.ShowInvoices.toString())
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { context } = useSelector((state) => state.appState)

    return (
        <Grid container sx={{ marginTop: 2 }}>
            <Grid
                xs={12}
                md={showInvoices && !isWhitelabel && context === AppContext.Owner ? 8 : 12}
                sx={{ paddingRight: isMobile ? undefined : 2, paddingBottom: isMobile ? 2 : undefined }}
            >
                <GeneralProfile />
                {!isWhitelabel && context === AppContext.Owner && <SubscriptionDetails />}
            </Grid>
            {showInvoices && !isWhitelabel && context === AppContext.Owner && (
                <Grid xs={12} md={4}>
                    <Invoices />
                </Grid>
            )}
        </Grid>
    )
}
