import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, LightTooltip, RHFSelect, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button, CircularProgress, MenuItem } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { RoleName, useUserContractTemplatesQuery, useUserDataQueryFn } from "../../../../queries/userData"
import { AddTenantWithContractSchema } from "../../../../validations/addTenantWithContractSchema"
import { LoadingButton } from "@mui/lab"
import { useSelector } from "../../../../redux/store"
import { ContractDetails } from "../../../types/AddTenant"

interface ContractProps {
    onNext: (value: ContractDetails) => void
    onBack: () => void
    isLoading: boolean
    contractId: string | undefined
    onContractIdChange: (id: string | undefined) => void
    defaultValues?: ContractDetails
    canChangeContract: boolean
}
const Contract = ({ onNext, isLoading, contractId, onBack, onContractIdChange, defaultValues, canChangeContract }: ContractProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: userContracts } = useUserContractTemplatesQuery(false)
    const { data: userData } = useUserDataQueryFn()
    const isDisabled = useMemo(() => userData?.roles.roleName === RoleName.BRONZE || userData?.roles.roleName === RoleName.FREE, [userData])
    const [isUsingOwnContract, setIsUsingOwnContract] = useState<boolean | undefined>(
        defaultValues == null ? true : defaultValues?.contractId === "-1",
    )
    const { errorMessage } = useSelector((state) => state.appState)

    const methods = useForm<ContractDetails>({
        resolver: yupResolver<ContractDetails>(AddTenantWithContractSchema),
        mode: "onChange",
    })
    const { handleSubmit, watch, setValue, getValues, reset } = methods

    useEffect(() => {
        reset(
            defaultValues ?? {
                files: [],
                contractId: "-1",
            },
        )
    }, [reset, defaultValues])

    useEffect(() => {
        const subscription = watch((v, info) => {
            if (info.name === "contractId") {
                setValue("files", [], { shouldValidate: true })
                onContractIdChange(v.contractId === "-1" ? undefined : v.contractId)
            }

            if (v.contractId && v.contractId !== "") {
                if (v.contractId === "-1") {
                    setIsUsingOwnContract(true)
                } else {
                    setIsUsingOwnContract(false)
                }
            }
            if (v.contractId === "") setIsUsingOwnContract(false)
        })

        return () => subscription.unsubscribe()
    }, [watch, setIsUsingOwnContract, onContractIdChange])

    const onSubmit = useCallback(
        (v: ContractDetails) => {
            let contractId: string | undefined

            if (v.contractId === "-1") {
                contractId = undefined
            } else {
                contractId = v.contractId
            }
            onNext({ ...v, contractId })
        },
        [onNext, getValues],
    )

    if (userContracts == null)
        return (
            <Box display={"flex"} justifyContent={"center"} height={100}>
                <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
            </Box>
        )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box paddingTop={4} display={"flex"} flexDirection={"column"} gap={2}>
                {!canChangeContract && (
                    <Box marginTop={2}>
                        <Alert severity={"info"}>{translate("can_not_choose_contract")}</Alert>
                    </Box>
                )}
                {canChangeContract && (
                    <LightTooltip title={isDisabled ? translate("no_permission_if_bronze") : ""}>
                        <span>
                            <RHFSelect
                                name='contractId'
                                label={translate("select_contract")}
                                size='small'
                                required
                                disabled={isDisabled}
                                defaultValue={"-1"}
                            >
                                <MenuItem value={"-1"} style={{ textTransform: "none" }}>
                                    {translate("own_contract")}
                                </MenuItem>
                                {userContracts.map((c) => (
                                    <MenuItem key={c.id} value={c.id} style={{ textTransform: "none" }}>
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </span>
                    </LightTooltip>
                )}
                {isUsingOwnContract && <RHFUpload name='files' multiple icon={"mdi:file-document-plus-outline"} text={"add_new_contract"} />}

                {errorMessage && contractId == null && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"}>
                    <Button color={"primary"} onClick={onBack} disabled={isLoading}>
                        {translate("back")}
                    </Button>
                    <LoadingButton color={"primary"} loading={isLoading} variant='contained' disabled={isLoading} type='submit'>
                        {contractId == null ? translate("send_invitation") : translate("next")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default Contract
